<div class="tile-card-structure trend-card-structure-height trend-chart" [ngClass]="{'class': true, 'div-border-none' : !showBorder }"
  style="margin-top:0 !important">
  <div class="block-header table-header-font">
    <span class="  card-heading  table-header-font tile-heading">{{name}}</span>
  </div>
  <div class="tile-card-body row">

    <div class="topbar col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xs-8 cost-sum text-right" *ngIf="yearFilter">
      <span class="sum{{CurrentYear-2}} chart-heading-font">{{type}} {{CurrentYear-2}} <br><span class="headerInfo">${{sum(CurrentYear-2)}}</span></span>
      <span class="sum{{CurrentYear-1}} chart-heading-font">{{type}} {{CurrentYear-1}} <br><span class="headerInfo">${{sum(CurrentYear-1)}}</span></span>
      <span class="sum{{CurrentYear}} chart-heading-font">{{type}} {{CurrentYear}} <br><span class="headerInfo">${{sum(CurrentYear)}}</span></span>
    </div>
    <div class="topbar p-1 col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-4" [ngClass]="class" *ngIf="yearFilter">
      <label class="checkbox chart-heading-font">
        <input title="click to view trend" type="checkbox" [checked]="true" name="{{CurrentYear-2}}"
          class="years first-year" [value]="CurrentYear-2" (click)="update()" />&nbsp; {{CurrentYear-2}}
      </label>
      <label class="checkbox chart-heading-font">
        <input title="click to view trend" type="checkbox" [checked]="true" class="years second-year"
          name="{{CurrentYear-1}}" [value]="CurrentYear-1" (click)="update()" />&nbsp; {{CurrentYear-1}}
      </label>
      <label class="checkbox chart-heading-font">
        <input title="click to view trend" type="checkbox" disabled [checked]="true" name="{{CurrentYear}}"
          class="years third-year" [value]="CurrentYear" (click)="update()" />&nbsp; {{CurrentYear}}
      </label>
    </div>

    <div class="chart-card col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
      <div class="my_dataviz my_dataviz-height">
        <svg class="svg-content" viewBox="0 0 750 360" preserveAspectRatio="xMidYMid meet"></svg>
      </div>
    </div>
    <!-- <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
      <div class="info-div">
        <div class="pullUp">
          <div class="square square-size" [style.background]="barcolor[0]">
          </div>
          <p class="legend">{{CurrentYear-2}}</p>
        </div>
        <div class="pullUp">
          <div class="square square-size" [style.background]="barcolor[1]">
          </div>
          <p class="legend">{{CurrentYear-1}}</p>
        </div>
        <div class="pullUp">
          <div class="square square-size" [style.background]="barcolor[2]">
          </div>
          <p class="legend">{{CurrentYear}}</p>
        </div>
      </div>
    </div> -->
  </div>
  
</div>
