<div class="uitk-form-control">
  <div *ngIf="label" class="input-label">
    <label for="{{ id }}"
      >{{ label
      }}<span
        *ngIf="isRequired"
        class="uitk-icon uitk-icon-asterisk sm-icon uitk-c-label__icon"
        aria-hidden="true"
      ></span
      >:
    </label>
  </div>
  <div class="input-box">
    <mat-radio-group
      [id]="id"
      (change)="updateValue($event)"
      [required]="isRequired"
      [disabled]="disabled"
      [value]="selectedvalue"
      aria-label="Select an option"
    >
      <mat-radio-button color="primary" *ngFor="let row of list" [value]="row.value"> {{ row.name }} </mat-radio-button>
    </mat-radio-group>
    <ng-container *ngIf="formcontrol && formcontrol?.invalid && formcontrol?.touched">
      <ul class="help-block uitk-c-input__message--error">
        <li *ngIf="formcontrol.hasError('required')">
          this is required field.
        </li>
        <!-- <li *ngFor="let err of formcontrol.errors | keyvalue">
           {{err.key}}
         </li> -->
      </ul>
    </ng-container>
    <ng-container *ngIf="formref?.submitted && !formcontrol?.touched">
      <ul *ngIf="this.form.controls[this.name].hasError('required')" class="help-block uitk-c-input__message--error">
        <li>
          this is required field
        </li>
      </ul>
    </ng-container>

    <strong *ngIf="debugg">Value: {{ selectedvalue }}</strong>
  </div>
</div>
