<div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 padu-edit-container">
  <div class="modal-actions pull-right">
    <mat-icon class="material-icons pgm-close" matTooltip="Close" (click)="closePopup()">close
    </mat-icon>
  </div>
  <div class="padu-status-container">
    <div class="table-con">
      <table mat-table [dataSource]="paduData" class="mat-elevation-z8">
        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of paduEditColumns">

          <th mat-header-cell [style.width]="column.width" *matHeaderCellDef
            [class.hide-action]="!sharedService.isEnabled('PADU_Pr', selectedLOB, selectedSUBLOB) && column.columnDef=='Edit'">
            {{ column.header }} </th>
          <td mat-cell [ngClass]="{'text-center': column.columnDef === 'Edit'}" [style.width]="column.width"
            *matCellDef="let element"
            [class.hide-action]="!sharedService.isEnabled('PADU_Pr', selectedLOB, selectedSUBLOB) && column.columnDef=='Edit'">
            <span class="add-padding" [ngClass]="getClassName(currentPADU)" *ngIf="column.columnDef=='current' ">
              <span style="position: absolute;"
                *ngIf="currentPADU=== 'I' || currentPADU=== 'PP'">{{PADUFullName[currentPADU]}}</span>
              <mat-select style="max-height: 300px;" class="mat-select-padu"
                (selectionChange)="valueChange($event,'current')" (ngModelChange)="modelChangedPadu($event,currentPADU)"
                [(ngModel)]="currentPADU">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label }}</span>
                </mat-option>
              </mat-select>
            </span>
            <span class="add-padding" [ngClass]=" getClassName(transition[0]) "
              *ngIf="column.columnDef=='transition_1' ">
              <span class="text-notavailable-option"
                *ngIf="transition[0]=== 'I' || transition[0]=== 'PP'">{{PADUFullName[transition[0]]}}</span>
              <mat-select style="max-height: 300px;" class="mat-select-padu"
                (ngModelChange)="modelChangedPadu($event,transition[0])"
                (selectionChange)="valueChange($event,'transition0')" [(ngModel)]="transition[0]">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
            <span class="add-padding" [ngClass]="getClassName(transition[1])" *ngIf="column.columnDef=='transition_2'">
              <span class="text-notavailable-option"
                *ngIf="transition[1]=== 'I' || transition[1]=== 'PP'">{{PADUFullName[transition[1]]}}</span>
              <mat-select (ngModelChange)="modelChangedPadu($event,transition[1])"
                (selectionChange)="valueChange($event,'transition1')" style="max-height: 300px;" class="mat-select-padu"
                [(ngModel)]="transition[1]">

                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
            <span class="add-padding" [ngClass]="getClassName(transition[2])" *ngIf="column.columnDef=='transition_3'">
              <span class="text-notavailable-option"
                *ngIf="transition[2]=== 'I' || transition[2]=== 'PP'">{{PADUFullName[transition[2]]}}</span>
              <mat-select (ngModelChange)="modelChangedPadu($event,transition[2])"
                (selectionChange)="valueChange($event,'transition2')" style="max-height: 300px;" class="mat-select-padu"
                [(ngModel)]="transition[2]">

                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
            <span class="add-padding" [ngClass]="getClassName(transition[3])" *ngIf="column.columnDef=='transition_4' ">
              <span class="text-notavailable-option"
                *ngIf="transition[3]=== 'I' || transition[3]=== 'PP'">{{PADUFullName[transition[3]]}}</span>
              <mat-select (ngModelChange)="modelChangedPadu($event,transition[3])"
                (selectionChange)="valueChange($event,'transition3')" style="max-height: 300px;" class="mat-select-padu"
                [(ngModel)]="transition[3]">

                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
            <span class="add-padding" [ngClass]="getClassName(transition[4])" *ngIf="column.columnDef=='transition_5'">
              <span class="text-notavailable-option"
                *ngIf="transition[4]=== 'I' || transition[4]=== 'PP'">{{PADUFullName[transition[4]]}}</span>
              <mat-select (ngModelChange)="modelChangedPadu($event,transition[4])"
                (selectionChange)="valueChange($event,'transition4')" style="max-height: 300px;" class="mat-select-padu"
                [(ngModel)]="transition[4]">

                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
            <span class="add-padding" [ngClass]=" getClassName(transition[5]) "
              *ngIf="column.columnDef=='transition_6' ">
              <span class="text-notavailable-option"
                *ngIf="transition[5]=== 'I' || transition[5]=== 'PP'">{{PADUFullName[transition[5]]}}</span>
              <mat-select (ngModelChange)="modelChangedPadu($event,transition[5])"
                (selectionChange)="valueChange($event,'transition5')" style="max-height: 300px;" class="mat-select-padu"
                [(ngModel)]="transition[5]">

                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="getClassName(option.value)">
                    {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
          </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="paduStatusCol"></tr>
        <tr mat-row class="example-element-row" *matRowDef="let row; columns: paduStatusCol"></tr>
      </table>
    </div>
    <div class="table-action pull-right">
      <button mat-mdc-raised-button class="addbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button 
        uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="submitForm()"
        [disabled]="PaduEditButtonStatus">
        Apply
      </button>
    </div>
  </div>
  <app-loader *ngIf="loader"
    style="position: absolute;left: 40%;top: 0%;stroke:#013B71; height: 100%; display: flex; justify-content: center; align-items: center;">
  </app-loader>

  <div class="road-map-status">
    <div class="form-container">
      <div class="layout-1">
        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Roadmap</span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Roadmap</mat-label> -->
              <mat-select class="virtual-scroll" (ngModelChange)="modelChanged($event,roadMapValue)"
                [(ngModel)]="roadMapValue" placeholder="roadMapValue" panelClass="padu-hub-class">
                <mat-option value="No Roadmap">
                  (None)
                </mat-option>
                <mat-option *ngFor="let option of RoadmapOptions" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Replacement App</span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Replacement App</mat-label> -->
              <mat-select [(ngModel)]="replacementApp" [placeholder]="replacementApp" (ngModelChange)="modelChanged($event,replacementApp)"
                class="virtual-scroll" panelClass="padu-hub-class">
                <input type="text" class="searchoption" (keyup)="keyUpSearch($event)"
                  (keydown)="$event.stopPropagation()" [(ngModel)]="searchValue" placeholder="Search " />
                <!-- <img src="../../../../assets/images/searchicon.png" style="position: absolute; right: 30px;
                              top: 4px;" /> -->
                <app-loader *ngIf="showLoader"
                  style="position: absolute;right: 0%;top: -34%;stroke:#013B71; height: 100%; display: flex; justify-content: center; align-items: center;">
                </app-loader>
                <mat-option value="">
                  (None)
                </mat-option>
                <mat-option *ngFor="let option of replacementOptions" [value]="option.app_id">
                  {{ option.app_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Initiative / Program</span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Initative / Program</mat-label> -->
              <input type="text" maxlength="125" (ngModelChange)="modelChanged($event,initiativeProgram)"
                [(ngModel)]="initiativeProgram" matInput placeholder="" />
            </mat-form-field>
          </div>
        </div>
        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Business Maintained</span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Business Maintained</mat-label> -->
              <mat-select (ngModelChange)="modelChanged($event,businessMaintained,'businessMaintained')"
                [(ngModel)]="businessMaintained" class="virtual-scroll" panelClass="padu-hub-class">
                <mat-option value="">
                  (None)
                </mat-option>
                <mat-option *ngFor="let option of YesNoOptions" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Tech/Business Alignment needed</span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Tech/Business Alignment needed</mat-label> -->
              <mat-select (ngModelChange)="modelChanged($event,alignmentNeeded)" [(ngModel)]="alignmentNeeded"
                class="virtual-scroll" panelClass="padu-hub-class">
                <mat-option value="No Mapping">
                  (None)
                </mat-option>
                <mat-option *ngFor="let option of YesNoOptions" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="layout-2">

        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Nonintegrated Tech</span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline">
              <!-- <mat-label>Nonintegrated Tech</mat-label> -->
              <mat-select (ngModelChange)="modelChanged($event,nonintegratedTech)" [(ngModel)]="nonintegratedTech"
                class="virtual-scroll" panelClass="padu-hub-class">

                <mat-option value="No Mapping">
                  (None)
                </mat-option>
                <mat-option *ngFor="let option of YesNoOptions" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="form-road-map">
          <div class="road-map-label">
            <span class="map-label">Comments </span>
          </div>
          <div class="form-field">
            <mat-form-field appearance="outline" [style.width.px]=400>
              <!-- <mat-label>Comments</mat-label> -->
              <textarea (ngModelChange)="modelChanged($event,comments)" [(ngModel)]="comments" cols="100" rows="5"
                maxlength="300" matInput placeholder=""></textarea>
            </mat-form-field>
          </div>
        </div>

        <ng-container
          *ngIf="paduData[0]['Decomm_Status'] && paduData[0]['Decomm_Status'].toLowerCase() != 'decom requested'">

          <div class="form-road-map forcat-container">
            <div class="road-map-label">
              <span class="map-label">Decomm Forecast Date</span>
            </div>
            <div class="forcast-inner" style="display:flex;">
              <div class="forcat-sec">
                <mat-form-field appearance="outline" [style.width.px]=150>
                  <!-- <mat-label>Decomm Forecast Date</mat-label> -->
                  <mat-select (ngModelChange)="modelChanged($event,decomforcastQurtr)" [(ngModel)]="decomforcastQurtr"
                    class="virtual-scroll" panelClass="padu-hub-class" (selectionChange)="onChangeQuarter($event)">
                    <mat-option value="">
                      (None)
                    </mat-option>
                    <mat-option *ngFor="let option of QuarterList"  [value]="option.value">
                      {{ option.label }}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
              <div class="forcat-sec" style="margin-left: 5px;">
                <mat-form-field appearance="outline" [style.width.px]=150 >
                  <mat-select (ngModelChange)="modelChanged($event,decomforcastyear)" [placeholder]="decomforcastyear"  [(ngModel)]="decomforcastyear"
                    class="virtual-scroll" panelClass="padu-hub-class">
                    <mat-option value="">
                      (None)
                    </mat-option>
                    <mat-option *ngFor="let option of YearsList" [value]="option.value" [disabled]="option.disable">
                      {{ option.label }}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="action-items road-map-status">
          <div class="table-action pull-right">
            <button mat-mdc-raised-button class="addbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button 
              uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="submitRoadmap()"
              [disabled]="RoadMapEditButtonStatus">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
