import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, Inject, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeCheckboxComponent } from '../../../../shared/elements/tree-checkbox/tree-checkbox.component';
import { SelectionModel } from '@angular/cdk/collections';
import { appModernizationDetails } from '../../../../config'
import { DailogModalComponent } from '../../../../shared/elements/dailog-modal/dailog-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DataTableComponent } from 'src/app/shared/elements/data-table/data-table.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { DatePipe } from '@angular/common';
import { EditableTableOverlayComponent } from '../editable-table-overlay/editable-table-overlay.component';
import { AppHubService } from '../../app-hub.service';
import { AppHubService as AppHubV4Service} from 'src/app/techopsv4/services/appHubV4Service';
import * as _ from 'lodash';
export interface TableFilterConfig {
  placeHolderText: string;
  labelText: string;
  filterCallBack: (event: any) => any;
  filterPredicate?: (item: any, filter: string) => boolean;
}


@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1, 'overflow-x': 'hidden' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class EditableTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() defaultPaduData;
  currentYear = new Date().getUTCFullYear();
  disableOtherIcon: boolean = false;
  mappedCapability = [];
  selectedLOB = ' ';
  selectedSUBLOB = ' ';
  searchOrganization: any;
  searchCapability: any;
  capabilityID: any;
  public popup: boolean = true;
  selectedCapability1 = '';
  @Input() app_ID;
  @Input() totalCapabilities: any;
  isCapabilitySelected: any;
  capabilityCounter = 0;
  @Input() openCapProposalWQReqArr = [];
  @ViewChildren('dataTableComponent') dataTableComponent: DataTableComponent;
  @ViewChild('mySelect', { static: false }) mySelect;
  selectedOrg;
  excludeModel = appModernizationDetails.excludeModel;
  filterLists: any = [];
  fromAssociateCapabilities:boolean = false;
  @ViewChild('editCpWQPopup') editCpWQPopup;
  @Input('capabilityWorkqueueDetails') capabilityWorkqueueDetails: any;
  @Input('selectedApp') selectedApp;
  capabilityData:any;
  @Output() refreshOpenRequest = new EventEmitter();
  previousSelection:Array<any> = [];
  searchfilterLists: any = [];

  associatedCapExpandedTablecolumns = [
    { columnDef: 'select', header: '', width: '4%', paddingLeft: '1% !important', cell: () => `` },
    {
      columnDef: 'organisation',
      header: 'Organization',
      width: '7%',
      type: 'html',
      textAlign: 'center',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => this.prepareorganisationName(`${element.organisation}`)
    },
    {
      columnDef: 'current',
      header: 'Current PADU',
      width: '12%',
      textAlign: 'center',
      marginLeft:'1.8vw',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.current === 'Preferred',
          'badge-padu-a-60': element.current === 'Acceptable',
          'badge-padu-d-60': element.current === 'Discouraged',
          'badge-padu-u-60': element.current === 'Unacceptable',
          'badge-padu-r-60': element.current === 'Retired',
          'badge-padu-pp-60': element.current === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.current}`
    },
    {
      columnDef: 'transition_1',
      header: `${this.currentYear + 1}`,
      width: '12%',
      textAlign: 'center',
      marginLeft:'2.7vw',
      cell: (element: any) => `${element.transition_1}`
    },
    {
      columnDef: 'transition_2',
      header: `${this.currentYear + 2}`,
      width: '12%',
      textAlign: 'center',
      marginLeft:'3.3vw',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.transition_2 === 'Preferred',
          'badge-padu-a-60': element.transition_2 === 'Acceptable',
          'badge-padu-d-60': element.transition_2 === 'Discouraged',
          'badge-padu-u-60': element.transition_2 === 'Unacceptable',
          'badge-padu-r-60': element.transition_2 === 'Retired',
          'badge-padu-pp-60': element.transition_2 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.transition_2}`
    },
    {
      columnDef: 'transition_3',
      header: `${this.currentYear + 3}`,
      width: '12%',
      textAlign: 'center',
      marginLeft:'2.9vw',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.transition_3 === 'Preferred',
          'badge-padu-a-60': element.transition_3 === 'Acceptable',
          'badge-padu-d-60': element.transition_3 === 'Discouraged',
          'badge-padu-u-60': element.transition_3 === 'Unacceptable',
          'badge-padu-r-60': element.transition_3 === 'Retired',
          'badge-padu-pp-60': element.transition_3 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.transition_3}`
    },
    {
      columnDef: 'transition_4',
      header: `${this.currentYear + 4}`,
      width: '12%',
      textAlign: 'center',
      marginLeft:'3.3vw',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.transition_4 === 'Preferred',
          'badge-padu-a-60': element.transition_4 === 'Acceptable',
          'badge-padu-d-60': element.transition_4 === 'Discouraged',
          'badge-padu-u-60': element.transition_4 === 'Unacceptable',
          'badge-padu-r-60': element.transition_4 === 'Retired',
          'badge-padu-pp-60': element.transition_4 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.transition_4}`
    },
    {
      columnDef: 'transition_5',
      header: `${this.currentYear + 5}`,
      width: '12%',
      textAlign: 'center',
      marginLeft:'2.5vw',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.transition_5 === 'Preferred',
          'badge-padu-a-60': element.transition_5 === 'Acceptable',
          'badge-padu-d-60': element.transition_5 === 'Discouraged',
          'badge-padu-u-60': element.transition_5 === 'Unacceptable',
          'badge-padu-r-60': element.transition_5 === 'Retired',
          'badge-padu-pp-60': element.transition_5 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.transition_5}`
    },
    {
      columnDef: 'transition_6',
      header: `${this.currentYear + 6}`,
      width: '12%',
      textAlign: 'center',
      marginLeft:'-1.5vw',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.transition_6 === 'Preferred',
          'badge-padu-a-60': element.transition_6 === 'Acceptable',
          'badge-padu-d-60': element.transition_6 === 'Discouraged',
          'badge-padu-u-60': element.transition_6 === 'Unacceptable',
          'badge-padu-r-60': element.transition_6 === 'Retired',
          'badge-padu-pp-60': element.transition_6 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.transition_6}`
    }
  ];

  expandedProductColumns = [
    { 
      columnDef: 'id', 
      header: 'Product ID', 
      width: '25%', 
      cell: (element: any) => `${element.productId}` 
    },
    {
      columnDef: 'productName',
      header: 'Product Name',
      width: '30%',
      cell: (element: any) => `${element.productName}`
    },
    {
      columnDef: 'productDescription',
      type:'ellipsis',
      header: 'Product Description',
      width: '44%',
      textAlign: 'left',
      cell: (element: any) =>  `${element.productDescription}`
    },
    {
      columnDef: 'empty',
      header: ' ',
      width: '1%',
      cell: () =>  ``
    },
  ]
  expandedElement;
  getCapabilityRedundency: any;
  selection = new SelectionModel<any>(true, []);
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() futurePaduDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() editSchemaConfig;
  @Input() addNewRow;
  @Input() Popupp;
  // @Input() capabilityHierarchyList = [];
  @Input() SelectAll = false;
  @Output() dataReceived: EventEmitter<any> = new EventEmitter();
  @Output() checkClick: EventEmitter<any> = new EventEmitter();
  @Output() removeNewRow: EventEmitter<any> = new EventEmitter();
  @Output() accordionremoveDisableFlag: EventEmitter<any> = new EventEmitter();

  @Input() pageSize: Array<number> = [];
  @Input() expandedElementObj: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(EditableTableOverlayComponent, { static: false }) child: EditableTableOverlayComponent;
  @ViewChild(TreeCheckboxComponent, { static: false }) treeCapability: TreeCheckboxComponent;
  @ViewChild('appCapAsscPopup', { static: false }) appCapAsscPopup;
  @ViewChild('appOrgAsscPopup', { static: false }) appOrgAsscPopup;
  @ViewChild('capAddEditPopup', { static: false }) capAddEditPopup;
  @ViewChild('capMergePopup', { static: false }) capMergePopup;
  @Input() Lob;
  @Input() SubLob;
  @Input() isDecommissionedApp;
  displayedColumns: Array<string>;
  dataSchema;
  searchField;
  cloneDataSource:any;
  openExpandEditTable: boolean = false;
  @Input() filterConfig: TableFilterConfig;
  @Input() tableConfig: any;
  @Input() columns: Array<{
    columnDef: string;
    header: string;
    width: string;
    type: string;
    spanCellStyle: () => object;
    display: string;
    cellStyle: () => object;
    cell: () => object;
  }>;
  iseditClicked = false;
  ismergeClicked = false;
  allOrganizationSelected: false;
  organizationSelected: any;

  currentOrganisations: any;
  isPrimary: any;
  editCapHierarchy: any;
  editdata: any;
  olddatasource: any
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  filterList: any;
  filValue: any = [];
  GMFilter = [];
  CPFilter = [];
  PRMFilter = [];
  Allfilter = [];
  searchValue: any;
  public pageSizes = [5, 10, 15];
  appviewData: any;
  orgData: any;
  filterCapability: any;

  @Input() isEditAccess: boolean;
  @Input() isDelegateAccess: boolean;
  @Input() businessProduct: boolean = false;
  modelData: any;
  clonedOrgList = [];
  clonedSelectedCapRow: any = {};
  openPopup = false;
  clonedSelectedCapRowMerge: any = {};
  @Input() isWQRequestViewAccess:boolean;
  @Input() selectedsublobId:any;
  constructor(
    public dialog: MatDialog,
    public toaster: ToastrService,
    public datepipe: DatePipe,
    public appHubService: AppHubService,
    public appHubV4Service:AppHubV4Service,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.searchOrganization = { key: 'org', value: '' };
    this.searchCapability = { key: 'capability', value: '' };
    this.capabilityID = data.selectedCapabilityID;
  }

  prepareorganisationName(organisationName) {
    let content = '<div><span>' + organisationName + '</span></div>';
    return content;
  }

  onOrganisationClick(event, data) {
    this.orgData = {
      OrganizationID: event.row['org_id'],
      organisationName: event.row['organisation'],
      model: data.group_model,
      capabilityID: data.selectedCapabilityID,
      capability: data.selectedCapability,
      app_id: this.app_ID
    };
    if (this.orgData && this.appOrgAsscPopup) {
      this.appOrgAsscPopup.show();
      let element =<any>document.querySelector('.nav-color');
      element.style.zIndex=1;
    }
  }

  ngOnChanges() {
    this.dataSchema = this.editSchemaConfig;
    if (!this.iseditClicked) {
      if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
        if (this.filterConfig && this.filterConfig.filterPredicate) {
          this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
        }
        this.cloneDataSource = this.dataSource;
        this.iseditClicked = false;
        this.popup = true;
      }
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort;
    }
  }

  ngAfterViewInit() {
    this.dataSchema = this.editSchemaConfig;
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }
      this.olddatasource = this.dataSource.data;
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort;
    }

  }
  ngOnInit() {
    this.allOrganizationSelected = false;
    this.organizationSelected = 0;
    this.dataSchema = this.editSchemaConfig;
    this.selectedLOB = this.Lob;
    this.selectedSUBLOB = this.SubLob;
    this.onLobChangeSubscription();
    this.displayedColumns = this.columns.map(element => element.columnDef);
    this.getModelAndCapData();

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numofRows = this.totalCapabilities;
    const numRows = this.paginator.toArray()[0].pageSize;
    const lastPageIndex = Number(this.paginator.length / this.paginator.toArray()[0].pageSize) as any;
    return (
      numSelected === numRows ||
      (parseInt(lastPageIndex) == this.paginator.toArray()[0].pageIndex && numSelected > 0 && numSelected === numofRows)
    );
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // console.log('masterToggle');
    let tempOrgIDS = [];
    this.capabilityCounter = this.capabilityCounter + 1;
    if (this.isAllSelected()) {
      this.isCapabilitySelected = { type: 'allDeSelected', orgIDS: tempOrgIDS, counter: this.capabilityCounter };
      this.selection.clear();
      this.checkClick.emit({ check: false, data: [] });
    } else {
      this.dataSource.data.forEach((row, index) => {
          if(index >= this.paginator.toArray()[0].pageIndex * this.paginator.toArray()[0].pageSize &&
          index < this.paginator.toArray()[0].pageSize * (this.paginator.toArray()[0].pageIndex + 1))
           this.selection.select(row)
      });
      this.selection.selected.forEach(selectedRow => {
        selectedRow.organisations.forEach(org => {
          tempOrgIDS.push(org.tempOrgID);
        });
      });
      this.isCapabilitySelected = { type: 'allSelected', orgIDS: tempOrgIDS, counter: this.capabilityCounter };
      this.checkClick.emit({ check: true, data: this.selection.selected });
    }
  }

  evaluateSpanSpecificClass(column, element) {
    let output = {};
    if (column.spanCellStyle) {
      output = column.spanCellStyle(element);
    }
    return output;
  }

  evaluateFunction(column, element) {
    let output = {};
    if (column.cellStyle) {
      output = column.cellStyle(element);
    }

    if (column.type === 'number') {
      output['text-align-right'] = true;
    }
    if (column.type === 'textnumber') {
      output['text-align-center'] = true;
    }
    if (column.type === 'html') {
      output['text-align-left'] = true;
    }
    return output;
  }

  expand(data) {
    let index = this.dataSource.data.indexOf(data);
    this.dataSource.data[index].expand = true;
    if(data.organisations){
      data.orgdatasource = new MatTableDataSource(data.organisations.sort((data1, data2) => {
        if (data1.organisation.toLowerCase() > data2.organisation.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      }))
    }
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.dataSource.paginator = this.paginator.toArray()[0];
    return true;
  }
  getCapabiltyInfo(element): string {
    let str = element.capabilityL0 + "(L0)" + " \n" + element.capabilityL1 + "(L1)" + " \n";
    if (element.capabilityL2 != 'NULL' && element.capabilityL2 != 'null' && element.capabilityL2 != null) {
      str = str + element.capabilityL2 + '(L2)' + ' \n';
      if (element.capabilityL3 != 'NULL' && element.capabilityL3 != 'null' && element.capabilityL3 != null) {
        str = str + element.capabilityL3 + '(L3)';
      }
    }
    // str = str.replace(/\n/g, '<br />');
    return str;
  }
  getCapabiltyLevel(element): string {
    let str = 'L1';
    if (element.capabilityL2 != 'NULL' && element.capabilityL2 != 'null' && element.capabilityL2 != null) {
      str = 'L2';
      if (element.capabilityL3 != 'NULL' && element.capabilityL3 != 'null' && element.capabilityL3 != null) {
        str = 'L3';
      }
    }
    return str;
  }

  loadDropdown(element) {
    if(this.isEditAccess) {
      this.popup = false;
      this.expandTableEdit(element);
    }
    else if(this.isDelegateAccess) {
      this.showWQpopup(element);
    }
  }
  loadDropdownMerge(element) {
    this.showPopupMerge(element);
  }

  expandTableEdit(element) {
    this.currentOrganisations = element.organisations;
    this.selectedOrg = element.organization;
    this.isPrimary = element.primary_status;
    this.collap(element);

    this.filterCapability = this.dataSource.data.filter(d => {
      if (d.group_model === element.group_model && d.organization === element.organization) {
        return d.capability;
      }
    });
    this.mappedCapability = this.filterCapability.map(d => d.capability);
    this.selectedCapability1 = element.capability || '';
    this.editCapHierarchy = {
      capabilityL0: element.capabilityL0,
      capabilityL1: element.capabilityL1,
      capabilityL2: element.capabilityL2,
      capabilityL3: element.capabilityL3,
      capabilityL0Id: element.capabilityL0Id,
      capabilityL1Id: element.capabilityL1Id,
      capabilityL2Id: element.capabilityL2Id,
      capabilityL3Id: element.capabilityL3Id,
    };
    this.showpopup('edit', element);
  }
  removeRow() {
    this.removeNewRow.emit();
  }
  selecttoggle(event, row) {
    let orgIDList = [];
    if (row.tempOrgID && row.tempOrgID > 0) {
      orgIDList.push(row.tempOrgID);
    }
    this.capabilityCounter = this.capabilityCounter + 1;
    if (this.selection.selected.length > 0) {
      this.checkClick.emit({ check: true, data: this.selection.selected });
    } else {
      this.checkClick.emit({ check: false, data: [] });
    }
    if (event && event.checked) {
      this.isCapabilitySelected = { type: 'selected', orgIDS: orgIDList, counter: this.capabilityCounter };
    } else {
      this.isCapabilitySelected = { type: 'unselected', orgIDS: orgIDList, counter: this.capabilityCounter };
    }
  }

  getSelectedRow() {
    return this.selection.selected;
  }
  onLobChangeSubscription() {
    this.appHubService.lobChangeSubject.subscribe(res => {
      this.selectedLOB = res.selectedLOB;
      this.selectedSUBLOB = res.selectedSUBLOB;
    });
  }
  searchOrganizationByName(event) {
    this.searchOrganization = { key: 'org', value: event.target.value };
  }
  searchCapabilityByName(event) {
    this.searchCapability = { key: 'capability', value: event.target.value };
  }

  navigateToViewApplications(element) {
    this.appviewData = {
      capabilityID: element.selectedCapabilityID,
      capability: element.selectedCapability,
      model: element.group_model,
      app_ID: this.select
    };
    if (this.appviewData) {
      this.appCapAsscPopup.show();
      let element =<any>document.querySelector('.nav-color');
      element.style.zIndex=1;
    }
  }

  primaryUpdate(element, isDecommissionedApp) {
    if (isDecommissionedApp) {
      return;
    }
    const subTitliMsg =
      element.primary_status == 'Yes'
        ? 'Please click continue to remove the selected capability as Primary'
        : 'Please click continue to make the selected capability Primary';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '200px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: '                          Primary Update Confirmation!',
      subTitle: subTitliMsg,
      buttonName: 'Continue'
    };

    const dialogRef = this.dialog.open(DailogModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'save') {
        let body = {
          cond: {
            capabilityL0Id: element.capabilityL0Id,
            capabilityL1Id: element.capabilityL1Id,
            capabilityL2Id: element.capabilityL2Id,
            capabilityL3Id: element.capabilityL3Id,
            group_model: element.group_model,
            app_id: this.app_ID.app_id,
          },
          data: {
            updatedById: localStorage.getItem('MsId') || sessionStorage.getItem('MsId'),
            updatedByName: JSON.parse(localStorage.getItem('currentUser')) || sessionStorage.getItem('currentUser'),
            updatedAt: new Date().toISOString(),
            primary_status: element.primary_status == 'Yes' ? 'No' : 'Yes',
            updatedColumns: 'Primary Status'
          }
        };
        // console.log(body);
        this.appHubV4Service.updatePrimarystatus(body).subscribe(
          (res: any) => {
            if (res) {
              this.toaster.success('Successfully updated.', '', { timeOut: 10000, positionClass: 'toast-top-center' });
              this.appHubV4Service.notifyAboutprimaryChange();
            }
          },
          err => {
            console.log(err, 'error occured while update');
            this.toaster.error('Internal server error.', '', { timeOut: 10000, positionClass: 'toast-top-center' });
          }
        );
      }
    });
  }

  checkboxClick(event) {
    if (this.selection.selected.length > 0 && event.tempOrgID) {
      let filterSelection = this.selection.selected.filter(i => i.tempOrgID == event.tempOrgID);
      if (
        filterSelection &&
        filterSelection.length > 0 &&
        filterSelection[0].organisations.length != event.data.length
      ) {
        this.selection.selected.forEach(selectedItem => {
          if (selectedItem.tempOrgID == event.tempOrgID) {
            this.selection.deselect(selectedItem);
            this.checkClick.emit({ check: false, data: this.selection.selected });
          }
        });
      }
    }

    this.dataSource.data.forEach(row => {
      if (row.tempOrgID == event.tempOrgID && row.organisations.length == event.data.length) {
        this.selection.select(row);
        row.indeterminate = false;
      }
      if (row.tempOrgID == event.tempOrgID && row.organisations.length != event.data.length) {
        row.indeterminate = true;
      }
      if (row.tempOrgID == event.tempOrgID && event.data.length == 0) {
        row.indeterminate = false;
      }
      if (row.tempOrgID == event.tempOrgID) {
        event.parentData = row;
        event.parentData['organisations_duplicate'] = [...event.data];
      }
    });
    if (event && event.selectAll) {
      this.selection.clear();
    }
    this.accordionremoveDisableFlag.emit(event);
    this.allOrganizationSelected = event.selectAll;
    this.organizationSelected = event.data.length;
  }

  showpopup(type, row) {
    this.openPopup = true;
    if (type == 'edit') {
      this.iseditClicked = true;
      this.clonedSelectedCapRow = _.omit(row, 'orgdatasource');
      this.clonedOrgList = _.cloneDeep(this.currentOrganisations);
    }
    else {
      this.iseditClicked = false;
      this.clonedSelectedCapRow = {};
      this.clonedOrgList = [];
    }
    this.capAddEditPopup.show();
    let element =<any>document.querySelector('.nav-color');
    element.style.zIndex=1;
    
  }
  showPopupMerge(row){
    this.clonedSelectedCapRowMerge = _.omit(row, 'orgdatasource');
    this.ismergeClicked = true;
    this.capMergePopup.show();
    let element =<any>document.querySelector('.nav-color');
    element.style.zIndex=1;
  }

  Saveupdate() {
    this.capAddEditPopup.hide();
    this.disableOtherIcon = false;
  }
  SaveupdateMerge() {
    this.capMergePopup.hide();
    this.disableOtherIcon = false;
  }
  hidePopup(type){
    if (type == 'edit') {
      this.iseditClicked = this.iseditClicked ? false : this.iseditClicked;
    }
    // if (type == 'merge') {
    //   this.ismergeClicked = this.ismergeClicked;
    // }
    this.disableOtherIcon = false;
  }

  collap(data) {
    let index = this.dataSource.data.indexOf(data);
    if (index > -1) {
      this.dataSource.data[index].expand = false;
    }
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.dataSource.paginator = this.paginator.toArray()[0];
    return true;
  }

  filterDropdown(column) {
    this.searchValue = '';

    if (this.Allfilter.length == 0) {
      if (column.header == 'Group Model') {
        this.filterList = this.olddatasource.map((item) => { return item.group_model });
        this.filValue = this.GMFilter;
        this.trigger.toArray()[0].openMenu();

      } if (column.header == 'Capability') {
        this.filterList = this.olddatasource.map((item) => { return item.selectedCapability });
        this.filValue = this.CPFilter;
        this.trigger.toArray()[1].openMenu();
      } if (column.header == 'Primary') {
        this.filterList = this.olddatasource.map((item) => { return item.primary_status });
        this.filValue = this.PRMFilter;
        this.trigger.toArray()[2].openMenu();
      }
      this.filterList = new Set([...this.filterList]);
      this.filterLists = this.filterList;
    } else {
      if (column.header == 'Group Model') {
        this.filterList = this.dataSource.data.map((item) => { return item.group_model });
        this.filValue = this.GMFilter;
        this.trigger.toArray()[0].openMenu();
      } if (column.header == 'Capability') {
        this.filterList = this.dataSource.data.map((item) => { return item.selectedCapability });
        this.filValue = this.CPFilter;
        this.trigger.toArray()[1].openMenu();
      } if (column.header == 'Primary') {
        this.filterList = this.dataSource.data.map((item) => { return item.primary_status });
        this.filValue = this.PRMFilter;
        this.trigger.toArray()[2].openMenu();
      }
      this.filterList = new Set([...this.filterList]);
      this.filterLists = this.filterList;
    }
  }

  applyLocalFilter() {
    let filterdata = this.olddatasource;
    this.Allfilter.forEach((e1) => {
      if (e1.column == 'Group Model') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.group_model));
          this.filterList = filterdata.map((item) => { return item.group_model });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[0].closeMenu();
        }
      } if (e1.column == 'Capability') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.selectedCapability));
          // console.log(filterdata)
          this.filterList = filterdata.map((item) => { return item.selectedCapability });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[1].closeMenu();

        }
      } if (e1.column == 'Primary') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.primary_status));
          this.filterList = filterdata.map((item) => { return item.primary_status });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[2].closeMenu();
        }
      }
    });
    if (this.Allfilter.length == 0) {
      this.dataSource = new MatTableDataSource(this.olddatasource);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort;
    }
    this.trigger.toArray()[0].closeMenu();
    this.trigger.toArray()[1].closeMenu();
    this.trigger.toArray()[2].closeMenu();

  }
  clearLocalFilter(column) {
    this.previousSelection = [];
    this.trigger.toArray()[0].closeMenu();
    this.trigger.toArray()[1].closeMenu();
    this.trigger.toArray()[2].closeMenu();
    if (column.header == 'Group Model') {
      this.GMFilter = [];
      this.Allfilter.forEach((a, i) => { if (a.column == 'Group Model') this.Allfilter.splice(i, 1) });
    }
    if (column.header == 'Capability') {
      this.CPFilter = [];
      this.Allfilter.forEach((a, i) => { if (a.column == 'Capability') this.Allfilter.splice(i, 1) });
    }
    if (column.header == 'Primary') {
      this.PRMFilter = [];
      this.Allfilter.forEach((a, i) => { if (a.column == 'Primary') this.Allfilter.splice(i, 1) });
    }
    this.applyLocalFilter();
  }

  selectionChange(event, column) {
    let newevent:any;
    if (column.header == 'Group Model') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Group Model') this.Allfilter.splice(i, 1) });
      newevent = this.checkPrevious(event,this.GMFilter);
      if (newevent.source._value.length > 0) {
        this.GMFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Group Model', value: newevent.source._value });
      } else {
        this.GMFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    }
    if (column.header == 'Capability') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Capability') this.Allfilter.splice(i, 1) });
      newevent = this.checkPrevious(event,this.CPFilter);
      if (newevent.source._value.length > 0) {
        this.CPFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Capability', value: newevent.source._value });
      } else {
        this.CPFilter = [];
      }
      this.trigger.toArray()[1].openMenu();
    }
    if (column.header == 'Primary') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Primary') this.Allfilter.splice(i, 1) });
      newevent = this.checkPrevious(event,this.PRMFilter);
      if (newevent.source._value.length > 0) {
        this.PRMFilter = event.source._value;
        this.Allfilter.push({ column: 'Primary', value: event.source._value });
      } else {
        this.PRMFilter = [];
      }
      this.trigger.toArray()[2].openMenu();
    }
  }
  clearallFilter() {
    this.trigger.toArray()[0].closeMenu();
    this.trigger.toArray()[1].closeMenu();
    this.trigger.toArray()[2].closeMenu();
    this.GMFilter = [];
    this.CPFilter = [];
    this.PRMFilter = [];
    this.Allfilter = [];
    this.dataSource = new MatTableDataSource(this.olddatasource);
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort;

  }
  search(query: string) {
    let result = this.select(query);
    this.searchfilterLists = result;
  }

  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.filterList) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  isPrimaryCapability(element) {
    return (element.primary_status === 'Yes');
  }

  closeModal(){
    let element =<any>document.querySelector('.nav-color');
    element.style.zIndex=2;
  }
  getModelAndCapData(){
    this.appHubV4Service.getGroupmodelV1().subscribe(res => {
      this.modelData = res;
    });
  }

  showWQpopup(el){
    this.capabilityData = el;
    this.fromAssociateCapabilities = true;
    this.editCpWQPopup.show();
  }

  hideModal(){
    this.fromAssociateCapabilities = false;
    this.capabilityData = {};
  }

  hideCPworkqueuePopup(){
    this.fromAssociateCapabilities = false;
    this.capabilityData = {};
    this.editCpWQPopup.hide();
    this.refreshOpenRequest.emit('refresh');
  }

  showWQList(event){
    if (event["message"] === 'show open work queue request'){
    let wqlist = event.requestIDArr;
    this.navigateToWQlisDetails(wqlist);
    }else if(event['message']='show lob maintenance page'){
      this.routeToLOBmaintenance();
    }
  }

  navigateToWQlisDetails(wqlist){
    wqlist = wqlist.map(val=>val['Request_Id']);
    const baseUrl = window.location.origin;
    const urlparams = wqlist.join(',');
    const wqUrl = baseUrl + '/work-queues?req=wq&id=' + JSON.stringify(urlparams);
    window.open(wqUrl, '_blank');
  }

  routeToLOBmaintenance(){
    const baseUrl = window.location.origin;
    const wqUrl = baseUrl + '/maintenanceV4/lob/?subLOBId=' + this.selectedsublobId;
    window.open(wqUrl, '_blank');
  }

  checkPrevious(event,prevArr:any = []) {
    this.previousSelection = prevArr;
    const filterList:Array<any> = [...this.filterLists];
    if (this.previousSelection && this.previousSelection.length > 0) {
      let isPrevValueUnselected = true;
      for (let i = 0; i < this.previousSelection.length; i++) {
        //If previous selection is in DropDownData and not in event then only add previous selection in Event Values(Server Side Search)
        let uncheckedElementName = event.options[0]._value;
        let unchekcedStatus = event.options[0]._selected;
        if (!(unchekcedStatus) && isPrevValueUnselected) { //check for unchecked elements
          if (this.previousSelection[i] == uncheckedElementName) //check for element which is unchecked
          {
            this.previousSelection.splice(i, 1);
            i--;
            isPrevValueUnselected = false;
          }
        } else if (filterList.includes(this.previousSelection[i]) && event.source._value.length > 0
          && !event.source._value.includes(this.previousSelection[i]))  //Checked cond1
        {
          event.source._value.push(this.previousSelection[i]);
        }
      }
    }
    if (event.source._value.length == 0) {//Unchecked cond2: element is unchecked after search
      for (let i = 0; i < this.previousSelection.length; i++) {
        if (filterList.includes(this.previousSelection[i])) {
          event.source._value.push(this.previousSelection[i]);
        }
      }
    }
    this.previousSelection = [... new Set(event.source._value)];
    return event
  }

}
