<!-- <div class="application-demographic-pop-up tile-card-structure">
    <div class="row m-0">
        <div class="col-4">
            <label class="section-name tabl-header tabl-header-font">
                Line Of Business
            </label>
            <mat-form-field appearance="outline" class="select-dropdown">
                <mat-select panelClass="global-select-panel" (selectionChange)="onLobChanged($event)"
                    [(ngModel)]="selectedLobObj">
                <div>
                    <input matinput type="text" [(ngModel)]="searchTxt" class="mat-input-search" 
                        placeholder="Search"  (keydown)="$event.stopPropagation()">
                        (keyup)="searchLobByName($event)"
                </div>
                <div class="table-data-font">
                    <ng-container>
                        <mat-option class="global-mat-option" *ngFor="let lobItem of lobItemList | appAttrSearch : searchTxt" title="{{lobItem.lob}}" [value]="lobItem"><label style="margin:0%">{{ lobItem.lob }}</label></mat-option>
                    </ng-container>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="selectedLobObj != null">
            <label class="section-name tabl-header tabl-header-font">
                Sub Line Of Business
            </label>
            <mat-form-field appearance="outline" class="select-dropdown">
                <mat-select panelClass="global-select-panel" (keydown)="$event.stopPropagation()" (selectionChange)="onSubLobChanged()"
                    [(ngModel)]="selectedSUBLOB" #filterSelect="ngModel" [disabled]="subLobItemList.length  == 0">
                <div>
                    (keyup)="searchSubLobByName($event)"
                    <input matinput type="text" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxtSubLob" class="mat-input-search" placeholder="Search">
                </div>
                <div class="table-data-font">
                    <ng-container>
                        <mat-option class="global-mat-option" *ngFor="let lobItem of subLobItemList | appAttrSearch : searchTxtSubLob" title="{{lobItem.sub_lob}}" [value]="lobItem.sub_lob">
                            <label style="margin:0%" *ngIf="lobItem.sub_lob">{{ lobItem.sub_lob }}</label>
                        </mat-option>
                    </ng-container>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-4">
            <label class="section-name tabl-header tabl-header-font">
                Category
            </label>
            <mat-form-field appearance="outline" class="select-dropdown">
                <mat-select panelClass="global-select-panel" (selectionChange)="onCategoryChanged($event)"
                    [(ngModel)]="selectedCategoryObj">
                <div>
                    <input matinput type="text" [(ngModel)]="searchCatTxt" class="mat-input-search" 
                        placeholder="Search" (keydown)="$event.stopPropagation()">
                </div>
                <div class="table-data-font">
                    <ng-container>
                        <mat-option class="global-mat-option" *ngFor="let category of categoryDropdownValues | appAttrSearch : searchCatTxt" title="{{category.Name}}" [value]="category"><label style="margin:0%">{{ category.Name }}</label></mat-option>
                    </ng-container>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <label class="section-name tabl-header tabl-header-font">
                Application Type
            </label>
            <mat-form-field appearance="outline" class="select-dropdown">
                <mat-select panelClass="global-select-panel" (selectionChange)="onAppTypeChanged($event)"
                    [(ngModel)]="selectedAppTypeObj" multiple>
                    <div>
                        <input matinput type="text" [(ngModel)]="searchAppTypeTxt" class="mat-input-search" 
                            placeholder="Search" (keydown)="$event.stopPropagation()">
                    </div>
                    <div class="attr-all-checkbox">
                        <mat-checkbox  (change)="selectAllCategories($event)" [checked]="allAppTypeSelected" 
                            [indeterminate]="selectedAppTypeObj.length > 0 && selectedAppTypeObj.length != appTypeDropdownValues.length"
                            (click)="$event.stopPropagation()" style="padding-left: 5%;padding-top: 2%;">
                            <label style="margin:0%">Select All</label>
                        </mat-checkbox>
                    </div>
                    <div class="table-data-font">
                        <ng-container>
                            <mat-option class="global-mat-option" *ngFor="let appType of appTypeDropdownValues | appAttrSearch : searchAppTypeTxt" title="{{appType.Name}}" [value]="appType"><label style="margin:0%">{{ appType.Name }}</label></mat-option>
                        </ng-container>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="hostingEnvList">
            <app-attributes-dropdown [dropdownLabel]="hostingEnvLabel" [dropdownData]="appHostingEnvDropdownValues"
                (selectionChanged)="onHostingEnvUpdated($event)" [selectedDropdownValues]="selectedAppHostingEnvList" [hostingEnvList]="hostingEnvList"></app-attributes-dropdown>  
        </div>
    </div>
    <div class="row m-0">
        <div class="col-12">
            <button  mat-mdc-raised-button class="float-right default-Button-Class uitk-c-button uitk-c-button--default 
                      uitk-u-margin-right--base uitk-u-margin-bottom--xs button-Margin button-size button-color" [disabled]="!valueUpdated"
                (click)="saveChanges();$event.stopPropagation();">Apply
            </button>
        </div>
    </div>
</div> -->

<ng-container *ngIf="showOpenRequestMessage">
    <p class="note-margin" *ngIf="workQueueSelected?.Id == 5">
        <span *ngIf="isWQRequestViewAccess">There is an open request for this capability. Therefore a New Request cannot be created. Click <a class="link-click" style="cursor : pointer" (click)="navigateToRequestDetails()" target="blank"> here </a> to view the Request details.</span>
        <span *ngIf="!isWQRequestViewAccess">There is an open Request for this capability, Please contact <a class="link-click" style="cursor : pointer" (click)="navigateToRequestDetails()" target="blank"> Architects </a> for more details.</span>
    </p>
    <p class="note-margin"  *ngIf="workQueueSelected?.Id != 5">
        There is an Open Request for this Application. Therefore a New Request cannot be created. Click <a class="link-click" style="cursor : pointer" (click)="navigateToRequestDetails()" target="blank"> here </a> to view the Request details.
    </p>
</ng-container>
<ng-container *ngIf="RoleAccessMessage['showRoleMessage']">
    <p class="note-margin note-highlighter">
        {{RoleAccessMessage['Message']}}
    </p>
</ng-container>


<div class="application-demographic-pop-up tile-card-structure">
    <ng-container *ngIf="fromAppHubEdit">
        <div class="row m-0 col-8 demographics-radio-buttons-margin">
            <mat-radio-group class="app-demgraphics-radio-buttons" [(ngModel)]="selectedOption" (change)="selectionTypeChange($event)">
                <mat-radio-button color="primary" [disabled]="disableLobRadioBtn" value='lob' [checked]="lobChecked">LOB</mat-radio-button>
                <mat-radio-button color="primary" [disabled]="!sharedAuthService.getAuthorization(currentLobDetails.currentLob,currentLobDetails.currentSubLob, 'Apphub')" value='attributes' [checked]="attributeChecked">Attributes</mat-radio-button>
            </mat-radio-group>
        </div>
    </ng-container>
 
    <ng-container *ngIf="(lobChecked && fromAppHubEdit) || fromWorkQueue || fromPADUEdit">
        <div class="row m-0">
            <div class="col-3 demographics-dropdowns">
                <label class="section-name tabl-header tabl-header-font">
                    Select Work Queue
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                </label>
                <mat-form-field appearance="outline" class="select-dropdown demographics-lob-drodown">
                    <mat-select [disabled]="fromAppHubEdit || fromPADUEdit" panelClass="global-select-panel" [(ngModel)]="workQueueSelected" 
                    (selectionChange)="onWorkQueueChanged($event)" placeholder="Select Work Queue Type">
                        <div>
                            <input matinput type="text" [(ngModel)]="searchTxtWorkQueue" class="mat-input-search" 
                                placeholder="Search"  (keydown)="$event.stopPropagation()" (keyup)="searchWorkQueueByName()" (blur)="resetInputValueForSelectingWorkqueue()">
                        </div>
                        <div class="table-data-font">
                            <ng-container *ngIf="fromWorkQueue">
                                <mat-option class="global-mat-option" *ngFor="let workQueue of workQueueTypes" title="{{workQueue.Work_Queue_Name}}" [value]="workQueue"><label style="margin:0%">{{ workQueue.Work_Queue_Name }}</label></mat-option>
                            </ng-container>
                            <ng-container *ngIf="fromAppHubEdit || fromPADUEdit">
                                <mat-option class="global-mat-option" title="{{workQueueSelected.Work_Queue_Name}}" [value]="workQueueSelected"><label style="margin:0%">{{ workQueueSelected.Work_Queue_Name }}</label></mat-option>
                            </ng-container>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <div [class.workqueues-loader]="!fromAssociateCapabilities" class="stk-table-loader " *ngIf="showLoader">
    </div>
    <ng-container *ngIf="((lobChecked && fromAppHubEdit) || fromWorkQueue || fromPADUEdit) && (workQueueSelected.Id == 1 || (workQueueSelected.Id == 4)) !showLoader">
        <div class="row my-3 mx-0">
            <div class="col-2 demographics-dropdowns demographics-dropdowns-appId">
                <label class="section-name tabl-header tabl-header-font demographics-app-id-label">
                    Select Application
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                </label>
                <mat-form-field appearance="outline" class="select-dropdown demographics-appId-drodown">
                    <mat-select [disabled]="fromAppHubEdit || fromPADUEdit" panelClass="global-select-panel" 
                    (selectionChange)="loadLobDetails('select' ,$event)" [(ngModel)]="applicationId" [placeholder]=" applicationId ? applicationId : 'Select an Application'">
                        <div>
                            <input matinput type="text" [(ngModel)]="appSearch" class="mat-input-search" 
                                placeholder="Search for an Application Name or ID"  (keydown)="$event.stopPropagation()" (keyup)="searchApplication($event)" (blur)="resetInputValueForApplicationList()">
                        </div>
                        <div class="table-data-font">
                            <ng-container *ngIf="fromWorkQueue">
                                <mat-option class="global-mat-option" *ngFor="let app of appList" title="{{ app.app_id }} - {{ app.app_name }}"  [value]="app.app_id">
                                    <label style="margin:0%">{{ app.app_id }} - {{ app.app_name }}</label></mat-option>
                            </ng-container>
                            <ng-container *ngIf="fromAppHubEdit || fromPADUEdit">
                                <mat-option class="global-mat-option" title="{{ applicationId }}" [value]="applicationId">
                                    <label style="margin:0%">{{ applicationId }}</label></mat-option>
                            </ng-container>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="workQueueSelected.Id == 1">
                <div class="col-md-2 col-sm-4 col-lg-2 col-xl-2 col-xs-2 lob-edit-lobName">
                    <label class="section-name tabl-header tabl-header-font">
                        Current LOB
                    </label>
                    <span title="{{ currentLobDetails.currentLob }}">
                        <p class="edit-defaults demographics-lob-default-name">{{ currentLobDetails.currentLob }}</p>
                    </span>
                </div>
                <div class="col-md-2 col-sm-4 col-lg-2 col-xl-2 col-xs-2 lob-edit-lobName demographics-sub-lob-name">
                    <label class="section-name tabl-header tabl-header-font">
                        Current Sub LOB
                    </label>
                    <span title="{{ currentLobDetails.currentSubLob }}">
                        <p class="edit-defaults demographics-lob-default-name">{{ currentLobDetails.currentSubLob }}</p>
                    </span>
                </div>
                <div class="col-3 demographics-dropdowns demographics-dropdowns-proposed-lob">
                    <label class="section-name tabl-header tabl-header-font">
                        Proposed LOB
                        <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    </label>
                    <mat-form-field appearance="outline" class="select-dropdown demographics-lob-drodown">
                        <mat-select panelClass="global-select-panel" (selectionChange)="onLobChanged($event)"
                            [(ngModel)]="proposedLOB" [disabled]="(!applicationId || disableProposedLob) || (fromAppHubEdit && disableLobRadioBtn)" placeholder="Select an LOB">
                        <div>
                            <input matinput type="text" [(ngModel)]="searchTxt" class="mat-input-search" 
                                placeholder="Search"  (keydown)="$event.stopPropagation()" (blur)="resetInputValueForProposedLOB()">
                        </div>
                        <div class="table-data-font">
                            <ng-container>
                                <mat-option class="global-mat-option" *ngFor="let lobItem of lobItemList | appAttrSearch : searchTxt" title="{{lobItem.lob}}" [value]="lobItem">
                                    <label style="margin:0%">{{ lobItem.lob }}</label></mat-option>
                            </ng-container>
                        </div>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3 demographics-dropdowns demographics-dropdowns-prop-subLob">
                    <label class="section-name tabl-header tabl-header-font">
                        Proposed Sub LOB
                        <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    </label>
                    <mat-form-field appearance="outline" class="select-dropdown">
                        <mat-select panelClass="global-select-panel" (keydown)="$event.stopPropagation()" (selectionChange)="onSubLobChanged($event)"
                            [(ngModel)]="proposedSubLOB" #filterSelect="ngModel" [disabled]="(subLobItemList && subLobItemList.length == 0) || !proposedLOB" placeholder="Select a Sub LOB">
                        <div>
                            <input matinput type="text" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxtSubLob" class="mat-input-search" placeholder="Search" (blur)="resetInputValueForProposedSUBLOB()">
                        </div>
                        <div class="table-data-font">
                            <ng-container *ngFor="let subLobItem of subLobItemList | appAttrSearch : searchTxtSubLob">
                                <mat-option class="global-mat-option"  title="{{subLobItem.sub_lob}}" [value]="subLobItem"
                                *ngIf="subLobItem.SubLobID != this.currentLobDetails.currentSubLobId">
                                    <label style="margin:0%" *ngIf="subLobItem.sub_lob">{{ subLobItem.sub_lob }}</label>
                                </mat-option>
                            </ng-container>
                        </div>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <!--PADU UPDATE UI logic starts-->
            <ng-container *ngIf="workQueueSelected.Id == 4 && applicationId && showPADUFields">
                    <div class="col-12 col-md-12 col-sm-12" style="margin-top:1%">
                        <div class="row" [ngClass]="{'disable-dropdowns' : showOpenRequestMessage == true}" class="row padu-text-align padutable"
                            style="margin-left:0">
                            <div class="mat-tile-1st-col padutableColumn">
                                <ng-container>
                                    <label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear}}</span></label>
                                    <p class="margin-Left-first-column-withoutflag clickdropdown" (click)="openPaduDropdown('paduSelect')"
                                        [ngClass]="{
                                                                              'paduandRoadMapEdit-global-badge-padu-p ': transition[0] === 'P',
                                                                              'paduandRoadMapEdit-global-badge-padu-a': transition[0] === 'A',
                                                                              'paduandRoadMapEdit-global-badge-padu-d':transition[0] === 'D',
                                                                              'paduandRoadMapEdit-global-badge-padu-u':transition[0] === 'U',
                                                                              'paduandRoadMapEdit-global-badge-padu-r': transition[0] === 'R',
                                                                              'paduandRoadMapEdit-global-badge-padu-i': transition[0] === 'I',
                                                                              'paduandRoadMapEdit-global-badge-padu-pp': transition[0] === 'PP',
                                                                              'paduandRoadMapEdit-global-badge-padu-tbr': transition[0] === 'TBR'
                                                                              }">{{getPADUFullName(transition[0])}}
                                        <mat-select #paduSelect *ngIf="currentPADUEdit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[0],'transition[0]')"
                                            [(ngModel)]="transition[0]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="padutableColumn">
                                <ng-container><label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear+1}}</span>
                                    </label>
                                    <p (click)="openPaduDropdown('paduSelectYearWise')" class="marginLeft clickdropdown" [ngClass]="{
                                                                          'paduandRoadMapEdit-global-badge-padu-p ': transition[1] === 'P',
                                                                          'paduandRoadMapEdit-global-badge-padu-a': transition[1] === 'A',
                                                                          'paduandRoadMapEdit-global-badge-padu-d':transition[1] === 'D',
                                                                          'paduandRoadMapEdit-global-badge-padu-u':transition[1] === 'U',
                                                                          'paduandRoadMapEdit-global-badge-padu-r': transition[1] === 'R',
                                                                          'paduandRoadMapEdit-global-badge-padu-i': transition[1] === 'I',
                                                                          'paduandRoadMapEdit-global-badge-padu-pp': transition[1] === 'PP',
                                                                          'paduandRoadMapEdit-global-badge-padu-tbr': transition[1] === 'TBR'
                                                                          }">{{getPADUFullName(transition[1])}}
                                        <mat-select #paduSelectYearWise *ngIf="transition_1_Edit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[1],'transition[1]')"
                                            [(ngModel)]="transition[1]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="padutableColumn">
                                <ng-container>
                                    <label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear+2}}</span>
                                    </label>
                                    <p class="marginLeft clickdropdown" (click)="openPaduDropdown('paduSelectYearWiseSecond')" [ngClass]="{
                                                                              'paduandRoadMapEdit-global-badge-padu-p ': transition[2] === 'P',
                                                                              'paduandRoadMapEdit-global-badge-padu-a': transition[2] === 'A',
                                                                              'paduandRoadMapEdit-global-badge-padu-d':transition[2] === 'D',
                                                                              'paduandRoadMapEdit-global-badge-padu-u':transition[2] === 'U',
                                                                              'paduandRoadMapEdit-global-badge-padu-r': transition[2] === 'R',
                                                                              'paduandRoadMapEdit-global-badge-padu-i': transition[2] === 'I',
                                                                              'paduandRoadMapEdit-global-badge-padu-pp': transition[2] === 'PP',
                                                                              'paduandRoadMapEdit-global-badge-padu-tbr': transition[2] === 'TBR'
                                                                              }">{{getPADUFullName(transition[2])}}
                                        <mat-select #paduSelectYearWiseSecond *ngIf="transition_2_Edit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[2],'transition[2]')"
                                            [(ngModel)]="transition[2]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="padutableColumn">
                                <ng-container>
                                    <label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear+3}}</span>
                                    </label>
                                    <p class="marginLeft clickdropdown" (click)="openPaduDropdown('paduSelectYearWiseThird')" [ngClass]="{
                                                                              'paduandRoadMapEdit-global-badge-padu-p ': transition[3]=== 'P',
                                                                              'paduandRoadMapEdit-global-badge-padu-a': transition[3]=== 'A',
                                                                              'paduandRoadMapEdit-global-badge-padu-d':transition[3]=== 'D',
                                                                              'paduandRoadMapEdit-global-badge-padu-u':transition[3]=== 'U',
                                                                              'paduandRoadMapEdit-global-badge-padu-r': transition[3]=== 'R',
                                                                              'paduandRoadMapEdit-global-badge-padu-i': transition[3]=== 'I',
                                                                              'paduandRoadMapEdit-global-badge-padu-pp': transition[3]=== 'PP',
                                                                              'paduandRoadMapEdit-global-badge-padu-tbr': transition[3]=== 'TBR'
                                                                              }">{{getPADUFullName(transition[3])}}
                                        <mat-select #paduSelectYearWiseThird *ngIf="transition_3_Edit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[3],'transition[3]')"
                                            [(ngModel)]="transition[3]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="padutableColumn">
                                <ng-container><label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear+4}}</span>
                                    </label>
                                    <p class="marginLeft clickdropdown" (click)="openPaduDropdown('paduSelectYearWiseForth')" [ngClass]="{
                                                                          'paduandRoadMapEdit-global-badge-padu-p ': transition[4]=== 'P',
                                                                          'paduandRoadMapEdit-global-badge-padu-a': transition[4]=== 'A',
                                                                          'paduandRoadMapEdit-global-badge-padu-d':transition[4]=== 'D',
                                                                          'paduandRoadMapEdit-global-badge-padu-u':transition[4]=== 'U',
                                                                          'paduandRoadMapEdit-global-badge-padu-r': transition[4]=== 'R',
                                                                          'paduandRoadMapEdit-global-badge-padu-i': transition[4]=== 'I',
                                                                          'paduandRoadMapEdit-global-badge-padu-pp': transition[4]=== 'PP',
                                                                          'paduandRoadMapEdit-global-badge-padu-tbr': transition[4]=== 'TBR'
                                                                          }">{{getPADUFullName(transition[4])}}
                                        <mat-select #paduSelectYearWiseForth *ngIf="transition_4_Edit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[4],'transition[4]')"
                                            [(ngModel)]="transition[4]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="padutableColumn">
                                <ng-container>
                                    <label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear+5}}</span>
                                    </label>
                                    <p class="marginLeft clickdropdown" (click)="openPaduDropdown('paduSelectYearWiseFifth')" [ngClass]="{
                                                                              'paduandRoadMapEdit-global-badge-padu-p ': transition[5]=== 'P',
                                                                              'paduandRoadMapEdit-global-badge-padu-a': transition[5]=== 'A',
                                                                              'paduandRoadMapEdit-global-badge-padu-d':transition[5]=== 'D',
                                                                              'paduandRoadMapEdit-global-badge-padu-u':transition[5]=== 'U',
                                                                              'paduandRoadMapEdit-global-badge-padu-r': transition[5]=== 'R',
                                                                              'paduandRoadMapEdit-global-badge-padu-i': transition[5]=== 'I',
                                                                              'paduandRoadMapEdit-global-badge-padu-pp': transition[5]=== 'PP',
                                                                              'paduandRoadMapEdit-global-badge-padu-tbr': transition[5]=== 'TBR'
                                                                              }">{{getPADUFullName(transition[5])}}
                                        <mat-select #paduSelectYearWiseFifth *ngIf="transition_5_Edit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[5],'transition[5]')"
                                            [(ngModel)]="transition[5]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="padutableColumn">
                                <ng-container>
                                    <label class="global-title padutableColumn-Marginleft">
                                        <span>{{currentYear+6}}</span>
                                    </label>
                                    <p class=" marginLeft clickdropdown" (click)="openPaduDropdown('paduSelectYearWiseSixth')" [ngClass]="{
                                                                              'paduandRoadMapEdit-global-badge-padu-p ': transition[6]=== 'P',
                                                                              'paduandRoadMapEdit-global-badge-padu-a': transition[6]=== 'A',
                                                                              'paduandRoadMapEdit-global-badge-padu-d':transition[6]=== 'D',
                                                                              'paduandRoadMapEdit-global-badge-padu-u':transition[6]=== 'U',
                                                                              'paduandRoadMapEdit-global-badge-padu-r': transition[6]=== 'R',
                                                                              'paduandRoadMapEdit-global-badge-padu-i': transition[6]=== 'I',
                                                                              'paduandRoadMapEdit-global-badge-padu-pp': transition[6]=== 'PP',
                                                                              'paduandRoadMapEdit-global-badge-padu-tbr': transition[6]=== 'TBR'
                                                                              }">{{getPADUFullName(transition[6])}}
                                        <mat-select #paduSelectYearWiseSixth *ngIf="transition_6_Edit" class="padu-mat-select"
                                            panelClass="padu-panel-class" (ngModelChange)="modelChangedPadu($event,transition[6],'transition[6]')"
                                            [(ngModel)]="transition[6]">
                                            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                                                <span class="add-padding" [ngClass]="getClassName(option.value)">
                                                    {{ option.label}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5" style="margin-top:2%">
                        <label class="section-name tabl-header tabl-header-font">
                            Justification
                            <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                        </label>
                        <mat-form-field appearance="outline" class="demographics-justification textarea-css padu-justification">
                            <p class="demographics-justification-margin"><textarea  [disabled]="showOpenRequestMessage" class="textarea_text lob-change-textarea" cols="120"
                                    rows="4" maxlength="300" [(ngModel)]="justificationPaduUpdate" matInput placeholder="" style="padding-left: 1%;"></textarea></p>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 col-sm-4 col-lg-4 col-xl-4 col-xs-4"  style="margin-top:2%" *ngIf="paduData.Decomm_Status && !(paduData.Decomm_Status.toLowerCase() == 'decom requested' && paduData.Decomm_Date != null)">
                        <div class="road-map-label">
                            <span class="map-label"><label> Decom Forecast Date</label></span>
                        </div>
                        <div class="forcat-sec">
                            <mat-form-field appearance="outline" [style.width.px]=150>
                                <mat-select class="virtual-scroll" [(ngModel)]="decomforcastQurtr_ui" [disabled]="showOpenRequestMessage"
                                    (selectionChange)="modelChanged($event,'decomforcastQurtr_ui')" panelClass="padu-hub-class">
                                    <mat-option value="">
                                        (None)
                                    </mat-option>
                                    <mat-option *ngFor="let option of QuarterList" [value]="option.value">
                                        {{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" [style.width.px]=150 style="margin-left:4%">
                                <mat-select class="virtual-scroll" panelClass="padu-hub-class" [(ngModel)]="decomforcastYear_ui"  
                                    [disabled]="showOpenRequestMessage" (selectionChange)="modelChanged($event,'decomforcastYear_ui')">
                                    <mat-option value="">
                                        (None)
                                    </mat-option>
                                    <mat-option *ngFor="let option of YearsList" [value]="option.value">
                                        {{ option.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-lg-3 col-xl-3 col-xs-3" style="margin-top:2%" *ngIf="paduData.Decomm_Status && !(paduData.Decomm_Status.toLowerCase() == 'decom requested' && paduData.Decomm_Date != null)">
                        <div class="road-map-label">
                            <span class="map-label"><label>Roadmap</label></span>
                            <span *ngIf="roadmapData.required" class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                        </div>
                        <div class="form-field">
                            <mat-form-field appearance="outline">
                                <span class="text-notavailable-option" *ngIf="roadmapOptions.includes(roadmapData.proposed) != true && roadmapData.proposed != 'No Mapping'">{{ roadmapData.proposed }}</span>
                                <mat-select class="virtual-scroll" (selectionChange)="roadmapChange($event)" [disabled]="roadmapData.disabled || showOpenRequestMessage"
                                    [(ngModel)]="roadmapData.proposed" panelClass="padu-hub-class">
                                    <mat-option value="No Mapping">
                                        (None)
                                    </mat-option>
                                    <mat-option *ngFor="let option of roadmapOptions" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-container *ngIf="showPaduUpdateInfo">
                        <div class="applyMessage">
                            <p class="note-margin note-highlighter">
                                Note: {{ showPaduUpdateInfo }}
                            </p>
                        </div>
                    </ng-container>
                    <div class="col-12">
                        <button mat-mdc-raised-button
                            class="float-right m-1 uitk-c-button uitk-c-button--default  
                            mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                            (click)="saveChangesForPaduUpdate();$event.stopPropagation();" [disabled]="toggleDisabledApplyBtn()">Apply
                        </button>
                    </div>
            </ng-container>
             <!--PADU UPDATE UI logic ends-->
        </div>
        <ng-container *ngIf="workQueueSelected.Id == 1">
            <div class="row my-3 mx-0 pbt-0">
                <div class="col-md-4 col-sm-12 col-lg-5 col-xl-4 col-xs-5">
                    <label class="section-name tabl-header tabl-header-font">
                        Justification
                        <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    </label>
                    <mat-form-field appearance="outline" class="demographics-justification textarea-css" >
                        <p class="demographics-justification-margin"><textarea [disabled]="disableJustificationMsg" class="textarea_text lob-change-textarea"
                                cols="120" rows="3" maxlength="300" [(ngModel)]="justificationLobUpdate" matInput placeholder=""
                                (keyup)="disableApplyForLob()"></textarea></p>
                    </mat-form-field>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-12">
                    <button [disabled]="disableApplyLob" mat-mdc-raised-button class="float-right m-1 uitk-c-button uitk-c-button--default 
                        mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                        (click)="saveChangesForLobUpdate();$event.stopPropagation();">Apply
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(fromAppHubEdit && lobChecked || fromWorkQueue) && showApprovalMessage">
            <div class="applyMessage">
                <p class="note-margin note-highlighter">
                    Note: {{ approvalMessage }}
                </p>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="attributeChecked && fromAppHubEdit">
        <div class="row m-0">
            <div class="col-4" *ngIf="appCategoryflag">
                <label class="section-name tabl-header tabl-header-font">
                    Category
                </label>
                <mat-form-field appearance="outline" class="select-dropdown demographics-attributes-dropdowns">
                    <mat-select panelClass="global-select-panel" (selectionChange)="onCategoryChanged()"
                        [(ngModel)]="selectedCategoryObj">
                    <div>
                        <input matinput type="text" [(ngModel)]="searchCatTxt" class="mat-input-search" 
                            placeholder="Search" (keydown)="$event.stopPropagation()">
                    </div>
                    <div class="table-data-font">
                        <ng-container>
                            <mat-option class="global-mat-option" *ngFor="let category of categoryDropdownValues | appAttrSearch : searchCatTxt" title="{{category.Name}}" [value]="category"><label style="margin:0%">{{ category.Name }}</label></mat-option>
                        </ng-container>
                    </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf="appTypeflag">
                <label class="section-name tabl-header tabl-header-font">
                    Application Type
                </label>
                <mat-form-field appearance="outline" class="select-dropdown demographics-attributes-dropdowns">
                    <mat-select panelClass="global-select-panel" (selectionChange)="onAppTypeChanged()"
                        [(ngModel)]="selectedAppTypeObj"> 
                        <!-- multiple> -->
                        <div>
                            <input matinput type="text" [(ngModel)]="searchAppTypeTxt" class="mat-input-search" 
                                placeholder="Search" (keydown)="$event.stopPropagation()">
                        </div>
                        <!-- <div class="attr-all-checkbox">
                            <mat-checkbox  (change)="selectAllCategories($event)" [checked]="allAppTypeSelected" 
                                [indeterminate]="selectedAppTypeObj.length > 0 && selectedAppTypeObj.length != appTypeDropdownValues.length"
                                (click)="$event.stopPropagation()" style="padding-left: 5%;padding-top: 2%;">
                                <label style="margin:0%">Select All</label>
                            </mat-checkbox>
                        </div> -->
                        <div class="table-data-font">
                            <ng-container>
                                <mat-option class="global-mat-option" *ngFor="let appType of appTypeDropdownValues | appAttrSearch : searchAppTypeTxt" title="{{appType.Name}}" [value]="appType"><label style="margin:0%">{{ appType.Name }}</label></mat-option>
                            </ng-container>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf="hostingEnvList && appHostingflag">
                <app-attributes-dropdown [dropdownLabel]="hostingEnvLabel" [dropdownData]="appHostingEnvDropdownValues"
                    (selectionChanged)="onHostingEnvUpdated($event)" [selectedDropdownValues]="selectedAppHostingEnvList" [hostingEnvList]="hostingEnvList"></app-attributes-dropdown>  
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <button  mat-mdc-raised-button class="float-right default-Button-Class m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button
                        uitk-u-margin-right--base uitk-u-margin-bottom--xs demographics-apply-button" [disabled]="disableApplyBtn()"
                    (click)="saveChangesForAttributes();$event.stopPropagation();">Apply
                </button>
            </div>
        </div>
        
    </ng-container>
    <ng-container *ngIf="workQueueSelected['Id'] == 5">
        <app-capability-proposal [selectedApp]="selectedApp" [capabilityData]="capabilityData" [isEditWorkflow]="isEditWorkflow"  [fromAssociateCapabilities]="fromAssociateCapabilities" [selectedWorqueue]="workQueueSelected" (showOpenRequest)="navigateToOpenRequest($event)" (closePopup)="onAssociatedRequestGenerated($event)" (updateSubLobDataAndAccess)="updateSubLobandAccess($event)"></app-capability-proposal>
    </ng-container>
</div>
