<div *ngIf="notificationList.length>0" class="notification-accordion">
    <mat-accordion multi="true">
        <ng-container *ngFor="let notification of notificationList;index as i">
            <mat-expansion-panel #panel *ngIf="notification.Popup=='True'||notification.Popup=='true'" class="prevent-click">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon class="expansion-icon mr-1 enable-click"
                            *ngIf="panel.expanded">keyboard_arrow_down</mat-icon>
                        <mat-icon class="expansion-icon mr-1 enable-click"
                            *ngIf="!panel.expanded">keyboard_arrow_right</mat-icon>
                        {{notification.Content}}:
                    </mat-panel-title>

                </mat-expansion-panel-header>
                <ng-container *ngFor="let description of notification.notifications">
                    <div class="notification-message enable-click"
                        [innerHTML]="description.Notification_message | safeHtml"></div>
                </ng-container>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
</div>