import { ListRange, SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
// import { MatCheckboxChange } from '@angular/material';
import { PixelService } from 'src/app/shared/services/pixel.service';
@Component({
  selector: 'app-dropdown-with-search',
  templateUrl: './dropdown-with-search.component.html',
  styleUrls: ['./dropdown-with-search.component.scss'],
  providers: [NgModel]
})
export class DropdownWithSearchComponent implements OnInit {

  @Input() placeholder;
  @Input() dropdownData;
  @Input() searchFlag;
  @Input() selectAllFlag=true;
  @Input() isMultiSelect;
  searchTxt: any;
  previousSelection:any;
  @Input() selected: any;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  public initialRange: ListRange = { start: 0, end: 8 };
  @Input() disableDD=false;
  selection = new SelectionModel<any>(true, []);
  allChecked: boolean = true;
  @Input() groupData;
  @Input() sharedgroups;
  groupDropdownData;
  @Input() isAppllyFilter = 'false';
  @Output() applyPaduStatus: EventEmitter<any> = new EventEmitter();
  @Output() clearPaduStatus: EventEmitter<any> = new EventEmitter();
  @ViewChild('matSelect') child: MatSelect;
  disableApply: boolean = false;
  applyTitle: string = '';
  allUncheck = true;
  @Input() Pixeltracking = 'false';
  @Input() showPlaceholder = 'true';
  @Input() isFromForecast = false;
  originalDropdownData: any = [];
  selectedOption = new FormControl();
  textTodisplay: any = [];

  constructor(public toaster: ToastrService, 
    private ngmodel:NgModel,
    private container: ElementRef,
    private pixelservive: PixelService,
    ) { }

  ngOnInit(): void {
    if (this.selected) {
      this.allChecked = !(this.selected.length == 0);
      if (this.placeholder === 'Select Group') {
        this.groupDropdownData = [...this.dropdownData, ...this.sharedgroups];
        this.allChecked = !(this.selected.length == 0);
      }
    }
  }

  ngOnChanges(changes: any) {
    if (changes['dropdownData']) {
      this.searchTxt = '';
    }
    this.selectedOption = new FormControl();
    if (!this.searchTxt) this.originalDropdownData = JSON.parse(JSON.stringify(this.dropdownData));
    if (this.selected) {
      if (this.selected.length == this.dropdownData.length) {
        this.allChecked = true;
      } else if (this.selected.length == 0) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
      this.selectedOption.patchValue(this.selected);
      this.textTodisplay = this.getSelectedItemsDisplayText(this.selected);
      this.previousSelection = this.selected;

      if (this.placeholder === 'Select Group') {
        this.groupDropdownData = [...this.dropdownData, ...this.sharedgroups];
        // this.selected = this.groupDropdownData.find(x => this.selected.name === x.name);
        this.allChecked = this.selected.length == this.groupDropdownData.length;
        if (this.selected.length == this.groupDropdownData.length) {
          this.allChecked = true;
        } else if (this.selected.length == 0) {
          this.allChecked = true;
        } else {
          this.allChecked = false;
        }
      }
    }
  }
  isCheckedForecastIndividual(data) {
    return this.selected.findIndex(row => row.id == data.id) > -1
  }
  getSelectedItemsDisplayText(value): string[] {
    const selectedItemsText = value.map(item => item.name);
    if (selectedItemsText.length === 0) {
      return null;
    }
    else if (selectedItemsText.length === 1) {
      return selectedItemsText;
    }
    else if (selectedItemsText.length == this.originalDropdownData.length) {
      return ['Selected All'];
    }
    return selectedItemsText;
  }
 
  checkMandateOption(data) {
    
    if (this.selected.length != this.dropdownData.length) {
      this.allChecked = false;
      this.allUncheck = false;

    }
    if (this.selected.length == this.dropdownData.length) {
      this.allUncheck = true;
    }
    if (this.selected.length === 1 && this.selected[0] == data) {
      if (this.placeholder == 'PADU Status') {
        this.toaster.error('Atleast One PADU Status must be selected.', '',
          { timeOut: 5000, positionClass: 'toast-top-center' });
      } else {
        this.toaster.error('Atleast One Group Model must be selected.', '',
          { timeOut: 5000, positionClass: 'toast-top-center' });
      }
      
    }
  }
 
  onSelectionChange(event, placeholder) {
    if(this.searchTxt) {
      if (this.previousSelection) {
        for(let i=0;i<this.previousSelection.length;i++) {
            //If previous selection is in DropDownData hen only add previous selection in Event Values(Server Side Search)
          if(this.dropdownData.includes(this.previousSelection[i]) && event.value.length>=this.previousSelection.length )  //Checked cond1
          {
            event.value.push(this.previousSelection[i]);
          }
          else  //Unchecked cond1
          {
              let uncheckedElementName = event.source.options['_results'][0]['_mostRecentViewValue'];
              let unchekcedStatus= event.source.options['_results'][0]['_selected'];
              if(!unchekcedStatus)
              {
                if(this.previousSelection[i]['name']==uncheckedElementName) // Need to add more conditions as per geeric use eg applicationName
                  {
                    this.previousSelection.splice(i,1);
                    i--;
                  }    
              }
          }
        }
      }
      if(event.value.length==0) {//Unchecked cond2
        for(let i=0;i<this.previousSelection.length;i++)
        {
          if(this.dropdownData.includes(this.previousSelection[i]))
          {
            event.value.push(this.previousSelection[i]);
          }
          
        }
      }   
    }
    if(placeholder == 'Select LOB')
    {
    //   let arr = event.value;
    event.value = [...new Map(event.value.map(item => [item['id'], item])).values()];
      this.selectionChange.emit({ data: event.value, type: placeholder });
    }
    else
    {
      this.selectionChange.emit({ data: event.value, type: placeholder });
    }

        // if (this.placeholder == 'Select The Model to view') {
    //   if (this.selected.length == 0) {

    //   }
    // }
    // else 
    if (this.selected.length == 0) {
      this.allChecked = true;
    } else {
      this.allChecked = event.value.length == this.dropdownData.length;
    }
    this.previousSelection = event.value;
  }
  onselectionForecast(event, data) {
    if (event.checked) {
      this.selected.push(data);
      this.textTodisplay = this.getSelectedItemsDisplayText(this.selected);
    }
    else {
      this.selected = this.selected.filter(x => x.id != data.id);
    }
    if (this.selected.length == this.originalDropdownData.length || this.selected.length == 0) {
      this.allChecked = true;
    }
    else {
      this.allChecked = false;
    }
    let selected = JSON.parse(JSON.stringify(this.selected));
    this.selectionChange.emit({ data: this.selected, type: this.placeholder });
    this.selectedOption.setValue(selected);
    if (this.selected.length == 0) {
      this.dropdownData = JSON.parse(JSON.stringify(this.originalDropdownData));
    }
  }

  searchDropdownForecast() {
    if (this.searchTxt) {
      this.dropdownData = this.originalDropdownData.filter(x => {
        return x.name && x.name.toLowerCase().includes(this.searchTxt.toLowerCase());
      });
    } else {
      this.dropdownData = this.originalDropdownData;
      this.selected = JSON.parse(JSON.stringify(this.selected));
      this.textTodisplay = this.getSelectedItemsDisplayText(this.selected);
    }
  }


  isChecked(model: NgModel, Values): boolean {
    if (this.placeholder == 'Select Group') {
      return model.value && this.groupDropdownData.length
      && model.value.length === this.groupDropdownData.length;
    } else {
      return model.value && Values.length
      && model.value.length === Values.length;
    }
    
  }
  isCheckedForecast(): boolean {
    return this.selected?.length == this.originalDropdownData?.length;
    
  }

  isIndeterminate(model: NgModel, values): boolean {
    return model.value && values.length && model.value.length
      && model.value.length < values.length;
  }

  toggleWithFilterButton(change, model, Values): void {
      if (change.checked) {
        model.update.emit(Values);
        this.disableApply = false;
        this.applyTitle = '';
        this.allUncheck = true;
        this.allChecked = true;
      }


    }

  toggleSelection(change, model, Values, placeholder): void {

    if (this.placeholder == 'Select Group') {
      if (change.checked) {
        model.update.emit(Values);
        this.selectionChange.emit({ data: this.groupDropdownData, type: placeholder });
      } else {
        model.update.emit([]);
        this.selectionChange.emit({ data: [], type: placeholder });
        this.allChecked = true;
      }
    } else {
      if (change.checked) {
        model.update.emit(Values);
        this.selectionChange.emit({ data: this.dropdownData, type: placeholder });
      } else {
        model.update.emit([]);
        this.selectionChange.emit({ data: [], type: placeholder });
        this.allChecked = true;
      }
    }
  }
  toggleSelectionForecast(change): void {
    if (change.checked) {
      this.selectedOption.setValue(this.originalDropdownData);
      this.selectionChange.emit({ data: this.dropdownData, type: this.placeholder });
    } else {
      this.selectedOption.setValue([]);
      this.selectionChange.emit({ data: [], type: this.placeholder });
    }
    this.allChecked = true;
  }

  public shareby(data) {
    let html = '';
    if (data) {
      html = 'shared by \n' + data.author;
      return html;
    } else {
      return html;
    }
  }
  clickedButton(event){
    this.clicked.emit(event); 
  }

  applyPaduFilter() {
    let data = this.selected;
    this.applyPaduStatus.emit(data);
    this.child.close();
  }

  clearPaduFilter() {
    this.selected = this.dropdownData;
    this.clearPaduStatus.emit(this.selected);
    this.child.close();
  }
  @Output() applyEnvStatus: EventEmitter<any> = new EventEmitter();
  @Output() clearEnvStatus: EventEmitter<any> = new EventEmitter();
  @Input() isTechViewFilter = 'false';
  applyEnvFilter() {
    let data = this.selected;
    this.applyEnvStatus.emit(data);
    this.child.close();
  }

  clearEnvFilter() {
    this.selected = this.dropdownData;
    this.allChecked = true;
    this.clearEnvStatus.emit(this.selected);
    this.child.close();
  }

  checkMandateForEnvFilter(data) {
    if (this.selected.length != this.dropdownData.length) {
      this.allChecked = false;
      this.allUncheck = false;
    }
    if (this.selected.length == this.dropdownData.length) {
      this.allUncheck = true;
    }
    if (this.selected.length === 1 && this.selected[0] == data) {
      this.toaster.error('Atleast One Environment must be selected.', '',
        { timeOut: 5000, positionClass: 'toast-top-center' });
    }
  }

  public resetInputValue(): void {
    let blurData;
    if(this.searchTxt) {
      blurData = this.dropdownData.filter(x =>{
        return x.name.toString().toLowerCase().includes(this.searchTxt.toLowerCase());
      })
    }
    if (blurData && blurData.length < 1) {
      this.searchTxt = "";
    }
  }
clickOnDropDown(event,Pixeltracking){
  event.stopPropagation();
  if(Pixeltracking == true){
  this.pixelservive.sendclicktopixel(this.placeholder);
  }
}

}
