import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect-global-product-view',
  templateUrl: './redirect-global-product-view.component.html',
  styleUrls: ['./redirect-global-product-view.component.css']
})
export class RedirectGlobalProductViewComponent implements OnInit {
  showLoader: boolean;

  constructor(private activatedRoute: ActivatedRoute, private route: Router) { }

  ngOnInit() {
    setTimeout(()=>{
      this.route.navigate([{ outlets: { primary: 'product-home' } }], {queryParams:{isUHC:true}});
    },5000)
    this.showLoader=true;
  }
 

}
