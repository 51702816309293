import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { Line, Position, SidenavService, AppHubCacheService } from '../../shared';
import { HttpClient } from '@angular/common/http';
import { appModernizationDetails } from '../../config';
import { environment } from '../../../environments/environment';
import {
  ICapabilityLobList,
  ICapabilityHeatmapEntity,
  ICapabilityRelationEntity,
  ICapabilityL0L1,
  ExportCapData
} from '../../shared/interface/interface';
import { map, delay, flatMap, first, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CapabilityService {
  private apiUrl: string;
  private config: any;
  private worker: Worker;
  private workerStream = new Subject();
  private lobUrl: string;
  private lobData: Array<string>;
  // private dataCache: any;
  constructor(private http: HttpClient,
    private dataCache: AppHubCacheService,
    private navService: SidenavService) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
    // if (typeof Worker !== undefined) {
      // Create a new instance of worker
      // let module: any = import.meta;
      // this.worker = new Worker(new URL('./capability-module.worker', module.url), { type: 'module' });
      // this.worker.onmessage = ({ data }) => {
      //   this.dataCache.setCache(this.apiUrl + this.config.routerpath.capLobAppDetails, data, true);
      //   this.workerStream.next(data);
      // };
    // }
  }
  position: { from: Position; to: Position } = {
    from: ['center', 'right'],
    to: ['center', 'left']
  };

  getMappingData(capability: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capRelationship;
    return this.http
      .get<Array<ICapabilityRelationEntity>>(url, { params: { cap: capability } })
      .pipe(
        map(data => {
          const flatArray = [];
          const appCount = {};
          data.forEach(entity => {
            const flatObj = {
              applicationName: entity.Appliction_view.app_name,
              applicationpadu: entity.Appliction_view.padu_lowest ? entity.Appliction_view.padu_lowest[0] : '',
              capabilityName: entity.capability,
              lobName: entity.lob
            };
            flatArray.push(flatObj);
            appCount[flatObj.applicationpadu] = appCount[flatObj.applicationpadu] ? ++appCount[flatObj.applicationpadu] : 1;
          });
          return {
            flatArray,
            appCount
          };
        })
      );
  }

  getCapabilityL0L1(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capL0L1List;
    const cache = this.dataCache.getStaticCache(url);
    if (cache) {
      return of(cache);
    } else {
      return this.http.get<Array<ICapabilityL0L1>>(url).pipe(
        flatMap(result => {
          this.dataCache.setCache(url, result, true);
          return of(result);
        })
      );
    }


  }

  getCapabilityDetails(cap: string): Observable<any> {
    // const url = this.apiUrl + this.config.routerpath.getCapabilityDetails;
    // return this.http.get<Array<ICapabilityL0L1>>(url, { params: { cap } })
    // .pipe(
    //   retry(3),
    //   map((capData) => {
    //     return capData;
    //   })
    // );


    const url = this.apiUrl + this.config.routerpath.getCapabilityDetails;
    const cache = this.dataCache.getCache(url + cap);
    if (cache) {
      return of(cache);
    }
    return this.http.get<Array<ICapabilityL0L1>>(url, { params: { cap } })
      .pipe(
        retry(3),
        map((capData) => {
          return capData;
        })
      );
  }
  getCapExportData(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getCapExportData;
    return this.http.get<Array<ExportCapData>>(url)
      .pipe(
        retry(3),
        map((capExportData) => {
          return capExportData;
        })
      );
  }

  getmodelList() {
    return this.navService.getGroupmodel();
  }

  getCapLevelConfig() {
    return this.config.capabilityLevel;
  }

  private transformToHeatMapData(data: ICapabilityLobList) {
    const result: Array<ICapabilityHeatmapEntity> = [];
    const { capList, capData } = data;
    if (capList.length) {
      capList.forEach(capL => {
        this.lobData.forEach(lob => {
          const { infra, app_count } = capData.find((item) => (item.lob === lob && item.capL2 === capL.capability) && item)
            || { infra: 0, app_count: 0 };
          const obj: ICapabilityHeatmapEntity = {
            lob,
            cap: capL.capability,
            model: capL.group_model,
            capL0: capL.domain,
            capL1: capL.capabilityL1,
            infra,
            appCount: app_count
          };

          result.push(obj);
        });
      });
    }

    return {
      lobCount: this.dataCache.getCache(this.lobUrl).length,
      capabilityData: result
    };
  }

  getHeatMapData() {
    const url = this.apiUrl + this.config.routerpath.capLobAppDetails;
    this.lobUrl = this.apiUrl + this.config.routerpath.lobList;
    const cache = this.dataCache.getStaticCache(url);

    if (!this.lobData) {
      this.navService
        .getLobList()
        .pipe(first())
        .subscribe(lobData => {
          this.lobData = lobData;
        });
    }
    if (!cache) {
      return this.http.get<ICapabilityLobList>(url).pipe(
        retry(3),
        flatMap(apiData => {
          let result;
          if (this.worker) {
            this.worker.postMessage({ apiData, lob: this.lobData });
            return this.workerStream.asObservable();
          } else {
            result = this.transformToHeatMapData(apiData);
            this.dataCache.setCache(url, result, true);
          }
          return of(result);
        })
      );
    } else {
      return of(cache).pipe(delay(100));
    }
  }

  reorderingLOBHeader(data: Array<ICapabilityHeatmapEntity>): Array<ICapabilityHeatmapEntity> {
    const lobRow = [];
    for (const i of this.lobData) {
      const cap = data.find(item => item && item.lob === i);
      if (cap) {
        lobRow.push(cap);
      }
    }
    lobRow.forEach((item, index) => {
      data[index] = item;
    });
    return data;
  }

  filterOnModel(
    data: Array<ICapabilityHeatmapEntity>,
    modal: Array<string> = ['UnitedHealthcare Working Model']
  ): Array<ICapabilityHeatmapEntity> {
    return data.filter(item => {
      return modal.find(mod => item.model === mod) && this.lobData.find(lob => lob === item.lob);
    });
  }

  filterOnCapL0(
    data: Array<ICapabilityHeatmapEntity>,
    capL0: Array<string> = ['Clinical Services Management - WUHC']
  ): Array<ICapabilityHeatmapEntity> {
    return data.filter(item => capL0.includes(item.capL0));
  }

  filterOnCapL1(
    data: Array<ICapabilityHeatmapEntity>,
    capL0: Array<string>
  ): Array<ICapabilityHeatmapEntity> {
    return data.filter(item => capL0.includes(item.capL1));
  }

  getDistinctItems(arr: Array<any>, prop: string): Array<any> {
    const distinctValue = Array.from(new Set(arr.map(s => s[prop]))).map(item => {
      const result = {};
      for (const key in arr[0]) {
        if (Object.prototype.hasOwnProperty.call(arr[0],(key))) {
          result[key] = arr.find(s => s[prop] === item)[key];
        }
      }
      return result;
    });
    return distinctValue;
  }

  getLineCordinatesMapping(source: Array<any>, from: string, to: string, color: string): Array<Line> {
    const lines: Array<Line> = [];
    source.forEach(item => {
      lines.push({
        from: item[from],
        to: item[to],
        color: this.ratingToColor(item[color]),
        position: this.position
      });
    });
    return lines;
  }



  private ratingToColor(rating: string): string {
    let color;
    switch (rating) {
      case 'P':
        color = 'green';
        break;
      case 'A':
        color = 'blue';
        break;
      case 'D':
        color = 'orange';
        break;
      case 'U':
        color = 'red';
        break;
      default:
        color = 'grey';
        break;
    }
    return color;
  }

  assignTableColumn() {
    const columns = [
      {
        columnDef: 'app_id', header: 'ASK ID', width: '11%', cell: (element: any) => `${element.app_id}`,
        cellStyle: () => {
          return {
            'table-pointer': true,
            'link-click': true
          };
        }
      },
      {
        columnDef: 'application',
        header: 'Application',
        width: '18%',
        customHeaderStyle: { 'font-style': 'italic' },
        cell: (element: any) => `${element.application}`
      },
      {
        columnDef: 'lob',
        header: 'LOB',
        width: '10%',
        customHeaderStyle: { 'font-style': 'italic' },
        cell: (element: any) => `${element.lob}`,
      },
      {
        columnDef: 'business_criticality',
        header: 'Business Criticality',
        width: '5%',
        cell: (element: any) => `${element.business_criticality}`,
      },
      {
        columnDef: 'infra',
        header: 'Infra YTD',
        width: '7%',


        cell: (element: any) => `$ ${(+element.infra.toFixed(2)).toLocaleString()}`
      },
      {
        columnDef: 'agg_padu',
        header: 'Agg PADU',
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.agg_padu === 'P',
            'padu-a': element.agg_padu === 'A',
            'padu-d': element.agg_padu === 'D',
            'padu-u': element.agg_padu === 'U',
            'padu-r': element.agg_padu === 'I',
            'padu-pp': element.agg_padu === 'PP'
          };
        },
        cell: (element: any) => element.agg_padu || ''
      },

      {
        columnDef: 'transition_1',
        header: `${new Date().getUTCFullYear() + 1}`,
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.transition_1 === 'P',
            'padu-a': element.transition_1 === 'A',
            'padu-d': element.transition_1 === 'D',
            'padu-u': element.transition_1 === 'U',
            'padu-r': element.transition_1 === 'I',
            'padu-pp': element.transition_1 === 'PP'
          };
        },
        cell: (element: any) => element.transition_1 || ''
      },

      {
        columnDef: 'transition_2',
        header: `${new Date().getUTCFullYear() + 2}`,
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.transition_2 === 'P',
            'padu-a': element.transition_2 === 'A',
            'padu-d': element.transition_2 === 'D',
            'padu-u': element.transition_2 === 'U',
            'padu-r': element.transition_2 === 'I',
            'padu-pp': element.transition_2 === 'PP'
          };
        },
        cell: (element: any) => element.transition_2 || ''
      },
      {
        columnDef: 'transition_3',
        header: `${new Date().getUTCFullYear() + 3}`,
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.transition_3 === 'P',
            'padu-a': element.transition_3 === 'A',
            'padu-d': element.transition_3 === 'D',
            'padu-u': element.transition_3 === 'U',
            'padu-r': element.transition_3 === 'I',
            'padu-pp': element.transition_3 === 'PP'
          };
        },
        cell: (element: any) => element.transition_3 || ''
      },
      {
        columnDef: 'transition_4',
        header: `${new Date().getUTCFullYear() + 4}`,
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.transition_4 === 'P',
            'padu-a': element.transition_4 === 'A',
            'padu-d': element.transition_4 === 'D',
            'padu-u': element.transition_4 === 'U',
            'padu-r': element.transition_4 === 'I',
            'padu-pp': element.transition_4 === 'PP'
          };
        },
        cell: (element: any) => element.transition_4 || ''
      },
      {
        columnDef: 'transition_5',
        header: `${new Date().getUTCFullYear() + 5}`,
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.transition_5 === 'P',
            'padu-a': element.transition_5 === 'A',
            'padu-d': element.transition_5 === 'D',
            'padu-u': element.transition_5 === 'U',
            'padu-r': element.transition_5 === 'I',
            'padu-pp': element.transition_5 === 'PP'
          };
        },
        cell: (element: any) => element.transition_5 || ''
      },
      {
        columnDef: 'transition_6',
        header: `${new Date().getUTCFullYear() + 6}`,
        width: '2%',
        cellStyle: element => {
          return {
            'padu-p': element.transition_6 === 'P',
            'padu-a': element.transition_6 === 'A',
            'padu-d': element.transition_6 === 'D',
            'padu-u': element.transition_6 === 'U',
            'padu-r': element.transition_6 === 'I',
            'padu-pp': element.transition_6 === 'PP'
          };
        },
        cell: (element: any) => element.transition_6 || ''
      }
    ];

    const tableHeaderColumns = [
      { def: 'firstHeader', name: '', colspan: 5 },
      { def: 'secondHeader', name: 'Transition PADU', colspan: 7 }
    ];
    return { columns, tableHeaderColumns };
  }

  public getGroupmodelList(): Observable<Array<string>> {
    const url = this.apiUrl + this.config.routerpath.GetProductModelList;
    const cache = this.dataCache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.dataCache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }
  }

  public GetCapabilities(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetCapabilityLevels;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetProductSuites(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetProductSuites;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getOpenCapabilityWorkqueueRequests(L0_Id) {
    const url = this.apiUrl + this.config.routerpath.getCapOpenWqReq + '/'+L0_Id;
    return this.http.get(url);
  }

}
