import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
  private ssoKeys = environment.sso_keys;
  private apiUrl: string;
  private config: any;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }


  tokenExchange(code = null) {
    const url = this.apiUrl + '/auth/tokenExchange';
    return this.http.post<any>(url, { code } );
  }
  getNewAccessToken(refresh_token = null) {
    const url = this.apiUrl + '/auth/refreshToken';
    return this.http.get<any>(url, { params: { refresh_token } });
  }

  public login() {


  }
  // Method that will fetch us a new token from servers
  public authenticateWith() {

  }

  public logout() {
  }

  public isLoggedIn() {

  }

  private getCachedToken() {

  }

  private isTokenAboutToExpire?() {

  }


}
