import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { OptimizeService } from '../../optimize.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any;
  showLaborHub: boolean = true;
  isLoggedIn: boolean = false;
  optimizationAccess: boolean = false;
  reclassifyView: boolean = false;
  panelOpenState: boolean = false;
  constructor(private route: Router, private optimize: OptimizeService) {}

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.isLoggedIn = true;
    }
    this.optimizationAccess=JSON.parse(localStorage.getItem("flagAccess"))==1?true:false;
    this.reclassifyView=JSON.parse(localStorage.getItem("reclassAccess"))==1?true:false;
  }
  openAppview() {
    this.route.navigate(['appview']);
  }
  redirectView(value) {
    switch (value) {
      case 'tcmhub-AppView':
         this.route.navigate([{ outlets: { primary: 'application-cost', sidebar: 'tcmhub' } }]);
        
        break;
      case 'tcmhub-LobView':
         this.route.navigate([{ outlets: { primary: 'cio-cost' } }]);
        
        break;
      case 'app-AppView':
        
        this.route.navigate([{ outlets: { primary: 'application-insights/selectFilter' } }]);
        
        break;

        case 'tcm-data-extract':
        window.open(environment.reportURL, '_blank');
        break;
        
      case 'app-lob':
        
        this.route.navigate([{ outlets: { primary: 'cio-app-insights' } }]);
        
        break;

      case 'app-cap':
         this.route.navigate([{ outlets: { primary: 'product-home' } }]);
        
        break;

      case 'app-decom':
        this.route.navigate([{ outlets: { primary: 'decommission-app' } }]);
        break;

      case 'app-product-mapping':
        this.route.navigate([{ outlets: { primary: 'uhcproduct-home' } }]);
        break;
      // case 'labourhub':
      //   this.route.navigate([{ outlets: { primary: 'laborhub/view', sidebar: 'laborhub' } }]);
      //   break;
      case 'optimization':
        this.route.navigate([{ outlets: { primary: 'optimization', sidebar: 'optimization' } }]);
        break;
      case 'reclassification':
          this.route.navigate([{ outlets: { primary: 'reclassification', sidebar: 'reclassification' } }]);
        
        break;
    }
  }

  releaseNote() {
    this.route.navigate([{ outlets: { primary: 'release-note' } }]);
  }

}
