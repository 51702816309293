import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppHubService } from 'src/app/techopsv4/application-hub/app-hub.service';
import { PortfolioHubService } from 'src/app/techopsv4/services/portfolioHubV4Service';
import { environment } from 'src/environments/environment';
import { DynamicContentModel } from '../landing-page-v4.component';

@Component({
  selector: 'app-landing-page-body',
  templateUrl: './landing-page-body.component.html',
  styleUrls: ['./landing-page-body.component.css']
})
export class LandingPageBodyComponent implements OnInit {
  selectedRoute: any;
  public bannerTitle : DynamicContentModel = {}  as DynamicContentModel;
  public bannerDesc : DynamicContentModel = {}  as DynamicContentModel;
  public bannerLink : DynamicContentModel = {}  as DynamicContentModel;
  public notification : DynamicContentModel = {}  as DynamicContentModel;
  public dynamicDataLoaded = false;
  @Input() dynamicContentData;
  constructor(
    private route: Router,
    private portfolioHubService: PortfolioHubService,
    private appHubService: AppHubService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.dynamicContentData) {
      if(this.dynamicContentData.Banner_Title) {
        this.bannerTitle = this.dynamicContentData.Banner_Title[0] ? this.dynamicContentData.Banner_Title[0] : {};
      }
      if(this.dynamicContentData.Banner_Desc){
        this.bannerDesc = this.dynamicContentData.Banner_Desc[0] ? this.dynamicContentData.Banner_Desc[0] : {};
      }
      if(this.dynamicContentData.Banner_link){
        this.bannerLink = this.dynamicContentData.Banner_link[0] ? this.dynamicContentData.Banner_link[0] : {};
      }
      if(this.dynamicContentData.Notification){
        this.notification = this.dynamicContentData.Notification[0] ? this.dynamicContentData.Notification[0] : {};
      }
      this.dynamicDataLoaded = true;
    }
  }

  redirectToUserManual() {
    if(this.bannerLink.visibility == 'True' && this.bannerLink.Content)
    window.open(environment.docUrl+this.bannerLink.Content);
  }

  redirectView(value) {
    this.selectedRoute = value;
    switch (value) {
      case 'optimization':
        this.route.navigate([{ outlets: { primary: 'optimization' } }]);
        break;
      case 'application-hub':
        this.appHubService.applicationHubFromKnowYourFinances = false;
        this.route.navigate([{ outlets: { primary: 'application-hub' } }]);
        break;
      case 'portfolio-hub':
        this.portfolioHubService.porfolioSelectedFromFinance = false;
        this.route.navigate([{ outlets: { primary: 'portfolio-hub' } }]);
        break;
      case 'reports':
        this.route.navigate(['/reports']);
        break;
      case 'capModel':
        this.route.navigate([{ outlets: { primary: 'product-home' }}]);
        break;
      case 'application-hub-finance':
        this.appHubService.applicationHubFromKnowYourFinances = true;
        this.route.navigate([{ outlets: { primary: 'application-hub' } }],{ queryParams: { tab: 2 } });
        break;
      case 'portfolio-hub-finance':
        this.portfolioHubService.porfolioSelectedFromFinance = true;
        this.route.navigate([{ outlets: { primary: 'portfolio-hub' }}]);
        break; 
      case 'all-groups':
        this.route.navigate([{ outlets: { primary: 'maintenance/group' }}]);
        break;
      case 'portfolio-hub-groups':
        this.portfolioHubService.porfolioSelectedFromFinance = false;
        this.route.navigate([{ outlets: { primary: 'portfolio-hub' }}],{ queryParams: { tab: 'group' } });
        break;  
        case 'workforce-budget':
        this.route.navigate([{ outlets: { primary: 'workforce-budget' }}]);
        break;   
    }
  }
}
