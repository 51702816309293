import { ListRange } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { PortfolioHubService } from 'src/app/techopsv4/services/portfolioHubV4Service';

@Component({
  selector: 'app-dropdown-with-api-search',
  templateUrl: './dropdown-with-api-search.component.html',
  styleUrls: ['./dropdown-with-api-search.component.css']
})
export class DropdownWithApiSearchComponent implements OnInit, OnChanges {

  @Input() placeholder;
  @Input() dropdownData = ['Type min 3 Characters to search'];
  @Input() searchFlag;
  @Input() selectAllFlag;
  @Input() isMultiSelect;
  @Input() selected;
  @Input() selecteOwnerCategory = [];
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  public initialRange: ListRange = { start: 0, end: 8 };
  @Input() disableDD=false;
  subject = new Subject<string>();
  destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchTxt:string = '';
  ownerSearchResult;
  @Input() ownerList;
  constructor(public portfolioService: PortfolioHubService) { }

  ngOnInit(): void {
    
    this.subject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        this.ownerList = [];
        let ownerPayload = {'Category': this.selecteOwnerCategory.map(x => x.key)};
        return this.portfolioService.getOwners(this.searchTxt, ownerPayload);
        
      })
    ).pipe(takeUntil(this.destroy$))
      .subscribe(searchObj => {
        /**below code is for name format. */
        let formattedName = searchObj.name.map(x => {
          let names = x.split(',');
          let final = '';
          names.forEach(name => {
            final = final.length == 0 ? final + name.trim().charAt(0).toUpperCase() + name.trim().slice(1) 
                                      : final + ', ' + name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
          })
        //  return x.charAt(0).toUpperCase() + x.slice(1);
        return final;
        })
          
        
        this.ownerSearchResult = searchObj;
        this.dropdownData = formattedName 
        if (this.dropdownData.length == 0) {
          this.dropdownData = ['No data available'];
        }
        // searchObj.name;
        
      });
  }

  ngOnChanges() {
    if (this.selecteOwnerCategory.length > 0) {
      if(this.ownerList.length > 0) {
        this.dropdownData = this.ownerList;
      }else {
        if (this.ownerSearchResult) {
          let tempOwnerList = [];
          this.selecteOwnerCategory.forEach(category => {
            tempOwnerList = [...tempOwnerList, ...this.ownerSearchResult[category.key]];
          })
          this.dropdownData = [...new Set(tempOwnerList)]
          if (this.dropdownData.length == 0) {
            this.dropdownData = ['No data for selected category. Type again'];
          }
        }
      }
      
    }
  }

  OnSearch(event) {
      if (event.target.value.length >= 3) {
        this.subject.next(event.target.value);
      }
  }

  onSelectionChange(event, placeholder) {
    
    if (placeholder == 'Select Owner') {
      this.selectionChange.emit({ data: event.value, type: placeholder, dropdownData: this.dropdownData, searchText: this.searchTxt});
    } else {
      this.selectionChange.emit({ data: event.value, type: placeholder});
    }
  }

}
