
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from 'src/app/shared';
import { AppHubService } from 'src/app/techopsv4/application-hub/app-hub.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-editable-table-view',
  templateUrl: './editable-table-view.component.html',
  styleUrls: ['./editable-table-view.component.css']
})
export class EditableTableViewComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<PeriodicElement>(true, []);
  displayedColumnSharing: string[] = ['select', 'userDetails'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
 @Input() appviewData: any;
  capabilityID: any;
  applicationDetails: any;
  viewClickLoader: boolean = false;
  model: any;
  app_ID: any;
  public pageSizes = [5,10,15];

  displayedColumns = [
    {
      columnDef: 'ApplicationID',
      header: 'ApplicationID',
      width: '20%',
      textAlign:'left',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => `${element.ApplicationID}`
    },
    {
      columnDef: 'ApplicationName',
      header: 'ApplicationName',
      width: '30%',
      textAlign:'left',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'test': element.ApplicationName
        };
      },
      cell: (element: any) => `${element.ApplicationName}`
    },
    {
      columnDef: 'ApplicationPADU',
      header: 'Application PADU',
      width: '13%',
      textAlign:'center',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => `${element.ApplicationPADU}`
    }];
  capability: any;

  constructor(private appViewService: AppHubService) {
    
  }
  ngOnInit(): void {}
  ngOnChanges(){
    this.capabilityID = this.appviewData.capabilityID;
    this.model = this.appviewData.model;
    this.app_ID = this.appviewData.app_ID;
    this.capability=this.appviewData.capability;
    this.viewClickLoader = true;
    this.appViewService.getCapabilityApplicationDetails(this.capabilityID, this.model, this.app_ID).subscribe(app => {
      this.applicationDetails = app;
      this.dataSource = new MatTableDataSource(this.applicationDetails);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function (record, filter) {
        return record.ApplicationName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
      };
      this.viewClickLoader = false;
    });
  }

  searchDataSet(event) {
    const filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

