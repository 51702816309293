import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { tcmHubConfigDetails } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class ApplistService {
  selectedApplicationLob: string;
  navFromLob: boolean = false;
  constructor(private http: HttpClient) { }
  getAppList(): Observable<any> {
    if (!sessionStorage.getItem('applist')) {
      const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.groupAppList;
      const applist = this.http.get(appListUrl).pipe(map(res => {
        sessionStorage.setItem('applist', JSON.stringify(res));
        return res;
      })
      );
      return applist;
    } else {
      return of(JSON.parse(sessionStorage.getItem('applist')));
    }
  }
  getSloTechList(searchString){
      const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.sloTechList;
      return this.http.get(appListUrl,{ params: { searchString:searchString} });
  }
  public getAllApplications() {
    const apiUrl = tcmHubConfigDetails.envApiUrl + tcmHubConfigDetails.routerpath.getAllApps;
    return this.http.get(apiUrl);
  }
  public getAllSlo() {
    const apiUrl = tcmHubConfigDetails.envApiUrl + tcmHubConfigDetails.routerpath.getAllSlo;
    return this.http.get(apiUrl);
  }
  public getAllTechOwners() {
    const apiUrl = tcmHubConfigDetails.envApiUrl + tcmHubConfigDetails.routerpath.getAllTechOwners;
    return this.http.get(apiUrl);
  }
  fetchAppList(searchString,multiple,body): Observable<any> {
      if(body&&body.applicationList&&body.applicationList.length>0){
        const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.applicationListPath;
        return this.http.post(appListUrl,body);
      }
      else{
        const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.applicationListPath +`?searchString=${searchString}&multiple=${multiple}`; 
        return this.http.post(appListUrl,body);
      }
      // const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.applicationListPath;
      // return this.http.get(appListUrl,{ params: { searchString:searchString,multiple:multiple} });;
  }
  public searchapplist(body) {
    const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.applicationListPath;
    return this.http.post(appListUrl, body);
  }
  getOptimizedList(): Observable<any> {
    const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.getOptimizedList;
    const appList = this.http.get(appListUrl).pipe(map(res => {
      return res;
    })
    );
    return appList;
  }
}
