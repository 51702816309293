import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { DecimalPipe, formatNumber } from '@angular/common';

@Component({
  selector: 'app-summary-box',
  templateUrl: './summary-box.component.html',
  styleUrls: ['./summary-box.component.css']
})
export class SummaryBoxComponent implements OnInit {
  @Input() Headertexts: Array<any> = [];
  @Input() numberValues: Array<any> = [];

  @Input() groupSummaryBoxes: Array<{
    headerText: string;
    value: number;
    width: string;
    type: number;
    color: string;
    icon: string;
    tooltip: string;
    tooltipnumber: string;
  }>;
  @Input() dollorsign: any;
  public popuphtmldata: any = '';
  constructor(private container: ElementRef, private decimalPipe: DecimalPipe) {}

  public numberFormatted: any;
  // public numberFormat(value: number, digits?: any): any {
  //   let output = {};
  //   if (value > 0 && value > 5) {
  //     this.numberFormatted = this.decimalPipe.transform((value / 1000).toFixed(0), digits);
  //     return '$ ' + this.numberFormatted;
  //   } else if (value < 0) {
  //     this.numberFormatted = this.decimalPipe.transform(Math.abs(value / 1000).toFixed(1), digits);
  //     return '-$' + this.numberFormatted;
  //   } else if (value = 0) {
  //     return '-';
  //   }
  // }
  public numberFormat(value: any): any {
    if (value == 0) {
      return value;
    }
    if (value) {
      if (this.dollorsign === false) {
        return formatNumber(Number(value), 'en-US', '1.0-0');
      } else {
        return '$ ' + formatNumber(Number(value), 'en-US', '1.0-0');
      }
    }
  }

  getColor(value) {
    if (value >= 3 && value <= 5) {
      return 'rgb(25,132,22)';
    } else if (value <= 3 && value > 0) {
      return 'rgb(233,27,24)';
    } else if (typeof value == 'string') {
      return 'rgb(120,200,159)';
    } else {
      return 'white';
    }
  }

  // getIcon(value) {
  //   if (value >= 3 && value <= 5) {
  //     return 'arrow_circle_up';
  //   }
  //   else if (value <= 2 && value > 0) {
  //     return 'arrow_circle_down ';
  //   }
  //   else {
  //     return 'summarybox.icon';
  //   }
  // }

  ngOnInit() {}
  popuptooltip(data) {
    const ele = this.container.nativeElement.querySelector('.hover_bkgr_fricc') as HTMLInputElement;
    ele.style.display = 'block';
    this.popuphtmldata = data;
  }
  hidepopup() {
    const ele = this.container.nativeElement.querySelector('.hover_bkgr_fricc') as HTMLInputElement;
    ele.style.display = 'none';
  }
}
