import { trigger, state, style, transition, animate } from '@angular/animations';
import { PlatformLocation } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppModernizationService } from 'src/app/shared/services/app-modernization.service';
import { appModernizationDetails } from 'src/app/config';
import { MatSelect } from '@angular/material/select';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { ListRange } from '@angular/cdk/collections';
import { IAppViewFilter } from 'src/app/shared/interface/interface';
import { AppHubService } from 'src/app/techopsv4/services/appHubV4Service';
import { SharedService } from 'src/app/shared';

@Component({
  selector: 'app-app-search',
  templateUrl: './app-search.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({

        opacity: 1,

      })),
      state('closed', style({

        opacity: 0,

      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
  ],
  styleUrls: ['./app-search.component.css']
})
export class AppSearchComponent implements OnInit {

  @Input() items;
  @Output() appSelectEvent: EventEmitter<any> = new EventEmitter();
  public initialRange: ListRange = { start: 0, end: 8 };
  public appNameList: Array<IAppViewFilter> = [];
  hideSearch = false;
  searchValue: string = '';
  showApplicationList: boolean = false;
  private config: any;
  public askId;
  showAppListLoader: boolean = false;
  totalSearchedApp: number = 0;
  data$: Observable<any>;
  // tslint:disable-next-line: max-line-length
  public appName;
  public appNameValue;
  public lobNameValue;
  public lobName;
  public cioName;
  public capabilityNameValue;
  public capabilityName;
  public queryDetails;
  public lobWithModal = [];
  public showFilter = {
    app: true,
    lob: false
  };
  public manageUser:any;
  public validateUser:boolean = false;

  subject = new Subject<string>();
  destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('modelSel', { static: false }) modelSel: MatSelect;
  @ViewChild('modelSelApp', { static: false }) modelSelApp: MatSelect;
  app_NAME: any;

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {

      this.showApplicationList = false;
      // this click event from outside
    }
  }
  constructor(private route: Router,
    private AppHubService: AppHubService,
    private router: ActivatedRoute,
    private location: PlatformLocation,
    private appRoadmap: AppModernizationService,
    private elementRef: ElementRef,
    private sharedService: SharedService) {
    this.config = appModernizationDetails;
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  decodeQuery(s: string): string {
    return this.decode(s.replace(/\+/g, '%20'));
  }
  decode(s: string): string {
    return decodeURIComponent(s);
  }
  ngOnInit(): void {
    this.subject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchText => {
        this.showAppListLoader = true;
        return this.AppHubService.getAppList(0, searchText)
      })
    ).pipe(takeUntil(this.destroy$))
      .subscribe(searchObj => {
        this.appNameList = searchObj.result;
        this.totalSearchedApp = searchObj.result.length;
        this.showAppListLoader = false;
        // this.handleSearch(searchObj);
      });
    this.defaulturlload();
  }


  searchApplication(event) {

    if (event.target.value.length >= 3) {
      this.subject.next(event.target.value);
    }
  }

  searchSpecificApplication(searchedAppId: any) {
    let appId;
    appId = { app_id: searchedAppId.app_id, app_name: searchedAppId.app_name, tab: 0 }
    this.showApplicationList = false;
    let params = { appId: JSON.stringify(appId.app_id) };
    if (searchedAppId.tab != null) {
      appId['tab'] = searchedAppId.tab;
      params['tab'] = searchedAppId.tab;
    }
    this.appSelectEvent.emit(appId);
    this.route.navigate(['application-hub'], { queryParams: params, queryParamsHandling: 'merge' });
    this.hideSearch = true;
  }

  defaulturlload(){
    this.items.push({ label: 'PADU Management', path: null })
    let appid= JSON.parse(this.router.snapshot.queryParamMap.get('appId'));
    let tab = JSON.parse(this.router.snapshot.queryParamMap.get('tab'));
    if (appid) this.searchSpecificApplication({app_id:appid,tab:tab});
  }
}
