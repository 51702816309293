import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div tabindex="0" role="alert" class="uitk-c-component-load-indicator__container" id="my-spinner1">
      <div class="uitk-c-component-load-indicator__spinner-container large">
        <div class="uitk-c-component-load-indicator__spinner large">
          <svg
            aria-hidden="true"
            focusable="false"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
          >
            <rect x="30.26" y="0.77" width="3.46" height="14.46" class="cls-1"></rect>
            <path d="M33,1.54V14.46H31V1.54H33M34.49,0h-5V16h5V0Z" class="cls-1"></path>
            <g class="cls-2">
              <rect
                x="18.26"
                y="3.99"
                width="3.46"
                height="14.46"
                transform="translate(-2.93 11.49) rotate(-29.99)"
                class="cls-1"
              ></rect>
              <path
                d="M17.59,5.15l6.46,11.19-1.66,1L15.93,6.11l1.66-1M18.15,3l-4.33,2.5,8,13.86,4.33-2.5L18.15,3Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-3">
              <rect
                x="9.48"
                y="12.78"
                width="3.46"
                height="14.46"
                transform="translate(-11.73 19.72) rotate(-60.01)"
                class="cls-1"
              ></rect>
              <path
                d="M6.09,16l11.19,6.46-1,1.66L5.13,17.61l1-1.66m-.56-2.1L3,18.18l13.86,8,2.5-4.33-13.86-8Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-4">
              <rect x="0.77" y="30.28" width="14.46" height="3.46" class="cls-1"></rect>
              <path d="M14.46,31.05V33H1.54V31.05H14.46M16,29.51H0v5H16v-5Z" class="cls-1"></path>
            </g>
            <g class="cls-5">
              <rect
                x="3.99"
                y="42.28"
                width="14.46"
                height="3.46"
                transform="translate(-20.5 11.51) rotate(-30)"
                class="cls-1"
              ></rect>
              <path
                d="M16.34,40l1,1.66L6.11,48.07l-1-1.66L16.34,40m.56-2.1L3,45.85l2.5,4.33,13.86-8-2.5-4.33Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-6">
              <rect
                x="12.78"
                y="51.06"
                width="14.46"
                height="3.46"
                transform="translate(-35.71 43.73) rotate(-60)"
                class="cls-1"
              ></rect>
              <path
                d="M22.41,46.72l1.66,1L17.61,58.87l-1.66-1,6.46-11.19m-.56-2.11-8,13.86L18.18,61l8-13.86-4.33-2.5Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-7">
              <rect x="30.28" y="48.77" width="3.46" height="14.46" class="cls-1"></rect>
              <path d="M33,49.54V62.46H31.05V49.54H33M34.51,48h-5V64h5V48Z" class="cls-1"></path>
            </g>
            <g class="cls-8">
              <rect
                x="42.28"
                y="45.55"
                width="3.46"
                height="14.46"
                transform="translate(-20.49 29.06) rotate(-29.99)"
                class="cls-1"
              ></rect>
              <path
                d="M41.61,46.7l6.46,11.19-1.66,1L40,47.66l1.66-1m.57-2.1-4.33,2.5,8,13.86,4.33-2.5-8-13.86Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-9">
              <rect
                x="51.06"
                y="36.76"
                width="3.46"
                height="14.46"
                transform="translate(-11.69 67.73) rotate(-60.01)"
                class="cls-1"
              ></rect>
              <path
                d="M47.68,39.93l11.19,6.46-1,1.66L46.72,41.59l1-1.66m-.57-2.11-2.5,4.33,13.86,8L61,45.82l-13.86-8Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-10">
              <rect x="48.77" y="30.26" width="14.46" height="3.46" class="cls-1"></rect>
              <path d="M62.46,31V33H49.54V31H62.46M64,29.49H48v5H64v-5Z" class="cls-1"></path>
            </g>
            <g class="cls-11">
              <rect
                x="45.55"
                y="18.26"
                width="14.46"
                height="3.46"
                transform="translate(-2.92 29.06) rotate(-30)"
                class="cls-1"
              ></rect>
              <path
                d="M57.89,15.93l1,1.66L47.66,24.05l-1-1.66,11.19-6.46m.57-2.11-13.86,8,2.5,4.33,13.86-8-2.5-4.33Z"
                class="cls-1"
              ></path>
            </g>
            <g class="cls-12">
              <rect
                x="36.76"
                y="9.48"
                width="14.46"
                height="3.46"
                transform="translate(12.29 43.7) rotate(-60)"
                class="cls-1"
              ></rect>
              <path
                d="M46.39,5.13l1.66,1L41.59,17.28l-1.66-1L46.39,5.13M45.82,3l-8,13.86,4.33,2.5,8-13.86L45.82,3Z"
                class="cls-1"
              ></path>
            </g>
          </svg>
        </div>
        <div
          class="uitk-c-component-load-indicator__visible-text-container"
          ng-reflect-ng-style="[object Object]"
          style="max-width: 192px;"
        >
         <!-- <p class="uitk-c-component-load-indicator__visible-text" id="my-spinner1_visibleText">
            <span role="alert" class="uitk-c-component-load-indicator__visible-text-accessibility"
              >Loading, please wait..</span
            >
          </p> -->
        </div>
      </div>
    </div>
  `
})
export class LoaderComponent {}
