<div class="model-maintenance-modal-container">
    <div class="custom-modal-header">
        <span class="title">{{modalTitle}}</span>
        <span class="modal-actions">
            <mat-icon class="material-icons pgm-close" matTooltip="Close" (click)="closePopup()">close
            </mat-icon>
        </span>
    </div>
    <div class="custom-modal-body">
        <div class="body-actions">
        </div>
        <div class="body-content">
            <form class="upload-form" [formGroup]="SaveForm">
                <div class="form-panel">
                    <mat-grid-list cols="1" rowHeight="60px">
                        <mat-grid-tile class="step-tiles">
                            <div class="form-f form-download-section">
                                <span class="upload-step1">
                                    Step #1:
                                </span>
                                <span class="download-temaplte">
                                    <a [href]='Filepath' download>
                                        Download Template
                                    </a>
                                </span>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile class="step-tiles">
                            <div class="form-f form-info-section">
                                <span class="upload-step2">
                                    Step #2:
                                </span>
                                <span class="">
                                    <a>
                                        Add your data (changes only) in the template
                                    </a>
                                </span>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile class="step-tiles">
                            <div class="form-f form-upload-section">
                                <span class="upload-step3">
                                    Step #3: Upload the filled in template
                                </span>
                                <span class="">
                                    <input type="file" #fileInput id="uploadFile"
                                        (change)="uploadFileEvt($event.target.files)" name="uploadFile"
                                        accept=".xlsx" />
                                </span>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
                <div class="form-footer">
                    <div class="footer-buttons">
                        <button mat-mdc-raised-button 
                            class="searchbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                            (click)="submitForm()" [disabled]="!fileToUpload">
                            Submit
                        </button>
                        <button mat-mdc-raised-button 
                            class="searchbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                            (click)="closePopup()">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!--  -->
    <ng-container *ngIf="showLoader">
        <div class="show-loader">
            <app-loader>
            </app-loader>

        </div>
    </ng-container>

</div>