import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-mbo-service-bar-chart',
  templateUrl: './mbo-service-bar-chart.component.html',
  styleUrls: ['./mbo-service-bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MboServiceBarChartComponent implements OnInit {
  public PrevYear = new Date().getFullYear() - 1;
  public CurrentYear = new Date().getFullYear();
  public margin: any;
  public yAxis: any;
  public xAxis: any;
  public xScale: any;
  public svg: any;
  public barHeight: any;
  public y0: any;
  public widther;
  public yAxisGroup;
  public barNumbers1;
  public barNumbers2;
  @Input() data: any = [{ num: 8822, num2: 35697, mbo: 20000, label1: '${this.PrevYear} YTD', label2: '${this.PrevYear}  FY' }];
  @Input() trenddata: any = [
    { date: '2021-01-01', volume: 179118249 },
    { date: '2021-02-01', volume: 201566818 },
    { date: '2021-03-01', volume: 147686869 },
    { date: '2021-04-01', volume: 151936061 },
    { date: '2021-05-01', volume: 211814173 }
  ];
  @Input() trend = false;
  @Input() mbotooltip = false;
  @Input() yoytooltip = false;
  @Input() symbal = '';
  @Input() mbobar = false;
  @Input() footertext: any;
  @Input() variance: any;

  constructor(private container: ElementRef) { }

  ngOnInit() {
    // Draw the chart
    this.barchart(this.data, this.container);
  }
  barchart(data, elem) {
    // Margin conventions
    this.margin = { top: 10, right: 50, bottom: 20, left: 0 };
    const box = document.querySelector('.g-chart');

    // var widther = window.outerWidth;
    this.widther = box.parentElement.offsetWidth;
    //const width = widther - this.margin.left - this.margin.right;
    const width = 380 - this.margin.left - this.margin.right;
    const height = 45 - this.margin.top - this.margin.bottom;

    this.barHeight = 25;

    // Appends the svg to the chart-container div
    this.svg = d3
      .select(elem.nativeElement)
      .select('.g-chart')
      .append('svg')
      .attr('width', width + this.margin.left + this.margin.right)
      .attr('height', height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // Creates the xScale
    this.xScale = d3.scaleLinear().range([0, width]);

    // Creates the yScale
    this.y0 = d3.scaleBand().range([height, 0], 1);

    // Defines the y axis styles
    this.yAxis = d3.axisLeft(this.y0);

    // Defines the y axis styles
    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickFormat(d => {
        return d + '%';
      })
      .tickSize(height);

    // FORMAT data
    data.forEach(d => {
      d.num = +d.num;
      d.num2 = +d.num2;
    });

    // Sets the max for the xScale
    const maxX = d3.max(data, d => {
      return d.num2;
    });

    // Gets the min for bar labeling
    // const minX = d3.min(data, d => {
    //   return d.num;
    // });

    // Defines the xScale max
    this.xScale.domain([0, maxX]);

    // Appends the y axis
    this.yAxisGroup = this.svg
      .append('g')
      .attr('class', 'y1axis')
      .call(this.yAxis);

    // Appends the x axis
    // const xAxisGroup = this.svg
    //   .append('g')
    //   .attr('class', 'x1axis')
    //   .call(this.xAxis);

    // Binds the data to the bars
    // const categoryGroup = this.svg
    //   .selectAll('.g-category-group')
    //   .data(data)
    //   .enter()
    //   .append('g')
    //   .attr('class', 'g-category-group')
    //   .attr('transform', () => {
    //     return 'translate(0,0)';
    //   });

    // Appends background bar
    // const bars2 = categoryGroup
    //   .append('rect')
    //   .attr('width', d => {
    //     if (d.num2 > 0) {
    //       return this.xScale(d.num2);
    //     }
    //     else if (d.num == 0) {
    //       return 330;
    //     }
    //   })
    //   .attr('height', this.barHeight - 1)
    //   .attr('class', 'g-num3')
    //   .style('fill', () => {
    //     if (this.variance) {
    //       return '#1984163d';
    //     }
    //   })
    //   .attr('transform', 'translate(0,4)');

    // Appends main bar
    // const bars = categoryGroup
    //   .append('rect')
    //   .attr('width', d => {
    //     if (d.num > d.num2) {
    //       return this.xScale(d.num2);
    //     } else {
    //       return this.xScale(d.num);
    //     }
    //   })
    //   .attr('height', this.barHeight - 1)
    //   .attr('class', 'g-num')
    //   .attr('transform', 'translate(0,4)')
    //   .on('mouseover', handleMouseOver)
    //   .on('mouseout', handleMouseOut)
    //   .on('click', handleclick);

    // if (this.mbobar) {
    //   const bars3 = categoryGroup
    //     .append('rect')
    //     .attr('width', 3)
    //     .attr('height', this.barHeight + 10)
    //     .attr('class', 'g-num')
    //     .style('fill', 'green')
    //     .attr('transform', d => {
    //       return 'translate(' + this.xScale(d.mbo) + ',-1)';
    //     });
    // }
    // Binds data to labels
    const labelGroup = this.svg
      .selectAll('g-num3')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'g-label-group')
      .attr('transform', () => {
        return 'translate(0,0)';
      });

    // Appends main bar labels
    // const barLabels1 = labelGroup
    //   .append('text')
    //   .text(d => {
    //     return d.label1;
    //   })
    //   .attr('x', 5)
    //   .style('fill', '#6c757d')
    //   .attr('y', 0)
    //   .attr('class', 'g-labels');

    // const barLabels2 = labelGroup
    //   .append('text')
    //   .text(d => {
    //     return d.label2;
    //   })
    //   .attr('x', 280
        //d => {
        // if (minX > 32) {
        //   return this.xScale(d.num2) - 37;
        // } else {
        //   return this.xScale(d.num2) - 45;
        // }
        //}
      // )
      // .style('fill', '#6c757d')
      // .attr('y', 0)
      // .attr('class', 'g-labels');

    /// bind the numbers
    this.barNumbers1 = labelGroup
      .append('text')
      .text(d => {
        return this.symbal == 'K' ? d.num + this.symbal : this.symbal + d.num;
      })
      .attr('x', 5)
      .style('fill', 'white')
      .style('display', d => {
        if (d.num == 0) {
          return 'none';
        } else {
          return 'block';
        }
      })
      .attr('y', 21)
      .attr('class', 'g-labels-text')
      // .on('mouseover', handleMouseOver)
      // .on('mouseout', handleMouseOut)
      .on('click', handleclick);

    this.barNumbers2 = labelGroup
      .append('text')
      .text(d => {
        return this.symbal == 'K' ? d.num2 + this.symbal : this.symbal + d.num2;
      })
      .attr('x', 330)
      .style('fill', '#10005d')
      .attr('y', 21)
      .attr('class', 'g-labels-text');

    // const tooltip2 = d3
    //   .select(elem.nativeElement)
    //   .select('.g-chart')
    //   .append('div')
    //   .style('position', 'absolute')
    //   .style('visibility', 'hidden')
    //   .style('display', () => {
    //     if (this.mbotooltip) {
    //       return '';
    //     } else if (this.yoytooltip) {
    //       return '';
    //     } else {
    //       return 'none';
    //     }
    //   })
    //   .style('background-color', 'white')
    //   .style('border', 'solid')
    //   .style('border-width', '1px')
    //   .style('border-radius', '5px')
    //   .style('padding', '10px')
    //   .html(() => {
    //     let tooltip = '';
    //     if (this.mbotooltip) {
    //       return (tooltip = '<p>Click to view Monthly Chargeback</p>');
    //     } else if (this.yoytooltip) {
    //       return (tooltip = '<p>25% of 2019 service chargeback</p>');
    //     } else {
    //       return (tooltip = '<p style="display:none"></p>');
    //     }
    //   });
    // RESPONSIVENESS
    //d3.select(window).on('resize', resized);

    // function resized() {
    //   // new margin
    //   const newMargin = { top: 10, right: 10, bottom: 20, left: 0 };

    //   // Get the width of the window
    //   const w = d3
    //     .select(elem.nativeElement)
    //     .select('.g-chart')
    //     .node().clientWidth;

    //   // Change the width of the svg
    //   d3.select('svg').attr('width', w);

    //   // Change the xScale
    //   this.xScale = d3.scaleLinear().range([0, w - newMargin.right - newMargin.left]);
    //   this.xAxis = d3
    //     .axisBottom(this.xScale)
    //     .tickFormat(d => {
    //       return d + '%';
    //     })
    //     .tickSize(height);
    //   // // Update the bars
    //   // bars.attr('width', d => {
    //   //   return this.xScale(d.num);
    //   // });

    //   // Update the second bars
    //   bars2.attr('width', d => {
    //     return this.xScale(d.num2);
    //   });

    //   // Updates bar labels
    //   barLabels1
    //     .attr('x', d => {
    //       if (minX > 32) {
    //         return this.xScale(d.num) - 37;
    //       } else {
    //         return this.xScale(d.num) + 6;
    //       }
    //     })
    //     .attr('y', 0);
    //   barLabels2
    //     .attr('x', d => {
    //       if (minX > 32) {
    //         return this.xScale(d.num2) - 37;
    //       } else {
    //         return this.xScale(d.num2) - 45;
    //       }
    //     })
    //     .attr('y', 0);

    //   /// bind the numbers
    //   this.barNumbers1 = labelGroup
    //     .append('text')
    //     .text(d => {
    //       return '$' + d.num;
    //     })
    //     .attr('x', 5)
    //     .style('fill', d => {
    //       if (minX > 32) {
    //         return 'white';
    //       } else {
    //         return 'white';
    //       }
    //     })
    //     .attr('y', 25)
    //     .attr('class', 'g-labels');

    //   this.barNumbers2 = labelGroup
    //     .append('text')
    //     .text(d => {
    //       return '$' + d.num2;
    //     })
    //     .attr('x', d => {
    //       if (minX > 32) {
    //         return this.xScale(d.num2) - 37;
    //       } else {
    //         return this.xScale(d.num2) - 25;
    //       }
    //     })
    //     .style('fill', d => {
    //       if (minX > 32) {
    //         return '#10005d';
    //       } else {
    //         return '#10005d';
    //       }
    //     })
    //     .attr('y', 25)
    //     .attr('class', 'g-labels');
    //   // Updates xAxis
    //   xAxisGroup.call(this.xAxis);

    //   // Updates ticks
    //   this.xAxis.scale(this.xScale);
    // }
    // Create Event Handlers for mouse
    // function handleMouseOver(d, i) {
    //   // console.log('mouse in', elem);
    //   return tooltip2.style('visibility', 'visible');
    // }

    // function handleMouseOut(d, i) {
    //   //  console.log('mouse out');
    //   tooltip2.style('visibility', 'hidden');
    // }
    function handleclick() {
      // console.log('clicked');
      //  this.linechart(this.trenddata);
      const ele = elem.nativeElement.querySelector('.hover_bkgr_fricc') as HTMLInputElement;
      if (ele) {
        ele.style.display = 'block';
      }
    }
  }
}
