<div *ngIf="showChartTitle" class=" card-heading table-Header-Nav-font" [ngClass]="customStyle === 'noMargin'? 'noMarginClass' : 'chart-title'">{{title}}</div>
<div id="d" class="donut-chart">
    <!-- <google-chart #chart [type]="this.type" [data]="this.chartData" [columnNames]="this.columnNames"
        [options]="this.options" [width]="this.width" [height]="this.height" (ready)="onReady()" (mouseleave)="onMouseOut($event)" (mouseover)="onMouseOver($event)">
    </google-chart>
    <div *ngIf="total && total != 0 && chartLoaded" id="cnt" class="overlay">
        <div class="tco-text">TCO {{selectedYear}}</div>
        <div>{{amountTotal}}</div>
    </div> -->
    
    <div  class="pie_div tcmcomponent-pie_div-height tcmcomponent-pie_div-padding pie_div-padding"></div>
<div class="donutcharttooltip"></div>
</div>