<table class="uitk-c-table">
  <thead class="uitk-c-table__head">
    <tr>
      <th colspan="2" style="background: none; color:black; padding-bottom: 5px !important;">
        <div style="min-height: 20px !important;padding-top: 4px; margin: -2px; margin-right: 2px !important;">
          Service
        </div>
      </th>
      <th colspan="2" style="background: none; color:black; padding-bottom: 5px !important;">
        <div style="min-height: 20px !important;padding-top: 4px; margin-right: 2px; margin-left: 2px;">
          <!-- Y-o-Y Full Year Volume Growth -->VOLUME: Y-o-Y FY Growth
        </div>
      </th>
      <th colspan="2"
        style="border-left: 1px solid #002677;background: none; color:black; padding-bottom: 5px !important;">
        <div style="min-height: 20px !important;padding-top: 4px; margin-left: 2px;">
          <!-- Full Year Run Rate vs 2021 MBO -->CHARGEBACK: FY Run Rate vs {{PrevYear}} Estimate
        </div>
      </th>
    </tr>
  </thead>
  <tr>
    <td colspan="2"></td>
    <td colspan="2">
      <span style="float: left; color: black; font-size: 11px;">Y-o-Y %</span>
    </td>
    <td colspan="2" style="border-left: 1px solid black;">
      <span style="color: black;font-size: 11px; padding-left: 5px;float: left;">
      </span>
    </td>
  </tr>

  <ng-container *ngFor="let row of pageOfItems; let i = index">
    <tr>
      <td style="width:4%;"> <span title="Click on arrow icon to view details" (click)="accordiontoggle($event)"
        class="accordion uitk-icon uitk-icon-arrow_right uitk-c-panel__icon"></span>
      </td>
      <td style="width:16%; text-align: left;font-size: 12px;font-weight: bold; padding-left: 5px;">{{ row.name }}</td>
      <td style="width: 4%; padding-bottom: 16px !important;">
        <span class="yoyCircle" *ngIf="(row.yoy < 500)">
          {{row.yoy}}%
        </span>
        <span class="yoyCircle" *ngIf="(row.yoy > 500)">
          INF
        </span>
      </td>

      <td style="width:36%;">
        <app-service-bar [data]="row.yoychartdata" [symbal]="'K'" [trend]="false"></app-service-bar>
      </td>

      <td style="width:4%; border-left: 1px solid #002677; padding-bottom: 16px !important;">
        <span class="mboCircle">
        </span>
      </td>
      <td style="width:36%;">
        <app-service-bar [data]="row.mbochartdata" [symbal]="'$'" [mbobar]="true" [mbotooltip]="true" [trend]="true" [trenddata]="row.trenddata"></app-service-bar>
      </td>
    </tr>
    <tr class="expand-panel">
      <td colspan="3" style="width:24%"></td>
      <td style="width:36%;">
        <app-service-bar [data]="row.chargebackchartdata" [symbal]="'K'" [trend]="false"></app-service-bar>
        <!-- [yoytooltip]="true" <app-service-bar [data]="row.chargebackchartdata" [symbal]="'K'" [footertext]="'(Unit of measure: CPU Hours)'"
          [yoytooltip]="true" [trend]="false"></app-service-bar> -->
      </td>
      <td style="width:4%; border-left: 1px solid #002677;"></td>
      <td style="width:36%">
        <app-mbo-service-bar-chart [data]="row.mbovarincechartdata" [symbal]="'$'" [variance]="true" [trend]="true" [trenddata]="row.trenddata">
        </app-mbo-service-bar-chart>
        <!-- <app-service-bar [data]="row.mbovarincechartdata" [symbal]="'$'" [variance]="true" [trend]="true"
          [trenddata]="row.trenddata"></app-service-bar> -->
        <!-- <app-service-bar [data]="row.mbovarincechartdata" [symbal]="'$'"
          [footertext]="'(Variance from target:' + row.variance + '%)'" [variance]="true" [trend]="true"
          [trenddata]="row.trenddata"></app-service-bar> -->
      </td>
    </tr>
    <!--   <tr class="expand-panel">
      <td colspan="6">
        <div class="row m-0 services card uitk-c-card">
          <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
            <table [hidden]="tablehidden[row.name]" class="uitk-c-table" (matSortChange)="change_order($event, i, row.name)" small mat-table
              [dataSource]="dataSource[row.name]" matSort matSortActive="CHARGES" matSortStart="desc"
              matSortDisableClear>
              <ng-container matColumnDef="LONG_APP_NAME">
                <th mat-header-cell *matHeaderCellDef>Application Name</th>
                <td mat-cell *matCellDef="let element">{{ element.LONG_APP_NAME }}</td>
              </ng-container>
              <ng-container matColumnDef="SV_DESC">
                <th mat-header-cell *matHeaderCellDef>Service Description</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.SV_DESC }}
                </td>
              </ng-container>
              <ng-container matColumnDef="CS_SERVICE_UNIQUE_IDENTIFIER">
                <th mat-header-cell *matHeaderCellDef>SUI ID</th>
                <td mat-cell *matCellDef="let element">{{ element.CS_SERVICE_UNIQUE_IDENTIFIER }}</td>
              </ng-container>
              <ng-container matColumnDef="CS_SUI_DESCRIPTION">
                <th mat-header-cell *matHeaderCellDef>SUI Desc</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.CS_SUI_DESCRIPTION }}
                </td>
              </ng-container>

              <ng-container matColumnDef="CHARGES">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Charges added/ dropped in
                </th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.SUI_TYPE == 'New SUI'">+</span>
                  <span *ngIf="element.SUI_TYPE == 'Dropped SUI'">
                    -
                  </span>
                  {{ element.CHARGES | currency : 'USD' : 'symbol' : '1.0-0' }}
                  <span *ngIf="element.SUI_TYPE == 'New SUI'"
                  >
                <span class="material-icons green">
                  trending_up
                  </span>
                  </span>
                  <span *ngIf="element.SUI_TYPE == 'Dropped SUI'">

                    <span class="material-icons red">
                      trending_down
                    </span>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
            </table>

            <mat-paginator [hidden]="tablehidden[row.name]" [pageSize]="3" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
            <span *ngIf="tablehidden[row.name]" class="no-data">No SUIs added or removed this month</span>
          </div>
        </div>
      </td>
    </tr>
  -->
  </ng-container>
</table>

<app-table-pagination *ngIf="Pagination" [items]="data" [pageSize]="pageSize" (changePage)="onChangePage($event)">
</app-table-pagination>

<div style="display: flex; flex-direction: column;">
  <div class="vl">
    <div style="padding-top: 10px; padding-left: 3px;">
      Indicates 2021 Estimate
    </div>
  </div>
  <div style="padding-top: 5px; text-align: left;">
    $ 0 chargebacks imply service chargebacks
    < $1K </div>
      <div style="padding-top: 5px; text-align: left;">
        INF in Y-o-Y% Indicates growth > 500%
      </div>
  </div>
