import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit {

  constructor(private fb: FormBuilder) { }
  @Input() formData: any;
  @Input() parentNode;
  initialValue: any = [];
  anyChangeInData: boolean = false;
  @Output() nameChange: EventEmitter<any> = new EventEmitter();
  @Output() formValue: EventEmitter<any> = new EventEmitter();
  form = this.fb.group({
    name: ['', Validators.required],
    rank: [{ value: null, disabled: true }],
    description: ['', Validators.required],
    // validates date format yyyy-mm-dd

  })
  ngOnInit() {
    this.initialValue.push(this.formData);// [...];
    if (!this.formData.addNewNodes) {
      this.form.setValue({
        name: this.formData.name.trim(),
        rank: this.formData.rank,
        description: this.formData.description.trim()
      });
    }
    else {
      this.form.get('rank').setValue(this.formData.rank);
    }


  }


  validateValueOfName() {
    if (this.form.value.name && this.form.value.name.trim().length < 3) {
      alert('Please add the name to submit the details');

    }
  }

  onSubmit(e) {
    e.preventDefault();
    if ((this.form.value.name && this.form.value.name.trim().length < 3) && (this.form.value.description && this.form.value.description.trim().length < 1)) {
      alert('Please add the name and description');

    } else if (this.form.value.name && this.form.value.name.trim().length < 3) {
      alert('Please add the name and it should be minimum 3 character');
    }
    else if (this.form.value.description && this.form.value.description.trim().length < 1) {
      alert('Please add the description');
    }
    else {
      this.form.value.name = this.form.value.name.trim();
      this.form.value.description = this.form.value.description.trim();
      const finalFormData = {
        changedValue: this.form.value,
        additionalInfo: this.formData,
        editMode: this.formData.addNewNodes ? 'new' : 'update',
        parentNode: this.parentNode
      }
      this.formValue.emit(finalFormData);
    }
  }
  changedName(e) {
    const obj = {
      value: e.target.value,
      row: this.formData,
      addNewNodes: this.formData.addNewNodes
    }
    this.nameChange.emit(obj);
  }

  checkForChanges() {
    const { name, description } = this.initialValue[0];
    return !((this.form.value.name && this.form.value.name.trim() !== name) || (this.form.value.description && this.form.value.description.trim() !== description))
  }

}
