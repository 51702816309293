import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { NgModel } from '@angular/forms';
import { AppHubService } from 'src/app/techopsv4/services/appHubV4Service';

@Component({
  selector: 'app-merge-cap-popup',
  templateUrl: './merge-cap-popup.component.html',
  styleUrls: ['./merge-cap-popup.component.css'],
  providers:[NgModel]
})
export class MergeCapPopupComponent implements OnInit {
  @Input() modelData = [];
  @Input() clonedSelectedCapRowMerge = {};
  @Input() capListForApp = [];
  @Input() excludeModel;
  @Input() application;
  @Output() dataReceived: EventEmitter<any> = new EventEmitter();
  selectedModel;
  childSelectedCapability;
  filteredCapability;
  selectedLevel;
  searchCapability: any;
  parentSelectedlevel = 0;
  parentSelectedCapability;
  filteredCapabilityGlobal;
  searchTxt = '';
  noAssCapability = 'No Associated Capabilities';
  @Input('openCapProposalWQReqArr') openCapProposalWQReqArr:Array<any> = [];
  showWQHyperlink:boolean = false;
  wqList:Array<any> = [];
  isCapabilityChanged: boolean = true;
  constructor(
    public toaster: ToastrService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private ngmodel:NgModel,
    public appHubV4Service: AppHubService
  ) { }

  ngOnInit() {

  }
  ngOnChanges() {
    this.isCapabilityChanged = true;
    this.selectedModel = this.clonedSelectedCapRowMerge['group_model'];
    this.childSelectedCapability = this.clonedSelectedCapRowMerge['selectedCapability'];
    if (this.clonedSelectedCapRowMerge['selectedCapabilityID'].includes('C1-'))
      this.selectedLevel = 1;
    if ( this.clonedSelectedCapRowMerge['selectedCapabilityID'].includes('C2-'))
        this.selectedLevel = 2;
    if ( this.clonedSelectedCapRowMerge['selectedCapabilityID'].includes('C3-'))
        this.selectedLevel = 3;
    this.filterCapabilityBasedOnGroupModel();
    this.showWQOpenRequestHyperlink(this.childSelectedCapability);
  }

  filterCapabilityBasedOnGroupModel(){
    this.parentSelectedCapability = null;
    this.filteredCapability = this.capListForApp.filter((capability) => {
      if (capability.group_model == this.selectedModel && capability.selectedCapabilityID != this.clonedSelectedCapRowMerge['selectedCapabilityID']) {
        return capability;
      }
    });
    this.filteredCapabilityGlobal = this.filteredCapability;
  }

  merge() {
    let requestBody = {
      'parentId': {},
      'childId': {},
      'app_id': this.application.app_id,
      'data': {
        updatedById: localStorage.getItem('MsId'),
        updatedByName: JSON.parse(localStorage.getItem('currentUser')),
        updatedAt: new Date().toISOString(),
      }
    };
    if (this.selectedLevel == 1) {
      requestBody.childId["capabilityL1Id"] = this.clonedSelectedCapRowMerge['selectedCapabilityID'];
      requestBody.childId["capabilityL2Id"] = null;
      requestBody.childId["capabilityL3Id"] = null;
    }
    else if (this.selectedLevel == 2) {
      requestBody.childId["capabilityL2Id"] = this.clonedSelectedCapRowMerge['selectedCapabilityID'];
      requestBody.childId["capabilityL3Id"] = null;
    }
    else if (this.selectedLevel == 3) {
      requestBody.childId["capabilityL3Id"] = this.clonedSelectedCapRowMerge['selectedCapabilityID'];
    }
    if (this.parentSelectedlevel == 1) {
      requestBody.parentId["capabilityL1Id"] = this.parentSelectedCapability.selectedCapabilityID;
      requestBody.parentId["capabilityL2Id"] = null;
      requestBody.parentId["capabilityL3Id"] = null;
    }
    else if (this.parentSelectedlevel == 2) {
      requestBody.parentId["capabilityL2Id"] = this.parentSelectedCapability.selectedCapabilityID;
      requestBody.parentId["capabilityL3Id"] = null;
    }
    else if (this.parentSelectedlevel == 3) {
      requestBody.parentId["capabilityL3Id"] = this.parentSelectedCapability.selectedCapabilityID;
    }
    this.appHubV4Service.mergeCaps(requestBody).subscribe(res => {
      if (res == 'Merged Successfully') {
        this.toaster.success('Capabilities merged successfully.', '', {
          timeOut: 10000,
          positionClass: 'toast-top-center'
        });
        this.appHubV4Service.notifyAboutOrganisationChange();
        this.dataReceived.emit();
      }
    });
  }

  searchCapabilityByName(event) {
    this.searchCapability = event.target.value;
    if (this.searchCapability) {
      this.filteredCapability = this.filteredCapabilityGlobal.filter((cap) => {
        if (cap.capability.toString().toLowerCase().indexOf(this.searchCapability.toLowerCase()) > -1){
          return cap;
        }
      });
    }
    else {
      this.filteredCapability = this.filteredCapabilityGlobal;
    }
    if (!this.filteredCapability.length) {
      this.filteredCapability = ['None'];
    }

}
  getMergedCapability(event) {
    if (event.value == 'None') {
      this.parentSelectedCapability = null;
    }
    else {
      this.isCapabilityChanged = false;
      this.parentSelectedCapability = event.value;
      if (this.parentSelectedCapability.selectedCapabilityID.includes('C1-'))
        this.parentSelectedlevel = 1;
      if (this.parentSelectedCapability.selectedCapabilityID.includes('C2-'))
        this.parentSelectedlevel = 2;
      if (this.parentSelectedCapability.selectedCapabilityID.includes('C3-'))
        this.parentSelectedlevel = 3;
    }
    this.searchTxt = '';
    this.filteredCapability = this.filteredCapabilityGlobal;
    if(event.value.capability)
      this.showWQOpenRequestHyperlink(event.value.capability);
  }
  resetValue(){
    this.searchTxt = '';
    this.ngmodel.reset(this.searchTxt);
    this.filteredCapability = this.filteredCapabilityGlobal;
  }

  showWQOpenRequestHyperlink(capabilityName){
    this.wqList = [];
    this.showWQHyperlink = false;
    const filterData = this.openCapProposalWQReqArr.filter((val)=>{
      if(val.capabilityL3 == capabilityName)
        return val
      else if(val.capabilityL2 == capabilityName && this.checkHierarchyExist(2,3,val))
        return val
      else if(val.capabilityL1 == capabilityName && this.checkHierarchyExist(1,3,val))
        return val
      else if(val.capabilityL0 == capabilityName && this.checkHierarchyExist(0,3,val))
        return val
    })
    if(filterData && filterData.length > 0){
      this.showWQHyperlink = true;
      this.wqList = filterData;
    }
  }

  checkHierarchyExist(index,maxIndex,val){
    let isExist:boolean = true;
    for(let i=index;i<maxIndex; i++){
      if(val['capabilityL' + (index + 1)] != null){
        isExist = false;
      }
    }
    return isExist;
  }

  navigateToWQlistPage(){
    const wqlist = this.wqList.map(val=>val['Request_Id']);
    const baseUrl = window.location.origin;
    const urlparams = wqlist.join(',');
    const wqUrl = baseUrl + '/work-queues?req=wq&id=' + JSON.stringify(urlparams);
    window.open(wqUrl, '_blank');
  }
}