<div  style ="display:flex;align-items:center;justify-content: center; height: 880px; background-color: #EEEEEE; margin-left: -15px; margin-right: -15px;" >
 
  <div class="container">
    <div *ngIf="isIE" class="alert alert-danger" role="alert">
      Please switch to Google Chrome for the best user experience.
    </div>

    <div *ngIf="isEdge" class="alert alert-danger" role="alert">
      Please switch to Google Chrome for the best user experience.
    </div>
    
    <div *ngIf="!isIE && !isEdge" class="bgc" [ngClass]="{'opactiy': loader}">
      <div class="login-card uitk-c-card">
        <div class="row">
          <div style="text-align: center !important; margin-bottom: 25px;" class="col-lg-12 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
          
            <img class="login-uhg-icon" src="/assets/UHGLogo/uhc-logo.svg" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px; text-align: center;">
          
            <span class="main-heading-sign-in" style="text-align: center !important;">Welcome to TechOps Hub</span>
          </div>
        </div>
        <div class="row" style="margin-bottom: 20px;">
          <div class="col-lg-12 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
          
            <div class="heading_sign_in" style="font-size: 12px !important;">Login With Your MS ID</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
            <div class="col-lg-12 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 m_form-group_opid">
              <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                <div class="form-group " [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                  <span class="opid" for="username">MS ID</span>
                  <input style="font-family: 'Arial Regular';" type="text" class="form-control" name="username" [(ngModel)]="model.username"
                    #username="ngModel" required minlength="3" maxlength="15" />
                  <div *ngIf="f.submitted && !username.valid" class="help-block uitk-c-input__message--error">Username is required</div>
                </div>
              
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                  <span class="pwd" for="password">Password</span>
                  <input type="password" autocomplete="off" class="form-control" name="password"
                    [(ngModel)]="model.password" #password="ngModel" required />
                  <div *ngIf="f.submitted && !password.valid" class="help-block uitk-c-input__message--error">Password is required</div>
                </div>
                <div class="form-group">
                <input type="checkbox" id="remember" name="remember" [(ngModel)]="model.remember" #remember="ngModel" value="RememberChecked">
                <span class="remember-me" for="remember"> Remember me</span>
              </div>
                <div class="form-group m_form-group_sign-in">
                  <button style="width: 40%; font-family: 'Arial Bold' !important; font-size: 12px;" [disabled]="loading" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs uitk-btn ">Login</button>
                </div>
               
              </form>
            </div>

           

          </div>
        </div>
      </div>

    </div>
  </div>
</div>

