import { Directive, ElementRef, HostListener, EventEmitter, Input} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { BookmarkDialogPopupComponent } from '../components/bookmark-dialog-popup/bookmark-dialog-popup.component';
import { BookmarkServiceService } from '../services/bookmark-service.service';
import { Bookmark } from '../interface/interface';

@Directive({
  selector: '[appBookmark]'
})
export class BookmarkDirective {
  constructor(private el: ElementRef, public dialog: MatDialog, private service: BookmarkServiceService) { }
  @Input() dialogData: any;
  bookmarkId: number;
  routeSelected: boolean;
  public click = new EventEmitter();
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges() {


  }
  ngOnInit() {
    if (this.dialogData) {

      const msId = localStorage.getItem('MsId');
      this.service.getBookmarkList(msId).subscribe(data => {
        const isBookmarked = this.checkBookmarked(data);

        if (isBookmarked) {
          this.bookmarkId = isBookmarked.id;
          this.routeSelected = true;
          this.el.nativeElement.className = 'uitk-icon uitk-icon-bookmark_filled';
          // this.el.nativeElement.style.color = '#FFD740';
        } else {
          this.bookmarkId = null;
          this.routeSelected = false;
          // this.el.nativeElement.style.color = 'black';
          this.el.nativeElement.className = 'uitk-icon uitk-icon-bookmark';
        }
      });
    }
  }
  @HostListener('click', ['$event.target'])
  onClickBtn() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.dialogData;
    dialogConfig.width = '500px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '250px';
    dialogConfig.height = 'auto';
    if (this.routeSelected) {
      // this.el.nativeElement.style.color = 'black';
      this.el.nativeElement.className = 'uitk-icon uitk-icon-bookmark';
      this.service.deleteBookmark(this.bookmarkId).subscribe(() => {
        this.routeSelected = false;
      });
    } else {

      this.dialog.open(BookmarkDialogPopupComponent, dialogConfig);
    }
  }

  checkBookmarked(bookList: Array<Bookmark>) {
    const { view, data } = this.dialogData;
    const { askId, offeringId, lob, model, capL0, capL1, product, product_family, startDecomTime, endDecomTime, decom_cat, capL2, padu, organisation, applicationnames, cio_lob } = data[0];
    let obj: Bookmark;
    switch (view) {
      case 'app':
        obj = bookList.find(item => {
          if (item.appId === askId && item.model === model) {
            return true;
          }
        });
        break;
      case 'tech':
        obj = bookList.find(item => {
          if (item.offerings === offeringId) {
            return true;
          }
        });
        break;
      case 'lob':
        obj = bookList.find(item => {
          if (item.lob === lob && item.cio_lob === cio_lob) {
            return true;
          }
        });
        break;
      case 'cap':
        obj = bookList.find(item => {
          if (item.capL0 === capL0 && item.capL1 === capL1 && item.model === model) {
            return true;
          }
        });
        break;
      case 'capDetail':
        obj = bookList.find(item => {
          if (item.capL2 === capL2 && item.lob === lob && item.padu === padu) {
            return true;
          }
        });
        break;
      case 'tcm_app':
        obj = bookList.find(item => {
          if (item.applicationnames === applicationnames) {
            return true;
          }
        });
        break;
      case 'tcm_lob':
        obj = bookList.find(item => {
          if (item.applicationnames === applicationnames) {
            return true;
          }
        });
        break;

      case 'product_table':
        obj = bookList.find(item => {
          if (item.product === product && item.product_family === product_family) {
            return true;
          }
        });
        break;

      case 'app_product_grid':
        obj = bookList.find(item => {
          if (item.appId === askId && item.organisation === organisation) {
            return true;
          }
        });
        break;

      case 'product_grid':
        obj = bookList.find(item => {

          if (item.path === view) {
            return true;
          }
        });
        break;

      case 'app_decom':

        obj = bookList.find(item => {
          if (item.path === 'app_decom') {

            if (item.appId === askId && item.cio_lob === cio_lob && item.lob === lob && item.applicationnames === applicationnames
              && item.decom_cat === decom_cat && Number(item.startDecomTime) === startDecomTime && Number(item.endDecomTime) === endDecomTime) {
              return true;
            }
          }
        });


        break;
    }
    return obj;
  }
}
