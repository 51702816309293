<div class="bulk-template">
    <table mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="col.key" *ngFor="let col of columns">
            <th mat-header-cell *matHeaderCellDef class="tabl-header" [style.width]="col.width" [style.textAlign]="col.align"> 
                {{ col.display_name }} 
            </th>
            <td mat-cell *matCellDef="let element" [style.width]="col.width" [style.textAlign]="col.align"> 
                <div [ngSwitch]="col.key">
                    <div *ngSwitchCase="'generate_template'">
                        <button mat-raised-button class="techops-global-button" (click)="generateTemplate(element)">Generate</button>
                    </div>
                    <div *ngSwitchCase="'post_submit'">
                        <button mat-raised-button class="techops-global-button" (click)="postSubmit(element)">Post Submit</button>
                    </div>
                    <div *ngSwitchCase="'lastUpdatedAt'">
                        {{ element[col.key] | date:'MMM d, y, h:mm a' }}
                    </div>
                    <div *ngSwitchCase="'postSubmit_lastUpdatedAt'">
                        {{ element[col.key] | date:'MMM d, y, h:mm a' }}
                    </div>
                    <div *ngSwitchDefault> {{ element[col.key] }}  </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons [length]="dataSource?.data.length"></mat-paginator>
</div>