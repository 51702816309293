import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrl: './add-report.component.css'
})
export class AddReportComponent {
  addReportForm: FormGroup;
  @Input() dataToEdit: any = {};
  @Input() formfields: any = [];
  @Input() tableName: string = '';
  @Input() editFlag: boolean = false;
  @Input() closePopup: boolean = false;
  @Output() emitReqPayload: EventEmitter<any> = new EventEmitter();
  reqPayload: any;

  constructor(private formBuilder: FormBuilder) {
    this.addReportForm = this.formBuilder.group({
      Report: ['', [Validators.required]],
      Description: ['', [Validators.required]],
      Report_Type: ['', [Validators.required]],
      Report_Link: ['', [Validators.required]],
      Access_Restricted: ['yes', [Validators.required]],
      Secure_group: ['', [Validators.required]],
      Secure_Check: ['', [Validators.required]],
      internal_Report: ['', [Validators.required]],
      Documentation: ['', [Validators.required]],
      HasOwner: ['yes', [Validators.required]],
      ownerDetails: ['', [Validators.required]],
      EMbed: ['', [Validators.required]],
      Embed_link: ['', [Validators.required]],
      Category: ['', [Validators.required]],
      Category_rank: ['', [Validators.required]],
      Managedby: ['', [Validators.required]],
      Access_Restriction_link: ['', [Validators.required]],
      Secure: ['', [Validators.required]],
      Page_name: ['', [Validators.required]],
      Image: ['', [Validators.required]],
      Embed_type: ['', [Validators.required]],
      Report_Id: ['', [Validators.required]],
      id: ['']
    });
  }

  ngOnChanges() {
    if (this.editFlag && this.dataToEdit) {
      this.addReportForm.setValue({
        Report: this.dataToEdit.Report,
        Description: this.dataToEdit.Description,
        Report_Type: this.dataToEdit.Report_Type,
        Report_Link: this.dataToEdit.Report_Link,
        Access_Restricted: this.dataToEdit.Access_Restricted,
        Secure_group: this.dataToEdit.Secure_group,
        Secure_Check: this.dataToEdit.Secure_Check,
        internal_Report: this.dataToEdit.internal_Report,
        Documentation: this.dataToEdit.Documentation,
        HasOwner: this.dataToEdit.HasOwner,
        ownerDetails: this.dataToEdit.ownerDetails,
        EMbed: this.dataToEdit.EMbed,
        Embed_link: this.dataToEdit.Embed_link,
        Category: this.dataToEdit.Category,
        Category_rank: this.dataToEdit.Category_rank,
        Managedby: this.dataToEdit.Managedby,
        Access_Restriction_link: this.dataToEdit.Access_Restriction_link,
        Secure: this.dataToEdit.Secure,
        Page_name: this.dataToEdit.Page_name,
        Image: this.dataToEdit.Image,
        Embed_type: this.dataToEdit.Embed_type,
        Report_Id: this.dataToEdit.Report_Id,
        id: this.dataToEdit.id
      })
    }

    if (this.closePopup) {
      this.addReportForm.reset();
    }
  }

  isRequired(currentfield, dependantfield, depFieldVal, mandCheck) {

    if (dependantfield && dependantfield.length) {
      let count = 0;
      for (let i = 0; i < dependantfield.length; i++) {
        switch (mandCheck[i]) {
          case 'equal':
            if ((this.addReportForm.get(dependantfield[i])?.value)?.toLowerCase() == depFieldVal[i]?.toLowerCase()) {
              count++;
            }
            break;
          case 'notEqual':
            if (((this.addReportForm.get(dependantfield[i])?.value))?.toLowerCase() != depFieldVal[i]?.toLowerCase()) {
              count++;
            }
            break;
          default:
            return false;
        }
      }
      if (count == dependantfield.length) {
        this.updateValidators(currentfield, 'yes');
        return true;
      } else{
        this.updateValidators(currentfield, 'no');
        return false;
      }

    } else {
      return true;
    }

  }
  updateValidators(currentfield: any, reqvalidationFlag) {
    const currentFieldControl = this.addReportForm.get(currentfield);
    currentFieldControl.clearValidators();
    if (reqvalidationFlag == 'yes') {
      currentFieldControl.setValidators([Validators.required])
    }
    currentFieldControl.updateValueAndValidity();
  }

  submitForm() {
    if (this.addReportForm.valid) {
      console.log("form is valid", this.addReportForm.getRawValue())
      this.reqPayload = {
        "payload": this.addReportForm.value,
        "editflag": this.editFlag
      }
      this.reqPayload.payload.Report_Type = (this.reqPayload.payload.Report_Type).toLowerCase()== 'power bi' ? 'Power BI' : this.reqPayload.payload.Report_Type;
      this.emitReqPayload.emit(this.reqPayload);
      this.addReportForm.reset();
    }
  }

}
