import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { tcmHubConfigDetails } from "../config";

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private apiUrl: string;
    private config: any;

    constructor(private http: HttpClient,private http2:HttpClient) {
        this.config = tcmHubConfigDetails;
        this.apiUrl = environment.apiUrl;
    }

    public getReports(query): Observable<any> {
        const url = this.apiUrl + this.config.routerpath.reports;
        return this.http.get<any>(url,{params:query});
      }

      public updateReport(req: any): Observable<any> {
        const url = this.apiUrl + this.config.routerpath.updateReport;
        const body = req;
        return this.http.post<any>(url, body);
      }
      public getReport(name): Observable<any> {
        const url = this.apiUrl + this.config.routerpath.report;
        return this.http.get<any>(url,{params:{name}});
      }
       // Power BI REST API call to get the embed URL of the report
  getembedUrl(accessToken,reportId){
    let state = {};
    fetch(environment.powerBiApiUrl + 'v1.0/myorg/reports/' + reportId, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }}).then((response:any) => {
      const errorMessage: string[] = [];
      errorMessage.push('Error occurred while fetching the embed URL of the report');
      errorMessage.push('Request Id: ' + response.headers.get('requestId'));     
          if (response.ok) {
            state = { data: response.body };
           return state;
          }
          // If error message is available
          else {
            errorMessage.push('Error ' + response.status + ': ' + response.body.error.code);

            state = { error: errorMessage };
            return state;
          }
        
    })
  }
}