import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-had',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './had.component.html',
  styleUrls: ['had.component.css']
})
export class HadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() haddata: any;
  @Input() service: any;
  percentage = 0;
  actualPoints = 0;
  possiblePoints = 0;
  color = 'white';
  nodata = false;
  bars3;
  constructor(private container: ElementRef) { }

  ngOnInit() { }
  ngOnChanges() {
    if (this.haddata && this.service === this.haddata.value[0].serviceId) {
      this.hadValue();
    }
    // console.log('hadcom', this.haddata);


  }
  ngAfterViewInit() {
    if (this.haddata && this.service === this.haddata.value[0].serviceId) {
      this.hadValue();
    }
  }

  hadValue() {
    // const ColorData = [{ value: 20 }, { value: 20 }, { value: 20 }, { value: 20 }, { value: 20 }];
    const ColorData = [{ value: 50 }, { value: 25 }, { value: 25 }];

    if (this.haddata != undefined) {
      if (this.haddata.value.length > 0) {
        this.nodata = false;
        const scores = this.haddata.value[0].scores;
        let filterscore = [];
        filterscore = scores.filter(item => {
          return item.category == 'N/A';
        });
        if (filterscore.length > 0) {
          this.percentage = filterscore[0].percentageScore;
          this.actualPoints = filterscore[0].actualPoints;
          this.possiblePoints = filterscore[0].possiblePoints;
          this.color = filterscore[0].scoreColor;
          this.stackedBar('#barchart', ColorData);
        }
      } else {
        this.nodata = true;
      }
    }
  }
  stackedBar(bind, data, config: any = []) {
    // group data for chart and filter out zero values
    function groupData(data, total) {
      // use scale to get percent values
      const percent = d3
        .scaleLinear()
        .domain([0, total])
        .range([0, 100]);
      // filter out data that has zero values
      // also get mapping for next placement
      // (save having to format data for d3 stack)
      let cumulative = 0;
      let labelcumulative = 0;
      const _data = data
        .map(d => {
          cumulative += d.value;
          labelcumulative += d.distance;
          return {
            value: d.value,
            // want the cumulative to prior value (start of rect)
            cumulative: cumulative - d.value,
            labelcumulative: labelcumulative,
            label: d.label,
            percent: percent(d.value)
          };
        })
        .filter(d => d.value > 0);
      return _data;
    }
    config = {
      f: d3.format('.1f'),
      margin: { top: 15, right: 10, bottom: 20, left: 10 },
      width: 130,
      height: 35,
      barHeight: 20,
      colors: ['Red', 'Yellow', 'Green'],
      ...config
    };
    const { margin, width, height, barHeight, colors } = config;
    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;
    const halfBarHeight = barHeight / 2;

    const total = d3.sum(data, d => d.value);
    const _data = groupData(data, total);

    // set up scales for horizontal placement
    const xScale = d3
      .scaleLinear()
      .domain([0, total])
      .range([0, w]);

    // create svg in passed in div
    d3.selectAll('#barchart svg').remove();

    const selection = d3
      .select(bind)
      .append('svg')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', "0 0 130 35")
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // stack rect for each data value
    selection
      .selectAll('rect')
      .data(_data)
      .enter()
      .append('rect')
      .attr('class', 'rect-stacked')
      .attr('x', d => xScale(d.cumulative))
      .attr('y', h / 4 - halfBarHeight)
      .attr('height', barHeight)
      .attr('width', d => xScale(d.value))
      .style('fill', (d, i) => colors[i])
      // .on('click', () => {

      //   window.open(`https://had.optum.com/application-health/${this.service}`, '_blank');

      // })
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut);

    this.bars3 = selection
      .append('rect')
      .attr('width', 2)
      .attr('height', 35)
      .attr('class', 'g-num')
      .style('fill', '#000')
      .attr('transform', () => {
        return 'translate(' + xScale(this.percentage) + ',-16)';
      });

    let tooltip2 = d3
      .select('#barchart')
      .append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', '#000000b8')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '10px')
      .style('z-index', '2000')
      .html(
        `<p style="color:#fff">Scale</p>
          <ul style="color: #fff;
          list-style-type: none;width: 100%;
          display: contents;">
          <li style="display: flex;
          width: 100%;"><span style="width: 12px;
          background: red;
          height: 12px;
          display: block;
          margin: 2px;"></span>0-50</li>
          <li style="display: flex;
          width: 100%;">
          <span style="width: 12px;
          background: yellow;
          height: 12px;
          display: block;
          margin: 2px;">
          </span>50-75</li>
          <li style="display: flex;
          width: 100%;">
          <span style="width: 12px;
          background: green;
          height: 12px;
          display: block;
          margin: 2px;"></span>75-100</li>
          </ul>
      `
      );

    // Create Event Handlers for mouse
    function handleMouseOver() {
      //console.log('mouse in');
      return tooltip2.style('visibility', 'visible');
    }

    function handleMouseOut() {
      // console.log('mouse out');
      return tooltip2.style('visibility', 'hidden');
    }
    // function handleclick(d, i) {
    //   window.open(`https://had.optum.com/application-health/${this.service}`, '_blank');
    //   // console.log('clicked');
    // }
  }
  openhad() {


    window.open(`https://had.optum.com/application-health/${this.service}`, '_blank');

  }
  ngOnDestroy() { }
}
