import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Sort, MatSort, } from '@angular/material/sort';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MAT_CHIPS_DEFAULT_OPTIONS, } from '@angular/material/chips';
import { SelectionModel } from '@angular/cdk/collections';
import { ConstantsData } from 'src/app/constants/fields';
import { ENTER } from '@angular/cdk/keycodes';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CommonModelService } from 'src/app/padu-reporting/capability/common-model.service';
import { MaintenanceModalComponent } from 'src/app/maintenance/shared/maintenance-modal/maintenance-modal.component';

@Component({
  selector: 'app-maintenance-table',
  templateUrl: './maintenance-table.component.html',
  styleUrls: ['./maintenance-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: MAT_CHIPS_DEFAULT_OPTIONS,
    useValue: {
      separatorKeyCodes: [ENTER]
    }
  }]
})
export class MaintenanceTableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() COLUMNS: any[] = ["Group Name", "Group Type", "No Of Applications", "Visibility", "Action"];
  @Input() SCHEMA;
  @Input() TABLE_CONFIG;
  @Input() SORT_CONFIG: Sort;
  @ViewChild(MatSort, { static: false }) DynamicSort: MatSort;
  @ViewChild('pagination', { static: true, read: true }) DynamicPaginator;
  @ViewChild('pagination', { static: false }) StaticPaginator;
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  futurePaduDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Output() paginationClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() sortChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() searchEvent: EventEmitter<any> = new EventEmitter();
  @Output() checkClick: EventEmitter<any> = new EventEmitter();
  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  @Output() CreateEvent: EventEmitter<any> = new EventEmitter();
  @Output() checkBoxEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteRecordEvent: EventEmitter<any> = new EventEmitter();
  @Output() removeRecordEvent: EventEmitter<any> = new EventEmitter();

  @Input() pageSize: number = 0;
  @Input() pageIndex: number = 0;
  @Input() pageName: string;
  private subject: Subject<string> = new Subject();

  currentYear = new Date().getUTCFullYear();
  editExpandDataSource = [];

  displayedColumns: any[];
  dataSchema;
  dataFilter;
  originalDataFilter;
  dataTotalItems;
  selection = new SelectionModel<any>(true, []);
  paduLong = ConstantsData.PADUFullName;
  expandedElement;
  element: any;
  addCapability;
  separatorKeysCodes: number[] = [ENTER];
  inputElement: any;
  selectedRow: any;
  originalEditRow: any;
  constructor(private capabilityService: CommonModelService,
    private toastrService: ToastrService, public dialog: MatDialog,) {

    this.addCapability = this.capabilityService.addCapability.subscribe((value: any) => {
      let data = this.dataSource.data;
      data = data.filter((ele) => !ele['isNew']);
      data.unshift(value);
      this.dataSource = new MatTableDataSource(data);
    });

  }

  ngOnInit() {
    console.log("Hey this is console", this.dataSource);
    this.displayedColumns = this.COLUMNS.map(element => element.key);
    this.inputElement = '';
    this.dataSchema = this.SCHEMA;
    this.selectedRow = '';
    if (this.TABLE_CONFIG) {
      if (this.TABLE_CONFIG.selection) {
        this.displayedColumns.unshift('select');
      }

      if (this.TABLE_CONFIG.FILTER_OPTIONS) {
        this.dataFilter = this.TABLE_CONFIG.FILTER_OPTIONS;
        this.originalDataFilter = JSON.parse(JSON.stringify(this.TABLE_CONFIG.FILTER_OPTIONS));
      }
    }

    this.subject.pipe(
      debounceTime(500)
    ).subscribe(searchObj => {
      this.handleSearch(searchObj);
    });

  }

  ngOnDestroy() {
    if (typeof this.addCapability != 'undefined') {
      this.addCapability.unsubscribe();
    }
  }


  ngOnChanges() {

    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      this.dataTotalItems = this.TABLE_CONFIG.totalItems;
      if (this.TABLE_CONFIG.serverSidePagination) {
        this.dataSource.paginator = this.DynamicPaginator;
      } else {
        this.dataSource.paginator = this.StaticPaginator;
      }

      if (this.SORT_CONFIG && this.DynamicSort) {
        this.DynamicSort.active = this.SORT_CONFIG.active;
        this.DynamicSort.direction = this.SORT_CONFIG.direction;
        this.dataSource.sort = this.DynamicSort;
      }
    }
  }

  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data.length) {
      this.dataTotalItems = this.TABLE_CONFIG.totalItems;
      if (this.TABLE_CONFIG.serverSidePagination) {
        this.dataSource.paginator = this.DynamicPaginator;
      } else {
        this.dataSource.paginator = this.StaticPaginator;
      }
      if (this.SORT_CONFIG && this.DynamicSort) {
        this.DynamicSort.active = this.SORT_CONFIG.active || '';
        this.DynamicSort.direction = this.SORT_CONFIG.direction;
      }
      this.dataSource.sort = this.DynamicSort;

    }

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    let params: any = {};
    if (this.isAllSelected()) {
      this.selection.clear();
      params['type'] = 'clearall';
      params['data'] = this.selection.selected
    } else {
      this.selection.select(...this.dataSource.data);
      params['type'] = 'selectall';
      params['data'] = this.selection.selected
    }
    this.checkBoxEvent.emit(params);
  }

  selecttoggle() {
    let params: any = {};
    if (this.selection.selected.length > 0) {
      params['type'] = 'selectSpecific';
      params['data'] = this.selection.selected
      this.checkClick.emit({ check: true, data: this.selection.selected });
    } else {
      params['type'] = 'unselect';
      params['data'] = this.selection.selected
      this.checkClick.emit({ check: false, data: [] });
    }
    this.checkBoxEvent.emit(params);
  }

  // cheskboxChanged(event, row) {
  //   event ? this.selection.toggle(row) : null;
  //   let params: any = {};
  //   params['type'] = 'singlecheck';
  //   params['checked'] = event.checked;
  //   params['data'] = this.selection.selected;
  //   this.checkBoxEvent.emit(params);
  // }

  /** The label for the checkbox on the passed row */

  /**
 * Server side Pagination Starts
 */

  fetchMoreItems(event) {
    if (this.SORT_CONFIG) {
      event.sort = [[this.SORT_CONFIG.active, this.SORT_CONFIG.direction]];
    }
    this.paginationClickEvent.emit(event);
    return false;
  }

  sortData(event) {
    let sort: any[] = [];
    if (event.direction) {
      sort = [[event.active, event.direction]];
    }
    this.sortChangeEvent.emit(sort);
  }


  deleteRecord(element) {
    element.isEdit = false;
    this.selection.clear();
    // let params = {};
    // params['type'] = 'unselect';
    // params['data'] = this.selection.selected
    // this.checkBoxEvent.emit(params)
    if (element.isNew) {
      let data = this.dataSource.data.filter((ele) => !ele['isNew']);
      this.dataSource = new MatTableDataSource(data);
    } else {
      for (let key in element) {
        element[key] = this.selectedRow[key]
      }
      // element= this.selectedRow;
      // this.deleteRecordEvent.emit(element);
      // element = JSON.parse(JSON.stringify(this.selectedRow));
      // this.selectedRow = '';
    }
  }

  removeRecord(element) {
    this.removeRecordEvent.emit(element);
  }

  enableEditView(element) {
    this.selectedRow = JSON.parse(JSON.stringify(element));
    element.isEdit = !element.isEdit;
    element.addsublob = false;
    if (typeof element['SubLineofBusiness'] != 'undefined') {
      if (!element['SubLineofBusiness'] || element['SubLineofBusiness'] == null) {
        element.addsublob = true;
      }
    }
  }

  applyLocalFilter(column) {
    const obj = {
      column: column,
      type: 'apply'
    };
    column.search = '';
    this.searchEvent.emit(obj);
  }

  clearLocalFilter(column) {
    const obj = {
      column: column,
      type: 'clear'
    };
    column.searchValue = '';
    column.search = '';
    this.searchEvent.emit(obj);
  }


  keyUpSearch(name, column) {
    let p: any = {}
    p['search'] = name.target.value;
    p['column'] = column;
    this.inputElement = name;
    if (name.target.value) {
      this.subject.next(p);
    } else {
      column.options = [];
    }
  }

  handleSearch(searchObj) {
    let value = searchObj['search'];
    let column = searchObj['column'];
    if (value) {
      this.capabilityService.getLDAPUsers(value).subscribe(data => {
        column.options = [];
        data.forEach(element => {
          column.options.push({employeeID:element.employeeID,displayName:element.displayName,middleName:element.middleName,mail:element.mail, givenName: element.givenName, sn: element.sn, name: element.givenName + ' ' + element.sn, key: element.cn });
        });
      }, (error) => {
        console.error(error);
      });

    } else {
      column.options = [];
    }
  }


  saveRecord(element) {
    let validationsColumns = this.COLUMNS.filter((column) => column.validations);
    let requiredColumns = validationsColumns.map((column) => {
      let req = column.validations.filter((val) => val.required);
      if (req) {
        return column;
      }
    });

    if (requiredColumns && requiredColumns.length) {
      let missingData = [];
      requiredColumns.forEach((col) => {
        let lab = col.labelKey ? col.labelKey : col.key;
        if (!element[lab]) {
          missingData.push(col.label);
        } else {
          if (!element.isNew) {
            let lab = col.key;
            if (!element[lab]) {
              missingData.push(col.label);
            }
          }
        }
      });
      if (missingData.length) {
        let message = 'Please enter values for ' + missingData.join(', ');
        this.toastrService.error(message, '', { timeOut: 3000, positionClass: 'toast-top-center' });
        return false;
      }
    }
    // if (element.isNew) {
    switch (this.TABLE_CONFIG && this.TABLE_CONFIG.TABLE_CLASS) {
      case 'lob-maintenance-tble-container':

        if (element.isNew) {
          let data = this.dataSource.data;

          let value = data.find((ele: any) => !ele.isNew && ele.MasterSegment == element['SegID']['value'] && ele.CIOLOB == element['LobID']['value'] && ele.SubLineofBusiness == element['SubLobID']['value']);
          if (value) {
            let message = 'Segment/LOB/Sub LOB combination already exists, Please select other combination. ';
            this.toastrService.error(message, '', { timeOut: 3000, positionClass: 'toast-top-center' });
            return false;
          }
        } else {

          let masterFilters = this.dataFilter['MasterData'];
          let lobFilterList = this.dataFilter['CIOLOB'];
          let sublobfilterlist = this.dataFilter['SubLineofBusiness'];
          let loblist: any[] = [];
          let subloblist: any[] = [];

          loblist = masterFilters.map((masele) => {
            if (masele.subLob && masele.subLob.length) {
              masele.subLob.map((sube) => {
                subloblist.push(sube);
              })
            }
            return masele.lob;
          });

          if (element['CIOLOB']) {
            const lobResult = loblist.find(lobe => {
              return lobe && lobe.toLowerCase() === element['CIOLOB'].toLowerCase();
            });
            if (lobResult) {
              let l = lobFilterList.find((e) => e.value && e.value.toLowerCase() === element['CIOLOB'].toLowerCase());
              if (l.key != element['LobID']) {
                this.toastrService.error("LOB already exists, Please try with other name.", '', { timeOut: 3000, positionClass: 'toast-top-center' });
                return false;
              }
            }
          }
          if (element['SubLineofBusiness']) {
            const sublobResult = subloblist.find(sub => {
              return sub && sub.sub_lob && sub.sub_lob.toLowerCase() === element['SubLineofBusiness'].toLowerCase();
            });
            if (sublobResult) {
              let subl = sublobfilterlist.find((e) => e.value && e.value.toLowerCase() === element['SubLineofBusiness'].toLowerCase());
              if (subl.key != element['SubLobID']) {
                this.toastrService.error("Sub LOB already exists, Please try with other name.", '', { timeOut: 3000, positionClass: 'toast-top-center' });
                return false;
              }
            }
          }
    }

    // }
    this.saveEvent.emit(element);
    element.isEdit = !element.isEdit;

    }
  }
  getFiltersList(col, from = '', record: any = '') {
    let dataFilter = from == 'filter' ? this.originalDataFilter : this.dataFilter;
    if (dataFilter && dataFilter[col.key]) {
      if (record) {
        if (['CIOLOB', 'SubLineofBusiness'].includes(col.key)) {
          if (!record['MasterSegment']) {
            return [];
          }
        }
        if (record.addsublob) {
          if (['SubLineofBusiness'].includes(col.key)) {
            return [];
          }
        }
        let options = dataFilter[col.key];
        options = options.sort((a, b) => a['value'] < b['value'] ? -1 : 1)
        return options;
      }
      let options = dataFilter[col.key];
      options = options.sort((a, b) => a['value'] < b['value'] ? -1 : 1)
      return options;
    }
    return [];
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      // this.fruits.push(value);
    }
  }

  remove(option, element, col): void {
    if (element[col.key]) {
      const index = element[col.key].indexOf(option);
      if (index >= 0) {
        element[col.key].splice(index, 1);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent, col, element): void {

    if (!element[col.key]) {
      element[col.key] = [];
    }
    let obj: any = {
      msid: event.option.value.key,
      givenName: event.option.value.givenName,
      sn: event.option.value.sn,
      employeeID: event.option.value.employeeID,
      mail: event.option.value.mail,
      middleName: event.option.value.middleName,
      displayName: event.option.value.displayName
    }
    element[col.key].push(obj);
    if (this.inputElement) {
      this.inputElement.target.value = '';
    }
    col.options = [];
  }

  getRequiredString(column) {
    if (column && column.validations) {
      let req = column.validations.filter((val) => val.required);
      if (req) {
        return '*';
      }
    }
    return;
  }

  onChangeInputField(col: any, event, record) {

    if (event && event.value) {
      record[col['key']] = event.value.value;
      if (this.TABLE_CONFIG && this.TABLE_CONFIG.dependentFilters) {
        let dependents = this.TABLE_CONFIG.dependentFilters;
        let masterFilters = this.dataFilter['MasterData'];
        let masterSegment;
        let lobMatchingList;
        switch (col.key) {
          case 'MasterSegment':
            masterSegment = masterFilters.filter((ele) => ele.master_segment == event.value.value);
            if (masterSegment && masterSegment.length) {
              let sublobList: any[] = [];
              let lobList = masterSegment.map((masele) => {
                if (masele.subLob && masele.subLob.length) {
                  masele.subLob.map((sube) => {
                    if (sube) {
                      sublobList.push(sube.sub_lob);
                    }
                  })
                }

                return masele.lob;
              });
              if (lobList && lobList.length) {
                let deps = dependents[col.key];
                deps.forEach(element => {
                  if (this.dataFilter[element] && element == 'CIOLOB') {
                    const uniquelob = [...new Set(lobList)];
                    let uniql = uniquelob.map((sube) => {
                      let option: any = {}
                      option['key'] = sube;
                      option['value'] = sube;
                      option['disable'] = false;
                      return option;
                    });

                    this.dataFilter[element] = uniql;
                    record['LobID'] = '';
                  }
                  if (this.dataFilter[element] && element == 'SubLineofBusiness') {
                    const uniqueSublob = [...new Set(sublobList)];
                    uniqueSublob.map((sube) => {
                      let option: any = {}
                      option['key'] = sube;
                      option['value'] = sube;
                      option['disable'] = false;
                      return option;
                    });

                    this.dataFilter[element] = [];//uniqsub
                    record['SubLobID'] = '';
                  }
                });
              }
            }
            break;

          case 'CIOLOB':
            lobMatchingList = masterFilters.filter((ele) => ele.lob == event.value.value);
            if (lobMatchingList && lobMatchingList.length) {
              let sublobListInner: any[] = [];
              lobMatchingList.map((masele) => {
                if (masele.subLob.length) {
                  masele.subLob.map((sube) => {
                    if (sube) {
                      sublobListInner.push(sube.sub_lob);
                    }
                  })
                }
              });
              if (sublobListInner.length) {
                const uniqueSublobInner = [...new Set(sublobListInner)];
                let uniqsubInner = uniqueSublobInner.map((sube) => {
                  let option: any = {}
                  option['key'] = sube;
                  option['value'] = sube;
                  option['disable'] = false;
                  return option;
                });
                this.dataFilter['SubLineofBusiness'] = uniqsubInner;
                record['SubLobID'] = '';
              }
            }
            break;
        }
      }
    }
  }

  getNavigationLink(col, element) {
    switch (col.key) {
      case 'offeringId':
        return '/technology-insights?offeringId=' + element[col.key]
        break;  
    }
  }

  createNew(col, element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Create New ' + col.label,
      Action_Type: 'Add_' + col.key,
      label: col.label,
      MasterData: this.dataFilter['MasterData']
    };
    dialogConfig.width = '35%';
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = 'maintenance-modal';
    dialogConfig.height = '40%';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(MaintenanceModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status == 'create') {
          result.element = element;
          this.CreateEvent.emit(result);
        }
      }
    });
  }

  checkCondition(column, record) {
    if (column && column.addNew) {
      switch (column.key) {
        case 'CIOLOB':
          if (record['MasterSegment']) {
            return true
          }
          return false;
        case 'SubLineofBusiness':
          if (record['MasterSegment'] && record['CIOLOB']) {
            return true
          }
          return false;
          break;
      }
    }
    return false;
  }

  checkboxLabel() {
    console.log('Checkboxlevel');
  }

}



