<div  class="row col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 landingpageservices-ServicesDiv landingpageservices-ServicesDiv-margin"
[ngClass]="tilesLength===6? 'landingpageservices-container-height': 'landingpageservices-container-height1'">
    <div class="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5 landingpage-sideText-padding">
        <div class="landingpageservices-ServicesHeader landingpage-sideText-header">
            <span *ngIf="bodyTitle.visibility == 'True'">{{bodyTitle.Content}}</span>
        </div>
        <div class="landingpageservices-ServicesDesc">
            <!--<span>Provide access to assist our executives in upholding technology roadmaps, harmonizing capacities and expenditures, and effectively overseeing our reporting requisites. Some of the services we provide include</span>-->
            <ul class="services-list landingpageservices-list-desc">
                <li class="landingpageservices-list-desc-margin" *ngFor="let service of services">
                    {{service}}
                </li>
                <!-- <li class="landingpageservices-list-desc-margin">Provide accurate and dependable chargeback, decommission and labor data from trustworthy sources like MPWR, AIDE and NEDD.</li>
                <li class="landingpageservices-list-desc-margin">Compile data into a cohesive format that empowers users with precise & useful insights to make informed decisions related to application roadmaps, Enterprise Strategic Initiatives, and Optimization Opportunities.</li>
                <li class="landingpageservices-list-desc-margin">TechOps Hub stands as the exclusive source of truth of Application PADU and roadmap, Capability PADU, Capability Models, Organization & LOB data.</li> -->
                <!--<li>Generation of reports based on Application or Capability PADU, Application chargebacks, decommission details and labor data</li>-->
            </ul>
        </div>
    </div>
    <div class="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-7 landingpage-servicetiles-padding">
        <div class="row landingpageservices-tilesDiv">
            <ng-container *ngFor="let tile of tiles">
                <div *ngIf="tile.Visibility=='True'"
                    class=" landingpageservices-tileDiv landingpageservices-tileDiv-height"
                    (click)="navigate(tile)">
                    <img class="landingpageservices-tileImage landingpageservices-tileImage-height" src="{{tile.icon}}"><br>
                    <span class="landingpageservices-tileHeader header-menu-font">{{tile.Tile}}</span> <br>
                    <p class="landingpageservices-tileDesc landingpage-body-menu-text-font landingpageservices-tileDesc-font">{{ tile.Description }}</p>
                    <div class="tooltipCard " *ngIf="tile.CostData=='Yes'">
                        <div class="landingpageservices-tooltip-content landingpageservices-tileDesc-font">
                            <!-- <p class="landingpageservices-tooltip-content landingpageservices-tileDesc-font">{{ tile.Description }}</p> -->
                            <ng-container>
                                <span class="landingpageservices-nextRefreshData">Next Cost Data Refresh:
                                    {{tcmNextRefresh}}</span><br />
                                <span class="landingpageservices-nextRefreshData">Cost Data Available:
                                    {{tcmAvailableData}}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
</div>