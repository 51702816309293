import { environment } from '../environments/environment';

export let tcmHubConfigDetails = {
  environment: environment.environmentName,

  envApiUrl: environment.apiUrl,

  routerpath: {
    applicationListPath: 'v3/tcm-app-view/listAllApplicationsList',
    manageApplicationList: 'v3/tcm-app-view/listManageApplication',
    sloTechList: 'v3/tcm-app-view/listSloTechOwnerList',
    groupAppList: "v3/tcm-app-view/listAllApplicationsListGroups",
    getSloListForTechOwner: 'v3/tcm-app-view/sloOwnerListForSelectedTecOwner',
    getTechOwnerListForSloOwner: 'v3/tcm-app-view/techOwnerListForSelecedSloOwner',
    getDataByApplicationName: 'tcm-app-view/listApplicatioByName',
    getAppsForSelectedSloAndTecOwner: 'v3/tcm-app-view/sloOwnerListforSelTecNSlo',
    getSuiDeatilsForApp: 'tcm-app-view/getSuiDetailsByAppName',
    getSuiDropDetailsForApp: 'tcm-app-view/getSuiDropoutDetailsByAppName',
    getAllAppForLob: 'tcm-lob-view/allAppsForSelectedLob',
    getOmDetails: 'tcm-lob-view/getOMDetails',
    getApplicationDetailsByNameV2: 'v3/tcm-app-view/tcmhubV2applicationdetails',
    getlobServices: 'v3/lob/lobservices',
    getLobDeatilsByNameV2: 'v3/lob/tcmhubV2LobDetails',
    getLobServiceDetails: 'v3/lob/tcmhubV2LobServiceDetails',
    getServiceDetailsByNameV2: 'v3/tcm-app-view/tcmhubV2monthDataByService',
    getSuiDetails: 'tcm-app-view/tcmhubV2getSuiDetails',
    getfinancialDetails: 'tcm-app-view/tcmhubV2getfinancialDetails',
    getmonthchargeyear: 'v3/tcm-app-view/monthlychargesByYear',
    getmonthchargeyearbarchart: 'v3/lob/lobmonthlychargesByYear',
    getaddedservicebyapp: 'v3/tcm-app-view/newlyAddedserviceByApp',
    getaddedservicebylob: 'v3/tcm-app-view/newlyAddedserviceByLob',
    getServiceCatDetails: 'v3/tcm-app-view/ServiceCatDetails',
    getServiceconsumption: 'v3/tcm-app-view/Serviceconsumption',
    getserviceDetails: 'v3/tcm-app-view/serviceDetails',
    getsuiDetails: 'v3/tcm-app-view/suiDetails',
    getlobSuiDetails: 'v3/tcm-app-view/lobsuiDetails',
    gettopsuiDetails: 'v3/tcm-app-view/topsuiDetails',
    getsuiaddeddropped: 'v3/tcm-app-view/addedDroppedSuiDetails',
    getkpiapplist: 'v3/tcm-app-view/kpiDetails',
    getgroupmodelsofApplications: 'v3/tcm-app-view/groupmodelsofApplications',
    getdecommisionstatus: 'v3/tcm-app-view/decommisionstatus',
    //Labour Hub Routes Start
    getLaborHubEmployeeDetails: 'lh/api/laborhub',
    // getLaborHubFilters: 'lh/api/commonservices/labourhubfilters',
    getLaborHubFilters: 'lh/api/labourhubfilters',
    getDataByTimeCategory: 'lh/api/laborhub/insightsbycategory',
    getHoursByCategory: 'lh/api/laborhub/hoursbytimecategory',
    getBlockLevelDetails: 'lh/api/laborhub/blockLevelDetails',
    getHoursPerByTimeCategoryTrend: 'lh/api/laborhub/hoursbytimecategorytrend',
    getGLDeptWiseData: 'lh/api/laborhub/gldepartmentdetails',
    getGLDeptDevHours: 'lh/api/laborhub/gldepartmentdevhours',
    getGLDeptOMHours: 'lh/api/laborhub/gldepartmentomhours',
    getLaborTypeDevHours: 'lh/api/laborhub/labortypedevhours',
    getLaborTypeOMHours: 'lh/api/laborhub/labortypeomhours',
    getHeadCountTrend: 'lh/api/laborhub/headcounttrend',
    getKeywordBasedEmployees: 'lh/api/laborhub',
    getEmployeeList: 'lh/api/laborhub/employee',
    getAclList: 'lh/api/acl/get',
    createAcl: 'lh/api/acl',
    updateAcl: 'lh/api/acl',
    getEmployeeRole: 'lh/api/labourhubfilters/employeerole',
    getAccessList: 'lh/api/acl/me',
    getFTEFiltersList: 'lh/api/ftefilters',
    getFTECount: 'lh/api/fte/count',
    getFTEBlockLevelData: 'lh/api/fte/block',
    getFTESummary: 'lh/api/fte/summary',
    getFTESummaryMovement: 'lh/api/fte/resourcechanges',
    getFTESummaryEmployeeLevelData: 'lh/api/fte/resourcedetails',
    getFTEMovementCount: 'lh/api/fte/execmovement',
    getFTESummaryEmployeeLevelDataMoved: 'lh/api/fte/execmovementdetails',
    getFTEList: 'lh/api/acl/get',

    //End
    getapplicationVunerableStatus: 'v3/tcm-app-view/applicationVunerableStatus',
    validateapp: 'v3/tcm-app-view/validateapp',
    getvalidateapp: 'v3/tcm-app-view/getvalidateapp',
    triggerhelpMail: '/v3/tcm-app-view/triggerMail/',
    triggerMail: '/v3/tcm-app-view/triggerMail/',
    addcomment: 'v3/tcm-app-view/addcomment',
    addtechopsDevAdminPage:'v3/tcm-app-view/addtechopsDevAdminPage',
    getTechopsAdminTable:'v3/tcm-app-view/getTechopsAdminTable',
    updateTechopsAdminTable:'v3/tcm-app-view/updateTechopsAdminTable',
    updatecomment: 'v3/tcm-app-view/updatecomment',
    deletecomment: 'v3/tcm-app-view/deletecomment',
    getcomments: 'v3/tcm-app-view/getcomments',
    facilitatorMail: '/v3/tcm-app-view/facilitatorMail',
    bulkeditGroup: 'v3/group/editbulkgroup',
    addBulkGroup: 'v3/group/addbulkgroup',
    addgroup: 'v3/group/addgroup',
    updategroup: 'v3/group/updategroup',
    deletegroup: 'v3/group/deletegroup',
    deletemappedapps: 'v3/group/deleteapps',
    getgroup: 'v3/group/getgroup',
    getAllApps: '/v3/group/getAllApps',
    getAllSlo: '/v3/group/getAllSlo',
    getAllTechOwners: '/v3/group/getAllTechOwners',
    gettech360padu: 'v3/tcm-app-view/gettech360padu',
    getperson: 'v3/tcm-app-view/getperson',
    //get optimizedAppList
    getOptimizedList: 'v3/tcm-optimize/getOptimizedlist',
    getKpiData: 'v3/tcm-optimize/getoptimizekpi',
    getOptimizeData: 'v3/tcm-optimize/getoptimzedetails',
    getoptimizedapp: 'v3/tcm-optimize/getoptimizedappDetails',
    neutralizeflag: 'v3/tcm-optimize/neutralizeflag',
    bulkneutralizeflag: 'v3/tcm-optimize/bulkneutralizeflag',
    flagAccess: 'v3/tcm-optimize/getaccessFlag',
    flagAccessData:'v3/tcm-optimize/getaccessFlagData',
    addflagAccessData: 'v3/tcm-optimize/addAccessFlagPageData',
    GetProductModelList: '/pmm',
    getcurrentMonthYear: 'v3/tcm-app-view/getYearmonth',
    getcart: 'v3/tcm-reclass/getcart',
    savecart: 'v3/tcm-reclass/savecart',
    updatecart: 'v3/tcm-reclass/updatecart',
    deletecart: 'v3/tcm-reclass/deletecart',
    getrequests: 'v3/tcm-reclass/getrequests',
    getnames: 'v3/tcm-reclass/getnames',
    getfincinfo:'v3/tcm-reclass/getfincinfo',
    sendrequest: 'v3/tcm-reclass/sendrequest',
    getreClassApps: 'v3/tcm-reclass/getreClassApps',
    getreClassSUIs: 'v3/tcm-reclass/getreClassSUIs',
    getdesreClassApps: 'v3/tcm-reclass/getdesreClassApps',
    updateRequest: 'v3/tcm-reclass/updateRequest',
    deleteRequest: 'v3/tcm-reclass/deleteRequest',
    sendcartrequest: 'v3/tcm-reclass/sendcartrequest',
    deletecartitem: 'v3/tcm-reclass/deletecartitem',
    updateBulkRequest: 'v3/tcm-reclass/updateBulkRequest',
    updateFinancialApproval: 'v3/tcm-reclass/updateFinancialapproval',
    helpemail: 'v3/tcm-reclass/helpemail',
    compltstsmail: 'v3/tcm-reclass/compltstsmail',
    updatecartitem: 'v3/tcm-reclass/updatecartitem',
    revertRequest: 'v3/tcm-reclass/revertRequest',
    getLobApps: "v3/group/getlobapps",
    groupDelegateAccess: 'v3/group/groupDelegateAccess',
    //syncjobs
    getneddrefresh: 'v3/sync/get_decomm',
    getneddrefresh_V1: 'v3/sync/get_decomm_V1',
    getnddrefresh: 'v3/sync/neddapi/v1/techOps/ndd_daily_syn',
    getGLrefresh: 'v3/tcm-reclass/Glaide',
    getGLrefresh_V1: 'v3/tcm-reclass/Glaide_V1',
    reports:'/v3/report/reportDetails',
    report:'/v3/report/getreport',
    updateReport:'/v3/report/update'
  },
  CurrentMonth: setConfig('CurrentMonth'),
  PrevMonth: setConfig('PrevMonth'),
  Month: setConfig('Month'),
  PreMonth: setConfig('PreMonth'),
  CurrentMonthIndex: setConfig('CurrentMonthIndex'),
  CurrentMonthPrevYear: setConfig('CurrentMonthPrevYear'),
  CurrentYear: setConfig('CurrentYear'),
  PrevYear: setConfig('PrevYear'),
  CurrentMonthFull: setConfig('CurrentMonthFull'),
  ForcastCycle: setConfig("ForcastCycle"),
  omConfig: 'OM',
  omAsgConfig: 'OMASG',
  isConfig: 'ISA',
  hcpConfig: 'HCP',
  lobListOrderTCMHub: [
    'UHC E&I',
    'UHC Global',
    'UHC C&S',
    'UHC M&R',
    'UHC CORE Services',
    'UHC LCRA',
    'UHC Provider',
    'EEPS',
    'Clinical Services',
    'OptumInsight PE',
    'Optum International',
    'Optum Growth Office',
    'Optum Ops',
    'OptumRx',
    'OptumCare Tech',
    'Corporate Systems',
    'Data Solutions',
    'Emerging Business'
  ],
  timelineDataTCMHUB: [
    { month: 'March 2020', date: 'April 8, 2020' },
    { month: 'April 2020', date: 'May 8, 2020' },
    { month: 'May 2020', date: 'June 9, 2020' },
    { month: 'June 2020', date: 'July 9, 2020' },
    { month: 'July 2020', date: 'August 10, 2020' },
    { month: 'August 2020', date: 'September 9, 2020' },
    { month: 'September 2020', date: 'October 8, 2020' },
    { month: 'October 2020', date: 'November 9, 2020' },
    { month: 'November 2020', date: 'December 9, 2020' },
    { month: 'December 2020', date: 'January 11, 2021' }
  ]
};
export const appModernizationDetails = {
  prodApiUrl: '',
  uatApiUrl: '',
  qaApiUrl: '',
  localApiUrl: 'http://localhost:1337',
  routerpath: {
    // get open work queue requests 
    getCapOpenWqReq: '/v4/wqlob/get-open-cap-req',
    notificationUrl : '/v4/sync/notifications/',
    notificationListUrl : '/v4/sync/notificationsList/',
    applist: '/app-view/app-list',
    orgList: '/products/org-list',
    appDetails: '/app-view/app-details',
    capabilityRedudency: '/app-view/capability-redundancy/v1',
    capabilityPaginatedAppList: '/app-view/capability-redundancy/v1',
    capabilityCount: '/app-view/capability-count',
    specificAppPLMData: '/app-view/app-plm-details',
    lobList: '/lob-view/lob-list',
    lobListCommonFilters: '/lob-view/loblist',
    lobListWithModal: '/lob-view/loblist',
    lobListWithSegment: '/lob-view/lob-master-details',
    lobDetails: '/lob-view/lob-details',
    updateLobDetails: '/lob-view/update-lob-details',
    getArchitectDetails: '/app-view/architects',
    lobOverviewTabel: '/lob-view/padu-overview-table',
    lobTciOverviewTabel: '/lob-view/padu-overview-tco-table',
    commonPADUOverview: '/lob-view/v1/padu-overview',
    LOBKPIDetails: '/lob-view/v1/lob-details',
    paduOverview: '/lob-view/padu-overview',
    paduOverviewTableFilterBased: '/lob-view/v1/padu-overview-table',
    duOverview: '/lob-view/du-application',
    filterBasedDUOverview: '/lob-view/v1/du-application',
    plmExportFilterBased: '/lob-view/v1/plm-data',
    modelList: '/cap-view/model-list',
    modelListV1: '/cap-view/model-list/V1',
    capRelationship: '/cap-view/cap-lob-map-list',
    capL0L1List: '/cap-view/cap-list',
    capLobAppDetails: '/cap-view/cap-lob-app-details',
    getCapabilityDetails: '/cap-view/cap-details-with-app',
    getCapabilityHierarchy: '/cap-view/cap-list-data/v1',
    bookmark: '/shared/bookmark',
    getJobDetls:'/shared/get-job-details',
    getMonthlyJobDetls: '/shared/get-monthly-job-details',
    lobDecomeChart: '/lob-view/decom-overview',
    createUserStats: '/shared/create-stats',
    decomAppInsert: '/app-decom',
    allowEdit: '/shared/allow-edit/',
    primaryUpdate: '/padu-simplification/appCapprimaryChange',
    // decommissionedApp: '/app-decom',
    decommissionedApp: '/v3/decom/application-decommission-search',
    exportPlmData: '/lob-view/plm-data',
    decomOverviewFilterBased: '/lob-view/v1/decom-overview',
    decomExcelData: '/lob-view/v1/decom-overview/excel',
    commonPLMData: '/lob-view//plm-chart-data',
    exportAllDUData: '/lob-view/decom-rating-export-data',
    exportAllDUDataV1: '/lob-view/v1/decom-rating-export-data',
    dataRefreshDate: '/shared/data-refresh-date',
    globalAppLobFilter: '/app-modernization/app-lob-list',
    globalFilter: '/products/filter-data',
    roadmapProductTableDetail: '/products/product-data',
    appDetailRoadmap: '/products/app-lob',
    roadmapsave: '/products/modernization-data',
    roadmapupdate: '/products/modernization-data',
    roadmapget: '/products/modernization-data',
    productMappingFilter: '/products/product-filter-data',
    getProductMappingTableData: '/products/app-product-data',
    getProductMappingDetailforEdit: '/products/app-product',
    getMSIDName: '/getUserDetails/',
    mapproductdatasave: '/products/map-product-data',
    mapproductdataupdate: '/products/map-product-data',
    mapproductdataget: '/products/map-product-data',
    getSaveProductMapping: '/products/map-product-data',
    getCapExportData: '/cap-view/cap-app-export-data',
    getProductGridDetail: '/products/product-grid-details',
    editMapping: '/products/app-product/',
    deleteMapping: '/products/app-product/',
    decomFilter: '/app-decom/data/filter-data',
    prodFamWithDesList: '/products/product-family-list',
    basicAppInfo: '/app-view/app-basic-info',
    fetchAllProductMappingData: '/products/all-product-data',
    getProductOfProductFamily: '/products/product-family-info',
    updateProductAndProductFamily: '/products/product-info',
    fetchAllProductMappingExport: '/products/all-product-data-export',
    getProductSuitesList: '/products/product-suites-list',
    saveProductSuiteInfo: '/products/product-suite-info',
    deleteProductSuiteId: '/products/product-suite/',
    getLobInProductMapping: '/products/product-lob-list',
    getRiskRecord: '/app-view/risk-records/',
    getScanStatus: '/app-view/issue-summaries/',
    getAppPaduList: '/product-cycle/app-padu-list',
    saveProduct360: '/product-cycle',
    getProduct360BasedOnProdCap: '/product-cycle',
    deleteProductCapabilityId: '/products/product-cap/',
    getStakeholderDataBasedOnProductId: '/products/stake-product-data',
    updatePaduSimplification: '/padu-simplification',
    updatePaduRoadMap: '/padu-simplification/update-road-map',
    updateApplicationAsscCapability: '/padu-simplification/app-capability/v1',
    deleteApplicationOrganizationAssociation: '/padu-simplification/appCapabilityAssociationRemove/v1',

    GetProductModelList: '/pmm',
    GetCapabilityLevels: '/pmm/level',
    GetProductSuites: '/productsuite',
    getImpactedLobInfo: '/lob-view/lob-master-details',
    getImpactedAskIdInfo: '/lob-view/askid-master-details',
    getImpactedApplictnInfo: '/lob-view/applictn-master-details',
    getcasecadeinfo: '/lob-view/applictn-casecade-filter-details',
    getcasecadeAppinfo: '/lob-view/applictn-casecade-app-filter-details',
    getcasecadeAskidinfo: '/lob-view/applictn-casecade-askid-filter-details',
    getcasecadePrmryinfo: '/lob-view/applictn-casecade-primry-filter-details',
    getAccessData: '/shared/get-tech-access/',
    getReportAccessData: '/shared/get-report-access/',
    GetProductRegistryList: '/pr',
    GetProductRegistryListByOfferingIds: '/pr/searchByOfferingId',
    searchOfferings: '/pr/search',
    addPurOfferingData: '/pr/addOffering',
    GetProductFamilyList: '/pr/getProductFamily',
    GetProductLineList: '/pr/getProductLine',
    GetProductOrUtilList: '/pr/getProductOrUtil',
    GetOfferingTypeList: '/pr/getOfferingType',
    GetPurAdminAccess: '/pr/checkPurAdmin',
    UpdatePurOfferingData: '/pr/updateOfferingData',
    AddContact: '/pr/addContact',
    DeleteContacts: '/pr/deleteContacts',
    AddProductFamily: '/pr/addProductFamily',
    UpdateProductFamily: '/pr/updateProductFamily',
    DeleteProductFamily: '/pr/deleteProductFamily',
    AddProductLine: '/pr/addProductLine',
    UpdateProductLine: '/pr/updateProductLine',
    DeleteProductLine: '/pr/deleteProductLine',
    AddProductType: '/pr/addProductType',
    UpdateProductType: '/pr/updateProductType',
    DeleteProductType: '/pr/deleteProductType',

    SearchTechlandscapeList: '/techHub/technologies/search',

    //edit product family and produt capability apis
    getDetailsOfProductFamily: '/cap-support/',
    deleteProductandMoveAssociatedApp: '/cap-support/move-app/',
    editAddProductSuite: '/products/v1/product-suite-info',
    deleteProductSuite: '/products/v1/product-suite/',
    moveCapability: '/cap-support/move-cap',

    // Organization maintenance
    getOrgList: '/app-view/org',
    capitalDemandLeader:'/v4/maintenance/capital-demand-leader',
    addCapitalDemandLeader:'/v4/maintenance/capital-demand-leader/add',
    addUcmg:'/v4/maintenance/capital-demand/investment',
    deleteDemand:'/v4/maintenance/capital-demand/delete/ucmg-id',
    GetCapabilityDetails: '/pmm/desc',
    GetCapabilityDetailsApplications: '/cm/expand',
    GetCapabilityExpandedDetailsApplications: '/cm/extendedTable',
    GetCapabilityStakeHolderdata: '/sh',
    SaveCapability: '/stkh/save',
    DeleteCapability: '/stkh',
    UpdateCapability: '/stkh',
    getUsersFromLDAP: '/auth/ldapmeta',
    bulkImport: '/cmi',
    editModelBulkImport:'/v4/apphub/bulkupload/editModel',
    editModelBulkPreview:'/v4/apphub/bulkupload/editModel-preview',
    initiativeColumnDetails:'/v4/configurable/initiative-column-details',
    initiativePreview:'/v4/apphub/bulkupload/initiativePreview',
    initiativeSubmit:'/v4/apphub/bulkupload/submitInitiative',
    businessSupportBulkImport : '/v4/apphub/bulkupload/businesssupport',
    businessSupportBulkSubmit : '/v4/apphub/bulkupload/businesssupport',
    businessSupportBulkPreview : '/v4/apphub/bulkupload/businesssupportPreview',
    businessLobSublobBulkImport : '/v4/apphub/bulkupload/lobsublob',
    paduRoadmapBulkImport : '/v4/apphub/bulkupload/paduroadmap-validation',
    businessLobSublobCheckAccess: '/v4/apphub/bulkupload/lobsublobCheckInsertAcess',
    paduRoadmapBulkSave: '/v4/apphub/bulkupload/savepaduroadmap',
    primaryUploadBulkImport : '/v4/apphub/bulkupload/primaryupload',
    primaryUploadBulkImportUpdate : '/v4/apphub/bulkupload/Updateprimaryupload',
    businessLobSublobBulkImportPreview : '/v4/apphub/bulkupload/lobsublobPreview',
    GetLoBLookUp: '/lobmaintenance/filter',
    GetLoBArchitectMapping: '/v4/lobmaintenance/lob',
    // GetLoBArchitectMapping: '/lobmaintenance',
    SaveLobMapping: '/lobmaintenance/save',
    UpdateLobMapping: '/lobmaintenance/update',
    DeleteLobMapping: '/lobmaintenance/bulk',
    Create_LoB: '/lobmaintenance/create',
    Create_Sub_LoB: '/lobmaintenance/createsublob',
    getAllPaginatedAppList: '/app-view/app-search',
    getPADUWithoutToBeRetired: '/app-view/app-default-padu',
    capabilityDetails: '/app-view/capability',
    organisationDetails: '/app-view/organisations',
    organisationApplicationDetails: '/app-view/applicationswithCapabilityPADU',
    addcaps: '/padu-simplification/v1/add-capability',
    editCaps: '/padu-simplification/v1/edit-capability',
    exportExcelAppView: '/cm/exportExcel',
    addStakeholder: '/stkh/save',
    getApplicationName:'/v4/apphub/applicationName',
    adminAccess:'/shared/get-admindashboard-access',
    releaseNoteDetails: '/configV4/release-notes',
    dynamicContent: '/v4/sync/GetFields',
    workQueueData:'/v4/admin-view/workqueue',
    appAttr:'/v4/admin-view/app-attribute',
    updateWorkQueueUrl:"/v4/admin-view/workqueue",
    updateAppAttrUrl:"/v4/admin-view/app-attribute",
    getLookupNoHryDataUrl:"/v4/admin-view/lookup-no-hierarchy",
    getLookupWithHryDataUrl : "/v4/admin-view/lookup-1-hierarchy",
    updateLookupNoHryUrl:"/v4/admin-view/lookup-no-hierarchy",
    updateLookupHryUrl:"/v4/admin-view/lookup-1-hierarchy",
    getReportsUrl:'/v4/admin-view/reports',
    updateReportsUrl : '/v4/admin-view/reports',
    initiativeListUrl : '/v4/configurable/list',
    initiativeRoleListUrl : '/v4/maintenance/enterprise-initiatives/getEnabledIntiativesListing',
    eiRoleListUrl : "/v4/maintenance/enterprise-initiatives/getEnabledIntiativesRoles",
    getLobListUrl : '/lob-view/lob-master-details',
    addEIRoleUrl : "/v4/maintenance/enterprise-initiatives/users",
    allInitiativeRolesURl: "/v4/maintenance/enterprise-initiatives/getAllIntiativesRoles",
    fcServiceListUrl : "/v4/forecastmanagement/service-details",
    fcServiceBulkPreview : "/v4/forecastmanagement/bulk-preview",
    fcServiceBulkSubmit : "/v4/forecastmanagement/bulk-submit",
    fcCurrentForecastYear: "/v4/forecastmanagement/current-forecast",
    fcOwnerDetails: "/v4/forecastmanagement/owner-details",
  },
  CurrentMonth: 'June',
  PrevMonth: 'Nov 19',
  CurrentYear: new Date().getUTCFullYear(),
  PrevYear: new Date().getUTCFullYear()-1,
  CurrentMonthFull: 'December',
  CurrentMonthYear: 'February 2020',
  lobListOrder: {
    sortOrder: {
      uhcOption: ['UHC E&I', 'UHC Global', 'UHC C&S', 'UHC M&R', 'UHC CORE Services', 'UHC LCRA', 'UHC Provider'],
      midOption: ['EEPS', 'Clinical Services'],
      optumOption: [
        'OptumInsight PE',
        'Optum International',
        'Optum Growth Office',
        'Optum Ops',
        'OptumRx',
        'OptumCare Tech'
      ]
    },
    skip: ['No Mapping']
  },
  lobList: [
    'UHC E&I',
    'UHC Global',
    'UHC C&S',
    'UHC M&R',
    'UHC CORE Services',
    'UHC LCRA',
    'UHC Provider',
    'EEPS',
    'Clinical Services',
    'OptumInsight PE',
    'Optum International',
    'Optum Growth Office',
    'Optum Ops',
    'OptumRx',
    'OptumCare Tech',
    'Corporate Systems',
    'Data Solutions',
    'Emerging Business'
  ],
  timelineData: [
    { month: 'March 2020', date: 'April 17, 2020' },
    { month: 'April 2020', date: 'May 12, 2020' },
    { month: 'May 2020', date: 'June 10, 2020' },
    { month: 'June 2020', date: 'July 10, 2020' },
    { month: 'July 2020', date: 'August 12, 2020' },
    { month: 'August 2020', date: 'September 11, 2020' },
    { month: 'September 2020', date: 'October 12, 2020' },
    { month: 'October 2020', date: 'November 11, 2020' },
    { month: 'November 2020', date: 'December 11, 2020' },
    { month: 'December 2020', date: 'January 13, 2021' }
  ],
  capabilityLevel: [
    { id: 'L0', value: 'domain' },
    { id: 'L1', value: 'capabilityL1' },
    { id: 'L2', value: 'capability' }
  ],
  excelHeader: {
    cio_lob: 'LOB',
    lob: 'Sub LOB',
    decom_id: 'Decom ID',
    application: 'Application or Initiative',
    target_status: 'Decommission Category',
    app_id: 'ASK Global ID',
    decom_date: 'Decom Date',
    benefit: 'Benefit'
  },
  pathName: {
    lobView: '/cio-app-insights(sidebar:padu)'
  },
  removeEditAccessForModel: ['Optum Group Model'],
  excludeModel: ['Optum Group Model']
};
function setConfig(type) {
  let config = JSON.parse(localStorage.getItem('config'));
  if (!config) {
    return '';
  }
  switch (type) {
    case 'CurrentYear':
      return config.currentYear;
      break;
    case 'PrevYear':
      return config.previousYear;
      break;
    case 'CurrentMonthIndex':
      return config.currentMonthIndex;
      break;
    case 'CurrentMonthFull':
      return capitalize(config.currentMonthFull);
      break;
    case 'CurrentMonth':
      return mergeTwoString(capitalize(config.currentMonth), config.currentYear);
      break;
    case 'CurrentMonthPrevYear':
      return mergeTwoString(capitalize(config.currentMonth), config.previousYear);
      break;
    case 'PrevMonth':
      return mergeTwoString(capitalize(config.previousMonth), config.currentYear);
      break;
      case 'PreMonth':
        return config.previousMonth;
        break;
        case 'Month':
          return config.currentMonth;
          break;
    case 'ForcastCycle':
      return config.forcastCycle;
      break;
    default:
      return 'No Values found';
  }
}
function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}
function mergeTwoString(str1, str2) {
  let slicestr = str2 ? str2.toString().slice(2) : "";
  return `${str1} ${slicestr}`;
}