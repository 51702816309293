import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class ExportDataService {
  public masterCardExcelData: any;
  constructor() {}

  exportToExcel(fileName: string, dataToExport: Array<any>, sheetname: Array<string> = []) {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    if (sheetname.length) {
      dataToExport.forEach((data, index) => {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

        ws['!cols'] = [];
        data.forEach(() => {
          ws['!cols'].push({ width: 30 });
        });
        XLSX.utils.book_append_sheet(wb, ws, sheetname[index]);
      });
    } else {
      dataToExport.forEach((data, index) => {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        ws['!cols'] = [];
        data.forEach(() => {
          ws['!cols'].push({ width: 30 });
        });

        XLSX.utils.book_append_sheet(wb, ws, 'sheet' + (index + 1));
      });
    }
    XLSX.writeFile(wb, fileName + ' ' + new Date() + '.xlsx');
    // this.autofitColumns(wb);
  }

  // autofitColumns(worksheet: WorkSheet) {
  //   let objectMaxLength: ColInfo[] = [];
  //   // tslint:disable-next-line: no-non-null-assertion
  //   const [startLetter, endLetter] = worksheet['!ref']?.replace(/\d/, '').split(':')!;
  //   const ranges = range(startLetter.charCodeAt(0), endLetter.charCodeAt(0) + 1);
  //   ranges.forEach((c) => {
  //     const cell = String.fromCharCode(c);
  //     const cellLength = worksheet[`${cell}1`].v.length + 1;
  //     objectMaxLength.push({ width: cellLength });
  //   });
  //   worksheet['!cols'] = objectMaxLength;
  // }
}
