import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppHubUtilService {

  constructor() { }
  filteredArray: Array<string> = [];
  public vulMonth = {
    current_status: 'current',
    month_6: '6',
    month_12: '12',
    month_18: '18',
    month_24: '24'
  };
  public formatNumber(val) {
    
    const values = val.split(',');
    let formatted;
    switch (values.length) {
      case 4:
        formatted = `$${values[0]}B`;
        break;
      case 3:
        formatted = `$${values[0]}M`;
        break;
      case 2:
        formatted = values[0].length === 3 ? `$${(values[0] / 1000).toFixed(1)}M` : `$${values[0]}K`;
        break;
      default:
        
        formatted = `$${(+values[0]).toFixed(0)}`;
    }
    return formatted;
  }

  public decimalFormatter(num: number, digits: number) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1E3, symbol: 'K' },
      { value: 1E6, symbol: 'M' },
      { value: 1E9, symbol: 'B' }

    ];
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits) + si[i].symbol;
  }

  public getDecomString = decomData => {
    const totalInfra = decomData.reduce((prev, cur) => prev + cur.benefit, 0);
    const decomDate = new Date(decomData[0].decom_date);
    const day = decomDate.getDate();
    const month = decomDate.getMonth() + 1;
    const year = decomDate.getFullYear();

    return `Decom target date: ${month}/${day}/${year}. Total benefit: $ ${totalInfra.toLocaleString()}`;
  }

  public plmVulnerabilityString(data) {
    data.forEach((d) => {
      this.onlyVulArray(d, 'V');

    });
    const month = this.vulMonth[(this.sortLowestVulMonth(this.filteredArray)[0])];
    return `Application will become vulnerable in ${month} month`;
  }

  onlyVulArray(object, value) {
    for (const prop in object) {
      if (Object.prototype.hasOwnProperty.call(object,prop)) {
        if (object[prop] === value) {
          this.filteredArray.push(prop);
        }
      }
    }
  }

  sortLowestVulMonth(filteredArray) {
    const sortOrder = ['current_status', 'month_6', 'month_12', 'month_18', 'month_24'];
    const reqArray = [];
    for (const i of sortOrder) {
      filteredArray.forEach(p => {
        if (p === i) {
          reqArray.push(p);
        }
      });
    }
    return reqArray;
  }

  getFormatDate(date: string) {
    if (!date) {
      return '';
    }
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  }

  //get capability Hierarchy List

  getCapabilityHierarchy(capHier)
  {
    let hierachy = {};
    let result = capHier.reduce(function (r, a) {
      r[a.capabilityL0] = r[a.capabilityL0] || [];
      r[a.capabilityL0].push(a);
    
      return r;
    }, Object.create(null));
    const keys = Object.keys(result);
   
    for (let key of keys) {
      let obj = {};
      let result1 = result[key].reduce(function (r, a) {
       
     
        r[a.capabilityL1] = r[a.capabilityL1] || [];
        r[a.capabilityL1].push(a);
        return r;
      
        
      }, Object.create(null));
      
     
      const keysCap = Object.keys(result1);

      for (let capke of keysCap) {
        if(result1[capke]){
        let result2 = result1[capke].reduce(function (r, a) {
          
          if( r[a.capabilityL2])
          r[a.capabilityL2] = null;

          return r;
        }, Object.create(null));
      
        obj[capke] =Object.keys(result2).length ? result2: null;

      }
    }

      result1 = obj;
      hierachy[key] = result1;
    }

   return hierachy;
  }

  tree(rows, keys) {
    return rows.reduce( (acc, row) => {
        keys.reduce( (parent, key, i) =>
            parent[row[key]] = parent[row[key]] || (i === keys.length - 1 ?
               [row] : {})
        , acc);
        return acc;
    }, {});
}

sanitize = (obj) => {
  return JSON.parse(JSON.stringify(obj, (key, value) => {
   
    return (key === null || key === 'null' ? undefined : value);
  }));
};

replaceNull(someObj, replaceValue) {
  const replacer = (key, value) =>
     Object.keys(value).length === 0 || value.length > 0  ? replaceValue : value;
  //^ because you seem to want to replace (strings) {} or "undefined" too

  return JSON.parse(JSON.stringify(someObj, replacer));
}

// sort array of objects

sortArray(arr, sortByName) {

  arr.sort((a, b) => {

    const nameA = a[sortByName].toUpperCase(); // ignore upper and lowercase
    const nameB = b[sortByName].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

// export to pdf name only for product model


}
