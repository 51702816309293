<div class="search-main-div">
  <div class="searchbox-placement">

    <!-- New serach feature starts-->
    <div>
      <input (focus)="searchValue.length>0 ? showApplicationList = true :showApplicationList = false " type="text"
        class="search-option table-data-font search-box"
        (keyup)="searchValue.length>0 ? showApplicationList = true :showApplicationList = false ; searchApplication($event)"
        (blur)="searchValue.length>0 ? showApplicationList = true :showApplicationList = false ;"
        (keydown)="$event.stopPropagation()" [(ngModel)]="searchValue"
        placeholder="Search by Application ID or Application Name or Alias" />
      <img class="search_Icon" src="../../../../assets/images/searchicon.png"
        style="position: relative;" />
      <div [@openClose]="showApplicationList" id="menu" *ngIf="showApplicationList" class="list-container">
        <div style="margin: 1% 2%; display: flex;
              justify-content: space-between;">
          <span class="search-list-header">{{totalSearchedApp >= 20 ? 'Top' : ""}} {{totalSearchedApp}} Results Found</span>
          <mat-icon (click)="showApplicationList = false" class="close-icon">close</mat-icon>

        </div>
        <ul class="list-group">
          <ng-container *ngIf="showAppListLoader; else showlist">
            <button *ngFor="let i of [1,2,3,4,5]" class="list-group-item list-item">
              <mat-progress-bar mode="buffer" class="dark-blue"></mat-progress-bar>
            </button>
          </ng-container>
          <ng-template #showlist>
            <button class="list-group-item list-item" (click)="searchSpecificApplication(name)"
              *ngFor="let name of appNameList"><strong>{{ name.app_id}}</strong>&nbsp;&nbsp;
              {{ name.app_name }}</button>
          </ng-template>
        </ul>

      </div>

    </div>

  </div>
</div>
