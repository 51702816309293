import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./theme-2-style.css', './footer2.component.css']
})
export class Footer2Component implements OnInit {
  outlookHref: string;
  currentYear: any;
  constructor() {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    const msId = localStorage.getItem('MsId');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.outlookHref = `mailto:techopshub_support@uhg.com?subject=${currentUser.replace(
        /^"(.*)"$/,
        '$1'
      )} (${msId}) has contacted!`;
    }
  }
  ngAfterViewInit() {
    const returnUrl = localStorage.getItem('returnUrl')
      ? window.location.host + localStorage.getItem('returnUrl')
      : null;
    const currenturl = window.location.href;
    if (returnUrl == currenturl) {
      localStorage.removeItem('returnUrl');
    }
  }
}
