import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard, PostAuthGuard } from './index';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [AuthGuard, PostAuthGuard]
})

export class GuardsModule { }
