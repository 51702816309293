<div class="productMapAddEdit">
    <div class="popup-header addcircle" mat-dialog-title>
        <span style="color: #fff; padding: 12px;position: sticky;left: 0%;">Capability details</span>
    
        <div (click)="closePopup()" class="back">
            <mat-icon class="cancel-icon">cancel</mat-icon>
        </div>
    </div>
    <p style="padding: 4px;position: sticky;left: 0%;margin-bottom:4rem;" class="tabl-header-font">{{title}} </p>
    <div *ngIf="moveEnabled" class="alert alert-warning alert-dismissible fade show" role="alert">
        <p class="alert-heading tabl-header-font">Rules for move:</p>
        <ul class="table-data-font">
            <li>Move should be at the same level or above.</li>
            <li>Move can not be done to L0 level (An item can not become L0)</li>
            <li>L1 can not be L0 but L2 can be L1.</li>
        </ul>
       
    </div>
    <mat-dialog-content [ngClass]=" {'arrange-level0': moveEnabled, 'mat-typography' : true ,'remove-grid':moveNodeVertical}">
    
      
        <ng-container *ngIf="!showLoader">
            <ng-container *ngIf="hierarchyNode.length > 0; else elseblock">
                <ng-container *ngIf="!moveEnabled; else moveblock">
    
                    <app-single-node-view *ngIf="hierarchyNode.length" [data]="hierarchyNode" [mode]="mode" (updateCapability)="receivedFormValue($event)"  (deleteCapability)="deleteNode($event)" (moveCapEnabled)="moveNode($event)" [openWqReq]="openWqReq"></app-single-node-view>
                    <!-- <div [ngClass]="(!hierarchyNode[0].showChildren && mode !== 'new' && hierarchyNode[0].id !== '') ? 'align-center': 'align-left' "
                        id="wrapper"><span class="node-label">
                            <div (click)="selectedNode(hierarchyNode[0])"
                                [ngClass]="[mode === 'new' && hierarchyNode[0].id === '' ? 'increase-width node' : 'node cursor' , (hierarchyNode[0]?.selectedNode)?'add-dotted-border':'remove-border']">
                                <div class="node-title-section">
                                    <label style="font-size: 0.8rem;">{{hierarchyNode[0].name}}
                                        (L{{hierarchyNode[0].level}})</label><br />
                                    <span class="add-level cursor" *ngIf="!hierarchyNode[0].addNewNodes && !moveEnabled"
                                        (click)="showChildNodes(hierarchyNode[0])">+ Add
                                        L{{hierarchyNode[0].level + 1}}</span>
                                    <span class="delete-trash cursor" (click)="deleteNode(hierarchyNode[0])"
                                        *ngIf="hierarchyNode[0]?.selectedNode && !this.hierarchyNode[0].children.length"><img
                                            class="delete-trash-icon"
                                            src="../../../../assets/appHub/delete.png">Delete</span>
                                </div>
                               
                                <app-product-form *ngIf="hierarchyNode[0].showDescription"
                                    (formValue)="receivedFormValue($event)" (nameChange)="nameChange($event)"
                                    [formData]="hierarchyNode[0]"></app-product-form>
    
                                <span class="node-sub-section cursor" (click)="showDescription(hierarchyNode[0])">
                                    {{hierarchyNode[0].showDescription ? 'Less Detail' : 'More Detail'}}</span>
    
                            </div>
                            <div [ngClass]="{'highlight-node':hierarchyNode[0].highlightNode && hierarchyNode[0].showChildren}"
                                class="node-expand-button cursor" (click)="toggleNodes(hierarchyNode[0])">
                                <mat-icon class="expand-branch-icon">keyboard_arrow_down</mat-icon>
                                {{hierarchyNode[0].children.length}}
                            </div>
                        </span>
                        <div *ngIf="hierarchyNode[0].showChildren" class="branch lv1">
                            <div [ngClass]="node.addNewNodes || node.showDescription ? 'entry edit-node': 'entry'"
                                *ngFor="let node of hierarchyNode[0].children"><span class="node-label">
                                    <div (click)="selectedNode(node)"
                                        [ngClass]="(node?.selectedNode)?'add-dotted-border':'remove-border' " class="node">
    
                                        <div class="node-title-section">
                                            <label style="font-size: 0.8rem;">{{node.name}} (L{{node.level}})</label><br />
                                            <span class="add-level cursor" *ngIf="!node.addNewNodes && !moveEnabled"
                                                (click)="showChildNodes(node)">+ Add L{{node.level+1}}</span>
                                            <span class="delete-trash cursor" (click)="deleteNode(node)"
                                                *ngIf="node?.selectedNode && !node.children.length"><img
                                                    class="delete-trash-icon"
                                                    src="../../../../assets/appHub/delete.png">Delete</span>
                                            <span class="move cursor" (click)="moveNode(node,hierarchyNode[0])"
                                                *ngIf="node?.selectedNode"><img
                                                    class="delete-trash-icon"
                                                    src="../../../../assets/appHub/move.png">Move</span>
                                        </div>
    
    
                                        <app-product-form *ngIf="node.showDescription || node.addNewNodes"
                                            [parentNode]="hierarchyNode[0]" (formValue)="receivedFormValue($event)"
                                            (nameChange)="nameChange($event)" [formData]="node"></app-product-form>
    
                                        <span class="node-sub-section cursor" (click)="showDescription(node)">
                                            {{node.showDescription || node.addNewNodes ? 'Less Detail' : 'More Detail'}}</span>
    
    
                                    </div>
    
                                    <div [ngClass]="{'highlight-node':node.highlightNode && node.showChildren}"
                                        class="node-expand-button cursor" (click)="toggleNodes(node)">
                                        <mat-icon class="expand-branch-icon">keyboard_arrow_down</mat-icon>
                                        {{node.children.length}}
                                    </div>
                                </span>
                                <div *ngIf="node.showChildren &&node.children.length" class="branch lv2">
                                    <div [ngClass]="child.addNewNodes || child.showDescription ? 'entry edit-node': 'entry'"
                                        *ngFor="let child of node.children"><span class="node-label">
                                            <div (click)="selectedNode(child)"
                                                [ngClass]="(child?.selectedNode)?'add-dotted-border':'remove-border'"
                                                class="node">
    
                                                <div class="node-title-section">
                                                    <label style="font-size: 0.8rem;">{{child.name}}
                                                        (L{{child.level}})</label><br />
                                                    <span class="add-level cursor"
                                                        *ngIf="!child.addNewNodes && !moveEnabled"
                                                        (click)="showChildNodes(child)">+ Add L{{child.level+1}}</span>
                                                    <span class="delete-trash cursor" (click)="deleteNode(child, node)"
                                                        *ngIf="child?.selectedNode && !child.children.length"><img
                                                            class="delete-trash-icon"
                                                            src="../../../../assets/appHub/delete.png">Delete</span>
                                                </div>
    
    
                                                <app-product-form *ngIf="child.showDescription || child.addNewNodes"
                                                    [parentNode]="node" (formValue)="receivedFormValue($event)"
                                                    (nameChange)="nameChange($event)" [formData]="child"></app-product-form>
                                                <p class="node-sub-section cursor" (click)="showDescription(child)">
                                                    {{child.showDescription || child.addNewNodes ? 'Less Detail' : 'More Detail'}}
                                                </p>
    
    
                                            </div>
    
                                            <div [ngClass]="{'highlight-node':child.highlightNode && child.showChildren}"
                                                class="node-expand-button cursor" (click)="toggleNodes(child)">
                                                <mat-icon class="expand-branch-icon">keyboard_arrow_down</mat-icon>
                                                {{child.children.length}}
                                            </div>
    
                                        </span>
                                        <div *ngIf="child.showChildren &&child.children.length" class="branch lv3">
                                            <div [ngClass]="children.addNewNodes || children.showDescription ? 'entry sole edit-node': 'entry sole'"
                                                *ngFor="let children of child.children"><span class="node-label">
                                                    <div (click)="selectedNode(children)"
                                                        [ngClass]="(children?.selectedNode)?'add-dotted-border':'remove-border'"
                                                        class="node">
    
                                                        <div class="node-title-section">
                                                            <label style="font-size: 0.8rem;">{{children.name}}
                                                                (L{{children.level}})</label><br />
                                                            <span class="delete-trash cursor"
                                                                *ngIf="children?.selectedNode  && !children.children.length"
                                                                (click)="deleteNode(children, child)"><img
                                                                    class="delete-trash-icon"
                                                                    src="../../../../assets/appHub/delete.png">Delete</span>
    
                                                        </div>
                                                        <app-product-form
                                                            *ngIf="children.showDescription|| children.addNewNodes"
                                                            [parentNode]="child" (formValue)="receivedFormValue($event)"
                                                            (nameChange)="nameChange($event)" [formData]="children">
                                                        </app-product-form>
    
    
    
                                                        <span class="node-sub-section cursor"
                                                            (click)="showDescription(children)">{{children.showDescription || children.addNewNodes ? 'Less Detail' : 'More Detail'}}</span>
    
    
                                                    </div>
    
    
                                                </span></div>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
    
    
    
                        </div>
                    </div> -->
                </ng-container>
                <ng-template #moveblock>
                    <ng-container *ngFor="let data of allProductModelData ">
                        <div [ngClass]="moveNodeVertical ? 'reduce-margin': 'add-margin'" >
                            
                                <app-single-node-view [data]="createData(data)" [isMoveSuccess]="disableAllIcon" (moveCapability)="moveCapability($event)" (cancelCapability)="cancelCapability()" (moveNodeVertically)="moveNodeVertically($event)" [moveEnabled]="true" [openWqReq]="openWqReq"></app-single-node-view>
                               
                        </div>
                    </ng-container>
    
                </ng-template>
            </ng-container>
    
            <ng-template #elseblock>
                <app-data-unavailable></app-data-unavailable>
            </ng-template>
        </ng-container>
    
        <div *ngIf="showLoader" class="row loader-container">
            <app-loader style="stroke:  #002677 !important;"></app-loader>
        </div>
    
    
    </mat-dialog-content>
</div>