import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editable-table-overlay',
  templateUrl: './editable-table-overlay.component.html',
  styleUrls: ['./editable-table-overlay.component.css']
})
export class EditableTableOverlayComponent implements OnInit {

  @Input() iseditExpand ;
  @Input() editExpandDataSource;
  @Input() editExpandConfig;
  @Input() isEdit;
  object = {};
  ar = [];
  FTransition : Array<string> =[];
  avalue = '';
  bvalue = '';
  thirdTransition = '';
  fourthTransition = '';
  fifthTransition = '';
  sixthTransition = '';

  public paduOptions: any = [{ label: 'Preferred', value: 'Preferred' },
  { label: 'Acceptable', value: 'Acceptable' },
  { label: 'Discouraged', value: 'Discouraged' },
  { label: 'Unacceptable', value: 'Unacceptable' },
  { label: 'Retired', value: 'Retired' }
  ];


  ngOnInit() {
   this.initialize();
  }

  initialize(){

    const {transition_1,transition_2, transition_3, transition_4, transition_5, transition_6} = this.editExpandDataSource[0];
    this.avalue = transition_1;
    this.bvalue = transition_2;
    this.thirdTransition = transition_3;
    this.fourthTransition = transition_4;
    this.fifthTransition = transition_5;
    this.sixthTransition = transition_6;   
  }

  ngOnChanges(){
    this.initialize();
  }


  updatedData(){


    const obj = {};

    obj['transition_1'] = this.avalue;
    obj['transition_2'] = this.bvalue;
    obj['transition_3'] = this.thirdTransition;
    obj['transition_4'] = this.fourthTransition;
    obj['transition_5'] = this.fifthTransition;
    obj['transition_6'] = this.sixthTransition;
   
    return obj;
  }
}