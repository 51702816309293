<div class="landing-page-body">
    <div class="page-content row m-0">
        <div class="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5 d-flex align-items-center landingpageservices-bodyfirstDiv-margin">
            <div class="app-info-div  landingpage-sideText-padding">
                <!-- <div class="landingpage-sideText-header pb-3">TechOps Hub</div> -->
                <div class="portfolio-sub-name app-warning-text" *ngIf="notification.visibility === 'True'">
                    <span class="warning-symbol">{{notification.Content}}</span>
                    {{notification.Description}}
                     <!-- Optum Tech Finance recently made changes in some segments around how technology costs are recovered.  The impact of this decision means that, starting with July, the source of truth financial system no longer has chargeback details for three billed segments.  Applications impacted are those that are billed to OT ASG, OT ChangeHealth, OT ETS.  We are actively working to find another source so that teams can continue to see their consumption information for those applications.  -->
                </div>
                <div class="landingpageservices-ServicesDesc landingpageservices-ServicesDesc-padding" *ngIf="dynamicDataLoaded">
                   <b class="landingpageservices-ServicesDesc-title" *ngIf="bannerTitle.visibility === 'True'">
                        {{bannerTitle.Content}}
                    </b>  
                    <span *ngIf="bannerDesc.visibility === 'True'">
                        {{bannerDesc.Content}}
                    </span>
                   <!-- empowers leaders by delivering data-driven insights, transforming both financial and architectural data into actionable recommendations that guide strategic decision-making. -->
                </div>
                <div class="micro-site-div">
                    <div class="explore-more-btn landingPage-body-exploremoreButton" (click)=redirectToUserManual()>
                        <span>Explore More</span> 
                    </div>
                </div>
            </div>
        </div> 
        <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 d-flex align-items-center chart-blur-bckg pt-3" >
            <img src="../../../assets/imagesV4/chart.png" class="landingpage-body-chart-image">
        </div>
        <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-4 d-flex landingpageservices-bodylastDiv-margin pt-3">
            <div class="page-menus">
                <div class="menu-end landingpage-body-menubar-margin">
                    <div class="landingPage-body-menuBox menu-box" (click)="redirectView('application-hub')">
                        <div class="arrow-div">
                            <div class="uitk-icon uitk-icon-arrow_wtail_bold_right menu-arrow"  matTooltip="View more details"></div>
                        </div>
                        <div class="text-div">
                            <div class="menu-text landingpage-body-menu-text-font">Explore Your Application</div>
                        </div>
                    </div>
                    <div class="landingPage-body-menuBox menu-box dropdown">
                        <ng-container id="financeDropdown" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <!-- <div class="arrow-div">
                                <div class="uitk-icon uitk-icon-arrow_wtail_bold_right menu-arrow"  matTooltip="View more details"></div>
                            </div> -->
                            <div class="text-div landingpagebody-dropdownbox-margin">
                                <div class="menu-text landingpage-body-menu-text-font">Know Your Finances</div>
                            </div>
                        </ng-container>
                        <div class="dropdown-menu sm-dropdown pt-1 dropdown-menu-right finance-dropdown" aria-labelledby="financeDropdown">
                            <span class="dropdown-item-Select dropdown-item-Select-font">Select By</span>
                            <span class="dropdown-item dropdown-item-font" (click)="redirectView('application-hub-finance')">Application</span>
                            <span class="dropdown-item dropdown-item-font" (click)="redirectView('portfolio-hub-finance')">Portfolio</span>
                            <span class="dropdown-item dropdown-item-font" (click)="redirectView('workforce-budget')">Workforce/Budget</span>
                        </div>
                    </div>
                </div>
                <div class="landingpage-body-menubar-margin landingpage-body-menubarmiddle-margin">
                    <div class="landingPage-body-menuBox menu-box dropdown"> 
                        <ng-container id="grpDropdown" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <!-- <div class="arrow-div">
                                <div class="uitk-icon uitk-icon-arrow_wtail_bold_right menu-arrow"  matTooltip="View more details"></div>
                            </div> -->
                            <div class="text-div landingpagebody-dropdownbox-margin">
                                <div class="menu-text landingpage-body-menu-text-font">Know Your App Groups/Platforms</div>
                            </div>
                        </ng-container>
                        <div class="dropdown-menu sm-dropdown pt-1 dropdown-menu-left grp-dropdown" aria-labelledby="grpDropdown">
                            <span class="dropdown-item-Select dropdown-item-Select-font">Select By</span>
                            <span class="dropdown-item dropdown-item-font" (click)="redirectView('all-groups')">All Groups</span>
                            <span class="dropdown-item dropdown-item-font" (click)="redirectView('portfolio-hub-groups')">Group Insights</span>
                        </div>
                    </div>
                    <div class="landingPage-body-menuBox menu-box" (click)="redirectView('portfolio-hub')">
                        <div class="arrow-div">
                            <div class="uitk-icon uitk-icon-arrow_wtail_bold_right menu-arrow"  matTooltip="View more details"></div>
                        </div>
                        <div class="text-div">
                            <div class="menu-text landingpage-body-menu-text-font">Analyze Your Portfolio</div>
                        </div>
                    </div>
                </div>
                <div class="menu-end"> 
                    <div class="landingPage-body-menuBox menu-box" (click)="redirectView('reports')">
                        <div class="arrow-div">
                            <div class="uitk-icon uitk-icon-arrow_wtail_bold_right menu-arrow"  matTooltip="View more details"></div>
                        </div>
                        <div class="text-div">
                            <div class="menu-text landingpage-body-menu-text-font">Extract Your Reports</div>
                        </div>
                    </div>
                    <div class="landingPage-body-menuBox menu-box" (click)="redirectView('capModel')">
                        <div class="arrow-div">
                            <div class="uitk-icon uitk-icon-arrow_wtail_bold_right menu-arrow"  matTooltip="View more details"></div>
                        </div>
                        <div class="text-div">
                            <div class="menu-text landingpage-body-menu-text-font">Understand Your Capabilities</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
