<div class="lookupSharedTable">
    <div class="table-margin">
        <table matSort mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)"
            class="mat-elevation-z8 table-width">
            <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns; let i = index">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="header-color tabl-header"
                    [style.textAlign]="column.textAlign" [style.width]="column.width" [style.padding]="column.padding"
                    [style.marginLeft]="column.marginLeft" [disabled]="!column.isSort"
                    [ngClass]="{'align-cancel-header': column.displayText === 'Cancel'}">
                    <div>
                    {{ column.header }}
                    <span *ngIf="column.filter">
                        <span *ngIf="!getfilterAppliedDtls(column.columnDef)" (click)="filterDropdown(column);$event.stopPropagation()"
                            [matMenuTriggerFor]="contextMenu" #trigger="matMenuTrigger">
                            <img class="stkh-table-filter-img" src="../../../../assets/images/table_filter.png">
                        </span>
                        <span *ngIf="getfilterAppliedDtls(column.columnDef)" (click)="filterDropdown(column);$event.stopPropagation()"
                            [matMenuTriggerFor]="contextMenu" #trigger="matMenuTrigger">
                            <img class="stkh-table-filter-img" src="../../../../assets/images/table_filter_applied.png">
                        </span>
                    </span>
                    <mat-menu #contextMenu="matMenu">
                        <div class="menu-filter-popup" (click)="$event.stopPropagation()"
                            (keydown)="$event.stopPropagation()">
                            <input matInput type="text" (keydown)="$event.stopPropagation()"
                                class="form-control searchoption filter-search-bar" [(ngModel)]="searchTxt"
                                placeholder="Search" />
                            <mat-selection-list class="filter-list-container"
                                (selectionChange)="selectionChange($event)" [disableRipple]="true"
                                [(ngModel)]="filValue">
                                <mat-list-option checkboxPosition="before"
                                    *ngFor="let row of filterLists | search : searchTxt" [value]="row">
                                    {{ row }}</mat-list-option>
                            </mat-selection-list>
                            <span style="cursor:pointer; float: right;padding-right: 70px;"
                                class="small localApplyButton" (click)="applyLocalFilter()">Apply</span>
                            <span style="cursor:pointer; float: right;padding-right: 22px;"
                                class="small localApplyButton" (click)="clearLocalFilter()">Clear</span>
                        </div>
                    </mat-menu>
                    <span *ngIf="showResetFilters && column.columnDef == 'Actions'" (click)="clearallFilter()">
                        &nbsp;
                        <i title="Reset Filters"
                            class="font-weight-bold ng-tns-c441-21 sm-icon stkh-table-filter-img uitk-icon uitk-icon-refresh"></i>
                    </span>
                </div>
                </th>
                <td mat-cell *matCellDef="let element;  let j = index" [style.textAlign]="column.textAlign"
                    [style.padding]="column.padding">

                    <ng-container [ngSwitch]="column.displayText">

                        <ng-container *ngSwitchCase="'Edit'">
                            <div class="action-buttons">
                                <button mat-icon-button (click)="addRole(element)" title="Click to Edit">
                                    <mat-icon class="actionItems excelandinfo-Icon-font"
                                        style="font-size: 1.1rem !important; cursor: pointer; color: #013B71;">
                                        create
                                    </mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'EditDelete'">
                            <div class="action-buttons">
                                <button [disabled] = "!(column.hasAdminAccess || (column.hasReportWfAccess && element.Managedby == 'Workforce'))" mat-icon-button (click)="addRole(element)" title="Click to edit the row">
                                    <mat-icon class="actionItems excelandinfo-Icon-font"
                                        style="font-size: 1.1rem !important; cursor: pointer; color: #013B71;">
                                        create
                                    </mat-icon>
                                </button>
                                <button [disabled] = "!(column.hasAdminAccess || (column.hasReportWfAccess && element.Managedby == 'Workforce'))"  mat-icon-button (click)="deleteRole(element)" title="Click to delete the row">
                                    <mat-icon class="actionItems excelandinfo-Icon-font"
                                        style="font-size: 1.1rem !important; cursor: pointer; color: #013B71;" >
                                        delete
                                    </mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Updated At'">
                            {{ element[column.columnDef] | date: 'MM/dd/yyyy hh:mm a' }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div> {{ element[column.columnDef] }} </div>
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"></tr>

            <ng-container matColumnDef="nodata">
                <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length" style="text-align: center;">
                    <div class="no-records-found">
                        <div class="img-align">
                            <img class="image-align" src="/assets/images/warning-icon.svg" />
                        </div>
                        <div class="data-title">
                            <div class="errorTitle">No Data Available</div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-footer-row [hidden]="dataSource && dataSource.data.length >0" *matFooterRowDef="['nodata']"> </tr>

        </table>
        <ng-container *ngIf="dataSource">
            <div class="mt-2 admin-table-paginator">
            <mat-paginator #pagination [length]="dataSource?.data?.length" [pageSize]="5" [pageSizeOptions]="[5,10,15]"
                showFirstLastButtons>
            </mat-paginator>
        </div>
        </ng-container>
    </div>

   
</div>