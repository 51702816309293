import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output,OnInit, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tcmHubConfigDetails } from '../../../config';
import { ApplistService } from '../../services/applist.service';
import { SharedService } from '../../services/shared.service';
import { TcmUtilsService } from '../../services/tcmUtils.service';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  loading = false;
  x: any;
  y: any;
  multiSelect: boolean = false;
  appliationSelectedLob: string;
  multiSelectAttr: string = '""';
  selectedTmdbCode: string;
  details: any = [];
  tecOwner: any = [];
  sloOwner: any = [];
  appList: any = [];
  sortedapplist: any = [];
  lobList: any = [];
  lobLists: any = [];
  cioList: any = [];
  askList: any = [];
  selectedApplicationName: any = [];
  selectedGloablAppCode = [];
  selctedTecOwner: string;
  selectedsloOwner: string;
  multiAppSelectAll: boolean = false;
  lobmulitiSelectAll: boolean = false;
  selectAll: boolean = false;
  lobselectAll: boolean = false;
  selectallaskid: boolean = false;
  // FAVC: any;
  // FSLO: any;
  // Fowner: any;
  // FAGI: any;
  FAVC: string = '';
  FSLO: string = '';
  Fowner: string = '';
  FAGI: string = '';
  LOB: string = '';
  CIO: string = '';
  // LOB: any;
  SelectedLob: any = [];
  SelectedCIO: any = [];
  initialRange: ListRange = { start: 0, end: 8 };

  @Output() valueChange = new EventEmitter();
  @Output() slovalueChange = new EventEmitter();
  @Output() appnameChange = new EventEmitter();
  @Output() appIdChange = new EventEmitter();
  @Output() appfilter = new EventEmitter();
  @Output() lobfilter = new EventEmitter();
  @Input() selectedValue: { selectedLob: string };
  lobListOrderTCMHub = tcmHubConfigDetails.lobListOrderTCMHub;

  constructor(
    private tcmUtilService: TcmUtilsService,
    private router: ActivatedRoute,
    private route: Router,
    private applistService: ApplistService,
    private http: HttpClient,
    private sharedService: SharedService,
    private container: ElementRef
  ) {
  }

  ngOnInit() {
  }

  onSearchClearClick() {
    this.FAVC = null;
    this.FSLO = null;
    this.Fowner = null;
    this.FAGI = null;
    this.LOB = null;
  }

  resetAppFilters() {
    // this.tecOwner = [];
    // this.sloOwner = [];
    // this.appList = [];
    this.multiAppSelectAll = false;
    this.lobmulitiSelectAll = false;
    this.selctedTecOwner = undefined;
    this.selectedsloOwner = undefined;
    this.selectedApplicationName = [];
    this.selectedGloablAppCode = undefined;
    this.SelectedLob = '';
    this.SelectedCIO = '';
    this.multiSelect = false;
    this.multiSelectAttr = '""';
    this.lobselectAll = false;
    this.getAppList2();
  }

  onTecOwnerChanged(technicalOwner) {
    this.tecOwner = [];
    this.tecOwner.push({ name: technicalOwner.value });
    this.selctedTecOwner = technicalOwner.value;
    this.loading = true;
    let appListUrl: string;
    this.multiAppSelectAll = true;
    if (this.selectedsloOwner == '' || this.selectedsloOwner === undefined) {
      this.multiSelect = true;
      this.multiSelectAttr = '""';
      appListUrl =
        tcmHubConfigDetails.envApiUrl +
        '/' +
        tcmHubConfigDetails.routerpath.getSloListForTechOwner +
        '/' +
        this.selctedTecOwner;
    } else {
      appListUrl =
        tcmHubConfigDetails.envApiUrl +
        '/' +
        tcmHubConfigDetails.routerpath.getAppsForSelectedSloAndTecOwner +
        '/' +
        this.selctedTecOwner +
        '/' +
        this.selectedsloOwner;
    }

    this.http
      .get<any>(appListUrl)
      .toPromise()
      .then(data => {
        // console.log(data);
        this.appList = data.appList;
        if (this.selectedsloOwner == '' || this.selectedsloOwner == undefined) {
          this.sloOwner = data.sloList;
        }
        this.loading = false;
        this.selecttechAppName();
      })
      .catch(err => {
        this.loading = false;
        console.log('err occured while getting data' + err);
      });
  }
  onSloOwnerChanged(slowner) {
    this.sloOwner = [];

    this.sloOwner.push({ name: slowner.value });
    this.multiAppSelectAll = true;
    this.loading = true;
    let appListUrl: string;
    this.selectedsloOwner = slowner.value;
    if (this.selctedTecOwner === '' || this.selctedTecOwner === undefined) {
      this.multiSelect = true;
      this.multiSelectAttr = '""';
      appListUrl =
        tcmHubConfigDetails.envApiUrl +
        '/' +
        tcmHubConfigDetails.routerpath.getTechOwnerListForSloOwner +
        '/' +
        this.selectedsloOwner;
    } else {
      appListUrl =
        tcmHubConfigDetails.envApiUrl +
        '/' +
        tcmHubConfigDetails.routerpath.getAppsForSelectedSloAndTecOwner +
        '/' +
        this.selctedTecOwner +
        '/' +
        slowner.value;
    }
    this.http
      .get<any>(appListUrl)
      .toPromise()
      .then(data => {
        this.appList = data.appList;
        if (this.selctedTecOwner == '' || this.selctedTecOwner == undefined) {
          this.tecOwner = data.techOwnerList;
        }
        this.loading = false;
        this.selectsloAppName();
      })
      .catch(err => {
        this.loading = false;
        console.log('err occured while getting data' + err);
      });
  }
  onAplicationValueChanged(applicationNames) {
    this.selectedGloablAppCode = [];
    let matchedArrays = [];
    // if (applicationNames.length > 20) {
    //   alert("Please select application less than 20");
    //   return false;
    // }
    for (let appCount = 0; appCount < this.appList.length; appCount++) {
      for (let count = 0; count < applicationNames.value.length; count++) {
        if (this.appList[appCount].LONG_APP_NAME == applicationNames.value[count]) {
          matchedArrays.push(this.appList[appCount]);
          this.selectedGloablAppCode.push(this.appList[appCount].GLOBAL_APP_ID);
        }
      }
    }
    if (matchedArrays.length > 0) {
      this.tecOwner = [];
      this.sloOwner = [];
      this.selectedTmdbCode = matchedArrays[0].TMDB_APP_CODE;
      this.appliationSelectedLob = matchedArrays[0].LINE_OF_BUSINESS;
      this.tecOwner.push({ name: matchedArrays[0].IT_DEV_OWNER });
      this.sloOwner.push({ name: matchedArrays[0].SERVICE_LEVEL_OWNER });
      this.selctedTecOwner = matchedArrays[0].IT_DEV_OWNER;
      this.selectedsloOwner = matchedArrays[0].SERVICE_LEVEL_OWNER;
    }
    // this.appList = this.details.applicationList;
  }
  onGlobalIDOwnerChanged(globalIDName) {
    const matchedObject = this.appList.filter(entry => {
      return entry.GLOBAL_APP_ID == globalIDName.value;
    });
    if (this.selectedApplicationName.length == 0) {
      this.appList = [];
    }
    if (matchedObject.length > 0) {
      this.selectedApplicationName.push(matchedObject[0].LONG_APP_NAME);
      this.tecOwner = [];
      this.sloOwner = [];
      this.appList = [];
      this.appliationSelectedLob = matchedObject[0].LINE_OF_BUSINESS;
      this.tecOwner.push({ name: matchedObject[0].IT_DEV_OWNER });
      this.sloOwner.push({ name: matchedObject[0].SERVICE_LEVEL_OWNER });
      this.appList.push(matchedObject[0]);
      this.selctedTecOwner = matchedObject[0].IT_DEV_OWNER;
      this.selectedsloOwner = matchedObject[0].SERVICE_LEVEL_OWNER;
    }
  }

  openAppView() {
    this.route.navigate(['tcmhub/appview']);
  }

  showhideapp() {
    this.x = document.getElementById('tp-body-section');
    this.y = document.getElementById('tp-mat-body-section');
    if (this.x.style.display === 'none') {
      this.x.style.display = 'block';
      this.y.style.display = 'none';
    } else {
      this.x.style.display = 'none';
    }
    this.applistService.navFromLob = false;
  }

  selectAllAppName(checkAll, select: NgModel, values) {
    let selectObject = {};
    selectObject['value'] = [];
    if (checkAll) {
      select.update.emit(
        values.map(value => {
          selectObject['value'].push(value['longAppName']);
          return value['longAppName'];
        })
      );
      this.onAplicationValueChanged(selectObject);
    } else {
      select.update.emit([]);
    }
  }
  selectsloAppName() {
    const preselectapp = [];
    this.appList.forEach(item => {
      preselectapp.push(item.LONG_APP_NAME);
    });
    this.selectedApplicationName = preselectapp;
    this.selectAll = true;
    const preselectaskid = [];
    this.appList.forEach(item => {
      preselectaskid.push(item.GLOBAL_APP_ID);
    });
    this.selectedGloablAppCode = preselectaskid;
    this.selectallaskid = true;
  }
  selecttechAppName() {
    const preselect = [];
    this.appList.forEach(item => {
      preselect.push(item.LONG_APP_NAME);
    });
    this.selectedApplicationName = preselect;
    this.selectAll = true;
    const preselectaskid = [];
    this.appList.forEach(item => {
      preselectaskid.push(item.GLOBAL_APP_ID);
    });
    this.selectedGloablAppCode = preselectaskid;
    this.selectallaskid = true;
  }

  selectAllAskGlobalID(checkAllId, select: NgModel, values) {
    if (checkAllId) {
      select.update.emit(
        values.map(value => {
          return value;
        })
      );
      console.log(
        values.map(value => {
          return value;
        })
      );
    } else {
      select.update.emit([]);
    }
  }

  getApplicationOfSelected() {
    if (this.selectedApplicationName.length > 0) {
      this.route.navigate(['application-cost'], {
        queryParams: {
          selectedApps: this.selectedApplicationName,
          selectedLob: this.appliationSelectedLob,
          selectedTechOwner: this.selctedTecOwner,
          selectedsloOwner: this.selectedsloOwner,
          selectedGlobalAppid: this.selectedGloablAppCode,
          selectedTmdbCode: this.selectedTmdbCode
        }
      });
      // this.appfilter.emit(matchedObject);
    } else {
      alert('Please enter application');
    }
  }
  showhidelob() {
    this.y = document.getElementById('tp-mat-body-section');
    this.x = document.getElementById('tp-body-section');
    if (this.y.style.display === 'none') {
      this.y.style.display = 'block';
      this.x.style.display = 'none';
    } else {
      this.y.style.display = 'none';
    }
    this.applistService.navFromLob = false;
  }

  appNameslist: any = [];
  askGlobalIdList: any = [];

  getAppList() {
    this.applistService.getAppList().subscribe(data => {
      this.details = data;
      this.sloOwner = this.details.sloList.sort(this.tcmUtilService.sortOnProperty('name'));
      this.lobList = this.details.lobList.sort(this.tcmUtilService.sortOnProperty('name'));
      this.lobLists = this.lobList;
      this.cioList = this.details.cioList.sort(this.tcmUtilService.sortOnProperty('name'));
      this.lobListOrderTCMHub = tcmHubConfigDetails.lobListOrderTCMHub;
      this.appList = this.details.applicationList.sort(this.tcmUtilService.sortOnProperty('LONG_APP_NAME'));
      this.askList = this.appList;
      this.cioList = this.details.cioList.sort(this.tcmUtilService.sortOnProperty('name'));
      this.sortedapplist = this.details.applicationList.sort(this.tcmUtilService.sortOnProperty('LONG_APP_NAME'));
      this.tecOwner = this.details.itownerList.sort(this.tcmUtilService.sortOnProperty('name'));
      for (let count = 0; count < this.appList.length; count++) {
        this.appNameslist.push(this.appList[count].LONG_APP_NAME);
        this.askGlobalIdList.push(this.appList[count].GLOBAL_APP_ID);
      }
      //this.route.navigate(['tcmhub']);
    });
  }
  getAppList2() {
    this.lobLists = [];
    this.sloOwner = this.details.sloList;
    this.lobLists = this.details.lobList.filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);

    this.appList = this.sortedapplist;
    this.tecOwner = this.details.itownerList;
    this.askList = this.appList;
    //  this.route.navigate([{ outlets: { primary: 'landing-page', sidebar: null } }]);
    //   this.route.navigate(['tcmhub']);
  }

  getLobView() {
    if (this.SelectedLob.length > 0 && this.SelectedLob[0] !== undefined) {
      this.route.navigate(['cio-cost'], { queryParams: { lob: this.SelectedLob, cio: this.SelectedCIO } });
      // this.lobfilter.emit(this.SelectedLob);
    } else {
      alert('Please enter lob');
    }
  }
  redirectToHome() {
    this.resetAppFilters();
    this.route.navigate([{ outlets: { primary: 'landing-page', sidebar: null } }]);
    // this.route.navigate(['landingpage']);
  }

  /**
   * method to reset to top when options is closed
   * @param opened param to tell if select options are opned
   * @param viewPort  reference of virtual scroll viewport which is to be reset
   */
  openedChange(opened, viewPort: CdkVirtualScrollViewport) {
    if (!opened) {
      viewPort.setRenderedContentOffset(0);
      viewPort.setRenderedRange(this.initialRange);
    }
  }

  lobtoappValueChanged(applicationNames) {
    const preselectapp = [];
    this.tecOwner = [];
    this.sloOwner = [];

    applicationNames.forEach(item => {
      const matchedObject = this.appList.filter(entry => {
        return entry.LONG_APP_NAME === item.LONG_APP_NAME;
      });
      preselectapp.push(item.LONG_APP_NAME);
      if (matchedObject) {
        this.selectedTmdbCode = matchedObject[0].TMDB_APP_CODE;
        this.appliationSelectedLob = matchedObject[0].LINE_OF_BUSINESS;
        this.tecOwner.push({ name: matchedObject[0].IT_DEV_OWNER });
        this.sloOwner.push({ name: matchedObject[0].SERVICE_LEVEL_OWNER });
        this.selctedTecOwner = matchedObject[0].IT_DEV_OWNER;
        this.selectedsloOwner = matchedObject[0].SERVICE_LEVEL_OWNER;
        this.selectedGloablAppCode = [matchedObject[0].GLOBAL_APP_ID];
      }
    });
    this.selectedApplicationName = preselectapp;
  }
  onCIOChanged(event) {
    this.lobmulitiSelectAll = true;
    const filterlobs = this.lobList.filter(item => {
      return item.cio_name == event.value;
    });

    let selectedloblist = [];
    this.lobLists = [];
    filterlobs.forEach(element => {
      selectedloblist.push(element.name);
      this.lobLists.push(element);
    });
    this.SelectedLob = selectedloblist;
    this.lobselectAll = true;

    // this.SelectedCIO = [];
    // this.SelectedCIO.push(event.value);
  }
  onLOBChanged(event) {
    let SelectedCIOs = [];
    this.lobList.forEach((value) => {
      if (event.value.includes(value.name)) {
        SelectedCIOs.push(value.cio_name);
      }
      //console.log('lobs', this.SelectedLob[i], value);
    });
    SelectedCIOs = [...new Set(SelectedCIOs)];

    if (SelectedCIOs.length == 1) {
      this.SelectedCIO = SelectedCIOs[0];
    } else {
      this.SelectedCIO = [];
    }
  }

  selectAlllobName(checkAllId, select: NgModel, values) {
    if (checkAllId) {
      select.update.emit(
        values.map(value => {
          return value;
        })
      );
      this.SelectedLob = [];
      console.log(
        values.map(value => {
          this.SelectedLob.push(value.name);
          return value;
        })
      );
    } else {
      select.update.emit([]);
    }
  }
  menuclose() {
    const elem = document.getElementsByClassName('mat-sidenav-container');
    (elem[0] as any).style.display = 'none';
  }
  menuopen() {
    const elem = document.getElementsByClassName('mat-sidenav-container');
    (elem[0] as any).style.display = 'block';
  }
  menutoggle() {
    const elem = document.getElementsByClassName('mat-sidenav-container');
    const display = (elem[0] as any).style.display;
    (elem[0] as any).style.display = display === 'none' ? 'block' : 'none';
    document.getElementById('menutoggle').classList.toggle('change');
  }
}
