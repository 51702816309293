import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { Bookmark } from '../../interface/interface';
import { BookmarkServiceService } from '../../services/bookmark-service.service';

@Component({
  selector: 'app-bookmark-dialog-popup',
  templateUrl: './bookmark-dialog-popup.component.html',
  styleUrls: ['./bookmark-dialog-popup.component.css']
})
export class BookmarkDialogPopupComponent implements OnInit {
  title: string;
  view: string;
  submitName: string;
  registerForm: FormGroup;
  body: Bookmark;
  formData: any;
  modalData: any;
  config: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private service: BookmarkServiceService,
    public dialogRef: MatDialogRef<BookmarkDialogPopupComponent>
  ) {
    this.title = data.title;
    this.submitName = data.submitName;
    this.view = data.view;
    this.formData = data.data;
  }

  ngOnInit() {
    const group: any = {};
    this.formData.forEach(question => {
      group[question.value] = question.fieldValue ? new FormControl(question.fieldValue) : new FormControl('');
    });
    this.registerForm = new FormGroup(group);
  }

  onSubmit() {
    this.modalData = this.formData[0];
    const { action, bookmarkId = '' } = this.modalData;
    if (action === 'rename') {
      this.updateBookMark(bookmarkId, { name: this.registerForm.value.bookName });
    } else {
      this.saveBookmark();
    }
  }

  updateBookMark(id: number, body: any) {
    this.service.updateBookmark(id, body).subscribe(() => {
      this.dialogRef.close(body.name);
    });
  }

  saveBookmark() {
    const loggedUserMsID = localStorage.getItem('MsId');
    const { askId, model, lob, product_family, product, startDecomTime, endDecomTime, decom_cat, organisation, padu, capL0, capL1, capL2, bookmarkType = '', tech_owner, slo, applicationnames, cio_lob, offerings } = this.modalData;
    this.body = {
      msid: loggedUserMsID,
      name: this.registerForm.value.bookName,
      path: this.view,
      appId: askId,
      lob,
      product_family,
      product,
      organisation,
      model,
      padu,
      capL0,
      capL1,
      capL2,
      startDecomTime,
      endDecomTime,
      decom_cat,
      bookmarkType,
      tech_owner,
      slo,
      applicationnames,
      cio_lob,
      offerings
    };
    this.body.tech_owner = this.body.tech_owner == undefined ? 'NULL' : this.body.tech_owner;
    this.service.addBookmarkDetails(this.body).subscribe(() => {
      this.service.setBookmarkSaved(true);
      this.dialogRef.close();
    });

  }
}
