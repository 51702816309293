<div class="hover_bkgr_fricc" (click)="hidepopup()">
    <span class="helper"></span>
    <div style="background: #eeeeee">
        <div class="popupCloseButton" (click)="hidepopup()">&times;</div>
        <div class="m-1 text-center">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-4" style="font-size: 12px; text-align: left; font-weight: bold;">
                        <div class="">
                            Monthly chargeback trend in {{CurrentYear}}
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xs-8" style="font-size: 12px; text-align: left; font-weight: bold;">
                        <div class="">
                           {{ServName}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <svg class="chart" width="100%" height="100%" viewBox="0 0 750 340"></svg>

    </div>
</div>