import { Directive, Input, Output, ElementRef, EventEmitter, NgZone, OnInit } from '@angular/core';

import { Rect } from './rect';
import { fromResizeObserver, ResizeEvent } from './from-resize-observer';

@Directive({
  selector: '[appLineAnchor]'
})
export class LineAnchorDirective implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('appLineAnchor') name: string;
  // tslint:disable-next-line: no-output-native
  @Output() resize = new EventEmitter<ResizeEvent>();

  constructor(private element: ElementRef, private zone: NgZone) { }

  ngOnInit(): void {
    fromResizeObserver(this.element.nativeElement).subscribe(event => {
      this.zone.run(() => this.resize.emit(event));
    });
  }

  get rect() {
    return Rect.fromElement(this.element);
  }
}
