import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductMappingService } from '../product-mapping.service';
import { FormBuilder } from '@angular/forms';
import { ProductMappingEditComponent } from '../product-mapping-edit/product-mapping-edit.component';
import { DailogModalComponent } from 'src/app/shared/elements/dailog-modal/dailog-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-single-node-view',
  templateUrl: './single-node-view.component.html',
  styleUrls: ['./single-node-view.component.css']
})
export class SingleNodeViewComponent implements OnInit {

  @Input() data;
  @Output() moveNodeVertically: EventEmitter<any> = new EventEmitter();
  @Output() moveCapability: EventEmitter<any> = new EventEmitter();
  @Output() moveCapEnabled: EventEmitter<any> = new EventEmitter();

  @Output() cancelCapability: EventEmitter<any> = new EventEmitter();
  @Output() updateCapability: EventEmitter<any> = new EventEmitter();
  @Output() deleteCapability: EventEmitter<any> = new EventEmitter();


  @Input() mode: string = 'edit';
  showMoveLoader: boolean = false;
  hierarchyNode;
  observable$: any;
  selectedElement: boolean = false;
  addNewNodes: boolean = false;
  showLoader: boolean = false;
  anyChangeInData: boolean = false;
  parentNodeId: any = {};
  isfirstLoad: boolean = true;
  productFamilyId: string = '';
  groupModel: string = '';
  @Input() moveEnabled: boolean = false;
  @Input() isMoveSuccess: boolean = false;
  @Input() openWqReq = [];
  disableAllIcon: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) data,
    public toaster: ToastrService,
    public dialogConfirmation: MatDialog,

    private route: Router, private service: ProductMappingService, private fb: FormBuilder,
    private router: ActivatedRoute, public dialog: MatDialogRef<ProductMappingEditComponent>) { }

  ngOnInit() {

    if (this.mode === 'edit') {
      this.productFamilyId = this.data[0].id;
      this.getDetailsOfProductFamily();
    }
    else {
      this.hierarchyNode = this.data;
    }


  }

  ngOnChanges() {
    if (this.isMoveSuccess) {
      this.showMoveLoader = false;
      this.disableAllIcon = true;
    }

  }

  getDetailsOfProductFamily() {
    this.showLoader = true;
    this.observable$ = this.service.getDetailsOfProductFamily(this.productFamilyId).subscribe(data => {
      this.hierarchyNode = data;
      this.showLoader = false;

    }, () => {
      this.showLoader = false;
    });
  }
  showChildNodes(node) {
    let newNodeLevel = -1;
    this.addNewNodes = !node.addNewNodes;
    const ifOneAddNewNodeExist = node.children.findIndex(element => element.id === '' && element.addNewNodes === true);
    switch (node.level) {
      case 0:
        newNodeLevel = 1;
        break

      case 1:
        newNodeLevel = 2;
        break;

      case 2:
        newNodeLevel = 3;
        break;

    }
    if (ifOneAddNewNodeExist < 0) {
      node.children.unshift(
        { id: '', name: '', description: '', children: [], level: newNodeLevel, rank: node.children.length + 1, addNewNodes: this.addNewNodes, showDescription: false, showChildren: false })
      node.showChildren = true;

    }
    else {
      alert('One add capability form already present');
      node.showChildren = true;
    }

    node.showDescription = false;
  }


  toggleNodes(node) {
    this.moveNodeVertically.emit(true)

    setTimeout(() => {
      node.showChildren = !node.showChildren;
      node.showDescription = false;

      node.highlightNode = (node.children.length > 0) ? true : false;

      if (!node.showChildren) {
        node.children = node.children.map(capL1 => {
          if (capL1.showChildren) {
            return { ...capL1, showChildren: false };
          }
          return capL1;
        }
        )
      }
    }, 0)

  }

  showDescription(node) {

    if (node.showChildren && node.level > 0) {
      node.showChildren = false;
    }
    node.showDescription = !node.showDescription;
  }

  closePopup() {
    this.dialog.close(this.anyChangeInData);
  }

  nameChange(obj) {
    if (obj.addNewNodes) {
      obj.row.name = obj.value;
    }
  }

  receivedFormValue(event,noode) {
    event.changedValue.origname = noode;
    event.hierarchyNode = this.hierarchyNode;
    this.updateCapability.emit(event);
  }

  findNodeParent(level, node) {
    if (level === 1) {
      this.parentNodeId.capabilityL0Id = this.hierarchyNode[0].id;

    }
    else if (level === 2) {
      this.parentNodeId.capabilityL0Id = this.hierarchyNode[0].id;
      this.parentNodeId.capabilityL1Id = node.parentNode.id

    }
    else if (level === 3) {
      this.parentNodeId.capabilityL0Id = this.hierarchyNode[0].id;
      for (let child of this.hierarchyNode[0].children) {
        const filteredNode = child.children.filter(d => d.id === node.parentNode.id)
        if (filteredNode.length) {
          this.parentNodeId.capabilityL1Id = child.id
        }

      }
      this.parentNodeId.capabilityL2Id = node.parentNode.id
      // this.parentNodeId.capabilityL0 = this.hierarchyNode[0].id;
      // this.parentNodeId.capabilityL1 = node.parentNode.id


    }

    return this.parentNodeId;

  }

  selectedNode(node) {
    if (Object.keys(node).includes('selectedNode')) {
      node['selectedNode'] = !node.selectedNode;
    }
    else {
      node['selectedNode'] = true;
    }
    // alert('Node clicked');
  }

  redirectToWorkQueue(reqIds) {
    let isAdminRole = localStorage.getItem('isAdminRole');
    let accessData = JSON.parse(localStorage.getItem('accessData'));
    const isAdmin =
      isAdminRole == 'true' && accessData && accessData['Role'] && accessData['Role'] == 'Admin'
        ? true
        : false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'cap-dialog-container';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '100px';
    dialogConfig.height = 'auto'; 
    dialogConfig.autoFocus = false;
    dialogConfig.position = { left: '60%' };
    
    if(isAdmin == true) {
      dialogConfig.data = {
        title: 'Cannot Move/Delete this Capability',
        subTitle: `<p class="pt-2 capability-pop-up-text">There are open Work Queue requests for this capability.<br/>Therefore it cannot be Moved/deleted. Click <a class="click-here-txt" id="capability-proposal-1">here</a> to view request details.`,
        displaySave: false,
        showButton: true,
      };
    } else {
      dialogConfig.data = {
        title: 'Cannot Move/Delete this Capability',
        subTitle: 'There are open Work Queue requests for this capability. Please contact Admins to Approve/Reject the requests.',
        displaySave: false,
        showButton: true,
      };
    }
    const dialogRef = this.dialogConfirmation.open(DailogModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.data == 'openWQopenRequestForCapabilityProposal'){
        this.openNewWindow(reqIds);
      }
    });
  }
  openNewWindow(reqIds) {
    const urlparams = reqIds.join(',');
    const url = window.location.origin + '/work-queues?req=wq&id=' + JSON.stringify(urlparams);
    window.open(url, '_blank');
  }

  hasOpenWqRequest(node) {
    const L1_Keys = _.groupBy(this.openWqReq, 'Capability_L1_Id');
    const L2_Keys = _.groupBy(this.openWqReq, 'Capability_L2_Id');
    const L3_Keys = _.groupBy(this.openWqReq, 'Capability_L3_Id');
    if(node.level == 1 && L1_Keys[node.id]) {
      const reqIds = L1_Keys[node.id].map(d => d.Request_Id);
      return { hasWq: true, reqIds: reqIds };
    } else if (node.level == 2 && L2_Keys[node.id]) {
      const reqIds = L2_Keys[node.id].map(d => d.Request_Id);
      return { hasWq: true, reqIds: reqIds };
    } else if (node.level == 3 && L3_Keys[node.id]) {
      const reqIds = L3_Keys[node.id].map(d => d.Request_Id);
      return { hasWq: true, reqIds: reqIds };
    }
    return { hasWq: false, reqIds: [] };
  }

  deleteNode(node, parentNode: any = {}) {
    const data = { node, parentNode }
    if (node.id) {
      const { hasWq, reqIds } = this.hasOpenWqRequest(node);
      if(hasWq) {
        this.redirectToWorkQueue(reqIds);
      } else {
        this.deleteCapability.emit(data);
      }
    }
    else {
      this.deleteNodeFromView(node, parentNode)
    }
  }

  deleteL2L3Capability(node, parentNode) {
    const payload: any = {
      level: node.level,
      updatedById: localStorage.getItem('MsId'),
      updatedByName: JSON.parse(localStorage.getItem('currentUser'))

    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '200px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus=false;


    dialogConfig.data = {
      title: 'Delete Confirmation!',
      subTitle: `Associated application under ${node.name} will be moved in its parent capability. Are you sure to continue?`,
      buttonName: 'Delete'
    };

    const dialogRef = this.dialogConfirmation.open(DailogModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result.data === 'save') {
        payload.deleteCap = true,
          this.showLoader = true;
        this.service.deleteProductandMoveAssociatedApp(node.id, payload).subscribe({
          next: () => {
            this.deleteNodeFromView(node, parentNode)

            this.showLoader = false;
            this.anyChangeInData = true;
            this.toaster.success("Successfully deleted.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
          },
          error: error => {
            console.error(error, "error occured while update")
            this.showLoader = false;
            this.anyChangeInData = true;
            this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });

            if (error.error.errors) {

              this.toaster.error(error.error.errors, '', { timeOut: 10000, positionClass: 'toast-top-center' });

            }
          }
        })



      }
    });
  }

  deleteNodeFromView(node, parentNode: any = {}) {
    switch (node.level) {
      case 0:{
        this.hierarchyNode = [];
        break;
      }
      case 1:{
        this.hierarchyNode[0].children = this.hierarchyNode[0].children.filter(d => d.id !== node.id)
        break;
      }
      case 2:{
        const index = this.hierarchyNode[0].children.findIndex(d => d.id === parentNode.id);
        this.hierarchyNode[0].children[index].children = this.hierarchyNode[0].children[index].children.filter(d => d.id !== node.id);
        break;
      }
      case 3:{

        const grandParentIndex = this.hierarchyNode[0].children.findIndex(d => {
          for (let child of d.children) {
            if (child.id === parentNode.id) {
              return true
            }
          }
        });
        // const indexOfGrandParent = this.hierarchyNode[0].children.findIndex(d => d.id === levelIds.capabilityL1Id);
        // console.log(indexOfGrandParent);
        // const indexOfParent = this.hierarchyNode[0].children[indexOfGrandParent].children.findIndex(d => d.id === levelIds.capabilityL2Id);
        const indexOfParent = this.hierarchyNode[0].children[grandParentIndex].children.findIndex(d => d.id === parentNode.id);
        this.hierarchyNode[0].children[grandParentIndex].children[indexOfParent].children = this.hierarchyNode[0].children[grandParentIndex].children[indexOfParent].children.filter(d => d.id !== node.id)
        break;
      }
    }
  }

  moveNode(node, parentNode) {
    const data = { node, parentNode };
    const { hasWq, reqIds } = this.hasOpenWqRequest(node);
    if(hasWq) {
      this.redirectToWorkQueue(reqIds);
    } else {
      this.moveCapEnabled.emit(data)
      this.moveEnabled = true;
    }
  }

  moveData(node,parent) {
    const { hasWq, reqIds } = this.hasOpenWqRequest(node);
    if(hasWq) {
      this.redirectToWorkQueue(reqIds);
    } else {
      this.showMoveLoader = true;
      const data = { node, parent }
      this.moveCapability.emit(data);
    }
  }

  cancelMove() {
    this.cancelCapability.emit(false);
  }

  ngOnDestroy() {
    if (this.observable$) {
      this.observable$.unsubscribe();
    }

  }


}
