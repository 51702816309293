import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { AppHubUtilService, SharedService } from 'src/app/shared';
import { OrdinalDatePipe } from 'src/app/shared/pipe/ordinal-date.pipe';

interface ReleaseInformationModel {
  releaseDate: string,
  occurance: number,
  title: string,
  points: string,
  titlePointsMap: Map<string,string[]>
}

@Component({
  selector: 'app-release-note-v4',
  templateUrl: './release-note-v4.component.html',
  styleUrls: ['./release-note-v4.component.css']
})
export class ReleaseNoteV4Component implements OnInit {

  refreshData;
  data;
  showLoader = true;
  public releaseInfoText = "View Previous Releases";
  public currentReleaseNote = {} as ReleaseInformationModel;
  public previousReleaseNote1 = {} as ReleaseInformationModel;
  public previousReleaseNote2 = {} as ReleaseInformationModel;
  public viewPreviousReleases = false;
  constructor(private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private util: AppHubUtilService,
    private sharedService: SharedService,
    private ordinalDatePipe: OrdinalDatePipe) {
    iconRegistry.addSvgIcon(
      'chrome',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons8-chrome.svg')
    );

    iconRegistry.addSvgIcon(
      'internet-explorer',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons8-internet-explorer.svg')
    );
  }

  refreshHeaderColumns = [
    { columnDef: 'type', header: 'Data Source', width: '50%', fontWeight: 'bold', cell: (element: any) => `${element.type}` },
    { columnDef: 'date', header: 'Data As Of', width: '50%', fontWeight: 'bold', cell: (element: any) => `${this.util.getFormatDate(element.date)}` }
  ];


  ngOnInit() {
    this.getReleaseNotes();
    this.sharedService.refreshDate().subscribe((date) => {
      this.data = date;
      this.refreshData = new MatTableDataSource(this.data);
      this.showLoader = false;
    });
  }

  /**
    * @author: Rohit Kadam
    * @description: Get Release Notes Details From API  
  */
  getReleaseNotes() {
    this.sharedService.getReleaseNotesData().subscribe(res => {
      if(res) {
        if(Object.keys(res).length > 0) {
          Object.keys(res).forEach(key=> {
            this.configureReleaseInfoObj(res[key],key);
            if(key == '1') {
              this.currentReleaseNote = this.configureReleaseInfoObj(res[key],key);
            }
            if(key == '2') {
              this.previousReleaseNote1 = this.configureReleaseInfoObj(res[key],key);
            }
            if(key == '3') {
              this.previousReleaseNote2 = this.configureReleaseInfoObj(res[key],key);
            }
          });
        }
      }
    })
  }

/**
  * @author: Rohit Kadam
  * @description: Configure Release Notes Object
*/
  configureReleaseInfoObj(res,i) {
    let obj : ReleaseInformationModel = {} as ReleaseInformationModel;
    obj.occurance = i;
    let pointsMap = new Map<string,string[]>();
    if(Object.keys(res).length > 0) {
      Object.keys(res).forEach(key =>{
        let arr = res[key];
        obj.releaseDate = this.ordinalDatePipe.transform(new Date(arr[0]['release_date']));
        if(arr.length > 0) {
          arr = arr.map(a => a.points);
          pointsMap.set(key,arr);
        }
      })
    }
    obj.titlePointsMap  = pointsMap;
    return obj;
  }

  /**
    * @author: Rohit Kadam
    * @description: Show/Hide Previous Release Notes Details
  */
  showHideNotes() {
    this.viewPreviousReleases = !this.viewPreviousReleases;
    if(this.viewPreviousReleases) {
      this.releaseInfoText = "Latest Release Only";
    } else {
      this.releaseInfoText = "View Previous Releases";
    }
  }

}
