import { Injectable,NgZone} from '@angular/core';
import { Observable, interval, BehaviorSubject } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
const STORE_KEY =  'userLastAction';
@Injectable({
  providedIn: 'root'
})
export class IdletimeService {
  public static runTimer: boolean;
  public static runSecondTimer: boolean;
  public USER_IDLE_TIMER_VALUE_IN_MIN: number;
  public FINAL_LEVEL_TIMER_VALUE_IN_MIN: number;
  public userIdlenessChecker: BehaviorSubject<string>;

  private sessionForIdle: Observable<number>;
  private userActivityChangeCallback: ($event) => void;

  public clockForIdle: Observable<number>;

  constructor(private zone: NgZone) {
    if (!this.userIdlenessChecker) {
      this.userIdlenessChecker = new BehaviorSubject<string>('INITIATE_TIMER');
    }


  }

  public initilizeSessionTimeout(): void {
    IdletimeService.runTimer = true;
    this.reset();
    this.initListener();
    this.initInterval();
  }

  get lastAction(): number {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }

  set lastAction(value) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  private initListener(): void {
    this.zone.runOutsideAngular(() => {
      this.userActivityChangeCallback = () => this.handleUserActiveState();
      window.document.addEventListener('click', this.userActivityChangeCallback.bind(this), true);
      window.document.addEventListener('mouseover', this.userActivityChangeCallback.bind(this), true);
    });
  }

  handleUserActiveState(): void {
    this.reset();
  }

  public reset(): void {
    this.lastAction = Date.now();
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.next('RESET_TIMER');
    }
  }

  private initInterval(): void {
    const intervalDuration = 1000;
    this.sessionForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      }),
      takeWhile(() => IdletimeService.runTimer)
    );

    this.check();
  }

  private check(): void {
    this.sessionForIdle
    .subscribe(() => {
      const now = Date.now();
      const timeleft = this.lastAction + this.USER_IDLE_TIMER_VALUE_IN_MIN * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;

      this.userIdlenessChecker.next(`${diff}`);

      if (isTimeout) {
        window.document.removeEventListener('click', this.userActivityChangeCallback, true);
        this.zone.run(() => {
          if (this.userIdlenessChecker) {
            this.userIdlenessChecker.next('STOPPED_TIMER');

            if (this.FINAL_LEVEL_TIMER_VALUE_IN_MIN > 0) {
              this.executeFinalTimer();
            }
          }
          IdletimeService.runTimer = false;
        });
      }
    });
  }

  public removeActionFromStore(): void {
    localStorage.removeItem(STORE_KEY);
  }

  private executeFinalTimer = () => {
    this.initializeFinalTimer();
  }

  private initializeFinalTimer(): void {
    const intervalDuration = 1000;
    this.clockForIdle = interval(intervalDuration).pipe(
      map((tick: number) => {
        return tick;
      })
    );

    this.checkUserActionTime();
  }

  private checkUserActionTime(): void {
    let timeInSecond = 60;
    let timeInMin = this.FINAL_LEVEL_TIMER_VALUE_IN_MIN - 1;
    this.clockForIdle.subscribe(() => {
      if (--timeInSecond === 0) {
        if (--timeInMin === 0) {
          timeInMin = (timeInMin - 1);
        }
        if (timeInMin < 0) {
          timeInMin = 0;
          setTimeout(() => {
            timeInSecond = 60;
          }, 800);
        } else {
          timeInSecond = 60;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.unsubscribe();
      this.userIdlenessChecker = undefined;
    }
  }
}
