import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DailogModalComponent } from 'src/app/shared/elements/dailog-modal/dailog-modal.component';
import { AdminService } from 'src/app/shared/services/admin.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrl: './report-management.component.css'
})
export class ReportManagementComponent {
  report_dataSource: MatTableDataSource<any>;
  showLoader: boolean = false;
  dataToEdit: any;
  popHeader: string;
  popupHeader: string;
  formfields: any;
  tableName: string;
  formGroupName: string;
  editFlag: boolean = false;
  hasEditAccessFlag: boolean;
  @ViewChild('addEditReportPopup') addEditReportPopup;
  report_displayedColumns: string[] = ['Report', 'Description', 'Report_Type', 'Managedby', 'Secure', 'Secure_Check', 'Page_name', 'Embed_type'];
  REPORT_COL = [];
  managedbyDropdownValues = ['TechOps', 'Workforce'];
  pageNameDropdownValues = ['Reports', 'Workforce'];
  embedtypeDropdownValues = ['Iframe', 'Web'];
  addReportFormFields = [
    {
      'label': "Report",
      'inputType': "text",
      'fcname': "Report",
      'placeholder': "Enter Report",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Report Id",
      'inputType': "text",
      'fcname': "Report_Id",
      'placeholder': "Report Id",
      'dropdownData': null,
      'dependentField': ["Report_Type", "Embed_type"],
      'depFieldVal': ['Power BI', 'Web'],
      'mandCheck': ['equal', 'equal'],
    },
    {
      'label': "Report Type",
      'inputType': "text",
      'fcname': "Report_Type",
      'placeholder': "Enter Report Type",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Internal Report",
      'inputType': "yesnoradio",
      'fcname': "internal_Report",
      'placeholder': null,
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Report Link",
      'inputType': "textarea",
      'fcname': "Report_Link",
      'dependentField': ["Report_Type"],
      'depFieldVal': ['power bi'],
      'mandCheck': ['notEqual'],
      'placeholder': "",
      'dropdownData': null
    },
    {
      'label': "Report Description",
      'inputType': "textarea",
      'fcname': "Description",
      'placeholder': "",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Documentation",
      'inputType': "textarea",
      'fcname': "Documentation",
      'placeholder': "",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    }
    ,
    {
      'label': "Image",
      'inputType': "text",
      'fcname': "Image",
      'placeholder': "Image",
      'dropdownData': null,
      'dependentField': ["Page_name"],
      'depFieldVal': ["Workforce"],
      'mandCheck': ['equal'],
    },
    {
      'label': "Access Restricted",
      'inputType': "yesnoradio",
      'fcname': "Access_Restricted",
      'placeholder': null,
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Access Restriction Link",
      'inputType': "textarea",
      'fcname': "Access_Restriction_link",
      'placeholder': "",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Category",
      'inputType': "text",
      'fcname': "Category",
      'placeholder': "Enter Category",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Category Rank",
      'inputType': "number",
      'fcname': "Category_rank",
      'placeholder': "Enter Category rank",
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Embed",
      'inputType': "yesnoradio",
      'fcname': "EMbed",
      'placeholder': null,
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Embed Link",
      'inputType': "textarea",
      'fcname': "Embed_link",
      'placeholder': "",
      'dropdownData': null,
      'dependentField': ["Report_Type", "Embed_type"],
      'depFieldVal': ['Power BI', 'Iframe'],
      'mandCheck': ['equal', 'equal'],
    },
    {
      'label': "Embed Type",
      'inputType': "singleSelect",
      'fcname': "Embed_type",
      'placeholder': "Select Embed type",
      'dropdownData': this.embedtypeDropdownValues,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Managed By",
      'inputType': "singleSelect",
      'fcname': "Managedby",
      'placeholder': "Select Managed by",
      'dropdownData': this.managedbyDropdownValues,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Secure",
      'inputType': "yesnoradio",
      'fcname': "Secure",
      'placeholder': null,
      'dropdownData': null,
      'dependentField': ['Access_Restricted'],
      'depFieldVal': ['Yes'],
      'mandCheck': ['equal']
    },
    {
      'label': "Secure Check",
      'inputType': "text",
      'fcname': "Secure_Check",
      'placeholder': "Enter Secure Check",
      'dropdownData': null,
      'dependentField': ['Access_Restricted'],
      'depFieldVal': ['Yes'],
      'mandCheck': ['equal']
    },
    {
      'label': "Secure Group",
      'inputType': "text",
      'fcname': "Secure_group",
      'placeholder': "Enter Secure Group",
      'dropdownData': null,
      'dependentField': ['Access_Restricted'],
      'depFieldVal': ['Yes'],
      'mandCheck': ['equal']
    },
    {
      'label': "Page Name",
      'inputType': "singleSelect",
      'fcname': "Page_name",
      'placeholder': "Select Page name",
      'dropdownData': this.pageNameDropdownValues,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Has Owner",
      'inputType': "yesnoradio",
      'fcname': "HasOwner",
      'placeholder': null,
      'dropdownData': null,
      'dependentField': null,
      'depFieldVal': null,
      'mandCheck': null
    },
    {
      'label': "Owner Details",
      'inputType': "text",
      'fcname': "ownerDetails",
      'placeholder': "Enter Owner Details",
      'dropdownData': null,
      'dependentField': ['HasOwner'],
      'depFieldVal': ['Yes'],
      'mandCheck': ['equal']
    }
  ];
  closePopup: boolean = false;
  isReportAdmin: boolean = false;
  isReportWf: boolean = false;
  @Input() hasReportWfAccess: boolean;
  @Input() hasReportAdminAccess: boolean;
  ngOnInit() {
    this.getReports();
    this.REPORT_COL = [
      {
        columnDef: 'Report',
        header: 'Report',
        filter: true,
        width:'14% !important'
      },
      {
        columnDef: 'Description',
        header: 'Description',
        filter: false,
        width:'18% !important'
      },
      {
        columnDef: 'Report_Type',
        header: 'Report Type',
        filter: true,
      },
      {
        columnDef: 'Managedby',
        header: 'Managed By',
        filter: true,
      }, {
        columnDef: 'Secure',
        header: 'Secure',
      }, {
        columnDef: 'Secure_Check',
        header: 'Secure Check',
      }, {
        columnDef: 'Page_name',
        header: 'Page Name',
        filter: true
      },
      {
        columnDef: 'Embed_type',
        header: 'Embed Type',
        filter: true
      },
      {
        columnDef: 'Actions',
        header: 'Action',
        displayText: 'EditDelete',
        hasAdminAccess: this.hasReportAdminAccess,
        hasReportWfAccess: this.hasReportWfAccess,
       
      }
    ];
  }
  constructor(public admnsrv: AdminService, public toaster: ToastrService, public dialog: MatDialog, public datepipe: DatePipe) { }
  getReports() {
    this.admnsrv.getReports().subscribe((response: any) => {
      this.showLoader = false;
      this.modifyDates(response.result);
      this.report_dataSource = new MatTableDataSource(response.result);
    },
      (err) => {
        this.showErrMsg(err);

      })
  }

  modifyDates(data) {
    data.forEach(element => {
      if (element['updatedAt']) {
        element['updatedAt'] = this.datepipe.transform(element['updatedAt'], 'dd/MM/yyyy,h:mm a');
      }
    });
    return data;
  }

  showErrMsg(err: any) {
    this.showLoader = false;
    if (err && err.messsage) {
      this.toaster.error(err.message, '', {
        timeOut: 10000,
        positionClass: 'toast-top-center'
      });
    }
  }

  showSuccessMsg(msg: any) {
    this.showLoader = false;
    if (msg) {
      this.toaster.success(msg, '', {
        timeOut: 10000,
        positionClass: 'toast-top-center'
      });
    }
  }

  addReports() {
    this.dataToEdit = {};
    this.closePopup = false;
    this.addEditReportPopup.show();
    this.formfields = this.addReportFormFields;
    this.popupHeader = "Add Reports";
    this.tableName = "REPORT_MANAG";
    this.editFlag = false;
  }

  editReports(event) {
    this.editFlag = true;
    this.closePopup = false;
    this.dataToEdit = event.data;
    this.popupHeader = "Update Reports";
    this.formfields = this.addReportFormFields;
    this.addEditReportPopup.show();

  }

  emitReqPayload(event) {
    this.showLoader = true;
    this.addEditReportPopup.hide();
    if (event.editflag) {
      this.admnsrv.updateReports(event.payload).subscribe((response) => {
        this.showSuccessMsg(response.msg);
        this.getReports();
      },
        (err) => {
          this.showErrMsg(err);
        })
    } else {
      if (event.payload.id) delete event.payload.id;
      this.admnsrv.addReports(event.payload).subscribe((response) => {
        this.showSuccessMsg(response.msg);
        this.getReports();
      },
        (err) => {
          this.showErrMsg(err);
        })
    }
    console.log(event)
  }


  closeModal() {
    this.closePopup = true;
  }
  deleteReports(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '200px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: 'Delete Confirmation!',
      subTitle: 'Are you to sure to delete the Record?',
      buttonName: 'Continue'
    };

    const dialogRef = this.dialog.open(DailogModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result.data != 'cancel') {
        this.showLoader = true;
        this.admnsrv.deleteRecord(event).subscribe((res: any) => {
          if (res) {
            this.showLoader = false;
            this.showSuccessMsg(res.msg);
            this.getReports();
          }
        })
      }
    },
      (error) => {
        console.error(error);
      });
  }

}

