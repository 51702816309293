<div class="app-attributes-dropdown">
    <label class="section-name tabl-header tabl-header-font">
        {{dropdownLabel}}
    </label>
    <mat-form-field appearance="outline" class="select-dropdown demographics-attributes-dropdowns">
        <mat-select [formControl]="hostingEnvs" (selectionChange)="onChange($event)" multiple panelClass="app-hosting-dropdown">
            <div>
                <input matinput type="text" [(ngModel)]="searchAppTypeTxt" class="mat-input-search" placeholder="Search"
                    (keydown)="$event.stopPropagation()" (keyup)="filterData()">
            </div>
            <mat-select-trigger>
                {{selectedValue}}
            </mat-select-trigger>
            <div class="attr-checkbox">
                <mat-checkbox (change)="selectAllEnvs($event)" [checked]="allEnvsSelected"
                    [indeterminate]="checkIndeterminate()"
                    (click)="$event.stopPropagation()" style="padding-top: 2%;">
                    <label style="margin:0%">Select All</label>
                </mat-checkbox>
            </div>
            <mat-optgroup *ngFor="let group of hostingEnvList">
                <div class="attr-checkbox">
                    <mat-checkbox [checked]="group.selected" [indeterminate]="group.indeterminate" (change)="toggleSelection($event, group)"
                        (click)="$event.stopPropagation()"  class="group-checkbox-width">
                        <label style="margin:0%">{{group.l0Name}} </label>
                    </mat-checkbox>
                </div>
                <mat-option #matOption1 *ngFor="let subEnv of group.subEnvs" [selected]="subEnv.id" [value]="subEnv.id" class="list-show"
                    style="padding-left: 10%;" (click)="onEnvChange(matOption1.selected,group,subEnv)">
                    <label style="margin:0%">{{subEnv.l1Name}}</label>
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</div>