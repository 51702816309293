import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';
import { AppHubService } from 'src/app/techopsv4/application-hub/app-hub.service';
import { environment } from 'src/environments/environment';
import { tcmHubConfigDetails } from '../../../config';
import { ExportDataService } from '../../services/export-data.service';

@Component({
  selector: 'app-stackchart-trend',
  templateUrl: './stackcharttrend.component.html',
  styleUrls: ['./stackcharttrend.component.css']
})
export class StackcharttrendComponent implements OnInit {
  @Input() CurrentYear = (tcmHubConfigDetails.CurrentYear);

  @Input() data = [
    {
      group: 'Jan',
      data: [
        {
          month: 'Jan',
          year: '2010',
          om: 11,
          dev: 10
        },
        {
          month: 'Jan',
          year: '2011',
          om: 12,
          dev: 13
        },
        {
          month: 'Jan',
          year: '2012',
          om: 14,
          dev: 15
        }
      ]
    },
    {
      group: 'Feb',
      data: [
        {
          month: 'Feb',
          year: '2010',
          om: 16,
          dev: 17
        },
        {
          month: 'Feb',
          year: '2011',
          om: 18,
          dev: 19
        },
        {
          month: 'Feb',
          year: '2012',
          om: 20,
          dev: 21
        }
      ]
    },
    {
      group: 'Mar',
      data: [
        {
          month: 'Mar',
          year: '2010',
          om: 22,
          dev: 23
        },
        {
          month: 'Mar',
          year: '2011',
          om: 24,
          dev: 25
        },
        {
          month: 'Mar',
          year: '2012',
          om: 26,
          dev: 27
        }
      ]
    },
    {
      group: 'Apr',
      data: [
        {
          month: 'Apr',
          year: '2010',
          om: 28,
          dev: 29
        },
        {
          month: 'Apr',
          year: '2011',
          om: 30,
          dev: 31
        },
        {
          month: 'Apr',
          year: '2012',
          om: 32,
          dev: 33
        }
      ]
    },
    {
      group: 'May',
      data: [
        {
          month: 'May',
          year: '2010',
          om: 34,
          dev: 35
        },
        {
          month: 'May',
          year: '2011',
          om: 36,
          dev: 37
        },
        {
          month: 'May',
          year: '2012',
          om: 38,
          dev: 39
        }
      ]
    },
    {
      group: 'Jun',
      data: [
        {
          month: 'Jun',
          year: '2010',
          om: 40,
          dev: 42
        },
        {
          month: 'Jun',
          year: '2011',
          om: 12,
          dev: 13
        },
        {
          month: 'Jun',
          year: '2012',
          om: 14,
          dev: 15
        }
      ]
    },
    {
      group: 'Jul',
      data: [
        {
          month: 'Jul',
          year: '2010',
          om: 16,
          dev: 17
        },
        {
          month: 'Jul',
          year: '2011',
          om: 18,
          dev: 19
        },
        {
          month: 'Jul',
          year: '2012',
          om: 20,
          dev: 21
        }
      ]
    },
    {
      group: 'Aug',
      data: [
        {
          month: 'Aug',
          year: '2010',
          om: 22,
          dev: 23
        },
        {
          month: 'Aug',
          year: '2011',
          om: 24,
          dev: 25
        },
        {
          month: 'Aug',
          year: '2012',
          om: 39,
          dev: 41
        }
      ]
    },
    {
      group: 'Sep',
      data: [
        {
          month: 'Sep',
          year: '2010',
          om: 39,
          dev: 41
        },
        {
          month: 'Sep',
          year: '2011',
          om: 39,
          dev: 41
        },
        {
          month: 'Sep',
          year: '2012',
          om: 39,
          dev: 41
        }
      ]
    },
    {
      group: 'Oct',
      data: [
        {
          month: 'Oct',
          year: '2010',
          om: 39,
          dev: 41
        },
        {
          month: 'Oct',
          year: '2011',
          om: 39,
          dev: 41
        },
        {
          month: 'Oct',
          year: '2012',
          om: 39,
          dev: 41
        }
      ]
    },
    {
      group: 'Nov',
      data: [
        {
          month: 'Nov',
          year: '2010',
          om: 39,
          dev: 41
        },
        {
          month: 'Nov',
          year: '2011',
          om: 39,
          dev: 41
        },
        {
          month: 'Nov',
          year: '2012',
          om: 39,
          dev: 41
        }
      ]
    },
    {
      group: 'Dec',
      data: [
        {
          month: 'Dec',
          year: '2010',
          om: 39,
          dev: 41
        },
        {
          month: 'Dec',
          year: '2011',
          om: 39,
          dev: 41
        },
        {
          month: 'Dec',
          year: '2012',
          om: 39,
          dev: 41
        }
      ]
    }
  ];
  // List of subgroups = header of the csv files = soil condition here
  //const subgroups = ['om', 'dev', '2012']
  @Input() subgroups = ['om', 'dev'];
  // List of groups = species here = value of the first column called group -> I show them on the X axis
  groups = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  svg;
  color;
  x;
  y;
  tooltip;
  @Input() label = 'Labor';
  type = 'om';
  @Input() name: any;
  total: any = 0;
  @Input() childgroups = [this.CurrentYear - 2, this.CurrentYear - 1, this.CurrentYear];
  xSubgroup: any;
  omdevfilter = false;
  filterData: any = [];
  dev: any = [];
  om: any = [];
  ommax: any;
  devmax: any;
  curYear = new Date().getUTCFullYear();
  @Input() hub: any;
  @Output('exportData') eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private container: ElementRef,
    private http: HttpClient,
    private decimalPipe: DecimalPipe,
    private service: AppHubService,
    private exportService: ExportDataService
  ) {}
  /**
   * @author: Anjit
   * @description: format the number as per notation
   * @augments: number
   * @returns: number with notation
   */
  decimalFormatter(num, digit) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' }
    ];

    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digit) + si[i].symbol;
  }

  ngOnInit() {
    if (this.data) {
      this.createchart(this.data);
    }
  }
  ngOnChanges() {
    this.childgroups = [this.CurrentYear - 2, this.CurrentYear - 1, this.CurrentYear];
    if (this.data) {
      this.total = 0;
      d3.select(this.container.nativeElement)
        .selectAll('.stackchart svg')
        .remove();
      this.createchart(this.data);
    }
  }
  ngAfterViewInit() {
    // this.textbind();
  }
  /**
   * @author: Anjit
   * @description: create bar chart with 3 year trend
   * @augments: 3 year data array
   * @returns: svg chart
   */
  stack(data) {
    return d3.stack().keys(this.subgroups)(data);
  }
  update(type,event) {
    if (type == 'filter') {
      this.omdevfilter = true;
    }

    let datum = this.data.length > 0 ? this.data : [];
    let checkedvalues = [];
    let year = [];
    let e = event;
    let key = [];
    let checkedcount = 0;
    let nodes = <any>this.container.nativeElement.querySelectorAll('.years');
    let skipyear: any = [];
    for (const e2 of nodes) {
      let key2 = e2.defaultValue;
      if (type == 'reset') {
        e.checked = false;
        if (key2 !== `${this.CurrentYear}`) e2.checked = true;
        this.omdevfilter = false;
      }
      if (!e2.checked) {
        year.push(key2);
        skipyear = {
          ...skipyear,
          year: year
        };
        e2.disabled = false;
        const classname = '.sum-' + key2;
        this.container.nativeElement.querySelector(classname).style.display = 'none';
      } else {
        checkedcount++;
        if (checkedcount < 2) {
          e2.disabled = true;
        } else {
          for (const e5 of nodes) {
            e5.disabled = false;
          }
        }
        const classname = '.sum-' + key2;
        if (this.container.nativeElement.querySelector(classname)) {
          this.container.nativeElement.querySelector(classname).style.display = 'inline-block';
        }
      }
    }
    if (this.omdevfilter) {
      if (e.checked && type != 'year' || !e.checked && type == 'year') {
        key = ['dev'];
        checkedvalues = {
          ...checkedvalues,
          [`${key}`]: 0
        };
  
      } else {
        key = ['om'];
        checkedvalues = {
          ...checkedvalues,
          [`${key}`]: 0
        };
      }
    }
    datum = datum.map(p => {
      return {
        ...p,
        data: [
          ...p.data.map(e3 => {
            return {
              ...e3,
              ...checkedvalues
            };
          })
        ]
      };
    });
    datum = datum.map(p => {
      return {
        ...p,
        data: [
          ...p.data.map(e4 => {
            if (Object.prototype.hasOwnProperty.call(skipyear,'year')) {
              if (skipyear.year.includes(`${e4.year}`)) {
                return {
                  ...e4,
                  om: 0,
                  dev: 0
                };
              } else {
                return e4;
              }
            } else {
              return e4;
            }
          })
        ]
      };
    });

    this.laborsum(datum);
    this.filterData = datum;
    this.svg.selectAll('.chart-container g').remove();

    this.svg
      .select('.chart-container')
      .selectAll('g')
      .data(datum)
      .join('g')
      .attr('transform', (d: any = []) => {
        return `translate(${this.x(d.group)}, 0)`;
      })
      .attr('class', 'year')
      .selectAll('g')
      // Enter in the stack data = loop key per key = group per group
      .data((d: any = []) => {
        return this.stack(d.data);
      })
      .enter()
      .append('g')
      .attr('fill', d => {
        return this.color(d.key);
      })
      .selectAll('rect')
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d = []) => {
        return d;
      })
      .join('rect')
      .attr('class', 'stack-bar')
      .attr('x', d => {
        return this.xSubgroup(d.data.year) ? this.xSubgroup(d.data.year) : 20;
      })
      .attr('y', d => {
        return this.y(d[1]);
      })
      .attr('height', d => {
        let hdata = d ? this.y(d[0]) - this.y(d[1]) : 0;
        return hdata;
      })
      .attr('width', 15)
      .attr('stroke', 'grey')
      .on('mouseover', d => {
        let subgroupName = d3.select(d3.event.currentTarget).node().parentNode.__data__.key;
        let subgroupValue = d.data[subgroupName];
        let month = d.data['month'];
        let year = d.data['year'];
        d3.select('.stacktooltip')
          .html(
            ' Year: <b>' +
              year +
              '</b><br>' +
            'Month: <b>' +
              month +
              '</b><br>' +
              subgroupName +
              ': <b>$' +
              this.decimalFormatter(subgroupValue, 1) +
              '</b>'
          )
          .style('opacity', 1);
      })
      .on('mousemove', function() {
        // d3.select(".stacktooltip")
        //   .style("left", (d3.mouse(this)[0]) +
        //     "px"
        //   ) // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
        //   .style("top", (d3.mouse(this)[1]) + "px")
      })
      .on('mouseleave', function() {
        d3.select('.stacktooltip').style('opacity', 0);
      });
  }
  createchart(data) {

    // Parse the Data
    let groupmax = [];
    if (data.length > 0) {
      data.forEach(element => {
        element.data.forEach(e => {
          groupmax.push(e.om + e.dev);
        });
      });
    }
    // set the dimensions and margins of the graph
    const margin = {
        top: 10,
        right: 30,
        bottom: 20,
        left: 50
      },
      width = 1200 - margin.left - margin.right,
      height = 350 - margin.top - margin.bottom;

    this.container.nativeElement.querySelectorAll('.stackchart svg').forEach(function(elem) {
      elem.parentNode.removeChild(elem);
    });
    // append the svg object to the body of the page
    this.svg = d3
      .select(this.container.nativeElement)
      .select('.stackchart')
      .append('svg')
      .attr('overflow', 'visible')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('viewBox', '0 0 1150 350')
      // .attr("width", width + margin.left + margin.right)
      // .attr("height", height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add X axis
    this.x = d3
      .scaleBand()
      .domain(this.groups)
      .range([0, width])
      .padding([0.2]);

    this.svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(this.x).tickSize(0))
      .selectAll('text')
      .attr('y', 8)
      .style('text-anchor', 'start');

    // Add Y axis
    this.y = d3
      .scaleLinear()
      .domain([0, this.laborsum(data)])
      .range([height, margin.top]);
    this.svg.append('g').call(d3.axisLeft(this.y).tickFormat(d => '$' + this.decimalFormatter(d, 1)));

    // Another scale for subgroup position?
    this.xSubgroup = d3
      .scaleBand()
      .domain(this.childgroups)
      .range([0, this.x.bandwidth()])
      .padding([0.05]);

    // color palette = one color per subgroup
    this.color = d3
      .scaleOrdinal()
      .domain(this.subgroups)
      .range(['#075786', '#9fc5d9']);

    // ----------------
    // Create a tooltip
    // ----------------
    this.tooltip = d3
      .select(this.container.nativeElement)
      .select('.stackchart')
      .append('div')
      .style('opacity', 0)
      .attr('font-weight', 'bold')
      .attr('class', 'stacktooltip')
      .style('border-radius', '8px 8px 8px 8px')
      .style('padding', '5px')
      .style('font-size','12px')
      .style('width', ' fit-content')
      .style('text-align', 'center')
      .style('text-transform', 'capitalize')
      .style('background', '#000000b5')
      .style('color','#fff')
      .style('position', 'absolute')
      .style('top', '50px')
      .style('right', '535px')
      .style('z-index', '9999');

      this.svg
      .append('g')
      .attr('class', 'chart-container')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('transform', (d: any = []) => {
        return `translate(${this.x(d.group)}, 0)`;
      })
      .attr('class', 'year')
      .selectAll('g')
      // Enter in the stack data = loop key per key = group per group
      .data((d: any = []) => {
        // let stackdata = d ? d : [];
        return this.stack(d.data);
      })
      .enter()
      .append('g')
      .attr('fill', d => {
        return this.color(d.key);
      })
      .selectAll('rect')
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d = []) => {
        return d;
      })
      .join('rect')
      .attr('class', 'stack-bar')
      .attr('x', d => {
        return this.xSubgroup(d.data.year);
      })
      .attr('y', d => {
        return this.y(d[1]);
      })
      .attr('height', d => {
        let hdata = d ? this.y(d[0]) - this.y(d[1]) : 0;
        return hdata;
      })
      .attr('width', 15)
      .attr('stroke', 'grey')

      .on('mouseover', d => {
        let subgroupName = d3.select(d3.event.currentTarget).node().parentNode.__data__.key;
        let subgroupValue = d.data[subgroupName];
        let month = d.data['month'];
        let year = d.data['year'];
        d3.select('.stacktooltip')
          .html(
            ' Year: <b>' +
              year +
              '</b><br>' +
            'Month: <b>' +
              month +
              '</b><br>' +
              subgroupName +
              ': <b>$' +
              this.decimalFormatter(subgroupValue, 1) +
              '</b>'
          )
          .style('opacity', 1);
      })
      .on('mousemove', function() {
        // d3.select(".stacktooltip")
        //   .style("left", (d3.mouse(this)[0]) +
        //     "px"
        //   ) // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
        //   .style("top", (d3.mouse(this)[1]) + "px")
      })
      .on('mouseleave', function() {
        d3.select('.stacktooltip').style('opacity', 0);
      });

    this.update('',{checked: false});
  }
  textbind() {
    let node = <any>this.container.nativeElement.querySelectorAll('.stack-bar');
    for (let e of node) {
      let text = parseFloat(e.firstChild.innerHTML);
      e.firstChild.innerHTML = this.decimalFormatter(text, 1);
      let html = e.innerHTML;
      if (text !== 0) e.insertAdjacentHTML('afterend', html);
      e.innerHTML = '';
    }
  }

  groupsum(key) {
    if (this.filterData) {
      let groupmax = [];
      this.filterData.forEach(element => {
        element.data.forEach(e => {
          if (e.year == key) groupmax.push(e.om + e.dev);
        });
      });
      return this.decimalFormatter(d3.sum(groupmax), 1);
    }
  }
  laborsum(data) {
    if (data.length > 0) {
      let groupmax = [];
      data.forEach(element => {
        element.data.forEach(e => {
          this.om.push(e.om);
          this.dev.push(e.dev);
          groupmax.push(e.om + e.dev);
        });
      });
      this.total = this.decimalFormatter(d3.max(groupmax), 1);
      this.ommax = d3.max(this.om);
      this.devmax = d3.max(this.dev);
      return d3.max(groupmax);
    }
  }
  showInfo() {
    window.open(environment.docUrl + 'user-guide/Portfolio-Hub/Cost-Views/#labor-monthly-trend');
    // window.open(environment.costviewUrl);
  }
  exportData() {
    let data: any;
    if (this.hub == 'portfolio hub') {
      this.eventEmitter.emit(true);
    } else {
      data = this.service.excelData;
      const array = [];
      const arr = [];
      const item = [];
      arr['AppData'] = data;
      arr['LaborData'] = this.data;
      item.push(arr);
      for (const prop in item[0]) {
        const finalExport = [];
        if (prop === 'AppData' && data != undefined) {
          let exportJSON: any = {};
          if(data) {
            exportJSON = data;
          }
          finalExport.push(exportJSON);
        }
        if (prop === 'LaborData' && this.data != undefined) {
          this.data.forEach(element => {
            element.data.forEach(element1 => {
              const exportJSON: any = {};
              exportJSON['Application Id'] = data['Application Id'];
              exportJSON['Application Name'] = data['Application Name'];
              exportJSON['Month'] = element1['month'];
              exportJSON['Year'] = element1['year'];
              exportJSON[' OM'] = element1['om'];
              exportJSON[' Dev'] = element1['dev'];
              finalExport.push(exportJSON);
            });
          });
        }

        array.push(finalExport);
      }
      this.exportService.exportToExcel(data['Application Name'] + ' Labor', array, ['Application', 'Labor']);
    }
  }
}
