<div *ngIf="filterConfig" style="position: absolute;
right: 16%;top: -61px;" class="search-container" appearance="outline">
  <input matInput [(ngModel)]="searchField" (keyup)="filterConfig.filterCallBack($event)"
    placeholder="{{ filterConfig.placeHolderText }}" class="searchoption" />
</div>
<table *ngIf="dataSource?.data.length > 0 " mat-table class="mat-elevation-z8" [dataSource]="dataSource"
  multiTemplateDataRows>



  <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns; let i = index">
    <ng-container *ngIf="column.columnDef === 'select'">
      <th mat-header-cell *matHeaderCellDef [style.width]="column.width" [style.display]="column.display">
        <mat-checkbox [disabled]="disableOtherIcon" (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" [style.text-align]="'center'" [style.display]="column.display">
        <mat-checkbox [disabled]="disableOtherIcon && row.id" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" (change)="$event ? selecttoggle() : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <th mat-header-cell *matHeaderCellDef [style.width]="column.width">
      <span *ngIf="column.columnDef !=='expandIcon'"> {{column.header}}</span>
    </th>



    <td [ngClass]="evaluateFunction(column, element)" mat-cell *matCellDef="let element" [style.width]="column.width">

      <!-- <span title="view" (click)="navigateToViewGroup(element);">
      <mat-icon class="uitk-icon uitk-icon-view sm-icon cancel-screen-icon icon-curd"></mat-icon>
     </span>  -->

      <div [ngSwitch]="dataSchema[column.columnDef]">

        <div *ngSwitchCase="'isEdit'" style="margin-left:-20px;">
          <button mat-icon-button style="margin-left: 10px;">
            <span title="view" (click)="navigateToViewApplications(element);">
              <mat-icon class="uitk-icon uitk-icon-view sm-icon cancel-screen-icon icon-curd"></mat-icon>
            </span>
          </button>

          <button mat-icon-button style="margin-left: 10px;"
            *ngIf=" expandedElement1 !== element && !element.addNewRow "
            (click)="expandedElement1 = expandedElement1 === element ? null : element ; expandedElement=null;dataConvertFuturePadu(element);resetSelections()"
            [disabled]="disableOtherIcon">
            <mat-icon style="cursor: pointer;">
              add_circle_outline</mat-icon>
          </button>
          <button mat-icon-button style="margin-left: 10px;"
            *ngIf=" expandedElement1 === element  && !element.addNewRow "
            (click)="expandedElement1 = expandedElement1 === element ? null : element ; expandedElement=null ;resetSelections();resetCapabilitySelections() "
            [disabled]="disableOtherIcon">
            <mat-icon>
              remove_circle_outline</mat-icon>
          </button>

          <button *ngIf="sharedService.isEnabled('Capability_Pr', selectedLOB, selectedSUBLOB) && !isDecommissionedApp"
            [class.disableEdit]="isDecommissionedApp" mat-icon-button [disabled]="disableOtherIcon"
            style="margin-left: 10px;"
            (click)="loadDropdown(element);element.isEdit = !element.isEdit; expandedElement = null;  expandedElement1 = expandedElement1 === element ? element : element ;">
            <mat-icon style="font-size: 1.1rem; cursor: pointer; ">
              create
            </mat-icon>
          </button>
          <!-- <mat-icon style="font-size: 1.1rem; cursor: pointer; color: lightgrey; "
            >
            createTwo
          </mat-icon>  -->
        </div>
        <span *ngSwitchDefault>
          <span *ngIf="column.type==='image'">

            <img href="" class="image-icon center-image" src="{{column.cell(element)}}" (click)="changeStar(element)" />
          </span>
          <span [ngClass]="evaluateSpanSpecificClass(column, element)" *ngIf="column.type!=='image'">
            <span *ngIf="column.columnDef==='capability'">
              <img class="image-icon" src="/assets/appHub/Rectangle Copy 20@2x.svg"
                matTooltip="{{getCapabiltyInfo(element)}}" matTooltipClass="my-custom-tooltip"
                matTooltipPosition="above" aria-hidden="false" aria-label="information  icon" />
            </span>
            {{column.cell(element)}}</span>

        </span>

      </div>


      <div [ngSwitch]="dataSchema[column.columnDef]" *ngIf="element.isEdit;">
        <div class="btn-edit" *ngSwitchCase="'isEdit'">

          <img class="save-icon" src="../../../../assets/appHub/save.svg"
            (click)="saveExpandTableData(element);expandedElement1 = expandedElement1 === element ? null : element ; expandedElement=null ; "
            title="save" />
          <img *ngIf="!element.addNewRow" class="edit-padu" src="../../../../assets/appHub/close.svg" title="undo"
            (click)="element.isEdit = !element.isEdit ;  expandedElement1 = expandedElement1 === element ? null : element ; iseditExpand = !iseditExpand;disableOtherIcon= false " />
          <img *ngIf="element.addNewRow" class="edit-padu" src="../../../../assets/appHub/close.svg"
            title="Remove add new row" (click)="removeRow();disableOtherIcon= false " />
        </div>

        <mat-form-field [floatLabel]="'never'" *ngSwitchCase="'model-select'" class="custom-dropdown">
          <span class="text-notavailable-option option-size"
            *ngIf=" !dropdownData.model.includes(selectedModel)">{{selectedModel}}</span>
          <mat-select [placeholder]="selectedModel? '': 'Select the Group Model'"
            (selectionChange)="getNewMappedCapabilityList()" [(ngModel)]="selectedModel">

            <mat-option *ngFor="let model of dropdownData.model" [value]="model">
              <ng-container *ngIf="!excludeModel.includes(model)">
                {{model}}
              </ng-container>
            </mat-option>

          </mat-select>
        </mat-form-field>

        <mat-form-field [floatLabel]="'never'" *ngSwitchCase="'capability-select'" class="custom-dropdown">
          <input *ngIf="selectedCapability1 && !element.addNewRow" style="width: 92%;
          height: 100%;
          font-size: 1.2em;
          position: absolute;
          top: 0px;
          background: transparent;
          border: none;" disabled [value]="selectedCapability1" />
          <input *ngIf="multipleSelectedCapability && element.addNewRow" style="width: 92%;
           height: 100%;
           font-size: 1.2em;
           position: absolute;
           top: 0px;
           background: transparent;
           border: none;" disabled [value]="multipleSelectedCapability" />
          <mat-select [disabled]="!selectedModel || !selectedOrg"
            [placeholder]="placeholderValue(element)? '': 'Select the capability'" #mySelect disableRipple>
            <!-- <input matinput type="text" class="search-option" (keyup)="searchCapabilityByName($event)" (keydown)="$event.stopPropagation()"  placeholder="Search"  > -->

            <mat-option style="height: auto !important; overflow: auto;">
              <app-tree-checkbox [multiple]="element.addNewRow" [List]="mappedCapability "
                (multipleSelectedData)="multipleList($event)"
                (selectedTreeData)="getSelectedCapbilityFromDropdown($event)"
                [selectedCapability]="selectedCapability1"></app-tree-checkbox>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span *ngSwitchCase="'padu-select'" class="add-padding" [ngClass]="{
        'badge-padu-p': currentPADU === 'Preferred',
        'badge-padu-a': currentPADU === 'Acceptable',
        'badge-padu-d':currentPADU === 'Discouraged',
        'badge-padu-u':currentPADU === 'Unacceptable',
        'badge-padu-r': currentPADU === 'Retired',
        'badge-padu-i': currentPADU=== 'Infrastructure',
        'badge-padu-pp': currentPADU === 'PADU Pending'}">
          <span class="text-notavailable-option"
            *ngIf="currentPADU=== 'Infrastructure' || currentPADU=== 'PADU Pending'">{{currentPADU}}</span>
          <mat-select [floatLabel]="'never'" [placeholder]="currentPADU? '' : 'PADU Status'"
            [ngClass]="!currentPADU? 'custom-dropdown padu-dropdown': 'mat-select-padu'" [(ngModel)]="currentPADU">
            <mat-option *ngFor="let option of paduOptions" [value]="option.value">
              <span class="add-padding" [ngClass]="{
         'badge-padu-p': option.value === 'Preferred',
         'badge-padu-a':option.value === 'Acceptable',
         'badge-padu-d':option.value === 'Discouraged',
         'badge-padu-u': option.value === 'Unacceptable',
         'badge-padu-r': option.value === 'Retired',
         'badge-padu-i': option.value === 'I',
         'badge-padu-pp': option.value === 'PP',
         'badge-padu-tbr': option.value === 'TobeRetired'
        }"> {{ option.label}}</span>
            </mat-option>
          </mat-select>
        </span>
        <mat-form-field [floatLabel]="'never'" *ngSwitchCase="'select'" placeholder="Select" class="custom-dropdown">
          <span class="text-notavailable-option option-size"
            *ngIf="!dropdownData.organisation.includes(selectedOrg)">{{selectedOrg}}</span>
          <mat-select [placeholder]="selectedOrg? '': 'Select the organization'"
            (selectionChange)="getNewMappedCapabilityList()" [(ngModel)]="selectedOrg">

            <input matinput type="text" class="search-option" (keyup)="searchOrganizationByName($event)"
              (keydown)="$event.stopPropagation()" placeholder="Search">

            <mat-option *ngFor="let org of dropdownData.organisation | listFilter:searchOrganization; " [value]="org">
              {{org}}</mat-option>

          </mat-select>
        </mat-form-field>

      </div>

      <!-- <button mat-icon-button
        (click)="expandedElement = expandedElement === element ? null : element ; expandedElement1=null;getCurrentCapabilityAppList(0)"
        *ngIf="column.columnDef=='expandIcon' && expandedElement !== element && !element.addNewRow"
        [disabled]="disableOtherIcon">
        <mat-icon style="cursor: pointer;">
          keyboard_arrow_down</mat-icon>
      </button>
      <button mat-icon-button
        (click)="expandedElement = expandedElement === element ? null : element ; expandedElement1=null"
        *ngIf="column.columnDef=='expandIcon' && expandedElement === element  && !element.addNewRow"
        [disabled]="disableOtherIcon">
        <mat-icon style="cursor: pointer;">
          keyboard_arrow_up</mat-icon>
      </button> -->

      <!-- <button mat-icon-button style="margin-left: 10px;"
        *ngIf="column.columnDef=='future_padu' && expandedElement1 !== element && !element.addNewRow "
        (click)="test(element)"
        [disabled]="disableOtherIcon">
        <span title="view" (click)="navigateToViewGroup(element);">
          <mat-icon class="uitk-icon uitk-icon-view sm-icon cancel-screen-icon icon-curd"></mat-icon>
      </span> 
      </button>

      <button mat-icon-button style="margin-left: 10px;"
        *ngIf="column.columnDef=='future_padu' && expandedElement1 !== element && !element.addNewRow "
        (click)="expandedElement1 = expandedElement1 === element ? null : element ; expandedElement=null;dataConvertFuturePadu(element)"
        [disabled]="disableOtherIcon">
        <mat-icon style="cursor: pointer;">
          add_circle_outline</mat-icon>
      </button>
      <button mat-icon-button
        *ngIf="column.columnDef=='future_padu' && expandedElement1 === element  && !element.addNewRow "
        (click)="expandedElement1 = expandedElement1 === element ? null : element ; expandedElement=null ;"
        [disabled]="disableOtherIcon">
        <mat-icon>
          remove_circle_outline</mat-icon>
      </button> -->
    </td>

    <!-- <td [ngClass]="evaluateFunction(column, element)"  mat-cell *matCellDef="let element" [style.width]="column.width">
      
       </td> -->
  </ng-container>

  <ng-container matColumnDef="expandedDetail">

    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="example-element-detail"
        [@detailExpand]="(element==expandedElement || this.expandedElementObj) ? 'expanded': 'collapsed'">
        <ng-container *ngIf="showLoader">
          <div class="row loader-container applist-loader-container">
            <app-loader style="stroke:  #002677 !important;"></app-loader>
          </div>
        </ng-container>
        <div *ngIf="!showLoader" class="example-element-description"
          style="width: 100%;background-color: white; padding: 2% 2% 3% 2%; border-left:4px solid #013B71">
          <!-- {{element.description}} -->
          <span class="example-element-description-attribution">


            <app-data-table [expandTable]="{class: 'expandedtableview'}" [dataSource]="childDataSource"
              [tableConfig]="applistTableConfig" [searchConfig]="childTableSearchConfig"
              [columns]="expandedTablecolumns" [pageSize]="currentApplistPageSize" [pageIndex]="currentApplistPageIndex"
              (paginationClickEvent)="onApplistPageChange($event)"></app-data-table>



          </span>
        </div>
      </div>
    </td>

  </ng-container>


  <ng-container matColumnDef="paduexpandedDetail">

    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="example-element-detail"
        [@detailExpand]="(element==expandedElement1 || element.addNewRow)  ? 'expanded': 'collapsed'">
        <div class="example-element-description"
          style="width: 100% !important; padding: 1% 2% 3% 2%; border-left:4px solid #013B71; margin-bottom: 1%;">
          {{element.description}}
          <ng-container>
            <mat-dialog-content>
              <app-edit-overlay *ngIf=" element.isEdit && !editClickLoader" [iseditExpand]="iseditExpand"
                [editExpandDataSource]="editExpandDataSource" [editExpandConfig]="futePaduExpandedTablecolumns">
              </app-edit-overlay>
            </mat-dialog-content>
            <div class="row loader-container applist-loader-container">
              <app-loader *ngIf="element.isEdit && editClickLoader" style="stroke:  #002677 !important;"></app-loader>
            </div>


          </ng-container>
          <ng-container>
            <app-data-table *ngIf="!element.isEdit && !paduClickLoader" [SelectAll]="true" [displaySelectAll]="false"
              [dataSource]="futurePaduDataSource" (cellClick)="onOrganisationClick($event)"
              [expandTable]="{class: 'expandedtableview'}" (onCheckClick)="checkboxClick($event) "
              [columns]="associatedCapExpandedTablecolumns" [capabilitySelected]="isCapabilitySelected">
              >
            </app-data-table>
            <div class="row loader-container applist-loader-container">
              <app-loader *ngIf="!element.isEdit && paduClickLoader" style="stroke:  #002677 !important;"></app-loader>
            </div>
          </ng-container>

        </div>
      </div>
    </td>

  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.student-expanded-row]="element.isExpanded"
    class="example-element-row"></tr>

  <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>
  <tr mat-row *matRowDef="let row; columns: ['paduexpandedDetail'];" class="example-detail-row"></tr>



</table>

<ng-container *ngIf="dataSource.data.length <= 0">
  <app-data-unavailable></app-data-unavailable>
</ng-container>

<ng-container *ngIf="pageSize.length > 0">
  <mat-paginator (page)="pageChange($event)" #pagination [pageSize]="pageSize[0]" [hidePageSize]="!pageSize[1]"
    [pageSizeOptions]="pageSize" showFirstLastButtons></mat-paginator>
</ng-container>
