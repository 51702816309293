import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BulkImportService } from '../../services/bulk-import.service';

@Component({
  selector: 'app-model-maintenance',
  templateUrl: './model-maintenance.component.html',
  styleUrls: ['./model-maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelMaintenanceComponent implements OnInit {

  modalTitle: string;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  fileAttr = 'Choose File';
  SaveForm: FormGroup;
  fileToUpload: File = null;
  showLoader: boolean = false;
  Filepath: any;
  userAccess: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toaster: ToastrService,
    public dialog: MatDialogRef<ModelMaintenanceComponent>,
    private formBuilder: FormBuilder,
    private importService: BulkImportService
  ) {

  }

  ngOnInit() {
    console.log(this.data);
    if (this.data && this.data.title) {
      this.modalTitle = this.data.title
      this.Filepath = this.data.Filepath
      this.userAccess = this.data.userAccess
    }

    this.SaveForm = this.formBuilder.group({
      file: ['', [Validators.required]],
      // adminComments: ['', []],
    });
  }


  closePopup() {
    this.dialog.close();
  }

  uploadFileEvt(files: FileList) {
    if (files && files.length) {
      this.fileToUpload = files.item(0);
    } else {
      this.fileToUpload = null;
    }
  }

  submitForm() {
    let fileType = this.fileToUpload.type;
    let fileExtension = this.fileToUpload.name.split('.').pop();
    if (!fileType ||
      !["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(fileType)
      || !['xls', 'xlsx', 'xlsm', 'xlsb'].includes(fileExtension)
    ) {
      this.toaster.error("File format is not supported, Please try with excel files only.")
      return false;
    }
    let formData: FormData = new FormData();
    formData.append('cmi_file', this.fileToUpload, this.fileToUpload.name);
    formData.append('Model_Name', this.data.groupModel)
    formData.append('User_email', localStorage.getItem('loggedInUserEmail'));
    formData.append('Action_Type', this.data.Action_Type);
    formData.append('User_Access', this.data.userAccess.Role);
    formData.append('lobId', this.data.userAccess.lobId);
    formData.append('subLobId', this.data.userAccess.subLobId);
    this.showLoader = true;
    this.importService.ImportFile(formData, null).subscribe(() => {
      this.closePopup();
      this.showLoader = false;
      this.toaster.success("Thank you for submitting the request. You will be notified via email on status", '', { positionClass: 'toast-top-center', timeOut: 2000, })
    }, () => {
      this.closePopup();
      this.showLoader = false;
      this.toaster.error("Something went wrong, Please try again later.", '', { positionClass: 'toast-top-center', timeOut: 1000, })
    });
  }

}
