import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppHubCacheService {
  private cacheObject: any = {};
  private staticCacheObject: any = {};
  constructor() { }

  setCache(url: string, payLoad: any, forceCache: boolean = false) {
    if (forceCache) {
      this.staticCacheObject[url] = JSON.parse(JSON.stringify(payLoad));
      return;
    }
    const cacheKeys = Object.keys(this.cacheObject);
    if (cacheKeys.length > 20) {
      delete this.cacheObject[cacheKeys[0]];
    }

    if (cacheKeys.length < 20) {
    this.cacheObject[url] = JSON.parse(JSON.stringify(payLoad));
    }
  }

  getCache(url: string) {
    return this.cacheObject[url];
  }

  getStaticCache(url: string) {
    return this.staticCacheObject[url];
  }
}
