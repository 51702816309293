<div>
  <footer class="uitk-c-footer footer-height" *ngIf="isLoggedIn && !path" >


    <div class="footerInside" style="padding-left: 5% ;">
      <div id="footerContent" class="noindex">
        <div class="footerContent">
          <div class="row" style="margin-bottom: 25%;">
            <div class="fourcol">
              <img src="../../../../assets/images/uhcsmall-logo.svg" />
            </div>
  
          </div>
          <div class="row">
            <div>©2021 UnitedHealth Group. All rights reserved.
            </div>
          </div>
        </div>
  
        <div class="footerSubContent">
          <div style="margin: 2% 6% 2% 2%; font-size: 13px;" class="footer-column">
  
            <div >
              <div style="color: white; padding-bottom: 15px; font-size: 15px;">Releases</div>
              <a class="footerLink" (click)="redirectToRelease()"  style="padding-bottom: 8px;text-decoration: none; color: #fff; cursor: pointer;">Release notes</a><br/>
             
            </div>
            <!-- <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
                <span style="color: white;">Contact Us</span>
              </div> -->
  
  
  
          </div>
          <div style="margin: 2% 6% 2% 2%; font-size: 13px;" class="footer-column">
  
            <div >
              <div style="color: white; padding-bottom: 15px; font-size: 15px;">Join us</div>
              <a class="footerLink" href= "https://teams.microsoft.com/l/channel/19%3ahyqBUCKo9V2xNwyKCXa2hIUNGPgh24c1lsC4AjQiX2o1%40thread.tacv2/General?groupId=2ddaff3a-b209-451a-8405-5c0530c43e91&tenantId=db05faca-c82a-4b9d-b9c5-0f64b6755421" target="_blank" style="padding-bottom: 8px;text-decoration: none; color: #fff;">Teams</a><br/>
              <a class="footerLink" href="https://github.optum.com/UHC-Tech-Ops-Hub/UHCTechOps" style="padding-bottom: 8px; text-decoration: none; color: #fff;"target="_blank">Github</a>
            </div>
            <!-- <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
                <span style="color: white;">Contact Us</span>
              </div> -->
  
  
  
          </div>
  
          <div style="margin: 2%; font-size: 13px;" class="footer-column">
  
            <div >
              <div style="color: white; padding-bottom: 15px; font-size: 15px;">Contact us</div>
              <a class="footerLink"  href={{hrefShareFeedback}} target="_blank" rel="noopener noreferrer" style="padding-bottom: 8px;text-decoration: none; color: #fff;">Share Feedback</a><br/>
              <a class="footerLink" href={{hrefLink}} target="_blank" rel="noopener noreferrer" style="padding-bottom: 8px;text-decoration: none; color: #fff;">Need help?</a>
            </div>
            <!-- <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
                <span style="color: white;">Contact Us</span>
              </div> -->
  
  
  
          </div>
        </div>
        <!-- <div class="span6" style="margin-top: 4%;margin-right: 7%;">
            <a class="sub-content-item">Support</a>
            <a class="sub-content-item" href={{hrefLink}}>Help</a>
            <a class="sub-content-item" href={{hrefLink}}>Contact Us</a>
            <a class="sub-content-item" href={{hrefShareFeedback}}>Share Feedback</a>
           
          </div> -->
      </div>
    </div>
   
  
  </footer>
  
  
  <footer class="footer-container" *ngIf="isLoggedIn && path">
  
    <div class="footerUHCInside">
      <div class="footerGrid">
        <div class="footerTitle" style="margin-top: -12px;">
          <img src="../../../../assets/images/Brand lock-up.svg" style="height: 54px;">
        </div>
      </div>
  
      <div class="footerGrid">
        <p style="font-size: 17px; font-family: 'UHC-Sans-Medium';">Support</p>
        <a class="footerLink" href={{hrefLink}}><span class="uitk-icon uitk-icon-help_filled md-icon"
            style="color: white;font-size:12px;"></span> Help & Contact Us</a>
        <br>
        <a class="footerLink" href={{hrefShareFeedback}}><span style="color: white; font-size:12px;"
            class="uitk-icon uitk-icon-chat md-icon"></span> Share Feedback</a><br>
        <!-- <a class="footerLink" href={{hrefLink}}><span style="color: white; font-size:12px;" class="uitk-icon uitk-icon-email md-icon"></span> E-mail: uhctechopshub@optum.com</a> -->
        <br>
      </div>
    </div>
    <div class="row" style="display: flex; flex-direction: row; margin-left: 8%; margin-right: 8%;">
      <span style="font-size: 10px; color: white; padding-top: 7px; padding-right: 7px; font-family: 'UHC-Sans-Medium';">©
        2021 United HealthCare Services
        Inc.</span>
    </div>
  </footer>
</div>