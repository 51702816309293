import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { DigitFormatterPipe } from 'src/app/shared/pipe/digit-formatter.pipe';
import { ThousandSuffixesPipe } from 'src/app/shared/pipe/thousand-suffixes.pipe';
import * as d3 from 'd3';

@Component({
  selector: 'app-donut-chart-1',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css'],
  providers: [ThousandSuffixesPipe]
})
export class DonutChartComponent implements OnInit {
  @ViewChild('chart', { static: true, read: ElementRef }) private chartContainer: ElementRef;
  @Input() public title;
  @Input() public titleMiddle = 'TCO';
  @Input() public type = 'PieChart';
  @Input() public chartData;
  @Input() public columnNames;
  @Input() public options;
  @Input() public total;
  @Input() public selectedYear;
  @Output() chartReady = new EventEmitter<boolean>();
  @Input() height = 200;
  @Input() width = 300;
  @Input() showCurrancy :boolean = true;
  @Input() showMiddleTitle: boolean = true;
  @Input() showChartTitle : boolean = false;
  @Input() pieLabelFont = 10;
  @Input() customStyle='chart-title';
  totalCount;
  public amountTotal: any = 0;
  public chartLoaded: boolean = false;
  tooltip: any;
  label1;
  arcGenerator;
  constructor(private digitFormatter: DigitFormatterPipe, private container: ElementRef) {

  }
  ngOnChanges() {
    if(this.total)
    {let value = parseFloat(this.total.replace(/,/g, ''));
    this.amountTotal = this.digitFormatter.transform(value, 1);
    }
   if(this.chartData) this.drawPieChart();
  }
  ngOnInit() {

  }

 

  onReady() {
    this.chartReady.next(true);
    this.chartLoaded = true;
    if (this.options.alignTitle && !this.options.titlePosition) {
      const abc = this.chartContainer.nativeElement;
      // TO ACCOMODATE MORE THAN 3 DONUT CHARTS IN A SINGLE CARD
      const node = abc.querySelector('text[x="81"]');
      if (node) {
        node.setAttribute('x', 10);
        node.setAttribute('y', 24);
        node.setAttribute('font-family', 'Arial Bold');
      }
    }
    if (this.options.legend && this.options.legend !== 'none' && this.options.legend !== 'right') {
      this.chartData.forEach(element => {
        const node = this.chartContainer.nativeElement.querySelector(`[column-id="${element[0]}"]`);
        if (node) {
          node.querySelector('text').setAttribute('x', 71);
          node.querySelector('circle').setAttribute('cx', 72);
        }
      });
    }
    this.totalCount = this.chartData.map(el => el[1]).reduce((curr, aggr) => curr + aggr, 0);
    this.modifyInnertext();
    const node = this.chartContainer.nativeElement.querySelectorAll('svg text');
    if (!this.chartData.length && node.length > 1) {
      const noData = node[1];
      noData.innerHTML = 'In Development';
      noData.setAttribute('font-size', '12');
    } else {
      const path = this.chartContainer.nativeElement.querySelectorAll('path');
      if (!path.length) {
        const noData = this.chartContainer.nativeElement.querySelector('svg');
        noData.innerHTML = `<text text-anchor="middle" x="150" y="102.8"
        font-family="Arial" font-size="12" stroke="none" stroke-width="0" fill="#000000">No data available</text>`;
      }
    }
  }

  private modifyInnertext() {
    const path = this.chartContainer.nativeElement.querySelectorAll('path');
    if (this.options.formatLabelText && typeof this.options.formatLabelText === 'function') {
      if (path.length > 0) {
        path.forEach(row => {
          // && row.nextSibling.innerHTML.indexOf('%') > 0
          if (row.nextSibling) {
            // let percentage = row.nextSibling.innerHTML;
            // percentage = +percentage.substr(0, percentage.length - 1);
            // const value = (percentage * this.totalCount) / 100;
            // const newVal = this.options.formatLabelText(value.toLocaleString());
            let value = parseFloat(row.nextSibling.innerHTML.replace(/,/g, ''));
            row.nextSibling.innerHTML = this.digitFormatter.transform(value, 1);
            row.nextSibling.setAttribute('x', +row.nextSibling.getAttribute('x') - 5);
            // row.nextSibling.innerHTML = newVal;
          }
        });
      }
    }

    if (path.length === 1) {
      path.forEach(row => {
        if (row.nextSibling) {
          row.nextSibling.setAttribute('x', 167);
          row.nextSibling.setAttribute('y', 145);
        }
      });
    }
  }

  appendValue() {
    const node = this.chartContainer.nativeElement.querySelectorAll('svg text');
    if (node.length === 1) {
      if (node[0].innerHTML.indexOf('%') == -1) {
        let value = parseFloat(node[0].innerHTML.replace(/,/g, ''));
        node[0].innerHTML = this.digitFormatter.transform(value, 1);
        node[0].setAttribute('x', 170);
        node[0].setAttribute('y', 145);
      }
    } else {
      node.forEach(item => {
        if (item.innerHTML.indexOf('%') == -1) {
          let value = parseFloat(item.innerHTML.replace(/,/g, ''));
          item.innerHTML = this.digitFormatter.transform(value, 1);
        }
      });
    }

  }

  drawPieChart() {
 // ----------------
    // Create a tooltip
    // ----------------
    // this.tooltip =  d3.select(this.container.nativeElement).select(".donut-chart")
    // .style("left", d3.event.pageX + "px")
    // .style("top", d3.event.pageY + "px")
    // .style("opacity", 1) 
    // .append("div")
    // .style("opacity", 0)
    // .attr("font", ".9vw sans-serif")
    // .attr("font-weight", "bold")
    // .attr("class", "stacktooltip")
    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    const radius = 55;
    this.container.nativeElement.querySelectorAll('.pie_div svg').forEach(function (elem) {
      elem.parentNode.removeChild(elem);
    });
    // append the svg object to the div called 'my_dataviz'
    const svg = d3.select(this.container.nativeElement.querySelector('.pie_div'))
      .append("svg")
      .attr('preserveAspectRatio', 'xMidYMid meet')
      // .attr('height',function(d){ 
      //   if (this.titleMiddle === "TCO"){
      //     return (this.height) 
      //   } 
      //   return null;
      // })
      // .attr('width',function(d){ 
      //   if (this.titleMiddle === "TCO"){
      //     return (this.width) 
      //   } 
      //   return null;
      // })
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .append("g")
      .attr("transform", `translate(${this.width / 2},${this.height / 2})`)



    // Create dummy data
    let data = {}
    let sliceColors = [];
    let titles = [];
    this.chartData.filter(slice => {
      if (slice.value != 0) {
        data[slice.label] = slice.value;
        sliceColors.push(slice.color)
        titles.push(this.digitFormatter.transform(slice.value, 1));
      }

    })


    if (this.amountTotal == 0) {
      titles.push(this.digitFormatter.transform(0, 1));
      sliceColors.push("#F3F3F3");
      data['no_data'] = 1;
    }

    // set the color scale
    const color = d3.scaleOrdinal()
      .range(sliceColors)

    // Compute the position of each group on the pie:
    const pie = d3.pie()
      .value(d => d[1])

    const data_ready = pie(Object.entries(data))

    let arc = d3.arc()
      .innerRadius(100)         // This is the size of the donut hole
      .outerRadius(radius);

    this.arcGenerator = d3.arc()
      .innerRadius(0)
      .outerRadius(radius)
    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('whatever')
      .data(data_ready)
      .join('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data[0])).on("mouseover",(event)=> {
        let filterdata=[];
        let html =  '';       
        filterdata = this.chartData.filter(item=>{
            return (item['label']==event['data'][0]);
        });
        if(filterdata.length>0){
          html = filterdata[0].tooltip;
        }
       d3.select(this.container.nativeElement).select(".donutcharttooltip")
        .style("left", d3.event.offsetX+ "px")
        .style("top", d3.event.offsetY + "px")
       .style("opacity", 1)       
        .html(html)
      })
      .on("mousemove", (event) => {
        let filterdata=[];
        let html =  '';       
        filterdata = this.chartData.filter(item=>{
            return (item['label']==event['data'][0]);
        });
        if(filterdata.length>0){
          html = filterdata[0].tooltip;
        }
       d3.select(this.container.nativeElement).select(".donutcharttooltip")
        .style("left", d3.event.offsetX+ "px")
        .style("top", d3.event.offsetY + "px")
       .style("opacity", 1)       
        .html(html)
      })
      .on("mouseout",()=> {
        setTimeout( () => {
          d3.select(this.container.nativeElement).select(".donutcharttooltip")
           .style("opacity", 0)
        },1000)
        // d3.select(this.container.nativeElement).select(".donutcharttooltip")
        // .style("opacity", 0)
      });
    // .attr("stroke", "black")
    // .style("stroke-width", "1px")

    svg
      .selectAll('whatever')
      .data(data_ready)
      .enter()
      .append('text')
      .text(function (d, i) { 
        return  (d.endAngle - d.startAngle) > 0.35? titles[i] : ''
      })
      .attr("transform", function (d) { 
        return "translate(" + arc.centroid(d) + ")"; 
      })
      .style("text-anchor", "middle")
      .style("font-size", this.pieLabelFont)
      .style('fill', '#FFFFFF').on("mouseover",()=> {
        // let html =  this.chartData[d].tooltip;        
        // d3.select(this.container.nativeElement).select(".donutcharttooltip")
        // .style("left", 50 + "px")
        // .style("top", 50 + "px")
        // .style("opacity", 1)       
        //  .html(html)
       })
       .on("mousemove", function() {
       
       })
       .on("mouseout", ()=> {
        //   d3.select(this.container.nativeElement).select(".donutcharttooltip")
        //  .style("opacity", 0)
       });

      if(this.showMiddleTitle) {
         this.label1 = svg.append("text")
        .attr("text-anchor", "middle")
        .text(this.titleMiddle + " " + this.selectedYear)
        .style("font-size", 13)
        .style("font-weight", 500);  
      }
    
    // console.log(label1.attr("transform"));
    // console.log(d3.transform(label1.attr("transform")).translate[0])
    svg.append("text")
      .attr("text-anchor", "middle")
      .text( (this.showCurrancy ? '$' : '') + this.amountTotal)
      .style("font-size", this.showMiddleTitle ? 14: 16)
      .style("font-weight", 500)
      .attr('y', this.showMiddleTitle ? 15 : 5)
      // .selectAll("tspan")
      // .data(d => {
      //   console.log(d)
      //   // const lines = `${title(d.data)}`.split(/\n/);
      //   // return (d.endAngle - d.startAngle) > 0.25 ? lines : lines.slice(0, 1);
      // })

    this.chartReady.next(true);

  }
}
