<ng-container *ngIf="!showLoader">
  <ng-container *ngIf="finalData.length">
    <ng-container *ngIf="pageOfItems">
      <mat-accordion *ngFor="let ob of pageOfItems">

        <mat-expansion-panel class="panel-container">
          <mat-expansion-panel-header class="title-modernization">
            <mat-panel-title>
              {{ob.name}}

            </mat-panel-title>
            <mat-panel-description style=" display: flex; justify-content: flex-end;">
              <span  class="uitk-icon uitk-icon-edit" style="font-size: 16px; margin-top: 5px;"
                (click)="editView(ob.basicData.data)"></span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-top: 10px; margin-bottom: 10px;">

              <!-- <div class="alert alert-secondary heading" role="alert">
            Application Details <span class="uitk-icon uitk-icon-info_hollow health" style="font-size: 16px; "
            matTooltip="Application level information"
            matTooltipPosition="right" matTooltipClass="custom-tooltip"  aria-hidden="false" aria-label="information  icon">
            </span>
          </div> -->
              <div class="sub-heading">
                <h6>Application Details <span class="uitk-icon uitk-icon-info_hollow health" style="font-size: 16px; "
                    matTooltip="Application level information" matTooltipPosition="right"
                    matTooltipClass="custom-tooltip" aria-hidden="false" aria-label="information  icon">
                  </span></h6>
              </div>


            </div>

            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
              <app-data-table [dataSource]="ob.basicData" [columns]="basicColumns"></app-data-table>
            </div>
            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
              <!-- <div class="alert alert-secondary heading" role="alert">
            Roadmap Details <span class="uitk-icon uitk-icon-info_hollow health" style="font-size: 16px; "
            matTooltip="Roadmap detail information"
            matTooltipPosition="right" matTooltipClass="custom-tooltip"  aria-hidden="false" aria-label="information  icon">
            </span>
          </div> -->

              <div class="sub-heading">
                <h6> Owner Details <span class="uitk-icon uitk-icon-info_hollow health" style="font-size: 16px; "
                    matTooltip="Roadmap detail information" matTooltipPosition="right" matTooltipClass="custom-tooltip"
                    aria-hidden="false" aria-label="information  icon">
                  </span></h6>
              </div>

            </div>

            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
              <app-data-table [dataSource]="ob.basicData" [columns]="ownerDetails"></app-data-table>
            </div>
            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
              <!-- <div class="alert alert-secondary heading" role="alert">
            Roadmap Details <span class="uitk-icon uitk-icon-info_hollow health" style="font-size: 16px; "
            matTooltip="Roadmap detail information"
            matTooltipPosition="right" matTooltipClass="custom-tooltip"  aria-hidden="false" aria-label="information  icon">
            </span>
          </div> -->

              <div class="sub-heading">
                <h6> Roadmap Details <span class="uitk-icon uitk-icon-info_hollow health" style="font-size: 16px; "
                    matTooltip="Roadmap detail information" matTooltipPosition="right" matTooltipClass="custom-tooltip"
                    aria-hidden="false" aria-label="information  icon">
                  </span></h6>
              </div>

            </div>

            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
              <app-data-table [dataSource]="ob.modernizationData" [columns]="modernizationColumns"></app-data-table>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>


    </ng-container>

    <ng-container *ngIf="!pageOfItems">
      <div class="row loader-container">
        <app-loader></app-loader>
      </div>
    </ng-container>
    <app-table-pagination [items]="finalData" [pageSize]="pageSize" (changePage)="onChangePage($event)">
    </app-table-pagination>
  </ng-container>

  <ng-container *ngIf="!finalData.length">
    <app-data-unavailable></app-data-unavailable>
  </ng-container>
</ng-container>

<div *ngIf="showLoader" class="row loader-container">
  <app-loader></app-loader>
</div>
<!-- <div class="row">
      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
          <label style="display: flex; justify-content: center; color: #C25608;">1099 Detail Reporting</label>
      </div>

      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
        <app-data-table [dataSource]="tableData" [columns]="tableColumn"></app-data-table>
      </div>

      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
        <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs" color="primary">Modernization Data</button>

      </div>

      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="margin-bottom: 10px;">
        <app-data-table [dataSource]="modData" [columns]="modColumn"></app-data-table>
      </div>
  </div> -->