import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-head',
  templateUrl: './session-head.component.html',
  styleUrls: ['./session-head.component.less'],
})
export class SessionHeadComponent implements OnInit {
  ngOnInit() {
    // if (navigator.userAgent.search('MSIE') >= 0) {
      // document.write('"MS Internet Explorer ');
      // const position = navigator.userAgent.search('MSIE') + 5;
      // const end = navigator.userAgent.search('; Windows');
      // const version = navigator.userAgent.substring(position, end);
      // document.write(version + '"');
    // } else if (navigator.userAgent.search('Chrome') >= 0) {
      //  alert("entered into chrome block");
    // } else if (navigator.userAgent.search('Firefox') >= 0) {
      //   //document.write('"Mozilla Firefox ');
      // const position = navigator.userAgent.search('Firefox') + 8;
      // const version = navigator.userAgent.substring(position);
      //    //document.write(version + '"');
    // } else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {// << Here
      // document.write('"Apple Safari ');
      // const position = navigator.userAgent.search('Version') + 8;
      // const end = navigator.userAgent.search(' Safari');
      // const version = navigator.userAgent.substring(position, end);
      // document.write(version + '"');
    // } else if (navigator.userAgent.search('Opera') >= 0) {
      // document.write('"Opera ');
      // const position = navigator.userAgent.search('Version') + 8;
      // const version = navigator.userAgent.substring(position);
      // document.write(version + '"');
    // } else {
      //  alert("entered into ie block");
    //   const elements = document.getElementById('sessionBanner');
    //   elements.style.display = 'block';

    // }

    //   document.getElementsByClassName('internetBanner');
  }

}
