<div class="productSuiteEdit">
    <div class="popup-header" mat-dialog-title>
        <span class="productSuit-Edit-Header addcircle">Product Suites detail</span>
    
        <div (click)="closePopup()" class="back">
            <mat-icon class="cancel-icon">cancel</mat-icon>
        </div>
    </div>
    <div mat-dialog-content>
    
        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="display: flex;;flex-direction: column;">
                <p class="tabl-header-font" style="margin-top: 2%;font-weight: bold; color: #013B71; ">Select Product suite to edit the existing data or click on <a style="text-decoration: underline; font-style: italic; cursor: pointer;" (click)="addNewProductSuite()"> Add New Product Suite</a>: </p>
                <div class="product-suite-fields">
                    <mat-form-field class="custom-dropdown product-suites-dropdown">
                        <mat-select (selectionChange)="fetchSpecificSuiteDetails()" placeholder="Select Product suite"
                            [(ngModel)]="selectedProductSuite">
                            <mat-option *ngFor="let suite of productSuiteList" [value]="suite">{{suite.productSuite}}
                                <!-- <app-tree-checkbox [multiple]="false" [List]="TreeCheckboxDataSource"
                                >
                              </app-tree-checkbox> -->
                            </mat-option>
                        </mat-select>
                        
                    </mat-form-field>
                    
                    <button class="m-1 ml-4 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" *ngIf="hierarchialData && hierarchialData.length && hierarchialData[0].id"
                    (click)="deleteProductSuite()">Delete</button>
                   
                </div>
            <!-- <div class="button-position">
                <button mat-raised-button class="addbuttonClass  uitk-c-button uitk-c-button--default 
                uitk-u-margin-right--base uitk-u-margin-bottom--xs" >Add</button>
            </div> -->
        </div><br/>
    
    
    
    
        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-10 col-sm-12 col-lg-10 col-xl-10 col-xs-10 col-sm-12 col-lg-10 col-xl-10 col-xs-10">
            <div *ngIf="!showLoader && !isInitialLoad && !deletedSuite" class="node increase-width">
                <!-- <span class="delete-trash cursor" *ngIf="hierarchialData && hierarchialData.length && hierarchialData[0].id"
                    (click)="deleteProductSuite()"><img class="delete-trash-icon"
                        src="../../../../assets/appHub/delete.png">Delete</span> -->
                        
                <form [formGroup]="form" >
                    <div class="row">
                        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
    
                            <span class="form-title" for="name">Product Suite Name <span class="required-asterisk">*</span>
                            </span><br>
                            <input type="text" id="name" placeholder="Enter the name" formControlName="name"><br>
                            <span style="color: red; font-size: 0.8rem;"
                                *ngIf="form.get('name') && form.get('name').invalid && form.get('name').touched">Please
                                enter the product suite name!</span>
    
                        </div>
                        <!-- <div class="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-2">
                            <span class="form-title" for="rank">Rank<span class="required-asterisk">*</span></span><br>
                            <input type="number" id="rank" formControlName="rank"><br>
                        </div> -->
                    </div><br />
                    <div class="row">
                        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                            <span class="form-title" for="description">Product Suite Description <span
                                    class="required-asterisk">*</span></span>
                            <textarea id="description" placeholder="Enter the Description" formControlName="description"
                                rows="6" cols="50">{{form.value.description}}</textarea><br>
                            <span style="color: red; font-size: 0.8rem;"
                                *ngIf="form.get('description') && form.get('description').invalid && form.get('description').touched">Please
                                enter the product suite description!</span>
                        </div>
                    </div>
                    <br />
                    <div class="row" style="max-height: 73vh">
                        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="max-height: inherit;">
                            <span class="form-title" for="selectedList">Product Family</span>
                            <mat-selection-list #list class="list-container">
                                <app-tree-checklist [multiple]="true" [List]="childdataList" [SelectedCheckbox]="productSuitedata" (multipleSelectedData)="multipleList($event)" [selectedCapability]="selectedoption"></app-tree-checklist>
                            </mat-selection-list>
                            <!-- <mat-selection-list #list class="list-container" formControlName="selectedList">
                                <mat-list-option *ngFor="let list of allProductFamily"
                                    [selected]="selectedIdList.includes(list.id)" [value]="list">
                                    {{list.name}}
                                </mat-list-option>
                            </mat-selection-list> -->
    
    
                        </div>
                    </div>
    
                    <div style="text-align: center;">
                        <button [disabled]="!form.valid"
                            class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs'"
                            type="submit" (click)="onSubmit()">{{editMode === 'new' ? 'Submit' : 'Update'}}</button></div>
                </form>
            </div>
    
            <div *ngIf="!showLoader && (isInitialLoad || deletedSuite)">
                <app-data-unavailable></app-data-unavailable>
            </div>
        </div>
    
    
        <div *ngIf="showLoader" class="row loader-container">
            <app-loader style="stroke:  #002677 !important;"></app-loader>
        </div>
    </div>
</div>