import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PeriodicElement } from '../shared';
import { SelectionModel } from '@angular/cdk/collections';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ReportsService } from './reports.service';
import { EditReportComponent } from './edit-report/edit-report.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../shared/services/shared.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  AuthError,
  InteractionStatus
} from '@azure/msal-browser';
import { service, factories } from 'powerbi-client';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @ViewChild('panel1') firstPanel: MatExpansionPanel;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<PeriodicElement>(true, []);
  reports: Array<any> = [];
  reportsByCategory: Array<any> = [];
  dataLoad: boolean = false;
  reportFormat: any = 'card';
  reportData: any;
  datarefresh: boolean;
  env = environment.environmentName;
  selectedReport:any;
  reportNameFromQueryParam=false;
  HTML:any;
  report:any;
  secureGrpRestriction:any = [];
  reportName:any;
  public pageSizes = [10, 20, 30];
  powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
  state: any;
  reportContainer: any;
  context: any;
  accessToken: any;
  embedUrl: any;
  selectedSecure: any = [];
  MSDLCOMPLETED: any = false;
  private _destroying$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private reportsService: ReportsService,
    public dialog: MatDialog,
    public toaster: ToastrService,
    private router: Router,
    private acRouter: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private msalService: MsalService, 
    private broadcastService: MsalBroadcastService,
    private ContainerRef: ElementRef,
  ) {
    this.MSDLCOMPLETED = false;
    this.msalService.initialize();
    this.reportFormat = 'card';
    this.acRouter.queryParams.subscribe((reportData:any) => {
      this.getAllReports();
      if(reportData['Report']) {
          this.reportNameFromQueryParam = true;  
          this.reportName=reportData['Report'];
          if(this.reportName)        
          this.findReportNameFromURL(this.reportName);
      } else {
        this.reportNameFromQueryParam = false;
      }
    })
  }

  async ngOnInit() {
     // User input - null check
     if (environment.workspaceId === '' || environment.reportId === '') {
      console.log('Please assign values to workspace Id and report Id in Config.ts file');
      return;
    }

    this.authenticate();
    
    const groups = JSON.parse(localStorage.getItem('secureGroups'));
    this.selectedSecure = Object.keys(groups).filter(item => groups[item] === 'Yes');
  }
  getAllReports() {
    this.reportsService.getReports({Page_name:'Reports'}).subscribe(result => {
      if (result.length > 0) {
        this.reports = result;
        result.sort((a, b) => a['Category_rank'] - b['Category_rank']);
        const groupByCategory = result.groupBy(report => {
          return report.Category;
        });
        this.reportsByCategory = Object.keys(groupByCategory).map(function(reportIndex) {
          let category = groupByCategory[reportIndex];
          return category;
        });
        this.reportsByCategory.forEach(element => {
          element.sort((a, b) => {
            let reportA = a.Report.toLowerCase(),
                reportB = b.Report.toLowerCase();
        
            if (reportA < reportB) {
                return -1;
            }
            if (reportA > reportB) {
                return 1;
            }
            return 0;
        });
        });
        this.reports.sort((a, b) => {
          let reportA = a.Report.toLowerCase(),
            reportB = b.Report.toLowerCase();

          if (reportA < reportB) {
            return -1;
          }
          if (reportA > reportB) {
            return 1;
          }
          return 0;
        });
        this.dataSource = new MatTableDataSource(this.reports);
        this.selection = new SelectionModel<PeriodicElement>(true, []);
        this.dataSource.paginator = this.paginator;
        this.dataLoad = true;
        this.dataSource.filterPredicate = function(record, filter) {
          return record.Report.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
        };
        this.dataLoad = true;
        if(this.reportName)        
        this.findReportNameFromURL(this.reportName);
      } else {
        this.dataLoad = false;
      }
     
    });
  }
  ngOnChanges(): void {}

  ngAfterViewInit(): void {}

  onDatarefresh(eve) {
    if (eve == true) {
      this.getAllReports();
      this.reportFormat = 'table';
    }
  }

  displayedColumns = [
    {
      columnDef: 'report',
      header: 'Report',
      width: '38%',
      textAlign: 'left',
      type: 'reportTitle',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => `${element.Report}`
    },
    {
      columnDef: 'Category',
      header: 'Report Category',
      width: '20%',
      textAlign: 'left',
      type: 'Category',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => `${element.Category}`
    },
    {
      columnDef: 'description',
      header: 'Description',
      type: 'DescriptionReport',
      width: '20%',
      textAlign: 'left',
      cellStyle: () => {
        return {
          'center-text': `More Details`,
          'font-size': '0.9vw'
        };
      },
      cell: (element: any) => `${element.Description}`
    },
    {
      columnDef: 'type',
      header: 'Type',
      width: '12%',
      textAlign: 'left',
      type: 'TypeReport',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => `${element.Report_Type}`
    },
    {
      columnDef: 'action',
      header: 'Actions',
      width: '20%',
      textAlign: 'center',
      type: 'action',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: () => ` `,
      reportLink: (element: any) => `${element.Report_Link}`,
      access: (element: any) => `${element.Access_Restricted}`,
      secure_group: (element: any) => `${element.Secure_group}`,
      documentation: (element: any) => `${element.Documentation}`,
      ownerDetails: (element: any) => `${element.ownerDetails}`,
      HasOwner: (element: any) => `${element.HasOwner}`,
      id: (element: any) => `${element.id}`,
      reportName: (element: any) => `${element.Report}`,
      reprtDescription: (element: any) => `${element.Description}`
    }
  ];

  reportFormatChange(e) {
    this.reportFormat = e.value;
  }

  findReportNameFromURL(reportName: any) {
    let filteredReport;
    let fetchedReportNameFrmURL = reportName;
    filteredReport = this.reports.filter(reportItem =>
      reportItem.Report === fetchedReportNameFrmURL
    );
    if (filteredReport[0].Access_Restricted === 'Yes' && filteredReport[0]['Secure'] === 'Yes') {
      let reportSecure = filteredReport[0]['Secure_Check'].split(',');
      reportSecure = reportSecure.filter((item: any) => this.selectedSecure.includes(item));
      this.secureGrpRestriction = reportSecure;
      if (reportSecure.length > 0) {
        if (filteredReport[0].EMbed == 'Yes') {
          this.openEmbededReport(reportName);
        } else {
          window.open(filteredReport[0].Report_Link, '_blank');
        }
      } else {
        window.open(filteredReport[0].Access_Restriction_link, '_blank');
        this.router.navigateByUrl('/reports');
      }
    } else {
      window.open(filteredReport[0].Report_Link, '_blank');
    }
  }

  navigateUrl(Report: any) {
    this.selectedReport = Report;
    
    if (Report.Report_Type != 'Power BI') {
      window.open(Report.Report_Link, '_blank');
    } else {
      if (Report.Access_Restricted === 'Yes' && Report['Secure'] === 'Yes') {
        let reportSecure = Report['Secure_Check'].split(',');
        reportSecure = reportSecure.filter((item: any) => this.selectedSecure.includes(item));
        this.secureGrpRestriction = reportSecure;
        if (reportSecure.length > 0) {
          if (Report.Embed_type == 'Web')
            this.router.navigateByUrl(
              'reports/powerbi-report?ReportName=' + Report.Report + '&ReportID=' + Report.Report_Id
            );
          else this.router.navigateByUrl('reports?Report=' + Report.Report);
        } else {
          window.open(Report.Access_Restriction_link, '_blank');
          return;
        }
      }
    }
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', item);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  showToolTip(secure_group: any) {
    return 'Request Access to ' + secure_group + ' to view Report';
  }
  showDocumentionInfo(docuMentUrl: any) {
    window.open(docuMentUrl, '_blank');
  }

  editReport(report: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: report.id,
      Report: report.Report,
      Description: report.Description,
      Report_Link: report.Report_Link
    };
    dialogConfig.width = '50%';
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = 'custom-modalbox';
    dialogConfig.height = '62%';
    dialogConfig.disableClose = false;
    dialogConfig.maxWidth = '80vw';
    dialogConfig.position = { right: '340px', top: '140px' };

    const dialogRef = this.dialog.open(EditReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.anyChangeInData) {
        this.getAllReports();
      }
    });
  }
  reportHasLoggedInUserOwner(report: any) {
    const loggedInUserMSID = localStorage.getItem('MsId');
    if (report.ownerDetails != null) {
      let owners = report.ownerDetails.split(',').map(x => x.trim());
      let reportOwnerIsloggedinUser = owners.includes(loggedInUserMSID);
      if (reportOwnerIsloggedinUser && report.HasOwner == 'Yes') {
        return true;
      } else return false;
    } else return false;
  }

  searchDataSet(event) {
    const filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.reports = this.dataSource.filteredData;
    const groupByCategory = this.dataSource.filteredData.reduce((group, report) => {
      const { Category } = report;
      group[Category] = group[Category] ?? [];
      group[Category].push(report);
      return group;
    }, {});
    this.reportsByCategory = Object.keys(groupByCategory).map(function(reportIndex) {
      let category = groupByCategory[reportIndex];
      return category;
    });
    // filterValue = filterValue.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    // this.groups.filter = filterValue;
  }
  info() {
    window.open(environment.docUrl + 'user-guide/reports-data-extract/');
  }

  openEmbededReport(name) {
    this.reportsService.getReport(name).subscribe(result => {
      if (result.length > 0) {
        this.report = result[0];
        this.HTML =  this.sanitizer.bypassSecurityTrustHtml(this.report.Embed_link);
      }
    });
  }
  
  // Authenticating to get the access token
  authenticate(): void {
    const msalInstance = this.msalService.instance;
    const msalAccounts = msalInstance.getAllAccounts();
    const msalInProgress = this.broadcastService.inProgress$;
    const isAuthenticated = msalInstance.getAllAccounts().length > 0;

    const eventCallback = msalInstance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_SUCCESS && !this.accessToken) {
        const payload = message.payload as AuthenticationResult;
        const name: string = payload.account?.name ? payload.account?.name : '';

        this.accessToken = payload.accessToken;
        sessionStorage.setItem('ptoken',this.accessToken);
        this.setUsername(name);
        this.tryRefreshUserPermissions();
      }
    });

    const loginRequest = {
      scopes: environment.scopeBase,
      account: msalAccounts[0]
    };
    // handle auth redired/do all initial setup for msal
    msalInstance
      .handleRedirectPromise()
      .then(authResult => {
        // Check if user signed in
        const account = msalInstance.getAllAccounts();
        if (!account) {
          // redirect anonymous user to login page
          msalInstance.loginRedirect(loginRequest);
        }
      })
      .catch(err => {
        // TODO: Handle errors
        console.log(err);
      });
    msalInProgress
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        if (!isAuthenticated) {
          await msalInstance.loginRedirect(loginRequest);
        } else if (isAuthenticated && this.accessToken) {
          msalInstance.removeEventCallback(eventCallback);
        } else if (isAuthenticated && !this.accessToken) {
          this.setUsername(msalAccounts[0].name);

          // get access token silently from cached id-token
          msalInstance
            .acquireTokenSilent(loginRequest)
            .then((response: AuthenticationResult) => {
              this.accessToken = response.accessToken;
              sessionStorage.setItem('ptoken',this.accessToken);
              this.MSDLCOMPLETED = true;
            })
            .catch((error: AuthError) => {
              // Refresh access token silently from cached id-token
              // Makes the call to handleredirectcallback
              if (
                error.errorCode === 'consent_required' ||
                error.errorCode === 'interaction_required' ||
                error.errorCode === 'login_required'
              ) {
                msalInstance.acquireTokenRedirect(loginRequest);
              } else if (error.errorCode === '429') {
                console.log('Our Service Token Server (STS) is overloaded, please try again in sometime');
              } else {
                console.log('There was some problem fetching the access token' + error.toString());
              }
            });
        }
      });
  }
    // Power BI REST API call to refresh User Permissions in Power BI
  // Refreshes user permissions and makes sure the user permissions are fully updated
  // https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
  tryRefreshUserPermissions(): void {
    fetch(environment.powerBiApiUrl + 'v1.0/myorg/RefreshUserPermissions', {
      headers: {
        Authorization: 'Bearer ' + this.accessToken
      },
      method: 'POST'
    })
      .then(function(response) {
        if (response.ok) {
          console.log('User permissions refreshed successfully.');
        } else {
          // Too many requests in one hour will cause the API to fail
          if (response.status === 429) {
            console.error('Permissions refresh will be available in up to an hour.');
          } else {
            console.error(response);
          }
        }
      })
      .catch(function(error) {
        console.error('Failure in making API call.' + error);
      });
  }

  // Show username in the UI
  setUsername(username: string): void {
    const welcome = document.getElementById('welcome');
    if (welcome !== null) welcome.innerText = 'Welcome, ' + username;
  }
}
