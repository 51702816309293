import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/shared/services/admin.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-lookup-editor',
  templateUrl: './lookup-editor.component.html',
  styleUrls: ['./lookup-editor.component.css']
})
export class LookupEditorComponent {
  @Input() lookupTables: any = [];
  @ViewChild('editDataPopup') editDataPopup;
  selectedLookupTable: string = '';
  closePopup: boolean = false;
  wq_dataSource: MatTableDataSource<any>;
  app_attr_dataSource: MatTableDataSource<any>;
  lookup_no_hry_dataSource: MatTableDataSource<any>;
  lookup_with_hry_dataSource: MatTableDataSource<any>;
  dropdownData = [];
  editFlag: boolean = false;
  tableNameCloseVar:any;
  emitReqPayloadVar:any;
  updateAppAttributeVar:any;
  updateLookupNoHryVar:any;
  updateLookupWithHryVar:any;
  wq_displayedColumns: string[] = ['workqueue', 'create_req_roles', 'approve_req_roles', 'updated_at', 'updated_by'];
  app_attr_displayedColumns: string[] = ['attribute_name', 'selection', 'hierarchy', 'editable', 'visual_order'];
  lookup_no_hry_displayedColumns: string[] = ['attribute', 'value', 'edit_drop_down', 'view_drop_down', 'updatedAt', 'updated_by'];
  lookup_with_hry_displayedColumns: string[] = ['attribute', 'value', 'sub_value', "edit_drop_down", "view_drop_down", 'updatedAt', 'updated_by'];
  WQ_MSTR_COL = [
    {
      columnDef: 'workqueue',
      header: 'Workqueue',
      width: '13%',
    },
    {
      columnDef: 'create_req_roles',
      header: 'Create Request Roles',
      width: '13%',
    },
    {
      columnDef: 'approve_req_roles',
      header: 'Approve Request Roles',
      width: '13%',
    },
    {
      columnDef: 'create_new_dropdown',
      header: 'Create New Dropdown',
      width: '13%',
    },
    {
      columnDef: 'view_visibility',
      header: 'View Visibility',
      width: '9%',
    },
    {
      columnDef: 'updated_at',
      header: 'Updated At',
      isSort: true
    },
    {
      columnDef: 'updated_by',
      header: 'Updated By',
    },
    {
      columnDef: 'Actions',
      header: 'Action',
      displayText: 'Edit',
      
    }
  ];
  APP_ATTR_COL = [
    {
      columnDef: 'attribute_name',
      header: 'Attribute Name',

    },
    {
      columnDef: 'selection',
      header: 'Selection',

    },
    {
      columnDef: 'hierarchy',
      header: 'Hierarchy',

    },
    {
      columnDef: 'editable',
      header: 'Editable',
    },
    {
      columnDef: 'visual_order',
      header: 'Visual order',
    },
    {
      columnDef: 'Actions',
      header: 'Action',
      displayText: 'Edit',
    }
  ];
  LOOKUP_NO_HRY_COL = [
    {
      columnDef: 'attribute',
      header: 'Attribute',
    },
    {
      columnDef: 'value',
      header: 'Value',
    },
    {
      columnDef: 'edit_drop_down',
      header: 'Edit Drop Down',
    },
    {
      columnDef: 'view_drop_down',
      header: 'View Drop Down',
    },
    {
      columnDef: 'updatedAt',
      header: 'Updated At',
      isSort: true
    },
    {
      columnDef: 'updated_by',
      header: 'Updated By',
    },
    {
      columnDef: 'Actions',
      header: 'Action',
      displayText: 'Edit',
    }
  ];
  LOOKUP_WITH_HRY_COL = [
    {
      columnDef: 'attribute',
      header: 'Attribute',
      width: '20%'
    },
    {
      columnDef: 'value',
      header: 'Value',

    },
    {
      columnDef: 'sub_value',
      header: 'Sub Value',

    },
    {
      columnDef: 'edit_drop_down',
      header: 'Edit Dropdown',
    },
    {
      columnDef: 'view_drop_down',
      header: 'View Dropdown',
    },
    {
      columnDef: 'updatedAt',
      header: 'Updated At',
      isSort: true
    },
    {
      columnDef: 'updated_by',
      header: 'Updated By',
    },
    {
      columnDef: 'Actions',
      header: 'Action',
      displayText: 'Edit',
    }
  ];
  createReqRolesDropdown: any = [
    {
      id: 'AD',
      name: 'AD'
    },
    {
      id: 'CA',
      name: 'CA'
    },
    {
      id: 'Admin',
      name: 'Admin'
    },
    {
      id: 'System',
      name: 'System'
    }
  ]
  approveReqRolesDropdown: any = [
    {
      id: 'AD',
      name: 'AD'
    },
    {
      id: 'CA',
      name: 'CA'
    },
    {
      id: 'Admin',
      name: 'Admin'
    },
    {
      id: 'System',
      name: 'System'
    }
  ];
  workQueuesDropdown: any = ["LOB Update", "New LOB Assignment"];
  yesNoDropdownValues: any = ["Yes", "No"];
  selectionDropdown: any = ["Single", "Multi"];
  hierarchyDropdown: any = [0, 1, 2, 3];
  visualOrderDropdown: any = [];
  attributeDropdown: any = [];
  attributeHryDropdown: any = [];
  appattributeDropdown: any = [];
  valueHryDropdown: any = [];
  workQueueFormFields = [
    {
      'label': "Select Work Queue",
      'inputType': "singleSelect",
      'fcname': "workQueue",
      'placeholder': "Select Work Queue",
      'dropdownData': this.workQueuesDropdown
    },
    {
      'label': "Approve Request Roles",
      'inputType': "multiSelect",
      'fcname': "approveReqRole",
      'placeholder': "Select Approve Req Roles",
      'dropdownData': this.approveReqRolesDropdown
    },
    {
      'label': "Create Request Roles",
      'inputType': "multiSelect",
      'fcname': "createReqRole",
      'placeholder': "Select Create Req Roles",
      'dropdownData': this.createReqRolesDropdown
    },
    {
      'label': "Create New Dropdown",
      'inputType': "singleSelect",
      'fcname': "createDropdown",
      'placeholder': "Create New Dropdown",
      'dropdownData': this.yesNoDropdownValues
    },
    {
      'label': "View visibility",
      'inputType': "singleSelect",
      'fcname': "viewVisibility",
      'placeholder': "View visibility",
      'dropdownData': this.yesNoDropdownValues
    }
  ];
  AppAttributeFormFields = [
    {
      'label': "Attribute Name",
      'inputType': "singleSelect",
      'fcname': "attribute",
      'placeholder': "Selection",
      'dropdownData': this.appattributeDropdown
    },
    {
      'label': "Selection",
      'inputType': "singleSelect",
      'fcname': "selection",
      'placeholder': "Selection",
      'dropdownData': this.selectionDropdown
    },
    {
      'label': "Hierarchy",
      'inputType': "singleSelect",
      'fcname': "hierarchy",
      'placeholder': "Select Heirarchy",
      'dropdownData': this.hierarchyDropdown
    },
    {
      'label': "Editable",
      'inputType': "singleSelect",
      'fcname': "editable",
      'placeholder': "Editable",
      'dropdownData': this.yesNoDropdownValues
    },
    {
      'label': "Visual order",
      'inputType': "singleSelect",
      'fcname': "visualorder",
      'placeholder': "Select visual order",
      'dropdownData': this.visualOrderDropdown
    }
  ];
  lookupNoHryFormFields = [
    {
      'label': "Attribute",
      'inputType': "singleSelect",
      'fcname': "attribute",
      'placeholder': "Select Attribute",
      'dropdownData': this.attributeDropdown
    },
    {
      'label': "Value",
      'inputType': "text",
      'fcname': "value",
      'placeholder': "Select Value",
      'dropdownData': null
    },
    {
      'label': "Edit Dropdown",
      'inputType': "singleSelect",
      'fcname': "editDropdown",
      'placeholder': "Edit Dropdown",
      'dropdownData': this.yesNoDropdownValues
    },
    {
      'label': "View Dropdown",
      'inputType': "singleSelect",
      'fcname': "viewDropdown",
      'placeholder': "View Dropdown",
      'dropdownData': this.yesNoDropdownValues
    }
  ];
  lookupWithHryFormFields = [
    {
      'label': "Attribute",
      'inputType': "singleSelect",
      'fcname': "attributeHry",
      'placeholder': "Select Attribute",
      'dropdownData': this.attributeHryDropdown
    },
    {
      'label': "Value",
      'inputType': "text",
      'fcname': "valueHry",
      'placeholder': "Select Value",
      'dropdownData': null
    },
    {
      'label': "Sub Value",
      'inputType': "text",
      'fcname': "subvalueHry",
      'placeholder': "Enter Sub Value",
      'dropdownData': null
    },
    {
      'label': "Edit Dropdown",
      'inputType': "singleSelect",
      'fcname': "editDropdownHry",
      'placeholder': "Edit Dropdown",
      'dropdownData': this.yesNoDropdownValues
    },
    {
      'label': "View Dropdown",
      'inputType': "singleSelect",
      'fcname': "viewDropdownHry",
      'placeholder': "View Dropdown",
      'dropdownData': this.yesNoDropdownValues
    }
  ];
  addLookupWithHryFormFields = [
    {
      'label': "Attribute",
      'inputType': "singleSelect",
      'fcname': "attributeHry",
      'placeholder': "Select Attribute",
      'dropdownData': this.attributeHryDropdown
    },
    {
      'label': "Value",
      'inputType': "textSelect",
      'fcname': "valueHry",
      'placeholder': "Select Value",
      'dropdownData': this.valueHryDropdown
    },
    {
      'label': "Sub Value",
      'inputType': "text",
      'fcname': "subvalueHry",
      'placeholder': "Enter Sub Value",
      'dropdownData': null
    },
    {
      'label': "Edit Dropdown",
      'inputType': "singleSelect",
      'fcname': "editDropdownHry",
      'placeholder': "Edit Dropdown",
      'dropdownData': this.yesNoDropdownValues
    },
    {
      'label': "View Dropdown",
      'inputType': "singleSelect",
      'fcname': "viewDropdownHry",
      'placeholder': "View Dropdown",
      'dropdownData': this.yesNoDropdownValues
    }
  ];
  addLookupWitNohHryFormFields = [
    {
      'label': "Attribute",
      'inputType': "singleSelect",
      'fcname': "attributeHry",
      'placeholder': "Select Attribute",
      'dropdownData': this.attributeDropdown
    },
    {
      'label': "Value",
      'inputType': "text",
      'fcname': "valueHry",
      'placeholder': "Enter Value",
      'dropdownData': null
    },
    {
      'label': "Edit Dropdown",
      'inputType': "singleSelect",
      'fcname': "editDropdownHry",
      'placeholder': "Edit Dropdown",
      'dropdownData': this.yesNoDropdownValues
    },
    {
      'label': "View Dropdown",
      'inputType': "singleSelect",
      'fcname': "viewDropdownHry",
      'placeholder': "View Dropdown",
      'dropdownData': this.yesNoDropdownValues
    }
  ];

  wqList: any = [];
  dataToEdit: any;
  popHeader: string;
  popupHeader: string;
  formfields: any;
  tableName: string;
  formGroupName: string;
  ahL0Ids: any = {};
  showLoader:boolean = false;

  constructor(public admnsrv: AdminService, public datepipe: DatePipe, public toaster: ToastrService) { }


  ngOnInit() {
    this.selectedLookupTable = 'WQ_MSTR'
    this.getWorkQueueMasterData();
    this.getAppAttributeDetails();
    this.getLookupNoHry();
    this.getLookupWithHry();
    this.wqList = [{
      "value": "LOB Update",
      "id": "LOB_Update"
    }, {
      "value": "New LOB Assignment",
      "id": "New_LOB_Assignment"
    }]
  }

  /**
* @author: Menagadevi
* @description: This methods helps to fetch the lookup with 1 hierarchy data by making an API call.
*/
  getLookupWithHry() {
    this.showLoader = true;
    this.admnsrv.getLookupWithHryData().subscribe((response: any) => {
      this.showLoader = false;
      this.modifyDates(response.result);
      this.lookup_with_hry_dataSource = new MatTableDataSource(response.result);
      this.valueHryDropdown = new Set(response.result.map(x => x.value));
      this.ahL0Ids = response.result.map(({ value, AH_L0_ID }) => ({ value, AH_L0_ID }));
      this.attributeHryDropdown = new Set(response.result.map(x => x.attribute));
      this.lookupWithHryFormFields[0].dropdownData = this.attributeHryDropdown;
      this.addLookupWithHryFormFields[0].dropdownData = this.attributeHryDropdown;
      this.addLookupWithHryFormFields[1].dropdownData = this.valueHryDropdown;
    },
      (err) => {
        this.showErrMsg(err);
        

      })
  }

  /**
* @author: Menagadevi
* @description: This methods helps to fetch the lookup with no hierarchy data by making an API call.
*/
  getLookupNoHry() {
    this.showLoader = true;
    this.admnsrv.getLookupNoHryData().subscribe((response: any) => {
      this.showLoader = false;
      this.modifyDates(response.result);
      this.lookup_no_hry_dataSource = new MatTableDataSource(response.result);
      this.attributeDropdown = [...new Set(response.result.map(x => x.attribute))];
      this.lookupNoHryFormFields[0].dropdownData = this.attributeDropdown;
      this.addLookupWitNohHryFormFields[0].dropdownData = this.attributeDropdown;
    },
      (err) => {
        this.showErrMsg(err);

      })
  }

  /**
* @author: Menagadevi
* @description: This methods helps to fetch the lookup with no hierarchy data by making an API call.
*/
  getAppAttributeDetails() {
    this.showLoader = true;
    this.admnsrv.getAppAttrData().subscribe((response: any) => {
      this.showLoader = false;
      this.app_attr_dataSource = new MatTableDataSource(response.result);
      this.visualOrderDropdown = [];
      response.result.forEach((elem, index) => {
        this.visualOrderDropdown.push(index + 1);
      });
      this.appattributeDropdown = [...new Set(response.result.map(x => x.attribute_name))];
      this.AppAttributeFormFields[0].dropdownData = this.appattributeDropdown;
      this.AppAttributeFormFields[4].dropdownData = this.visualOrderDropdown;

    },
      (err) => {
        this.showErrMsg(err);

      })
  }
  getWorkQueueMasterData() {
    this.showLoader = true;
    this.admnsrv.getWorkQueueData().subscribe((response: any) => {
      this.showLoader = false;
      this.modifyDates(response.result);
      this.workQueuesDropdown = response.result.map(x=>x.workqueue);
      this.workQueueFormFields[0].dropdownData = this.workQueuesDropdown;
      console.log(this.workQueuesDropdown)
      this.wq_dataSource = new MatTableDataSource(response.result);
    },
      (err) => {
        if (err && err.message) {
          this.showErrMsg(err);
        }
      })
  }


  modifyDates(data) {
    data.forEach(element => {

      if (element['updated_at']) {
        element['updated_at'] = this.datepipe.transform(element['updated_at'], 'dd/MM/yyyy,h:mm a');
      }
      if (element['updatedAt']) {
        element['updatedAt'] = this.datepipe.transform(element['updatedAt'], 'dd/MM/yyyy,h:mm a');
      }
    });
    return data;
  }
  selectChange() {
  }

  closeModal(tableName: string) {
    this.tableNameCloseVar = tableName;
    this.closePopup = true;
  }

  editWorkQueue(event) {
    this.editFlag = true;
    if (event.tableName == 'WQ_MSTR') {
      this.dataToEdit = event.data;
      this.popupHeader = "Update Role";
      this.formfields = this.workQueueFormFields;
      this.editDataPopup.show();
      this.tableName = "WQ_MSTR";

    }
    else if (event.tableName == 'APP_ATTR') {
      this.dataToEdit = event.data;
      this.editDataPopup.show();
      this.formfields = this.AppAttributeFormFields;
      this.popupHeader = "Update App Attribute : " + this.dataToEdit.attribute_name;
      this.tableName = "APP_ATTR";
    }
    else if (event.tableName == 'LOOKUP_NO_HRY') {
      this.dataToEdit = event.data;
      this.editDataPopup.show();
      this.formfields = this.lookupNoHryFormFields;
      this.popupHeader = "Update lookup with no hierarchy : " + this.dataToEdit.attribute;
      this.tableName = "LOOKUP_NO_HRY";
    }
    else if (event.tableName == 'LOOKUP_WITH_HRY') {
      this.dataToEdit = event.data;
      this.editDataPopup.show();
      this.formfields = this.lookupWithHryFormFields;
      this.popupHeader = "Update lookup with hierarchy : " + this.dataToEdit.attribute;
      this.tableName = "LOOKUP_WITH_HRY";
    }

  }

  emitReqPayload(event) {
    if (event.tableName == "WQ_MSTR") {
      this.showLoader = true;
      this.admnsrv.updateWorkQueueRole(event.payload).subscribe((response) => {
        this.emitReqPayloadVar = response;
        this.editDataPopup.hide();
        this.showSuccessMsg('Data updated Successfully.');
        this.getWorkQueueMasterData();
      },
        (err) => {
          this.showErrMsg(err);
        })
    }
    else if (event.tableName == "APP_ATTR") {
      this.showLoader = true;
      this.admnsrv.updateAppAttribute(event.payload).subscribe((response) => {
        this.updateAppAttributeVar = response;
        this.editDataPopup.hide();
        this.showSuccessMsg('Data updated Successfully.');
        this.getAppAttributeDetails();
      }, (err) => {
        this.showErrMsg(err);
      })
    }
    else if (event.tableName == "LOOKUP_NO_HRY") {
      this.showLoader = true;
      this.admnsrv.updateLookupNoHry(event.payload).subscribe((response) => {
        this.updateLookupNoHryVar = response;
        this.editDataPopup.hide();
        this.showSuccessMsg('Data updated Successfully.');
        this.getLookupNoHry();
      }, (err) => {
        this.showErrMsg(err);
      })
    } else if (event.tableName == "LOOKUP_WITH_HRY") {
      this.showLoader = true;
      this.admnsrv.updateLookupWithHry(event.payload).subscribe((response) => {
        this.updateLookupWithHryVar = response;
        this.editDataPopup.hide();
        this.showSuccessMsg('Data updated Successfully.');
        this.getLookupWithHry();
      }, (err) => {
        this.showErrMsg(err);

      })
    }
    else if (event.tableName == "ADD_LOOKUP_WITH_HRY") {
      this.showLoader = true;
      let index = this.ahL0Ids.findIndex(o => event.payload.value === o.value);
      if (index >= 0)
        event.payload["AH_L0_ID"] = this.ahL0Ids[index].AH_L0_ID;
      else {
        event.payload["AH_L0_ID"] = null;
      }
      this.admnsrv.addLookupWithHry(event.payload).subscribe((response) => {
        this.editDataPopup.hide();
        this.showSuccessMsg(response.message);
        this.getLookupWithHry();
      }, (err) => {
        this.showErrMsg(err);
      })
    }
    else if (event.tableName == "ADD_LOOKUP_WITH_NO_HRY") {
      this.showLoader = true;
      this.admnsrv.addLookupWithNoHry(event.payload).subscribe((response) => {
        this.editDataPopup.hide();
        this.showSuccessMsg(response.message);
        this.getLookupNoHry();
      }, (err) => {
        this.showErrMsg(err);
      })
    }


  }
  showErrMsg(err: any) {
    this.showLoader = false;
    if (err && err.messsage) {
      this.toaster.error(err.message, '', {
        timeOut: 10000,
        positionClass: 'toast-top-center'
      });
    }
  }

  showSuccessMsg(msg: any) {
    this.showLoader = false;
    if (msg) {
      this.toaster.success(msg, '', {
        timeOut: 10000,
        positionClass: 'toast-top-center'
      });
    }
  }

  addHry() {
    this.dataToEdit = {};
    this.editDataPopup.show();
    this.formfields = this.addLookupWithHryFormFields;
    this.popupHeader = "Add lookup with hierarchy";
    this.tableName = "ADD_LOOKUP_WITH_HRY";
    this.editFlag = false;
  }

  addNoHry() {
    this.dataToEdit = {};
    this.editDataPopup.show();
    this.formfields = this.addLookupWitNohHryFormFields;
    this.popupHeader = "Add lookup no hierarchy";
    this.tableName = "ADD_LOOKUP_WITH_NO_HRY";
    this.editFlag = false;
  }


}
