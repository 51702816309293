import { NgModule } from '@angular/core';
import { ThousandSuffixesPipe } from './thousand-suffixes.pipe';
import { CommonModule } from '@angular/common';
import { DigitFormatterPipe } from './digit-formatter.pipe';
import { OrdinalDatePipe } from './ordinal-date.pipe';
import { AppAttrSearchPipe } from './app-attr-search.pipe';
@NgModule({
    imports: [CommonModule],
    declarations: [ThousandSuffixesPipe, DigitFormatterPipe, OrdinalDatePipe, AppAttrSearchPipe],
    exports: [ThousandSuffixesPipe,DigitFormatterPipe, OrdinalDatePipe,AppAttrSearchPipe],
    providers: [ThousandSuffixesPipe,DigitFormatterPipe, OrdinalDatePipe,AppAttrSearchPipe]
})

export class ThousandPipeModule {
    static forRoot() {
        return {
            ngModule: ThousandPipeModule,
            providers: []
        };
    }
}
