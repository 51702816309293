<div class="uitk-form-control">
  <div *ngIf="label" class="input-label">
    <label for="{{ id }}"
      >{{ label }}<span *ngIf="isRequired" class="uitk-icon uitk-icon-asterisk sm-icon uitk-c-label__icon" aria-hidden="true"></span
    >: </label>
  </div>
  <div class="input-box">
    <input
      name="name"
      [type]="type"
      class="switch-input"
      [value]="value"
      [id]="id"
      (input)="updateValue($event.target.value)"
      (change)="updateValue($event.target.value)"
      (submit)="updateValue($event.target.value)"
      required="{{isRequired}}"
      [disabled]="disabled"
      [pattern]="pattern"
      [maxlength]="maxlength"
      [minlength]="minlength"
    />
    <ng-container *ngIf="formcontrol && formcontrol?.invalid && formcontrol?.touched">
      <ul class="help-block uitk-c-input__message--error">
        <li *ngIf="formcontrol.hasError('required')">
          this is required field.
        </li>
        <li *ngIf="formcontrol.hasError('pattern')">
          pattern is invalid
        </li>
        <li *ngIf="formcontrol.hasError('maxlength')">
          the length is over the max limit.
        </li>
        <li *ngIf="formcontrol.hasError('minlength')">
          the length is below the min limit.
        </li>
        <!-- <li *ngFor="let err of formcontrol.errors | keyvalue">
         {{err.key}}
       </li> -->
      </ul>
    </ng-container>
    <ng-container *ngIf="formref?.submitted && !formcontrol?.touched">
      <ul *ngIf="this.form.controls[this.name].hasError('required')" class="help-block uitk-c-input__message--error">
        <li>
          this is required field
        </li>
      </ul>
    </ng-container>

    <strong *ngIf="debugg">Value: {{ value }}</strong>
  </div>
</div>
