<div id="sessionBanner" class="alert alert-info" role="alert" style="color: #31708f !important;
background-color: #d9edf7 !important;
border-color: #bce8f1 !important;
  color:black !important;
 text-align: center;display:none">
  <strong></strong>
  Please use the Chrome web browser with TCM Hub. Internet Explorer is not supported due to severe performance issues.
</div>

<!-- <div class="content-wrapper">
  <header class="header-base" role="banner">
    <div class=" header-align">
      <div class="header-logo-space col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-2 col-sm-2">
        <a href="#">
          <img class="uhc-logo" src="../../../../assets/images/TCM Logo.png" alt="UHG Logo" />
        </a>
      </div>
    </div>
  </header>
  <header class="header-divider" role="banner"></header>
</div> -->

<header class="bg-white border-bottom navbar flex-column flex-md-row"
  style="font-family: Roboto,Helvetica Neue,sans-serif;">
  <div class="col-4">
    <!-- <img class="align-baseline img-fluid w-25" src="../../../../assets/images/TCM Logo.png" /> -->
  </div>
  <div class="col-4 text-center">
    <a
      style="font-family: 'UHCSans-Regular', 'Arial'; margin-top: -2px; color: #115fa0; font-size: 18px; display: inline-block;  justify-content: center">
      TCM Hub</a>
  </div>
  <div class="col-4">
  </div>
</header>