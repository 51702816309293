import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { CostService } from '../../../techopsv4/services/CostService';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() urldata: any = [];
  @Input() usericon: true;
  public comments: any = [];
  public newComment: any;
  public loggedusermail: any;
  constructor(
    private container: ElementRef,
    public datepipe: DatePipe,
    private CostService: CostService
  ) { }
  ngOnInit() {
    this.loggedusermail = JSON.parse(sessionStorage.getItem('loggedInUserEmail'));
  }
  ngOnChanges() { if (this.urldata) this.getcomments(); }
  getcomments() {

    delete this.urldata.appid;
    delete this.urldata.appname;
    delete this.urldata.SERVICE_LEVEL_OWNER;
    delete this.urldata.Category;
    delete this.urldata.Technical_Owner;

    const ServicePromise = this.CostService.getcomments(JSON.stringify(this.urldata));
    ServicePromise.then(res => {
      this.comments = res;
      this.comments = this.comments.map(acc => {
        acc.author = JSON.parse(acc.author);
        acc['GLOBAL_APP_ID'] = acc.GLOBAL_APP_ID;
        acc['LONG_APP_NAME'] = acc.LONG_APP_NAME;
        acc.childs.map(acc2 => {
          acc2.author = JSON.parse(acc2.author);
          acc2.GLOBAL_APP_ID = acc.GLOBAL_APP_ID;
          acc2.LONG_APP_NAME = acc.LONG_APP_NAME;
          return acc2;
        });
        return acc;
      });
    }).catch(err => {
      console.log(err);
    });

  }
  addComment(data, f = null) {
    const ServicePromise = this.CostService.addcomment(JSON.stringify(data));
    ServicePromise.then(res => {
      if (res) this.getcomments();
      if (f) f.resetForm();
    }).catch(err => {
      console.log(err);
    });
  }
  updateComment(data, id) {
    const ServicePromise = this.CostService.updatecomment(JSON.stringify(data), id);
    ServicePromise.then(res => {
      if (res) this.getcomments();
    }).catch(err => {
      console.log(err);
    });
  }
  deleteComment(id) {
    const ServicePromise = this.CostService.deletecomment(id);
    ServicePromise.then(res => {
      if (res) this.getcomments();
    }).catch(err => {
      console.log(err);
    });
  }
  addNewComment(f) {
    delete this.urldata.appid;
    delete this.urldata.appname;
    const userNameFullDetails = JSON.parse(sessionStorage.getItem('currentUser'));
    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    this.comments.push({
      Month: month,
      Year: new Date().getFullYear(),
      content: this.newComment,
      parent: '',
      created_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma'),
      author: JSON.stringify({ email: this.loggedusermail, name: userNameFullDetails }),
      ...this.urldata
    });

    this.addComment(this.comments[0], f);
  }
  getGravatar() {
    return 'assets/images/FPO_User.svg';
  }
  replyform(index) {
    this.comments[index].reply = true;
  }
  replychildform(parentindex, childindex) {
    this.comments[parentindex].childs[childindex].reply = true;
  }
  closereply(index) {
    this.comments[index].reply = false;
    this.container.nativeElement.querySelector('.replyComment-' + index).value = '';
  }
  closechildreply(parentindex, childindex) {
    this.comments[parentindex].childs[childindex].reply = false;
    this.container.nativeElement.querySelector('.replyComment-' + parentindex + childindex).value = '';
  }
  addreply(id, parentindex) {
    const userNameFullDetails = JSON.parse(sessionStorage.getItem('currentUser'));
    const value = this.container.nativeElement.querySelector('.replyComment-' + parentindex).value;
    if (value == '') {
      alert('Please add comment!');
      return;
    }
    // if (this.comments[parentindex].hasOwnProperty('Childs')) {
    //   this.comments[parentindex].childs.push({
    //      id: this.comments[parentindex].length + 1,
    //     content: value,
    //     created_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma'),
    //     author: { email: this.loggedusermail, name: userNameFullDetails }
    //   });
    // } else {
    //   this.comments[parentindex]['Childs'] = [
    //     {
    //       id: this.comments[parentindex].length + 1,
    //       content: value,
    //       created_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma'),
    //       author: { email: this.loggedusermail, name: userNameFullDetails }
    //     }
    //   ];
    // }
    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    let data = {
      Month: month,
      Year: new Date().getFullYear(),
      content: value,
      parent: id,
      created_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma'),
      author: JSON.stringify({ email: this.loggedusermail, name: userNameFullDetails }),
      ...this.urldata
    };
    this.addComment(data);
    this.closereply(parentindex);
  }
  addchildreply(id, parentindex, childindex) {
    const userNameFullDetails = JSON.parse(sessionStorage.getItem('currentUser'));
    const value = this.container.nativeElement.querySelector('.replyComment-' + parentindex + childindex).value;
    if (value == '') {
      alert('Please add comment!');
      return;
    }
    // this.comments[parentindex].Childs.push({
    //   // id: this.comments[parentindex].Childs.length + 1,
    //   content: value,
    //   created_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma'),
    //   author: { email: this.loggedusermail, name: userNameFullDetails }
    // });
    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    let data = {
      Month: month,
      Year: new Date().getFullYear(),
      content: value,
      parent: id,
      created_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma'),
      author: JSON.stringify({ email: this.loggedusermail, name: userNameFullDetails }),
      ...this.urldata
    };
    this.addComment(data);
    this.closechildreply(parentindex, childindex);
  }
  delParentCom(id) {
    if (confirm('Are you sure want to delete! It will delete all reply in this thread.')) {
      this.deleteComment(id);
    }
  }
  delchildCom(id) {
    if (confirm('Are you sure want to delete!')) {
      this.deleteComment(id);
    }
  }
  editform(index) {
    this.comments[index]['edit'] = true;
  }
  editchildform(parentindex, childindex) {
    this.comments[parentindex].childs[childindex]['edit'] = true;
  }
  closeedit(index) {
    this.comments[index].edit = false;
  }
  closechildedit(parentindex, childindex) {
    this.comments[parentindex].childs[childindex].edit = false;
  }
  editcomment(id, parentindex) {
    const value = this.container.nativeElement.querySelector('.editComment-' + parentindex).value;
    this.comments[parentindex].content = value;
    if (value == '') {
      alert('Please add comment!');
      return;
    }
    this.closeedit(parentindex);
    let data = {
      content: value,
      update_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma')
    };
    this.updateComment(data, id);
  }
  editchildcomment(id, parentindex, childindex) {
    const value = this.container.nativeElement.querySelector('.editComment-' + parentindex + childindex).value;
    this.comments[parentindex].childs[childindex].content = value;
    if (value == '') {
      alert('Please add comment!');
      return;
    }
    let data = {
      content: value,
      update_at: this.datepipe.transform(Date(), 'dd MMM yy hh:mma')
    };
    this.updateComment(data, id);
    this.closechildedit(parentindex, childindex);
  }
}
