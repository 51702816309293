import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppModernizationService } from '../../services/app-modernization.service';

@Component({
  selector: 'app-expand-collapse-table',
  templateUrl: './expand-collapse-table.component.html',
  styleUrls: ['./expand-collapse-table.component.css']
})
export class ExpandCollapseTableComponent implements OnInit {

  constructor(private service: AppModernizationService,
    private route: Router) { }
  // panelOpenState = false;


  @Input() tableData;
  @Input() tableColumn;
  @Input() modData;
  @Input() modColumn;
  subscription: Subscription;
  pageOfItems: Array<any> = [];
  @Input() pageSize = 5;
  showLoader: boolean;
  askidDetail = [];
  finalData = [];
  @Input() data;
  body;


  writeAcess = JSON.parse(localStorage.getItem('isWriteAccess'));
  basicColumns = [
    {
      columnDef: 'app_id', header: 'ASK ID.', width: '8%', cell: (element: any) => `${element.app_id}`,
      description: { name: 'ASKID', value: true }
    },
    {
      columnDef: 'tmdb', header: 'TMDB', width: '8%', cell: (element: any) => `${element.tmdb}`,
      description: { name: 'TMDB', value: true }
    },
    {
      columnDef: 'app_name',
      header: `Application Name`,
      width: '10%',
      cell: (element: any) => `${element.app_name || ''}`,
      description: { name: 'Application Name', value: true }
    },
    {
      columnDef: 'formalName',
      header: 'Formal Name',
      width: '10%',
      cell: (element: any) => `${element.formalName || ''}`,
      description: { name: 'Formal Name', value: true }
    },
    {
      columnDef: 'lob',
      header: `LOB`,
      width: '5%',
      cell: (element: any) => `${element.lob || ''}`,
      description: { name: 'LOB', value: true }
    },
    {
      columnDef: 'category', header: 'Category', width: '8%', cell: (element: any) => `${element.category || ''}`,
      description: { name: 'Category', value: true }
    },
    {
      columnDef: 'lifecycle_status', header: 'Application Lifecycle Status', width: '7%', cell: (element: any) =>
        `${element.lifecycle_status || ''}`,
      description: { name: 'Application Lifecycle Status', value: true }
    }
  ];

  ownerDetails = [
    {
      columnDef: 'business_owner', header: 'Business Owner', width: '7%', cell: (element: any) => `${element.business_owner || ''}`,
      description: { name: 'Business Owner', value: true }
    },
    {
      columnDef: 'itOwner',
      header: 'IT Owner',
      width: '9%',
      cell: (element: any) => `${element.itOwner || ''}`,
      description: { name: 'IT Owner', value: true }
    },
    {
      columnDef: 'slo',
      header: 'SLO',
      width: '9%',
      cell: (element: any) => `${element.slo || ''}`,
      description: { name: 'SLO', value: true }
    },
    {
      columnDef: 'enterArchitOwner',
      header: 'Enterprise Architect',
      width: '10%',
      cell: (element: any) => `${element.enterArchitOwner || ''}`,
      description: { name: 'Enterprise Architect', value: true }
    },
    {
      columnDef: 'tech_owner',
      header: 'Technical Owner Executive',
      width: '10%',
      cell: (element: any) => `${element.tech_owner || ''}`,
      description: { name: 'Technical Owner Executive', value: true }
    },
    {
      columnDef: 'technicaOwnerLeader',
      header: 'Technical Owner Leader',
      width: '10%',
      cell: (element: any) => `${element.technicaOwnerLeader || ''}`,
      description: { name: 'Technical Owner Leader', value: true }
    }
  ];

  modernizationColumns = [
    {
      columnDef: 'mainframe', header: 'Mainframe', width: '3%', cell: (element: any) => `${element.mainframe}`,
      description: { name: 'Mainframe', value: true }
    },
    {
      columnDef: 'roadmap', header: 'Roadmap', width: '8%', cell: (element: any) => `${element.roadmap}`,
      description: { name: 'Roadmap', value: true }
    },
    {
      columnDef: 'roadmapStatus',
      header: `Roadmap Status`,
      width: '10%',
      cell: (element: any) => `${element.roadmapStatus}`,
      description: { name: 'Roadmap Status', value: true }
    },
    {
      columnDef: 'cloudStatus',
      header: 'Cloud Status',
      width: '3%',
      cell: (element: any) => `${element.cloudStatus}`,
      description: { name: 'Cloud Status', value: true }
    },
    {
      columnDef: 'cloudStatusComment', header: 'Cloud status comment',
      width: '8%', cell: (element: any) => `${element.cloudStatusComment}`,
      description: { name: 'Cloud status comment', value: true }
    },
    {
      columnDef: 'cloudModerStatus', header: 'Cloud Modernization Status', width: '7%', cell: (element: any) =>
        `${element.cloudModerStatus}`,
      description: { name: 'Cloud Modernization Status', value: true }
    },
    {
      columnDef: 'totalProFormaBenefit', header: 'Total Pro Forma Benefit',
      width: '7%', cell: (element: any) => `$ ${element.totalProFormaBenefit}`,
      description: { name: 'Total Pro Forma Benefit', value: true },
      type: 'number'
    },
    {
      columnDef: 'nextRoadmapMilestoneDate',
      header: 'Next Roadmap Date',
      width: '9%',
      cell: (element: any) => `${element.nextRoadmapMilestoneDate}`,
      description: { name: 'Next Roadmap Date', value: true }
    },
    {
      columnDef: 'roadmapMilestonecomments',
      header: 'Roadmap Milestone comment',
      width: '9%',
      cell: (element: any) => `${element.roadmapMilestonecomments}`,
      description: { name: 'Roadmap Milestone comment', value: true }
    },
    {
      columnDef: 'milestoneOwner',
      header: 'Milestone Owner',
      width: '10%',
      cell: (element: any) => `${element.milestoneOwner}`,
      description: { name: 'Milestone Owner', value: true }
    },
    {
      columnDef: 'decomCandidate',
      header: 'Decom Candidate',
      width: '10%',
      cell: (element: any) => `${element.decomCandidate}`,
      description: { name: 'Decom Candidate', value: true }
    },
    {
      columnDef: 'decomDate',
      header: 'Decom Date',
      width: '10%',
      cell: (element: any) => `${element.decomDate}`,
      description: { name: 'Decom Date', value: true }
    }
  ];

  ngOnChanges() {

    this.getFilterValues();
  }

  ngOnInit() {

    // this.getFilterValues();
  }

  getFilterValues() {
    this.subscription = this.service.filterValues().subscribe(value => {
      this.showLoader = true;
      this.modifyRequestData(value);
    });

  }

  modifyRequestData(value) {
    this.askidDetail = value.appFilter$ && value.appFilter$.map(i => i.app_id);
    this.getRoadmapTableDetail(value);
  }

  getRoadmapTableDetail(value) {
    this.body = {
      app_id: this.askidDetail,
      lob: value.lobLevelFilter$,
      organization: value.orgFilter$,
      product: value.productFilter$,
      product_family: value.productFamilyFilter$
    };
    this.service.getRoadmapProductData(this.body).subscribe(v => {
      this.finalData = [];
      v.forEach((d) => {
        const object = {};
        object['name'] = d.name || d.basicData[0].app_name;
        object['basicData'] = new MatTableDataSource(d.basicData);
        object['modernizationData'] = new MatTableDataSource(d.modernizationData);
        this.finalData.push(object);

      }
      );
      // this.pageOfItems = this.finalData;
      // console.log(this.pageOfItems);
      this.showLoader = false;
    });
  }

  onChangePage(pageOfItems) {
    // console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems.data;
  }

  editView(basicInfo) {
    let dd: any = {};
    dd = JSON.stringify(basicInfo);
    this.route.navigate([{ outlets: { primary: 'padu/app-edit' } }], { queryParams: { data: dd } });
  }



  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();

  }

}
