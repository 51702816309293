<div class="overlay-expanded-section">
    <div class="overlay-container">
      <span>Future PADU Roadmap</span>
    </div>
    <div class="overlay-structure">
      <div [style.width]="data.width" *ngFor="let data of editExpandConfig">
  
        <div *ngIf="!iseditExpand">
          <span class="future-padu-label">
            {{data.additionalHeader}}
  
          </span>
          <span [ngClass]="{
                  'adjust-badge': true,
                  'badge-padu-p': editExpandDataSource[0][data.columnDef] === 'Preferred',
                  'badge-padu-a':  editExpandDataSource[0][data.columnDef] === 'Acceptable',
                  'badge-padu-d':editExpandDataSource[0][data.columnDef] === 'Discouraged',
                  'badge-padu-u': editExpandDataSource[0][data.columnDef] === 'Unacceptable',
                  'badge-padu-r':editExpandDataSource[0][data.columnDef] === 'Retired',
                  'badge-padu-i':  editExpandDataSource[0][data.columnDef]=== 'Infrastructure',
                  'badge-padu-pp':editExpandDataSource[0][data.columnDef] === 'PADU Pending'}">
            {{(editExpandDataSource && editExpandDataSource[0][data.columnDef]) || ''}}
          </span>
  
        </div>
  
        <div *ngIf="iseditExpand">
  
  
          <div style="display: flex;">
            <span class="future-padu-label">
              {{data.additionalHeader}}
  
  
            </span>
            <span style="width: 70%;" [ngClass]="{
                      'add-padding': avalue!== 'PADU Pending',
                      'badge-padu-p': avalue === 'Preferred',
                      'badge-padu-a': avalue === 'Acceptable',
                      'badge-padu-d':avalue === 'Discouraged',
                      'badge-padu-u':avalue === 'Unacceptable',
                      'badge-padu-r': avalue === 'Retired',
                      'badge-padu-i': avalue=== 'Infrastructure',
                      'badge-padu-pp padu-pending-padding': avalue === 'PADU Pending'}"
              *ngIf="data.columnDef==='transition_1'">
              <span class="text-notavailable-option"
                *ngIf="(avalue=== 'Infrastructure' || avalue=== 'PADU Pending') && data.columnDef==='transition_1'">{{avalue}}</span>
              <mat-select [placeholder]="avalue ? '': 'PADU Status'"
                [ngClass]="!avalue? 'custom-dropdown padu-dropdown': 'mat-select-padu'" [(ngModel)]="avalue">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="{
                             'badge-padu-p': option.value === 'Preferred',
                             'badge-padu-a':option.value === 'Acceptable',
                             'badge-padu-d':option.value === 'Discouraged',
                             'badge-padu-u': option.value === 'Unacceptable',
                             'badge-padu-r': option.value === 'Retired',
                             'badge-padu-i': option.value === 'Infrastructure',
                             'badge-padu-pp': option.value === 'PADU Pending'}"> {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
  
            <span style="width: 70%;" class="add-padding" [ngClass]="{
                      'add-padding':  bvalue!== 'PADU Pending',
                      'badge-padu-p':bvalue === 'Preferred',
                      'badge-padu-a': bvalue === 'Acceptable',
                      'badge-padu-d':bvalue === 'Discouraged',
                      'badge-padu-u':bvalue === 'Unacceptable',
                      'badge-padu-r':bvalue === 'Retired',
                      'badge-padu-i':bvalue=== 'Infrastructure',
                      'badge-padu-pp padu-pending-padding': bvalue === 'PADU Pending'}"
              *ngIf="data.columnDef=='transition_2'">
              <span class="text-notavailable-option"
                *ngIf="(bvalue=== 'Infrastructure' || bvalue=== 'PADU Pending') && data.columnDef==='transition_2'">{{bvalue}}</span>
              <mat-select [placeholder]="bvalue ? '': 'PADU Status'"
                [ngClass]="!bvalue? 'custom-dropdown padu-dropdown': 'mat-select-padu'" [(ngModel)]="bvalue">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="{
                                 
                             'badge-padu-p': option.value === 'Preferred',
                             'badge-padu-a':option.value === 'Acceptable',
                             'badge-padu-d':option.value === 'Discouraged',
                             'badge-padu-u': option.value === 'Unacceptable',
                             'badge-padu-r': option.value === 'Retired',
                             'badge-padu-i': option.value === 'Infrastructure',
                             'badge-padu-pp': option.value === 'PADU Pending'}"> {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
  
            <span style="width: 70%;" [ngClass]="{
                      'add-padding':  thirdTransition!== 'PADU Pending',
                      'badge-padu-p':thirdTransition === 'Preferred',
                      'badge-padu-a': thirdTransition === 'Acceptable',
                      'badge-padu-d':thirdTransition === 'Discouraged',
                      'badge-padu-u':thirdTransition === 'Unacceptable',
                      'badge-padu-r': thirdTransition === 'Retired',
                      'badge-padu-i': thirdTransition=== 'Infrastructure',
                      'badge-padu-pp padu-pending-padding': thirdTransition === 'PADU Pending'}"
              *ngIf="data.columnDef=='transition_3'">
              <span class="text-notavailable-option"
                *ngIf="(thirdTransition=== 'Infrastructure' || thirdTransition=== 'PADU Pending') && data.columnDef==='transition_3'">{{thirdTransition}}</span>
              <mat-select [placeholder]="thirdTransition ? '': 'PADU Status'"
                [ngClass]="!thirdTransition? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
                [(ngModel)]="thirdTransition">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="{
                                 
                             'badge-padu-p': option.value === 'Preferred',
                             'badge-padu-a':option.value === 'Acceptable',
                             'badge-padu-d':option.value === 'Discouraged',
                             'badge-padu-u': option.value === 'Unacceptable',
                             'badge-padu-r': option.value === 'Retired',
                             'badge-padu-i': option.value === 'Infrastructure',
                             'badge-padu-pp': option.value === 'PADU Pending'}"> {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
  
            <span style="width: 70%;" [ngClass]="{
                      'add-padding':  fourthTransition!== 'PADU Pending',
                      'badge-padu-p':fourthTransition === 'Preferred',
                      'badge-padu-a': fourthTransition === 'Acceptable',
                      'badge-padu-d':fourthTransition === 'Discouraged',
                      'badge-padu-u':fourthTransition === 'Unacceptable',
                      'badge-padu-r': fourthTransition === 'Retired',
                      'badge-padu-i': fourthTransition=== 'Infrastructure',
                      'badge-padu-pp padu-pending-padding ':fourthTransition === 'PADU Pending'}"
              *ngIf="data.columnDef=='transition_4'">
              <span class="text-notavailable-option"
                *ngIf="fourthTransition=== 'Infrastructure' || fourthTransition=== 'PADU Pending'">{{fourthTransition}}</span>
              <mat-select [placeholder]="fourthTransition ? '': 'PADU Status'"
                [ngClass]="!fourthTransition? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
                [(ngModel)]="fourthTransition">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="{
                                 
                             'badge-padu-p': option.value === 'Preferred',
                             'badge-padu-a':option.value === 'Acceptable',
                             'badge-padu-d':option.value === 'Discouraged',
                             'badge-padu-u': option.value === 'Unacceptable',
                             'badge-padu-r': option.value === 'Retired',
                             'badge-padu-i': option.value === 'Infrastructure',
                             'badge-padu-pp': option.value === 'PADU Pending'}"> {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
  
            <span style="width: 70%;" [ngClass]="{
                      'add-padding':  fifthTransition!== 'PADU Pending',
                      'badge-padu-p':fifthTransition === 'Preferred',
                      'badge-padu-a': fifthTransition === 'Acceptable',
                      'badge-padu-d':fifthTransition === 'Discouraged',
                      'badge-padu-u':fifthTransition === 'Unacceptable',
                      'badge-padu-r': fifthTransition === 'Retired',
                      'badge-padu-i': fifthTransition === 'Infrastructure',
                      'badge-padu-pp padu-pending-padding ': fifthTransition === 'PADU Pending'}"
              *ngIf="data.columnDef=='transition_5'">
              <span class="text-notavailable-option"
                *ngIf="fifthTransition=== 'Infrastructure' || fifthTransition=== 'PADU Pending'">{{fifthTransition}}</span>
              <mat-select [placeholder]="fifthTransition ? '': 'PADU Status'"
                [ngClass]="!fifthTransition? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
                [(ngModel)]="fifthTransition">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="{
                                 
                             'badge-padu-p': option.value === 'Preferred',
                             'badge-padu-a':option.value === 'Acceptable',
                             'badge-padu-d':option.value === 'Discouraged',
                             'badge-padu-u': option.value === 'Unacceptable',
                             'badge-padu-r': option.value === 'Retired',
                             'badge-padu-i': option.value === 'Infrastructure',
                             'badge-padu-pp': option.value === 'PADU Pending'}"> {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
  
            <span style="width: 70%;" [ngClass]="{
                      'add-padding':  sixthTransition!== 'PADU Pending',
                      'badge-padu-p':sixthTransition === 'Preferred',
                      'badge-padu-a': sixthTransition=== 'Acceptable',
                      'badge-padu-d':sixthTransition === 'Discouraged',
                      'badge-padu-u':sixthTransition === 'Unacceptable',
                      'badge-padu-r': sixthTransition === 'Retired',
                      'badge-padu-i': sixthTransition === 'Infrastructure',
                      'badge-padu-pp padu-pending-padding': sixthTransition === 'PADU Pending'}"
              *ngIf="data.columnDef=='transition_6'">
              <span class="text-notavailable-option "
                *ngIf="sixthTransition=== 'Infrastructure' || sixthTransition=== 'PADU Pending'">{{sixthTransition}}</span>
              <mat-select [floatLabel]="'never'" [placeholder]="sixthTransition ? '': 'PADU Status'"
                [ngClass]="!sixthTransition? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
                [(ngModel)]="sixthTransition">
                <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                  <span class="add-padding" [ngClass]="{
                                 
                             'badge-padu-p': option.value === 'Preferred',
                             'badge-padu-a':option.value === 'Acceptable',
                             'badge-padu-d':option.value === 'Discouraged',
                             'badge-padu-u': option.value === 'Unacceptable',
                             'badge-padu-r': option.value === 'Retired',
                             'badge-padu-i': option.value === 'Infrastructure',
                             'badge-padu-pp': option.value === 'PADU Pending'}"> {{ option.label}}</span>
                </mat-option>
              </mat-select>
            </span>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  