import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from '../../../../environments/environment'

@Injectable()
export class AuthGuard  {
    globalRoute: any;
    globalState: any;
    routeURL;
    constructor(private router: Router, private AuthService: AuthService,) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.globalRoute = route;
        this.globalState = state;
        if (sessionStorage.getItem('token')) {
            // logged in so return true
            // console.log('token available');
            return true;
        }
        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login']);

        else if (environment.enableSSO) {
            Cookie.delete('access_token');
            Cookie.delete('expires_in');
            Cookie.delete('refresh_token');
            sessionStorage.clear();   
            localStorage.removeItem('MsId');
            localStorage.removeItem('lob');
            localStorage.removeItem('selectedSegment');
            localStorage.removeItem('isAdminRole');
            localStorage.removeItem('isWriteAccess');
            localStorage.removeItem('loggedInUserEmail');
            localStorage.removeItem('isModelEditor');
            localStorage.removeItem('userLastAction');
            localStorage.removeItem('selectedlob');
            localStorage.removeItem('landingPageContent');
            localStorage.removeItem('accessData');
            localStorage.removeItem('selectedsublob');
            localStorage.removeItem('flagAccess');
            localStorage.removeItem('config');
            localStorage.removeItem('currentUser');   
            return false;
        }
        else {
            this.router.navigate([{ outlets: { primary: 'login' } }]);
        }

    }
}
