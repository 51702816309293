<div class="reports main-div-with-gray-bkg" *ngIf="!reportNameFromQueryParam" [hidden]="!MSDLCOMPLETED">
  <div style="padding-left: 1.7%;padding-right: 1.2%;">
    <div class="row m-0">
      <div class="col-8" style="margin-top: 1%;">
        <!-- style="margin-left: 2%;" -->
        <mat-radio-group class="report-mat-radio" [(ngModel)]="reportFormat"
      (change)="reportFormatChange($event)" aria-label="Select an option">
        <label class="View-by-label">View By </label>
        <mat-radio-button color="primary" value='card'>Cards</mat-radio-button>
          <mat-radio-button color="primary" value='table'>List</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-4" style="margin-top: 1%;">
        <div class="breadcrumb-div">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/landing-page']">Home</a></li>
              <li class="breadcrumb-item active" [routerLink]="['/reports']"  aria-current="page" style="margin-top: 0.8%;cursor:pointer;">Reports</li>
              <li class="report-hub-icons">
                <button [attr.Pixel-tracking]="true" [attr.Pixel-title]="'Documentation'"
                class="uitk-icon uitk-icon-info_hollow md-icon report-filter-icons" title="Info" (click)="info()">
                </button>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="row m-0" style="margin-left: 0.7%;">
      <ng-container>
        <div class="table-search col-3">
          <input 
          class="report-search-option table-data-font search-box repo-search"
          (keyup)="searchDataSet($event)"
          placeholder="Search Report" />
        <img class="search_Icon" src="../../../../assets/images/searchicon.png"
          style="position: relative;" />
        </div>
      </ng-container>
      <div class="reportCategory category-margin col-3">
        <div style="width: 50%;">
          <span>Report Category :</span><span style="color: #013B71; margin-left: 0.3%;">{{reportsByCategory.length}}</span>
        </div>
        <!-- countRepo -->
        <div class="reportCount" style="width: 50%;">
          <span>No Of Reports :</span><span style="color: #013B71; margin-left: 0.3%;">{{reports.length}}</span>
        </div>
      </div>
      <!-- report-top-padding  -->
      <div class="accessNote col-6 p-0">
        <span style="background: #F1F172;">1st time users are required to request access to restricted reports.Hover on key icon or click on i icon for details</span>
      </div>  
    </div>
    
    
  </div>
  <div class="row p-3 m-0">
    <!-- style="margin-top: 5%; " -->
    <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 p-0" >
      <div class="card-table-gap p-0">
        <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 card-structure">
          <app-table-data *ngIf="dataLoad && reportFormat=='table'" [dataSource]="dataSource" [SelectAll]="false"
            [displaySelectAll]="false" [columns]="displayedColumns" [pageSize]="pageSizes" (datarefresh)="onDatarefresh($event)">
          </app-table-data>
          <div class="categorySection "*ngFor='let reportByCategory of reportsByCategory;'>
          <div class="cardForm" *ngIf="dataLoad && reportFormat=='card'">
            <div class="categoryTitle">
            <span class="card-heading  tabl-header-font tile-heading border-padding">{{reportByCategory[0].Category}}</span>
          </div>
            <div class="row" style="
            padding-bottom: 1.5%;">
              <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure" *ngFor='let report of reportByCategory;' >
                <div [attr.Pixel-tracking]="true" [attr.Pixel-title]="report.Report" class="card-container app-App" [title]="report.Description" (click)="navigateUrl(report)" >
                  <span [attr.Pixel-tracking]="true" [attr.Pixel-title]="report.Report" class="view-name report-Header-font" >{{report.Report}}</span> 
                   <div class="report-card-footer-bar report-card-footer-bar-height">   
                    <div class="footer-div-margin">
                      <p class="view-icon-report-type view-icon-report-type-margin report-footer-font">Type : {{report.Report_Type}}</p></div> 
                    <div class="reports-footer-div-margin">
                      <i class="uitk-icon uitk-icon-key sm-icon action-margin" *ngIf="report.Access_Restricted=='Yes'" 
                   [title]="showToolTip(report.Secure_group)"></i>
                   <i class="uitk-icon uitk-icon-copy sm-icon" (click)="copyToClipboard(report.Report_Link) ; $event.stopPropagation();" 
                   title="Copy to Clipboard"></i>
                   <i (click)="showDocumentionInfo(report.Documentation); $event.stopPropagation();" title="View Documentation"
                   class="iIcon-Font-size uitk-iconReport uitk-iconReport-info_hollow health"></i>
                   <i class="uitk-icon uitk-icon-edit sm-icon" *ngIf="reportHasLoggedInUserOwner(report)"
                   style="margin-left: 5.5px; position: sticky;"
                   (click)="editReport(report) ; $event.stopPropagation();"
                    title="Edit report" ></i></div>          
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <app-data-unavailable *ngIf="!dataLoad"></app-data-unavailable>
    </div>
  </div>
</div>
<div *ngIf="secureGrpRestriction?.length > 0  && reportNameFromQueryParam">
  <div class="pt-2 reports main-div-with-gray-bkg">
    <!-- class="height-separater" -->
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb embeded-breadcrum-padding">
          <li class="breadcrumb-item"><a [routerLink]="['/landing-page']">Home</a></li>
          <li class="breadcrumb-item" aria-current="page"><a [routerLink]="['/reports']"  style="padding-left: 4%;cursor:pointer">Reports</a></li>
          <li class="breadcrumb-item"></li>
          <li  aria-current="page" style="color: #6c757d;">
            {{ reportName }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="row p-3">
      <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 p-0">
        <div class="card-table-gap p-0">
          <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 card-structure">
            <div class="iframe" [innerHTML]="HTML"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>