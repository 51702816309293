
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { of } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Bookmark } from '../../../shared/interface/interface';
import { BookmarkServiceService } from '../../../shared/services/bookmark-service.service';
import { AppViewService } from '../../../shared/services/tcm-hub-appview.service';
import { SpinnerOverlayService } from '../../../spinner-overlay.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
    `
                .logoutCss{
                  height:auto !important
                }
                 .lob-maintenance, .add-model-maintenance{
                    display: flex;
                  }
                  .add-model-img{
                    height:25px;
                    width:1.95vw
                  }
                  .add-model-text{
                    font-size:14px;
                    margin-left:10px;
                  }
               
          
                .hideContent {
                  display: none;
                }
          
                .marginPos {
                  margin-left: 132px;
                  text-decoration: none !important;
                }
                .titleCenter{
                  margin-right: 32px !important;
                  text-decoration: none !important;
                }
                .bar1,
          .bar2,
          .bar3 {
            width: 35px;
            height: 5px;
            background-color: #fff;
            margin: 0.47vw 0;
            transition: 0.4s;
          }
          
          .change .bar1 {
            -webkit-transform: rotate(-45deg) translate(-0.7vw, 0.47vw);
            transform: rotate(-45deg) translate(-0.7vw, 0.47vw);
          }
          
          .change .bar2 {
            opacity: 0;
          }
          
          .change .bar3 {
            -webkit-transform: rotate(45deg) translate(-8px, -8px);
            transform: rotate(45deg) translate(-8px, -8px);
          }
          .menu-toggle {
            float: left;
            z-index: 999;
            margin-top: -9px;
          }
          
          .home-icon{
            width: 22px;
            height: 22px;
            margin-bottom: 6px;
          }
              `
  ]
})


export class HeaderComponent implements OnInit {
  public nodeEndUrl = environment.apiUrl;
  public userNameFullDetails;
  @Input() appName = 'TCM Hub';
  @Input() path: boolean = false;
  public tabIndex = 0;
  private inAppNavigation;
  text1: string = '';
  allBookmarkList: any;
  tcmBookmarkList: Array<any> = [];
  appBookmarkList: Array<any> = [];
  bookmarkList: Array<Bookmark> = [];
  visibleIndex = -1;
  bookmarkNewName: string = '';
  enablelogin: boolean = false;
  isAdminRole: boolean = environment.isAdminRole;
  acccess = {
    hasAppEditorSecureGrpAccess: false,
    hasModelEditorSecureGrpAccess: false,
    hasAdminSecureGrpAccess: false
  }
  isWriteAccess: string;
  constructor(
    private router: Router,
    private spinnerService: SpinnerOverlayService,
    private sharedService: SharedService,
    private route: Router,
    private bookService: BookmarkServiceService,
    private AppViewService: AppViewService,
    public dialog: MatDialog,
  ) {
   
    if (environment.enableSSO) {
      this.enablelogin = false;
    }
    else {
      this.enablelogin = true;
    }
    router.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationStart;
        })
      )
      this.route.events.subscribe((event: NavigationStart) => {
        if (event instanceof NavigationStart) {
          this.userNameFullDetails = JSON.parse(localStorage.getItem('currentUser'));
        }
      });

    this.sharedService.changeTabChange$.subscribe(data => {
      this.inAppNavigation = data;
      if (data.targetApp === 'app') {
        this.tabIndex = 1;
        this.onLinkClick({ index: this.tabIndex });
      } else {
        this.tabIndex = 0;
        this.onLinkClick({ index: this.tabIndex });
      }
    });

  }

  ngOnInit() {
    this.userNameFullDetails = JSON.parse(localStorage.getItem('currentUser'));
    if(this.userNameFullDetails)this.getBookmarkList();
    this.hasEditProductAccess();
    if (window.location.href.indexOf('/padu') > -1) {
      this.tabIndex = 1;
    }
  }
  hasEditProductAccess() {
    const msId = localStorage.getItem('MsId');
    const secureGroups = JSON.parse(localStorage.getItem('secureGroups'));
    msId && this.sharedService.getAccessData(msId, { 'secureGroups': secureGroups }).subscribe(accessData => {
      const { isModelEditor = false, isWriteAccess = false, isAdminRole = false,tiles={}} = accessData || {};
      localStorage.setItem('isModelEditor', isModelEditor);
      localStorage.setItem('isAdminRole', isAdminRole);
      localStorage.setItem('isWriteAccess', isWriteAccess);
      localStorage.setItem('tiles', JSON.stringify(tiles));
      this.acccess.hasAdminSecureGrpAccess = isAdminRole;
      this.acccess.hasAppEditorSecureGrpAccess = isWriteAccess;
      this.acccess.hasModelEditorSecureGrpAccess = isModelEditor;

      if (isAdminRole) {
        this.isAdminRole = true;
      }
      this.acccess.hasAdminSecureGrpAccess = this.isAdminRole;
    })
    this.sharedService.getAccessData(msId, { 'secureGroups': secureGroups }).subscribe(accessData => {
      const { data = {}} = accessData || {};
      localStorage.setItem('accessData', JSON.stringify(data));
    });

  }


  private navigateToRoute(route: string) {
    this.spinnerService.show();
    this.router.navigate([{ outlets: { primary: route, sidebar: route } }]);
  }
  getBookmarkList() {
    const msId = localStorage.getItem('MsId');
    this.tcmBookmarkList = [];
    this.appBookmarkList = [];
    this.bookService.getBookmarkList(msId).subscribe(data => {
      this.allBookmarkList = data;
      this.bookmarkList = this.allBookmarkList;
      this.allBookmarkList.forEach(item => {
        if (item.bookmarkType === 'tcm_hub') {
          this.tcmBookmarkList.push(item)
        } else {
          this.appBookmarkList.push(item)
        }
      });
    });
  }

  deleteBookmark(id: number, hubType: string) {
    if (confirm('Are you sure want to delete.')) {
      this.bookService.deleteBookmark(id).subscribe(() => {
        if (hubType === 'tcm') {
          this.tcmBookmarkList = this.tcmBookmarkList.filter(b => b.id !== id);
        } else {
          this.appBookmarkList = this.appBookmarkList.filter(b => b.id !== id);
        }
        this.allBookmarkList = this.allBookmarkList.filter(b => b.id !== id);
        this.bookmarkList = this.allBookmarkList;
      });
    }
  }
  public redirect(booklist) {

    // console.log(document.getElementById(booklist.id));
    // console.log( document.getElementById(booklist.id).children.length);
    // console.log( document.getElementById(booklist.id).children[0].tagName);
    if (document.getElementById(booklist.id)) {

      if (document.getElementById(booklist.id).children && document.getElementById(booklist.id).children.length && !(document.getElementById(booklist.id).children[0].tagName === 'INPUT')) {
        this.bookService.redirect(booklist);
      } else if ((document.getElementById(booklist.id).children.length === 0) && (document.getElementById(booklist.id).tagName === 'SPAN')) {
        this.bookService.redirect(booklist);
      }


    }
    //  if (document.getElementById(booklist.id) && ((document.getElementById(booklist.id).children) && !(document.getElementById(booklist.id).children[0].tagName === 'INPUT'))) {
    //   this.bookService.redirect(booklist)

    // }
    // else if (document.getElementById(booklist.id) && (document.getElementById(booklist.id).tagName === 'SPAN')) {

    //   this.bookService.redirect(booklist);
    // }
  }

  // console.log(document.getElementsByTagName('input'));
  // if(!document.getElementsByTagName('input') && !booklist.id ){
  //   this.bookService.redirect(booklist);
  // }



  public onanimation() {
    const data = Object.assign({}, this.inAppNavigation);
    if (data.params) {
      of(data)
        .pipe(delay(2000))
        .subscribe((da) => {
          // this.sharedService.emitChange(da.params);
          if (typeof da.params === 'string') {
            this.sharedService.emitChange(da.params);
          } else {
            let url = 'application-cost';
            if (da.view === 'lobView') {
              url = 'cio-cost';
            }
            this.router.navigate([url], { queryParams: da.params });
          }
        });
    }
    this.inAppNavigation = undefined;
    this.spinnerService.hide();
  }

  logout() {
    this.saveUserLogoutStats(localStorage.getItem('MsId'));
    setTimeout(() => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('MsId');
      localStorage.removeItem('isWriteAccess');
      localStorage.removeItem('token');
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('MsId');
      sessionStorage.removeItem('isWriteAccess');
      sessionStorage.removeItem('token');
      // this.router.navigate((["login"]));

      this.router.navigate([{ outlets: { primary: 'login', sidebar: 'tcmhub' } }]);

    }, 200);
  } // end logout function

  saveUserLogoutStats(msId: string) {
    const body = { logOut_at: new Date().toISOString() };
    this.sharedService.createUserStats(msId, body).subscribe(() => {
    });
  }

  onLinkClick(event: any) {
    this.spinnerService.show();
    of(event.index)
      .pipe(delay(20))
      .subscribe(data => {
        switch (data) {
          case 1:
            this.navigateToRoute('application-hub');
            break;
          default:
            this.navigateToRoute('portfolio-hub');
            break;
        }
      });
  }
  menutoggle(event) {
    event.target.classList.toggle('change');
  }
  redirectToHome() {
    this.route.navigate([{ outlets: { primary: 'landing-page', sidebar: null } }]);
    // this.route.navigate(['landingpage']);
  }

  adminSetting() {
    this.route.navigate([{ outlets: { primary: 'admin', sidebar: null } }]);
  }

  redirectToUserManual() {
    // window.open(`https://uhgazure.sharepoint.com/:w:/r/sites/technologycostmanagement/Shared Documents1/TechOps Hub User Manual.docx?d=w1e8d43b96e664522a02a35d2c1aeb2ae&csf=1&web=1&e=VckjXT`, '_blank');
    window.open(environment.docUrl);
  }

  redirectToUserGuide() {
    this.route.navigate([{ outlets: { primary: 'user-guide', sidebar: null } }]);
  }

  editClick(booklist, ind, hub) {

    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
    let element = document.getElementById(ind);
    if (hub === 'app') {
      element.innerHTML = this.escapeHTML(`<input id='${ind}' #${ind} style="width:80%; height:40px;  background-color: #F4F9FF;
     border-color: none !important; color : #196ECF" value='${booklist.name}'/>`);
      document.getElementById(ind).focus();
    } else {
      element.innerHTML = this.escapeHTML(`<input id='${ind}' #${ind} style="width:80%; height:40px;  background-color: #F4F9FF;
        border-color: none !important; color : #196ECF" value='${booklist.name}'/>`);
      document.getElementById(ind).focus();
    }


  }

  cancelChanges(booklist, ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
    let element = document.getElementById(ind);
    element.innerHTML = this.escapeHTML(`<span style="cursor: pointer;" (click)="redirect(booklist)"> ${booklist.name} </span>`);

    // let element = document.getElementsByTagName('input');
    // let txt = document.getElementById(ind).innerText;
    // console.log("txt", txt, ind);
    // element.innerHTML = `<input id='changed' style="width:60%;" [(ngModel)]='${this.bookmarkNewName}'  />`;
    //document.getElementById(ind).focus();
  }



  // saveEdit(text) {
  //   alert('Saved')
  //   let txt = document.getElementsByTagName("input")[0].value;
  //   console.log(document.getElementById('changed'));
  //   let element = document.getElementById('bookValue');
  //   element.innerHTML = `<span onclick='editClick(this)'> ${text} </span>`;
  // }

  saveChanges(bookmark, ind, hub) {
    let booknewname;
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }

    let txt = document.getElementsByTagName('input');
    // console.log(txt[0].value);
    booknewname = txt[0].value;
    if (hub === 'app') {


      let oldName;
      this.appBookmarkList.map(t => {
        if (t.id === bookmark.id && booknewname) {
          oldName = t.name;
          t.name = booknewname;

        }
        return t;
      });

      if (oldName !== booknewname) {

        this.bookService.updateBookmark(bookmark.id, { name: booknewname }).subscribe(() => {

        });

      }
      let element = document.getElementById(ind);
      element.innerHTML = this.escapeHTML(`<span style="cursor: pointer;" (click)="redirect(booklist)"> ${txt[0].value} </span>`);
    }
    else {
      let oldName;
      this.tcmBookmarkList.map(t => {
        if (t.id === bookmark.id && booknewname) {
          oldName = t.name;
          t.name = booknewname;

        }
        return t;
      });

      if (oldName !== booknewname) {

        this.bookService.updateBookmark(bookmark.id, { name: booknewname }).subscribe(() => {

        });

      }
      let element = document.getElementById(ind);
      element.innerHTML = this.escapeHTML(`<span style="cursor: pointer;" (click)="redirect(booklist)"> ${txt[0].value} </span>`);
    }
    // let newname = txt[0].value;



  }


  // RemoveEdit() {
  //   alert('Clear Value');
  // }
  contactfacilator() {
    if (confirm('Do you want to send mail to TCM facilitator for review?')) {
      const mailPromise = this.AppViewService.triggerhelpMail();
      mailPromise
        .then(function () {
          alert('An email has been sent to the TCM team, someone will reach out to you');
        })
        .catch(function () {
          alert('Error occured while sending the mail, please try again later');
        });
    }
  }


  navigateToLobMaintenance() {
    this.route.navigate([{ outlets: { primary: 'maintenance/lob' } }]);
  }

  navigateToOrgMaintenance() {
    this.route.navigate([{ outlets: { primary: 'maintenance/org' } }]);
  }

  navigateToBulkMaintenance() {
    this.route.navigate([{ outlets: { primary: 'maintenance/bulk' } }]);
  }

  navigateToGroupMaintenance() {
    this.route.navigate([{ outlets: { primary: 'maintenance/group' } }]);
  }
  escapeHTML =(unsafe_str) =>{
    return unsafe_str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/\//g, '&#x2F;')
  }
}


