<div class="uitk-c-card">
  <div>
    <div class="uitk-c-card__header"><h1 class="uitk-c-card__name uitk-u-h3">Access Management</h1></div>
    <div class="uitk-c-card__content">
      <div class="row">
        <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3"></div>
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
          <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && formsubmit(f)" novalidate>
            <app-input
              [isRequired]="true"
              label="Add User"
              name="userid"
              [formref]="f"
              id="input-1"
              [(ngModel)]="msid"
              [required]="true"
            ></app-input>
            <div class="uitk-form-control">
              <div class="input-label">
                <label for="input-2"
                  >Access<span class="uitk-icon uitk-icon-asterisk sm-icon uitk-c-label__icon" aria-hidden="true"></span
                  >:
                </label>
              </div>
              <div class="input-box">
                <label for="input-2"
                  ><input
                    name="access"
                    type="checkbox"
                    class="switch-input"
                    id="input-2"
                    required="true"
                    value="apphub"
                    [(ngModel)]="apphub"
                  /> AppHub</label
                > 
                <label for="input-3">
                  <input
                    name="access"
                    type="checkbox"
                    class="switch-input"
                    id="input-3"
                    required="true"
                    value="tcmhub"
                    [(ngModel)]="tcmhub"
                  /> TCMHub</label
                > 
                <label for="input-4">
                  <input
                    name="access"
                    type="checkbox"
                    class="switch-input"
                    id="input-4"
                    required="true"
                    value="ssrs"
                    [(ngModel)]="ssrs"
                  /> SSRS Repotrs</label
                > 
                <ng-container *ngIf="formcontrol && formcontrol?.invalid && formcontrol?.touched">
                  <ul class="help-block uitk-c-input__message--error">
                    <li *ngIf="formcontrol.hasError('required')">
                      this is required field.
                    </li>
                  </ul>
                </ng-container>
                <ng-container *ngIf="f?.submitted && !formcontrol?.touched">
                  <ul *ngIf="f.controls.access.hasError('required')" class="help-block uitk-c-input__message--error">
                    <li>
                      this is required field
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
            <button style="width:100%" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs uitk-btn ">
              Submit
            </button>
          </form>
        </div>
        <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3"></div>
      </div>
    </div>
  </div>
</div>

<div class="uitk-c-card" style="margin-top: 1%;">
  <div>
    <div class="uitk-c-card__header">
      <h1 class="uitk-c-card__name uitk-u-h3">Autherization list</h1>
      <!-- <i
        (click)="deletetcmBookmark(booklist.id)"
        class="uitk-icon uitk-icon-add sm-icon pull-right"
        style="cursor: pointer;background: #c25608;
        color: #fff;
        padding: 5px;
        border-radius: 50%;"
      ></i> -->
    </div>
    <div class="uitk-c-card__content">
      <table class="uitk-c-table uitk-c-table--responsive">
        <tr class="uitk-c-table__head">
          <th style="width:60%">CIO</th>
          <th style="width:10%;text-align: center">Read</th>
          <th style="width:10%;text-align: center">Write</th>
          <!-- <th style="width:10%;text-align: center">Delete</th> -->
        </tr>
        <tr class="rows uitk-c-table__row" *ngFor="let row of ciolist;">
          <td>{{ row.name}}</td>
          <td style="width:10%;text-align: center;cursor: pointer;">
            <input name="read" [(ngModel)]="row.r" (change)="changeper(row)"   type="checkbox" class="switch-input" />
          </td>
          <td style="width:10%;text-align: center;cursor: pointer;">
            <input name="write" [(ngModel)]="row.w" (change)="changeper(row)" type="checkbox"  class="switch-input" />
          </td>
          <!-- <td style="width:10%;text-align: center;cursor: pointer;">
            <i (click)="deletetcmBookmark(booklist.id)" class="uitk-icon uitk-icon-trash_delete sm-icon"></i>
          </td> -->
        </tr>
      </table>
    </div>
  </div>
</div>
