import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './login/auth/guards/auth.guard';
import { SidenavComponent } from './shared';

import { ErrorSecureGrpComponent } from './error-secure-grp/error-secure-grp.component';
import { RedirectGlobalProductViewComponent } from './product-mapping-view/redirect-global-product-view/redirect-global-product-view.component';
import { LandingPageV4Component } from './landing-page-v4/landing-page-v4.component';
import { ReleaseNoteV4Component } from './techopsv4/release-note-v4/release-note-v4.component';

const routes: Routes = [
  // { path: '',  redirectTo: 'landing-page',pathMatch: 'prefix' },
  // { path: 'landing-page', component: LandingPageComponent },
  { 
    path: 'login', 
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule) 
  },
  { 
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  { 
    path: '', 
    component: LandingPageV4Component,
    canActivate: [AuthGuard] 
  },
  { 
    path: 'landing-page', 
    component: LandingPageV4Component, 
    canActivate: [AuthGuard]
  },
  { 
    path: 'UHC-View', 
    component: RedirectGlobalProductViewComponent,
    canActivate: [AuthGuard],  
  },
  {
    path: 'tcmhub',
    component: SidenavComponent,
    outlet: 'sidebar'
  },
  { 
    path: 'tcmhub', 
    component: HomeComponent, 
    canActivate: [AuthGuard] 
  },

  { 
    path: 'error-NotPartSecure', 
    component: ErrorSecureGrpComponent,
    canActivate: [AuthGuard]  
  },
  {
    path: 'cio-app-insights',
    loadChildren: () => import('./padu-reporting/lob-owner/lob-owner.module').then(m => m.LobOwnerModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'product-home',
    loadChildren: () => import('./padu-reporting/capability/capability.module').then(m => m.CapabilityModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'uhcproduct-home',
    loadChildren: () =>
      import('./padu-reporting/product-mapping-view/product-mapping-view.module').then(m => m.ProductMappingViewModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'technology-insights',
    loadChildren: () =>
      import('./padu-reporting/technology-view/technology-view.module').then(m => m.TechnologyViewModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'decommission-app',
    loadChildren: () =>
      import('./padu-reporting/decommissioned/decommissioned.module').then(m => m.DecommissionedModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  { 
    path: 'release-note', 
    component: ReleaseNoteV4Component, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'tcmhubv3',
    component: SidenavComponent,
    outlet: 'sidebar'
  },
  {
    path: 'optimization',
    component: SidenavComponent,
    canActivate: [AuthGuard], 
    outlet: 'sidebar'
  },
  {
    path: 'optimization',
    loadChildren: () => import('./techopsv4/optimization/optimization.module').then(m => m.OptimizationModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'reclassification',
    component: SidenavComponent,
    canActivate: [AuthGuard], 
    outlet: 'sidebar'
  },
  {
    path: 'reclassification',
    loadChildren: () =>
      import('./techopsv4/reclassification/reclassification.module').then(m => m.ReclassificationModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
 
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'technology',
    loadChildren: () => import('./techopsv4/technology/technology.module').then(m => m.TechnologyModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'application-hub',
    loadChildren: () => import('./techopsv4/application-hub/app-hub-tabs.module').then(m => m.AppHubTabsModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'cost-view-service',
    loadChildren: () => import('./techopsv4/application-hub/cost-view-service-page/cost-view-service-page.module').then(m => m.CostViewServicePageModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'portfolio-hub',
    loadChildren: () => import('./techopsv4/portfolio-hub/portfolio-hub.module').then(m => m.PortfolioHubModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'maintenanceV4',
    loadChildren: () => import('./techopsv4/maintenance/maintenance-module').then(m => m.maintenanceModuleV4),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'work-queues',
    loadChildren: () => import('./techopsv4/work-queues/work-queues.module').then(m => m.WorkQueuesModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'workforce-budget',
    loadChildren: () => import('./workforce/workforce.module').then(m => m.WorkforceModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }