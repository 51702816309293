import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appModernizationDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { maintenanceV4APIs } from '../configV4';
import { configurableAttributesAPIs } from '../configV4';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
 

  public enabledInitiativeList = new BehaviorSubject <any>([]);
  public isEnabledInitiativesListSet: boolean = false;
  public addCapability = new Subject();
  public fundingSegment= new Subject();
  private apiUrl: string;
  private config: any;
  private configV4: any;
  private configAttribute: any;
  leaderList$ = new BehaviorSubject<any>({});

  constructor(private http: HttpClient,) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
    this.configV4 = maintenanceV4APIs;
    this.configAttribute = configurableAttributesAPIs;
  }

  setAddCapability(value) {
    this.addCapability.next(value);
  }
  getFundingListSubjectValues() {
    return this.fundingSegment;
  }

  setFundingSubjectValues(data) {
    this.fundingSegment.next(data);
  }

  /**
   * GetLookup's
   */
  // public GetLookUpValues(body): Observable<any> {
  //   const url = this.apiUrl + this.config.routerpath.GetLoBLookUp;
  //   return this.http.post(url, body).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }

  public getDecomLobAccess(msid): Observable<any> {
    //let allDecomLobAccess = [];
    //console.log(this.configV4.getDecomLobAccess);
    const url = this.apiUrl + this.configV4.getDecomLobAccess;
    const payload = {
     
    }
    return this.http
      .post<any>(url, payload, { params: { msid } })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public getDecomLobAccessDetails():Observable<any>{
    const url = this.apiUrl + this.configV4.getDecomLobDetails;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    ); 
  }

  public GetLobArchitectMappingList(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetLoBArchitectMapping;
    return this.http.get(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public SaveLobMapping(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.SaveLobMapping;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  public UpdateLobMapping(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdateLobMapping;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  public addNewUcmg(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addUcmg;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }
  /**
 * Delete Mappings
 */
  public DeleteMappings(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteLobMapping;
    let options: any = {};
    options['body'] = body;
    return this.http.delete(url, options).pipe(
      map(data => {
        return data;
      })
    );
  }

  public CreateLoB(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.Create_LoB;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  public CreateSubLoB(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.Create_Sub_LoB;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }
  
  public getOrganizationList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getOrgList;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public addNewOrganization(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getOrgList;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public editExistingOrganization(id, body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getOrgList+`/${id}`;
    return this.http.put(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public deleteOrganization(body, id): Observable<any> {
  const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.config.routerpath.getOrgList + `/${id}`;
    return this.http.delete<any>(url, options);
  }

  public deleteSubLOB(body): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.configV4.deleteSubLob;
    return this.http.put<any>(url, options);
  }

  public addLOB(body): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   }),
    //   body
    // }
    const url: string = this.apiUrl + this.configV4.addLob;
    return this.http.post<any>(url, body);
  }

  public getLobDecomMapping(LOB_ID): Observable<any> {
    const url = this.apiUrl + this.configV4.lobLeaderDecom;
    return this.http.get(url, { params : { LOB_ID } });
  }

  public saveLobDecomMapping(body): Observable<any> {
    const url = this.apiUrl + this.configV4.saveLobLeaderDecom;
    return this.http.post(url, body);
  }

  public getDecomLobMasterMapping() : Observable<any> {
    const url = this.apiUrl + this.configV4.getDecomLobMasterMapping;
    return this.http.get(url);
  }

  public updateDecomLob(SubLobID, body) : Observable<any> {
    const url = this.apiUrl + this.configV4.updateDecomLob;
    return this.http.post(url, body , { params : { SubLobID } });
  }
  
  public editSubLOB(body): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   }),
    //   body
    // }
    const url: string = this.apiUrl + this.configV4.addLob + '?type=sublob';
    return this.http.put<any>(url, body);
  }
  
  public editLLOB(body): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   }),
    //   body
    // }
    const url: string = this.apiUrl + this.configV4.addLob + '?type=lob';
    return this.http.put<any>(url, body);
  }
  
  public checkLOB(body): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   }),
    //   body
    // }
    const url: string = this.apiUrl + this.configV4.checkLob;
    return this.http.post<any>(url, body);
  }

  public lobBulkUpload(body): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    let options = {
      headers: headers
    }
    
  const url: string = this.apiUrl + this.configV4.bulklob;
  return this.http.post<any>(url, body, options).pipe(
    map(data => { return data; }));
  }

  public getCapitalDemandList(): Observable<any> {
    const url = this.apiUrl + this.configV4.fetCapitalDemandList;
    return this.http.get(url).pipe(
      map(data => {
        this.setFundingSubjectValues(data);
        return data;
      })
    );
  }

  public insertSegment(body): Observable<any> {
    const url = this.apiUrl + this.configV4.fetCapitalDemandList;
    return this.http.post(url, body);
  }

  public DeleteSegment(body): Observable<any> {
    const url = this.apiUrl + this.configV4.fetCapitalDemandList + '/' + body;
    return this.http.delete(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public editSegmentById(body, segmentId): Observable<any> {
    const url = this.apiUrl + this.configV4.fetCapitalDemandList + '/' + segmentId;
    return this.http.put(url, body);
  }

  public getSegmenById(segmentId) :Observable<any>{
    const url = this.apiUrl + this.configV4.fetCapitalDemandList + '/' + segmentId;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getCapitalDemandInvestmentList(): Observable<any> {
    const url = this.apiUrl + this.configV4.fetchCapitalDemandInvestmentList;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public deleteCapitalDemand(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.deleteDemand;
    return this.http.post<any>(url,body);
  }

  public deleteUcmgLeader(params): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params
    }
    const url: string = this.apiUrl + this.config.routerpath.capitalDemandLeader;
    return this.http.delete<any>(url, options);
  }
  public editExistingLeader(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capitalDemandLeader;
    return this.http.put(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public addNewLeader(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addCapitalDemandLeader;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }
  
  public editUcmg(body,id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addUcmg+`/${id}`;
    return this.http.put(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  getLeaderListSubjectValues() {
    return this.leaderList$;
  }

  public getEnterpriseInitiatives(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.initiativeListUrl;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
  public setEnabledInitiativesData() {
    this.getEnterpriseInitiatives().subscribe((data: any) => {
      if (data && data.length > 0) {
        this.isEnabledInitiativesListSet = true;
        let enabledInitiativeList: Array<any> = data.filter((val) => {
          val.name = val.Initiative_Name;
          if (val.Enabled == 'Yes')
            return val;
        });
        if (enabledInitiativeList && enabledInitiativeList.length > 0) {
          this.enabledInitiativeList.next(enabledInitiativeList);
        }
      }
    }, error => {
      console.error('error', error);
    });
  }
  public getEnabledInitiativesRoleList(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.initiativeRoleListUrl+'/'+payload.initiativeID;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
  public updateEIRole(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addEIRoleUrl;
   return this.http.post(url,payload)
   .pipe(
     map(data => {
       return data;
     })
   );
  }
 
  public getEIRoles(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.eiRoleListUrl+'/'+payload.initiativeID;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
  getFCServiceDtl(reqparams) {
    const url = this.apiUrl + this.configV4.fcServiceListUrl;
    return this.http.get(url,{params:reqparams}).pipe(
      map(data => {
        return data;
      })
    );
  }


  updateFCDetails(reqPayload) {
    const url = this.apiUrl + this.configV4.updateFCurl;
    return this.http.post(url,reqPayload)
    .pipe(
      map(data => {
        return data;
      })
    );
  }
 

  
  public getLOBList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getLobListUrl;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  
  setLeaderListSubjectValues(data) {
    this.leaderList$.next(data);
  }

  public getLeaderList(payload){
     const url = this.apiUrl + this.config.routerpath.capitalDemandLeader;
    return this.http.post(url,payload)
    .pipe(
      map(data => {
        this.setLeaderListSubjectValues(data);
        return data;
      })
    );
  }

  public getFundingAccessDetails(segId) {
    let isFundingOwnerAdmin = localStorage.getItem('isFundingOwnerAdmin');
    let fundingAccessData  = JSON.parse(localStorage.getItem('fundingAccess'));
    if (isFundingOwnerAdmin && fundingAccessData) {
      let fundingRoles = fundingAccessData.map((row) => row['User_Role']);
      if (segId == ''){
        return isFundingOwnerAdmin == 'true' && fundingRoles.includes('Funding_Admin');
      }
      else {
        let SegmentAccess = fundingAccessData.map(row => {
          if (['Funding_Owner', 'Funding_Delegate'].includes(row['User_Role'])) {
            return row['Funding_SegmentID'];
          }
        });
        return (isFundingOwnerAdmin == 'true' && SegmentAccess.length > 0) ? SegmentAccess : [];
      }
    }
    return '';
  }
  public getCIOMapping(body): Observable<any>{
    const url = this.apiUrl + this.configV4.fetchCIOMappingList;
    return this.http.post(url,body).pipe(
      map(data => {
        return data;
      })
    );
  }
  public addRemoveUsers(body): Observable<any>{
    const url = this.apiUrl + this.configV4.addRemoveUsers;
    return this.http.post(url,body).pipe(
      map(data => {
        return data;
      })
    );
  }
  public getForecastFilters() {
    const url = this.apiUrl + this.configV4.getForecastFilters;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
  public getForecastDetails(body) {
    const url = this.apiUrl + this.configV4.getForecastDetails;
    return this.http.post(url,body).pipe(
      map((data:Array<any>) => {
        if(data ){
          data.map((val,index)=>{
            val = this.getAggregatedDataForForecast(val);
          })
        }
        return data;
      })
    );
  }

  getAggregatedDataForForecast(val){
    val['Prev_FY_Total'] = 0;
    val['Current_Projections_Total']=0;
    val['Curr_Year_FC_Prev_Charges_Total']=0;
    val['Curr_Year_FC_Curr_Charges_Total']=0;
    val['Future_Year_FC_Prev_Charges_Total']=0;
    val['Future_Year_FC_Curr_Charges_Total']=0;
    
    val.services.forEach((el)=>{
      if(el.charges && el.charges.Prev_Year_FY_Charges != null){
        val['Prev_FY_Total'] += el.charges.Prev_Year_FY_Charges;
      }
      if(el.charges && el.charges.Infra_projected_Charges != null){
        val['Current_Projections_Total'] += el.charges.Infra_projected_Charges;
      }
      if(el.charges && el.charges.Curr_Year_FC_Prev_Charges != null){
        val['Curr_Year_FC_Prev_Charges_Total'] += el.charges.Curr_Year_FC_Prev_Charges;
      }
      if(el.charges && el.charges.Curr_Year_FC_Curr_Charges != null){
        val['Curr_Year_FC_Curr_Charges_Total'] += el.charges.Curr_Year_FC_Curr_Charges;
      }
      if(el.charges && el.charges.Future_Year_FC_Prev_Charges != null){
        val['Future_Year_FC_Prev_Charges_Total'] += el.charges.Future_Year_FC_Prev_Charges;
      }
      if(el.charges && el.charges.Future_Year_FC_Curr_Charges != null){
        val['Future_Year_FC_Curr_Charges_Total'] += el.charges.Future_Year_FC_Curr_Charges;
      }
      el['ask_global_id'] = val['ask_global_id'];
    })
    return val;
  }

  addApplicationIdInVolServices(arr:Array<any> = [],id){
    arr.forEach(element => {
      element['ask_global_id'] = id;
    });
    return arr;
  }

  public getForecastMasterData(){
    const url = this.apiUrl + this.configV4.getForecastMasterData;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
  public getServicesList() {
    const url = this.apiUrl + this.configV4.getServicesList;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public addService(body) {
    const url = this.apiUrl + this.configV4.addService;
    return this.http.post(url,body).pipe(
      map(data => {
        return data;
      })
    );
  }
  public getMasterCardStats(){
    const url = this.apiUrl + this.configV4.getMasterCardStats;
    return this.http.get(url).pipe(
      map(data=>{
        return data
      })
    );
  }

  public getForecastCyclesData(){
    const url = this.apiUrl + this.configV4.getForecacastData;
    return this.http.get(url).pipe(
      map(data =>{
        return data;
      })
    );
  }
  public getApplicationStats(body){
    //debugger;
    const url = this.apiUrl + this.configV4.getApplicationStats;
    return this.http.post(url,body).pipe(
      map(data=>{
        return data;
      })
    )
  }

  public addForecastCycle(body) {
    const url = this.apiUrl + this.configV4.addForecast;
    return this.http.post(url,body).pipe(
      map(data => {
        return data;
      })
    );
  }

  updateForecastMaster(body) {
    const url = this.apiUrl + this.configV4.updateForecastMaster;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    )
  }

  public getFieldData(body): Observable<any>{
    const url = this.apiUrl + this.configV4.getFieldData + '?initiativeID=' + body.initiativeID + '&activeFlag=' + body.activeFlag;
    return this.http.get(url,body).pipe(
      map(data=>{
        return data;
      })
    )
  }

  public enabledFields(body): Observable<any> {
    const url = this.apiUrl + this.configAttribute.enableFieldUrl;
    return this.http.post(url,body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getAllInitiativeRoles(payload) : Observable<any> {
    const url = this.apiUrl + this.config.routerpath.allInitiativeRolesURl+'/'+payload.initiativeID;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
    
  public updateColorderForInitiatives(payload,initiativeID): Observable<any> {
    const url = this.apiUrl + this.configV4.updateColumnOrder + '/reorder-column?initiativeID=' + initiativeID;
   return this.http.put(url,payload)
   .pipe(
     map(data => {
       return data;
     })
   );
  }
  public updateFieldForInitiatives(payload): Observable<any> {
    const url = this.apiUrl + this.configV4.updateField;
   return this.http.post(url,payload)
   .pipe(
     map(data => {
       return data;
     })
   );
  }

  public getAllUsedWords(payload) : Observable<any> {
    const url = this.apiUrl + this.configV4.getAllUsedWords+'/'+payload.initiativeID + '?columnId=' + payload.columnID;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getForecastOwnerDetails(): Observable<any> {
    let url = this.apiUrl + this.config.routerpath.fcOwnerDetails;
    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    let options = {
      headers: headers
    }
    return this.http.get<any>(url, options);
}
}





