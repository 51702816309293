
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from 'src/app/shared';
import { MatPaginator } from '@angular/material/paginator';
import { AppHubService } from '../../../app-hub.service';
@Component({
  selector: 'app-editable-table-expand',
  templateUrl: './editable-table-expand.component.html',
  styleUrls: ['./editable-table-expand.component.css']
})
export class EditableTableExpandComponent implements OnInit {
  dataSource!: MatTableDataSource<any>;
  selection = new SelectionModel<PeriodicElement>(true, []);
  selectionSharing = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  capabilityID: any;
  applicationDetails: any;
  viewClickLoader: boolean = false;
  model: any;
  OrganizationID: any;
  app_id: string;
  @Input() orgData:any;
  public pageSize =  [5,10,15];

  displayedColumns = [
    {
      columnDef: 'ApplicationID',
      header: 'ApplicationID',
      width: '20%',
      textAlign:'left',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => `${element.ApplicationID}`
    },
    {
      columnDef: 'ApplicationName',
      header: 'ApplicationName',
      width: '40%',
      textAlign:'left',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'test': element.ApplicationName
        };
      },
      cell: (element: any) => `${element.ApplicationName}`
    },
    {
      columnDef: 'CapabilityPADU',
      header: 'Capability PADU',
      width: '13%', 
      textAlign:'center',     
      cell: (element: any) => `${element.CapabilityPADU}`
    }];
  capability: any;
  organisationName: any;


  constructor(private appViewService: AppHubService) {
   
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.getOrgData()
  }

  getOrgData() {
    this.capabilityID = this.orgData.capabilityID;
    this.model = this.orgData.model
    this.OrganizationID = this.orgData.OrganizationID
    this.app_id = this.orgData.app_id
    this.capability=this.orgData.capability
    this.organisationName=this.orgData.organisationName
    this.viewClickLoader = true;
    this.appViewService.getOrganisationApplicationDetails(this.capabilityID, this.model, this.OrganizationID, this.app_id).subscribe(app => {
      this.applicationDetails = app;
      this.dataSource = new MatTableDataSource(this.applicationDetails);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function (record, filter) {
        return record.ApplicationName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
      };
      this.viewClickLoader = false;
    });
  }

  searchDataSet(event) {
    const filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

