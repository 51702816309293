import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digitFormatter'
})
export class DigitFormatterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    if (typeof value !== 'number') {
      value = Number(value) // or handle it as needed
    }
    const si = [{ value: 1, symbol: '' }, { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'B' }];

    let i;
    let valueType = args[1] || 'cost'
    if(value == 0) {
      return value;
    }
    if(value > 0 && value < 1000) {
      if(valueType == 'app') {
        return value;
      } else {
        return value.toFixed(args[0]);
      }
      
    }
    for (i = si.length - 1; i > 0; i--) {
      if (value >= si[i].value) {
        break;
      }
    }
    return (value / si[i].value).toFixed(args[0]) + si[i].symbol;
  }

}
