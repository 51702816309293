import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { MatOption } from '@angular/material/core';
import {  filter } from 'rxjs/operators';

@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.css']
})
export class SelectMultipleComponent implements OnInit {
  @ViewChildren(MatOption)
  options: QueryList<MatOption>;
  toppings = new FormControl();
  @Input() optionList: any[] = [];
  @Input() selected: any = [];
  @Input() type:any;
  @Output() selectChanges = new EventEmitter<any>();

  public selectedTexts: string[] = [];
  public search:string=''
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  public checked:boolean=false;
  public multiSelectControl = new FormControl();
  constructor(private cd: ChangeDetectorRef, readonly sd: ScrollDispatcher) {
  }
  ngOnChanges(){
   this.loadDefault();
  }

ngAfterViewInit(): void {
  this.sd
    .scrolled()
    .pipe(filter(scrollable => this.cdkVirtualScrollViewPort === scrollable))
    .subscribe(() => {
      let needUpdate = false;

      this.options.forEach(option => {
        const selected = this.selected.includes(option.value);

        if (selected && !option.selected) {
          option.select();
          needUpdate = true;
        } else if (!selected && option.selected) {
          option.deselect();
          needUpdate = true;
        }
      });

      if (needUpdate) {
        this.cd.detectChanges();
      }
    });
}
ngOnInit(){
this.loadDefault();
}
foropen() {
  this.cdkVirtualScrollViewPort.scrollToIndex(5);
}

selectAll(value) {
  if(value==false){
        this.checked=true;
        this.selected = Array.from(this.optionList);
        this.multiSelectControl.patchValue(this.optionList);
        this.displaySelectedTexts();
        this.onUpdateSelected(true);
      }
      else{
        this.checked=false;
        this.selected = [];
        this.multiSelectControl.patchValue([]);
        this.displaySelectedTexts();
        this.onUpdateSelected(false);
      }
}
loadDefault(){
  const selected = this.optionList.filter((option) => this.selected.some(select => option.name === select.name ))
  this.selected = Array.from(selected);
  this.multiSelectControl.patchValue(this.selected);
  this.displaySelectedTexts();
}

clear() {
  this.selected = [];
  this.multiSelectControl.patchValue([]);
}

openChange($event: boolean) {
  if ($event) {
    this.foropen();
    this.cdkVirtualScrollViewPort.scrollToIndex(0);
    this.cdkVirtualScrollViewPort.checkViewportSize();
    this.displaySelectedTexts();
  }
}

onSelectionChange(change): void {
  if (!change.isUserInput) {
    return;
  }
  const value = change.source.value;
  const idx = this.selected.indexOf(change.source.value);
  if (idx > -1) {
    this.selected.splice(idx, 1);
  } else {
    this.selected.push(value);
  }
  this.displaySelectedTexts();
  this.onUpdateSelected(false);
}
displaySelectedTexts() {
  this.selectedTexts = this.selected.map(x => x.name);
  
}
onUpdateSelected(value){
  let filterType={}
  if(value==true){
    filterType={
      type:this.type,
      values:this.selectedTexts,
      selectAll:true
    }
  }
  else{
    filterType={
      type:this.type,
      values:this.selectedTexts,
      selectAll:false
    } 
  }
  this.selectChanges.emit(filterType)
}
}
