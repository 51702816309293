import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  msid: any;
  apphub: any;
  tcmhub: any;
  ssrs: any;
  ciolist: any;
  formcontrol: any;
  btnClickEventVar:any;
  formSubmitEventvar:any;
  changePerEventVar:any;

  constructor() { }

  ngOnInit() {
    this.ciolist = [
      { name: 'Enterprise Clinical Services', w: true, r: true },

      { name: 'Corporate Systems', w: true, r: true },

      { name: 'Optum Digital', w: true, r: true },

      { name: 'EEPS', w: true, r: true },

      { name: 'Emerging Business', w: true, r: true },

      { name: 'OptumCare Tech', w: true, r: true },

      { name: 'Optum Ops & Corp', w: true, r: true },

      { name: 'Optum Financial Services', w: true, r: true },

      { name: 'Optum International', w: true, r: true },

      { name: 'OptumInsight PE', w: true, r: true },

      { name: 'OptumRx', w: true, r: true },

      { name: ' UHC Government', w: true, r: true },

      { name: 'UHC E&I', w: true, r: true },

      { name: 'UHC Global', w: true, r: true },

      { name: 'UHC LCRA', w: true, r: true },

      { name: 'UHC CORE Services', w: true, r: true }
    ];
  }

  buttonclick(event:any) {
    this.btnClickEventVar = event;
  }

  formsubmit(form:any) {
   this.formSubmitEventvar = form;
  }

  changeper(event:any) {
   this.changePerEventVar = event;
  }
}
