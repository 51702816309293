<div class="lookup-editor-tab">
    <div class="stk-table-loader show-loader" *ngIf="showLoader">
        <app-loader>
        </app-loader>
    </div>
    <section class="lookupEditorSection">
        <div class="selectLookupTable row">
            <mat-form-field appearance="outline" class="custom-dropdown col-3">
                <input style="border:none;width:100%" class="edit text-notavailable-option option-size" />
                <mat-select placeholder="Select Table" [(ngModel)]="selectedLookupTable" (change)="selectChange()"
                    class="lookupTabledropdown">
                    <mat-option class="dropDownList" *ngFor="let item of lookupTables" [value]="item.id">
                        <ng-container>{{item.name}}</ng-container>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="col-2" *ngIf="selectedLookupTable == 'LOOKUP_NO_HRY'">
                <button class="btn btn-primary add-btn-lookup" (click)="addNoHry()">Add Attribute</button>
            </div>
            <div class="col-2" *ngIf="selectedLookupTable == 'LOOKUP_WITH_HRY'">
                <button class="btn btn-primary add-btn-lookup" (click)="addHry()">Add Attribute</button>
            </div>
        </div>
        <ng-container *ngIf="selectedLookupTable == 'WQ_MSTR'">
            <app-admin-table-data [columns]="WQ_MSTR_COL" [dataSource]="wq_dataSource"
                [displayedColumns]="wq_displayedColumns" [tableName]="'WQ_MSTR'"
                (editWorkQueue)="editWorkQueue($event)"></app-admin-table-data>
        </ng-container>
        <ng-container *ngIf="selectedLookupTable == 'APP_ATTR'">
            <app-admin-table-data [columns]="APP_ATTR_COL" [dataSource]="app_attr_dataSource"
                [displayedColumns]="app_attr_displayedColumns" [tableName]="'APP_ATTR'"
                (editWorkQueue)="editWorkQueue($event)"></app-admin-table-data>
        </ng-container>
        <ng-container *ngIf="selectedLookupTable == 'LOOKUP_NO_HRY'">

            <app-admin-table-data [columns]="LOOKUP_NO_HRY_COL" [dataSource]="lookup_no_hry_dataSource"
                [displayedColumns]="lookup_no_hry_displayedColumns" [tableName]="'LOOKUP_NO_HRY'"
                (editWorkQueue)="editWorkQueue($event)"></app-admin-table-data>
        </ng-container>
        <ng-container *ngIf="selectedLookupTable == 'LOOKUP_WITH_HRY'">

            <app-admin-table-data [columns]="LOOKUP_WITH_HRY_COL" [dataSource]="lookup_with_hry_dataSource"
                [displayedColumns]="lookup_with_hry_displayedColumns" [tableName]="'LOOKUP_WITH_HRY'"
                (editWorkQueue)="editWorkQueue($event)"></app-admin-table-data>
        </ng-container>

    </section>
    <app-modal [maximizable]="true" #editDataPopup class="modal-demo lookup-editor-section-modal"
        (closeModal)="closeModal(tableName)">
        <ng-container class="app-modal-header">
            <div style="display: flex;width: 100%;">
                <div style="width: 98%;">
                    {{popupHeader}}
                </div>
            </div>
        </ng-container>
        <ng-container class="app-modal-body">
            <app-edit-data-popup [dataToEdit]="dataToEdit" [editFlag]="editFlag"
                (emitReqPayload)="emitReqPayload($event)" [formfields]="formfields" [tableName]="tableName"
                [closePopup]="closePopup"></app-edit-data-popup>
        </ng-container>
    </app-modal>

</div>