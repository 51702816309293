import {AfterViewInit, Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dailog-modal',
  templateUrl: './dailog-modal.component.html',
  styleUrls: ['./dailog-modal.component.css']
})
export class DailogModalComponent implements OnInit,AfterViewInit {
  Remarks: any;
  disableContinue: boolean=true;
  remarksCharsLeft:any;
  displaySave: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<DailogModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data.displaySave == false)
    this.displaySave = data.displaySave;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if(document.getElementsByClassName('click-here-txt')){
      let el = document.getElementsByClassName('click-here-txt')[0]; //on removing associate capability from app hub
      if (el) {
        el.id= "capability-proposal-1";
        document.getElementById('capability-proposal-1').onclick = (e) => this.navigateToCapabilityDetails(e);
      }
    }
  }

  onSave() {
    this.dialogRef.close({ data: "save" ,remarks:this.Remarks});
  }
  onCancel() {
    this.dialogRef.close({ data: "cancel" });
  }
  getContinueButtonflag(){
    this.remarksCharsLeft=300-this.Remarks.length

     if(this.Remarks.length>0){
      this.disableContinue = false;
    }  else {
      this.disableContinue = true;
    }
  }

  navigateToCapabilityDetails(ev){
    this.dialogRef.close({ data: "openWQopenRequestForCapabilityProposal"});
  }
}
