import { Component, Injectable, Input, EventEmitter, Output } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { AppViewService } from 'src/app/padu-reporting/application-view/app-view.service';
import { AppHubService } from 'src/app/techopsv4/services/appHubV4Service';

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}


/**
 * The Json object for to-do list data.
 */

// const TREE_DATA = {
//   'Administer Benefits & Services(L0)': {

//     'Manage Claims - Optum(L1)': {
//       'Adjustments Applied - Optum(L2)': null,

//     }
//   },
//   'Manage Demand - Optum(L0)': {

//     'Manage Claims2 - Optum(L1)': {
//       'Adjustments Applied2 - Optum(L2)': null,

//     }
//   },
//   'Direct & Guide the Organization(L0)': {
//     'Manage Claims3 - Optum(L1)': {
//       'Adjustments Applied3 - Optum(L2)': null,

//     }
//   }
// }

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  TREE_DATA;

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor(private service: AppViewService,private appHubService: AppHubService) {
    this.appHubService.getTreeStructureDropdownValues().subscribe(d => {
      this.TREE_DATA = d;
      if (this.TREE_DATA) this.initialize();
    });
  }



  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(this.TREE_DATA, 0);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: object, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];

      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-tree-checkbox',
  templateUrl: './tree-checkbox.component.html',
  styleUrls: ['./tree-checkbox.component.css'],
  providers: [ChecklistDatabase]
})
export class TreeCheckboxComponent {
  searchString: any;
  @Input() TreeCheckboxDataSource = [];
  selectedCapabilityList = [];
  @Input() selectedCapability;
  @Input() List;
  @Input() multiple = false;
  @Input() openWQRequest = [];
  selectedHierarchy = {};
  listOfParent = {};
  childArr = [];
  ar = [];
  dynamicCapabilityList = [];
  // List = ['Enterprise Tax Management - UHG','Benefit Validation - C&S', 'Activity Monitoring - Optum','1099 Generation - C&S']
  @Output() selectedTreeData: EventEmitter<any> = new EventEmitter();
  @Output() multipleSelectedData: EventEmitter<any> = new EventEmitter();
  @Output() showOpenRequest: EventEmitter<any> = new EventEmitter();

  capSelection: boolean = false;
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  constructor(private database: ChecklistDatabase, private service: AppViewService,private appHubService: AppHubService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.sortHierarchy(data)
      this.dataSource.data = data;
      if (this.selectedCapability) {
        this.treeControl.dataNodes.filter(d => {
          if (d.item === this.selectedCapability) {
            this.todoItemSelectionToggle(d);
          }

        })
      }


    });


  }

  ngOnChanges() {
    this.selectedCapabilityList = [];
    if (this.selectedCapability) {
      this.treeControl.dataNodes.filter(d => {
        if (d.item.split('^')[0] === this.selectedCapability) {
          this.todoItemSelectionToggle(d);
        }


      })
    }
    this.appHubService.getTreeStructureDropdownValues().subscribe(d => {

      this.database.TREE_DATA = d;
      let data = this.dataSource.data;
      this.sortHierarchy(data)
      this.dataSource.data = data;
      if (d) this.database.initialize();

    });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {

    const descendants = this.treeControl.getDescendants(node);

    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));

    return result && !this.descendantsAllSelected(node);

  }

  //  single select value from mat tree
  treeNodeOptionClick(node) {
    const tempMyObj = Object.assign({}, this.getAllParent(node, node.level));
    const latestKey = 'capabilityL' + node.level;
    tempMyObj[latestKey] = node.item.split('^')[0];
    this.selectedTreeData.emit(this.addAllKeysObject(tempMyObj));
    if(this.openWQRequest.includes(node.item.split('^')[0])){
      this.showOpenRequest.emit(node.item.split('^')[0])
    }
  }

  addAllKeysObject(obj) {
    delete obj['capabilityL-1']
    if (!obj['capabilityL1']) {
      obj['capabilityL1'] = null
    }

    if (!obj['capabilityL2']) {
      obj['capabilityL2'] = null
    }

    if (!obj['capabilityL3']) {
      obj['capabilityL3'] = null
    }
    return obj;
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);


    // this.checklistSelection.isSelected(node)
    //   ? this.checklistSelection.select(...descendants)
    //   : this.checklistSelection.deselect(...descendants);

    if (this.checklistSelection.isSelected(node)) {
      if (this.multiple) {
        const val = this.getAllParent(node, node.level);
        const tempMyObj = Object.assign({}, val);
        const latestKey = 'capabilityL' + node.level;
        tempMyObj[latestKey] = node.item.split('^')[0];
        this.selectedCapabilityList = this.selectedCapabilityList.concat(tempMyObj)
        // this.getFinalHierarchy(node);
        // Add the other capability key if not present in array
        this.selectedCapabilityList = this.selectedCapabilityList.map(d => {
          delete d['capabilityL-1']
          if (!d['capabilityL1']) {
            d['capabilityL1'] = null
          }
          if (!d['capabilityL2']) {
            d['capabilityL2'] = null
          }

          if (!d['capabilityL3']) {
            d['capabilityL3'] = null
          }
          return d
        })
      }
    }
    else {
      // If unselected then remove from the existing selected hierarchy array
      const key = 'capabilityL' + node.level;
      this.selectedCapabilityList = this.selectedCapabilityList.filter(d => d[key] !== node.item.split('^')[0])
    }
    if (this.multiple) {
      this.multipleSelectedData.emit(this.selectedCapabilityList);
    }
    // this.selectedTreeData.emit(this.checklistSelection.selected.filter(d=> d.level == 2))
  }

  fetchAssocLevel(node) {
    if (this.getLevel(node) > 0)
      return `(L${this.getLevel(node)})`
  }


  // get all the parent of the specific node
  getAllParent(node, level) {
    if (level < 0) {
      return this.listOfParent
    }
    else {
      const obj = this.getParent(node);
      const key = 'capabilityL' + (this.getLevel(node) - 1)
      this.listOfParent[key] = obj && obj.item.split('^')[0];
      return this.getAllParent(obj, level - 1)
    }

  }

  // get all the children of the specific node
  getAllChildren(node, element) {

    const val = this.getAllParent(node, node.level);
    const tempMyObj = Object.assign({}, val);
    const latestKey = 'capabilityL' + (node.level);

    tempMyObj[latestKey] = node.item;
    const latestKey1 = 'capabilityL' + (node.level + 1);

    tempMyObj[latestKey1] = element.item;
    this.selectedCapabilityList = this.selectedCapabilityList.concat(tempMyObj)
  }


  // get all capability level hierarchy 
  getFinalHierarchy(node) {

    if (this.flatNodeMap.get(node) && this.flatNodeMap.get(node).children) // Check if selected node has children array
    {
      let child = this.flatNodeMap.get(node).children // get all the children of the selected node
      child.forEach(element => { // loop through all the children 
        if (this.getChildren(element)) { // If it has children then loop through each child
          const nodeElement = this.treeControl.getDescendants(node);
          this.getChildren(element).forEach(d => {
            const specificElementInNode = nodeElement.filter(d1 => d1.item === d.item)
            return this.getFinalHierarchy(specificElementInNode[0])
          })
        }
        else { // If it doesnt have children then array of all its hierarchy is created
          return this.getAllChildren(node, element)
        }
      });
    }
    else { // If selected node doesnt have children then fetching all its parent and level
      const val = this.getAllParent(node, node.level);
      const tempMyObj = Object.assign({}, val);
      const latestKey = 'capabilityL' + node.level;
      tempMyObj[latestKey] = node.item;
      this.selectedCapabilityList = this.selectedCapabilityList.concat(tempMyObj)
      return this.selectedCapabilityList
    }
  }

  fetchSelectedValue(node) {
    this.todoItemSelectionToggle(node);
  }

  getParent(node: any) {
    const { treeControl } = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  disableCheck(node) {
    if (this.List.includes(node.item)) {
      return true;
    }
    else {
      return false
    }

  }
  filterChanged(filterText: string) {
    this.searchString = filterText;
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }
  sortHierarchy(nodes) {
    nodes.sort((a, b) => a.item.localeCompare(b.item));
    nodes.forEach(({ children }) => this.sortHierarchy(children || []));
  }
}



