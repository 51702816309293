import { Component, Inject,OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from 'src/app/shared';
import { AppViewService } from 'src/app/padu-reporting/application-view/app-view.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-editable-data-table-view',
  templateUrl: './editable-data-table-view.component.html',
  styleUrls: ['./editable-data-table-view.component.css']
})
export class EditableDataTableViewComponent implements OnInit {
  //displayedColumns: string[] = ['ApplicationID', 'ApplicationName', 'ApplicationPADU'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<PeriodicElement>(true, []);
  displayedColumnSharing: string[] = ['select', 'userDetails'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  
  capabilityID: any;
  applicationDetails: any;
  viewClickLoader: boolean = false;
  model: any;
  app_ID: any;

  displayedColumns=[
    {
      columnDef: 'ApplicationID',
      header: 'ApplicationID',
      width: '20%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) =>`${element.ApplicationID}`
    },
    {
      columnDef: 'ApplicationName',
      header: 'ApplicationName',
      width: '50%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'test':element.ApplicationName
        };
      },
      cell: (element: any) =>`${element.ApplicationName}`
    },
    {
      columnDef: 'ApplicationPADU',
      header: 'Application PADU',
      width: '20%',
      cellStyle: () => {
        return {
          'left-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'badge-padu-p-view': element.ApplicationPADU === 'Preferred',
                'badge-padu-a-view': element.ApplicationPADU === 'Acceptable',
                'badge-padu-d-view': element.ApplicationPADU === 'Discouraged',
                'badge-padu-u-view': element.ApplicationPADU === 'Unacceptable',
                'badge-padu-r-view': element.ApplicationPADU === 'Retired',
                'badge-padu-i-view': element.ApplicationPADU === 'Infrastructure',
                'badge-padu-pp-view': element.ApplicationPADU === 'PADU Pending',
                'badge-padu-tbr-view': element.ApplicationPADU === 'To Be Retired'
        };
      },
      cell: (element: any) => `${element.ApplicationPADU}`
    }];
 
  constructor(private appViewService: AppViewService, @Inject(MAT_DIALOG_DATA) data) {
    this.capabilityID = data.capabilityID;
    this.model=data.model;
    this.app_ID=data.app_ID;
    
  }
  ngOnInit(): void {
    this.viewClickLoader =true;
    this.appViewService.getCapabilityApplicationDetails(this.capabilityID,this.model,this.app_ID).subscribe(app => {
      this.applicationDetails = app;
      this.dataSource = new MatTableDataSource(this.applicationDetails);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function(record, filter) {
        return record.ApplicationName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
      };
      this.viewClickLoader =false;
    });
  }

  searchDataSet(event) {
    const filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
