<div class="col-half-offset" [style.width]="summarybox.width"
    *ngFor="let summarybox of groupSummaryBoxes; let last = last" [ngClass]="{'last-child': last}">
    <div class="uitk-c-card cardClass summaryboxClass" *ngIf="summarybox.type == 'number'"
        [style.border-left-color]="summarybox.color" style="border-left: 5px solid">

        <div class="row">
            <div  [ngClass]="[summarybox.type != 'string' ? 'col-md-9 col-sm-12 col-lg-9 col-xl-9 col-xs-9' : 'col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12']">
                <div class="border-0 card-header small text-left" [style.color]="summarybox.color">
                    {{summarybox.headerText}}</div>

                <div class="small-card-body" style="font-size: 20px;">

                    <ng-template [ngIf]="summarybox.type == 'number'" [ngIfElse]="getcolor">
                        <div>
                            {{numberFormat(summarybox.value)}}
                        </div>
                    </ng-template>

                    <ng-template #getcolor>
                        <div>
                            {{summarybox.value}}
                        </div>
                    </ng-template>

                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3" style="padding-top:20px; padding-left: 0px;">
                <div *ngIf="summarybox.tooltip" style="cursor: pointer;">
                    <mat-icon [matTooltip]="summarybox.tooltip"> {{summarybox.icon}} </mat-icon>
                </div>
                <div *ngIf="summarybox.tooltip"> {{summarybox.tooltipnumber}} </div>

                <div *ngIf=" !summarybox.tooltip">
                    <img src="../../../../assets/targeting-vector-bullseye.png" width="30px" height="30px">
                </div>
            </div>
        </div>
    </div>


    <div class="uitk-c-card cardClass summaryboxClass" *ngIf="summarybox.type != 'number'"
        [style.border-left-color]="getColor(summarybox.value)" style="border-left: 5px solid">

        <div class="row">
            <div [ngClass]="[summarybox.type != 'string' ? 'col-md-9 col-sm-12 col-lg-9 col-xl-9 col-xs-9' : 'col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12']">
                <div class="border-0 card-header small text-left" [style.color]="summarybox.color">
                    {{summarybox.headerText}}</div>

                <div class="small-card-body" style="font-size: 20px;">

                    <ng-template [ngIf]="summarybox.type == 'number'" [ngIfElse]="getcolor">
                        <div>
                            {{numberFormat(summarybox.value)}}
                        </div>
                    </ng-template>

                    <ng-template #getcolor>

                        <div class="numbercircle" [style.background]="getColor(summarybox.value)" *ngIf="summarybox.type != 'string'">
                            {{summarybox.value}}
                        </div>
                        <div class="title-value"  *ngIf="summarybox.type == 'string'">
                            {{summarybox.value}}
                        </div>
                    </ng-template>

                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3" style="padding-top:20px; padding-left: 0px;"  *ngIf="summarybox.type != 'string'">
                <!-- <mat-icon [matTooltip]="summarybox.tooltip"> {{getIcon(summarybox.value) }}</mat-icon> -->
                
                <img src="../../../../assets/Quality.png" width="30px" height="30px" >
                <div *ngIf="summarybox.tooltip" style="cursor: pointer;">

                    <mat-icon [matTooltip]="summarybox.tooltip" matTooltipPosition="right"> {{summarybox.icon}}
                    </mat-icon>
                </div>
                <div *ngIf="summarybox.tooltippop" style="margin-left: 4px;margin-top:2px;cursor: pointer;" (click)="popuptooltip(summarybox.tooltiphtml)">
                    <mat-icon [matTooltip]="summarybox.tooltip" matTooltipPosition="right"> {{summarybox.icon}}
                    </mat-icon>
                </div> 
            </div>
        </div>
    </div>

  

</div>
<div class="hover_bkgr_fricc" (click)="hidepopup()">
    <span class="helper"></span>
    <div style="background: #eeeeee">
        <div class="popupCloseButton" (click)="hidepopup()">&times;</div>
       <span [innerHtml]="popuphtmldata"></span>
    </div>
</div>