import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import paginate from 'jw-paginate';
import { Subject } from 'rxjs';
import { IServerSidePagination } from '../../interface/interface';

@Component({
  selector: 'app-table-pagination',
  template: `
    <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
      <li
        style="line-height: 14px;
      margin: 10px; font-size:0.6em;"
      >
        Displaying Page: {{ pager.currentPage }} of {{ pager.totalPages }}
      </li>
      <!--  <li [ngClass]="{ disabled: pager.currentPage === 1 }" class="page-item first-item">
        <a (click)="setPage(1)" class="page-link uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--xxs">First</a>
      </li> -->
      <li [ngClass]="{ disabled: pager.currentPage === 1 }" class="page-item previous-item">
        <a style="font-size: 0.6em;"
          (click)="setPage(pager.currentPage - 1); loadNextServerPage(pager)"
          class="page-link uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--xxs"
          >Previous</a
        >
      </li>
      <!-- <li
        *ngFor="let page of pager.pages"
        [ngClass]="{ active: pager.currentPage === page }"
        class="page-item number-item"
      >
        <a (click)="setPage(page)" class="page-link uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--xxs">{{ page }}</a>
      </li> -->
      <li [ngClass]="{ disabled: pager.currentPage === pager.totalPages }" class="page-item next-item">
        <a style="font-size: 0.6em;"
          (click)="setPage(pager.currentPage + 1); loadNextServerPage(pager)" 
          class="page-link uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--xxs"
          >Next</a
        >
      </li>
      <!-- <li [ngClass]="{ disabled: pager.currentPage === pager.totalPages }" class="page-item last-item">
        <a (click)="setPage(pager.totalPages)" class="page-link uitk-c-button uitk-c-button--alternative-ghost uitk-u-margin-right--xxs">Last</a>
      </li> -->
    </ul>
  `,
  styles: [
    `
                .pagination {
                  float: right;
                  padding: 1%;
                }
              `
  ]
})
export class TablePaginationComponent implements OnInit, OnChanges {
  @Input() items: Array<any>;
  @Output() changePage = new EventEmitter<any>(true);
  @Output() serverSidePageChange = new EventEmitter<any>();

  @Input() initialPage = 1;
  @Input() pageSize = 5;
  @Input() maxPages = 10;
  @Input() serverSidePagination: IServerSidePagination
  subject = new Subject<boolean>();


  pager: any = {};

  ngOnInit() {
    // set page if items array isn't empty
    if (this.items && this.items.length) {
      this.setPage(this.initialPage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (this.serverSidePagination && this.serverSidePagination.firstLoad) {
      this.setPage(this.initialPage);
    }
    else {
      if ((changes.items.currentValue !== changes.items.previousValue) && (!this.serverSidePagination)) {
        this.setPage(this.initialPage);
      }

    }
  }


  public setPage(page: number) {
    // get new pager object for specified page
    if (this.serverSidePagination && this.serverSidePagination.isServerPaginate) {
      this.pager = paginate(this.serverSidePagination.maxItem, page, this.pageSize, this.maxPages);
    }
    else {
      this.pager = paginate(this.items.length, page, this.pageSize, this.maxPages);
    }

    // get new page of items from items array



    // this.changePage.emit(pageOfItems);
    // call change page function in parent component
    // this.changePage.emit({ data: pageOfItems, pager: page });
  }

  loadNextServerPage(page) {
    if (this.serverSidePagination && this.serverSidePagination.isServerPaginate) {
      this.serverSidePageChange.emit(page.currentPage - 1);
    }
  }

}
