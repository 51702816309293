<div class="stack-chart-trend">
  <div class="tile-card-structure">
    <div class="block-header" style="width: 113%;">
      <div style="width: 20%;">
        <span class="  card-heading  table-header-nav-font tile-heading">{{label}}</span>
      </div>
      <div style="width: 20%;" class="tile-card-body cost-sum">
        <span class="sum-{{CurrentYear-2}}  chart-heading-font">{{name}}
          {{CurrentYear-2}}<br><span class="headerInfo">${{groupsum(CurrentYear-2)}}</span></span>
        <span class="sum-{{CurrentYear-1}}  chart-heading-font">{{name}}
          {{CurrentYear-1}}<br><span class="headerInfo">${{groupsum(CurrentYear-1)}}</span></span>
        <span class="sum-{{CurrentYear}}  chart-heading-font">{{name}} {{CurrentYear}}<br><span class="headerInfo">${{groupsum(CurrentYear)}}</span></span>
      </div>
      <div style="width: 20%; padding-left: 4%;">
        <span class="toggles">
          <i *ngIf="ommax && devmax!==0" (click)="update('reset',$event)" style="vertical-align: middle;"
            class="uitk-icon uitk-icon-refresh sm-icon pr-2 refresh-icon" title="Reset"></i>
          <span *ngIf="ommax && devmax!==0" class="toggle-div">
            <span class="toggle-label toggle-label-1 toggle-label-1-margin" style="vertical-align: super;
            font-weight: bold;
            color: #075786;">Dev Labor</span>
            
              <mat-slide-toggle [color]="'#013b71'" class="toggle-margin omdevfilter" [(ngModel)]="omdevfilter" (change)="update('filter',$event)">
              </mat-slide-toggle>
              <span class="mat-slide-toggle-content toggle-label toggle-label-2 ml-1 toggle-label-2-margin">OM Labor</span>
          </span>
        </span>
      </div>
      <div style="width: 20%; padding-left: 5%;">
        <label class="checkbox stack-chart-trend-checkbox-margin trendChart-checkbox-font">
          <input type="checkbox" name="{{CurrentYear-2}}" class="years stack-first-year" checked
            value="{{CurrentYear-2}}" (click)="update('year',$event)" />&nbsp; &nbsp; &nbsp;{{CurrentYear-2}}
        </label>
        <label class="checkbox stack-chart-trend-checkbox-margin trendChart-checkbox-font">
          <input type="checkbox" name="{{CurrentYear-1}}" class="years stack-second-year" checked
            value="{{CurrentYear-1}}" (click)="update('year',$event)" />&nbsp; &nbsp; &nbsp;{{CurrentYear-1}}
        </label>
        <label class="checkbox stack-chart-trend-checkbox-margin trendChart-checkbox-font">
          <input type="checkbox" name="{{CurrentYear}}" class="years stack-third-year" value="{{CurrentYear}}" checked
            (click)="update('year',$event)" />&nbsp; &nbsp; &nbsp;{{CurrentYear}}
        </label></div>
      <div style="width: 10%; padding-left: 5%;">
        <span [attr.Pixel-tracking]="true" [attr.Pixel-title]="'Documentation'" class="uitk-icon uitk-icon-info_hollow health md-icon for-media tab-Image excelandinfo-Icon-font"
        matTooltipPosition="left" aria-hidden="false" aria-label="information  icon" (click)="showInfo()"
        style="cursor: pointer; top: 5%; position: relative;">
    </span>
    <span [attr.Pixel-tracking]="true" [attr.Pixel-title]="'Labor Export'" class="uitk-icon uitk-icon-download md-icon excelandinfo-Icon-font" title="Click to download"
        style="cursor: pointer; position: absolute;margin-left:0.4%" (click)="exportData()">
    </span></div>
    </div>
    <!-- <div class="block-header">
      <span class="  card-heading  table-header-nav-font tile-heading">{{label}}</span>
      <div style="width: 20%;" class="card-infoblock-padding-labor labor-info-padding">
        <span class="uitk-icon uitk-icon-info_hollow health md-icon for-media tab-Image excelandinfo-Icon-font"
        matTooltipPosition="left" aria-hidden="false" aria-label="information  icon" (click)="showInfo()"
        style="cursor: pointer; top: 5%; position: relative;">
    </span>
    <span class="uitk-icon uitk-icon-download md-icon excelandinfo-Icon-font" title="Click to download"
        style="cursor: pointer; position: absolute;margin-left:0.4%" (click)="exportData()">
    </span>
</div>
    </div> -->
    <div class="tile-card-body row">
      <!-- <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 row text-right topbar">
        <div class="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-7 cost-sum">
          <span class="sum-{{CurrentYear-2}}  chart-heading-font">{{name}}
            {{CurrentYear-2}}<br><span class="headerInfo">${{groupsum(CurrentYear-2)}}</span></span>
          <span class="sum-{{CurrentYear-1}}  chart-heading-font">{{name}}
            {{CurrentYear-1}}<br><span class="headerInfo">${{groupsum(CurrentYear-1)}}</span></span>
          <span class="sum-{{CurrentYear}}  chart-heading-font">{{name}} {{CurrentYear}}<br><span class="headerInfo">${{groupsum(CurrentYear)}}</span></span>
        </div>

        <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-4">
          <span class="toggles">
            <i *ngIf="ommax && devmax!==0" (click)="update('reset')" style="vertical-align: middle;"
              class="uitk-icon uitk-icon-refresh sm-icon pr-2" title="Reset"></i>
            <span *ngIf="ommax && devmax!==0" class="toggle-div">
              <span class="toggle-label toggle-label-1 toggle-label-1-margin" style="vertical-align: super;
              font-weight: bold;
              color: #075786;">Dev Labor</span> -->
              <!-- <mat-slide-toggle class="toggle-margin omdevfilter" [(ngModel)]="omdevfilter" (change)="update('filter')">
                <span class="mat-slide-toggle-content toggle-label toggle-label-2">OM Labor</span>
              </mat-slide-toggle> -->
              <!-- 
            </span>
          </span> -->
          <!-- <b class="toggle-text"> Dev</b>
          <label class="switch">
            <input type="checkbox" class="omdevfilter" value="om" (click)="update('fitler')">
            <span class="slider"></span>
          </label>
          <b class="toggle-text">Om</b> -->
          <!-- <label class="checkbox trendChart-checkbox-font">
            <input type="checkbox" name="{{CurrentYear-2}}" class="years stack-first-year" checked
              value="{{CurrentYear-2}}" (click)="update('year')" />&nbsp; &nbsp; &nbsp;{{CurrentYear-2}}
          </label>
          <label class="checkbox trendChart-checkbox-font">
            <input type="checkbox" name="{{CurrentYear-1}}" class="years stack-second-year" checked
              value="{{CurrentYear-1}}" (click)="update('year')" />&nbsp; &nbsp; &nbsp;{{CurrentYear-1}}
          </label>
          <label class="checkbox trendChart-checkbox-font">
            <input type="checkbox" name="{{CurrentYear}}" class="years stack-third-year" value="{{CurrentYear}}" checked
              (click)="update('year')" />&nbsp; &nbsp; &nbsp;{{CurrentYear}}
          </label>
        </div>

      </div> -->
      <div class="col-md-12  col-sm-12 col-lg-12 col-xl-12 col-xs-12 stackchart chartAlignCenter">
        <!-- <svg class="svg-content" viewBox="0 0 970 350" preserveAspectRatio="xMidYMax meet"></svg> -->
      </div>
      <div class="info-div">
        <div class="pullUp">
          <div class="square square-size dev"></div>
          <p class="legend legend-font">Dev Labor</p>
        </div>
        <div class="pullUp">
          <div class="square square-size om"></div>
          <p class="legend legend-font">OM Labor</p>
        </div>
      </div>
    </div>
  </div>
</div>