<ng-container>
    <div class="data-loader-container"  style="stroke:  #002677 !important;" *ngIf="viewClickLoader">
      <app-loader></app-loader>
    </div>
    <div *ngIf="!viewClickLoader">
      <div class="container-fluid p-0">
        <div class="row no-gutters bgcolor p-2 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 vertical-align">
          <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-11 col-sm-12 col-lg-11 col-xl-11 col-xs-11">
            <span class="text-white">Applications</span>
          </div>
          <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-1">
            <button
              type="button"
              class="close  text-white"
              aria-label="Close"
              style="color:white;"
              [mat-dialog-close]="true"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
  
      <div class="container-fluid p-3">
        <ng-container>
          <div class="table-search col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5" style="padding-left: 70px;">
            <mat-form-field class="data-filter" appearance="outline">
              <input
                matInput
                type="text"
                class="capability-search"
                placeholder="Search Application"
                (keyup)="searchDataSet($event)"
              />
              <img
                src="../../../../assets/images/searchicon.png"
                style="position: absolute;
                                              right: 0px;
                                              top: 7px;"
              />
            </mat-form-field>
          </div>
        </ng-container>
      </div>
  
      <!-- <ng-container>
        <div class="container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="ApplicationID">
              <th mat-header-cell *matHeaderCellDef>Application ID</th>
              <td mat-cell id="td.mat-cell:first-of-type" *matCellDef="let app">{{ app.ApplicationID }}</td>
            </ng-container>
            <ng-container matColumnDef="ApplicationName">
              <th mat-header-cell *matHeaderCellDef>Application Name</th>
              <td mat-cell *matCellDef="let app">{{ app.ApplicationName }}</td>
            </ng-container>
            <ng-container matColumnDef="CapabilityPADU">
              <th mat-header-cell *matHeaderCellDef>Capability PADU</th>
              <td
                mat-cell
                class="add-padding"
                [ngClass]="{
                  'badge-padu-p': app.CapabilityPADU === 'Preferred',
                  'badge-padu-a': app.CapabilityPADU === 'Acceptable',
                  'badge-padu-d': app.CapabilityPADU === 'Discouraged',
                  'badge-padu-u': app.CapabilityPADU === 'Unacceptable',
                  'badge-padu-r': app.CapabilityPADU === 'Retired',
                  'badge-padu-i': app.CapabilityPADU === 'Infrastructure',
                  'badge-padu-pp': app.CapabilityPADU === 'PADU Pending',
                  'badge-padu-tbr': app.CapabilityPADU === 'To Be Retired'
                }"
                *matCellDef="let app"
              >
                {{ app.CapabilityPADU }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
  
          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </div>
      </ng-container> -->
      <ng-container>
        <app-data-table  [SelectAll] = "false" [displaySelectAll]="false" [dataSource]="dataSource"  [columns]="displayedColumns"
        [pageSize]="[6]">
      </app-data-table>
      </ng-container>
    </div>
  </ng-container>
  