<div class="stack-chart">
  <div class="tile-card-structure">
    <div class="block-header">
      <span class="  card-heading  table-header-nav-font tile-heading">{{label}}</span>
    </div>
    <div class="tile-card-body row">
      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 row text-right topbar">
        <div class="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-7 cost-sum">
          <span>{{name}} {{CurrentYear}}<br><span>{{total}}</span></span>
          <!-- <span>{{type}} <br><span>{{sum(type,'true')}}</span></span> -->
        </div>
        <div class="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5">
          <i (click)="update('reset')" class="uitk-icon uitk-icon-refresh sm-icon pr-2" style="vertical-align: middle;"
            title="Reset"></i>
          <span class="toggle-div row">
            <span class="toggle-label toggle-label-1">Dev</span>
            <mat-slide-toggle [color]="'#013b71'" class="toggle-margin omdevfilter" (change)="update('fitler')">
              <span class="mat-slide-toggle-content toggle-label toggle-label-2">Om</span>
            </mat-slide-toggle>
          </span>
          <!-- <b class="toggle-text"> Dev</b>
          <label class="switch">
            <input type="checkbox" class="omdevfilter" value="om" (click)="update('fitler')">
            <span class="slider"></span>
          </label>
          <b class="toggle-text">Om</b> -->
        </div>

      </div>
      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 my_dataviz2">
        <svg class="svg-content" viewBox="0 0 970 350" preserveAspectRatio="xMidYMax meet"></svg>
      </div>
    </div>
  </div>
</div>
