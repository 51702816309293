import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-unavailable',
  templateUrl: './data-unavailable.component.html',
  styleUrls: ['./data-unavailable.component.css']
})
export class DataUnavailableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
