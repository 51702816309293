import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class SharedAuthService {
    isRead: boolean = true;
    isWrite: boolean = false;
    isAdmin;
    userAccessData;
    userRole;
    isModelEditor;
    writeAccessRoles = ['Chief Architect', 'Architect Delegate', 'Admin']
    userAccessRole: any;
    architectRoles = ['Chief Architect', 'Architect Delegate'];
  public notificationSource = new Subject<any>();

    constructor () {
        this.isAdmin = localStorage.getItem('isAdminRole');
        this.userAccessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : {};
        this.isModelEditor = localStorage.getItem('isModelEditor');
    }
    getAuthorization(selectedLob, selectSubLob, component) {
        let editableLOBsInTechAccessView;
        let editableSubLobsInTechAccessView;
        this.isAdmin = localStorage.getItem('isAdminRole');
        this.userAccessData = JSON.parse(localStorage.getItem('accessData'));
        this.isModelEditor = localStorage.getItem('isModelEditor');
        let userAccessRole = "none";
        if(this.userAccessData) userAccessRole = this.userAccessData.Role;
        if (this.isAdmin === 'true' && userAccessRole.toLowerCase() == 'admin') {
            return true;
        }

        if (this.userAccessData != null && this.userAccessData != undefined) {
            editableLOBsInTechAccessView = this.userAccessData.lob;
            editableSubLobsInTechAccessView = this.userAccessData.subLob;
            let isUserInAppEditorSecuregrp =  JSON.parse(localStorage.getItem('isWriteAccess'));
            if (component === 'Apphub') {
                if (editableLOBsInTechAccessView.includes(selectedLob) && editableSubLobsInTechAccessView.includes(selectSubLob)
                        && this.writeAccessRoles.includes(userAccessRole) && isUserInAppEditorSecuregrp) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
        
        if (component === 'Capability') {
            if (this.isModelEditor === 'true') 
                return true;
            else 
                return false;
        }
            

    }

    getAuthorizationForEA(){
        this.isAdmin = localStorage.getItem('isAdminRole');
        this.userAccessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : {};
        if (this.userAccessData != null){
            let isUserInAppEditorSecuregrp =  JSON.parse(localStorage.getItem('isWriteAccess'));
            if (this.userAccessData["Role"] === 'Admin' && this.isAdmin === 'true'){
                return 'Admin';
            }
            else if(isUserInAppEditorSecuregrp && this.architectRoles.includes(this.userAccessData["Role"])){
                if (this.userAccessData["Role"] === 'Chief Architect'){
                    return 'CA';
                }
                else{
                    return 'AD';
                }
            }
            return '';
        }
        else{
            return '';
        }
        
    }
    getAuthorizationForCD(){
        let userAccessData = JSON.parse(localStorage.getItem('fundingAccess'));
        let isFundingOwnerAdmin = localStorage.getItem('isFundingOwnerAdmin');
        let fundingRoles = userAccessData?.map((item) => item.User_Role);
        if (fundingRoles.length >0 && isFundingOwnerAdmin === 'true'){
            return true;
        }else{
            return false;
        }  
    }
}