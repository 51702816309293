<div class="cap-pop-up">
  <div class="cap-hyperlink-block" *ngIf="showWQHyperlink">
    <p>There is an open request for this capability. Therefore a new request can not be created. Click <span class="cap-hyperlink-txt" (click)="navigateToWQlistPage()">here</span> to view request details.</p>
  </div>
    <!-- Group model-->
    <div class="row-new">
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3">
        <label class="section-name tabl-header tabl-header-font">
          Select Group Model
        </label>
        <mat-form-field [floatLabel]="'never'" class="custom-dropdown remove-bg-color">
          <input style="border:none;width:100%" class="edit text-notavailable-option option-size"
            *ngIf="!modelData.includes(selectedModel)" [value]="selectedModel ? selectedModel : ''" />
          <mat-select [placeholder]="selectedModel? '': 'Select Group Model'"
            (selectionChange)="getNewMappedCapabilityList()" [disabled]="isInitialCall && showWQHyperlink" [(ngModel)]="selectedModel">
            <mat-option class="dropDownList" *ngFor="let model of modelData" [value]="model">
              <ng-container *ngIf="!excludeModel.includes(model)">
                <label>{{model}}</label>
              </ng-container>
            </mat-option>
  
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3">
        <label class="section-name tabl-header tabl-header-font">
          Select Capability
        </label>
        <mat-form-field [floatLabel]="'never'" class="custom-dropdown remove-bg-color" (click)="onCapabilitySelectDropdown()">
          <input class="edit edit-font tree-dropdown-cap" [value]="selectedCapability" placeholder="Please Select Capability" (blur)="resetValue()" />
          <mat-select panelClass="global-select-panel"  [disabled]="!selectedModel || (isInitialCall && showWQHyperlink)" #mySelect disableRipple>
            <div>
              <input #searchInput matinput type="text" class="mat-input-search w-100"
                  placeholder="Search for a Capability" (blur)="resetValue()" [(ngModel)]="term" (keyup)="searchCapabilityByName($event)" (keydown)="$event.stopPropagation()">
            </div>
            <mat-option   class="dropDownList"  style="height: auto !important; overflow: auto; background-color: white;">
              <label>
                <app-tree-checkbox  [multiple]="false" [List]="mappedCapability"
                [openWQRequest] = "openRequest"
                (showOpenRequest)="showWQOpenRequestHyperlink($event)"
                (selectedTreeData)="getSelectedCapbilityFromDropdown($event)" [selectedCapability]="selectedCapability">
                </app-tree-checkbox>
              </label>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-2">
        <div style="padding-left: 10px;">
          <label class="section-name tabl-header tabl-header-font" style="padding-left: 1rem;">
            Primary
          </label>
          <div class="row marginLeft" style=" padding: left 45px;;">
            <div class="m-1">
              <input [disabled]="(capListForApp.length>0 && Primarydisable && !isEdit) || showWQHyperlink" style="height:15px; width:15px;"
                type="radio" name="imgsel" value="Yes" [checked]="primary==='Yes'"
                (change)="onItemChange($event.target.value)" />
                <label class="align-middle radio-button-cap"> Yes </label>
            </div>
  
            <div class="m-1">
              <input [disabled]="(capListForApp.length>0 && Primarydisable && !isEdit) || showWQHyperlink" [checked]="primary==='No'"
                style="height:15px; width:15px; padding-left: 15px;" type="radio" name="imgsel" value="No"
                (change)="onItemChange($event.target.value)" />
                <label class="align-middle radio-button-cap"> No </label>
            </div>
          </div>
  
        </div>
      </div>
      <div class="align-self-end col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-4 text-align-right" style="padding-bottom: 5px;">
        <button [disabled]="!selectedCapability || showWQHyperlink" style="margin-top: 5px;" mat-mdc-raised-button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="addOrg()">Add Organization</button>
  
        <button *ngIf="isEdit" [disabled]="(applyDisable && primaryChange !='Yes') || showWQHyperlink"
          style="margin-top: 5px;" mat-mdc-raised-button 
          class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
          (click)="Reset()">Reset</button>
      </div>
    </div>
    <!-- end Group model-->
  </div>
  <div class="stk-table-loader assc-cap-loader" *ngIf="showLoader">
    <app-loader>
    </app-loader>
  </div>
  
  <div class="table-container cap-popup-table-header-color">
    <table mat-table [dataSource]="isEdit === true ? orgDataSource : dataSource" class="mat-elevation-z8">
  
      <ng-container  matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef style="width:2%; text-align: center !important"></th>
        <!-- <p style="margin-bottom: 2%">Remove</p> -->
        <td mat-cell *matCellDef="let row" style="width:2%; text-align: center !important">
          <mat-icon [class.disabled]="showWQHyperlink" style="font-size: 1.1rem; cursor: pointer; " (click)="deleteOrg(row)">
            delete
          </mat-icon>
        </td>
      </ng-container>  
      <ng-container matColumnDef="organisation">
        <th mat-header-cell *matHeaderCellDef style="width: 10%;
        text-align: center !important;"> <label class = "marginforheader">Organization</label></th>
        <td mat-cell *matCellDef="let element" style="width: 10%;
        text-align: center !important;">
          <mat-form-field [floatLabel]="'never'" placeholder="Select" class="custom-dropdown">
            <span class="text-notavailable-option option-size org-placeholder">{{element.organisation}}</span>
            <mat-select panelClass="global-select-panel" (openedChange)="resetsearch($event)" [disabled]="showWQHyperlink"
              [placeholder]="element.organisation? '': 'Select the organization'" [(ngModel)]="element.organisation"
              (selectionChange)="orgChange(element, $event)">
              <div>
                <input matinput type="text" class="mat-input-search w-100"
                    placeholder="Search for an Organization"(keyup)="searchOrganizationByName($event)" [(ngModel)]="term" 
                    (blur)="resetValue()" (keydown)="$event.stopPropagation()">
              </div>
              <mat-option class="no-cursor" value="" *ngIf="Orglist?.length == 0"><label>No Results Found</label></mat-option>
              <mat-option  [disabled]="this.excludedOrgs.includes(org.org_id) == true" *ngFor="let org of Orglist" [value]="org">
                <label>{{org.org_name}}</label>
              </mat-option>

            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="current">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">Current PADU</label></th>
        <td class="text-align-center" mat-cell *matCellDef="let element">
          <span style="display: block !important;" [ngClass]="{
                'global-badge-padu-p-pop-up': element.current === 'Preferred',
                'global-badge-padu-a-pop-up': element.current === 'Acceptable',
                'global-badge-padu-d-pop-up':element.current === 'Discouraged',
                'global-badge-padu-u-pop-up':element.current === 'Unacceptable',
                'global-badge-padu-r-pop-up': element.current === 'Retired',
                'global-badge-padu-i-pop-up': element.current=== 'Infrastructure',
                'global-badge-padu-pp-pop-up': element.current === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.current === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.current=== 'Infrastructure' || element.current=== 'PADU Pending' || (element.current === 'Future' && editablePaduValues.includes('Future') != true)">{{element.current}}</span>
            <mat-select [placeholder]="element.current? '' : 'PADU Status'"
              [ngClass]="!element.current? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.current" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" [ngClass]="{
                 'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
                 'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
                 'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
                 'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
                 'global-badge-padu-r-pop-up-option': option.value === 'Retired',
                 'global-badge-padu-i-pop-up-option': option.value === 'I',
                 'global-badge-padu-pp-pop-up-option': option.value === 'PP',
                 'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
                }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="transition_1">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 1}} </label></th>
        <td class="text-align-center" mat-cell *matCellDef="let element">
          <span style="display: block !important;" [ngClass]="{
                'global-badge-padu-p-pop-up': element.transition_1 === 'Preferred',
                'global-badge-padu-a-pop-up': element.transition_1 === 'Acceptable',
                'global-badge-padu-d-pop-up':element.transition_1 === 'Discouraged',
                'global-badge-padu-u-pop-up':element.transition_1 === 'Unacceptable',
                'global-badge-padu-r-pop-up': element.transition_1 === 'Retired',
                'global-badge-padu-i-pop-up': element.transition_1=== 'Infrastructure',
                'global-badge-padu-pp-pop-up': element.transition_1 === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.transition_1 === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.transition_1=== 'Infrastructure' || element.transition_1=== 'PADU Pending' || (element.transition_1 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_1}}</span>
            <mat-select [placeholder]="element.transition_1? '' : 'PADU Status'"
              [ngClass]="!element.transition_1? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.transition_1" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" [ngClass]="{
                 'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
                 'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
                 'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
                 'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
                 'global-badge-padu-r-pop-up-option': option.value === 'Retired',
                 'global-badge-padu-i-pop-up-option': option.value === 'I',
                 'global-badge-padu-pp-pop-up-option': option.value === 'PP',
                 'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
                }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="transition_2">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 2}}</label> </th>
        <td class="text-align-center" mat-cell *matCellDef="let element">
          <span style="display: block !important;" [ngClass]="{
                'global-badge-padu-p-pop-up': element.transition_2 === 'Preferred',
                'global-badge-padu-a-pop-up': element.transition_2 === 'Acceptable',
                'global-badge-padu-d-pop-up':element.transition_2 === 'Discouraged',
                'global-badge-padu-u-pop-up':element.transition_2 === 'Unacceptable',
                'global-badge-padu-r-pop-up': element.transition_2 === 'Retired',
                'global-badge-padu-i-pop-up': element.transition_2=== 'Infrastructure',
                'global-badge-padu-pp-pop-up': element.transition_2 === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.transition_2 === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.transition_2=== 'Infrastructure' || element.transition_2=== 'PADU Pending' || (element.transition_2 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_2}}</span>
            <mat-select [placeholder]="element.transition_2? '' : 'PADU Status'"
              [ngClass]="!element.transition_2? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.transition_2" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" [ngClass]="{
                 'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
                 'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
                 'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
                 'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
                 'global-badge-padu-r-pop-up-option': option.value === 'Retired',
                 'global-badge-padu-i-pop-up-option': option.value === 'I',
                 'global-badge-padu-pp-pop-up-option': option.value === 'PP',
                 'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
                }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="transition_3">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 3}}</label> </th>
        <td class="text-align-center" mat-cell *matCellDef="let element"> <span style="display: block !important;" [ngClass]="{
            'global-badge-padu-p-pop-up': element.transition_3 === 'Preferred',
            'global-badge-padu-a-pop-up': element.transition_3 === 'Acceptable',
            'global-badge-padu-d-pop-up':element.transition_3 === 'Discouraged',
            'global-badge-padu-u-pop-up':element.transition_3 === 'Unacceptable',
            'global-badge-padu-r-pop-up': element.transition_3 === 'Retired',
            'global-badge-padu-i-pop-up': element.transition_3=== 'Infrastructure',
            'global-badge-padu-pp-pop-up': element.transition_3 === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.transition_3 === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.transition_3=== 'Infrastructure' || element.transition_3=== 'PADU Pending' || (element.transition_3 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_3}}</span>
            <mat-select [placeholder]="element.transition_3? '' : 'PADU Status'"
              [ngClass]="!element.transition_3? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.transition_3" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" [ngClass]="{
             'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
             'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
             'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
             'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
             'global-badge-padu-r-pop-up-option': option.value === 'Retired',
             'global-badge-padu-i-pop-up-option': option.value === 'I',
             'global-badge-padu-pp-pop-up-option': option.value === 'PP',
             'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
            }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span></td>
      </ng-container>
      <ng-container matColumnDef="transition_4">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 4}}</label> </th>
        <td class="text-align-center" mat-cell *matCellDef="let element">
          <span style="display: block !important;" [ngClass]="{
                'global-badge-padu-p-pop-up': element.transition_4 === 'Preferred',
                'global-badge-padu-a-pop-up': element.transition_4 === 'Acceptable',
                'global-badge-padu-d-pop-up':element.transition_4 === 'Discouraged',
                'global-badge-padu-u-pop-up':element.transition_4 === 'Unacceptable',
                'global-badge-padu-r-pop-up': element.transition_4 === 'Retired',
                'global-badge-padu-i-pop-up': element.transition_4=== 'Infrastructure',
                'global-badge-padu-pp-pop-up': element.transition_4 === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.transition_4 === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.transition_4=== 'Infrastructure' || element.transition_4=== 'PADU Pending' || (element.transition_4 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_4}}</span>
            <mat-select [placeholder]="element.transition_4? '' : 'PADU Status'"
              [ngClass]="!element.transition_4? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.transition_4" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" [ngClass]="{
                 'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
                 'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
                 'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
                 'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
                 'global-badge-padu-r-pop-up-option': option.value === 'Retired',
                 'global-badge-padu-i-pop-up-option': option.value === 'I',
                 'global-badge-padu-pp-pop-up-option': option.value === 'PP',
                 'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
                }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="transition_5">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 5}}</label> </th>
        <td class="text-align-center" mat-cell *matCellDef="let element">
          <span style="display: block !important;" [ngClass]="{
                'global-badge-padu-p-pop-up': element.transition_5 === 'Preferred',
                'global-badge-padu-a-pop-up': element.transition_5 === 'Acceptable',
                'global-badge-padu-d-pop-up':element.transition_5 === 'Discouraged',
                'global-badge-padu-u-pop-up':element.transition_5 === 'Unacceptable',
                'global-badge-padu-r-pop-up': element.transition_5 === 'Retired',
                'global-badge-padu-i-pop-up': element.transition_5=== 'Infrastructure',
                'global-badge-padu-pp-pop-up': element.transition_5 === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.transition_5 === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.transition_5=== 'Infrastructure' || element.transition_5=== 'PADU Pending' || (element.transition_5 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_5}}</span>
            <mat-select [placeholder]="element.transition_5? '' : 'PADU Status'"
              [ngClass]="!element.transition_5? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.transition_5" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" style="display: block !important;" [ngClass]="{
                 'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
                 'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
                 'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
                 'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
                 'global-badge-padu-r-pop-up-option': option.value === 'Retired',
                 'global-badge-padu-i-pop-up-option': option.value === 'I',
                 'global-badge-padu-pp-pop-up-option': option.value === 'PP',
                 'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
                }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span> </td>
      </ng-container>
      <ng-container matColumnDef="transition_6">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 6}}</label> </th>
        <td class="text-align-center" mat-cell *matCellDef="let element">
          <span style="display: block !important;" [ngClass]="{
                'global-badge-padu-p-pop-up': element.transition_6 === 'Preferred',
                'global-badge-padu-a-pop-up': element.transition_6 === 'Acceptable',
                'global-badge-padu-d-pop-up':element.transition_6 === 'Discouraged',
                'global-badge-padu-u-pop-up':element.transition_6 === 'Unacceptable',
                'global-badge-padu-r-pop-up': element.transition_6 === 'Retired',
                'global-badge-padu-i-pop-up': element.transition_6=== 'Infrastructure',
                'global-badge-padu-pp-pop-up': element.transition_6 === 'PADU Pending',
                'global-badge-padu-f-pop-up': element.transition_6 === 'Future'}">
            <span class="text-notavailable-option no-option-text-display"
              *ngIf="element.transition_6=== 'Infrastructure' || element.transition_6=== 'PADU Pending' || (element.transition_6 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_6}}</span>
            <mat-select [placeholder]="element.transition_6? '' : 'PADU Status'"
              [ngClass]="!element.transition_6? 'custom-dropdown padu-dropdown': 'mat-select-padu'"
              [(ngModel)]="element.transition_6" [disabled]="showWQHyperlink" (selectionChange)="checkRowForPaduChange(element)" panelClass="assc-cap-padu-dropdown">
              <mat-option *ngFor="let option of paduOptions" [value]="option.value">
                <span class="add-padding" [ngClass]="{
                 'global-badge-padu-p-pop-up-option': option.value === 'Preferred',
                 'global-badge-padu-a-pop-up-option':option.value === 'Acceptable',
                 'global-badge-padu-d-pop-up-option':option.value === 'Discouraged',
                 'global-badge-padu-u-pop-up-option': option.value === 'Unacceptable',
                 'global-badge-padu-r-pop-up-option': option.value === 'Retired',
                 'global-badge-padu-i-pop-up-option': option.value === 'I',
                 'global-badge-padu-pp-pop-up-option': option.value === 'PP',
                 'global-badge-padu-tbr-pop-up-option': option.value === 'TobeRetired',
                 'global-badge-padu-f-pop-up-option': option.value === 'Future'
                }"> {{ option.label}}</span>
              </mat-option>
            </mat-select>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        <!-- [ngClass]="selection.isSelected(row) ? 'highlightRow' : 'unhighlightRow'"> -->
      </tr>
    </table>
  </div>
  <div class="pull-right">
    <button [disabled]="isEdit ? ((applyDisable && primaryChange === 'No') ||showWQHyperlink) : (dataSource?.data.length==0 ||showWQHyperlink)"
      style="margin-top: 5px;" mat-mdc-raised-button class="*addbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button
      techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right" (click)="apply()">Apply
    </button>
  </div>
  