import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appAttrSearch'
})
export class AppAttrSearchPipe implements PipeTransform {

  transform(items: any[], searchTxt: string): any[] {
    if(!items || !items.length) return items;
    if(!searchTxt || !searchTxt.length)  return items;
   
    return items.filter(item => {
      if (item.name) {
        return item.name.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      } else if (item.Name) {
        return item.Name.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      } else if (item.sub_lob) {
        return item.sub_lob.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      } else if (item.lob) {
        return item.lob.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      } else if (item.l0Name) {
        return item.l0Name.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      } else {
        return item.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
      }
    });
  }

}
