import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgForm,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-datepicker',
  templateUrl: './techops-date.component.html',
  styleUrls: ['./techops-date.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechopsDateComponent),
      multi: true
    }
  ]
})
export class TechopsDateComponent implements ControlValueAccessor, AfterViewChecked {
  @HostBinding('attr.id')
  externalId = '';
  @Input() Selected: any;
  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  private _ID = '';
  public date;
  @Input() _value = '';
  @Input() isRequired: boolean = false;
  @Input() disabled: boolean;
  @Input() pattern = 'yyyy-MM-dd';
  @Input() type: string = 'Date';
  @Input() debugg = false;
  @Input() name = 'input';
  @Input() formref: NgForm;
  @Input() label = '';
  @Input()
  public controlName: string;
  public formcontrol: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {}

  ngAfterViewChecked() {
   
    let to = null;

    to = setTimeout(() => {
      if (this.formref.controls[this.name]) {
        if (this.isRequired) {
          this.formref.controls[this.name].setErrors({ required: true });
        } else {
          this.formref.controls[this.name].setErrors(null);
        }
      }
      if (to) {
        clearTimeout(to);
      }
      this.date = new FormControl(new Date(this.value));
    });
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  updateValue(event) {
    if (event.value != '') {
      this.value = this.datePipe.transform(event.value, this.pattern);
    }
    this.formcontrol = this.form.controls[this.name];
    if (this.isRequired) {
      this.formcontrol.setErrors({ required: true });
    } else {
      this.formcontrol.setErrors(null);
    }
    if (this.debugg) {
      console.log(this.formcontrol, this.form, this.formref);
    }
  }
  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }
}
