import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-edit-data-popup',
  templateUrl: './edit-data-popup.component.html',
  styleUrls: ['./edit-data-popup.component.css']
})
export class EditDataPopupComponent {
  createRoleform: FormGroup;
  updateAppAttrForm: FormGroup;
  updateLookUpHryForm: FormGroup;
  updateLookUpNoHryForm: FormGroup;
  addUpdateLookUpHryForm: FormGroup;
  addUpdateLookUpNoHryForm: FormGroup;
  @Input() dataToEdit: any = {};
  @Input() formfields: any = [];
  @Input() tableName: string = '';
  @Input() editFlag: boolean = false;
  @Input() closePopup:boolean = false;
  @Output() emitReqPayload: EventEmitter<any> = new EventEmitter();
  reqPayload: any;
  formName: string;
  selectedValue: any = [];
  text:boolean = false;
  btnName:string = "Create New";

  constructor(private formBuilder: FormBuilder) {
    this.createRoleform = this.formBuilder.group({
      workQueue: [{value:"",disabled: true}, [Validators.required]],
      createReqRole: ['', [Validators.required]],
      approveReqRole: ['', [Validators.required]],
      createDropdown: ['', [Validators.required]],
      viewVisibility: ['', [Validators.required]]
    });
    this.updateAppAttrForm = this.formBuilder.group({
      attribute:[{value:"",disabled: true}, [Validators.required]],
      selection: [{value:"",disabled: true}, [Validators.required]],
      hierarchy: [{value:"",disabled: true}, [Validators.required]],
      editable: ['', [Validators.required]],
      visualorder: ['', [Validators.required]]
    });
    this.updateLookUpHryForm = this.formBuilder.group({
      attributeHry: [{value:"",disabled: true}, [Validators.required]],
      valueHry: ["", [Validators.required]],
      subvalueHry: ['', [Validators.required]],
      editDropdownHry: ['', [Validators.required]],
      viewDropdownHry: ['', [Validators.required]]
    });
    this.addUpdateLookUpHryForm = this.formBuilder.group({
      attributeHry: [{value:"",disabled: false}, [Validators.required]],
      valueHry: ['', [Validators.required]],
      subvalueHry: ['', [Validators.required]],
      editDropdownHry: ['', [Validators.required]],
      viewDropdownHry: ['', [Validators.required]]
    });
    this.addUpdateLookUpNoHryForm = this.formBuilder.group({
      attributeHry: [{value:"",disabled: false}, [Validators.required]],
      valueHry: ['', [Validators.required]],
      editDropdownHry: ['', [Validators.required]],
      viewDropdownHry: ['', [Validators.required]]
    });
    this.updateLookUpNoHryForm = this.formBuilder.group({
      attribute: [{value:"",disabled: true}, [Validators.required]],
      value: ['', [Validators.required]],
      editDropdown: ['', [Validators.required]],
      viewDropdown: ['', [Validators.required]]
    });



  }

  checkFormFilled(){
    return (this.addUpdateLookUpHryForm.status == 'VALID' ? false : true);
  }
  checkNoHryFormFilled(){
    return (this.addUpdateLookUpNoHryForm.status == 'VALID' ? false : true);
  }

  toggleInput(){
    this.btnName = this.text ?  'Create New' : 'Existing Value';
   this.text = !this.text;
  }

  ngOnChanges() {
    if (this.editFlag) {
      if (this.dataToEdit && this.tableName == "WQ_MSTR") {
        let createReqRoleDD = this.formfields.filter(x => x.fcname == 'createReqRole')
        let create_req_roles = createReqRoleDD[0].dropdownData.filter(x => this.dataToEdit.create_req_roles.split(",").includes(x.id));
        let approveReqRoleDD = this.formfields.filter(x => x.fcname == 'approveReqRole')
        let approve_req_roles = approveReqRoleDD[0].dropdownData.filter(x => this.dataToEdit.approve_req_roles.split(",").includes(x.id));
        this.createRoleform.setValue({
          workQueue: this.dataToEdit.workqueue,
          createReqRole: create_req_roles,
          approveReqRole: approve_req_roles,
          createDropdown: this.dataToEdit.create_new_dropdown,
          viewVisibility: this.dataToEdit.view_visibility
        })
        this.setSelectedDropdownValues("approveReqRole", approve_req_roles)
        this.setSelectedDropdownValues("createReqRole", create_req_roles)
      }
      else if (this.dataToEdit && this.tableName == "APP_ATTR") {
        this.updateAppAttrForm.setValue({
          attribute: this.dataToEdit.attribute_name,
          selection: this.dataToEdit.selection,
          hierarchy: this.dataToEdit.hierarchy,
          editable: this.dataToEdit.editable,
          visualorder: this.dataToEdit.visual_order
        })
      }
      else if (this.dataToEdit && this.tableName == "LOOKUP_NO_HRY") {
        console.log(this.formfields)
        this.updateLookUpNoHryForm.setValue({
          attribute: this.dataToEdit.attribute,
          value: this.dataToEdit.value,
          editDropdown: this.dataToEdit.edit_drop_down,
          viewDropdown: this.dataToEdit.view_drop_down
        })
      }
      else if (this.dataToEdit && this.tableName == "LOOKUP_WITH_HRY") {
        this.updateLookUpHryForm.setValue({
          attributeHry: this.dataToEdit.attribute,
          valueHry: this.dataToEdit.value,
          subvalueHry: this.dataToEdit.sub_value,
          editDropdownHry: this.dataToEdit.edit_drop_down,
          viewDropdownHry: this.dataToEdit.view_drop_down
        })
      }
    }

    if(this.closePopup){
      this.resetFormValue();
    }

  }

  submitForm() {
    if (this.tableName == "WQ_MSTR") {
      this.reqPayload = {
        "tableName": "WQ_MSTR",
        "payload": this.dataToEdit
      }
      let create_req_roles = this.selectedValue.createReqRole.map(x=>x.name);
      this.reqPayload.payload["create_req_roles"] = create_req_roles.join(",");
      this.reqPayload.payload["approve_req_roles"] = this.selectedValue.approveReqRole.map(x=>x.name).join(",");
      this.reqPayload.payload["create_new_dropdown"] = this.createRoleform.value.createDropdown;
      this.reqPayload.payload["view_visibility"] = this.createRoleform.value.viewVisibility;
      this.emitReqPayload.emit(this.reqPayload);

    } else if (this.tableName == "APP_ATTR") {
      this.reqPayload = {
        "tableName": "APP_ATTR",
        "payload": this.dataToEdit
      }
      this.reqPayload.payload["selection"] = this.updateAppAttrForm.value.selection;
      this.reqPayload.payload["heirarchy"] = this.updateAppAttrForm.value.heirarchy;
      this.reqPayload.payload["editable"] = this.updateAppAttrForm.value.editable;
      this.reqPayload.payload["visual_order"] = this.updateAppAttrForm.value.visualorder;
      this.emitReqPayload.emit(this.reqPayload);

    }
    else if (this.tableName == "LOOKUP_NO_HRY") {
      this.reqPayload = {
        "tableName": "LOOKUP_NO_HRY",
        "payload": this.dataToEdit
      }
      this.reqPayload.payload["attribute"] = this.updateLookUpNoHryForm.value.attribute;
      this.reqPayload.payload["value"] = this.updateLookUpNoHryForm.value.value;
      this.reqPayload.payload["edit_drop_down"] = this.updateLookUpNoHryForm.value.editDropdown;
      this.reqPayload.payload["view_drop_down"] = this.updateLookUpNoHryForm.value.viewDropdown;
      this.emitReqPayload.emit(this.reqPayload);

    }
    else if (this.tableName == "LOOKUP_WITH_HRY") {
      this.reqPayload = {
        "tableName": "LOOKUP_WITH_HRY",
        "payload": this.dataToEdit
      }
      this.reqPayload.payload["attribute"] = this.updateLookUpHryForm.value.attributeHry;
      this.reqPayload.payload["value"] = this.updateLookUpHryForm.value.valueHry;
      this.reqPayload.payload["sub_value"] = this.updateLookUpHryForm.value.subvalueHry;
      this.reqPayload.payload["edit_drop_down"] = this.updateLookUpHryForm.value.editDropdownHry;
      this.reqPayload.payload["view_drop_down"] = this.updateLookUpHryForm.value.viewDropdownHry;
      this.emitReqPayload.emit(this.reqPayload);

    }

  }

  addLookupHry() {
    this.reqPayload = {
      "tableName": "ADD_LOOKUP_WITH_HRY"
    }

    this.reqPayload.payload = {
      "attribute": this.addUpdateLookUpHryForm.value.attributeHry,
      "value": this.addUpdateLookUpHryForm.value.valueHry,
      "sub_value": this.addUpdateLookUpHryForm.value.subvalueHry,
      "edit_drop_down": this.addUpdateLookUpHryForm.value.editDropdownHry,
      "view_drop_down": this.addUpdateLookUpHryForm.value.viewDropdownHry,
    }
   
     this.emitReqPayload.emit(this.reqPayload);

  }

  addLookupNoHry() {
    this.reqPayload = {
      "tableName": "ADD_LOOKUP_WITH_NO_HRY"
    }

    this.reqPayload.payload = {
      "attribute": this.addUpdateLookUpNoHryForm.value.attributeHry,
      "value": this.addUpdateLookUpNoHryForm.value.valueHry,
      "edit_drop_down": this.addUpdateLookUpNoHryForm.value.editDropdownHry,
      "view_drop_down": this.addUpdateLookUpNoHryForm.value.viewDropdownHry,
    }

    // this.addUpdateLookUpNoHryForm.reset();
    this.emitReqPayload.emit(this.reqPayload);
  }

  resetFormValue(){
    this.addUpdateLookUpNoHryForm.reset();
    this.addUpdateLookUpHryForm.reset();
  }



  setSelectedDropdownValues(field, data) {
    this.selectedValue[field] = data;
  }

  setSelectedValues(data,type) {
    if (type == "Select Create Req Roles")
      this.selectedValue.createReqRole = data.data;

    else if (type == "Select Approve Req Roles") {
      this.selectedValue.approveReqRole = data.data;
    }
  }

}
