<h2 mat-dialog-title *ngIf='data?.title' class="dialog-header">{{data.title}}</h2>
<!-- <ng-template #dialogStaticHeader>
  <h2 mat-dialog-title >PADU Update Confirmation!</h2>
</ng-template> -->
<mat-dialog-content class="mat-typography">
  <div *ngIf='data?.subTitle; else dialogSubTitleHeader' class="dialog-content" [innerHTML]="data.subTitle">
  
  </div>
  <div *ngIf='data?.textbox;' >
    <span class="opid form-inline" for="Description">
      <p style="color: #013b71;font-weight: bold;">Comments <b class="text-danger">*</b></p>
      <p *ngIf="disableContinue" >(upto 300 characters)</p>
      <p *ngIf="!disableContinue" >({{remarksCharsLeft}} characters left)</p>
    </span>
    <textarea  (input)="getContinueButtonflag()"  [(ngModel)]="Remarks" style="font-family: 'Arial Regular'; width: 440px; height: 100px;" type="textarea"
      class="form-control" name="Description"  required minlength="3" maxlength="300"
      [ngModelOptions]="{standalone: true}"></textarea>
    </div>
  <ng-template #dialogSubTitleHeader>
    <div class="dialog-content" *ngIf='!data?.isOptum'> Click Save to proceed</div>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="data?.showButton != false">
    <!-- <button mat-button mat-dialog-close (click)="onCancel()">Cancel</button> -->
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="onSave()">Save</button> -->
    <button mat-mdc-raised-button mat-dialog-close class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    (click)="onCancel()" >
    Cancel
  </button>  
    <button *ngIf='!data?.textbox && displaySave' mat-mdc-raised-button [mat-dialog-close]="true"  class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    (click)="onSave()" >
    {{data?.buttonName || 'Save'}}
  </button>  
  <button [disabled]="disableContinue" *ngIf='data?.textbox && displaySave' mat-mdc-raised-button [mat-dialog-close]="true"  class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
    (click)="onSave()" >
    {{data?.buttonName || 'Save'}}
  </button> 
</mat-dialog-actions>