import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PrintComponentComponent } from './components/print-component/print-component.component';
import { SessionHeadComponent } from './components/session-head/session-head.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MinusPipe } from '../shared/pipe/minus.pipe';
import { MillionPipe } from '../thousand.pipe';

import { CdkTreeModule } from '@angular/cdk/tree';
import { RouterModule } from '@angular/router';
import { BookmarkDialogPopupComponent } from './components/bookmark-dialog-popup/bookmark-dialog-popup.component';
import { DataUnavailableComponent } from './components/data-unavailable/data-unavailable.component';
import { SearchViewDefaultTextComponent } from './components/search-view-default-text/search-view-default-text.component';
import { BookmarkDirective } from './directives/bookmark.directive';
import { DailogModalComponent } from './elements/dailog-modal/dailog-modal.component';
import { DataTableComponent } from './elements/data-table/data-table.component';
import { DrawLinesComponent } from './elements/draw-line/draw-lines.component';
import { LineAnchorDirective } from './elements/draw-line/line-anchor.directive';
import { EditOverlayComponent } from './elements/edit-overlay/edit-overlay.component';
import { EditableDataTableComponent } from './elements/editable-data-table/editable-data-table.component';
import { ExpandCollapseTableComponent } from './elements/expand-collapse-table/expand-collapse-table.component';
import { MboServiceBarChartComponent } from './elements/mbo-service-bar-chart/mbo-service-bar-chart.component';
import { ModelMaintenanceComponent } from './elements/model-maintenance/model-maintenance.component';
import { PaduEditModalComponent } from './elements/padu-edit-modal/padu-edit-modal.component';
import { PermissionAccessPopupComponent } from './elements/permission-access-popup/permission-access-popup.component';
import { PopupComponent } from './elements/popup/popup.component';
import { SelectMultipleComponent } from './elements/select-multiple/select-multiple.component';
import { TreeCheckboxComponent } from './elements/tree-checkbox/tree-checkbox.component';
import { MaintenanceTableComponent } from './components/maintenance-table/maintenance-table.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  AccordianTableComponent, ApplistService, AppViewService, ButtonComponent, CategoryBarComponent, ExportDataService, FooterComponent, HadComponent,
  // NgbdToastGlobalModule,
  HeaderComponent, HighlightPipe, LaborhubNavComponent, LineChartComponent, LoaderComponent, PADUUtilsService,
  PrintServiceService, ServiceBarChartComponent, SharedService, SidebarComponent,
  SidenavComponent, StepperStatesComponent, SummaryBoxComponent,
  TablePaginationComponent, TcmUtilsService, TechopsCheckboxGroupComponent,
  TechopsDateComponent, TechopsInputComponent,
  TechopsSelectComponent, ThousandPipeModule, WorkQueuesPopUpComponent, AttributesDropdownComponent,
  PixelService,
  Ng2SearchPipe
} from './index';
import { ListFilterPipe } from './listFilter/list-filter.pipe';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { InfodailogComponent } from './components/infodailog/infodailog.component';
import { CommentsComponent } from './elements/comments/comments.component';
import { DonutChartComponent } from './elements/donut-chart/donut-chart.component';
import { EditableDataTableExpandComponent } from './elements/editable-data-table/editable-data-table-expand/editable-data-table-expand.component';
import { EditableDataTableViewComponent } from './elements/editable-data-table/editable-data-table-view/editable-data-table-view.component';
import { StackcharttrendComponent } from './elements/stackchart-trend/stackcharttrend.component';
import { StackchartComponent } from './elements/stackchart/stackchart.component';
import { TreeChecklistComponent } from './elements/tree-checklist/tree-checklist.component';
import { TrendChartComponent } from './elements/trend-chart/trend-chart.component';

import { DropdownWithSearchComponent } from './elements/dropdown-with-search/dropdown-with-search.component';
import { SearchPipe } from './pipe/search.pipe';
// import { SearchPipe } from './pipe/search.pipe';
import { BreadcrumbComponent } from './elements/breadcrumb/breadcrumb.component';
import { DropdownWithApiSearchComponent } from './elements/dropdown-with-api-search/dropdown-with-api-search.component';
import { TrendChartPortfolioComponent } from './elements/trend-chart-portfolio/trend-chart-portfolio.component';
import { CapabilityProposalComponent } from './elements/work-queue-popup/capability-proposal/capability-proposal.component';
import { TrendChartForecastComponent } from './elements/trend-chart-forecast/trend-chart-forecast.component';
// import { BreadcrumbComponent } from './elements/breadcrumb/breadcrumb.component';


@NgModule({
  imports: [
    CommonModule,
    // NgbdToastGlobalModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatSelectModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    ScrollingModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSortModule,
    MatTooltipModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    CdkTreeModule,
    MatGridListModule,
    MatCardModule,
    MatCheckboxModule,
    ThousandPipeModule,
    MatAutocompleteModule, 
  ],
  declarations: [
    SessionHeadComponent,
    ButtonComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SidenavComponent,
    PrintComponentComponent,
    MillionPipe,
    MinusPipe,   
    Ng2SearchPipe,
    DataTableComponent,
    LineAnchorDirective,
    DrawLinesComponent,
    BookmarkDialogPopupComponent,
    BookmarkDirective,
    ServiceBarChartComponent,
    LineChartComponent,
    AccordianTableComponent,
    SummaryBoxComponent,
    TablePaginationComponent,
    DataUnavailableComponent,
    CategoryBarComponent,
    MboServiceBarChartComponent,
    SearchViewDefaultTextComponent,
    ExpandCollapseTableComponent,
    HadComponent,
    LoaderComponent,
    TechopsInputComponent,
    TechopsSelectComponent,
    TechopsCheckboxGroupComponent,
    PermissionAccessPopupComponent,
    TechopsDateComponent,
    StepperStatesComponent,
    LaborhubNavComponent,
    PopupComponent,
    DailogModalComponent,
    EditableDataTableComponent,
    EditOverlayComponent,
    TreeCheckboxComponent,
    ListFilterPipe,
    SelectMultipleComponent,
    ModelMaintenanceComponent,
    PaduEditModalComponent,
    InfodailogComponent,
    EditableDataTableViewComponent,
    EditableDataTableExpandComponent,
    HighlightPipe,
    TrendChartComponent,
    StackchartComponent,
    DonutChartComponent,
    TreeChecklistComponent,
    CommentsComponent,
    StackcharttrendComponent,
    DropdownWithSearchComponent,
    SearchPipe,
    TrendChartPortfolioComponent,
    DropdownWithApiSearchComponent,
    BreadcrumbComponent,
    WorkQueuesPopUpComponent,
    AttributesDropdownComponent,
    CapabilityProposalComponent,
    TrendChartForecastComponent,
    MaintenanceTableComponent,
  ],
  exports: [
    SessionHeadComponent,
    ButtonComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SidenavComponent,
    PrintComponentComponent,
    MillionPipe,
    MinusPipe,
    DataTableComponent,
    LineAnchorDirective,
    DrawLinesComponent,
    ReactiveFormsModule,
    FormsModule,
    ScrollingModule,
    RouterModule,
    BookmarkDirective,
    ServiceBarChartComponent,
    SummaryBoxComponent,
    LineChartComponent,
    AccordianTableComponent,
    TablePaginationComponent,
    DataUnavailableComponent,
    CategoryBarComponent,
    SearchViewDefaultTextComponent,
    ExpandCollapseTableComponent,
    HadComponent,
    ScrollingModule,
    LoaderComponent,
    TechopsInputComponent,
    TechopsSelectComponent,
    TechopsCheckboxGroupComponent,
    PermissionAccessPopupComponent,
    TechopsDateComponent,
    StepperStatesComponent,
    LaborhubNavComponent,
    EditableDataTableComponent,
    EditOverlayComponent,
    ListFilterPipe,
    SelectMultipleComponent,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatListModule,
    TreeCheckboxComponent,
    HighlightPipe,
    TrendChartComponent,
    StackchartComponent,
    DonutChartComponent,
    TreeChecklistComponent,
    CommentsComponent,
    StackcharttrendComponent,
    DropdownWithSearchComponent,
    SearchPipe,
    TrendChartPortfolioComponent,
    DropdownWithApiSearchComponent,
    BreadcrumbComponent,
    WorkQueuesPopUpComponent,
    AttributesDropdownComponent,    
    Ng2SearchPipe,
    TrendChartForecastComponent,
    MaintenanceTableComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApplistService,
    SharedService,
    ExportDataService,
    PADUUtilsService,
    PrintServiceService,
    TcmUtilsService,
    AppViewService,
    PixelService,
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ]
})
export class SharedComponentsModule { }
