<div class="table-container table-data-div">
  <div [ngClass]="getTableClass('main')">
    <mat-form-field *ngIf="filterConfig" class="data-filter" appearance="outline">
      <input matInput [(ngModel)]="searchField" (keyup)="filterConfig.filterCallBack($event)"
        placeholder="{{ filterConfig.placeHolderText }}" />
      <button mat-button *ngIf="searchField" matSuffix mat-icon-button aria-label="Clear"
        (click)="onClickClearSearchField()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <ng-container *ngIf="hidecolumn && !productView" class="data-column" appearance="outline">
      <mat-checkbox class="example-margin" (change)="showhidecolumn()">Show All Columns</mat-checkbox>
    </ng-container>
    <div class="table-container-inside" [ngClass]="getTableClass()">
      <table *ngIf="(dataSource?.data.length > 0 || dataSource?.filteredData.length > 0) && dataReady" mat-table
        [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort multiTemplateDataRows
        class="mat-elevation-z8">
        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns; let i = index">
          <ng-container *ngIf="SelectAll && i == 0">
            <th class="tabl-header" mat-header-cell *matHeaderCellDef class="blue-header" [style.text-align]="'center'"
              [style.color]="'white'" [style.width]="column.width" [style.text-align]="column.textAlign" [style.padding-left]="column.paddingLeft"
              [style.padding-right]="column.paddingRight">
              <mat-checkbox *ngIf="displaySelectAll && i == 0" (change)="$event ? masterToggle() : null" color="primary"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td class="table-data" mat-cell *matCellDef="let row" [style.text-align]="'center'"
              [style.display]="column.display" [style.width]="column.width"  [style.padding-left]="column.paddingLeft">
              <mat-checkbox *ngIf="SelectAll" (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null" (change)="$event ? selecttoggle() : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <th class="tabl-header"
            [ngClass]="{ 'blue-header': expandTable && expandTable.class === 'expandedtableview' }" mat-header-cell
            mat-sort-header [style.width]="column.width" [style.text-align]="column.textAlign"
            [style.padding-right]="column.paddingRight" [disabled]="!column.sort" *matHeaderCellDef
            [class.table-right-actions-column]="column.columnDef == 'edit'">
            <mat-icon *ngIf="paduData && checkFlagCondition(column)" [ngStyle]=" getFlagStyle()">
              flag</mat-icon>
            <span [style.margin-left]="column.marginLeft" [style.text-align]='column.textAlign' >{{ column.header }}</span>
            <span >
              <!-- [ngClass]="{'reset-button-css': (column.columnDef=='SUI_Count' || column.columnDef=='currentMonthYTD')&&  filterApplied}" -->
              <span>

                <span class="data-table-search-icon"
                  *ngIf="column.columnDef == 'edit' && !tableConfig.isPaginatorAttached">
                  <input *ngIf="isSearchEnabled" style="height: 32px !important;" class="search " type="text"
                    placeholder="Application Name" (keyup)="childSearchCallback($event)" />

                  <mat-icon *ngIf="!isSearchEnabled" style="transform: translateX(8.5rem);"
                    (click)="isSearchEnabled = true" class="material-icon">search
                  </mat-icon>

                  <mat-icon *ngIf="isSearchEnabled" (click)="isSearchEnabled = false; closeSearch()"
                    class="material-icon-close">close</mat-icon>
                </span>

                <span class="data-table-search-icon"
                  *ngIf="column.columnDef == 'edit' && tableConfig.isPaginatorAttached">
                  <span class="data-table-search-icon">
                    <input *ngIf="isSearchEnabled; else footerPagination" class="search" type="text"
                      placeholder="Application Name" (keyup)="childSearchCallback($event)" />
                  </span>
                  <span style="float: right; ">
                    <mat-icon *ngIf="isSearchEnabled" (click)="isSearchEnabled = false; closeSearch()"
                      class="material-icon-close">close</mat-icon>
                    <mat-icon *ngIf="!isSearchEnabled" (click)="isSearchEnabled = true" class="material-icon">search
                    </mat-icon>
                  </span>
                </span>
                <span *ngIf="checkToolTip(column)" class="uitk-icon uitk-icon-info_hollow lbr-table-tool"
                  [matTooltip]="getToolTipContent(column)" aria-hidden="false" aria-label="information  icon">
                </span>

                <span *ngIf="checkLocalFilerValid(column.columnDef)" (click)="filterDropdown(column)"
                  [matMenuTriggerFor]="menu">
                  <span class="uitk-icon uitk-icon-menu sm-icon menu-icon"></span>
                </span>
                <mat-menu #menu>
                  <div class="menu-filter-popup" (click)="$event.stopPropagation()"
                    (keydown)="$event.stopPropagation()">
                    <input matInput type="text" class="searchoption" (keyup)="valueSearchSave(searchValue, column)"
                      (keydown)="$event.stopPropagation()" [(ngModel)]="searchValue" placeholder="Search" />
                    <mat-selection-list [disableRipple]="true" class="filter-list-container" [(ngModel)]="filValue">
                      <mat-list-option checkboxPosition="before" *ngFor="let shoe of filterList | filter: searchValue"
                        [value]="shoe">
                        {{ shoe.name }}</mat-list-option>
                    </mat-selection-list>
                    <span style="cursor:pointer; float: right;padding-right: 22px;" class="localApplyButton"
                      (click)="applyLocalFilter(filValue, column)">Apply</span>
                    <span style="cursor:pointer; float: right;padding-right: 22px;" class="localApplyButton"
                      (click)="clearLocalFilter(filValue, column)">Clear</span>
                  </div>
                </mat-menu>
                                                  <!-- filter report code          -->
                                                  <span *ngIf="column.header=='Report'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownR(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Report Category'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownR(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Description'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownR(column)">
                                                    <!-- <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img"> -->
                                                  </span>
                                                  <span *ngIf="column.header=='Type'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownR(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='ApplicationID' && !filterApplicationIdApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='ApplicationID' && filterApplicationIdApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='ApplicationName' && !filterApplicationNameApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='ApplicationName' && filterApplicationNameApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Capability PADU'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Application PADU' && !filterApplicationPADUApplied && column.columnDef != 'PADU_Values'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Application PADU' && filterApplicationPADUApplied && column.columnDef != 'PADU_Values'" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='SERVICE' && !filterApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column);$event.stopPropagation()">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='SERVICE' && filterApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column);$event.stopPropagation()">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Server' && !filterApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column);$event.stopPropagation()">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.header=='Server' && filterApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownforAssociatedCapabilitiesPopup(column);$event.stopPropagation()">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  
                                                  <span *ngIf="column.columnDef=='assetId' && !filterAssetIdApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='assetId' && filterAssetIdApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='assetName'  && !filterAssetNameApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='assetName' && filterAssetNameApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='owner'  && !filterAssetOwnerApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='owner' && filterAssetOwnerApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='ownerAssetTeam'  && !filterAssetTeamApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <span *ngIf="column.columnDef=='ownerAssetTeam' && filterAssetTeamApplied" [matMenuTriggerFor]="MenuR" (click)="filterDropdownA(column)">
                                                    <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                                                  </span>
                                                  <mat-menu #MenuR>
                                                    <div class="menu-filter-popup" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                                      <input matInput type="text" class="searchoption" (keydown)="$event.stopPropagation()"
                                                        (keyup)="searchR($event.target.value)" [(ngModel)]="searchValue" placeholder="Search" />
                                                      <mat-selection-list class="filter-list-container" (selectionChange)="selectionChangeR($event,column)"
                                                        [disableRipple]="true" [(ngModel)]="filValue">
                                                        <ng-container *ngIf="searchValue">
                                                          <mat-list-option checkboxPosition="before"  *ngFor="let row of searchFilterLists" [value]="row">
                                                            {{ row }} </mat-list-option>
                                                        </ng-container>
                                                        <ng-container *ngIf="!searchValue">
                                                          <mat-list-option checkboxPosition="before"  *ngFor="let row of filterLists" [value]="row">
                                                            {{ row }} </mat-list-option>
                                                        </ng-container>
                                                      </mat-selection-list>
                                                      <span style="cursor:pointer; float: right;padding-right: 70px;" class="small localApplyButton"
                                                        (click)="applyLocalFilterR()">Apply</span>
                                                      <span style="cursor:pointer; float: right;padding-right: 22px;" class="small localApplyButton"
                                                        (click)="clearLocalFilterR(column)">Clear</span>
                                                    </div>
                                                  </mat-menu>
              </span>
              <br />
              <span *ngIf="column.customHeaderStyle">
                <span [ngStyle]="column.customHeaderStyle">
                  {{ column.formattedText }}
                </span>
              </span>
            </span>
            <span *ngIf="(column.columnDef=='reset_filter' || column.columnDef == 'ApplicationPADU')&&  filterApplied" (click)="clearallFilter();$event.stopPropagation();">
              <i title="Reset Filters"
                class="font-weight-bold ng-tns-c441-21 pl-1 sm-icon stkh-table-filter-img uitk-icon uitk-icon-refresh"></i>
            </span>
            <span *ngIf="(column.columnDef=='reset_filter')&&  (filterAssetIdApplied || filterAssetNameApplied || filterAssetOwnerApplied || filterAssetTeamApplied)" 
              (click)="clearallFilter();$event.stopPropagation();">
              <i title="Reset Filters"
                class="font-weight-bold ng-tns-c441-21 pl-1 sm-icon stkh-table-filter-img uitk-icon uitk-icon-refresh"></i>
            </span>
          </th>
         
          <td class="table-data table-data-font " mat-cell [style.width]="column.width" [style.text-align]="column.textAlign" [style.display]="column.display"
            (click)="getRecord(element, column)" [ngClass]="evaluateFunction(column, element)" *matCellDef="let element"
            style="align-items: left;">

            <div *ngIf="column.columnDef=='automationAssetStatus'" class="automationAssetStatus statuscall">
              <span class="badge-height">
                {{ column.cell(element) }}
              </span>
            </div>
            
            <mat-icon *ngIf="column.columnDef == 'Service_Description' && checkServiceFlagCondition(element)" [ngStyle]="getServiceFlagStyle(element)">
              flag</mat-icon>
            <span class="badge-height" *ngIf="column.columnDef!='automationAssetStatus' && column.type != 'html' && column.type !='html_server' && column.type!='DescriptionReport' && column.type!='TypeReport' && column.type!='reportTitle' && column.type!='plm' && column.type != 'ellipsis'" 
            [ngClass]="{
          'global-badge-padu-p  ': column.cell(element) === 'Preferred',
          'global-badge-padu-a': column.cell(element) === 'Acceptable',
          'global-badge-padu-d': column.cell(element) === 'Discouraged',
          'global-badge-padu-u': column.cell(element) === 'Unacceptable',
          'global-badge-padu-r': column.cell(element) === 'Retired',
          'global-badge-padu-i': column.cell(element) === 'Infrastructure',
          'global-badge-padu-pp ': column.cell(element) === 'PADU Pending',
          'global-badge-padu-tbr': column.cell(element) === 'To Be Retired',
          'global-badge-padu-s': column.cell(element) === 'Supported',
          'global-badge-padu-us': column.cell(element) === 'Unsupported',
          'global-badge-padu-rem': column.cell(element) === 'Remediate',
          'global-badge-padu-v': column.cell(element) === 'Vulnerable',
          'global-badge-padu-fut': column.cell(element) === 'Future',
          'global-badge-padu-dec': (column.type !='Category'&& column.cell(element) === 'Decommission')}">
        {{column.cell(element)}}</span>
        <span *ngIf="column.type != 'html' && column.type !='html_server' && column.type!='DescriptionReport' && column.type!='TypeReport' && column.type!='reportTitle' && column.type=='plm' && column.type !='ellipsis'" style="display: block !important;"
              [ngClass]="{
          'global-badge-padu-p': column.cell(element) === 'Preferred',
          'global-badge-padu-a': column.cell(element) === 'Acceptable',
          'global-badge-padu-d': column.cell(element) === 'Discouraged',
          'global-badge-padu-u': column.cell(element) === 'Unacceptable',
          'global-badge-padu-r': column.cell(element) === 'Retired',
          'global-badge-padu-i': column.cell(element) === 'Infrastructure',
          'global-badge-padu-pp': column.cell(element) === 'PADU Pending',
          'global-badge-padu-tbr': column.cell(element) === 'To Be Retired',
          'global-badge-padu-s': column.cell(element) === 'Supported',
          'global-badge-padu-us': column.cell(element) === 'Unsupported',
          'global-badge-padu-rem': column.cell(element) === 'Remediate',
          'global-badge-padu-v': column.cell(element) === 'Vulnerable',
          'global-badge-padu-fut': column.cell(element) === 'Future',
          'global-badge-padu-dec':(column.type !='Category'&& column.cell(element) === 'Decommission')}">
        {{column.cell(element)}}</span>
        <span *ngIf="column.type == 'html' " [matTooltip]="column.columnDef == 'Service_Description' ? 'Click here to View Service details' : 'View Applications with the same Capability for this Organization'"  matTooltipClass=""
        matTooltipPosition="above" class="hyperlink" 
            [ngClass]="ClickIgnoreColumns?.includes(column.columnDef) ? '' : getClassHtmlClassName(column, element)"(click)="getcellRecord(element, column)"
            [innerHtml]="column.cell(element)"></span>
                 <span class="HTMLhover hyperlink"   [ngClass]="ClickIgnoreColumns?.includes(column.columnDef) ? '' : getClassHtmlClassName(column, element)" 
                 *ngIf="column.type == 'html_server'" >
                   <span [innerHtml]="column.cell(element)"></span>
                   <span [innerHtml]= "column.list(element)" class="HTMLtooltip"></span>
                  </span>
                 <span *ngIf="column.type=='action'" class="reportAction">
                  <img class="view-icon" src="../../../../assets/images/view.png"
                  matTooltip="View Report" matTooltipPosition="above"
                    (click)="navigateUrl(element)" />
                    <img class="view-icon" (click)="copyToClipboard(column.reportLink(element))" 
                    matTooltip="Copy to Clipboard" matTooltipPosition="above"
                    src="../../../../assets/images/copy.png"/>
                    
                     <i (click)="showDocumentionInfo(column.documentation(element))" matTooltip="View Documentation"
                      class="iIcon-Font-size-table-data uitk-iconReport uitk-iconReport-info_hollow health"></i>
                      <i class="uitk-icon uitk-icon-edit sm-icon" *ngIf="reportHasLoggedInUserOwner(column.ownerDetails(element),column.HasOwner(element))"
                        (click)="editReport(column.id(element),
                        column.reportName(element),
                        column.reprtDescription(element),
                        column.reportLink(element)) ; $event.stopPropagation();"
                        style="cursor: pointer;"
                         title="Edit report" ></i>
                    <img *ngIf="column.access(element)=='Yes'" 
                    [matTooltip]="showToolTip(column.secure_group(element))" matTooltipPosition="above"
                    class="view-icon" src="../../../../assets/images/key.png"/>
                    <!-- <img class="view-icon" src="../../../../assets/images/favourite.png"/> -->
                </span>
                <span *ngIf="column.type=='reportTitle'" class="reportTitle">
                  {{column.cell(element)}}
                </span>
                <span *ngIf="column.type=='DescriptionReport'" class="descReport" [title]="column.cell(element)">
                  {{column.cell(element)}}
                </span>
                <span *ngIf="column.type=='TypeReport'" class="typeReport">
                  {{column.cell(element)}}
                </span>
                <span *ngIf="column.type == 'ellipsis'" class="product-descrpition">
                  <span class="text-ellipsis" [matTooltip]="column.cell(element)"
                  matTooltipClass="custom-tooltip">
                    {{(column.cell(element) != 'null') ? column.cell(element) : ''}}
                  </span>
                </span>
          </td>
        </ng-container>
        <ng-container *ngFor="let extraHeader of tableHeaders">
          <ng-container [matColumnDef]="extraHeader.def">
            <th class="tabl-header" mat-header-cell *matHeaderCellDef class="text-center"
              [attr.colspan]="extraHeader.colspan">
              {{ extraHeader.name }}
            </th>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td class="table-data" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="app-detail" [@detailExpand]="element == expandedElement ? 'expanded ' : 'collapsed'">
              <div class="child-header">
                <span class="app-title ">Global App ID </span>
                <span class="app-title ">Line of Business</span>
              </div>
              <div class="child-content">
                <span class="infra-title">{{ element.ASK_GLOBAL_ID }}</span>
                <span class="infra-title">{{ element.lob }}</span>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="tableHeaders.length">
          <tr mat-header-row *matHeaderRowDef="tableHeaderColumn"></tr>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
          [ngClass]="{ 'hight-top': element.appName3 == selectedRowIndex }"
          [class.example-expanded-row]="expandedElement === element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
          [ngClass]="[isExpand == true && rowExpandable == true ? 'example-detail-row' : 'd-none']"></tr>
      </table>



    </div>
  </div>
  <ng-container *ngIf="!tableConfig?.isPaginatorAttached">
    <ng-container *ngIf="dataSource">
      <!-- <mat-paginator *ngIf="dataSource.data.length && !paduData" #pagination [pageIndex]="pageIndex"
        (page)="fetchMoreItems($event)" [length]="tableTotalItems" [pageSizeOptions]="pageSize" [pageSize]="pageSize[0]"
        showFirstLastButtons hidePageSize>
      </mat-paginator> -->
      <mat-paginator  #pagination [pageSize]="pageSize[0]" [hidePageSize]="!pageSize[1]"
      [pageSizeOptions]="pageSize" showFirstLastButtons></mat-paginator>
    </ng-container>
  </ng-container>

  <ng-template #footerPagination>
    <ng-container>
      <mat-paginator style="height:32px; border-radius: 21px; font-size: 21px !important; margin-right: 5px;"
        *ngIf="dataSource.data.length && !paduData" #pagination [pageIndex]="pageIndex" (page)="fetchMoreItems($event)"
        [length]="tableTotalItems" [pageSizeOptions]="pageSize" [pageSize]="pageSize[0]" class="tech-table-pagination"
        showFirstLastButtons hidePageSize>
      </mat-paginator>
    </ng-container>
  </ng-template>

</div>
