<div class="lob-model-vie">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" style="margin-bottom: 0px;padding-top: 1.5%;">
      <li class="breadcrumb-item"><a [routerLink]="['/landing-page']"> Home </a></li>
      <li class="breadcrumb-item active" aria-current="page"> Admin Dashboard </li>
    </ol>
  </nav>
  <div class=" uitk-l-grid__col--sm-12 uitk-l-grid__col--m-12 uitk-l-grid__col--lg-12">
    <div class="uitk-c-card uhc-font" style="padding-left:14px ; width: 100%;">
      <div class="row1">
        <div class="uitk-l-grid__col--20 admin-jobs-tab"  *ngIf="hasAccess || hasUserRoleChangeAccess || hasReportAdminAccess || hasReportWfAccess">
          <div class="stk-table-loader" *ngIf="showLoader">
            <app-loader></app-loader> 
          </div>
          <mat-tab-group class="Admintabs" [selectedIndex]="Activetab">
            <mat-tab class="mat-tab-label" label="User Role" *ngIf="hasUserRoleChangeAccess">
              <div class="changeUserAcess">
                <br> <br>
                <div class="row">
                  <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3 col-xs-3 alignMsid"> {{ userMsid }} </div>
                  <div class="col-md-3 col-sm-6 col-lg-3 col-xl-3 col-xs-3 alignDropdown">
                    <mat-form-field appearance="outline" class="dropdownWidth">
                      <mat-label> User Role </mat-label>
                      <mat-select panelClass="global-select-panel" (keydown)="$event.stopPropagation()" [(ngModel)]="selectedUserRoleType">
                        <div class="table-data-font">
                          <ng-container *ngFor="let data of userRoles">
                            <mat-option class="global-mat-option" title="{{data}}" [value]="data">
                              <label style="margin:0%">{{data}}</label>
                            </mat-option>
                          </ng-container>     
                        </div>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-sm-6 col-lg-3 col-xl-3 col-xs-3 alignDropdown">
                    <mat-form-field appearance="outline" class="dropdownWidth">
                      <mat-label> Secure Group </mat-label>
                      <mat-select multiple panelClass="global-select-panel" (keydown)="$event.stopPropagation()" [(ngModel)]="selectedSecureGroupType"                        
                      (selectionChange)="secureGroupChange($event)">
                        <mat-option *ngFor="let data of secureGroups" class="global-mat-option" title="{{data}}" [value]="data">
                          <label style="margin:0%">{{data}}</label>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3 col-xs-3 alignUpdate">
                    <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button uitk-u-margin-right--base uitk-u-margin-bottom--xs techops-global-button" 
                    (click)='updateUserRole()' > Update </button>
                  </div>
                </div>
                <div *ngIf="selectedUserRoleType == 'CA/AD'">
                  <p class="disclaimer"> * Click <a href="/maintenanceV4/lob" target="blank" class="link-click"> here </a> 
                    to add yourself as Chief Architect or Architect Delegate to an LOB before switching to CA/AD role.
                  </p>
                </div>
                <br> <br>
              </div>
            </mat-tab>
            <mat-tab class="mat-tab-label" label="Refresh Jobs" *ngIf="hasAccess">
              <div class="container" style="overflow: hidden;">
                <div class="stk-table-loader" *ngIf="showJobLoader">
                  <app-loader>
                  </app-loader>
                </div>
                <div *ngIf="!showJobLoader">
                  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="jobName">
                      <th mat-header-cell *matHeaderCellDef class="header"> Job Name </th>
                      <td mat-cell *matCellDef="let app" > {{app.type}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Params">
                      <th mat-header-cell *matHeaderCellDef  class="header" style="width: 1px;" > Parameter </th>
                      <td mat-cell *matCellDef="let app; let i = index" >
                        <mat-form-field color="accent" appearance="fill"  *ngIf ="checkParamType(app, 'date')">
                          <mat-label>Choose a date</mat-label>
                          <input matInput [matDatepicker]="picker2" (dateInput)="addDateEvent($event, app, i+(paginatorJobs.pageIndex*paginatorJobs.pageSize))" [max]="maxDate">
                          <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                        <div color="accent" appearance="fill" *ngIf ="checkParamType(app, 'year')" >
                          <mat-form-field>
                            <mat-label>Select Year</mat-label>
                            <mat-select name="my-select" [(ngModel)]="selectedYear" (selectionChange)="selectChange(i+(paginatorJobs.pageIndex*paginatorJobs.pageSize))" style="width: 130px;">
                              <mat-option [value]="item.name" *ngFor="let item of yearValues" style="text-align: center;">
                                  {{item.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div *ngIf="(app.type == 'EP-Delta-refresh') && showHoursMessage" class="showEpDeltaRefreshMessage">
                          Selected date can't be greater than 72 hours.
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="refreshTime">
                      <th mat-header-cell *matHeaderCellDef style="width:15%;"  class="header" > Last Refresh Date </th>
                      <td mat-cell *matCellDef="let app" style="width:15%;"> {{app.refreshTime | date: 'dd/MM/yyyy,h:mm a'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Schedule">
                      <th mat-header-cell *matHeaderCellDef style="width:15%;"  class="header" > Schedule </th>
                      <td mat-cell *matCellDef="let app" style="width:15%;"> {{ app.Schedule }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                      <th mat-header-cell *matHeaderCellDef style="width:15%;" class="header" > Action </th>
                      <td mat-cell *matCellDef="let app; let i = index" style="width:15%; " > 
                        <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button uitk-u-margin-right--base uitk-u-margin-bottom--xs techops-global-button" [disabled]="disableTrigger[i+(paginatorJobs.pageIndex*paginatorJobs.pageSize)]" (click)='submit(app,i+(paginatorJobs.pageIndex*paginatorJobs.pageSize))'> Trigger</button> 
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <mat-paginator #paginatorJobs="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                    [pageSize]=5 (page)="onPaginateChange($event)" aria-label="Select page of periodic elements">
                  </mat-paginator>
                </div> 
                <br>
                <h6> Monthly Refresh Jobs </h6>
                <div class="searchDiv">
                  <input type="text" class="search-option table-data-font search-box" placeholder="Search" (keyup)="MonthlyJobFilter($event.target.value)"/>
                  <img class="search_Icon" src="../../../../assets/images/searchicon.png" style="position: relative;" />
                </div>
                <table mat-table [dataSource]="dataSourceMonthlyJobs" class="mat-elevation-z8 MonthlyTable">
                  <ng-container matColumnDef="Environment">
                    <th mat-header-cell *matHeaderCellDef class="smallColumn"> Environment </th>
                    <td mat-cell *matCellDef="let job" class="smallColumn"> {{ job.Environment }}  </td>
                  </ng-container>
                  <ng-container matColumnDef="Process_Level">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Main Stage </th>
                    <td mat-cell *matCellDef="let job" style="text-align: left;"> {{ job.Process_Level }} </td>
                  </ng-container>
                  <ng-container matColumnDef="Process_Name">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Sub Stage </th>
                    <td mat-cell *matCellDef="let job" style="text-align: left;"> {{ job.Process_Name }} </td>
                  </ng-container>
                  <ng-container matColumnDef="Year">
                    <th mat-header-cell *matHeaderCellDef class="smallColumn"> Year </th>
                    <td mat-cell *matCellDef="let job" class="smallColumn"> {{ job.Year }} </td>
                  </ng-container>
                  <ng-container matColumnDef="Month">
                    <th mat-header-cell *matHeaderCellDef class="smallColumn"> Month </th>
                    <td mat-cell *matCellDef="let job" class="smallColumn"> {{ job.Month }} </td>
                  </ng-container>
                  <ng-container matColumnDef="Start_Time">
                    <th mat-header-cell *matHeaderCellDef> Start Time </th>
                    <td mat-cell *matCellDef="let job" class='date' > {{ job.Start_Time | date: 'dd/MM/yyyy hh:mm a'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="End_Time">
                    <th mat-header-cell *matHeaderCellDef > End Time </th>
                    <td mat-cell *matCellDef="let job"> {{ job.End_Time | date: 'dd/MM/yyyy hh:mm a'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="smallColumn"> Status </th>
                    <td mat-cell *matCellDef="let job" class="smallColumn"> {{ job.Status }} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsMonthlyJobs"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsMonthlyJobs;"></tr>
                </table>
                <mat-paginator #paginatorMonthlyJobs="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                  aria-label="Select page of periodic elements"> </mat-paginator> <br>
              </div>
            </mat-tab>
            <mat-tab class="mat-tab-label" label="Access" *ngIf="hasAccess">
              <div class="container"> 
                <table mat-table [dataSource]="dataSourceAdmin" class="mat-elevation-z8">
                  <ng-container matColumnDef="User_Name">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;" > User Name </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> {{app.User_Name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Msid">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;"> MSID </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> {{app.Msid }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Justification">
                    <th mat-header-cell *matHeaderCellDef style="width:15%;" > Justification Details </th>
                    <td title="{{app.Justification}}" mat-cell *matCellDef="let app" style="width:15%;"> {{app.Justification.length >30 ? app.Justification.substr(0,30)+'...' : app.Justification}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Page">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;" > Page URI </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> {{app.Page}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Tab">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;" > Tab </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> {{app.Tab}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Request_Type">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;" > Request Type </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> {{app.Request_Type}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Createdate">
                    <th mat-header-cell *matHeaderCellDef style="width:15%;" > Last Updated Date </th>
                    <td mat-cell *matCellDef="let app" style="width:15%;"> {{app.Updatedate ?app.Updatedate.substr(0,10) : ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Request_Status">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;"> Status </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> {{app.Request_Status}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef style="width:10%;" class="header"> Action </th>
                    <td mat-cell *matCellDef="let app" style="width:10%;"> 
                      <span *ngIf="app.Request_Status === 'Open'">
                        <img style="margin-right: 5px; cursor: pointer;" (click)="status('Approved',app)" src="../../../../assets/images/correct.png" /> 
                        <img style=" cursor: pointer;"  (click)="status('Rejected',app)" src="../../../../assets/images/reject.png" />
                      </span>
                      <span *ngIf="app.Request_Status !== 'Open'">{{ActionNA}}</span> 
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsAdmin"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsAdmin;"></tr>
                </table>             
                <mat-paginator #paginatorAdmin="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                  aria-label="Select page of periodic elements">
                </mat-paginator>
              </div>  
            </mat-tab>
            <mat-tab class="mat-tab-label" label="Dynamic Tables" *ngIf="hasAccess">
              <div class="lookupSection">
                <app-lookup-editor [lookupTables]="lookupTables"></app-lookup-editor>
              </div>   
            </mat-tab>
            <mat-tab label="Report Management" *ngIf = "hasReportAdminAccess || hasReportWfAccess">
              <div class="lookupSection">
                <app-report-management [hasReportWfAccess]="hasReportWfAccess" [hasReportAdminAccess]="hasReportAdminAccess" ></app-report-management>
              </div>   
            </mat-tab>
            <mat-tab label="Bulk Templates" *ngIf="hasUserRoleChangeAccess">
              <div class="lookupSection">
                <app-bulk-templates></app-bulk-templates>
              </div>   
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
