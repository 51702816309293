import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from 'src/app/shared/shared-components.module';
import { AppSearchComponent } from './app-search/app-search.component';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';

import { TableDataComponent } from './table-data/table-data.component';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { EditableTableOverlayComponent } from './editable-table-overlay/editable-table-overlay.component';
import { EditableTableViewComponent } from './editable-table/editable-table-view/editable-table-view.component';
import { EditableTableExpandComponent } from './editable-table/editable-table-expand/editable-table-expand.component';
import { CapPopUpComponent } from './editable-table/cap-pop-up/cap-pop-up.component';
import { ModalModule } from 'ng-modal-lib-sab';
import { MatSortModule } from '@angular/material/sort';
import { MergeCapPopupComponent } from './editable-table/merge-cap-popup/merge-cap-popup.component';
// import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
@NgModule({
  declarations: [AppSearchComponent, TableDataComponent, EditableTableComponent, EditableTableOverlayComponent, EditableTableViewComponent, EditableTableExpandComponent, CapPopUpComponent, MergeCapPopupComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSortModule,
    CdkTableModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    NgSelectModule,
    MatCardModule,
    ReactiveFormsModule,
    MatListModule,
    CdkTreeModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentsModule,
    ModalModule
  ],
  exports: [AppSearchComponent, TableDataComponent, EditableTableComponent,SharedComponentsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]
  
})
export class GlobalShareModule { }
