<div class="edit-reports">
<div class="popup-header" mat-dialog-title>
    <span style="color: #fff; padding: 12px;">Update Report</span>

    <div (click)="closePopup()" class="back">
        <mat-icon class="cancel-icon">cancel</mat-icon>
    </div>
</div>

<div class="form-report">
    <div class="relabel">
        <span class="report-label">Name</span>
    </div>
    <div class="form-field">
        <mat-form-field appearance="outline">
            <input type="text" (ngModelChange)="modelChanged()"
           [(ngModel)]="data.Report" matInput placeholder=""/>
        </mat-form-field>
    </div>
</div>

<div class="form-report">
    <div class="relabel">
        <span class="report-label">Description</span>
    </div>
    <div class="form-field">
        <mat-form-field appearance="outline">
            <textarea class="reportInput" type="text" (ngModelChange)="modelChanged()"
           [(ngModel)]="data.Description" matInput placeholder=""></textarea>
        </mat-form-field>
    </div>
</div>

<div class="form-report">
    <div class="relabel">
        <span class="report-label">URL</span>
    </div>
    <div class="form-field">
        <mat-form-field appearance="outline">
            <textarea class="reportInput" type="text" (ngModelChange)="modelChanged()"
           [(ngModel)]="data.Report_Link" matInput placeholder=""></textarea>
        </mat-form-field>
    </div>
</div>
<div class="updateButton">
    <div class="table-action pull-right applyButton">
        <button [disabled]="!enableButton" mat-mdc-raised-button  class="default-Button-Class m-1 uitk-c-button uitk-c-button--default 
            mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="updateReport()">
            Update
        </button>
    </div>
</div>
</div>
