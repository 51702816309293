import { Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrl: './notification-popup.component.css'
})
export class NotificationPopupComponent {
  @Input() notificationList: any = [];
  openedPanelNumber: any = -1;
  closedPanelNumber: any = -1;
  @ViewChild('panel') panel: MatExpansionPanel;

  constructor(private sharedService: SharedService) { }
}
