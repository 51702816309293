<mat-accordion class="sidebar" *ngIf="false">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header class="header-height">
      <mat-panel-title>
        Main Menu
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="row" style="margin-bottom: 1%;">
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure  group-card">
        <div class="card-container" (click)="redirectView('tcmhub-AppView')">
          <img class="icon" src="/assets/images/3721887.svg" />
          <span class="view-name">Application Cost Data</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure  group-card">
        <div class="card-container" (click)="redirectView('tcmhub-LobView')">
          <img class="icon" src="/assets/images/people copy.svg" />
          <span class="view-name">Cost Data by CIO/LOB</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">

          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card">
        <div class="card-container" (click)="redirectView('tcm-data-extract')">
          <img class="icon" src="/assets/images/data.svg" />
          <span class="view-name">Reports Data Extract</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">

          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card">

        <div class="card-container" (click)="redirectView('app-decom')">
          <img class="icon" src="/assets/images/cloud-computing.svg" />
          <span class="view-name">Decommission Information</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">

          </div>
        </div>
      </div>
    </div>
    <div class="row" [ngStyle]="{'margin-bottom': (showLaborHub) ? '1%' : ''}">
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card">
        <div class="card-container" (click)="redirectView('app-cap')">
          <img class="icon" src="/assets/images/pie-chart.svg" />
          <span class="view-name">Capability Models</span>
          <div class="tooltipCard" style="width: 310px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">

          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card">
        <div class="card-container" (click)="redirectView('app-AppView')">
          <img class="icon" src="/assets/images/graph-1.svg" />
          <span class="view-name">PADU Management</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">

          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card">
        <div class="card-container" (click)="redirectView('app-lob')">
          <img class="icon" src="/assets/images/Group 3.svg" />
          <span class="view-name">Application Insights by CIO/LOB </span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">

          </div>
        </div>

      </div>
      <div *ngIf="showLaborHub" class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card">
        <div class="card-container" (click)="redirectView('labourhub')">
          <img class="icon" src="/assets/images/laborhub/Labor_Hub_World_Tile.png" />
          <span class="view-name">Labor Hub</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">
          </div>
        </div>
      </div>

    
    </div>
    <div  class="row">
     
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card" *ngIf="optimizationAccess">
        <div class="card-container" (click)="redirectView('optimization')">
          <span class="uitk-icon uitk-icon-dollar_sign_hollow lg-icon icon" style="font-size: 50px;"></span>
          <!-- <img class="icon" src="/assets/images/laborhub/Labor_Hub_World_Tile.png" /> -->
          <span class="view-name reclasify-view">Optimization</span>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure group-card" *ngIf="reclassifyView">
        <div class="card-container reclassify" (click)="redirectView('reclassification')">
          <span class="uitk-icon uitk-icon-dollar_sign_hollow lg-icon reclass-top-icon dollor-icon" ></span>
         <div>
           <span class="reclasify-left-img">
            <img class="icon" src="/assets/images/3721887.svg"  style="height: 40px;width: 45px;"/>
           </span>
           <span class="uitk-icon uitk-icon-arrow_wtail_bold_left md-icon reclassify-left-right" style="font-size: 22px;"></span>
            <span class="uitk-icon uitk-icon-arrow_wtail_bold_right md-icon" style="font-size: 22px;"></span>
           <span class="reclasify-right-img">
            <img class="icon" src="/assets/images/3721887.svg" style="height: 40px;width: 45px;"/>
           </span>
          <span class="view-name" style="margin-left: 2px;">Reclassfication Portal </span>
         </div>
          <div class="tooltipCard" style="width: 290px;  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.25) !important;">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
        <span (click)="releaseNote()" style="float: right; color: #0072C6; cursor: pointer;">Release Notes</span>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>