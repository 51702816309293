import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, Inject, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownCapability } from 'src/app/shared/interface/interface';
import { ConstantsData } from 'src/app/constants/fields';
import { SharedService, SidenavService } from 'src/app/shared';

import { EditOverlayComponent } from '../edit-overlay/edit-overlay.component';
import { TreeCheckboxComponent } from '../tree-checkbox/tree-checkbox.component';
import { AppViewService } from 'src/app/padu-reporting/application-view/app-view.service';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AppHubUtilService } from '../../services/app-hub-util.service';
import { environment } from 'src/environments/environment';
import { appModernizationDetails } from '../../../config'
import { EditableDataTableViewComponent } from './editable-data-table-view/editable-data-table-view.component';
import { EditableDataTableExpandComponent } from './editable-data-table-expand/editable-data-table-expand.component';
import { DailogModalComponent } from '../dailog-modal/dailog-modal.component';
import { ToastrService } from 'ngx-toastr';

export interface TableFilterConfig {
  placeHolderText: string;
  labelText: string;
  filterCallBack: (event: any) => any;
  filterPredicate?: (item: any, filter: string) => boolean;
}
@Component({
  selector: 'app-editable-data-table',
  templateUrl: './editable-data-table.component.html',
  styleUrls: ['./editable-data-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
    ]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1, 'overflow-x': 'hidden' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class EditableDataTableComponent implements OnInit, AfterViewInit, OnChanges {
  currentYear = new Date().getUTCFullYear();
  disableEditAppInsight: boolean = !environment.disableEditAppInsight;
  disableOtherIcon: boolean = false;
  mappedCapability = [];
  public isEditable: boolean = false;
  paduLong = ConstantsData.PADUFullName;
  paduShortName = ConstantsData.PADUShortName;
  expandedElement1: any;
  selectedLOB = ' ';
  selectedSUBLOB = ' ';
  searchOrganization: any;
  searchCapability: any;
  capabilityID: any;
  childDataSource;
  disableIcon = true;
  currentCapabilityPADU;
  iseditExpand = false;
  selectedModel = '';
  selectedCapability1 = '';
  multipleSelectedCapability = '';
  @Input() app_ID: string;
  @Input() totalCapabilities: any
  globalAppID: any
  removeDisableFlag: any
  isCapabilitySelected: boolean;


  @ViewChild('mySelect', { static: false }) mySelect;
  itemValue: string = '';
  currentPADU = 'PADU Pending';
  selectedOrg;
  panelOpen = true;
  applistTableConfig: any;
  excludeModel = appModernizationDetails.excludeModel;
  futePaduExpandedTablecolumns = [

    {
      columnDef: 'transition_1', header: '',
      additionalHeader: `${this.currentYear + 1}`, width: '16%', spanCellStyle: element => {
        return {
          'space-padding': true,
          'badge-padu-p': element.transition_1 === 'Preferred',
          'badge-padu-a': element.transition_1 === 'Acceptable',
          'badge-padu-d': element.transition_1 === 'Discouraged',
          'badge-padu-u': element.transition_1 === 'Unacceptable',
          'badge-padu-r': element.transition_1 === 'Retired',
          'badge-padu-pp': element.transition_1 === 'PADU Pending'
        }
      }, cell: (element: any) => `${element.transition_1}`
    },
    {
      columnDef: 'transition_2', header: '',
      spanCellStyle: element => {
        return {
          'space-padding': true,
          'badge-padu-p': element.transition_2 === 'Preferred',
          'badge-padu-a': element.transition_2 === 'Acceptable',
          'badge-padu-d': element.transition_2 === 'Discouraged',
          'badge-padu-u': element.transition_2 === 'Unacceptable',
          'badge-padu-r': element.transition_2 === 'Retired',
          'badge-padu-pp': element.transition_2 === 'PADU Pending'
        }
      }, additionalHeader: `${this.currentYear + 2}`, width: '16%', cell: (element: any) => `${element.transition_2}`
    },
    {
      columnDef: 'transition_3',
      header: '',
      additionalHeader: `${this.currentYear + 3}`,
      width: '16%',
      spanCellStyle: element => {
        return {
          'space-padding': true,
          'badge-padu-p': element.transition_3 === 'Preferred',
          'badge-padu-a': element.transition_3 === 'Acceptable',
          'badge-padu-d': element.transition_3 === 'Discouraged',
          'badge-padu-u': element.transition_3 === 'Unacceptable',
          'badge-padu-r': element.transition_3 === 'Retired',
          'badge-padu-pp': element.transition_3 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.transition_3}`
    },
    {
      columnDef: 'transition_4', header: '',
      additionalHeader: `${this.currentYear + 4}`, width: '16%',
      spanCellStyle: element => {
        return {
          'space-padding': true,
          'badge-padu-p': element.transition_4 === 'Preferred',
          'badge-padu-a': element.transition_4 === 'Acceptable',
          'badge-padu-d': element.transition_4 === 'Discouraged',
          'badge-padu-u': element.transition_4 === 'Unacceptable',
          'badge-padu-r': element.transition_4 === 'Retired',
          'badge-padu-pp': element.transition_4 === 'PADU Pending'
        }
      }, cell: (element: any) => `${element.transition_4}`
    },
    {
      columnDef: 'transition_5', header: '',
      additionalHeader: `${this.currentYear + 5}`, width: '16%',
      spanCellStyle: element => {
        return {
          'space-padding': true,
          'badge-padu-p': element.transition_5 === 'Preferred',
          'badge-padu-a': element.transition_5 === 'Acceptable',
          'badge-padu-d': element.transition_5 === 'Discouraged',
          'badge-padu-u': element.transition_5 === 'Unacceptable',
          'badge-padu-r': element.transition_5 === 'Retired',
          'badge-padu-pp': element.transition_5 === 'PADU Pending'
        }
      }, cell: (element: any) => `${element.transition_5}`
    },
    {
      columnDef: 'transition_6', header: '',
      additionalHeader: `${this.currentYear + 6}`, width: '16%',
      spanCellStyle: element => {
        return {
          'space-padding': true,
          'badge-padu-p': element.transition_6 === 'Preferred',
          'badge-padu-a': element.transition_6 === 'Acceptable',
          'badge-padu-d': element.transition_6 === 'Discouraged',
          'badge-padu-u': element.transition_6 === 'Unacceptable',
          'badge-padu-r': element.transition_6 === 'Retired',
          'badge-padu-pp': element.transition_6 === 'PADU Pending'
        }
      }, cell: (element: any) => `${element.transition_6}`
    },

  ];


  associatedCapExpandedTablecolumns = [
    { columnDef: 'select', header: 'ss', width: '1%', cell: () => `` },
    {
      columnDef: 'OrganizationName',
      header: 'Organization Name',
      width: '13%',
      type: 'html',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) => this.prepareorganisationName(`${element.OrganizationName}`)
    },
    {
      columnDef: 'CurrentPADU',
      header: 'Current PADU',
      width: '11%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.CurrentPADU === 'Preferred',
          'badge-padu-a-60': element.CurrentPADU === 'Acceptable',
          'badge-padu-d-60': element.CurrentPADU === 'Discouraged',
          'badge-padu-u-60': element.CurrentPADU === 'Unacceptable',
          'badge-padu-r-60': element.CurrentPADU === 'Retired',
          'badge-padu-pp-60': element.CurrentPADU === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.CurrentPADU}`
    },
    {
      columnDef: 'Transition1',
      header: +`${this.currentYear + 1}`,
      width: '11%',
      type: 'textnumber',
      cellStyle: () => {
        return {
          'justify-content': `center`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.Transition1 === 'Preferred',
          'badge-padu-a-60': element.Transition1 === 'Acceptable',
          'badge-padu-d-60': element.Transition1 === 'Discouraged',
          'badge-padu-u-60': element.Transition1 === 'Unacceptable',
          'badge-padu-r-60': element.Transition1 === 'Retired',
          'badge-padu-pp-60': element.Transition1 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.Transition1}`
    },
    {
      columnDef: 'Transition2',
      header: `${this.currentYear + 2}`,
      width: '11%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.Transition2 === 'Preferred',
          'badge-padu-a-60': element.Transition2 === 'Acceptable',
          'badge-padu-d-60': element.Transition2 === 'Discouraged',
          'badge-padu-u-60': element.Transition2 === 'Unacceptable',
          'badge-padu-r-60': element.Transition2 === 'Retired',
          'badge-padu-pp-60': element.Transition2 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.Transition2}`
    },
    {
      columnDef: 'Transition3',
      header: `${this.currentYear + 3}`,
      width: '11%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.Transition3 === 'Preferred',
          'badge-padu-a-60': element.Transition3 === 'Acceptable',
          'badge-padu-d-60': element.Transition3 === 'Discouraged',
          'badge-padu-u-60': element.Transition3 === 'Unacceptable',
          'badge-padu-r-60': element.Transition3 === 'Retired',
          'badge-padu-pp-60': element.Transition3 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.Transition3}`
    },
    {
      columnDef: 'Transition4',
      header: `${this.currentYear + 4}`,
      width: '11%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.Transition4 === 'Preferred',
          'badge-padu-a-60': element.Transition4 === 'Acceptable',
          'badge-padu-d-60': element.Transition4 === 'Discouraged',
          'badge-padu-u-60': element.Transition4 === 'Unacceptable',
          'badge-padu-r-60': element.Transition4 === 'Retired',
          'badge-padu-pp-60': element.Transition4 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.Transition4}`
    },
    {
      columnDef: 'Transition5',
      header: `${this.currentYear + 5}`,
      width: '11%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.Transition5 === 'Preferred',
          'badge-padu-a-60': element.Transition5 === 'Acceptable',
          'badge-padu-d-60': element.Transition5 === 'Discouraged',
          'badge-padu-u-60': element.Transition5 === 'Unacceptable',
          'badge-padu-r-60': element.Transition5 === 'Retired',
          'badge-padu-pp-60': element.Transition5 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.Transition5}`
    },
    {
      columnDef: 'Transition6',
      header: `${this.currentYear + 6}`,
      width: '11%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'space-padding-5': true,
          'badge-padu-p-60': element.Transition6 === 'Preferred',
          'badge-padu-a-60': element.Transition6 === 'Acceptable',
          'badge-padu-d-60': element.Transition6 === 'Discouraged',
          'badge-padu-u-60': element.Transition6 === 'Unacceptable',
          'badge-padu-r-60': element.Transition6 === 'Retired',
          'badge-padu-pp-60': element.Transition6 === 'PADU Pending'
        };
      },
      cell: (element: any) => `${element.Transition6}`
    },];

  expandedTablecolumns = [
    { columnDef: 'app_id', header: 'ASKID', width: '16%', cell: (element: any) => `${element.app_id}` },
    {
      columnDef: 'application',
      header: 'Application Name',
      width: '18%',
      cell: (element: any) => `${element.application}`
    },
    {
      columnDef: 'current',
      header: 'Current PADU',
      width: '5%',
      spanCellStyle: element => {
        return {
          'add-padding': true,
          'badge-padu-p': element.current === 'P',
          'badge-padu-a': element.current === 'A',
          'badge-padu-d': element.current === 'D',
          'badge-padu-u': element.current === 'U',
          'badge-padu-r': element.current === 'R',
          'badge-padu-pp': element.current === 'PP',
          'badge-padu-tbr': element.current === 'TBR'
        };
      },
      cell: (element: any) => this.paduLong[element.current]
    },
    { columnDef: 'edit', header: '', width: '10%', cell: (element: any) => element.edit },
  ];

  public paduOptions: any = [
    { label: 'Preferred', value: 'Preferred' },
    { label: 'Acceptable', value: 'Acceptable' },
    { label: 'Discouraged', value: 'Discouraged' },
    { label: 'Unacceptable', value: 'Unacceptable' },
    { label: 'Retired', value: 'Retired' }
  ];
  childTableSearchConfig;
  clonedchildDataSource: any;
  expandedElement;
  @Input() newRowRemoved;
  EditDropdownOption = [];
  getCapabilityRedundency: any;
  addNewCapMapping: boolean = true;
  selection = new SelectionModel<any>(true, []);
  // localFilterPagination: boolean = false;

  // expandViewTableConfig = {
  //   serverSidePagination: true,
  //   'tableClass': 'product_tble_class',
  //   pageSize: [5],
  //   pageIndex: 0,
  //   totalItems: 0
  // };

  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() futurePaduDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() editSchemaConfig;
  @Input() expandTableConfig;
  @Input() addNewRow;
  @Input() TreeCheckboxDataSource;
  @Input() capabilityHierarchyList = [];
  @Input() SelectAll = false;
  @Input() editDropdownConfig;
  @Output() expandClick: EventEmitter<any> = new EventEmitter();
  @Output() dataReceived: EventEmitter<any> = new EventEmitter();
  @Output() checkClick: EventEmitter<any> = new EventEmitter();
  @Output() removeNewRow: EventEmitter<any> = new EventEmitter();
  @Output() loadDropdownApi: EventEmitter<any> = new EventEmitter();
  @Output() accordionremoveDisableFlag: EventEmitter<any> = new EventEmitter();

  @Input() pageSize: Array<number> = [];
  @Input() expandedElementObj: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(EditOverlayComponent, { static: false }) child: EditOverlayComponent;
  @ViewChild(TreeCheckboxComponent, { static: false }) treeCapability: TreeCheckboxComponent;
  @Input() dropdownData: IDropdownCapability;
  @Input() Lob;
  @Input() SubLob;
  @Input() isDecommissionedApp;
  displayedColumns: Array<string>;
  dataSchema;
  selectedCapHierarchy;
  displayedChildColumns: Array<string>;
  futurePaduDisplayedColumns: Array<string>;
  editExpandDataSource = [];
  searchField;
  openExpandEditTable: boolean = false;
  editClickLoader: boolean = false;
  paduClickLoader: boolean = false;
  @Input() filterConfig: TableFilterConfig;
  @Input() tableConfig: any;
  @Input() columns: Array<{
    columnDef: string;
    header: string;
    width: string;
    type: string;
    spanCellStyle: any;
    display: string;
    cellStyle: any;
    cell: any;
  }>;
  localFilterBody: any;
  currentPage: number = 0;
  showLoader: boolean;
  currentApplistPageIndex: number;
  currentApplistPageSize: number[];
  organisationCapability: any;
  organisationGroupModel: any;
  accordionremoveDisable = true;

  constructor(
    private container: ElementRef,
    private appViewService: AppViewService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private appUtil: AppHubUtilService,
    public sharedService: SharedService,
    private navService: SidenavService,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.searchOrganization = { key: 'org', value: '' };
    this.searchCapability = { key: 'capability', value: '' };
    this.clonedchildDataSource = [];
    this.currentApplistPageSize = [5];
    this.capabilityID = data.selectedCapabilityID;

    this.route.queryParams.subscribe(param => {
      this.localFilterBody = {
        appId: param.askId,

      }
    });

    this.childTableSearchConfig = {
      isServerSideSearch: false,
      onServerSearch: (currentSearchedValue: string) => {
        if (currentSearchedValue.length >= 3) {
          this.getCurrentCapabilityAppList(0, currentSearchedValue);
        } else {
          if (currentSearchedValue.length === 0) {
            this.getCurrentCapabilityAppList(0, currentSearchedValue);
          }
        }
      },
      filterPredicate: () => {
        this.childDataSource.filterPredicate = (data: any, filterValue: string) => {
          return (
            data.application
              .trim()
              .toLowerCase()
              .indexOf(filterValue) !== -1 || filterValue.length === 0
          );
        };
      },
      validateSearch: (value: string) => {
        return value.length >= 3;
      }
    };
  }

  prepareorganisationName(organisationName) {
    let content = '<div><span>' + organisationName + '</span></div>';
    return content;
  }

  onOrganisationClick(event) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      OrganizationID: event.row['OrganizationID'],
      model: this.organisationGroupModel,
      capabilityID: this.organisationCapability,
      app_id: this.app_ID,
    };
    dialogConfig.width = '84%';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = 'app-editable-data-table-expand';
    dialogConfig.height = '85%';

    const dialogRef = this.dialog.open(EditableDataTableExpandComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.type) {
        switch (result.type) {
          case 'cancel':
            return;
            break;
          case 'update':
            break;
          // Disabled Close explicit
          // this.currentPADU = result.currentPADU;
          // this.transition = result.transition;
          // this.onPADUUpdate()
        }
      }
    });

  }

  ngOnChanges() {
    this.dataSchema = this.editSchemaConfig;
    this.resetCapabilitySelections();
    // if(!this.dataReady) {
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }

      if (this.addNewRow) {
        this.paginator.firstPage();
      }
      if (this.addNewRow && this.dropdownData.model.length > 0) {

        this.selection.clear();

        this.multipleSelectedCapability = '';
        this.expandTableEdit({});
        this.getNewMappedCapabilityList();
        this.changeAllFuturePADU();
        this.editClickLoader = false;
      } else {
        this.disableOtherIcon = false;
        if (this.dropdownData.model.length > 0) {
          this.expandTableEdit(this.dropdownData.row);
          this.editClickLoader = false;
        } else {
          this.editClickLoader = true;
        }
        this.selection.clear();
      }
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit() {
    this.dataSchema = this.editSchemaConfig;
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {
    this.dataSchema = this.editSchemaConfig;

    this.selectedLOB = this.Lob;
    this.selectedSUBLOB = this.SubLob;
    this.onLobChangeSubscription();
    this.displayedColumns = this.columns.map(element => element.columnDef);

    this.displayedChildColumns = this.expandedTablecolumns.map(element => element.columnDef);
    this.futurePaduDisplayedColumns = this.futePaduExpandedTablecolumns.map(element => element.columnDef);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numofRows = this.totalCapabilities
    const numRows = this.paginator.pageSize;
    const lastPageIndex = Number(this.paginator.length / this.paginator.pageSize) as any;
    return numSelected === numRows || (parseInt(lastPageIndex) == this.paginator.pageIndex && numSelected > 0 && numSelected === numofRows);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.checkClick.emit({ check: false, data: [] });
    } else {
      this.dataSource.data.forEach((row, index) => {
        if(index >= this.paginator.pageIndex * this.paginator.pageSize &&
          index < this.paginator.pageSize * (this.paginator.pageIndex + 1))
           this.selection.select(row);         
      });
      this.checkClick.emit({ check: true, data: [] });
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  evaluateSpanSpecificClass(column, element) {
    let output = {};
    if (column.spanCellStyle) {
      output = column.spanCellStyle(element);
    }
    return output;
  }

  evaluateFunction(column, element) {
    let output = {};
    if (column.cellStyle) {
      output = column.cellStyle(element);
    }

    if (column.type === 'number') {
      output['text-align-right'] = true;
    }
    if (column.type === 'textnumber') {
      output['text-align-center'] = true;
    }
    if (column.type === 'html') {
      output['text-align-left'] = true;
    }
    return output;
  }

  onClickClearSearchField() {
    this.searchField = '';
    if (this.getTableClass()) {
      this.filterConfig.filterCallBack('');
    }
  }

  getTableClass(type = '') {
    if (this.tableConfig && this.tableConfig.tableClass) {
      if (type) {
        return this.tableConfig.tableClass + '_' + type;
      } else {
        return this.tableConfig.tableClass;
      }
    }
    return '';
  }

  // expandedTablePaginationEvent(event) {
  //   if (event) {
  //     let sort = null;
  //    this.getCapabilityRedundency(event.pageIndex, event.pageSize, sort);

  //   }
  // }

  dataConvertFuturePadu(data) {
    let arr = [];
    this.paduClickLoader = true;
    this.organisationCapability = data.selectedCapabilityID
    this.organisationGroupModel = data.group_model
    this.appViewService.getCapabilityOrganisationDetails(data.selectedCapabilityID, data.group_model, this.app_ID).subscribe(app => {
      arr = app;
      this.futurePaduDataSource = new MatTableDataSource(arr);
      this.editExpandDataSource = arr;
      this.paduClickLoader = false;
    });
    return true;
  }
  getCapabiltyInfo(element): string {
    let str = element.capabilityL0 + '(L0)' + ' \n' + element.capabilityL1 + '(L1)' + ' \n';
    if (element.capabilityL2 != 'NULL' && element.capabilityL2 != 'null' && element.capabilityL2 != null) {
      str = str + element.capabilityL2 + '(L2)' + ' \n';
      if (element.capabilityL3 != 'NULL' && element.capabilityL3 != 'null' && element.capabilityL3 != null) {
        str = str + element.capabilityL3 + '(L3)';
      }
    }
    return str;
  }
  getFilteredApplication = event => {
    const searchedValue = event.target.value;

    this.childDataSource.filter = searchedValue;

    // if(searchedValue.length>=3){
    //   const filteredList=this.childDataSource.data.filter((item)=>{
    //          return item.app_name.toLowerCase().includes(searchedValue.toLowerCase());

    //   });
    //   if(filteredList.length>0){
    //     console.log(this.expandedElement);
    //     this.expandedElement.appList=filteredList;

    //   }

    // }else{
    //   this.expandedElement.appList=this.clonedchildDataSource.data;
    // }
  };

  loadDropdown(element) {
    this.disableOtherIcon = true;
    this.editClickLoader = true;
    this.newRowRemoved = false;
    if (this.dropdownData.model.length <= 0) {
      this.loadDropdownApi.emit(element);
    } else {
      this.expandTableEdit(element);
      this.editClickLoader = false;
    }
  }

  expandTableEdit(element) {
    this.selectedModel = element.group_model;
    this.currentPADU = this.paduLong[element.current_padu] || 'PADU Pending';
    this.selectedOrg = element.organization;
    //  this.appViewService.setcapabilityHierarchyDropdownValues({cap:element.capability});

    const filterCapability = this.dataSource.data.filter(d => {
      if (d.group_model === element.group_model && d.organization === element.organization) {
        return d.capability;
      }
    });
    this.mappedCapability = filterCapability.map(d => d.capability);
    this.selectedCapability1 = element.capability;

    // this.appViewService.setcapabilityHierarchyDropdownValues({ cap: element.capability, alreadyMappedCapability: [] });
    // this.appViewService.getcapabilityHierarchyDropdownValues().subscribe(d => {
    //   this.selectedCapability1 = d.cap;

    // })
    this.dataConvertFuturePadu(element);
    this.disableOtherIcon = this.newRowRemoved ? false : true;
    const capHier = this.selectedModel
      ? this.capabilityHierarchyList.filter(d => d.group_model === this.selectedModel)
      : this.capabilityHierarchyList;
    this.TreeCheckboxDataSource = this.appUtil.replaceNull(
      this.appUtil.sanitize(
        this.appUtil.tree(capHier, ['capabilityL0', 'capabilityL1', 'capabilityL2', 'capabilityL3'])
      ),
      null
    );

    this.appViewService.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);

    // this.iseditExpand = !this.iseditExpand;
    this.iseditExpand = true;
  }

  saveExpandTableData(element) {
    const saveDataObj = {};
    const obj = {};

    if (this.addNewRow) {
      obj['group_model'] = this.selectedModel;
      obj['capabilityData'] = this.treeCapability.selectedCapabilityList;
      obj['organisation'] = this.selectedOrg;

      (obj['transition_1'] = this.paduShortName[this.child.updatedData()['transition_1']]),
        (obj['transition_2'] = this.paduShortName[this.child.updatedData()['transition_2']]),
        (obj['transition_3'] = this.paduShortName[this.child.updatedData()['transition_3']]),
        (obj['transition_4'] = this.paduShortName[this.child.updatedData()['transition_4']]),
        (obj['transition_5'] = this.paduShortName[this.child.updatedData()['transition_5']]),
        (obj['transition_6'] = this.paduShortName[this.child.updatedData()['transition_6']]),
        (obj['current'] = this.paduShortName[this.currentPADU]);
      (obj['createdById'] = localStorage.getItem('MsId')),
        (obj['createdByName'] = JSON.parse(localStorage.getItem('currentUser')));
      saveDataObj['data'] = obj;
      saveDataObj['view'] = 'new';
    } else {
      element.isEdit = !element.isEdit;
      this.iseditExpand = !this.iseditExpand;
      this.disableOtherIcon = false;
      element.group_model = this.selectedModel;
      element.current_padu = this.paduShortName[this.currentPADU];
      element.capability = this.selectedCapability1;
      element.organization = this.selectedOrg;
      obj['group_model'] = this.selectedModel;
      (obj['capabilityL1'] =
        (this.selectedCapHierarchy && this.selectedCapHierarchy.capabilityL1) || element.capabilityL1),
        (obj['capabilityL2'] = this.selectedCapHierarchy
          ? this.selectedCapHierarchy.capabilityL2
          : element.capabilityL2),
        (obj['capabilityL3'] = this.selectedCapHierarchy
          ? this.selectedCapHierarchy.capabilityL3
          : element.capabilityL3),
        (obj['organisation'] = this.selectedOrg);
      (obj['capabilityL0'] =
        (this.selectedCapHierarchy && this.selectedCapHierarchy.capabilityL0) || element.capabilityL0),
        (obj['transition_1'] = this.paduShortName[this.child.updatedData()['transition_1']]),
        (obj['transition_2'] = this.paduShortName[this.child.updatedData()['transition_2']]),
        (obj['transition_3'] = this.paduShortName[this.child.updatedData()['transition_3']]),
        (obj['transition_4'] = this.paduShortName[this.child.updatedData()['transition_4']]),
        (obj['transition_5'] = this.paduShortName[this.child.updatedData()['transition_5']]),
        (obj['transition_6'] = this.paduShortName[this.child.updatedData()['transition_6']]),
        (obj['current'] = element.current_padu);
      (obj['createdById'] = localStorage.getItem('MsId')),
        (obj['createdByName'] = JSON.parse(localStorage.getItem('currentUser')));
      saveDataObj['data'] = obj;
      saveDataObj['id'] = element.id;
      saveDataObj['view'] = 'edit';
    }
    this.dataReceived.emit(saveDataObj);
  }

  getNewMappedCapabilityList() {
    const filterCapability = this.dataSource.data.filter(d => {
      if (d.group_model === this.selectedModel && d.organization === this.selectedOrg) {
        return d.capability;
      }
    });

    this.mappedCapability = filterCapability.map(d => d.capability);
    const capHier = this.selectedModel
      ? this.capabilityHierarchyList.filter(d => d.group_model === this.selectedModel)
      : this.capabilityHierarchyList;

    this.TreeCheckboxDataSource = this.appUtil.replaceNull(
      this.appUtil.sanitize(
        this.appUtil.tree(capHier, ['capabilityL0', 'capabilityL1', 'capabilityL2', 'capabilityL3'])
      ),
      null
    );
    this.appViewService.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
  }

  removeRow() {
    this.removeNewRow.emit();
  }



  changeAllFuturePADU() {
    this.editExpandDataSource = [
      {
        transition_1: this.currentPADU || 'PADU Pending',
        transition_2: this.currentPADU || 'PADU Pending',
        transition_3: this.currentPADU || 'PADU Pending',
        transition_4: this.currentPADU || 'PADU Pending',
        transition_5: this.currentPADU || 'PADU Pending',
        transition_6: this.currentPADU || 'PADU Pending'
      }
    ];
  }

  getSelectedCapHierarchy() {
    this.mySelect.open();
    // console.log(this.treeCapability.checkedValue())
  }

  getCurrentCapabilityAppList = (currentPage: number, searchKeyWord?: string): void => {
    this.currentApplistPageIndex = currentPage;
    const pageParams = {
      page: currentPage,
      per_page: 5
    };
    if (searchKeyWord) {
      pageParams['appNameKeyWord'] = searchKeyWord;
    }
    this.showLoader = true;
    const obj = Object.assign({}, this.expandedElement);
    delete obj['capability'];
    const capabilityLevel = Object.keys(obj).find(key => obj[key] === this.expandedElement.capability);
    this.localFilterBody['capability'] = this.expandedElement.capability;
    this.localFilterBody['model'] = this.expandedElement.group_model;
    this.localFilterBody['capabilityLevel'] = capabilityLevel;
    this.localFilterBody['organization'] = this.expandedElement.organization;
    this.localFilterBody['org_id'] = this.expandedElement.org_id;
    this.localFilterBody['capabilityId'] = this.expandedElement[`${capabilityLevel}Id`];
    this.appViewService.getCapabilityAppList(this.localFilterBody, pageParams).subscribe(
      res => {
        this.applistTableConfig = {
          totalItems: res.appCount,
          serverSidePagination: true,
          isPaginatorAttached: true
        };
        // const currentCapaility = res.capPaginatedAppList.find((item) => {
        //   return item.capability === this.expandedElement.capability;
        // });
        const totalAppList = res.capPaginatedAppList.map(capability => {
          capability.applist = capability.appList.map(appItem => {
            appItem['tco'] = capability.tco;
            return appItem;
          });
          return capability.appList;
        });
        const concatedAppList = [...new Set([].concat(...totalAppList))];

        this.childDataSource = new MatTableDataSource(concatedAppList);
      },
      () => { },
      () => {
        this.showLoader = false;
      }
    );
  };

  onApplistPageChange(event) {
    this.getCurrentCapabilityAppList(event.pageIndex);
  }

  getSelectedCapbilityFromDropdown(event) {
    this.selectedCapHierarchy = event;
    if (event.capabilityL3 !== null) {
      this.selectedCapability1 = event.capabilityL3;
    } else if (event.capabilityL2 !== null) {
      this.selectedCapability1 = event.capabilityL2;
    } else if (event.capabilityL1 !== null) {
      this.selectedCapability1 = event.capabilityL1;
    } else {
      this.selectedCapability1 = event.capabilityL0;
    }
    this.mySelect.close();
  }

  multipleList(event) {
    this.multipleSelectedCapability = event
      .map(d => {
        if (d.capabilityL3 !== null) {
          return d.capabilityL3;
        } else if (d.capabilityL2 !== null) {
          return d.capabilityL2;
        } else if (d.capabilityL1 !== null) {
          return d.capabilityL1;
        } else {
          return d.capabilityL0;
        }
      })
      .join(',');
  }

  placeholderValue(element) {
    if (element.addNewRow && this.multipleSelectedCapability.length <= 0) {
      return false;
    } else {
      return true;
    }
  }

  selecttoggle() {
    this.isCapabilitySelected = false;
    if (this.selection.selected.length > 0) {
      this.isCapabilitySelected = true;
      this.checkClick.emit({ check: true, data: this.selection.selected });
    } else {
      this.isCapabilitySelected = false;
      this.checkClick.emit({ check: false, data: [] });
    }
  }

  getSelectedRow() {
    return this.selection.selected;
  }
  collapsedExpandedElement() {
    this.expandedElement1 = null;
    this.expandedElement = null;
  }
  onLobChangeSubscription() {
    this.appViewService.lobChangeSubject.subscribe(res => {
      this.selectedLOB = res.selectedLOB;
      this.selectedSUBLOB = res.selectedSUBLOB;
    });
  }
  searchOrganizationByName(event) {
    this.searchOrganization = { key: 'org', value: event.target.value };
  }
  searchCapabilityByName(event) {
    this.searchCapability = { key: 'capability', value: event.target.value };
  }

  pageChange(event) {
    this.currentApplistPageIndex = event.pageIndex;
    this.selection.clear();
  }
  navigateToViewApplications(element) {
    console.log(element);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      capabilityID: element.selectedCapabilityID,
      model: element.group_model,
      app_ID: this.app_ID
    };
    dialogConfig.width = '84%';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = 'editable-data-table-view';
    dialogConfig.height = '85%';

    const dialogRef = this.dialog.open(EditableDataTableViewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.type) {
        switch (result.type) {
          case 'cancel':
            return;
            break;
          case 'update':
            break;
          // Disabled Close explicit
          // this.currentPADU = result.currentPADU;
          // this.transition = result.transition;
          // this.onPADUUpdate()
        }
      }
    });

    /*dialogRef.componentInstance.onSave.subscribe((result: any) => {
      this.currentPADU = result.currentPADU;
      this.transition = result.transition;
      this.onPADUUpdate()
    });

    dialogRef.componentInstance.onRoadMapSave.subscribe((result: any) => {
      this.paduData[0].RoadMap_Flag = 'red';
      if (result[0].RoadMap) {
        this.paduData[0].RoadMap_Flag = 'orange';
      }
      this.onApplicationRoadMapUpdate(result);
      // this.onPADUUpdate()
    });*/
  }

 
  changeStar(element) {
    const subTitliMsg = element.primary_status == 'Yes' ? "Please click continue to remove the selected capability as Primary" : "Please click continue to make the selected capability Primary";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '200px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus= false;
    dialogConfig.data = {
      title: '                         Primary Update Confirmation!',
      subTitle: subTitliMsg,
      buttonName: 'Continue'
    };

    const dialogRef = this.dialog.open(DailogModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'save') {
        let body = {
          "cond": {
            "capabilityL0": element.capabilityL0,
            "capabilityL0Id": element.capabilityL0Id,
            "capabilityL1": element.capabilityL1,
            "capabilityL1Id": element.capabilityL1Id,
            "capabilityL2": element.capabilityL2,
            "capabilityL2Id": element.capabilityL2Id,
            "capabilityL3": element.capabilityL3,
            "capabilityL3Id": element.capabilityL3Id,
            "group_model": element.group_model,
            "app_id": this.app_ID
          },
          "data": {
            "primary_status": element.primary_status == 'Yes' ? "No" : "Yes"
          }
        }
        this.appViewService.updatePrimarystatus(body)
          .subscribe(() => {
            this.toaster.success("Successfully updated.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
            this.appViewService.notifyAboutprimaryChange();
          }, (err) => {
            console.log(err, "error occured while update")
            this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
          });
      }
    });
  }

  checkboxClick(event) {
    if (event && event.selectAll) {
      this.selection.clear();
    }
    this.checkClick.emit({ check: false, data: [] });
    this.accordionremoveDisableFlag.emit(event);
  }

  resetSelections() {
    this.accordionremoveDisableFlag.emit({ check: false, data: [] });
  }
  resetCapabilitySelections() {
    this.isCapabilitySelected = false;
  }
}
