import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginStubComponent } from './login-stub/login-stub.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { FormsModule } from '@angular/forms';
import { GuardsModule } from './guards/guards.module';
import { SharedComponentsModule } from '../../shared/shared-components.module';
import { AuthService } from './auth.service';
import { AuthStore } from './auth.store';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedComponentsModule
  ],
  exports: [GuardsModule, LoginStubComponent],
  declarations: [
    LoginStubComponent,
    AuthenticateComponent
  ],
  providers: [
    AuthService,
    AuthStore,
  ]
})

export class AuthModule { }
