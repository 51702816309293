import { OnInit, Component } from '@angular/core';
import { tcmHubConfigDetails } from '../config';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  PrevYear: any;
  CurrentMonth: any;
  PrevMonth: any;
  CurrentYear: any;
  CurrentMonthFull: any;
  btnClickedEventVar:any;

  config: any;
  displayedColumns: string[] = ['month', 'date'];
  dataSource;

  columns = [
    {
      columnDef: 'month',
      header: 'TCM & TCO Reporting Month',
      width: '50%',
      cell: (element: any) => `${element.month}`
    },
    {
      columnDef: 'date',
      header: 'Date TCM/TCO Available for Use',
      width: '50%',
      cell: (element: any) => `${element.date}`
    }
  ];

  constructor() {
    this.config = tcmHubConfigDetails;
    this.dataSource = new MatTableDataSource(this.config.timelineDataTCMHUB);
  }

  ngOnInit() {
    this.PrevYear = tcmHubConfigDetails.PrevYear;
    this.CurrentMonth = tcmHubConfigDetails.CurrentMonth;
    this.PrevMonth = tcmHubConfigDetails.PrevMonth;
    this.CurrentYear = tcmHubConfigDetails.CurrentYear;
    this.CurrentMonthFull = tcmHubConfigDetails.CurrentMonthFull;
  }

  buttonclick(event) {
    this.btnClickedEventVar = event;
  }
}
