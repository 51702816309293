<mat-form-field  appearance="outline">
	<mat-select [formControl]="multiSelectControl" multiple [value]="selected" 
	(openedChange)="openChange($event)"
	placeholder="{{selected.length > 0 ? selectedTexts : 'Select'}}" 
	panelClass="remove-input-margin select-multiple-dropdown-width"
	>
	<mat-select-trigger>
		{{selectedTexts}}
	</mat-select-trigger>
	<input
	(keydown)="$event.stopPropagation()"
	matinput
	type="text"
	class="searchoption"
	[(ngModel)]="search"
	placeholder="Search"
  />
			<cdk-virtual-scroll-viewport itemSize="50" [style.height.px]=5*48>
				<mat-checkbox class="example-margin" (click)="selectAll(checked)">
					Select All
				  </mat-checkbox>
			<mat-option *cdkVirtualFor="let option of optionList  | filter: search"  [value]="option"
				(onSelectionChange)="onSelectionChange($event)">{{option.name}}</mat-option>
		</cdk-virtual-scroll-viewport>
	</mat-select>
</mat-form-field>
