<div class="maintenance-modal-container">
    <div class="custom-modal-header">
        <span class="title">{{modalTitle}}</span>
        <span class="modal-actions">
            <mat-icon class="material-icons pgm-close" matTooltip="Close" (click)="closePopup()">close
            </mat-icon>
        </span>
    </div>
    <div class="custom-modal-body">
        <div class="body-actions">
        </div>
        <div class="body-content">
            <form class="upload-form" [formGroup]="SaveForm">
                <div class="form-panel">
                    <div class="form-f comments-sec">
                        <mat-form-field appearance="outline" floatLabel="auto" class="mat-form-field is-invalid"
                            [style.width.px]=400 [class.mat-form-field-invalid]="errorMessage">
                            <mat-label>Enter {{Label}} Name </mat-label>
                            <input matInput formControlName="name" placeholder="" [class.is-invalid]="errorMessage">
                        </mat-form-field>
                        <ng-container *ngIf="errorMessage">
                            <div class="form-error">
                                <span class="form-error-message">
                                    {{errorMessage}}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-footer">
                    <div class="footer-buttons">
                        <button mat-mdc-raised-button 
                            class="searchbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                            (click)="submitForm()" [disabled]="(!SaveForm.valid)">
                            Save
                        </button>
                        <button mat-mdc-raised-button 
                            class="searchbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                            (click)="closePopup()">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!--  -->
    <ng-container *ngIf="showLoader">
        <div class="show-loader">
            <app-loader>
            </app-loader>

        </div>
    </ng-container>

</div>