import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appModernizationDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { Bookmark } from '../interface/interface';
import { AppHubCacheService } from './app-hub-cache.service';
import { map, flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BookmarkServiceService {

  private apiUrl: string;
  private config: any;
  private url: string;
  public BookmarkSaved = new Subject<boolean>();
  public msid: string;
  constructor(
    private http: HttpClient,
    private dataCache: AppHubCacheService,
    private route: Router,
  ) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
    this.url = this.apiUrl + this.config.routerpath.bookmark;
    this.msid = localStorage.getItem('MsId');
  }

  setBookmarkSaved(value: boolean) {
    this.BookmarkSaved.next(value);
  }

  public addBookmarkDetails(body: Bookmark): Observable<any> {
    const cache = this.dataCache.getCache(this.url + this.msid) || [];
    return this.http.post<Bookmark>(this.url, body).pipe(flatMap(data => {
      cache.unshift(data);
      return of(data);
    }));
  }

  public getBookmarkList(msid: string): Observable<any> {
    const cache = this.dataCache.getCache(this.url + msid);
    const getData$ = cache
      ? of(cache)
      : this.http
        .get<Array<Bookmark>>(this.url, { params: { msid } })
        .pipe(
          map(data => {
            this.dataCache.setCache(this.url + msid, data);
            return data;
          })
        );
    return getData$.pipe(
      map(data => {
        return data;
      })
    );
  }

  public getBookmarkDetails(id: string): Observable<any> {
    const cache = this.dataCache.getCache(this.url + this.msid) || [];
    const bookmark = cache.filter(c => c.id === +id);
    if (bookmark.length) {
      return of(bookmark);
    }
    return this.http.get<Array<Bookmark>>(this.url, { params: { id } });
  }

  public deleteBookmark(id: number): Observable<any> {
    const completeURL = `${this.url}/${id}`;
    const cache = this.dataCache.getCache(this.url + this.msid);
    if (cache) {
      const removeIndex = cache.map((item: Bookmark) => item.id).indexOf(id);
      cache.splice(removeIndex, 1);
      this.dataCache.setCache(this.url + this.msid, cache);
    }
    return this.http.delete<Array<Bookmark>>(completeURL);
  }

  public updateBookmark(id: number, body: any): Observable<any> {
    const completeURL = `${this.url}/${id}`;
    return this.http.put<Array<Bookmark>>(completeURL, body);
  }
  redirect(bookmark: Bookmark) {
    const { path, appId, offerings, model, lob, padu, id, capL2, tech_owner, organisation, cio_lob, applicationnames } = bookmark;
    let redirectPath = [];
    let queryParams = {};
    switch (path) {
      case 'app':
        redirectPath = [{ outlets: { primary: 'application-insights'} }];
        queryParams = { askId: appId };
        break;
      case 'tech':
        redirectPath = [{ outlets: { primary: 'technology-insights'} }];
        queryParams = { offeringId: offerings };
        break;
      case 'lob':
        redirectPath = [{ outlets: { primary: 'cio-app-insights' } }];
        queryParams = { cio: cio_lob, lob, padu };
        break;
      case 'cap':
        redirectPath = [{ outlets: { primary: 'product-home' } }];
        queryParams = { filterId: id };
        break;
      case 'capDetail':
        redirectPath = [{ outlets: { primary: 'product-home/capability-details' } }];
        queryParams = { capName: capL2, filterId: id };
        break;
      case 'tcm_app':
        redirectPath = [{ outlets: { primary: 'application-cost', sidebar: 'tcmhub' } }];
        queryParams = {
          selectedApps: applicationnames.split(','),
          selectedTechOwner: tech_owner,
          selectedsloOwner: lob,
          selectedGlobalAppid: appId.split(',')
        };
        break;
      case 'tcm_lob':
        redirectPath = [{ outlets: { primary: 'cio-cost', sidebar: 'tcmhub' } }];
        queryParams = { lob: applicationnames.split(','), cio: 'NULL' !== model ? model : null };
        break;

      case 'product_table':
        redirectPath = [{ outlets: { primary: 'uhcproduct-home/table' } }];
        queryParams = { filterId: id };
        break;

      case 'app_product_grid':
        redirectPath = [{ outlets: { primary: 'uhcproduct-home/askid-level-capability' } }];
        queryParams = {
          askId: appId,
          appName: applicationnames,
          organization: organisation
        };
        break;

      case 'product_grid':
        redirectPath = [{ outlets: { primary: 'uhcproduct-home' } }];
        break;

      case 'app_decom':
        redirectPath = [{ outlets: { primary: 'decommission-app' } }];
        queryParams = { filterId: id };
        break;
    }
    this.route.navigate(redirectPath, { queryParams });
  }
}
