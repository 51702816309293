
<header *ngIf="userNameFullDetails && !path" class="header-container">
  <div>
    <img style="float: left; width: 100%;
    padding-right: 10%;" class="uhg-logo" src="/assets/UHGLogo/uhc-logo.svg" />
  </div>

  <div>

    <div>
      <span style="color: #003C71; float: left;" class="toh-seperation-font"> | </span>
    </div>

  </div>
  <div style="width: 100%;
  text-align: center;
 
">
    <!-- <div class="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5" *ngIf="!userNameFullDetails" style="width: 280px !important;"
      [ngClass]="{ hideContent: !userNameFullDetails }">
     
    </div> -->



    <a (click)="redirectToHome()" class="toh-font"
      style="float: left;
         cursor: pointer; margin-left: 4%; color:  #003C71;text-decoration: none !; font-family: 'UHC Sans' !important; font-weight: bold !important;">{{
      appName }}
    </a>

  </div>



  <div class="tcmfacicon tcmfacicon-font" style="display: inline-block;" (click)='redirectToHome()'>
    <div style="width: max-content;"><img class="home-icon" src="/assets/UHGLogo/Home Selected.svg" />
      <span> Home </span></div>
  </div>
  <div class="tcmfacicon tcmfacicon-font manualIcon" style=" display: inline-block;" (click)='redirectToUserManual()'>
    <div style="width: max-content;"><img class="home-icon" src="/assets/UHGLogo/User Manual.png" />
      <span> Documentation </span></div>
  </div>
  <!-- <div class="tcmfacicon tcmfacicon-font manualIcon" style="margin-right: -10px;" (click)='redirectToUserGuide()'>
    <div><img src="/assets/UHGLogo/User Manual.png" />
      <span> User Guide </span></div>
  </div> -->
  <!-- <img class="homeSelectIcon" (click)='redirectToHome()' src="/assets/images/Home Selected.svg" /> -->
  <!--<div class="tcmfacicon tcmfacicon-font" (click)='contactfacilator()'><i class="uitk-icon uitk-icon-email"></i>
    <span> Contact TCM Faciliatator</span></div>-->
  <!-- <img class="manualIcon" (click)='redirectToUserManual()' src="/assets/images/User Manual Copy.svg" /> -->

  <!-- <img  class="bookmarkIcon" src="/assets/images/bookmark.svg" /> -->


  <div *ngIf="userNameFullDetails">
    <div ngbDropdown>
      <a (click)="getBookmarkList()" class="dropdown-toggle-bookmark" id="dropdownBasic2" ngbDropdownToggle>

        <!-- <img ngbDropdown class="bookmarkIcon" src="/assets/images/bookmark.svg" /> -->

        <img class="bookmarkIcon home-icon" style="padding-right: 8px; width: 35px;" src="/assets/UHGLogo/Bookmark Copy@2x.png" />
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="bookmarkpopupCss">
        <!-- <button (click)="logout()" ngbDropdownItem>Logout</button> -->
        <div *ngIf="tcmBookmarkList.length" class="bookmark-heading">TCM HUB</div>
        <div class="bookmark-content" *ngFor="let booklist of tcmBookmarkList; let index = index">
          <div class="bookmark-items">
            <span id={{booklist.id}} (click)="redirect(booklist)" class="bookmark-items">{{ booklist.name }}</span>
          </div>

          <div style="display: flex" *ngIf="visibleIndex !== booklist.id">
            <img title="Click to rename bookmark" id="img1" class="edit-icon"
              (click)="editClick(booklist, booklist.id,'tcm')" src="/assets/images/Group 2 Copy 2-1.svg" />
            <img title="Click to delete bookmark" id="del1" (click)="deleteBookmark(booklist.id, 'tcm')"
              class="delete-icon" src="/assets/images/Group 3 Copy 2.svg" />
          </div>

          <div style="display: flex" *ngIf="visibleIndex === booklist.id">
            <img title="Save changes" id="img2" class="edit-icon" (click)="saveChanges(booklist, booklist.id,'tcm')"
              src="/assets/images/Group 5.svg" />
            <img title="Cancel" id="del2" (click)="cancelChanges(booklist,booklist.id)" class="delete-icon"
              src="/assets/images/Group 4.svg" />
          </div>


        </div>

        <div *ngIf="appBookmarkList.length" class="bookmark-heading">APPLICATION HUB</div>
        <div class="bookmark-content" *ngFor="let booklist of appBookmarkList;let index = index">
          <div class="bookmark-items" id="element">
            <span id={{booklist.id}} style="cursor: pointer;" (click)="redirect(booklist)"
              class="bookmark-items">{{booklist.name}}</span>
          </div>
          <div style="display: flex" *ngIf="visibleIndex !== booklist.id">
            <img title="Click to rename bookmark" id="img1" class="edit-icon"
              (click)="editClick(booklist, booklist.id,'app')" src="/assets/images/Group 2 Copy 2-1.svg" />
            <img title="Click to delete bookmark" id="del1" (click)="deleteBookmark(booklist.id, 'app')"
              class="delete-icon" src="/assets/images/Group 3 Copy 2.svg" />
          </div>
          <div style="display: flex" *ngIf="visibleIndex === booklist.id">
            <img title="Save changes" id="img2" class="edit-icon" (click)="saveChanges(booklist, booklist.id,'app')"
              src="/assets/images/Group 5.svg" />
            <img title="Cancel" id="del2" (click)="cancelChanges(booklist,booklist.id)" class="delete-icon"
              src="/assets/images/Group 4.svg" />
          </div>

        </div>
        <div *ngIf="!appBookmarkList.length && !tcmBookmarkList.length" class="bookmark-heading">No Bookmarks</div>
        <!-- <div class="bookmark-content" *ngFor="let booklist of appBookmarkList">
         
          <span (click)="redirect(booklist)" class="bookmark-items">{{ booklist.name }}</span>
 
          <img class="edit-icon" (click)="editClick(this)"  src="/assets/images/Group 2 Copy 2-1.svg" />
          <img (click)="deleteBookmark(booklist.id, 'app')" class="delete-icon" src="/assets/images/Group 3 Copy 2.svg" />
        
        </div> -->
      </div>
    </div>


  </div>


  <div *ngIf="userNameFullDetails">
    <div ngbDropdown>
      <a class="tcmfacicon-font" style="
         color: #424242; text-decoration: none; cursor: pointer; font-weight: 500; font-family: 'UHC Sans';"
        id="dropdownBasic1" ngbDropdownToggle>
        <img style="padding-right: 8px; width: 32px; height: 32px;" src="/assets/images/FPO_User.svg" /> {{ userNameFullDetails }}</a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="logoutCss">
        <button *ngIf="enablelogin" (click)="logout()" ngbDropdownItem>Logout</button>
        <div class="dropdown-divider"></div>
        <button (click)="navigateToLobMaintenance()" ngbDropdownItem>
          <div class="lob-maintenance">
            <img class="icon add-model-img" src="/assets/images/lob_maintenance.png" />
            <span class="add-model-text">LOB Maintenance </span>
          </div>
        </button>
        <div class="dropdown-divider"></div>
        <button (click)="navigateToOrgMaintenance()" ngbDropdownItem>
          <div class="lob-maintenance">
            <img class="icon add-model-img" src="/assets/images/lob_maintenance.png" />
            <span class="add-model-text">Organization Maintenance </span>
          </div>
        </button>
        <div class="dropdown-divider"></div>
        <button (click)="navigateToGroupMaintenance()" ngbDropdownItem>
          <div class="lob-maintenance">
            <span class="uitk-icon uitk-icon-group sm-icon group-icon" title="Create Group"></span>
            <span class="add-model-text">Group Maintenance </span>
          </div>
        </button>
        <ng-container *ngIf="acccess.hasAdminSecureGrpAccess || acccess.hasAppEditorSecureGrpAccess || acccess.hasModelEditorSecureGrpAccess">
          <div class="dropdown-divider"></div>
          <button (click)="navigateToBulkMaintenance()" ngbDropdownItem>
            <div class="lob-maintenance">
              <img class="icon add-model-img" src="/assets/images/lob_maintenance.png" />
              <span class="add-model-text">Bulk Edit </span>
            </div>
          </button>
        </ng-container>
      </div>
    </div>

  </div>

</header>
<div *ngIf="userNameFullDetails && !path" class="headerLine"></div>



<header *ngIf="userNameFullDetails && path" class="uitk-c-header" style="background-color: #002677; height: 60px; display: flex;
align-items: center;
justify-content: center;
">
  <div>
    <img style="float: left; margin-right: 31px; height: 34px" src="/assets/images/Brand lock-up.svg" />
  </div>

  <div>

    <div>
      <span style="color: white; float: left; font-size: 30px;"> | </span>
    </div>

  </div>
  <div style="width: 100%;
  text-align: center;
 
">
    <!-- <div class="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-5" *ngIf="!userNameFullDetails" style="width: 280px !important;"
      [ngClass]="{ hideContent: !userNameFullDetails }">
     
    </div> -->



    <a (click)="redirectToHome()"
      style="float: left;
         cursor: pointer; margin-left: 5px; margin-top: 2px; color:  white; font-size: 21px; font-weight: 700; text-decoration: none !; font-family: 'UHC-Sans-Bold' !important;">{{
      appName }}
    </a>

  </div>
  <img class="homeSelectIconUHC" (click)='redirectToHome()' src="/assets/images/Home Selected.svg" />
  <!--<div class="tcmfacicon tcmfacicon-font" (click)='contactfacilator()'><i class="uitk-icon uitk-icon-email"></i>
    <span> Contact TCM Faciliatator</span></div>-->
  <img class="manualIconUHC" (click)='redirectToUserManual()' src="/assets/images/User Manual Copy.svg" />

  <!-- <img  class="bookmarkIcon" src="/assets/images/bookmark.svg" /> -->
  <div *ngIf="userNameFullDetails">
    <div ngbDropdown>
      <span (click)="getBookmarkList()" class="bookmarkIcon" id="dropdownBasic2" ngbDropdownToggle>
        <img ngbDropdown class="bookmarkIcon" src="/assets/images/bookmark.svg" /></span>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="bookmarkpopupCss">
        <!-- <button (click)="logout()" ngbDropdownItem>Logout</button> -->
        <div *ngIf="tcmBookmarkList.length" class="bookmark-heading">TCM HUB</div>
        <div class="bookmark-content" *ngFor="let booklist of tcmBookmarkList; let index = index">
          <div class="bookmark-items">
            <span id={{booklist.id}} (click)="redirect(booklist)" class="bookmark-items">{{ booklist.name }}</span>
          </div>

          <div style="display: flex" *ngIf="visibleIndex !== booklist.id">
            <img title="Click to rename bookmark" id="img1" class="edit-icon"
              (click)="editClick(booklist, booklist.id,'tcm')" src="/assets/images/Group 2 Copy 2-1.svg" />
            <img title="Click to delete bookmark" id="del1" (click)="deleteBookmark(booklist.id, 'tcm')"
              class="delete-icon" src="/assets/images/Group 3 Copy 2.svg" />
          </div>

          <div style="display: flex" *ngIf="visibleIndex === booklist.id">
            <img title="Save changes" id="img2" class="edit-icon" (click)="saveChanges(booklist, booklist.id,'tcm')"
              src="/assets/images/Group 5.svg" />
            <img title="Cancel" id="del2" (click)="cancelChanges(booklist,booklist.id)" class="delete-icon"
              src="/assets/images/Group 4.svg" />
          </div>


        </div>

        <div *ngIf="appBookmarkList.length" class="bookmark-heading">APPLICATION HUB</div>
        <div class="bookmark-content" *ngFor="let booklist of appBookmarkList;let index = index">
          <div class="bookmark-items" id="element">
            <span id={{booklist.id}} style="cursor: pointer;" (click)="redirect(booklist)"
              class="bookmark-items">{{booklist.name}}</span>
          </div>
          <div style="display: flex" *ngIf="visibleIndex !== booklist.id">
            <img title="Click to rename bookmark" id="img1" class="edit-icon"
              (click)="editClick(booklist, booklist.id,'app')" src="/assets/images/Group 2 Copy 2-1.svg" />
            <img title="Click to delete bookmark" id="del1" (click)="deleteBookmark(booklist.id, 'app')"
              class="delete-icon" src="/assets/images/Group 3 Copy 2.svg" />
          </div>
          <div style="display: flex" *ngIf="visibleIndex === booklist.id">
            <img title="Save changes" id="img2" class="edit-icon" (click)="saveChanges(booklist, booklist.id,'app')"
              src="/assets/images/Group 5.svg" />
            <img title="Cancel" id="del2" (click)="cancelChanges(booklist,booklist.id)" class="delete-icon"
              src="/assets/images/Group 4.svg" />
          </div>

        </div>
        <div *ngIf="!appBookmarkList.length && !tcmBookmarkList.length" class="bookmark-heading">No Bookmarks</div>
        <!-- <div class="bookmark-content" *ngFor="let booklist of appBookmarkList">
         
          <span (click)="redirect(booklist)" class="bookmark-items">{{ booklist.name }}</span>
 
          <img class="edit-icon" (click)="editClick(this)"  src="/assets/images/Group 2 Copy 2-1.svg" />
          <img (click)="deleteBookmark(booklist.id, 'app')" class="delete-icon" src="/assets/images/Group 3 Copy 2.svg" />
        
        </div> -->
      </div>
    </div>

  </div>


  <div class="uitk-c-header__trigger-container" *ngIf="userNameFullDetails">
    <div ngbDropdown>
      <a class="tcmfacicon-font" style="
         color: white; text-decoration: none; cursor: pointer; font-weight: 500; font-family: 'UHC-Sans-Medium';"
        id="dropdownBasic1" ngbDropdownToggle>
        <img style="padding-right: 8px;" src="/assets/images/FPO_User.svg" /> {{ userNameFullDetails }}</a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="logoutCss">
        <button *ngIf="enablelogin" (click)="logout()" ngbDropdownItem>Logout</button>
        <div class="dropdown-divider"></div>
        <button (click)="navigateToLobMaintenance()" ngbDropdownItem>
          <div class="lob-maintenance">
            <img class="icon add-model-img" src="/assets/images/lob_maintenance.png" />
            <span class="add-model-text">LOB Maintenance </span>
          </div>
        </button>
        <div class="dropdown-divider"></div>
        <button (click)="navigateToOrgMaintenance()" ngbDropdownItem>
          <div class="lob-maintenance">
            <img class="icon add-model-img" src="/assets/images/lob_maintenance.png" />
            <span class="add-model-text">Organization Maintenance </span>
          </div>
        </button>

        <ng-container *ngIf="acccess.hasAdminSecureGrpAccess || acccess.hasAppEditorSecureGrpAccess || acccess.hasModelEditorSecureGrpAccess">
          <div class="dropdown-divider"></div>
          <button (click)="navigateToBulkMaintenance()" ngbDropdownItem>
            <div class="lob-maintenance">
              <img class="icon add-model-img" src="/assets/images/lob_maintenance.png" />
              <span class="add-model-text">Bulk Edit </span>
            </div>
          </button>
        </ng-container>
        <!-- <ng-container *ngIf="isAdminRole">
          <div class="dropdown-divider"></div>
          <button (click)="addModelPopup()" ngbDropdownItem>
            <div class="add-model-maintenance">
              <img class="icon add-model-img" src="/assets/images/pie-chart.svg" />
              <span class="add-model-text"> Add Model </span>
            </div>
          </button>
        </ng-container> -->
      </div>
    </div>

  </div>


</header>
