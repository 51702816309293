import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Inject,
  SimpleChanges,
  QueryList,
  ViewChildren
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { ProductMappingService } from 'src/app/padu-reporting/product-mapping-view/product-mapping.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditReportComponent } from 'src/app/reports/edit-report/edit-report.component';
import { ReportsService } from 'src/app/reports/reports.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
export interface TableFilterConfig {
  placeHolderText: string;
  labelText: string;
  filterCallBack: (event: any) => any;
  filterPredicate?: (item: any, filter: string) => boolean;
}

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TableDataComponent implements OnInit, AfterViewInit, OnChanges {
  isSearchEnabled = false;
  olddatasource: any;
  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private service: ProductMappingService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public dialog: MatDialog,
    private reportsService: ReportsService
  ) {}
  searchField;
  @Input() capabilityModel;
  @Input() checked: boolean = false;
  public product_app_view = true;
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() columns: Array<{
    columnDef: string;
    header: string;
    width: string;
    type: string;
    spanCellStyle: () => object;
    display: string;
    textAlign: string;
    paddingRight: string;
    cellStyle: () => object;
    cell: () => object;
    marginLeft: string;
  }>;
  @Input() TABLE_CONFIG;
  @Input() pageSize;
  @Input() pageIndex: number = 0;
  @Input() tableHeaders: Array<any> = [];
  @Input() filterConfig: TableFilterConfig;
  @Input() searchConfig?: any;
  @Input() tableConfig: any;
  @Input() localFilterConfig: any;
  @Input() expandTable;
  @Input() capabilitySelected: any;
  @Input() orgData: any;
  @ViewChild('pagination', { static: true, read: true }) DynamicPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, { static: false }) DynamicSort: MatSort;
  @Output() rowClick = new EventEmitter<any>();
  @Output() cellClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() localFilterServerSidePageEvent: EventEmitter<any> = new EventEmitter();
  @Input() sortConfig: Sort;
  @Input() localFilter: boolean = false;
  displayedColumns: Array<string>;
  tableHeaderColumn: Array<string>;
  selection = new SelectionModel<PeriodicElement>(true, []);
  @Input() SelectAll = false;
  @Input() displaySelectAll = true;
  @Input() setStakeholderView = false;
  @Output() onCheckClick: EventEmitter<any> = new EventEmitter();
  @Output() onChildClick: EventEmitter<any> = new EventEmitter();
  allSelected: boolean = false;
  public dataReady = false;
  public oldlength: any;
  public isExpand: boolean = false;
  tooltipInfo = 'Benefits';
  decomColumn: any;
  plmColumn: any;
  model: string;
  @Input() hidecolumn = false;
  @Input() productView = '';
  @Input() initialTableData;
  @Input() FinalTableData;
  @Input() stackHolderColumns;
  finalTableData = [];
  @Input() togglecolumn: any = [];
  defaultData;
  filterList = [];
  filterListR: any;
  filValue = [];
  REPFilter = [];
  REPCategoryFilter = [];
  DSCFilter = [];
  TYPEFilter = [];
  Allfilter = [];
  assetIdFilter = [];
  assetNameFilter = [];
  assetOwnerFilter = [];
  assetTeamFilter = [];
  writeAcess = JSON.parse(localStorage.getItem('isWriteAccess'));
  searchValue = '';
  firstClickOnFilter = false;
  isCheckedShowAllColumn = true;
  selectedFilteredValue = {
    appName: ''
  };
  public selectedRowIndex: any = '';
  @Input() rowExpandable: any;
  tableTotalItems;
  @Output() paginationClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() sortChangeEvent: EventEmitter<any> = new EventEmitter();
  prodInitial: any;
  prodFamilyInitial: any;
  productId: any;
  productFamilyId: any;
  expandedElement: any;
  tempOrgID = 0;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @Input() paduData;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  filterApplied: boolean = false;
  filterAssetIdApplied: boolean = false;
  filterAssetNameApplied: boolean = false;
  filterAssetOwnerApplied: boolean = false;
  filterAssetTeamApplied: boolean = false;
  filterApplicationNameApplied: boolean = false;
  filterApplicationIdApplied: boolean = false;
  filterApplicationPADUApplied: boolean = false;

  ClickIgnoreColumns = ['Request', 'static_columns', 'column1', 'MOM', 'Roster_Status'];
  @Output() datarefresh: EventEmitter<any> = new EventEmitter();
  previousSelection:Array<any> = [];
  searchFilterLists :Array<any> = [];

  ngOnChanges(changes: SimpleChanges) {
    this.dataReady = false;

    if (changes['capabilitySelected']) {
      let valueChange = false;
      if (this.capabilitySelected && this.capabilitySelected.type && this.capabilitySelected.orgIDS) {
        this.dataSource.data.forEach(row => {
          if (this.capabilitySelected.type == 'allDeSelected') {
            this.selection.clear();
            valueChange = true;
          } else if (
            this.capabilitySelected.type == 'allSelected' &&
            this.capabilitySelected.orgIDS.length > 0 &&
            this.capabilitySelected.orgIDS.includes(row.tempOrgID)
          ) {
            valueChange = true;
            this.selection.select(row);
          } else if (
            this.capabilitySelected.orgIDS.length > 0 &&
            this.capabilitySelected.orgIDS.includes(row.tempOrgID)
          ) {
            valueChange = true;
            if (this.capabilitySelected.type == 'selected') {
              this.selection.select(row);
            } else {
              this.selection.deselect(row);
            }
          }
        });
        if (valueChange) {
          this.onCheckClick.emit({ check: false, data: this.selection.selected, tempOrgID: this.tempOrgID });
        }
      }
    }

    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {
      if (
        changes.dataSource &&
        changes.dataSource.currentValue &&
        changes.dataSource.previousValue &&
        changes.dataSource.currentValue.filteredData != changes.dataSource.previousValue.filteredData
      ) {
        console.log('Empty Decleartion');
      }

      if (this.searchConfig && this.searchConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.searchConfig.filterPredicate;
      }
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }
      if (this.dataSource.data[0].tempOrgID) {
        this.tempOrgID = this.dataSource.data[0].tempOrgID;
      }

      if (this.tableConfig && this.tableConfig.serverSidePagination) {
        this.tableTotalItems = this.tableConfig.totalItems;
        this.dataSource.paginator = this.DynamicPaginator;

        if (this.sortConfig && this.DynamicSort) {
          this.DynamicSort.active = this.sortConfig.active;
          this.DynamicSort.direction = this.sortConfig.direction;
          this.dataSource.sort = this.DynamicSort;
        }
      } else {
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort;
        if (this.sortConfig && this.sort) {
          this.sort.active = this.sortConfig.active || '';
          this.sort.direction = this.sortConfig.direction;
          this.sort.sortChange.emit(this.sortConfig);
        }
      }
      this.dataReady = true;
    } else {
      this.dataReady = false;
    }

    if (this.checked) this.viewChange(this.checked);
    this.removeLinkToTechPadu();
  }

  removeLinkToTechPadu() {
    let element = this._document.querySelectorAll('.link-click');
    if (element && element.length > 0) {
      element.forEach(ele => {
        let childNode = ele.getElementsByClassName('no-link');
        if (childNode && childNode.length > 0) {
          ele.classList.replace('link-click', 'no-link');
        }
      });
    }
  }
  getRecord(row, col) {
    const obj = { row, col };
    this.rowClick.emit(obj);
  }
  getcellRecord(row, col) {
    const obj = { row, col };
    this.cellClick.emit(obj);
  }
  ngOnInit() {
    this.allSelected = false;
    this.displayedColumns = this.columns.map(element => element.columnDef);
    this.tableHeaderColumn = this.tableHeaders.map(item => item.def);
    this.router.queryParams.subscribe(details => {
      this.model = details.model || '';
    });
    this.defaultData = this.dataSource && this.dataSource.data;
    this.prodInitial = this.router.snapshot.queryParamMap.get('product');
    this.prodFamilyInitial = this.router.snapshot.queryParamMap.get('product_family');
    this.productId = this.router.snapshot.queryParamMap.get('product_id');
    this.productFamilyId = this.router.snapshot.queryParamMap.get('family_id');
    this.olddatasource = this.dataSource.data;
  }
  ngDoCheck() {
    this.removeLinkToTechPadu();
  }
  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data.length) {
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }

      if (this.tableConfig && this.tableConfig.serverSidePagination) {
        this.tableTotalItems = this.tableConfig.totalItems;
        this.dataSource.paginator = this.DynamicPaginator;
        if (this.sortConfig && this.DynamicSort) {
          this.DynamicSort.active = this.sortConfig.active || '';
          this.DynamicSort.direction = this.sortConfig.direction;
        }

        this.dataSource.sort = this.DynamicSort;
      } else {
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort;
      }

      if (this.tableConfig && this.tableConfig.searchString) {
        this.searchField = this.tableConfig.searchString;
      }
    }
    if (this.checked) {
      this.viewChange(this.checked);
    }
  }

  evaluateFunction(column, element) {
    let output = {};
    if (column.cellStyle) {
      output = column.cellStyle(element);
    }

    if (column.type === 'number') {
      output['text-align-right'] = true;
    }
    if (column.type === 'textnumber') {
      output['text-align-center'] = true;
    }
    if (column.type === 'html') {
      output['text-align-left'] = true;
    }
    return output;
  }

  // evaluateSpanSpecificClass(column, element) {
  //   let output = {};
  //   if (column.spanCellStyle) {
  //     output = column.spanCellStyle(element);
  //   }
  //   return output;
  // }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.onChildClick.emit({ childCheck: numSelected === numRows, data: [] });
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.onCheckClick.emit({ check: false, data: [], tempOrgID: this.tempOrgID });
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row));
      this.onCheckClick.emit({ check: true, data: this.selection.selected, tempOrgID: this.tempOrgID });
    }
  }

  selecttoggle() {
    if (this.selection.selected.length > 0) {
      this.onCheckClick.emit({ check: true, data: this.selection.selected, tempOrgID: this.tempOrgID });
    } else {
      this.onCheckClick.emit({ check: false, data: [], tempOrgID: this.tempOrgID });
    }
  }
  onClickClearSearchField() {
    this.searchField = '';
    if (this.getTableClass()) {
      this.filterConfig.filterCallBack('');
    }
  }

  redirectToAppView(element) {
    const { app_id } = element;
    this.route.navigate(['application-insights'], { queryParams: { askId: app_id } });
  }

  getTooltipInfo(column, element) {
    if (column.description) {
      if (column.description.value) {
        switch (column.columnDef) {
          case 'product':
            if (element.productDescription) {
              return `${element.productDescription || 'No Description Available'}`;
            } else {
              return 'No Description Available';
            }

          case 'product_family':
            if (element.productFamilyDescription) {
              return `${element.productFamilyDescription || 'No Description Available'}`;
            } else {
              return 'No Description Available';
            }

          case 'current':
            return `Transition 1 : ${element.Transition1},
                    Transition 2 : ${element.Transition2},
                    Transition 3 : ${element.Transition3},
                    Transition 4 : ${element.Transition4},
                    Transition 5 : ${element.Transition5},
                    Transition 6 : ${element.Transition6}`;

          default:
            return `${column.description.name}: ${column.cell(element)}`;
        }
      } else {
        if (typeof column.description == 'function') {
          let descResponse = column.description(element);
          if (descResponse) {
            return `${descResponse}`;
          }
        } else {
          return `${column.description.name}`;
        }
      }
    }
  }
  public viewChange(value) {
    if (value) {
      this.isCheckedShowAllColumn = false;
      this.product_app_view = false;
      const unique = (arr, props = []) => [
        ...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()
      ];
      const newArr = unique(this.dataSource.data, [
        'product_family',
        'product',
        'productManager',
        'productOwner',
        'deliveryOwner'
      ]);
      this.dataSource.data = newArr;
      this.defaultData = newArr;
      this.columns.forEach(element => {
        if (element.display === 'none') {
          this.togglecolumn.push(element.columnDef);
        }
        delete element.display;
        if (!this.stackHolderColumns.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
    } else {
      this.isCheckedShowAllColumn = true;
      this.columns.forEach(element => {
        delete element.display;
        if (this.togglecolumn.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
      if (this.productView === 'product_view') {
        this.dataSource.data = this.FinalTableData;
        this.defaultData = this.initialTableData;
      }
    }
  }

  checkEditFeasibility(column) {
    if (column.columnDef === 'action') {
      return false;
    } else if (column.columnDef === 'action-edit') {
      return true;
    } else {
      return false;
    }
  }

  checkDeleteRow(col) {
    if (col.columnDef === 'deleterow') return true;
  }

  filterDropdown(column) {
    let result;
    let result1;
    let result2;
    let result3;
    let result4;
    let result5;
    switch (column.columnDef) {
      case 'app_id':
        result = this.localFilterConfig.filter.filter(d => d.filterName === 'app_id');
        this.filterList = result[0].filterValue.map(i => ({ name: i.app_id, selected: false }));

        break;

      case 'application':
        result1 = this.localFilterConfig.filter.filter(d => d.filterName === 'application');
        this.filterList = result1[0].filterValue.map(i => ({ name: i.app_name, id: i.app_id, selected: false }));
        break;

      case 'lob':
        result2 = this.localFilterConfig.filter.filter(d => d.filterName === 'lob');
        this.filterList = result2[0].filterValue.map(i => ({ name: i, selected: false }));
        break;

      case 'product_family':
        result3 = this.localFilterConfig.filter.filter(d => d.filterName === 'product_family');
        this.filterList = result3[0].filterValue.map(i => ({ name: i.product_family, selected: false }));
        break;

      case 'product':
        result4 = this.localFilterConfig.filter.filter(d => d.filterName === 'product');
        this.filterList = result4[0].filterValue.map(i => ({ name: i.product, selected: false }));
        break;

      case 'organization':
        result5 = this.localFilterConfig.filter.filter(d => d.filterName === 'organization');
        this.filterList = result5[0].filterValue.map(i => ({ name: i, selected: false }));
        break;
    }
    return true;
  }

  applyLocalFilter(list, column) {
    const obj = {
      list: list,
      column: column,
      type: 'apply'
    };
    this.localFilterServerSidePageEvent.emit(obj);
  }

  clearLocalFilter(list, column) {
    this.previousSelection = [];
    const obj = {
      list: list,
      column: column,
      type: 'clear'
    };
    this.localFilterServerSidePageEvent.emit(obj);
  }

  checkLocalFilerValid(col) {
    if (this.localFilterConfig && this.localFilterConfig.localFilter) {
      switch (col) {
        case 'action':
        case 'productManager':
        case 'productOwner':
        case 'deliveryOwner':
          return false;

        case 'app_id':
        case 'application':
        case 'lob':
        case 'product_family':
        case 'product':
        case 'organization':
          return true;
      }
    } else {
      return false;
    }
  }

  valueSearchSave(val, column) {
    switch (column.columnDef) {
      case 'application':
        this.selectedFilteredValue.appName = val;
    }
  }
  filterLists: any = [];

  filterDropdownA(column) {
    this.searchValue = '';
    if (this.Allfilter.length == 0) {
      if (column.columnDef == 'assetId') {
        this.filterListR = this.olddatasource.map(item => {
          return item.assetId;
        });
        this.filValue = this.assetIdFilter;
        this.trigger.toArray()[0].openMenu();
      } else if (column.columnDef == 'assetName') {
        this.filterListR = this.olddatasource.map(item => {
          return item.assetName;
        });
        this.filValue = this.assetNameFilter;
        this.trigger.toArray()[1].openMenu();
      } else if (column.columnDef == 'owner') {
        this.filterListR = this.olddatasource.map(item => {
          return item.owner;
        });
        this.filValue = this.assetOwnerFilter;
        this.trigger.toArray()[2].openMenu();
      } else if (column.columnDef == 'ownerAssetTeam') {
        this.filterListR = this.olddatasource.map(item => {
          return item.ownerAssetTeam;
        });
        this.filValue = this.assetTeamFilter;
        this.trigger.toArray()[3].openMenu();
      }
      this.filterListR = new Set([...this.filterListR]);
      this.filterLists = this.filterListR;
    } else {
      if (column.columnDef == 'assetId') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.assetId;
        });
        this.filValue = this.assetIdFilter;
        this.trigger.toArray()[0].openMenu();
      } else if (column.columnDef == 'assetName') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.assetName;
        });
        this.filValue = this.assetNameFilter;
        this.trigger.toArray()[1].openMenu();
      } else if (column.columnDef == 'owner') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.owner;
        });
        this.filValue = this.assetOwnerFilter;
        this.trigger.toArray()[2].openMenu();
      } else if (column.columnDef == 'ownerAssetTeam') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.ownerAssetTeam;
        });
        this.filValue = this.assetTeamFilter;
        this.trigger.toArray()[3].openMenu();
      }
      this.filterListR = new Set([...this.filterListR]);
      this.filterLists = this.filterListR;
    }
  }
  filterDropdownR(column) {
    this.searchValue = '';
    if (this.Allfilter.length == 0) {
      if (column.header == 'Report') {
        this.filterListR = this.olddatasource.map(item => {
          return item.Report;
        });
        this.filValue = this.REPFilter;
        this.trigger.toArray()[0].openMenu();
      }
      if (column.header == 'Report Category') {
        this.filterListR = this.olddatasource.map(item => {
          return item.Category;
        });
        this.filValue = this.REPCategoryFilter;
        this.trigger.toArray()[1].openMenu();
      }
      if (column.header == 'Description') {
        this.filterListR = this.olddatasource.map(item => {
          return item.Description;
        });
        this.filValue = this.DSCFilter;
        this.trigger.toArray()[2].openMenu();
      }
      if (column.header == 'Type') {
        this.filterListR = this.olddatasource.map(item => {
          return item.Report_Type;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[3].openMenu();
      }

      this.filterListR = new Set([...this.filterListR]);
      this.filterLists = this.filterListR;
    } else {
      if (column.header == 'Report') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.Report;
        });
        this.filValue = this.REPFilter;
        this.trigger.toArray()[0].openMenu();
      }
      if (column.header == 'Report Category') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.Category;
        });
        this.filValue = this.REPCategoryFilter;
        this.trigger.toArray()[1].openMenu();
      }
      if (column.header == 'Description') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.Description;
        });
        this.filValue = this.DSCFilter;
        this.trigger.toArray()[2].openMenu();
      }
      if (column.header == 'Type') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.Report_Type;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[3].openMenu();
      }
      this.filterListR = new Set([...this.filterListR]);
      this.filterLists = this.filterListR;
    }
  }
  applyLocalFilterR() {
    let filterdata = this.olddatasource;
    this.Allfilter.forEach(e1 => {
      if (e1.column == 'Report') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.Report));
          this.filterListR = filterdata.map(item => {
            return item.Report;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[0].closeMenu();
        }
      } else if (e1.column == 'Report Category') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.Category));
          this.filterListR = filterdata.map(item => {
            return item.Category;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[1].closeMenu();
        }
      } else if (e1.column == 'Description') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.Description));
          this.filterListR = filterdata.map(item => {
            return item.Description;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[2].closeMenu();
        }
      } else if (e1.column == 'Type') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.Report_Type));
          this.filterListR = filterdata.map(item => {
            return item.Report_Type;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[3].closeMenu();
        }
      } else if (e1.column == 'ApplicationID') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.ApplicationID));
          this.filterListR = filterdata.map(item => {
            return item.ApplicationID;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterApplied = true;
          this.filterApplicationIdApplied = true;
          this.trigger.toArray()[0].closeMenu();
        }else
          this.filterApplicationIdApplied = false;
      } else if (e1.column == 'ApplicationName') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.ApplicationName));
          this.filterListR = filterdata.map(item => {
            return item.ApplicationName;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterApplied = true;
          this.filterApplicationNameApplied = true;
          this.trigger.toArray()[1].closeMenu();
        }else
          this.filterApplicationNameApplied = false;
      } else if (e1.column == 'Capability PADU') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.CapabilityPADU));
          this.filterListR = filterdata.map(item => {
            return item.CapabilityPADU;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[2].closeMenu();
        }
      } else if (e1.column == 'Application PADU') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.ApplicationPADU));
          this.filterListR = filterdata.map(item => {
            return item.ApplicationPADU;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterApplied = true;
          this.filterApplicationPADUApplied = true;
          this.trigger.toArray()[2].closeMenu();
        }else
          this.filterApplicationPADUApplied = false;
      } else if (e1.column == 'Service_Description') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.Service_Description));
          this.filterListR = filterdata.map(item => {
            return item.Service_Description;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterApplied = true;
          this.trigger.toArray()[2].closeMenu();
        }
      } else if (e1.column == 'server') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.CS_SERVICE_VIRTUAL));
          this.filterListR = filterdata.map(item => {
            return item.CS_SERVICE_VIRTUAL;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterApplied = true;
          this.trigger.toArray()[0].closeMenu();
        }
      } else if (e1.column == 'assetId') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.assetId));
          this.filterListR = filterdata.map(item => {
            return item.assetId;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterAssetIdApplied = true;
          this.trigger.toArray()[0].closeMenu();
        }
      } else if (e1.column == 'assetName') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.assetName));
          this.filterListR = filterdata.map(item => {
            return item.assetName;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterAssetNameApplied = true;
          this.trigger.toArray()[1].closeMenu();
        }
      } else if (e1.column == 'owner') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.owner));
          this.filterListR = filterdata.map(item => {
            return item.owner;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterAssetOwnerApplied = true;
          this.trigger.toArray()[2].closeMenu();
        }
      } else if (e1.column == 'ownerAssetTeam') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter(item => e1.value.some(e => e == item.ownerAssetTeam));
          this.filterListR = filterdata.map(item => {
            return item.ownerAssetTeam;
          });
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort;
          this.filterAssetTeamApplied = true;
          this.trigger.toArray()[3].closeMenu();
        }
      }
    });
    if (this.Allfilter.length == 0) {
      this.dataSource = new MatTableDataSource(this.olddatasource);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort;
      this.filterApplied = false;
      this.filterAssetIdApplied = false;
      this.filterAssetNameApplied = false;
      this.filterAssetOwnerApplied = false;
      this.filterAssetTeamApplied = false;
      this.filterApplicationIdApplied = false;
      this.filterApplicationNameApplied = false;
      this.filterApplicationPADUApplied = false;
    }
    this.trigger.toArray()[0].closeMenu();
    this.trigger.toArray()[1].closeMenu();
    this.trigger.toArray()[2].closeMenu();
    if(this.trigger.toArray()[3])
      this.trigger.toArray()[3].closeMenu();
  }
  clearLocalFilterR(column) {
    this.trigger.toArray()[0].closeMenu();
    if (this.trigger.toArray()[1]) {
      this.trigger.toArray()[1].closeMenu();
    }
    if (this.trigger.toArray()[2]) {
      this.trigger.toArray()[2].closeMenu();
    }
    if (this.trigger.toArray()[3]) {
      this.trigger.toArray()[3].closeMenu();
    }

    if (column.header == 'Report') {
      this.REPFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Report') this.Allfilter.splice(i, 1);
      });
    } else if (column.header == 'Report Category') {
      this.REPCategoryFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Report Category') this.Allfilter.splice(i, 1);
      });
    } else if (column.header == 'Description') {
      this.DSCFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Description') this.Allfilter.splice(i, 1);
      });
    } else if (column.header == 'Type') {
      this.TYPEFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Type') this.Allfilter.splice(i, 1);
      });
    } else if (column.header == 'ApplicationID') {
      this.REPFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'ApplicationID') this.Allfilter.splice(i, 1);
      });
      this.filterApplicationIdApplied = false;
    } else if (column.header == 'ApplicationName') {
      this.DSCFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'ApplicationName') this.Allfilter.splice(i, 1);
      });
      this.filterApplicationNameApplied = false;
    } else if (column.header == 'Capability PADU') {
      this.TYPEFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Capability PADU') this.Allfilter.splice(i, 1);
      });
    } else if (column.header == 'Application PADU') {
      this.TYPEFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Application PADU') this.Allfilter.splice(i, 1);
      });
      this.filterApplicationPADUApplied = false;
    } else if (column.header == 'SERVICE') {
      this.TYPEFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Service_Description') this.Allfilter.splice(i, 1);
      });
      this.filterApplied = false;
    } else if (column.header == 'Server') {
      this.TYPEFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'server') this.Allfilter.splice(i, 1);
      });
      this.filterApplied = false;
    } else if (column.columnDef == 'assetId') {
      this.assetIdFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'assetId') this.Allfilter.splice(i, 1);
      });
      this.filterAssetIdApplied = false;
    } else if (column.columnDef == 'assetName') {
      this.assetNameFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'assetName') this.Allfilter.splice(i, 1);
      });
      this.filterAssetNameApplied = false;
    } else if (column.columnDef == 'owner') {
      this.assetOwnerFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'owner') this.Allfilter.splice(i, 1);
      });
      this.filterAssetOwnerApplied = false;
    } else if (column.columnDef == 'ownerAssetTeam') {
      this.assetTeamFilter = [];
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'ownerAssetTeam') this.Allfilter.splice(i, 1);
      });
      this.filterAssetTeamApplied = false;
    }
    this.applyLocalFilterR();
  }
  selectionChangeR(event, column) {
    let newevent;
    if (column.header == 'Report') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Report') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.REPFilter);
      if (newevent.source._value.length > 0) {
        this.REPFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Report', value: newevent.source._value });
      } else {
        this.REPFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    } else if (column.header == 'Report Category') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Report Category') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.REPCategoryFilter);
      if (newevent.source._value.length > 0) {
        this.REPCategoryFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Report Category', value: newevent.source._value });
      } else {
        this.REPCategoryFilter = [];
      }
      this.trigger.toArray()[1].openMenu();
    } else if (column.header == 'Description') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Description') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.DSCFilter);
      if (newevent.source._value.length > 0) {
        this.DSCFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Description', value: newevent.source._value });
      } else {
        this.DSCFilter = [];
      }
      this.trigger.toArray()[2].openMenu();
    } else if (column.header == 'Type') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Type') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.TYPEFilter);
      if (newevent.source._value.length > 0) {
        this.TYPEFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Type', value: newevent.source._value });
      } else {
        this.TYPEFilter = [];
      }
      this.trigger.toArray()[3].openMenu();
    } else if (column.header == 'ApplicationID') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'ApplicationID') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.REPFilter);
      if (newevent.source._value.length > 0) {
        this.REPFilter = newevent.source._value;
        this.Allfilter.push({ column: 'ApplicationID', value: newevent.source._value });
      } else {
        this.REPFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    } else if (column.header == 'ApplicationName') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'ApplicationName') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.DSCFilter);
      if (newevent.source._value.length > 0) {
        this.DSCFilter = newevent.source._value;
        this.Allfilter.push({ column: 'ApplicationName', value: newevent.source._value });
      } else {
        this.DSCFilter = [];
      }
      this.trigger.toArray()[1].openMenu();
    } else if (column.header == 'Capability PADU') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Capability PADU') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.TYPEFilter);
      if (newevent.source._value.length > 0) {
        this.TYPEFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Capability PADU', value: newevent.source._value });
      } else {
        this.TYPEFilter = [];
      }
      this.trigger.toArray()[2].openMenu();
    } else if (column.header == 'Application PADU') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Application PADU') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.TYPEFilter);
      if (newevent.source._value.length > 0) {
        this.TYPEFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Application PADU', value: newevent.source._value });
      } else {
        this.TYPEFilter = [];
      }
      this.trigger.toArray()[2].openMenu();
    } else if (column.header == 'SERVICE') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'Service_Description') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.TYPEFilter);
      if (newevent.source._value.length > 0) {
        this.TYPEFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Service_Description', value: newevent.source._value });
      } else {
        this.TYPEFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    } else if (column.header == 'Server') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'server') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.TYPEFilter);
      if (newevent.source._value.length > 0) {
        this.TYPEFilter = newevent.source._value;
        this.Allfilter.push({ column: 'server', value: newevent.source._value });
      } else {
        this.TYPEFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    } else if (column.columnDef == 'assetId') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'assetId') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.assetIdFilter);
      if (newevent.source._value.length > 0) {
        this.assetIdFilter = newevent.source._value;
        this.Allfilter.push({ column: 'assetId', value: newevent.source._value });
      } else {
        this.assetIdFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    } else if (column.columnDef == 'assetName') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'assetName') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.assetNameFilter);
      if (newevent.source._value.length > 0) {
        this.assetNameFilter = newevent.source._value;
        this.Allfilter.push({ column: 'assetName', value: newevent.source._value });
      } else {
        this.assetNameFilter = [];
      }
      this.trigger.toArray()[1].openMenu();
    } else if (column.columnDef == 'owner') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'owner') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.assetOwnerFilter);
      if (newevent.source._value.length > 0) {
        this.assetOwnerFilter = newevent.source._value;
        this.Allfilter.push({ column: 'owner', value: newevent.source._value });
      } else {
        this.assetOwnerFilter = [];
      }
      this.trigger.toArray()[2].openMenu();
    } else if (column.columnDef == 'ownerAssetTeam') {
      this.Allfilter.forEach((a, i) => {
        if (a.column == 'ownerAssetTeam') this.Allfilter.splice(i, 1);
      });
      newevent = this.checkPrevious(event,this.assetTeamFilter);
      if (newevent.source._value.length > 0) {
        this.assetTeamFilter = newevent.source._value;
        this.Allfilter.push({ column: 'ownerAssetTeam', value: newevent.source._value });
      } else {
        this.assetTeamFilter = [];
      }
      this.trigger.toArray()[3].openMenu();
    }
  }
  clearallFilter() {
    if (this.trigger.toArray()[0]) {
      this.trigger.toArray()[0].closeMenu();
    }
    if (this.trigger.toArray()[1]) {
      this.trigger.toArray()[1].closeMenu();
    }
    if (this.trigger.toArray()[2]) {
      this.trigger.toArray()[2].closeMenu();
    }
    if (this.trigger.toArray()[3]) {
      this.trigger.toArray()[3].closeMenu();
    }
    this.REPFilter = [];
    this.REPCategoryFilter = [];
    this.DSCFilter = [];
    this.TYPEFilter = [];
    this.assetIdFilter = [];
    this.assetNameFilter = [];
    this.assetOwnerFilter = [];
    this.assetTeamFilter = [];
    this.Allfilter = [];
    this.dataSource = new MatTableDataSource(this.olddatasource);
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort;
    this.filterApplied = false;
    this.filterAssetIdApplied = false;
    this.filterAssetNameApplied = false;
    this.filterAssetOwnerApplied = false;
    this.filterAssetTeamApplied = false;
    this.filterApplicationIdApplied = false;
    this.filterApplicationNameApplied = false;
    this.filterApplicationPADUApplied = false;
  }
  searchR(query: string) {
    this.searchFilterLists = this.filterLists;
    let result = this.selectR(query);
    this.searchFilterLists = result;
  }
  selectR(query: string): string[] {
    let result: string[] = [];
    for (let a of this.filterListR) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a);
      }
    }
    return result;
  }
  navigateUrl(element: any) {
    if(element.EMbed == "Yes")
    this.route.navigate(['/reports'], { queryParams:{Report: element.Report }});
    else
    window.open(element.Report_Link, '_blank');
  }
  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', item);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  showToolTip(secure_group: any) {
    return 'Request Access to ' + secure_group + ' to view Report';
  }
  showDocumentionInfo(docuMentUrl: any) {
    window.open(docuMentUrl, '_blank');
  }
  getTableClass(type = '') {
    if (this.tableConfig && this.tableConfig.tableClass) {
      if (type) {
        return this.tableConfig.tableClass + '_' + type;
      } else {
        return this.tableConfig.tableClass;
      }
    }
    return '';
  }
  fetchMoreItems(event) {
    if (this.sortConfig) {
      event.sort = [[this.sortConfig.active, this.sortConfig.direction]];

      if (this.searchField) {
        event.search = this.searchField;
      }
    }
    this.paginationClickEvent.emit(event);
    return false;
  }

  sortData(event) {
    if (this.tableConfig.serverSidePagination) {
      let sort: any[] = [];
      if (event.direction) {
        sort = [[event.active, event.direction]];
      }
      this.sortChangeEvent.emit(sort);
    }
  }

  checkToolTip(column) {
    let status = false;
    switch (column.columnDef) {
      case 'Employee_Count':
        status = true;
        break;
    }
    return status;
  }

  getToolTipContent(column) {
    let content: any;
    switch (column.columnDef) {
      case 'Employee_Count':
        content = 'Headcount calculated based on billable utilization';
        break;
    }
    return content;
  }
  checkForHover(column) {
    let hoverStatus = false;
    switch (column) {
      case 'appName3':
        hoverStatus = true;
        this.isExpand = true;
        break;
    }
    return hoverStatus;
  }

  getColumValue(column, ele) {
    if (column == 'appName3') {
      let temp = this.selectedRowIndex;
      if (temp == ele.appName3) {
        this.selectedRowIndex = '';
      } else {
        this.selectedRowIndex = ele.appName3;
      }
      this.isExpand = true;
    } else {
      this.isExpand = false;
    }
  }

  getClassHtmlClassName(column, element) {
    if (column['columnLink']) {
      return '';
    }
    if (
      this.tableConfig &&
      ['lbr_type_tble_class', 'dept_tble_class', 'emp_tble_class'].includes(this.tableConfig['tableClass'])
    ) {
      if (element[column['columnDef']]) {
        return 'link-click';
      }
    } else {
      return 'link-click';
    }
  }

  childSearchCallback(event) {
    const currentSearchedValue = event.target.value;
    if (!this.searchConfig.isServerSideSearch) {
      if (this.searchConfig.validateSearch !== undefined) {
        const validateResult = this.searchConfig.validateSearch(currentSearchedValue);
        if (validateResult) {
          this.dataSource.filter = currentSearchedValue;
        } else {
          this.dataSource.filter = '';
        }
      } else {
        this.dataSource.filter = currentSearchedValue;
      }
    } else {
      this.searchConfig.onServerSearch(currentSearchedValue);
    }
  }
  closeSearch() {
    this.dataSource.filter = '';
  }

  getSelectedRowC() {
    return this.selection.selected;
  }

  getServiceInfo(details) {
    return details.SV_DESC ? `Service Description:${details.SV_DESC}` : '';
  }
  checkFlagCondition(column) {
    let padu = this.paduData.lowest_padu;
    if (column.columnDef == 'current') {
      return false;
    } else {
      switch (column.columnDef) {
        case 'transition_1':
          if (
            padu['transition_1'] &&
            padu['current'] == padu['transition_1'] &&
            padu['transition_1'].toLocaleLowerCase() == 'u'
          ) {
            return true;
          }
          break;
        case 'transition_2':
          if (
            padu['transition_2'] &&
            padu['transition_1'] == padu['transition_2'] &&
            padu['transition_2'].toLocaleLowerCase() == 'u'
          ) {
            return true;
          } else {
            if (
              padu['transition_2'] &&
              padu['current'] == padu['transition_1'] &&
              padu['transition_1'] == padu['transition_2'] &&
              padu['transition_2'].toLocaleLowerCase() == 'd'
            ) {
              return true;
            }
          }
          break;
        case 'transition_3':
          if (
            padu['transition_3'] &&
            padu['transition_2'] == padu['transition_3'] &&
            padu['transition_3'].toLocaleLowerCase() == 'u'
          ) {
            return true;
          } else {
            if (
              padu['transition_3'] &&
              padu['transition_1'] == padu['transition_2'] &&
              padu['transition_2'] == padu['transition_3'] &&
              padu['transition_2'].toLocaleLowerCase() == 'd'
            ) {
              return true;
            }
          }
          break;
        case 'transition_4':
          if (
            padu['transition_4'] &&
            padu['transition_3'] == padu['transition_4'] &&
            padu['transition_4'].toLocaleLowerCase() == 'u'
          ) {
            return true;
          } else {
            if (
              padu['transition_4'] &&
              padu['transition_2'] == padu['transition_3'] &&
              padu['transition_3'] == padu['transition_4'] &&
              padu['transition_4'].toLocaleLowerCase() == 'd'
            ) {
              return true;
            }
          }
          break;
        case 'transition_5':
          if (
            padu['transition_5'] &&
            padu['transition_4'] == padu['transition_5'] &&
            padu['transition_5'].toLocaleLowerCase() == 'u'
          ) {
            return true;
          } else {
            if (
              padu['transition_5'] &&
              padu['transition_3'] == padu['transition_4'] &&
              padu['transition_4'] == padu['transition_5'] &&
              padu['transition_5'].toLocaleLowerCase() == 'd'
            ) {
              return true;
            }
          }
          break;
        case 'transition_6':
          if (
            padu['transition_6'] &&
            padu['transition_5'] == padu['transition_6'] &&
            padu['transition_6'].toLocaleLowerCase() == 'u'
          ) {
            return true;
          } else {
            if (
              padu['transition_6'] &&
              padu['transition_4'] == padu['transition_5'] &&
              padu['transition_5'] == padu['transition_6'] &&
              padu['transition_6'].toLocaleLowerCase() == 'd'
            ) {
              return true;
            }
          }
          break;
      }
    }
  }
  getFlagStyle() {
    let style = { color: 'red', 'font-size': '16px' };
    let color = this.paduData.RoadMap_Flag;
    if (color && color != 'Not Flagged') {
      style = { color: color.toLowerCase(), 'font-size': '16px' };
    }
    return style;
  }

  showhidecolumn() {}

  filterDropdownforAssociatedCapabilitiesPopup(column) {
    this.searchValue = '';
    if (this.Allfilter.length == 0) {
      if (column.header == 'ApplicationID') {
        this.filterListR = this.olddatasource.map(item => {
          return item.ApplicationID;
        });
        this.filValue = this.REPFilter;
        this.trigger.toArray()[0].openMenu();
      } else if (column.header == 'ApplicationName') {
        this.filterListR = this.olddatasource.map(item => {
          return item.ApplicationName;
        });
        this.filValue = this.DSCFilter;
        this.trigger.toArray()[1].openMenu();
      } else if (column.header == 'Capability PADU') {
        this.filterListR = this.olddatasource.map(item => {
          return item.CapabilityPADU;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[2].openMenu();
      } else if (column.header == 'Application PADU') {
        this.filterListR = this.olddatasource.map(item => {
          return item.ApplicationPADU;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[2].openMenu();
      } else if (column.header == 'SERVICE') {
        this.filterListR = this.olddatasource.map(item => {
          return item.Service_Description;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[0].openMenu();
      } else if (column.header == 'Server') {
        this.filterListR = this.olddatasource.map(item => {
          return item.CS_SERVICE_VIRTUAL;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[0].openMenu();
      }
      this.filterListR = new Set([...this.filterListR]);
      this.filterLists = this.filterListR;
    } else {
      if (column.header == 'ApplicationID') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.ApplicationID;
        });
        this.filValue = this.REPFilter;
        this.trigger.toArray()[0].openMenu();
      } else if (column.header == 'ApplicationName') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.ApplicationName;
        });
        this.filValue = this.DSCFilter;
        this.trigger.toArray()[1].openMenu();
      } else if (column.header == 'Capability PADU') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.CapabilityPADU;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[2].openMenu();
      } else if (column.header == 'Application PADU') {
        this.filterListR = this.dataSource.data.map(item => {
          return item.ApplicationPADU;
        });
        this.filValue = this.TYPEFilter;
        this.trigger.toArray()[2].openMenu();
      }
      this.filterListR = new Set([...this.filterListR]);
      this.filterLists = this.filterListR;
    }
  }

  resetFilters() {
    this.Allfilter.forEach((a, i) => {
      if (a.column == 'Service_Description') this.Allfilter.splice(i, 1);
    });
    this.filterApplied = false;
    this.filterAssetIdApplied = false;
    this.filterAssetNameApplied = false;
    this.filterAssetOwnerApplied = false;
    this.filterAssetTeamApplied = false;
    this.filterApplicationIdApplied = false;
    this.filterApplicationNameApplied = false;
    this.filterApplicationPADUApplied = false;
  }

  checkServiceFlagCondition(element) {
    if (
      element.flagapp &&
      element.flagapp.length > 0 &&
      !(element.flagapp[0].flag == 0 && element.flagapp[0].Flag_Email == 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getServiceFlagStyle(element) {
    let color = 'red';
    if (element.flagapp[0].flag == 1 && element.flagapp[0].Flag_Email == 1) {
      color = 'red';
    } else if (element.flagapp[0].flag == 1 && element.flagapp[0].Flag_Email == 0) {
      color = 'orange';
    }
    let style = { color: color, 'font-size': '16px;', 'vertical-align': 'bottom', 'margin-right': '0.23vw' };
    return style;
  }

  reportHasLoggedInUserOwner(ownerDetails: any, HasOwner: any) {
    const loggedInUserMSID = localStorage.getItem('MsId');
    if (ownerDetails != null) {
      let owners = ownerDetails.split(',').map(x => x.trim());
      let reportOwnerIsloggedinUser = owners.includes(loggedInUserMSID);
      if (reportOwnerIsloggedinUser && HasOwner == 'Yes') {
        return true;
      } else return false;
    } else return false;
  }

  editReport(id, Report, Description, Report_Link) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: id,
      Report: Report,
      Description: Description,
      Report_Link: Report_Link
    };
    dialogConfig.width = '50%';
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = 'custom-modalbox';
    dialogConfig.height = '62%';
    dialogConfig.disableClose = false;
    dialogConfig.maxWidth = '80vw';
    dialogConfig.position = { right: '340px', top: '140px' };

    const dialogRef = this.dialog.open(EditReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.anyChangeInData) {
        this.datarefresh.emit(true);
      }
    });
  }

  checkPrevious(event,prevArr:any = []) {
    this.previousSelection = prevArr;
    const filterList:Array<any> = [...this.filterLists];
    if (this.previousSelection && this.previousSelection.length > 0) {
      let isPrevValueUnselected = true;
      for (let i = 0; i < this.previousSelection.length; i++) {
        //If previous selection is in DropDownData and not in event then only add previous selection in Event Values(Server Side Search)
        let uncheckedElementName = event.options[0]._value;
        let unchekcedStatus = event.options[0]._selected;
        if (!(unchekcedStatus) && isPrevValueUnselected) { //check for unchecked elements
          if (this.previousSelection[i] == uncheckedElementName) //check for element which is unchecked
          {
            this.previousSelection.splice(i, 1);
            i--;
            isPrevValueUnselected = false;
          }
        } else if (filterList.includes(this.previousSelection[i]) && event.source._value.length > 0
          && !event.source._value.includes(this.previousSelection[i]))  //Checked cond1
        {
          event.source._value.push(this.previousSelection[i]);
        }
      }
    }
    if (event.source._value.length == 0) {//Unchecked cond2: element is unchecked after search
      for (let i = 0; i < this.previousSelection.length; i++) {
        if (filterList.includes(this.previousSelection[i])) {
          event.source._value.push(this.previousSelection[i]);
        }
      }
    }
    this.previousSelection = [... new Set(event.source._value)];
    return event
  }
}
