import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { tcmHubConfigDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
export interface Person {
  employee_email: string;
  employee_msid: string;
}
@Injectable()
export class AppViewService {
  private apiUrl: string;
  private config: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*'
    })
  };
  people: any;

  constructor(private http: HttpClient) {
    this.config = tcmHubConfigDetails;
    this.apiUrl = environment.apiUrl;
  }

  public kpidetailsapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getApplicationDetailsByNameV2}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public monthlychargeapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getmonthchargeyear}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }

  public monthlychargeapiChart(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getmonthchargeyearbarchart}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public addedservicebyappapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getaddedservicebyapp}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public addedservicebylobapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getaddedservicebylob}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getServiceCatDetailsapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getServiceCatDetails}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getServiceconsumptionapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getServiceconsumption}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getgetserviceDetailsapi(service, body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getserviceDetails}/${service}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }

  public getSuiDetails(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getsuiDetails}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public gettopSuiDetails(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.gettopsuiDetails}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  triggerMail(selectedappName = '') {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const apiUrl = this.apiUrl + this.config.routerpath.triggerhelpMail + currentUser + '/' + selectedappName;
    let mailPromise = this.http.get<any>(apiUrl).toPromise();
    mailPromise
      .then(function () {
        return 'An email has been sent to the TCM team, someone will reach out to you';
      })
      .catch(function () {
        return 'Error occured while sending the mail, please try again later';
      });
  }
  facilitatorMail(body: any) {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser = currentUser ? currentUser : localStorage.currentUser;
    let loggedInUserEmail = JSON.parse(sessionStorage.getItem('loggedInUserEmail'));
    loggedInUserEmail = loggedInUserEmail ? loggedInUserEmail : localStorage.loggedInUserEmail;
    body.currentUser = currentUser;
    body.loggedInUserEmail = loggedInUserEmail;
    const apiUrl = this.apiUrl + this.config.routerpath.facilitatorMail;
    let mailPromise = this.http.post<any>(apiUrl, body, this.httpOptions).toPromise();
    mailPromise
      .then(function () {
        return 'An email has been sent to the TCM team, someone will reach out to you';
      })
      .catch(function () {
        return 'Error occured while sending the mail, please try again later';
      });
  }
  triggerhelpMail() {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const apiUrl = this.apiUrl + this.config.routerpath.triggerhelpMail + currentUser;
    let mailPromise = this.http.get<any>(apiUrl).toPromise();
    return mailPromise;
  }
  public getsuiaddeddropped(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getsuiaddeddropped}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getkpiapplist(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getkpiapplist}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getgroupmodelsofApplications(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getgroupmodelsofApplications}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getdecommisionstatus(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getdecommisionstatus}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getapplicationVunerableStatus(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getapplicationVunerableStatus}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public validateapp(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.validateapp}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getvalidateapp(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getvalidateapp}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getServiceDetailsByNameV2(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getServiceDetailsByNameV2}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public addcomment(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.addcomment}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public addtechopsDevAdminPage(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.addtechopsDevAdminPage}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public getTechopsDevAdminPage() {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getTechopsAdminTable}`;
    return this.http.get(apiUrl);
  }
  public updateTechopsAdminTable(body, msid,requestType) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updateTechopsAdminTable}/${msid}/${requestType}`;
    return this.http.post(apiUrl, body, this.httpOptions)
  }
  public updatecomment(body, id) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updatecomment}/${id}`;
    return this.http.patch(apiUrl, body, this.httpOptions).toPromise();
  }
  public deletecomment(id) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.deletecomment}/${id}`;
    return this.http.delete(apiUrl, this.httpOptions).toPromise();
  }
  public getcomments(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getcomments}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public addBulkGroup(body) {
    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    let options = {
      headers: headers
    }
    // return this.http.post<any>(url, body, options).pipe(
    //   map(data => { return data; }));
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.addBulkGroup}`;
    return this.http.post(apiUrl, body, options).toPromise();
  }
  public addgroup(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.addgroup}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public updategroup(body, id) {
    let updateGrpId = Number(id);
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updategroup}/${updateGrpId}`;
    return this.http.patch(apiUrl, body, this.httpOptions).toPromise();
  }
  public deletegroup(id) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.deletegroup}/${id}`;
    return this.http.delete(apiUrl, this.httpOptions).toPromise();
  }
  public deleteMappedApps(groupkey, payload) {
    let mappedGrpKey = Number(groupkey);
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.deletemappedapps}/${mappedGrpKey}`; 
    return this.http.post(apiUrl, payload, this.httpOptions);

  }
  public getgroup(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getgroup}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public getLobAppList(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getLobApps}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }

  public getManageAppList(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.manageApplicationList}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }

  public gettechpadu(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.gettech360padu}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }

  searchPeople(term) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getperson}`;
    return this.http.post(apiUrl, { employee_msid: term }, this.httpOptions).pipe(
      map(Data => {
        return Data;
      })
    );
  }
  public getlobSuiDetails(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getlobSuiDetails}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getcart(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getcart}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public savecart(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.savecart}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public updatecart(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updatecart}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public deletecart(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.deletecart}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getrequests(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getrequests}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getnames(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getnames}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getfincinfo(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getfincinfo}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getfinanceinfo(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getfincinfo}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }

  public sendrequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.sendrequest}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getreClassApps(askid) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getreClassApps}`;
    return this.http.get(apiUrl, { params: { searchString: askid } });
  }
  public getreClassSUIs(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getreClassSUIs}`;
    return this.http.get(apiUrl, { params: { searchString: body } });
  }
  public getreClassAppSUIs(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getreClassSUIs}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public updateRequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updateRequest}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public deleteRequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.deleteRequest}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  // getreClassSUIs(): Observable<any> {
  //   if (!localStorage.getItem('reclasslist')) {
  //     const apiUrl = `${this.apiUrl}/${this.config.routerpath.getreClassSUIs}`;
  //     const applist = this.http.get(apiUrl).pipe(
  //       map(res => {
  //         localStorage.setItem('reclasslist', JSON.stringify(res));
  //         return res;
  //       })
  //     );
  //     return applist;
  //   } else {
  //     return of(JSON.parse(localStorage.getItem('reclasslist')));
  //   }
  // }
  public getdesreClassApps(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getdesreClassApps}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public sendcartrequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.sendcartrequest}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public deletecartitem(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.deletecartitem}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getUpdatedMonth() {
    const apiUrl = `${this.apiUrl}/v3/tcm-app-view/getYearmonth`;
    const configList = this.http.get(apiUrl).pipe(
      map(res => {
        return res;
      })
    );
    return configList;
  }
  public checkMonth(month) {
    if (!month) {
      this.getUpdatedMonth().subscribe((res: any) => {
        localStorage.setItem('config', JSON.stringify(res));
        window.location.reload();
      });
    }
  }
  public updateBulkRequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updateBulkRequest}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public updateFinancialApprove(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updateFinancialApproval}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public CompltStsEmail(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.compltstsmail}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public helpEmail(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.helpemail}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
  public updatecartitem(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updatecartitem}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public RevertRequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.revertRequest}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }

  public groupDelegateAccess(body, id) {
    let groupDelegateAccessId = Number(id);
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.groupDelegateAccess}/${groupDelegateAccessId}`;
    return this.http.patch(apiUrl, body, this.httpOptions).toPromise();
  }

  public editGroupBulkUpload(body, id) {
    let editGroupBulkdId = Number(id);
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.bulkeditGroup}/${editGroupBulkdId}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }
}
