import { Injectable } from '@angular/core';
import { tcmHubConfigDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { formatNumber } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LaborHubService {
  private apiUrl: string;
  private config: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*'
    })
  };
  public pieChartSelection = new Subject<string>();
  public LineChartSelection = new Subject<any[]>();

  namedMonths = {
    "01": 'Jan',
    "02": 'Feb',
    "03": 'Mar',
    "04": 'Apr',
    "05": 'May',
    '06': 'Jun',
    '07': 'Jul',
    "08": 'Aug',
    '09': 'Sep',
    "10": 'Oct',
    "11": 'Nov',
    "12": 'Dec',
  }
  decimalFixed = 1;

  public TimeCategoriesColumnMapping = {
    'Admin': 'Current_Month_Admin',
    'Dev': 'Current_Month_Dev',
    'OM': 'Current_Month_OM',
    'TAFW': 'Current_Month_TAFW'
  };

  timeCategoryList = {
    'Admin': 'Admin',
    'Dev': 'Dev',
    'OM': 'OM',
    'TAFW': 'TAFW'
  };
  devColor = '#0066F5';
  adminColor = '#999999';
  omcolor = '#009104';
  tafwcolor = '#424242'
  // Chart_Colors = [`#0db948`, `#2292b5`, `#caca17`, `#07461dd9`];
  Chart_Colors = [this.adminColor, this.devColor, this.omcolor, this.tafwcolor];
  addedColor: string = '#003c71';
  removedColor: string = '#bfbfbf';
  RosterChange_Colors = [this.addedColor, this.removedColor];
  Chart_Colors_trend = [this.adminColor, this.devColor, '#ff0000', this.omcolor, this.tafwcolor];

  public Ignore_RateColumn = new Subject<boolean>();
  readonly IgnoreRate$ = this.Ignore_RateColumn.asObservable();
  constructor(
    private http: HttpClient,
  ) {
    this.config = tcmHubConfigDetails;
    this.apiUrl = environment.apiUrl;
  }
  /**
   * API call to fetch all the employees of labor hub
   * @param body 
   */
  public getEmployeesList(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getLaborHubEmployeeDetails}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  /**
   * API call to fetch all the filter options of labor hub
   */
  getFiltersOptionsOld(detailView = false): Observable<any> {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getLaborHubFilters}`;
    return this.http.post<any>(apiUrl, { detailView: detailView });
  }

  public responseCache = new Map();

  /**
   * API call to fetch all the filter options of labor hub
   */
  getFiltersOptions(detailView = false, type = 'view'): Observable<any> {
    let apiUrl;
    let postBody;
    if (type == 'fteview') {
      apiUrl = `${this.apiUrl}/${this.config.routerpath.getFTEFiltersList}`;
    } else {
      apiUrl = `${this.apiUrl}/${this.config.routerpath.getLaborHubFilters}`;
      postBody = { detailView: detailView };
    }

    let dataCacheKey = 'type?=' + type;
    const dataFromCache = this.responseCache.get(dataCacheKey);

    if (dataFromCache) {
      return of(dataFromCache);
    }
    return this.http.post<any>(apiUrl, postBody);
  }

  getDataByTimeCategory(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getDataByTimeCategory}`;
    return this.http.post<any>(apiUrl, body);
  }

  getHoursByCategory(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getHoursByCategory}`;
    return this.http.post<any>(apiUrl, body);
  }

  getBlockLevelDetails(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getBlockLevelDetails}`;
    return this.http.post<any>(apiUrl, body);
  }

  getGLDeptWiseData(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getGLDeptWiseData}`;
    return this.http.post<any>(apiUrl, body);
  }

  getHoursPerByTimeCategoryTrend(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getHoursPerByTimeCategoryTrend}`;
    return this.http.post<any>(apiUrl, body);
  }

  getDeptWiseDevHours(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getGLDeptDevHours}`;
    return this.http.post<any>(apiUrl, body);
  }

  getDeptWiseOMHours(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getGLDeptOMHours}`;
    return this.http.post<any>(apiUrl, body);
  }

  getLaborTypeWiseDevHours(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getLaborTypeDevHours}`;
    return this.http.post<any>(apiUrl, body);
  }

  getLaborTypeOMHours(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getLaborTypeOMHours}`;
    return this.http.post<any>(apiUrl, body);
  }

  getHeadCountTrend(body = null) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getHeadCountTrend}`;
    return this.http.post<any>(apiUrl, body);
  }

  getEmployeesListByKeyword(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getKeywordBasedEmployees}`;
    return this.http.post<any>(apiUrl, body);
  }

  getEmployeeList(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getEmployeeList}`;
    return this.http.post<any>(apiUrl, body);
  }


  setPieChartFilter(value) {
    this.pieChartSelection.next(value);
  }

  setLineChartFilter(value) {
    this.LineChartSelection.next(value);
  }

  getImage(value) {
    let image = '';
    let color_image = '';
    if (value > 0) {
      image = 'trending_up.svg';
      color_image = '';//'green_image'
    } else if (value < 0) {
      image = 'trending_down.svg';
      color_image = '';//'red_image'
    } else {
      image = 'trending_flat.svg';
    }
    return { color_image, image };
  }
  /**
   * Parse data returned from API to plot in UI
   * @param data 
   */
  parseCategoryWiseData(data) {
    let parsedData = {}
    try {
      let recentYear, recentMonth;
      let maxDate = data.MAXDATE;
      if (maxDate) {
        maxDate = maxDate['Max_Date']
        let maxSplit = maxDate.split('-');
        recentYear = maxSplit[0];
        recentMonth = maxSplit[1].length == 1 ? ("0" + (maxSplit[1])).slice(0, 2) : maxSplit[1];
        recentMonth = this.namedMonths[recentMonth]
      }
      let monthly = data.MonthlyDetails;
      let yearly = data.YTDDetails;
      let variance = data.RollingVarianceDetails;
      let fte = data.FTE_Details;
      parsedData = {
        dev:
          [
            {
              'name': recentYear + ' ' + recentMonth,
              'value': monthly['Current_Month_Dev'].toFixed(this.decimalFixed) + '%'
            },
            {
              'name': recentYear + " YTD",
              'value': yearly['YTD_Dev'].toFixed(this.decimalFixed) + '%'
            },
            {
              'name': "Variance to Rolling Average",
              'headerToolTip': 'Variance to 12-month Rolling Average',
              'value': variance['Rolling_Variance_Dev'].toFixed(this.decimalFixed) + '%',
              "image": this.getImage(variance['Rolling_Variance_Dev'])['image'],
              "image_class": this.getImage(variance['Rolling_Variance_Dev'])['color_image'],
              "tooltip": 'Average:' + variance['Average_Data']['Average_Data_Dev'].toFixed(this.decimalFixed) + '%',
            }
          ],
        om:
          [
            {
              'name': recentYear + ' ' + recentMonth,
              'value': monthly['Current_Month_OM'].toFixed(this.decimalFixed) + '%'
            },
            {
              'name': recentYear + " YTD",
              'value': yearly['YTD_OM'].toFixed(this.decimalFixed) + '%'
            },
            {
              'name': "Variance to Rolling Average",
              'headerToolTip': 'Variance to 12-month Rolling Average',
              'value': variance['Rolling_Variance_OM'].toFixed(this.decimalFixed) + '%',
              "image": this.getImage(variance['Rolling_Variance_OM'])['image'],
              "image_class": this.getImage(variance['Rolling_Variance_OM'])['color_image'],
              "tooltip": 'Average:' + variance['Average_Data']['Average_Data_OM'].toFixed(this.decimalFixed) + '%',
            }
          ],

        nonbillable: [
          {
            'name': recentYear + ' ' + recentMonth,
            'value': (parseFloat(monthly['Current_Month_TAFW']) + parseFloat(monthly['Current_Month_Admin'])).toFixed(this.decimalFixed) + '%'
          },
          {
            'name': recentYear + " YTD",
            'value': (parseFloat(yearly['YTD_TAFW']) + parseFloat(yearly['YTD_Admin'])).toFixed(this.decimalFixed) + '%'
          },
          {
            'name': "Variance to Rolling Average",
            'headerToolTip': 'Variance to 12-month Rolling Average',
            'value': (parseFloat(variance['Rolling_Variance_TAFW']) + parseFloat(variance['Rolling_Variance_Admin'])).toFixed(this.decimalFixed) + '%',
            "image": this.getImage(variance['Rolling_Variance_TAFW'])['image'],
            "image_class": this.getImage(variance['Rolling_Variance_TAFW'])['color_image'],
            "tooltip": 'Average:' + (parseFloat(variance['Average_Data']['Average_Data_TAFW']) + parseFloat(variance['Average_Data']['Average_Data_Admin'])).toFixed(this.decimalFixed) + '%',
          }
        ],
        fte: [
          {
            'name': recentYear + ' ' + recentMonth + ' Count',
            value: this.numberFormat(fte['FTE_COUNT'])
          },
          {
            'name': 'Prior Month Change',
            value: this.numberFormat(fte['DIFF_FTE_COUNT']),
            "image": this.getImage(fte['DIFF_FTE_COUNT'])['image'],
            "image_class": this.getImage(fte['DIFF_FTE_COUNT'])['color_image'],
          }
        ]
      }
    } catch (e) {
      console.log(e);
    }

    return parsedData;
  }


  /**
   * Parse data returned from API to plot in UI
   * @param data 
   */
  parseCategoryWiseTimeFrameLevelData(data) {
    let parsedData = {}
    try {
      let recentYear, recentMonth;
      let maxDate = data.MAXDATE;
      if (maxDate) {
        maxDate = maxDate['Max_Date'];
        if (maxDate) {
          let maxSplit = maxDate.split('-');
          recentYear = maxSplit[0];
          recentMonth = maxSplit[1].length == 1 ? ("0" + (maxSplit[1])).slice(0, 2) : maxSplit[1];
          recentMonth = this.namedMonths[recentMonth]
        }
      }
      let monthly = data.MonthlyDetails;
      let yearly = data.YTDDetails;
      let variance = data.RollingVarianceDetails;
      let fte = data.FTE_Details;
      let shortYear = recentYear ? recentYear.slice(2, 4) : '';
      let blockData = [];
      if (fte['FTEDetailCount']) {
        for (let block of fte['FTEDetailCount']) {
          blockData.push({
            'name': block.Emp_Type_location,
            'value': this.numberFormat(block.Headcount)
          });
        }
      }

      if (!blockData.length) {
        let employeeTypeOptions = [
          "Employee Onshore",
          "Employee Offshore",
          "Contractor Onshore",
          "Contractor Offshore"
        ];

        employeeTypeOptions = employeeTypeOptions.map((ele) => {
          let element: any = {};
          element.name = ele;
          element.value = 0;
          return element;
        });
        blockData = employeeTypeOptions;
      }

      blockData.sort(this.generateSortFn([{ "name": "name", "reverse": true }]));
      recentYear = recentYear ? recentYear : '';
      recentMonth = recentMonth ? recentMonth : '';
      parsedData = {
        month: {
          data: [
            {
              'name': "Development",
              'value': monthly['Current_Month_Dev'].toFixed(this.decimalFixed) + '%',
              'valueHrs': 'Hours: ' + this.numberFormat(monthly['Current_Month_Hr_Dev']),
              'variance': variance['Rolling_Variance_Dev'].toFixed(this.decimalFixed) + '%',
              //"tooltip": 'Variance to 12-month Rolling Average: ' + variance['Average_Data']['Average_Data_Dev'].toFixed(this.decimalFixed) + '%',
              "tooltip": 'Variance to 12-month Rolling Average',
            },
            {
              'name': "O&M",
              'value': monthly['Current_Month_OM'].toFixed(this.decimalFixed) + '%',
              'valueHrs': 'Hours: ' + this.numberFormat(monthly['Current_Month_Hr_OM']),
              'variance': variance['Rolling_Variance_OM'].toFixed(this.decimalFixed) + '%',
              //"tooltip": 'Variance to 12-month Rolling Average: ' + variance['Average_Data']['Average_Data_OM'].toFixed(this.decimalFixed) + '%',
              "tooltip": 'Variance to 12-month Rolling Average',
            },
            {
              'name': "Non-Billable",
              'value': (parseFloat(monthly['Current_Month_TAFW']) + parseFloat(monthly['Current_Month_Admin'])).toFixed(this.decimalFixed) + '%',
              'valueHrs': 'Hours: ' + this.numberFormat(monthly['Current_Month_Hr_TAFW'] + monthly['Current_Month_Hr_Admin']),
              'variance': (parseFloat(variance['Rolling_Variance_TAFW']) + parseFloat(variance['Rolling_Variance_Admin'])).toFixed(this.decimalFixed) + '%',
              // "tooltip": 'Variance to 12-month Rolling Average: ' + (parseFloat(variance['Average_Data']['Average_Data_TAFW']) + parseFloat(variance['Average_Data']['Average_Data_Admin'])).toFixed(this.decimalFixed) + '%',
              "tooltip": 'Variance to 12-month Rolling Average',
            },
          ],
          label: recentMonth + ' ' + recentYear,
        },
        ytd: {
          data: [
            {
              'name': "Development",
              'value': yearly['YTD_Dev'].toFixed(this.decimalFixed) + '%',
              'valueHrs': 'Hours: ' + this.numberFormat(yearly['YTD_Hr_Dev']),
            },
            {
              'name': "O&M",
              'value': yearly['YTD_OM'].toFixed(this.decimalFixed) + '%',
              'valueHrs': 'Hours: ' + this.numberFormat(yearly['YTD_Hr_OM']),
            },
            {
              'name': "Non-Billable",
              'value': (parseFloat(yearly['YTD_Admin']) + parseFloat(yearly['YTD_TAFW'])).toFixed(this.decimalFixed) + '%',
              'valueHrs': 'Hours: ' + this.numberFormat(yearly['YTD_Hr_Admin'] + yearly['YTD_Hr_TAFW']),
            },
          ],
          label: recentYear + ' YTD',
        },
        fte: {
          data: [
            {
              // 'name': recentYear + ' ' + recentMonth + ' Count',
              'name': '',
              "link": true,
              "identifier": "headcount",
              "value": this.numberFormat(fte['FTE_COUNT']),
              'variance': (Math.sign(fte['DIFF_FTE_COUNT']) > 0) ? '+' + this.numberFormat(fte['DIFF_FTE_COUNT']) : this.numberFormat(fte['DIFF_FTE_COUNT']),
              "tooltip": "Change From Prior Month",
            },
            {
              "blockData": blockData
            }
          ],
          headerToolTip: "Headcount calculated based on billable utilization",
          label: "Headcount (as of " + recentMonth + " '" + shortYear + ")"
        }
      }
    } catch (e) {
      console.log(e);
    }

    return parsedData;
  }


  /**
   * 
   * @param data 
   * @param employeeTypes 
   * @param category 
   */
  preparePieChartToolTip(data, employeeTypes, category) {
    let toolTip: any = {}
    let empTypes: any[] = data.map((ele) => ele.Emp_Type_location);
    empTypes.forEach(element => {
      let empLoccation = data.filter((ele) => ele['Emp_Type_location'] == element);
      if (empLoccation) {
        let value = empLoccation.reduce((accumulator, current) => accumulator + current[this.TimeCategoriesColumnMapping[category]], 0);
        toolTip[element] = (value);
      }
    });
    return toolTip;
  }

  /**
   * 
   * @param data 
   */
  parseHoursByCategory(data, filterOptions = []) {
    let baseData = data;
    baseData = baseData.filter((ele) => ele.Location != '' && ele.Location != null && ele.Location != 'Null');
    let employeeTypes: any[] = baseData.map((ele) => ele.Emp_Type_location);
    if (filterOptions && filterOptions.length) {
      employeeTypes = filterOptions;
    }
    let timeCategory = [];
    ['Admin', 'Dev', 'OM', ].forEach((category) => {
      let catObj: any = {};
      catObj['name'] = category;
      let empLoccation = baseData.filter((ele) => employeeTypes.includes(ele['Emp_Type_location']));
      if (!empLoccation.length) {
        empLoccation = baseData;
      }
      let value = empLoccation.reduce((accumulator, current) => accumulator + current[this.TimeCategoriesColumnMapping[category]], 0)
      catObj['value'] = (value);
      catObj['toolTip'] = this.preparePieChartToolTip(empLoccation, employeeTypes, category)
      timeCategory.push(catObj);
    })

    let TotalSum = timeCategory.reduce((accumulator, current) => accumulator + current['value'], 0)
    timeCategory.map((ele) => {
      ele.per = TotalSum && ele.value ? ((ele.value / TotalSum) * 100).toFixed(1) : 0;
    })
    return { timeCategory, employeeTypes };
  }

  public numberFormat(value: any, digits?: any): any {
    if (value) {
      digits = digits ? digits : '1.0-0'
      return formatNumber(Number(value), 'en-US', digits);
    }
    return value;
  }

  public dateFormat(value: any): any {
    let date: any = '';
    if (value && value != 'null') {
      date = new Date(value);
      date = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    }
    return date;
  }

  /**
   * Sorts the data as per the provided keys
   * @param props 
   */
  generateSortFn(props) {
    return function (a, b) {
      for (let i = 0; i < props.length; i++) {
        let prop = props[i];
        let name = prop.name;
        let reverse = prop.reverse;
        if (a[name] < b[name])
          return reverse ? 1 : -1;
        if (a[name] > b[name])
          return reverse ? -1 : 1;
      }
      return 0;
    };
  }

  randomString(len, an) {
    an = an && an.toLowerCase();
    let str = "",
      i = 0,
      min = an == "a" ? 10 : 0,
      max = an == "n" ? 10 : 62;
    for (; i++ < len;) {
      let r = Math.random() * (max - min) + min << 0;
      str += String.fromCharCode(r += r > 9 ? r < 36 ? 55 : 61 : 48);
    }
    return str;
  }

  getParentNodes(root, column, value, parent = {}, node = []) {

    let rootKeys = Object.keys(root);
    rootKeys.map(function (n) {
      let childRoot = root[n];

      if (childRoot.key === column) {

        if (String(value) == String(childRoot.id)) {

          node.push(parent);

        }
      }

      if (childRoot.children) {
        return node = this.getParentNodes(childRoot.children, column, value, childRoot, node);
      }
    });
    return node || null;
  }

  getParentNodesN(root, columns = [], parent: any = {}, node = []) {

    let rootKeys = Object.keys(root);
    rootKeys.map(function (n) {
      let childRoot = root[n];
      for (let i = 0; i < columns.length; i++) {
        let column = columns[i];
        if (childRoot.key === column.name) {
          if (column.value && column.value.includes(childRoot.id)) {
            if (!columns[i] || columns.length == 1) {
              node.push(parent);
            } else {
              let columnsNew = columns.slice(1);
              childRoot.parent = parent;
              return node = this.getParentNodesN(childRoot.children, columnsNew, childRoot, node);
            }
          } else {
            let columnsNew = columns.slice(1);
            childRoot.parent = parent;
            return node = this.getParentNodesN(childRoot.children, columnsNew, childRoot, node);
          }
        } else {
          return node = this.getParentNodesN(childRoot.children, columns, childRoot, node);
        }
      }

      // if (childRoot.children) {
      //   return node = that.getParentNodes(childRoot.children, columns, childRoot, node);
      // }
    });

    return node || null;
  }

  filterAndGetParentNodes(totalColumnNValues, drillDownFilters, parentColumn) {
    let output = [];
    this.getParentNodesN(drillDownFilters, totalColumnNValues, {}, output);
    let finalOptions: any = [];
    // console.log(output);
    if (output.length) {
      for (let val of output) {
        let column = val;
        if (column.key === parentColumn) {
          finalOptions.push(String(column['id']));
        } else if (column.parent && column.parent.key === parentColumn) {
          finalOptions.push(String(column.parent['id']));
        } else if (column.parent.parent && column.parent.parent.key === parentColumn) {
          finalOptions.push(String(column.parent.parent['id']));
        }
        else if (column.parent.parent.parent && column.parent.parent.parent.key === parentColumn) {
          finalOptions.push(String(column.parent.parent.parent['id']));
        }
        else if (column.parent.parent.parent.parent && column.parent.parent.parent.parent.key === parentColumn) {
          finalOptions.push(String(column.parent.parent.parent.parent['id']));
        }
        else if (column.parent.parent.parent.parent.parent && column.parent.parent.parent.parent.parent.key === parentColumn) {
          finalOptions.push(String(column.parent.parent.parent.parent.parent['id']));
        }
      }
    }
    finalOptions = [...new Set(finalOptions)];
    return finalOptions;
  }


  filterAndGetChildNodes(totalColumnNValues, drillDownFilters, parentColumn) {
    let output = [];
    this.getChildNodesN(drillDownFilters, totalColumnNValues, output);
    let finalOptions: any = [];
    // console.log(output);
    if (output.length) {
      for (let val of output) {
        let columnM = val;
        for (let [colValue] of Object.entries(columnM)) {
          if (colValue && colValue['key'] === parentColumn) {
            finalOptions.push(String(colValue['id']));
          } else if (colValue['children']) {
            let child1 = colValue['children'];
            for (let [ column1] of Object.entries(child1)) {
              if (column1 && column1['key'] === parentColumn) {
                finalOptions.push(String(column1['id']));
              } else if (column1['children']) {
                let child2 = column1['children'];
                for (let [ column2] of Object.entries(child2)) {
                  if (column2 && column2['key'] === parentColumn) {
                    finalOptions.push(String(column2['id']));
                  } else if (column2['children']) {
                    let child3 = column2['children'];
                    for (let [column3] of Object.entries(child3)) {
                      if (column3 && column3['key'] === parentColumn) {
                        finalOptions.push(String(column3['id']));
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    finalOptions = [...new Set(finalOptions)];
    return finalOptions;
  }

  getChildNodesN(root, columns = [], node = []) {

    let rootKeys = Object.keys(root);
    rootKeys.map(function (n) {
      let childRoot = root[n];
      for (let i = 0; i < columns.length; i++) {
        let column = columns[i];
        if (childRoot.key === column.name) {
          if (column.value && column.value.includes(childRoot.id)) {
            if (!columns[i] || columns.length == 1) {
              node.push(childRoot.children);
            } else {
              let columnsNew = columns.slice(1);
              return node = this.getChildNodesN(childRoot.children, columnsNew,  node);
            }
          } else {
            return node = this.getChildNodesN(childRoot.children, columns,  node);
          }
        } else {
          return node = this.getChildNodesN(childRoot.children, columns, node);
        }
      }

      // if (childRoot.children) {
      //   return node = that.getParentNodes(childRoot.children, columns, childRoot, node);
      // }
    });

    return node || null;
  }

  getChildNodes(root, column, value, node = []) {
    // var node;
    let rootKeys = Object.keys(root);
    rootKeys.map(function (n) {
      let childRoot = root[n];
      if (childRoot.key === column) {
        if (String(value) == String(childRoot.id)) {
          node.push(childRoot);
        }
      }
      if (childRoot.children) {
        return node = this.getChildNodes(childRoot.children, column, value, node);
      }
    });
    return node || null;
  }

  prepareLaborTypeGrandTotalRow(data) {
    let row: any = {};
    row['Emp_Type_location'] = 'Total';
    row['Block_Type_Admin'] = data.reduce((sum, item) => sum + item['Block_Type_Admin'], 0);
    row['Block_Type_Dev'] = data.reduce((sum, item) => sum + item['Block_Type_Dev'], 0);
    row['Block_Type_OM'] = data.reduce((sum, item) => sum + item['Block_Type_OM'], 0);
    row['Block_Type_TAFW'] = data.reduce((sum, item) => sum + item['Block_Type_TAFW'], 0);
    row['Total_Hours'] = data.reduce((sum, item) => sum + item['Total_Hours'], 0);
    row['Employee_Count'] = data.reduce((sum, item) => sum + item['Employee_Count'], 0);
    row['UCMGID_Count'] = data.reduce((sum, item) => sum + item['UCMGID_Count'], 0);
    return row;
  }

  compareAndOneDayToDate(dateObject) {
    // console.log(dateObject.getDate())
    // console.log(dateObject.getUTCDate())

    // console.log(dateObject.getMonth())
    // console.log(dateObject.getUTCMonth())

    // console.log(dateObject.getFullYear())
    // console.log(dateObject.getUTCFullYear())

    if (dateObject.getDate() != dateObject.getUTCDate()) {
      if (dateObject.getMonth() < dateObject.getUTCMonth()) {
        //3 4
        // if (dateObject.getDate() < dateObject.getUTCDate()) {
        //31 1
        if (dateObject.getFullYear() <= dateObject.getUTCFullYear()) {
          dateObject.setDate(dateObject.getDate() + 1);
        }
        // }
      } else {
        // 1 31
        // 1 0
        //2021 2021

        // 1 31
        // 0 11
        //2020 2019

        // 31 1
        //11 0
        //2019 2020
        if (dateObject.getFullYear() < dateObject.getUTCFullYear()) {
          dateObject.setDate(dateObject.getDate() + 1);
        } else {
          if (dateObject.getDate() < dateObject.getUTCDate()) {
            if (dateObject.getMonth() < dateObject.getUTCMonth()) {
              if (dateObject.getFullYear() <= dateObject.getUTCFullYear()) {
                dateObject.setDate(dateObject.getDate() + 1);
              }
            }
          }
        }

      }
    }

    return dateObject;
  }

  getVarianceClass(section) {
    let className = '';
    let value = parseInt(section.variance);
    let name = section.name;

    switch (name) {
      case 'Development':
      case 'O&M':
        if (value < 0) {
          className = 'red-class'
        } else if (value > 0) {
          className = 'green-class'
        } else {
          className = '';
        }
        break;
      case 'Non-Billable':
        if (value > 0) {
          className = 'red-class'
        } else if (value < 0) {
          className = 'green-class'
        } else {
          className = '';
        }
        break;
    }

    return className;
  }

  public setIgnoreRateColumn(value: boolean) {
    this.Ignore_RateColumn.next(value);
  }

  prepareOptions(optns, element = null) {
    let options = [];
    let nullOptions = [];
    if (optns.length) {
      optns = [...new Set(optns)];
      optns = optns.sort();
      optns.forEach((ele: any) => {

        if (ele && ele != 'null') {
          let opt: any = {};
          opt['label'] = ele.replace('/', '');
          opt['value'] = ele;
          options.push(opt);
        }

      });
      // nulls.forEach((ele: any) => {
      //   let opt: any = {};
      //   opt['label'] = String(ele);
      //   opt['value'] = String(ele);
      //   nullOptions.push(opt);
      // });
    }
    //options.unshift({ label: "All", "value": "All" });
    let ops = [...new Set(nullOptions), ...new Set(options)];
    if (element && element.key == 'Emp_Type_location') {
      ops.sort(this.generateSortFn([{ name: 'label', reverse: true }]))
    }
    return ops;
  }

  handleAllOptions(selectedFilter, filter) {

    //Selecting All values if user selecting all options in UI

    if (filter.allValues.includes('All')) {
      if (filter.search) {
        let searchKeyword = filter.search;
        let options = filter.options;
        options = options.filter((ele: any) => ele.value.toLowerCase().indexOf(searchKeyword) != -1);
        filter.value = options.map(ele => ele.value);
        selectedFilter = filter.value;
        // selectedFilter.unshift('All');
        selectedFilter = [...selectedFilter];

      } else {
        filter.value = filter.options.map(ele => ele.value);
        selectedFilter = filter.value;
        selectedFilter.unshift('All');
        selectedFilter = [...selectedFilter];
      }


    } else {
      if (selectedFilter.includes('All')) {
        if (selectedFilter.length == 1) {
          filter.value = filter.options.map(ele => ele.value);
          selectedFilter = filter.value;
          selectedFilter.unshift('All');
          selectedFilter = [...selectedFilter];
        } else {

          // if any option un selected remove all option from selection
          let removedALL = selectedFilter.filter(item => item != 'All');
          if (removedALL.length != filter.options.length) {
            filter.value = removedALL;
            selectedFilter = removedALL;
            selectedFilter = [...selectedFilter];
            filter.all = false;
          }
        }
      }
    }

    //Selecting All if all options were selected
    let allFilterValues = selectedFilter.filter((ele) => ele != 'All' && ele != null && ele != '' && ele != 'null');

    if (allFilterValues.length == filter.options.length) {
      filter.value = filter.options.map(ele => ele.value);
      selectedFilter = filter.value;
      selectedFilter.unshift('All');
      selectedFilter = [...selectedFilter];
    } else {
      // filter.all = false;
    }
    selectedFilter = [...new Set(selectedFilter)];
    return { selectedFilter, filter };
  }

  getSelectedValues(filterName, selectedFilterValue, list, depedentDropDown) {

    let values = [];
    if (depedentDropDown[filterName]) {
      let dependentColumns: any[] = depedentDropDown[filterName];
      list.forEach(element => {
        if (element.isActive && dependentColumns.includes(element.key)) {
          if (selectedFilterValue.includes('All') && (filterName == element.key)) {
            let allOptions = element.options.map((ele) => ele.value);
            values.push([...new Set(allOptions)]);
          } else {
            if (filterName == element.key) {
              values.push(selectedFilterValue);
            } else {
              values.push(element.value);
            }
          }
        }
      });
    }
    return values
  }

  loopObjectAndReturnOptionsN(drillDownFilters, columnName, lowlevlColum, list) {
    let filterIndex = list.findIndex((ele) => { return ele.key == lowlevlColum });
    let childObjects = [];
    if (filterIndex != -1) {

      let totalColumnNValues = [];
      columnName.forEach((col) => {
        let colIndex = list.findIndex((ele) => { return ele.key == col });
        if (colIndex !== -1) {
          let colNValues: any = {};
          colNValues['name'] = col;
          colNValues["value"] = list[colIndex].value;
          if (colNValues["value"] && colNValues["value"].length) {
            totalColumnNValues.push(colNValues);
          }
        }
      });
      childObjects = this.filterAndGetChildNodes(totalColumnNValues, drillDownFilters, lowlevlColum)
    }

    return { childObjects, filterIndex };
  }

  loopObjectAndReturnParentOptionsN(drillDownFilters, childColumnName, parentColumn, list) {

    let filterIndex = list.findIndex((ele) => { return ele.key == childColumnName[childColumnName.length - 1] });
    let parentIndex = list.findIndex((ele) => { return ele.key == parentColumn });
    let parentObjects = [];
    if (filterIndex != -1) {

      let totalColumnNValues = [];
      let emptyvalues = false;
      childColumnName.forEach((col) => {
        let colIndex = list.findIndex((ele) => { return ele.key == col });
        if (colIndex !== -1) {
          let colNValues: any = {};
          colNValues['name'] = col;
          colNValues["value"] = list[colIndex].value;
          if (colNValues["value"] && colNValues["value"].length && !emptyvalues) {
            totalColumnNValues.push(colNValues);
          } else {
            emptyvalues = true;
          }
        }
      });
      parentObjects = this.filterAndGetParentNodes(totalColumnNValues, drillDownFilters, parentColumn)
    }

    return { parentObjects, parentIndex };
  }


  public getACLList(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getAclList}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }

  saveAccessRequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.createAcl}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }

  updateAccessRequest(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.updateAcl}`;
    return this.http.put(apiUrl, body, this.httpOptions);
  }

  public getEmployeeRole(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getEmployeeRole}`;
    return this.http.post(apiUrl, body, this.httpOptions);
  }

  public getAccessList() {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getAccessList}`;
    let dataCacheKey = 'lbr_access_list';
    const dataFromCache = this.responseCache.get(dataCacheKey);
    if (dataFromCache) {
      return of(dataFromCache);
    }
    return this.http.get(apiUrl, this.httpOptions);
  }

}
