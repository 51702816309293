import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-infodailog',
  templateUrl: './infodailog.component.html',
  styleUrls: ['./infodailog.component.css']
})
export class InfodailogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<InfodailogComponent>) { }

  ngOnInit(): void {
  }
  hideMoreContent(){
    this.dialogRef.close();
  }

}
