import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { appModernizationDetails } from '../../config';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonModelService {
  public addCapability = new Subject();
  private apiUrl: string;
  private config: any;

  constructor(private http: HttpClient,) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
  }

  setAddCapability(value) {
    this.addCapability.next(value);
  }

  public addStakeholder(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addStakeholder;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetCapabilityDetails(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetCapabilityDetails;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetCapabilityApps(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetCapabilityDetailsApplications;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }
  public GetExpandedCapabilityApps(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetCapabilityExpandedDetailsApplications;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }


  public GetProductRegistryList(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetProductRegistryList;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetProductRegistryListByOfferingIds(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetProductRegistryListByOfferingIds;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public searchOfferings(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.searchOfferings;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public addPurOfferingData(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addPurOfferingData;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public UpdatePurOfferingData(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdatePurOfferingData;
    return this.http.put(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public SearchTechlandscapeList(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.SearchTechlandscapeList;
    return this.http.post<Array<string>>(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }


  public GetProductFamilyList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetProductFamilyList;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetProductLineList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetProductLineList;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetProductOrUtilList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetProductOrUtilList;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetOfferingTypeList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetOfferingTypeList;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getPurAdminAccess(msId): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.GetPurAdminAccess;
    return this.http.get<any>(url + '/' + msId);
  }

  public AddContact(offeringId, body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.AddContact;
    return this.http.post(url + '/' + offeringId, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public DeleteContacts(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteContacts;
    return this.http.request('delete', url, { body: body });
  }

  public addProductFamily(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.AddProductFamily;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public updateProductFamily(id, body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdateProductFamily;
    return this.http.put(url + '/' + id, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public deleteProductFamily(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteProductFamily;
    return this.http.request('delete', url, { body: body });
  }
  public addProductLine(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.AddProductLine;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public updateProductLine(id, body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdateProductLine;
    return this.http.put(url + '/' + id, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public deleteProductLine(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteProductLine;
    return this.http.request('delete', url, { body: body });
  }
  
  public addProductType(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.AddProductType;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public updateProductType(id, body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdateProductType;
    return this.http.put(url + '/' + id, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public deleteProductType(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteProductType;
    return this.http.request('delete', url, { body: body });
  }

  getLobWithSegment(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getImpactedLobInfo;
    return this.http.get<any>(url, { params: params });
  }

  getLobWithSegmentPost(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getImpactedLobInfo;
    return this.http.post<any>(url, params);
  }

  getAskidWithSegmentPost(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getImpactedAskIdInfo;
    return this.http.post<any>(url, params);
  }
  getAppctnWithSegmentPost(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getImpactedApplictnInfo;
    return this.http.post<any>(url, params);
  }

  getCascadAppFilter(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getcasecadeAppinfo;
    return this.http.post<any>(url, params);
  }
  getCascadFilter(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getcasecadeinfo;
    return this.http.post<any>(url, params);
  }
  getCascadAskIdFilter(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getcasecadeAskidinfo;
    return this.http.post<any>(url, params);
  }
  getCascadPrimryFilter(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getcasecadePrmryinfo;
    return this.http.post<any>(url, params);
  }
  /**
   * GetCapabilityStakeHolderdata
   */
  public GetCapabilityStakeHolderdata(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetCapabilityStakeHolderdata;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  getLDAPUsers(msId): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getUsersFromLDAP;
    return this.http.get<any>(url + '?q=' + msId);
  }

  /**
   * GetCapabilityStakeHolderdata
   */
  public SaveCapability(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.SaveCapability;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  /**
  * GetCapabilityStakeHolderdata
  */
  public UpdateCapability(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdateCapability;
    return this.http.put(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  /**
  * Delete Capabilities
  */
  public DeleteCapabilities(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteCapability;
    let options: any = {};
    options['body'] = body;
    return this.http.delete(url, options).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getExportData(body: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.exportExcelAppView;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }


}
