import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
// import { tcmHubConfigDetails } from '../../../config';
import { SharedService } from '../../services/shared.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  selectedappName = this.router.snapshot.queryParams.LONG_APP_NAME;
  isLoggedIn: boolean = false;
  hrefLink: string;
  hrefShareFeedback: string;
  pathArray = '';
  @Input() path : string = '';
  constructor(
    private http: HttpClient,
    private router: ActivatedRoute,
    private route: Router,
    private sharedService: SharedService) {
    route.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationStart;
        })
      )
      this.route.events.subscribe((event: NavigationStart) => {
        if (event instanceof NavigationStart) {
          this.isLoggedIn = JSON.parse(localStorage.getItem('currentUser'));
         
        }
      });

       this.pathArray = window.location.pathname.split('/')[2];
  }

  ngOnInit() {
    
    
    const msId = localStorage.getItem('MsId');
   
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser){
      this.isLoggedIn = true;
      this.hrefLink = `mailto:techops_support@ds.uhc.com?subject=${currentUser.replace(/^"(.*)"$/, '$1')} (${msId}) needs help!`;
      this.hrefShareFeedback = `mailto:techops_support@ds.uhc.com?subject=${currentUser.replace(/^"(.*)"$/, '$1')} (${msId}) has shared a Feedback!`;
    
    }
   
    this.router.queryParams.subscribe(params => {
      this.selectedappName = params.LONG_APP_NAME;
    });
    
  
  }

  redirectToRelease(){
    this.route.navigate([{ outlets: { primary: 'release-note'} }]);
  }
  // triggerMail() {
  //   const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //   if (
  //     this.selectedappName == null ||
  //     this.selectedappName == '' ||
  //     this.selectedappName === 'All' ||
  //     this.selectedappName === undefined
  //   ) {
  //     alert('Please select Application');
  //     return false;
  //   } else {
  //     let appNameSelected = String(this.selectedappName);
  //     const mailPromise = this.http
  //       .get<any>(tcmHubConfigDetails.envApiUrl + '/triggerMail/' + String(currentUser) + '/' + appNameSelected)
  //       .toPromise();
  //     mailPromise
  //       .then(() => {
  //         alert('An email has been sent to the TCM team, someone will reach out to you');
  //         return false;
  //       })
  //       .catch(() => {
  //         alert('Error occured while sending the mail, please try again later');
  //         return false;
  //       });
  //   }
  //   return false;
  // }
}
