// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    DARID: 'UHG.UHC.BenOps.TechOpsHub.DevCloud',
    BASEDARID: 'UHG.UHC.BenOps.TechOpsHub',
    Pixel_script: 'https://stage-repo.rakanto.com/rakanto/cx/cx.js',
    production: false,
    environmentName: 'cloudDev',
    apiUrl: 'https://techops-dev-api.uhg.com',
    enableSSO: true, // set true for sso and false for normal login
    disableEditAppInsight: false,
    enableProductEditor: false,
    enableMapping: true,
    isAdminRole: true,
    ssrcLink: 'http://orbit-ssrs-test-int.optum.com/reports/browse/Optum%20ET/TCM',
    sso_keys: {
      url: 'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421/oauth2/v2.0/authorize?client_id=2534b782-38e6-4c18-9d79-a8ec41e652e6&response_type=code&redirect_uri=https://techops-dev.uhg.com&scope=openid%20profile%20address%20email'
    },
    docUrl: 'https://techopshub-nonprod.s3docs.optum.com/',
    capitalDemandUrl:'https://techopshub-nonprod.s3docs.optum.com/user-guide/Capital-Demand/',
    reportURL: 'https://orbit-ssrs-dev-int.optum.com/reports/report/Optum%20ET/TCM/Decommission%20overview',
    lobMaintenanceBulkEditTemplate: 'assets/Edit_Lob_Maintenance_Template_Non_Prod.xlsx',
    AppDemographicsBulkEditTemplate: 'assets/App_Demographics_Template.xlsx',
    AppPADUTemplate: 'assets/Application_PADU_Template.xlsx',
    
    authorityUrl: 'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421/',
  
    // End point URL for Power BI API
    powerBiApiUrl: 'https://api.powerbi.com/',
  
    // Scope for securing access token
    scopeBase: ['https://analysis.windows.net/powerbi/api/Report.Read.All'],
  
    // Client Id (Application Id) of the AAD app.
    clientId: '23d140cc-54a0-478b-9762-0c4d41343342',
  
    // Id of the workspace where the report is hosted
    workspaceId: '2d4d4c89-8451-47ff-9d37-ed474530a010',
  
    // Id of the report to be embedded
    reportId: '4bc3d2d2-5571-4fa7-af2e-b6e65536b823',
  
    // Report link for all 5 Initiatives. Used to give report link in Bulk Edit 
    initiativeReportLink: '/reports?Report=Enterprise%20Initiatives',
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  
