<div class="uitk-form-control">
  <div *ngIf="label" class="input-label">
    <label for="{{ id }}"
      >{{ label
      }}<span
        *ngIf="isRequired"
        class="uitk-icon uitk-icon-asterisk sm-icon uitk-c-label__icon"
        aria-hidden="true"
      ></span
      >:
    </label>
  </div>
  <div class="select-box">
    <mat-form-field>
      <mat-label>Please Select</mat-label>
      <mat-select
        (selectionChange)="updateValue($event)"
        (submit)="updateValue($event)"
        class="switch-input"
        [id]="id"
        [required]="isRequired"
        [disabled]="disabled"
        name="name"
        required="isRequired"
        [(value)]="Selected"
        [selected]="Selected"
      >
        <mat-option *ngFor="let row of list" [value]="row.name">
          {{ row.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="formcontrol && formcontrol?.invalid && formcontrol?.touched">
      <ul class="help-block uitk-c-input__message--error">
        <li *ngIf="formcontrol.hasError('required')">
          this is required field.
        </li>
        <!-- <li *ngFor="let err of formcontrol.errors | keyvalue">
           {{err.key}}
         </li> -->
      </ul>
    </ng-container>
    <ng-container *ngIf="formref?.submitted && !formcontrol?.touched">
      <ul *ngIf="this.form.controls[this.name].hasError('required')" class="help-block uitk-c-input__message--error">
        <li>
          this is required field
        </li>
      </ul>
    </ng-container>

    <strong *ngIf="debugg">Value: {{ Selected | json }}</strong>
  </div>
</div>
