import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorSecureGrpComponent } from './error-secure-grp/error-secure-grp.component';
import { HomeComponent } from './home/home.component';
//import { LandingPageComponent } from './padu-reporting/landing-page/landing-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginModule } from './login/login.module';
import { ReleaseNoteComponent } from './padu-reporting/release-note/release-note.component';
import { TestComponent } from './padu-reporting/test/test.component';
import { HttpConfigInterceptor } from './shared/interceptor/httpconfig.interceptor';
// imported all shared components and directive //
import { SharedComponentsModule } from './shared/shared-components.module';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
//import { Angulartics2Piwik } from 'angulartics-piwik';
import { ToastrModule } from 'ngx-toastr';
import { RedirectGlobalProductViewComponent } from './product-mapping-view/redirect-global-product-view/redirect-global-product-view.component';
import { ProductMappingEditComponent } from './padu-reporting/product-mapping-view/product-mapping-edit/product-mapping-edit.component';
import { ProductFormComponent } from './padu-reporting/product-mapping-view/product-form/product-form.component';
import { ProductSuitesEditComponent } from './padu-reporting/product-mapping-view/product-suites-edit/product-suites-edit.component';
import { SingleNodeViewComponent } from './padu-reporting/product-mapping-view/single-node-view/single-node-view.component';

import { AdminModule } from './admin/admin.module';
import {MatNativeDateModule} from '@angular/material/core';
import { ReportsComponent } from './reports/reports.component';
import { GlobalShareModule } from './techopsv4/application-hub/global-share/global-share.module';
import { MatRadioModule } from '@angular/material/radio';
import { EditReportComponent } from './reports/edit-report/edit-report.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Header2Component } from './shared/layouts/theme-2/header2.component';
import { Footer2Component } from './shared/layouts/theme-2/footer2.component';
import { LandingPageV4Component } from './landing-page-v4/landing-page-v4.component';
import { LandingPageBodyComponent } from './landing-page-v4/landing-page-body/landing-page-body.component';
import { LandingPageServicesComponent } from './landing-page-v4/landing-page-services/landing-page-services.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReleaseNoteV4Component } from './techopsv4/release-note-v4/release-note-v4.component';
import { ThousandPipeModule } from './shared';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import { ModalModule } from 'ng-modal-lib-sab';
import { NotificationPopupComponent } from './shared/layouts/theme-2/notification-popup/notification-popup.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { SafeHtmlPipe } from './shared/pipe/safe-html.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
@NgModule({
  declarations: [
    Header2Component,
    Footer2Component,
    AppComponent,
    HomeComponent,
    ReleaseNoteComponent,
    TestComponent,
    SpinnerOverlayComponent,
    LandingPageComponent,
    ErrorSecureGrpComponent,
    RedirectGlobalProductViewComponent,
    ProductMappingEditComponent,
    ProductSuitesEditComponent,
    ProductFormComponent,
    SingleNodeViewComponent,
    ReportsComponent,
    EditReportComponent,
    LandingPageV4Component,
    LandingPageBodyComponent,
    LandingPageServicesComponent,
    ReleaseNoteV4Component,
    SpinnerOverlayComponent,
    ProductMappingEditComponent,
    ProductFormComponent,
    SingleNodeViewComponent,
    ProductSuitesEditComponent,
    NotificationPopupComponent,
    SafeHtmlPipe,
  ],
  imports: [
    LoginModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    MatRadioModule,
    MatFormFieldModule ,
    MatInputModule,
    // FormsModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    // ReactiveFormsModule,
    HttpClientModule,
    MatTooltipModule,
    AdminModule,
    MatNativeDateModule,
    GlobalShareModule,
    ToastrModule.forRoot(),
    MatTooltipModule,
    CommonModule,
    ThousandPipeModule,
    MatBadgeModule,
    ModalModule,
    MatExpansionModule,
    MatAutocompleteModule,
  ],
  providers: [
    // Angulartics2Piwik,
    DecimalPipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    provideClientHydration()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class AppModule { }