<div class="applicationlist">
    <ng-container>
      <div class="data-loader-container" style="stroke:  #002677 !important;" *ngIf="viewClickLoader">
        <app-loader></app-loader>
      </div>
      <div *ngIf="!viewClickLoader">
        <!-- <div class="container-fluid p-0">
          <div class="row no-gutters bgcolor p-2 col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 vertical-align">
            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-11 col-sm-12 col-lg-11 col-xl-11 col-xs-11">
              <span class="text-white table-Header-Nav">Applications associated with the Group Model and Capability</span>
            </div>
            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-1">
              <button type="button" class="close  text-white" aria-label="Close" style="color:white;"
                [mat-dialog-close]="true">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div> -->
        <div class="row no-gutters col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12 container-fluid" style="padding-top: 16px;">
          <div class="col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xs-8">
            <p> <b class="tabl-header">Group Model:</b> {{model}}<b class="tabl-header"> Capability: </b>{{capability}}<p>
          </div>
          <!-- <ng-container>
            <div class="table-search col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-4 search-Margin-Top" >
              <mat-form-field class="data-filter" appearance="outline">
                <input matInput type="text"  placeholder="Search Application"
                  (keyup)="searchDataSet($event)" />
                <img src="../../../../assets/images/searchicon.png" style="position: absolute;
                                              right: 0px;
                                              top: 7px;" />
              </mat-form-field>
            </div>
          </ng-container> -->
        </div>
        <ng-container>
          <app-table-data [SelectAll]="false" [displaySelectAll]="false" [dataSource]="dataSource"
            [columns]="displayedColumns" [pageSize]="pageSizes">
          </app-table-data>
        </ng-container>
      </div>
    </ng-container>
  </div>
  