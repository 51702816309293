<section class="createRole">
    <ng-container *ngIf="tableName == 'WQ_MSTR'">
        <form [formGroup]="createRoleform">
            <div class="row m-0">
                <div class="col-4 mb-4" *ngFor="let field of formfields">
                    <label class="section-name tabl-header tabl-header-font ">
                        {{field.label}}
                    </label>
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <div class="multiSelect-dropdown" (selectionChange)="setSelectedValues($event,field.placeholder)">
                                <app-dropdown-with-search searchFlag="true"
                                    isMultiSelect="true" [dropdownData]="field.dropdownData"
                                    [selected]="selectedValue[field.fcname]"
                                    (selectionChange)="setSelectedValues($event,field.placeholder)"></app-dropdown-with-search>
                            </div>
                        </ng-container>
                    </ng-container>

                </div>
            </div>

            <div class="form-footer">
                <div class="footer-buttons">
                    <button mat-mdc-raised-button 
                        class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                        (click)="submitForm()">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="tableName == 'APP_ATTR'">
        <form [formGroup]="updateAppAttrForm">
            <div class="row m-0">
                <div class="col-4 mb-4" *ngFor="let field of formfields">
                    <label class="section-name tabl-header tabl-header-font">
                        {{field.label}}
                    </label>
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <mat-select panelClass="global-select-panel" [name]="field.fcname"
                                    [formControlName]="field.fcname" multiple>
                                    <div class="table-data-font">
                                        <mat-option class="global-mat-option" *ngFor="let data of field.dropdownData"
                                            title="{{data}}" [value]="data"><label
                                                style="margin:0%">{{data}}</label></mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                </div>
            </div>

            <div class="form-footer">
                <div class="footer-buttons">
                    <button mat-mdc-raised-button 
                        class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                        (click)="submitForm()">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="tableName == 'LOOKUP_NO_HRY'">
        <form [formGroup]="updateLookUpNoHryForm">
            <div class="row m-0">
                <div class="col-4 mb-4" *ngFor="let field of formfields">
                    <label class="section-name tabl-header tabl-header-font">
                        {{field.label}}
                    </label>
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <mat-select panelClass="global-select-panel" [name]="field.fcname"
                                    [formControlName]="field.fcname" multiple>
                                    <div class="table-data-font">
                                        <mat-option class="global-mat-option" *ngFor="let data of field.dropdownData"
                                            title="{{data}}" [value]="data"><label
                                                style="margin:0%">{{data}}</label></mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field appearance="outline" class="lookup-text-box">
                                <input type="text" matInput [name]="field.fcname" [formControlName]="field.fcname" />
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                </div>
            </div>

            <div class="form-footer">
                <div class="footer-buttons">
                    <button mat-mdc-raised-button 
                        class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                        (click)="submitForm()">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="tableName == 'LOOKUP_WITH_HRY'">
        <form [formGroup]="updateLookUpHryForm">
            <div class="row m-0">
                <div class="col-4 mb-4" *ngFor="let field of formfields">
                    <label class="section-name tabl-header tabl-header-font">
                        {{field.label}}
                    </label>
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <mat-select panelClass="global-select-panel" [name]="field.fcname"
                                    [formControlName]="field.fcname" multiple>
                                    <div class="table-data-font">
                                        <mat-option class="global-mat-option" *ngFor="let data of field.dropdownData"
                                            title="{{data}}" [value]="data"><label
                                                style="margin:0%">{{data}}</label></mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field appearance="outline" class="lookup-text-box">
                                <input type="text" matInput [name]="field.fcname" [formControlName]="field.fcname" />
                            </mat-form-field>
                        </ng-container>
                       
                        
                    </ng-container>

                </div>
            </div>

            <div class="form-footer">
                <div class="footer-buttons">
                    <button mat-mdc-raised-button 
                        class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                        (click)="submitForm()">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="tableName == 'ADD_LOOKUP_WITH_HRY'">
        <form [formGroup]="addUpdateLookUpHryForm">
            <div class="row m-0">
                <div class="col-4 mb-4" *ngFor="let field of formfields">
                    <label class="section-name tabl-header tabl-header-font">
                        {{field.label}}
                    </label>
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <mat-select panelClass="global-select-panel" [name]="field.fcname"
                                    [formControlName]="field.fcname" multiple>
                                    <div class="table-data-font">
                                        <mat-option class="global-mat-option" *ngFor="let data of field.dropdownData"
                                            title="{{data}}" [value]="data"><label
                                                style="margin:0%">{{data}}</label></mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field appearance="outline" class="lookup-text-box">
                                <div>
                                    <input type="text" matInput [name]="field.fcname" [formControlName]="field.fcname"
                                        [placeholder]='field.placeholder' />
                                </div>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'textSelect'">
                            <button class="newButton" type="button" (click)="toggleInput()">{{btnName}}</button>
                            <mat-form-field appearance="outline" class="lookup-text-box" *ngIf="text">
                                <input type="text" matInput [name]="field.fcname" [formControlName]="field.fcname" />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="custom-dropdown" *ngIf="!text">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                    

                </div>
            </div>

            <div class="form-footer">
                <div class="footer-buttons">
                    <button mat-mdc-raised-button 
                        class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                        (click)="addLookupHry()" [disabled]="checkFormFilled()" type="button">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="tableName == 'ADD_LOOKUP_WITH_NO_HRY'">
        <form [formGroup]="addUpdateLookUpNoHryForm">
            <div class="row m-0">
                <div class="col-4 mb-4" *ngFor="let field of formfields">
                    <label class="section-name tabl-header tabl-header-font">
                        {{field.label}}
                    </label>
                    <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <mat-select panelClass="global-select-panel" [name]="field.fcname"
                                    [formControlName]="field.fcname" multiple>
                                    <div class="table-data-font">
                                        <mat-option class="global-mat-option" *ngFor="let data of field.dropdownData"
                                            title="{{data}}" [value]="data"><label
                                                style="margin:0%">{{data}}</label></mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field appearance="outline" class="lookup-text-box">
                                <div>
                                    <input type="text" matInput [name]="field.fcname" [formControlName]="field.fcname"
                                        [placeholder]='field.placeholder' />
                                </div>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                </div>
            </div>

            <div class="form-footer">
                <div class="footer-buttons">
                    <button mat-mdc-raised-button 
                        class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                        (click)="addLookupNoHry()" [disabled]="checkNoHryFormFilled()" type="button">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </ng-container>

</section>