import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterSelectionService {

  constructor() { }
  private filter$ = new BehaviorSubject({ askId: ''});

  filterValues() {
    return this.filter$;
  }

  setFilterValue(obj: { lob: string, appName: string, askId: string, capability: string, model: string, padu: string, cio: string, infraApp: string, category: string, lifecycle: string }) {
    this.filter$.next(obj);
  }
}
