<svg #svg>
  <line
    *ngFor="let line of svgLines"
    [attr.x1]="line.x1"
    [attr.y1]="line.y1"
    [attr.x2]="line.x2"
    [attr.y2]="line.y2"
    [style.stroke] = "line.color"
    style="stroke-width:1"
  ></line>
</svg>

<div class="content" appResize (resize)="repaint()">
  <ng-content></ng-content>
</div>
