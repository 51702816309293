import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minus'
})
export class MinusPipe implements PipeTransform {

  transform(num: number): any {
    return Math.abs(num);
  }

}

