
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LaborHubService } from '../../services/labor-hub.service';
import { MatSelectChange } from '@angular/material/select';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-laborhub-nav',
  templateUrl: './laborhub-nav.component.html',
  styleUrls: ['./laborhub-nav.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [

  ]
})
export class LaborhubNavComponent implements OnInit {
  loading = false;
  x: any;
  y: any;
  appList: any = [];
  BASE_YEAR = 2020;
  FILTERS_LIST = [
    {
      name: "Sr Exec",
      key: "WFM_Sr_Exec",
      isActive: true,
      options: [],
      value: '',
      oldValues: [],
      search: '',
      all: false,
      allValues: [],
      type: "select",
      disable: true,
    },
    {
      name: "Exec",
      key: "WFM_Exec",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
    },
    {
      name: "ADG",
      key: "WFM_Adg",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
    },
    {
      name: "Director",
      key: "WFM_Director",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
    },
    {
      name: "GL Dept",
      key: "GL_DEPT",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
    },
    {
      name: "Date",
      key: "Date",
      isActive: true,
      value: {
        begin: '',
        end: '',
      },
      type: "date",
      maxDate: '',
      minDate: new Date('2020-01-01 00:00:00.000'),
      disable: true,

    }
  ];

  FILTERS_LIST_DETAIL = [
    {
      name: "Sr Exec",
      key: "WFM_Sr_Exec",
      isActive: true,
      options: [],
      value: '',
      oldValues: [],
      search: '',
      all: false,
      allValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Exec",
      key: "WFM_Exec",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "ADG",
      key: "WFM_Adg",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Director",
      key: "WFM_Director",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "GL Dept",
      key: "GL_DEPT",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Labor Type",
      key: "Emp_Type_location",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Employee",
      key: "Full_Name",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: true
    },
    {
      name: "Date",
      key: "Date",
      isActive: true,
      value: {
        begin: '',
        end: '',
      },
      type: "date",
      maxDate: '',
      minDate: new Date('2020-01-01 00:00:00.000'),
      disable: true,
    },
    {
      name: "Contractors Rate  > $120",
      key: "Target_Employee",
      isActive: true,
      value: false,
      type: "checkbox",
      disable: true,
    }
  ];

  FTE_VIEW_FILTERS = [
    {
      name: "View",
      key: "View",
      isActive: true,
      options: this.prepareViewOptions(),
      value: 'ftecount',
      oldValues: [],
      search: '',
      all: false,
      allValues: [],
      type: "single-select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Year",
      key: "Year",
      isActive: true,
      options: this.prepareYearOptions(),
      value: new Date().getFullYear(),
      oldValues: [],
      search: '',
      all: false,
      allValues: [],
      type: "single-select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Sr Exec",
      key: "WFM_Sr_Exec",
      isActive: true,
      options: [],
      value: '',
      oldValues: [],
      search: '',
      all: false,
      allValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Exec",
      key: "WFM_Exec",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "ADG",
      key: "WFM_Adg",
      isActive: true,
      options: [],
      value: '',
      search: '',
      all: false,
      allValues: [],
      oldValues: [],
      type: "select",
      disable: true,
      virtualScroll: false
    },
    {
      name: "Date",
      key: "Date",
      isActive: false,
      value: {
        begin: '',
        end: '',
      },
      type: "date",
      maxDate: '',
      minDate: new Date('2020-01-01 00:00:00.000'),
      disable: true,
    },
  ];

  date: any;

  depedentDropDown = {
    'GL_DEPT': ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg', 'WFM_Director', 'GL_DEPT'],
    'WFM_Director': ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg', 'WFM_Director'],
    'WFM_Adg': ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg'],
    'WFM_Exec': ['WFM_Sr_Exec', 'WFM_Exec'],
    'WFM_Sr_Exec': ['WFM_Sr_Exec']
  };

  depedentDropDownList = {
    'GL_DEPT': { high: ['WFM_Director', 'WFM_Adg', 'WFM_Exec', 'WFM_Sr_Exec'], low: [] },
    'WFM_Director': { high: ['WFM_Adg', 'WFM_Exec', 'WFM_Sr_Exec'], low: ['GL_DEPT'] },
    'WFM_Adg': { high: ['WFM_Exec', 'WFM_Sr_Exec'], low: ['WFM_Director', 'GL_DEPT'] },
    'WFM_Exec': { high: ['WFM_Sr_Exec'], low: ['WFM_Adg', 'WFM_Director', 'GL_DEPT'] },
    'WFM_Sr_Exec': { high: [], low: ['WFM_Exec', 'WFM_Adg', 'WFM_Director', 'GL_DEPT'] }
  };

  depedentDropDownListFteView = {
    'WFM_Adg': { high: ['WFM_Exec', 'WFM_Sr_Exec'], low: [] },
    'WFM_Exec': { high: ['WFM_Sr_Exec'], low: ['WFM_Adg'] },
    'WFM_Sr_Exec': { high: [], low: ['WFM_Exec', 'WFM_Adg'] }
  };

  depedentDropDownListDetailView = {
    'Full_Name': { high: ['Emp_Type_location', 'GL_DEPT', 'WFM_Director', 'WFM_Adg', 'WFM_Exec', 'WFM_Sr_Exec'], low: [] },
    'Emp_Type_location': { high: ['GL_DEPT', 'WFM_Director', 'WFM_Adg', 'WFM_Exec', 'WFM_Sr_Exec'], low: ['Full_Name'] },
    'GL_DEPT': { high: ['WFM_Director', 'WFM_Adg', 'WFM_Exec', 'WFM_Sr_Exec'], low: ['Emp_Type_location', 'Full_Name'] },
    'WFM_Director': { high: ['WFM_Adg', 'WFM_Exec', 'WFM_Sr_Exec'], low: ['GL_DEPT', 'Emp_Type_location', 'Full_Name'] },
    'WFM_Adg': { high: ['WFM_Exec', 'WFM_Sr_Exec'], low: ['WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name'] },
    'WFM_Exec': { high: ['WFM_Sr_Exec'], low: ['WFM_Adg', 'WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name'] },
    'WFM_Sr_Exec': { high: [], low: ['WFM_Exec', 'WFM_Adg', 'WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name'] }
  };

  Filter_Columns = ['Date'];
  API_FILTERS_LIST;
  FILTER;
  QUERY_PARAMS: any = [];
  DETAIL_VIEW: boolean = false;
  PAGE: string = 'view';
  COMMON_FILTERS_LIST: any[];
  initialRange: ListRange = { start: 0, end: 8 };
  FILTER_API_LOADING: boolean = false;
  selectedEmployees: any[] = [];
  Ignore_RateColumn: boolean = true;
  viewPort1;
  constructor(
    private laborHubService: LaborHubService,
    private router: ActivatedRoute,
    private route: Router,
    private cdr: ChangeDetectorRef
  ) {
    // this.getFiltersList();
  }

  ngOnInit() {
    this.API_FILTERS_LIST = {};
    this.FILTER = '';

    this.router.queryParams.subscribe(params => {
      let path = this.route.url;
      this.DETAIL_VIEW = false;
      if (path.indexOf('detailview') !== -1) {
        this.DETAIL_VIEW = true;
        this.PAGE = 'detailview'
      } else if (path.indexOf('fteview') !== -1) {
        this.PAGE = 'fteview'
      } else if (path.indexOf('view') !== -1) {
        this.PAGE = 'view'
      }

      this.QUERY_PARAMS = [];

      if (params && params.q) {
        let queryString = localStorage.getItem(params.q);
        if (queryString) {
          this.QUERY_PARAMS = JSON.parse(atob(queryString));
          this.assignAppliedFilters();
        }
      }
    });
    if (!this.FILTER_API_LOADING) {
      this.getAppFiltersList();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  resetAppFilters() {
    let list = this.getFiltersList();
    list.forEach(element => {
      element.search = '';
      element.value = '';
      element.options = [];
      if (element.isActive && element.key == 'WFM_Sr_Exec') {
        let options: any[];
        options = [];
        let optionsList = Object.keys(this.API_FILTERS_LIST['Filters']);
        options = this.prepareOptions(optionsList, element);
        element.options = options;
      }
    });
  }

  resetAppFiltersNew() {
    this.selectedEmployees = [];
    this.assignFilterOptionsNew();
  }

  getApplicationOfSelected() {

    let filtersApplied: any = this.appliedFilters()['appliedFilters'];

    let pastQuery = this.router.snapshot.queryParamMap.get('q');
    if (pastQuery) {
      localStorage.removeItem(pastQuery);
    }
    const string = this.laborHubService.randomString(20, 'a') + '_' + Math.random().toFixed(10);
    let route: string;
    switch (this.PAGE) {
      case 'detailview':
        filtersApplied.push({
          name: "Ignore_RateColumn",
          value: this.Ignore_RateColumn,
        })
        route = 'detailview';
        break;
      case 'fteview':
        route = 'fteview';
        break;
      default:
        route = 'view';
        break;
    }
    filtersApplied = unescape(encodeURIComponent(JSON.stringify(filtersApplied)))
    localStorage.setItem(string, btoa(filtersApplied));
    this.route.navigate(['laborhub/' + `${route}`], {
      queryParams: {
        q: string
      }
    });

  }

  appliedFilters() {
    let appliedFilters = [];
    let removeAllOptions = [];
    let activeFilters = [];
    let list = this.getFiltersList();
    list.forEach((filterOption) => {
      if ((filterOption.isActive && (filterOption.value || (filterOption.type == 'checkbox')))) {
        let filter: any = {};
        filter['name'] = filterOption.key;
        filter['value'] = filterOption.value;
        appliedFilters.push(filter);
        if (filterOption.type == 'select') {
          let values: any = filterOption.value;
          values = values.filter(item => item != 'All');
          if (values.length) {
            removeAllOptions.push(filter);
          }
        } else if (filterOption.type == 'date') {
          if (filterOption.value.begin && filterOption.value.end) {
            removeAllOptions.push(filter);
          }
        } else {
          removeAllOptions.push(filter);
        }
      }
      if (filterOption.isActive) {
        activeFilters.push(filterOption);
      }
    });
    return {
      appliedFilters: appliedFilters,
      enabled: removeAllOptions.length == activeFilters.length
    }
  }

  getAppFiltersList() {

    this.FILTER_API_LOADING = true;
    this.laborHubService.getFiltersOptions(this.DETAIL_VIEW, this.PAGE).subscribe((results) => {
      this.FILTER_API_LOADING = false;
      let dataCacheKey = 'type?=' + this.PAGE;
      this.laborHubService.responseCache.set(dataCacheKey, results)

      this.API_FILTERS_LIST = results.data;

      if (this.API_FILTERS_LIST && typeof this.API_FILTERS_LIST.hasAccess != 'undefined') {
        this.Ignore_RateColumn = !this.API_FILTERS_LIST.hasAccess;
      }

      // this.assignFilterOptions(results.data);
      this.assignFilterOptionsNew();
      if (this.QUERY_PARAMS && this.QUERY_PARAMS.length) {
        this.assignAppliedFilters();
        //this.laborHubService.setIgnoreRateColumn(this.Ignore_RateColumn);
      }

    });
  }

  /**
   * Function which loops all the active filters and assign dropdown values to it.
   * @param data consist of API response for all the active filters
   */
  assignFilterOptions() {
    let list = this.getFiltersList();
    list.forEach(element => {
      element.disable = false;
      if (element.isActive && element.key == 'WFM_Sr_Exec') {
        let options: any[];
        options = [];
        element.options = [];
        element.search = '';
        let optionsList = Object.keys(this.API_FILTERS_LIST['Filters']);
        options = this.prepareOptions(optionsList, element);
        element.options = options;
      }
      else if (element.isActive && element.key == 'Date') {
        if (this.API_FILTERS_LIST['DATE'] && this.API_FILTERS_LIST['DATE']['Max_Date']) {
          // let maxDate: any = this.API_FILTERS_LIST['DATE']['Max_Date'];//moment(this.API_FILTERS_LIST['DATE']['Max_Date']).g;//new Date(this.API_FILTERS_LIST['DATE']['Max_Date']);
          let maxDateObject = new Date(this.API_FILTERS_LIST['DATE']['Max_Date']);
          let minDateObject = new Date(this.API_FILTERS_LIST['DATE']['Min_Date']);

          maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);
          let maxDate: any = maxDateObject
          element.maxDate = maxDate;

          minDateObject = this.laborHubService.compareAndOneDayToDate(minDateObject);

          element.minDate = minDateObject;
          let endDate: any = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0)
          // element.value = maxDate;
          element.value = {
            begin: maxDate,
            end: endDate
          }
        }
      }
    });
  }

  getFiltersList() {
    let filters = [];
    switch (this.PAGE) {
      case 'detailview':
        filters = this.FILTERS_LIST_DETAIL;
        if (this.Ignore_RateColumn) {
          filters = filters.filter((ele) => ele.key !== 'Target_Employee');
        }
        break;
      case 'fteview':
        filters = this.FTE_VIEW_FILTERS;
        break;
      default:
        filters = this.FILTERS_LIST;
        break;
    }
    this.COMMON_FILTERS_LIST = filters;
    return filters;
  }

  /**
   * Function which loops all the active filters and assign dropdown values to it.
   * @param data consist of API response for all the active filters
   */
  assignFilterOptionsNew() {
    let uniqueFilters: any = this.API_FILTERS_LIST['UniqueFilters'];
    let flist: any = this.getFiltersList();
    flist.forEach(element => {
      element.disable = false;
      if (element.isActive && element.key && uniqueFilters[element.key]) {
        let options: any[];
        options = [];
        element.options = [];
        element.value = '';
        element.search = '';
        let optionsList = uniqueFilters[element.key];
        options = this.prepareOptions(optionsList, element);
        element.options = options;
      }
      else if (element.isActive && element.key == 'Date') {

        if (this.API_FILTERS_LIST['DATE'] && this.API_FILTERS_LIST['DATE']['Max_Date']) {
          if (this.PAGE == 'fteview') {
            let date = new Date();
            let yearField = this.COMMON_FILTERS_LIST.find(ele => ele.key == 'Year');
            if (yearField.value && yearField.value == date.getFullYear()) {
              let maxDateObject = new Date(date.getFullYear(), 0, 1);
              maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);
              let endDate: any = new Date(date.getFullYear(), date.getMonth() + 1, 0)
              endDate = this.laborHubService.compareAndOneDayToDate(endDate);
              if (element) {
                element.minDate = maxDateObject;
                element.maxDate = endDate;
                element.value = {
                  begin: maxDateObject,
                  end: endDate
                }
              }
            } else {
              let yearFirstDate = new Date(yearField.value, 0, 1);
              //maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);
              let endDate: any = new Date(yearField.value, 12, 0)
              //endDate = this.laborHubService.compareAndOneDayToDate(endDate);
              if (element) {
                element.minDate = yearFirstDate;
                element.maxDate = endDate;
                element.value = {
                  begin: yearFirstDate,
                  end: endDate
                }
              }
            }

          } else {
            let maxDateObject = new Date(this.API_FILTERS_LIST['DATE']['Max_Date']);
            let minDateObject = new Date(this.API_FILTERS_LIST['DATE']['Min_Date']);
            maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);

            let maxDate: any = maxDateObject
            element.maxDate = maxDate;
            minDateObject = this.laborHubService.compareAndOneDayToDate(minDateObject);

            element.minDate = minDateObject;
            let endDate: any = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0)
            endDate = this.laborHubService.compareAndOneDayToDate(endDate);
            element.value = {
              begin: maxDate,
              end: endDate
            }
          }

        }
      } else if (element.isActive && element.key == 'Target_Employee') {
        element.value = false;
      }
      // else if (element.isActive && element.key == 'Emp_Type_location') {
      //   if (this.API_FILTERS_LIST['LabourType'] && this.API_FILTERS_LIST['LabourType']['EmpTypeLocation']) {
      //     let laborType = this.API_FILTERS_LIST['LabourType']['EmpTypeLocation']
      //     laborType.sort(this.laborHubService.generateSortFn([{ name: 'label', reverse: true }]))
      //     element.value = '';
      //     element.options = laborType;
      //   }
      // } else if (element.isActive && element.key == 'Full_Name') {
      //   if (this.API_FILTERS_LIST['Full_Name']) {
      //     let employees = this.API_FILTERS_LIST['Full_Name']
      //     let options = employees.map((ele) => {
      //       let opt: any = {};
      //       opt['label'] = ele.Full_Name;
      //       opt['value'] = ele.Full_Name;
      //       return opt;
      //     });
      //     element.value = '';
      //     element.options = options;
      //   }
      // }
    });
  }

  /**
   * Set applied filters
   * @param filters 
   */
  assignAppliedFilters() {
    let filters = this.QUERY_PARAMS;
    if (this.API_FILTERS_LIST && Object.keys(this.API_FILTERS_LIST).length) {
      let list = this.getFiltersList();
      if (!this.appliedFilters().enabled) {
        filters.forEach(filter => {
          let index = list.findIndex((ele) => ele.key == filter.name);
          if (index > -1) {
            list[index].value = filter.value;
            list[index].oldValues = JSON.parse(JSON.stringify(filter.value));
            list[index].disable = false;
            if (!['Date', 'Target_Employee', 'View', 'Year'].includes(filter.name)) {
              this.autoFillDependentFilters(filter.value, list[index], true);
            }
          }
        });
        let viewColumns = filters.find((ele) => ele.name == 'View');
        if (viewColumns) {
          this.onChangeFilter(viewColumns);
        }
      }
    } else {
      if (!this.FILTER_API_LOADING) {
        this.getAppFiltersList();
      }
    }

  }


  toggleAllSelection(event: MatOptionSelectionChange, filter) {
    this.FILTER = filter;
    if (event.isUserInput) {
      if (event.source.selected == true) {
        this.FILTER.allValues = [];
        this.FILTER.allValues.push('All');
        this.FILTER.all = true;


        if (filter.virtualScroll && event.isUserInput) {
          switch (filter.key) {
            case 'Full_Name':{
              let option = event.source;
              let optionValue = option.value;
              if (optionValue === 'All') {
                if (option.selected) {
                  this.selectedEmployees = filter.options.map((ele) => ele.value);
                } else if (!option.selected) {
                  this.selectedEmployees = [];
                }
              }
              // filter.value = this.selectedEmployees;
              // this.autoFillDependentFilters(this.selectedEmployees, filter);
              break;
            }
          }
        }

      } else {
        this.FILTER.all = false;
        this.FILTER.allValues = [];
        this.FILTER.value = [];
        switch (filter.key) {
          case 'Full_Name':
            this.selectedEmployees = [];
            break;
        }
      }
    }
  }

  toggleUnselectAllSelection(event) {
    if (event.isUserInput) {
      console.log(event);

    }
  }

  optionSelection(event: MatOptionSelectionChange, filter) {
    filter.allValues = [];
    filter.all = false;

    if (filter.virtualScroll && event.isUserInput) {
      switch (filter.key) {
        case 'Full_Name':{
          let option = event.source;
          let optionValue = option.value;
          if (this.selectedEmployees.includes(optionValue)) {
            if (!option.selected) {
              this.selectedEmployees = this.selectedEmployees.filter((ele) => ele != optionValue);
            }
          } else if (option.selected) {
            if (!this.selectedEmployees.includes(optionValue)) {
              this.selectedEmployees.push(optionValue)
            }
          }
          // filter.value = this.selectedEmployees;
          // this.autoFillDependentFilters(this.selectedEmployees, filter);
          break;
        }
      }
    }
  }

  onFilterSelection(event: MatSelectChange, filterV) {
    // let filter = '';// this.FILTER;//filterV;
    // if (this.FILTER) {
    //   filter = Object.assign(filter, this.FILTER);
    //   this.FILTER = '';
    // }
    // if (!filter) {
    //   filter = filterV;
    // }
    this.fillDependetDropDown(event.value, filterV);
  }


  selectItem(event: MatSelectChange, filterV) {
    if (filterV.key === 'Full_Name') {
      setTimeout(() => {
        this.autoFillDependentFilters(this.selectedEmployees, filterV);
      }, 100)
    } else {
      this.autoFillDependentFilters(event.value, filterV);
    }
  }

  getNextChild(childObjects) {
    let finalOptions = [];
    if (childObjects.length) {
      childObjects.forEach((childObj) => {
        let optnsv2 = Object.keys(childObj['children']);
        finalOptions = [...finalOptions, ...optnsv2];
      })
    }
    finalOptions = [...new Set(finalOptions)];
    return finalOptions;
  }

  getParentObject(parentObject) {
    let finalOptions: any = [];
    if (parentObject.length) {
      parentObject.forEach((parentObj) => {
        let optnsv2 = String(parentObj['id']);
        finalOptions.push(optnsv2);
      })
    }
    finalOptions = [...new Set(finalOptions)];
    return finalOptions;
  }

  loopObjectAndReturnOptions(drillDownFilters, columnName, lowlevlColum) {
    let list = this.getFiltersList();
    let parentFilterIndex = list.findIndex((ele) => { return ele.key == columnName });
    let filterIndex = list.findIndex((ele) => { return ele.key == lowlevlColum });
    let childObjects = [];
    if (filterIndex != -1) {
      let parentSelectedValues: any = list[parentFilterIndex].value;
      if (parentSelectedValues.length) {
        parentSelectedValues.forEach(filterValue => {
          let childObject = this.laborHubService.getChildNodes(drillDownFilters, columnName, filterValue);
          if (childObject) {
            childObjects.push(childObject);
          }
        });
      }
      if (childObjects.length) {
        childObjects = childObjects.reduce(function (prev, next) {
          return prev.concat(next);
        });
      }
    }

    return { childObjects, filterIndex };
  }

  loopObjectAndReturnParentOptions(drillDownFilters, childColumnName, parentColumn) {
    let list = this.getFiltersList();
    let filterIndex = list.findIndex((ele) => { return ele.key == childColumnName });
    let parentIndex = list.findIndex((ele) => { return ele.key == parentColumn });
    let parentObjects = [];
    if (filterIndex != -1) {
      let filterSelectedValue: any = list[filterIndex].value;
      if (filterSelectedValue.length) {

        filterSelectedValue.forEach(filterValue => {
          let childObject = this.laborHubService.getParentNodes(drillDownFilters, childColumnName, filterValue);
          if (childObject) {
            parentObjects.push(childObject);
          }

        });
        if (parentObjects.length) {
          parentObjects = parentObjects.reduce(function (prev, next) {
            return prev.concat(next);
          });
        }
      }
    }

    return { parentObjects, parentIndex };
  }


  autoFillDependentFilters(event, filterV, forceLoad = false) {
    let filterName = filterV.key;
    let columnName, filterIndex, optionsList, selectedOptions, parentIndex;
    let drillDownFilters = this.API_FILTERS_LIST['Filters'];

    if (filterV.oldValues && filterV.oldValues.length && !forceLoad) {
      if (!filterV.value && !event) {
        filterV.value = [];
      }
      let result = filterV.oldValues.filter(e => !filterV.value.find(a => e == a));
      if (result.length && result.includes('All')) {
        filterV.value = [];
      } else if (result.length) {
        filterV.value = event.filter(item => item != 'All');
      }
    }

    let { selectedFilter} = this.handleAllOptions(filterV.value, filterV);
    filterV.value = [...selectedFilter];

    // if (forceLoad) {
    switch (filterV.key) {
      case 'Full_Name':
        this.selectedEmployees = filterV.value;
        break;
    }
    // }

    if (!filterV.value || !filterV.value.length) {
      filterV.oldValues = [];
      return false;
    }


    filterV.oldValues = JSON.parse(JSON.stringify(filterV.value));
    let list = this.getFiltersList();

    if (!filterV.value.length) {
      let uniqueFilters: any = this.API_FILTERS_LIST['UniqueFilters'];
      if (uniqueFilters && uniqueFilters[filterV.key]) {
        filterV.options = this.prepareOptions(uniqueFilters[filterV.key], filterV);
      }
    }

    let filtersDependent;
    switch (this.PAGE) {
      case 'detailview':
        filtersDependent = this.depedentDropDownListDetailView[filterName];
        break;
      case 'fteview':
        filtersDependent = this.depedentDropDownListFteView[filterName];
        break;
      default:
        filtersDependent = this.depedentDropDownList[filterName];
    }


    if (filtersDependent) {
      let topLevelFilters = filtersDependent.high;
      let lowLevelFilters = filtersDependent.low;
      let childObjects = [];
      let parentObjects = [];

      topLevelFilters.forEach((highlevlColumn) => {
        switch (highlevlColumn) {
          case 'Emp_Type_location':
            // columnName = 'Full_Name';
            columnName = ['Full_Name'];
            break;
          case 'GL_DEPT':
            columnName = ['Emp_Type_location', 'Full_Name',];
            break;
          case 'WFM_Director':
            // columnName = ['GL_DEPT', 'Emp_Type_location', 'Full_Name',];
            columnName = ['GL_DEPT'];
            break;
          case 'WFM_Adg':
            // columnName = 'WFM_Director';
            // columnName = ['WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name',];
            columnName = ['WFM_Director'];
            break;
          case 'WFM_Exec':
            // columnName = 'WFM_Adg';
            // columnName = ['WFM_Adg', 'WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name',];
            columnName = ['WFM_Adg'];
            break;
          case 'WFM_Sr_Exec':
            // columnName = 'WFM_Exec';
            // columnName = ['WFM_Exec', 'WFM_Adg', 'WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name',];
            columnName = ['WFM_Exec'];
            break;
        }

        if (!this.DETAIL_VIEW) {
          //columnName = columnName.filter(ele => !['Emp_Type_location', 'Full_Name'].includes(ele))
        }

        switch (this.PAGE) {
          case 'fteview':
            columnName = columnName.filter(ele => !['WFM_Director', 'GL_DEPT', 'Emp_Type_location', 'Full_Name'].includes(ele))
            break;
          case 'detailview':
            break;
          case 'view':
            columnName = columnName.filter(ele => !['Emp_Type_location', 'Full_Name'].includes(ele))
            break;
        }

        ({ parentObjects, parentIndex } = this.laborHubService.loopObjectAndReturnParentOptionsN(drillDownFilters, columnName, highlevlColumn, this.getFiltersList()));
        if (parentIndex !== -1) {
          if (parentObjects.length) {
            selectedOptions = parentObjects;//this.getParentObject(parentObjects);
            selectedOptions = selectedOptions.filter((ele) => ele != null && ele != '');
            selectedOptions = selectedOptions.sort().reverse();

            if (list[parentIndex].options.length == selectedOptions.length) {
              selectedOptions.unshift('All');
            }

            list[parentIndex].value = selectedOptions;
            let filterOps = list[parentIndex].options;
            if (filterOps.length) {
              filterOps.sort((a, b) => {
                return selectedOptions.indexOf(b.value) - selectedOptions.indexOf(a.value);
              });
              list[parentIndex].options = filterOps;
            }
          } else {
            let filtersEmpty: any = []
            list[parentIndex].value = filtersEmpty;
          }
          list[parentIndex].oldValues = JSON.parse(JSON.stringify(list[parentIndex].value));
        }
      });

      lowLevelFilters.forEach((lowlevlColum) => {
        switch (lowlevlColum) {
          case 'WFM_Exec':
            // columnName = 'WFM_Sr_Exec';
            columnName = ['WFM_Sr_Exec'];
            break;
          case 'WFM_Adg':
            // columnName = 'WFM_Exec';
            columnName = ['WFM_Sr_Exec', 'WFM_Exec'];
            break;
          case 'WFM_Director':
            // columnName = 'WFM_Adg';
            columnName = ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg'];
            break;
          case 'GL_DEPT':
            // columnName = 'WFM_Director';
            columnName = ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg', 'WFM_Director'];
            break;
          case 'Emp_Type_location':
            // columnName = 'GL_DEPT';
            columnName = ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg', 'WFM_Director', 'GL_DEPT'];
            break;
          case 'Full_Name':
            // columnName = 'Emp_Type_location';
            columnName = ['WFM_Sr_Exec', 'WFM_Exec', 'WFM_Adg', 'WFM_Director', 'GL_DEPT', 'Emp_Type_location'];
            break;
        }
        if (!this.DETAIL_VIEW) {
          columnName = columnName.filter(ele => !['Emp_Type_location', 'Full_Name'].includes(ele));
        }
        // ({ childObjects, filterIndex } = this.loopObjectAndReturnOptions(drillDownFilters, columnName, lowlevlColum));
        ({ childObjects, filterIndex } = this.laborHubService.loopObjectAndReturnOptionsN(drillDownFilters, columnName, lowlevlColum, this.getFiltersList()));
        if (filterIndex !== -1) {
          selectedOptions = childObjects;//this.getNextChild(childObjects);
          selectedOptions = selectedOptions.filter((ele) => ele != null && ele != '');
          optionsList = this.prepareOptions(selectedOptions, list[filterIndex]);

          list[filterIndex].options = optionsList;
          if (selectedOptions.length && list[filterIndex].options.length == selectedOptions.length) {
            selectedOptions.unshift('All');
          } else {
            selectedOptions = selectedOptions.filter((ele) => ele != 'All');
          }

          list[filterIndex].value = selectedOptions;
          list[filterIndex].oldValues = JSON.parse(JSON.stringify(list[filterIndex].value));

          switch (list[filterIndex].key) {
            case 'Full_Name':
              this.selectedEmployees = list[filterIndex].value;
              break;
          }

          if (!optionsList.length) {
            let key = list[filterIndex].key;
            let uniqueFilters: any = this.API_FILTERS_LIST['UniqueFilters'];
            if (uniqueFilters && uniqueFilters[key]) {
              list[filterIndex].options = this.prepareOptions(uniqueFilters[key], list[filterIndex]);
            }
          }
        }
      });
    }

  }


  getSelectedValues(filterName, selectedFilterValue) {
    return this.laborHubService.getSelectedValues(filterName, selectedFilterValue, this.getFiltersList(), this.depedentDropDown)
  }

  handleAllOptions(selectedFilter, filter) {
    return this.laborHubService.handleAllOptions(selectedFilter, filter);
  }

  fillDependetDropDown(event, filterV, forceLoad = false) {
    let filterName = filterV.key;
    let columnName, findIdex, values;
    let drillDownFilters = this.API_FILTERS_LIST['Filters'];

    if (filterV.oldValues && filterV.oldValues.length && !forceLoad) {
      if (!filterV.value) {
        filterV.value = [];
      }
      let result = filterV.oldValues.filter(e => !filterV.value.find(a => e == a));
      if (result.length && result.includes('All')) {
        event = [];
      } else if (result.length) {
        event = event.filter(item => item != 'All');
      }
    }
    let list = this.getFiltersList();
    switch (filterName) {
      case 'WFM_Sr_Exec':{
        columnName = 'WFM_Exec';
        findIdex = list.findIndex((ele) => { return ele.key == columnName });
        let { selectedFilter, filter } = this.handleAllOptions(event, filterV);
        let optns = [];
        if (findIdex != -1) {
          selectedFilter.forEach((val) => {
            if (drillDownFilters[val] && drillDownFilters[val].children) {
              let ops = Object.keys(drillDownFilters[val].children);
              ops = ops.filter((ele) => ele != null && ele != '' && ele != 'null');
              optns = [...optns, ...ops];
            }
          });
          list[findIdex].options = this.prepareOptions(optns, list[findIdex]);
        }

        selectedFilter = [...selectedFilter];
        filter.value = selectedFilter;
        filter.oldValues = JSON.parse(JSON.stringify(filter.value));
        list.forEach((ele) => {
          if (!this.depedentDropDown[columnName].includes(ele.key) && !this.Filter_Columns.includes(ele.key)) {
            ele.value = '';
            ele.options = [];
          }
          if (ele.key == columnName) {
            ele.value = '';
          }
        });

        if (optns.length) {
          let ops = list[findIdex].options;
          ops = ops.map(ele => ele.value);
          this.fillDependetDropDown(ops, list[findIdex], true);
        }

        break;
      }
      case 'WFM_Exec':{
        columnName = 'WFM_Adg';
        findIdex = list.findIndex((ele) => { return ele.key == columnName });
        let { selectedFilter, filter } = this.handleAllOptions(event, filterV);
        values = this.getSelectedValues(filterName, selectedFilter);
        let level1Data = [];
        if (findIdex != -1) {
          let v1 = values[0];
          let v3 = values[1];
          v1.forEach((v2) => {
            if (drillDownFilters[v2] && drillDownFilters[v2]['children']) {
              let optns: any = drillDownFilters[v2]['children'];
              v3.forEach((v4) => {
                if (optns[v4] && optns[v4]['children']) {
                  let optnsv = Object.keys(optns[v4]['children']);
                  optnsv = optnsv.filter((ele) => ele != null && ele != '' && ele != 'null');
                  level1Data = [...level1Data, ...optnsv];
                }
              })
            }
          });
          list[findIdex].options = this.prepareOptions(level1Data, list[findIdex]);
        }
        selectedFilter = [...selectedFilter];
        filter.value = selectedFilter;
        filter.oldValues = JSON.parse(JSON.stringify(filter.value));
        list.forEach((ele) => {
          if (!this.depedentDropDown[columnName].includes(ele.key) && !this.Filter_Columns.includes(ele.key)) {
            ele.value = '';
            ele.options = [];
          }
          if (ele.key == columnName) {
            ele.value = '';
          }
        });

        if (level1Data.length) {
          let ops = list[findIdex].options;
          ops = ops.map(ele => ele.value);
          this.fillDependetDropDown(ops, list[findIdex], true);
        }

        break;
      }
      case 'WFM_Adg':{
        columnName = 'WFM_Director';
        findIdex = list.findIndex((ele) => { return ele.key == columnName });
        let { selectedFilter, filter } = this.handleAllOptions(event, filterV);
        values = this.getSelectedValues(filterName, selectedFilter);
        let level2Data = [];
        if (findIdex != -1) {

          let v1 = values[0];
          let v3 = values[1];
          let v5 = values[2];

          v1.forEach((v2) => {
            if (drillDownFilters[v2]) {
              let optns: any = drillDownFilters[v2]['children'];
              v3.forEach((v4) => {
                if (optns[v4] && optns[v4]['children']) {
                  let optnsv = (optns[v4]['children']);
                  v5.forEach((v6) => {
                    if (optnsv[v6] && optnsv[v6]['children']) {
                      let optnsv1 = Object.keys(optnsv[v6]['children']);
                      optnsv1 = optnsv1.filter((ele) => ele != null && ele != '' && ele != 'null');
                      level2Data = [...level2Data, ...optnsv1];
                    }
                  });
                }
              });
            }
          });
          list[findIdex].options = this.prepareOptions(level2Data, list[findIdex]);
        }

        selectedFilter = [...new Set(selectedFilter)];
        filter.value = selectedFilter;
        filter.oldValues = JSON.parse(JSON.stringify(filter.value));

        list.forEach((ele) => {
          if (!this.depedentDropDown[columnName].includes(ele.key) && !this.Filter_Columns.includes(ele.key)) {
            ele.value = '';
            ele.options = [];
          }
          if (ele.key == columnName) {
            ele.value = '';
          }
        });

        if (level2Data.length) {
          let ops = list[findIdex].options;
          ops = ops.map(ele => ele.value);
          this.fillDependetDropDown(ops, list[findIdex], true);
        }

        break;
      }
      case 'WFM_Director':{
        columnName = 'GL_DEPT';
        findIdex = list.findIndex((ele) => { return ele.key == columnName });
        let { selectedFilter, filter } = this.handleAllOptions(event, filterV);
        values = this.getSelectedValues(filterName, selectedFilter);
        let level3Data = [];
        if (findIdex != -1) {

          let v1 = values[0];
          let v3 = values[1];
          let v5 = values[2];
          let v7 = values[3];

          v1.forEach((v2) => {
            if (drillDownFilters[v2]) {
              let optns: any = drillDownFilters[v2]['children'];
              v3.forEach((v4) => {
                if (optns[v4] && optns[v4]['children']) {
                  let optnsv = (optns[v4]['children']);
                  v5.forEach((v6) => {
                    if (optnsv[v6] && optnsv[v6]['children']) {
                      let optnsv1 = (optnsv[v6]['children']);
                      v7.forEach((v8) => {
                        if (optnsv1[v8] && optnsv1[v8]['children']) {
                          let optnsv2 = Object.keys(optnsv1[v8]['children']);
                          optnsv2 = optnsv2.filter((ele) => ele != null && ele != '' && ele != 'null');
                          level3Data = [...level3Data, ...optnsv2];
                        }
                      })
                    }
                  });
                }
              });
            }
          });
          list[findIdex].options = this.prepareOptions(level3Data, list[findIdex]);
        }

        selectedFilter = [...new Set(selectedFilter)];
        filter.value = selectedFilter;
        filter.oldValues = JSON.parse(JSON.stringify(filter.value));

        list.forEach((ele) => {
          if (!this.depedentDropDown[columnName].includes(ele.key) && !this.Filter_Columns.includes(ele.key)) {
            ele.value = '';
            ele.options = [];
          }
          if (ele.key == columnName) {
            ele.value = '';
          }
        });
        if (level3Data.length) {
          let ops = list[findIdex].options;
          ops = ops.map(ele => ele.value);
          this.fillDependetDropDown(ops, list[findIdex], true);
        }
        break;
      }
      case 'GL_DEPT':{
        columnName = 'GL_DEPT';
        let { selectedFilter, filter } = this.handleAllOptions(event, filterV);
        selectedFilter = [...selectedFilter];
        filter.value = selectedFilter;
        filter.oldValues = JSON.parse(JSON.stringify(filter.value));
        break;
      }
    }

  }

  prepareOptions(optns, element = null) {
    return this.laborHubService.prepareOptions(optns, element);

  }

 

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<Moment>, filter, type) {

    // const ctrlValue = this.date.value;
    // ctrlValue.month(normalizedMonth.month());
    // this.date.setValue(ctrlValue);
    let dateObject = new Date(normalizedMonth);
    // console.log(dateObject.getDate());
    // console.log(dateObject.getUTCDate());


    // console.log(dateObject.getMonth());
    // console.log(dateObject.getUTCMonth());

    // console.log(dateObject.getFullYear());
    // console.log(dateObject.getUTCFullYear());

    dateObject = this.laborHubService.compareAndOneDayToDate(dateObject);

    if (type == 'from') {
      filter.value.begin = dateObject;
    } else if (type == 'to') {
      filter.value.end = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0);
    }

    if (filter.value.begin > filter.value.end) {
      filter.value.end = '';
    }
    datepicker.close();
  }

  openedChange(filter, opened, viewPort: CdkVirtualScrollViewport) {
    if (filter.virtualScroll && viewPort) {
      switch (filter.key) {
        case 'Full_Name':
          // filter.value = this.selectedEmployees;
          break;
      }
      if (!opened) {
        viewPort.setRenderedContentOffset(0);
        viewPort.setRenderedRange(this.initialRange);
      }
    }

    if (!opened) {
      if (filter && filter.search) {
        filter.search = '';
      }
    }
  }

  scrolledIndexChange(event, filter) {
    filter.value = this.selectedEmployees;
  }

  prepareViewOptions() {
    return [
      {
        value: 'ftecount',
        label: "FTE Count"
      },
      {
        value: 'ftesummary',
        label: "FTE Summary"
      }
    ]
  }

  prepareYearOptions() {
    let currentYear = new Date().getFullYear();
    let options = [];
    for (let i = this.BASE_YEAR; i <= currentYear; i++) {
      let option = {
        value: i,
        label: i
      };
      options.push(option);
    }
    return options;
  }

  onChangeFilter(filter) {
    let value = filter.value;
    switch (this.PAGE) {
      case 'fteview':{
        let view = this.COMMON_FILTERS_LIST.find((ele) => ele.key === 'View');
        let element = this.COMMON_FILTERS_LIST.find((ele) => ele.key === 'Date');

        if (view && view.value && view.value == 'ftesummary') {
          element.isActive = true;
          if (filter.key === 'Year') {
            let dateObject = this.API_FILTERS_LIST['DATE'];
            let yearDateObject = dateObject.find((ele) => ele.Year == value);

            if (yearDateObject) {
              let maxDateObject = new Date(yearDateObject['Year'], yearDateObject['Min_Month'] - 1, 1);
              maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);
              let endDate: any = new Date(yearDateObject['Year'], yearDateObject['Max_Month'], 0)
              endDate = this.laborHubService.compareAndOneDayToDate(endDate);
              if (element) {
                element.minDate = maxDateObject;
                element.maxDate = endDate;
                element.value = {
                  begin: maxDateObject,
                  end: endDate
                }
              }
            } else {
              let date = new Date();
              if (value == date.getFullYear()) {
                let maxDateObject = new Date(date.getFullYear(), 0, 1);
                maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);
                let endDate: any = new Date(date.getFullYear(), date.getMonth() + 1, 0)
                endDate = this.laborHubService.compareAndOneDayToDate(endDate);
                if (element) {
                  element.minDate = maxDateObject;
                  element.maxDate = endDate;
                  element.value = {
                    begin: maxDateObject,
                    end: endDate
                  }
                }
              } else {
                let yearFirstDate = new Date(value, 0, 1);
                //maxDateObject = this.laborHubService.compareAndOneDayToDate(maxDateObject);
                let endDate: any = new Date(value, 12, 0)
                //endDate = this.laborHubService.compareAndOneDayToDate(endDate);
                if (element) {
                  element.minDate = yearFirstDate;
                  element.maxDate = endDate;
                  element.value = {
                    begin: yearFirstDate,
                    end: endDate
                  }
                }
              }
            }
          } else {
            if (filter.key === 'View') {
              let element = this.COMMON_FILTERS_LIST.find((ele) => ele.key === 'Year');
              this.onChangeFilter(element)
            }
          }
        } else {
          element.isActive = false;
        }

        break;
      }
    }
  }
  ngOnDestroy() {
    // let pastQuery = this.router.snapshot.queryParamMap.get('q');
    // if (pastQuery) {
    //   localStorage.removeItem(pastQuery);
    //   this.QUERY_PARAMS = [];
    // }
  }
}
