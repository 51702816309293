import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { tcmHubConfigDetails } from '../../config';
import { portfoliohubV4APIs } from '../configV4';
import { OptimizeService } from 'src/app/shared/optimize.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioHubService {
  private apiUrl: string;
  private config: any;
  public configV4: any;
  public allPaduSummaryData = [];
  public allPaduSummaryDatatemp: any;
  public paduSummaryData = new BehaviorSubject<any>({});
  public paduPendingData = new BehaviorSubject<any>({});
  public paduYear = new BehaviorSubject<any>({});
  public PADU_filter_Year = new BehaviorSubject<any>({});
  public paduValues = new BehaviorSubject<any>({});
  public portfolioExcelData: any;
  public showPaduValuesLoader: boolean = false;
  public noKPILoader: boolean = false;
  public allPaduKpiDetails = {};
  public paduKpiDetailsFiltered = new BehaviorSubject<any>({});
  public allPaduKpiNoPrimaryDetails = {};
  public kpiNoPrimaryDetailsFiltered = new BehaviorSubject<any>({});
  public relationshipOverview = {};
  public filteredRelationshipOverview = new BehaviorSubject<any>({});
  public infraMonthlyTrend = {};
  public filteredInfraMonthlyTrend = new BehaviorSubject<any>({});
  public tcoMonthlyTrend = {};
  public laborMonthlyTrend = {};
  public filteredTcoMonthlyTrend = new BehaviorSubject<any>({});
  public categoryDetails = {};
  public filteredCategoryDetails = new BehaviorSubject<any>({});
  public infraData = {};
  public filteredInfraData = new BehaviorSubject<any>({});
  public filteredLaborMonthlyTrend = new BehaviorSubject<any>({});
  public showloaderFortrendChart: boolean = true;
  public filteredPaduCostData = new BehaviorSubject<any>({});
  public decomData = new BehaviorSubject<any>({});
  public CapsData = new BehaviorSubject<any>({});
  public porfolioSelectedFromFinance =  false;
  public padutabledata:any = [];
  public temppaginator:any;
  public comparereqbdy:any ={}
  public tempPadulenth;
  public tcotabledata:any =[];
  public tcmtabledata:any =[];
  public infraTableApplicationdata:any =[];
  public infraTableServicedata:any =[];
  CurrentYear = tcmHubConfigDetails.CurrentYear;
  PrevYear = tcmHubConfigDetails.PrevYear;
  CurrentMonth = tcmHubConfigDetails.CurrentMonth;
  PreMonth = tcmHubConfigDetails.PreMonth;
  CurrentMonthPrevYear = tcmHubConfigDetails.CurrentMonthPrevYear;
  Month = tcmHubConfigDetails.Month;
  constructor(private http: HttpClient, private http2: HttpClient, handler: HttpBackend, private optimize:OptimizeService) {
    this.configV4 = portfoliohubV4APIs;
    this.apiUrl = environment.apiUrl;
    this.http2 = new HttpClient(handler);
    this.getYearMonth();
  }
  getYearMonth() {
    this.optimize.getCurrentYear().subscribe(
      (res: any) => {
        if (res) {
          this.CurrentYear=res.currentYear;
          this.Month=res.currentMonth;
          this.PreMonth =res.previousMonth;
          tcmHubConfigDetails.CurrentYear = this.CurrentYear;
        }
      },
      err => {
        console.error(err);
        localStorage.removeItem('config');
      }
    );
  }
  public getSegmentLists(): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getSegments;
    return this.http.get<any>(url);
  }

  public getGroups(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getGroups;
    return this.http.post<any>(url, body);
  }

  public getOwners(searchText, body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getOwners + `?search=${searchText}`;
    return this.http.post<any>(url, body);
  }

  public getPADUSummary(body, type): Observable<any> {
    this.allPaduSummaryData = [];
    const url = this.apiUrl + this.configV4.routerPath.getPADUSummary;
    this.showPaduValuesLoader = true;
    return this.http
      .post<any>(url, body, { params: { type } })
      .pipe(
        map(data => {
          this.allPaduSummaryData = data;
          this.showPaduValuesLoader = false;
          if (data) {
            this.configurePaduCostData(type);
            return this.configurePaduSummaryData(type);
          } else {
            this.configurePaduCostData(type);
            return this.configurePaduSummaryData(type);
          }
        })
      );
  }

  public getdecommsummary(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getdecommsummary;
    return this.http.post<any>(url, body, { params: { type } });
  }

  public getpadulisttabledata(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getapplisttabledata;
    return this.http.post<any>(url, body, { params: { type } });
  }

  public getPaduKpiNoPrimary(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getPaduKpiNoPrimary;
    this.noKPILoader = true;
    return this.http
      .post<any>(url, body, { params: { type ,Year:this.CurrentYear} })
      .pipe(
        map(data => {
          this.allPaduKpiNoPrimaryDetails = data;
          this.noKPILoader = false;
          if (data) {
            return this.configureKpiNoPrimaryDetails(type);
          } else {
            return this.configureKpiNoPrimaryDetails(type);
          }
        })
      );
  }
  public getpadulisttabledatacount(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getapplisttabledatacount;
    return this.http.post<any>(url, body, { params: { type } });
  }
  public getCategoryDetails(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getCategoryDetails;
    return this.http
      .post<any>(url, body, { params: { type } })
      .pipe(
        map(data => {
          this.categoryDetails = data;
          if (data) {
            return this.configureCategoryDetails(type);
          } else {
            return this.configureCategoryDetails(type);
          }
        })
      );
  }

  public getPaduKpiDetails(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getPaduKpiDetails;
    return this.http
      .post<any>(url, body, { params: { type,Year:this.CurrentYear } })
      .pipe(
        map(data => {
          this.allPaduKpiDetails = data;
          if (data) {
            return this.configurePaduKpiDetails(type);
          } else {
            return this.configurePaduKpiDetails(type);
          }
        })
      );
  }

  public getInfraDetails(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getInfraDetails;
    return this.http.post<any>(url, body, { params: { type } });
  }
  public getTcoDetails(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getTcoDetails;
    return this.http.post<any>(url, body);
  }
  public getTcoExpansionDetails(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getTcoExpansionDetails;
    return this.http.post<any>(url, body);
  }
  public getTcmExpansionDetails(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getTcmExpansionDetails;
    return this.http.post<any>(url, body);
  }
  public getExpansioncount(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.tcotcmExpansiontabledatacount;
    return this.http.post<any>(url, body);
  }
  public getLaborMonthlyTrend(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getLaborMonthlyTrend;
    return this.http
      .post<any>(url, body, { params: { type } })
      .pipe(
        map(data => {
          this.laborMonthlyTrend = data;
          if (data) {
            return this.configurelaborMonthlyTrend(type);
          } else {
            return this.configurelaborMonthlyTrend(type);
          }
        })
      );
  }
  configurelaborMonthlyTrend(type) {
    let filteredData = [];
    if(type == 'leader') {
        filteredData = [];
        let laborData = this.laborMonthlyTrend;
        for (let [key, value] of Object.entries(laborData)) {
          let valueArray: any = value;
          let costObjData = [
            { month: '', year: '', om: 0, dev: 0 },
            { month: '', year: '', om: 0, dev: 0 },
            { month: '', year: '', om: 0, dev: 0 }
          ];
          let costObj = {};
          costObj['group'] = key;
          costObj['data'] = costObjData;
          valueArray.forEach(element => {
              Object.keys(element).map(objKey => {
                if (objKey != 'Agg_PADU' && objKey != 'group') {
                  if (element.data[0]) {
                    costObj[`${objKey}`][0].month = element.data[0].month;
                    costObj[`${objKey}`][0].year = element.data[0].year;
                    costObj[`${objKey}`][0].om += element.data[0].om;
                    costObj[`${objKey}`][0].dev += element.data[0].dev;
                  }
  
                  if (element.data[1]) {
                    costObj[`${objKey}`][1].month = element.data[1].month;
                    costObj[`${objKey}`][1].year = element.data[1].year;
                    costObj[`${objKey}`][1].om += element.data[1].om;
                    costObj[`${objKey}`][1].dev += element.data[1].dev;
                  }
  
                  if (element.data[2]) {
                    costObj[`${objKey}`][2].month = element.data[2].month;
                    costObj[`${objKey}`][2].year = element.data[2].year;
                    costObj[`${objKey}`][2].om += element.data[2].om;
                    costObj[`${objKey}`][2].dev += element.data[2].dev;
                  }
                }
              });
          });
          filteredData.push(costObj);
        }
        this.setFilteredLaborMonthlyTrend(filteredData);
    }
    else {
      this.paduValues.subscribe(data => {
        filteredData = [];
        let laborData = this.laborMonthlyTrend;
        for (let [key, value] of Object.entries(laborData)) {
          let valueArray: any = value;
          let costObjData = [
            { month: '', year: '', om: 0, dev: 0 },
            { month: '', year: '', om: 0, dev: 0 },
            { month: '', year: '', om: 0, dev: 0 }
          ];
          let costObj = {};
          costObj['group'] = key;
          costObj['data'] = costObjData;
          // valueArray.forEach(element => {
          //     if(data.includes(element['Agg_PADU'])) {
          //         Object.keys(element).map(objKey => {
          //             if(objKey != 'Agg_PADU' && objKey != 'group') {
          //                // costObj[`${objKey}`] = 0;
          //                 // costObjData=element.data;
          //                 costObjData[0].om=0;
          //                 costObjData[1].om=0;
          //                 costObjData[2].om=0;
          //                 costObjData[0].dev=0;
          //                 costObjData[1].dev=0;
          //                 costObjData[2].dev=0;

          //             }
          //         });
          //     }
          // });
          valueArray.forEach(element => {
            if (data.includes(element['Agg_PADU'])) {
              Object.keys(element).map(objKey => {
                if (objKey != 'Agg_PADU' && objKey != 'group') {
                  if (element.data[0]) {
                    costObj[`${objKey}`][0].month = element.data[0].month;
                    costObj[`${objKey}`][0].year = element.data[0].year;
                    costObj[`${objKey}`][0].om += element.data[0].om;
                    costObj[`${objKey}`][0].dev += element.data[0].dev;
                  }

                  if (element.data[1]) {
                    costObj[`${objKey}`][1].month = element.data[1].month;
                    costObj[`${objKey}`][1].year = element.data[1].year;
                    costObj[`${objKey}`][1].om += element.data[1].om;
                    costObj[`${objKey}`][1].dev += element.data[1].dev;
                  }

                  if (element.data[2]) {
                    costObj[`${objKey}`][2].month = element.data[2].month;
                    costObj[`${objKey}`][2].year = element.data[2].year;
                    costObj[`${objKey}`][2].om += element.data[2].om;
                    costObj[`${objKey}`][2].dev += element.data[2].dev;
                  }
                }
              });
            }
          });
          filteredData.push(costObj);
        }
        this.setFilteredLaborMonthlyTrend(filteredData);
      });
    }
    return filteredData;
  }
  setFilteredLaborMonthlyTrend(data) {
    this.filteredLaborMonthlyTrend.next(data);
  }

  getFilteredLaborMonthlyTrend() {
    return this.filteredLaborMonthlyTrend;
  }
  public getPaduCostDetails(body, type, flag): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getPaduCostDetails;
    return this.http.post<any>(url, body, { params: { type, flag } });
  }
  setPaduYear(year) {
    this.paduYear.next(year);
  }

  getPaduYear() {
    return this.paduYear;
  }

  setPaduValues(paduValues) {
    this.paduValues.next(paduValues);
  }

  getPaduValues() {
    return this.paduValues;
  }

  setPaduSummaryData(data) {
    this.paduSummaryData.next(data);
  }
  setPADUpending(data) {
    this.paduPendingData.next(data);
  }
  getPaduPendingData() {
    return this.paduPendingData;
  }
  getPaduSummaryData() {
    return this.paduSummaryData;
  }
  public getPADUYOY(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getPADUYOY;
    return this.http.post<any>(url, body, { params: { type,Year:this.CurrentYear } });
  }
  public getAppPaduYoy(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getAppPaduYoy;
    return this.http.post<any>(url, body, { params: { type } });
  }
  setPaduFilterYear(year) {
    this.PADU_filter_Year.next(year);
  }

  getPaduFilterYear() {
    return this.PADU_filter_Year;
  }

  configurePaduSummaryData(type) {
    let paduData = {};
    if(type == 'leader') {
        paduData = {
          Applications: 0,
          Infra_charges_CM: 0,
          Infra_Charges_YTD: 0,
          Infra_Charges_FY: 0,
          Infra_Projected_Charges: 0,
          Infra_Projected_Volumes: 0,
          Infra_Target_Charges: 0,
          Infra_Target_Volumes: 0,
          OM_Labor_CM: 0,
          OM_Labor_YTD: 0,
          OM_Labor_FY: 0,
          Dev_Labor_CM: 0,
          Dev_Labor_YTD: 0,
          Dev_Labor_FY: 0,
          ISA_CM: 0,
          ISA_YTD: 0,
          ISA_FY: 0,
          TCO_CM: 0,
          TCO_YTD: 0,
          TCO_FY: 0,
          PIM_Products: 0,
          PY_Infra_Charges_CM: 0,
          PY_Infra_Charges_YTD: 0,
          PY_Infra_Charges_FY: 0,
          PY_OMLaborSDSS_CM: 0,
          PY_OMLaborSDSS_YTD: 0,
          PY_OMLaborSDSS_FY: 0,
          PY_DevLaborSDSS_CM: 0,
          PY_DevLaborSDSS_YTD: 0,
          PY_DevLaborSDSS_FY: 0,
          PY_ISA_Charges_CM: 0,
          PY_ISA_Charges_YTD: 0,
          PY_ISA_Charges_FY: 0,
          PY_TCO_CM: 0,
          PY_TCO_YTD: 0,
          PY_TCO_FY: 0,
          PLM_Remediate: 0,
          PLM_Supported: 0,
          PLM_Unsupported: 0,
          PLM_Decommission: 0,
          PLM_Vulnerable: 0,
          PLM_Not_Availble: 0,
          TECH_PADU_Acceptable:0,
          TECH_PADU_PADU_Preferred:0,
          TECH_PADU_PADU_Unacceptable:0,
          TECH_PADU_Discouraged:0,
          TECH_PADU_PADU_Pending:0
        };
        this.allPaduSummaryData.forEach(element => {
          paduData[element.Agg_PADU] = element.Applications;
            if (element.Agg_PADU == 'PADU Pending') {
              this.setPADUpending([element]);
            }
            paduData['Dev_Labor_FY'] += element.Dev_Labor_FY;
            paduData['Applications'] += element.Applications;
            paduData['Infra_charges_CM'] += element.Infra_charges_CM;
            paduData['Infra_Charges_YTD'] += element.Infra_Charges_YTD;
            paduData['Infra_Charges_FY'] += element.Infra_Charges_FY;
            paduData['Infra_Projected_Charges'] += element.Infra_Projected_Charges;
            paduData['Infra_Projected_Volumes'] += element.Infra_Projected_Volumes;
            paduData['Infra_Target_Charges'] += element.Infra_Target_Charges;
            paduData['Infra_Target_Volumes'] += element.Infra_Target_Volumes;
            paduData['OM_Labor_CM'] += element.OM_Labor_CM;
            paduData['OM_Labor_YTD'] += element.OM_Labor_YTD;
            paduData['OM_Labor_FY'] += element.OM_Labor_FY;
            paduData['Dev_Labor_CM'] += element.Dev_Labor_CM;
            paduData['Dev_Labor_YTD'] += element.Dev_Labor_YTD;
            paduData['ISA_CM'] += element.ISA_CM;
            paduData['ISA_YTD'] += element.ISA_YTD;
            paduData['ISA_FY'] += element.ISA_FY;
            paduData['TCO_CM'] += element.TCO_CM;
            paduData['TCO_YTD'] += element.TCO_YTD;
            paduData['TCO_FY'] += element.TCO_FY;
            paduData['PIM_Products'] += element.PIM_Products;
            paduData['PY_Infra_Charges_CM'] += element.PY_Infra_Charges_CM;
            paduData['PY_Infra_Charges_YTD'] += element.PY_Infra_Charges_YTD;
            paduData['PY_Infra_Charges_FY'] += element.PY_Infra_Charges_FY;
            paduData['PY_OMLaborSDSS_CM'] += element.PY_OMLaborSDSS_CM;
            paduData['PY_OMLaborSDSS_YTD'] += element.PY_OMLaborSDSS_YTD;
            paduData['PY_OMLaborSDSS_FY'] += element.PY_OMLaborSDSS_FY;
            paduData['PY_DevLaborSDSS_CM'] += element.PY_DevLaborSDSS_CM;
            paduData['PY_DevLaborSDSS_YTD'] += element.PY_DevLaborSDSS_YTD;
            paduData['PY_DevLaborSDSS_FY'] += element.PY_DevLaborSDSS_FY;
            paduData['PY_ISA_Charges_CM'] += element.PY_ISA_Charges_CM;
            paduData['PY_ISA_Charges_YTD'] += element.PY_ISA_Charges_YTD;
            paduData['PY_ISA_Charges_FY'] += element.PY_ISA_Charges_FY;
            paduData['PY_TCO_CM'] += element.PY_TCO_CM;
            paduData['PY_TCO_YTD'] += element.PY_TCO_YTD;
            paduData['PY_TCO_FY'] += element.PY_TCO_FY;
            paduData['PLM_Remediate'] += element.PLM_Remediate;
            paduData['PLM_Supported'] += element.PLM_Supported;
            paduData['PLM_Unsupported'] += element.PLM_Unsupported;
            paduData['PLM_Decommission'] += element.PLM_Decommission;
            paduData['PLM_Vulnerable'] += element.PLM_Vulnerable;
            paduData['PLM_Not_Availble'] += element.PLM_Not_Availble;        
            paduData['TECH_PADU_Acceptable'] += element.TECH_PADU_Acceptable;
            paduData['TECH_PADU_PADU_Preferred'] += element.TECH_PADU_PADU_Preferred;
            paduData['TECH_PADU_PADU_Unacceptable'] += element.TECH_PADU_PADU_Unacceptable;
            paduData['TECH_PADU_Discouraged'] += element.TECH_PADU_Discouraged;
            paduData['TECH_PADU_PADU_Pending'] += element.TECH_PADU_PADU_Pending;
        });
        this.setPaduSummaryData([paduData]);
    }
    else  {
      this.getPaduValues().subscribe(data => {
        paduData = {
          Applications: 0,
          Infra_charges_CM: 0,
          Infra_Charges_YTD: 0,
          Infra_Charges_FY: 0,
          Infra_Projected_Charges: 0,
          Infra_Projected_Volumes: 0,
          Infra_Target_Charges: 0,
          Infra_Target_Volumes: 0,
          OM_Labor_CM: 0,
          OM_Labor_YTD: 0,
          OM_Labor_FY: 0,
          Dev_Labor_CM: 0,
          Dev_Labor_YTD: 0,
          Dev_Labor_FY: 0,
          ISA_CM: 0,
          ISA_YTD: 0,
          ISA_FY: 0,
          TCO_CM: 0,
          TCO_YTD: 0,
          TCO_FY: 0,
          PIM_Products: 0,
          PY_Infra_Charges_CM: 0,
          PY_Infra_Charges_YTD: 0,
          PY_Infra_Charges_FY: 0,
          PY_OMLaborSDSS_CM: 0,
          PY_OMLaborSDSS_YTD: 0,
          PY_OMLaborSDSS_FY: 0,
          PY_DevLaborSDSS_CM: 0,
          PY_DevLaborSDSS_YTD: 0,
          PY_DevLaborSDSS_FY: 0,
          PY_ISA_Charges_CM: 0,
          PY_ISA_Charges_YTD: 0,
          PY_ISA_Charges_FY: 0,
          PY_TCO_CM: 0,
          PY_TCO_YTD: 0,
          PY_TCO_FY: 0,
          PLM_Remediate: 0,
          PLM_Supported: 0,
          PLM_Unsupported: 0,
          PLM_Decommission: 0,
          PLM_Vulnerable: 0,
          PLM_Not_Availble: 0,
          TECH_PADU_Acceptable:0,
          TECH_PADU_PADU_Preferred:0,
          TECH_PADU_PADU_Unacceptable:0,
          TECH_PADU_Discouraged:0,
          TECH_PADU_PADU_Pending:0
        };
        this.allPaduSummaryData.forEach(element => {
          paduData[element.Agg_PADU] = element.Applications;
          if (data.includes(element.Agg_PADU)) {
            if (element.Agg_PADU == 'PADU Pending') {
              this.setPADUpending([element]);
            }
            paduData['Dev_Labor_FY'] += element.Dev_Labor_FY;
            paduData['Applications'] += element.Applications;
            paduData['Infra_charges_CM'] += element.Infra_charges_CM;
            paduData['Infra_Charges_YTD'] += element.Infra_Charges_YTD;
            paduData['Infra_Charges_FY'] += element.Infra_Charges_FY;
            paduData['Infra_Projected_Charges'] += element.Infra_Projected_Charges;
            paduData['Infra_Projected_Volumes'] += element.Infra_Projected_Volumes;
            paduData['Infra_Target_Charges'] += element.Infra_Target_Charges;
            paduData['Infra_Target_Volumes'] += element.Infra_Target_Volumes;
            paduData['OM_Labor_CM'] += element.OM_Labor_CM;
            paduData['OM_Labor_YTD'] += element.OM_Labor_YTD;
            paduData['OM_Labor_FY'] += element.OM_Labor_FY;
            paduData['Dev_Labor_CM'] += element.Dev_Labor_CM;
            paduData['Dev_Labor_YTD'] += element.Dev_Labor_YTD;
            paduData['ISA_CM'] += element.ISA_CM;
            paduData['ISA_YTD'] += element.ISA_YTD;
            paduData['ISA_FY'] += element.ISA_FY;
            paduData['TCO_CM'] += element.TCO_CM;
            paduData['TCO_YTD'] += element.TCO_YTD;
            paduData['TCO_FY'] += element.TCO_FY;
            paduData['PIM_Products'] += element.PIM_Products;
            paduData['PY_Infra_Charges_CM'] += element.PY_Infra_Charges_CM;
            paduData['PY_Infra_Charges_YTD'] += element.PY_Infra_Charges_YTD;
            paduData['PY_Infra_Charges_FY'] += element.PY_Infra_Charges_FY;
            paduData['PY_OMLaborSDSS_CM'] += element.PY_OMLaborSDSS_CM;
            paduData['PY_OMLaborSDSS_YTD'] += element.PY_OMLaborSDSS_YTD;
            paduData['PY_OMLaborSDSS_FY'] += element.PY_OMLaborSDSS_FY;
            paduData['PY_DevLaborSDSS_CM'] += element.PY_DevLaborSDSS_CM;
            paduData['PY_DevLaborSDSS_YTD'] += element.PY_DevLaborSDSS_YTD;
            paduData['PY_DevLaborSDSS_FY'] += element.PY_DevLaborSDSS_FY;
            paduData['PY_ISA_Charges_CM'] += element.PY_ISA_Charges_CM;
            paduData['PY_ISA_Charges_YTD'] += element.PY_ISA_Charges_YTD;
            paduData['PY_ISA_Charges_FY'] += element.PY_ISA_Charges_FY;
            paduData['PY_TCO_CM'] += element.PY_TCO_CM;
            paduData['PY_TCO_YTD'] += element.PY_TCO_YTD;
            paduData['PY_TCO_FY'] += element.PY_TCO_FY;
            paduData['PLM_Remediate'] += element.PLM_Remediate;
            paduData['PLM_Supported'] += element.PLM_Supported;
            paduData['PLM_Unsupported'] += element.PLM_Unsupported;
            paduData['PLM_Decommission'] += element.PLM_Decommission;
            paduData['PLM_Vulnerable'] += element.PLM_Vulnerable;
            paduData['PLM_Not_Availble'] += element.PLM_Not_Availble;        
            paduData['TECH_PADU_Acceptable'] += element.TECH_PADU_Acceptable;
            paduData['TECH_PADU_PADU_Preferred'] += element.TECH_PADU_PADU_Preferred;
            paduData['TECH_PADU_PADU_Unacceptable'] += element.TECH_PADU_PADU_Unacceptable;
            paduData['TECH_PADU_Discouraged'] += element.TECH_PADU_Discouraged;
            paduData['TECH_PADU_PADU_Pending'] += element.TECH_PADU_PADU_Pending;
          }
        });
        this.setPaduSummaryData([paduData]);
      });
    }
    return [paduData];
  }

  setPaduKpiDetailsFiltered(data) {
    this.paduKpiDetailsFiltered.next(data);
  }

  getPaduKpiDetailsFiltered() {
    return this.paduKpiDetailsFiltered;
  }

  configurePaduKpiDetails(type) {
    let paduKpiData = {};
    if(type == 'leader') {
        let noRoadmap = {
          TCO_FY: 0,
          Applications: 0
        };
        let globalNoRoadmap: [] = this.allPaduKpiDetails['noRoadmap'];
        if (globalNoRoadmap.length > 0) {
          globalNoRoadmap.forEach(element => {
              noRoadmap['TCO_FY'] += element['TCO_FY'];
              noRoadmap['Applications'] += element['Applications'];
          });
        }
        paduKpiData['noRoadmap'] = noRoadmap;
        this.setPaduKpiDetailsFiltered(paduKpiData);
    }
    else {
      this.paduValues.subscribe(data => {
        let noRoadmap = {
          TCO_FY: 0,
          Applications: 0
        };
        let globalNoRoadmap: [] = this.allPaduKpiDetails['noRoadmap'];
        if (globalNoRoadmap.length > 0) {
          globalNoRoadmap.forEach(element => {
            if (data.includes(element['Agg_PADU'])) {
              noRoadmap['TCO_FY'] += element['TCO_FY'];
              noRoadmap['Applications'] += element['Applications'];
            }
          });
        }
        paduKpiData['noRoadmap'] = noRoadmap;
        this.setPaduKpiDetailsFiltered(paduKpiData);
      });
    }
    return paduKpiData;
  }

  setKpiNoPrimaryDetailsFiltered(data) {
    this.kpiNoPrimaryDetailsFiltered.next(data);
  }

  getKpiNoPrimaryDetailsFiltered() {
    return this.kpiNoPrimaryDetailsFiltered;
  }

  configureKpiNoPrimaryDetails(type) {
    let paduKpiNoPrimaryData = {};
    if(type == 'leader') {
        let noCapability = {
          TCO_FY: 0,
          Applications: 0
        };
        let primaryCount = {
          TCO_FY: 0,
          Applications: 0
        };
        let globalNoCapability: [] = this.allPaduKpiNoPrimaryDetails['noCapability'];
        let globalPrimaryCount: [] = this.allPaduKpiNoPrimaryDetails['primaryCount'];
        if (globalNoCapability.length > 0) {
          globalNoCapability.forEach(element => {
              noCapability['TCO_FY'] += element['TCO_FY'];
              noCapability['Applications'] += element['Applications'];
          });
        }
        if (globalPrimaryCount.length > 0) {
          globalPrimaryCount.forEach(element => {
              primaryCount['TCO_FY'] += element['TCO_FY'];
              primaryCount['Applications'] += element['Applications'];
          });
        }
        paduKpiNoPrimaryData['noCapability'] = noCapability;
        paduKpiNoPrimaryData['primaryCount'] = primaryCount;
        this.setKpiNoPrimaryDetailsFiltered(paduKpiNoPrimaryData);
    }
    else {
      this.paduValues.subscribe(data => {
        let noCapability = {
          TCO_FY: 0,
          Applications: 0
        };
        let primaryCount = {
          TCO_FY: 0,
          Applications: 0
        };
        let globalNoCapability: [] = this.allPaduKpiNoPrimaryDetails['noCapability'];
        let globalPrimaryCount: [] = this.allPaduKpiNoPrimaryDetails['primaryCount'];
        if (globalNoCapability.length > 0) {
          globalNoCapability.forEach(element => {
            if (data.includes(element['Agg_PADU'])) {
              noCapability['TCO_FY'] += element['TCO_FY'];
              noCapability['Applications'] += element['Applications'];
            }
          });
        }
        if (globalPrimaryCount.length > 0) {
          globalPrimaryCount.forEach(element => {
            if (data.includes(element['Agg_PADU'])) {
              primaryCount['TCO_FY'] += element['TCO_FY'];
              primaryCount['Applications'] += element['Applications'];
            }
          });
        }
        paduKpiNoPrimaryData['noCapability'] = noCapability;
        paduKpiNoPrimaryData['primaryCount'] = primaryCount;
        this.setKpiNoPrimaryDetailsFiltered(paduKpiNoPrimaryData);
      });
    }
    return paduKpiNoPrimaryData;
  }

  public getInfraTableCount(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getInfraTableCount;
    return this.http.post<any>(url, body);
  }
  public getInfraTableData(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getInfraTableData;
    return this.http.post<any>(url, body);
  }

  getRelationshipsOverView(body, type) {
    const url = this.apiUrl + this.configV4.routerPath.getRelationshipsOverView;
    return this.http
      .post<any>(url, body, { params: { type } })
      .pipe(
        map(data => {
          this.relationshipOverview = data;
          if (data) {
            return this.configureRelationshipOverviewData(type);
          } else {
            return this.configureRelationshipOverviewData(type);
          }
        })
      );
  }
  public getInfraDetail(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getInfraDetails;
    return this.http
      .post<any>(url, body, { params: { type } })
      .pipe(
        map(data => {
          this.infraData = data;
          if (data) {
            return this.configureInfraData(this.infraData, type);
          } else {
            return this.configureInfraData(this.infraData, type);
          }
        })
      );
  }
  setFilteredRelationshipOverview(data) {
    this.filteredRelationshipOverview.next(data);
  }
  setFilteredInfraData(data) {
    this.filteredInfraData.next(data);
  }
  getFilteredRelationshipOverview() {
    return this.filteredRelationshipOverview;
  }
  getFilteredInfraData() {
    return this.filteredInfraData;
  }
  configureInfraData(infraData, type) {
    let filteredInfraData = {};
    if(type == 'leader') {
        filteredInfraData = {
          serviceCount: 0
        };
        let servicesData = infraData['serviceCount'];
        let allServicesCount: [] = [];
        if (servicesData) {
          Object.keys(servicesData).map(key => {
              let paduValueArray: [] = servicesData[key];
              paduValueArray.forEach(value => {
                if (value['noofServices'] != null && !allServicesCount.includes(value['noofServices'])) {
                  allServicesCount.push(value['noofServices']);
                }
              });
          });
        }
        filteredInfraData['serviceCount'] = allServicesCount.length;
        filteredInfraData['infraCharges'] = this.infraData['infraCharges'];
        this.setFilteredInfraData(filteredInfraData);
    }
    else {
      this.paduValues.subscribe(data => {
        filteredInfraData = {
          serviceCount: 0
        };
        let servicesData = infraData['serviceCount'];
        let allServicesCount: [] = [];
        if (servicesData) {
          Object.keys(servicesData).map(key => {
            if (data.includes(key)) {
              let paduValueArray: [] = servicesData[key];
              paduValueArray.forEach(value => {
                if (value['noofServices'] != null && !allServicesCount.includes(value['noofServices'])) {
                  allServicesCount.push(value['noofServices']);
                }
              });
            }
          });
        }
        filteredInfraData['serviceCount'] = allServicesCount.length;
        filteredInfraData['infraCharges'] = this.infraData['infraCharges'];
        this.setFilteredInfraData(filteredInfraData);
      });
    }
  }
  configureRelationshipOverviewData(type) {
    let filteredRelationshipOverview = {};
    if(type == 'leader') {
        filteredRelationshipOverview = {
          capabilities: 0,
          organizations: 0,
          platforms: 0,
          products: 0
        };

        let globalCapabilities = this.relationshipOverview['capabilities'];
        let globalOrganizations = this.relationshipOverview['organizations'];
        let globalPlatforms = this.relationshipOverview['platforms'];
        let globalProducts = this.relationshipOverview['products'];
        let allCapabilities: [] = [];
        let allOrganizations: [] = [];
        let allPlatforms: [] = [];
        let allProducts: [] = [];
        if (globalCapabilities) {
          Object.keys(globalCapabilities).map(key => {
              let paduValueArray: [] = globalCapabilities[key];
              paduValueArray.forEach(value => {
                if (value['capabilities'] != null && !allCapabilities.includes(value['capabilities'])) {
                  allCapabilities.push(value['capabilities']);
                }
              });
          });
        }
        if (globalOrganizations) {
          Object.keys(globalOrganizations).map(key => {
              let paduValueArray: [] = globalOrganizations[key];
              paduValueArray.forEach(value => {
                if (value['organizations'] != null && !allOrganizations.includes(value['organizations'])) {
                  allOrganizations.push(value['organizations']);
                }
              });
          });
        }
        if (globalPlatforms) {
          Object.keys(globalPlatforms).map(key => {
              let paduValueArray: [] = globalPlatforms[key];
              paduValueArray.forEach(value => {
                if (value['platforms'] != null && !allPlatforms.includes(value['platforms'])) {
                  allPlatforms.push(value['platforms']);
                }
              });
          });
        }
        if (globalProducts) {
          Object.keys(globalProducts).map(key => {
              let paduValueArray: [] = globalProducts[key];
              paduValueArray.forEach(value => {
                if (value['products'] != null && !allProducts.includes(value['products'])) {
                  allProducts.push(value['products']);
                }
              });
          });
        }
        filteredRelationshipOverview['capabilities'] = allCapabilities.length;
        filteredRelationshipOverview['organizations'] = allOrganizations.length;
        filteredRelationshipOverview['platforms'] = allPlatforms.length;
        filteredRelationshipOverview['products'] = allProducts.length;
        this.setFilteredRelationshipOverview(filteredRelationshipOverview);
    }
    else {
      this.paduValues.subscribe(data => {
        filteredRelationshipOverview = {
          capabilities: 0,
          organizations: 0,
          platforms: 0,
          products: 0
        };

        let globalCapabilities = this.relationshipOverview['capabilities'];
        let globalOrganizations = this.relationshipOverview['organizations'];
        let globalPlatforms = this.relationshipOverview['platforms'];
        let globalProducts = this.relationshipOverview['products'];
        let allCapabilities: [] = [];
        let allOrganizations: [] = [];
        let allPlatforms: [] = [];
        let allProducts: [] = [];
        if (globalCapabilities) {
          Object.keys(globalCapabilities).map(key => {
            if (data.includes(key)) {
              let paduValueArray: [] = globalCapabilities[key];
              paduValueArray.forEach(value => {
                if (value['capabilities'] != null && !allCapabilities.includes(value['capabilities'])) {
                  allCapabilities.push(value['capabilities']);
                }
              });
            }
          });
        }
        if (globalOrganizations) {
          Object.keys(globalOrganizations).map(key => {
            if (data.includes(key)) {
              let paduValueArray: [] = globalOrganizations[key];
              paduValueArray.forEach(value => {
                if (value['organizations'] != null && !allOrganizations.includes(value['organizations'])) {
                  allOrganizations.push(value['organizations']);
                }
              });
            }
          });
        }
        if (globalPlatforms) {
          Object.keys(globalPlatforms).map(key => {
            if (data.includes(key)) {
              let paduValueArray: [] = globalPlatforms[key];
              paduValueArray.forEach(value => {
                if (value['platforms'] != null && !allPlatforms.includes(value['platforms'])) {
                  allPlatforms.push(value['platforms']);
                }
              });
            }
          });
        }
        if (globalProducts) {
          Object.keys(globalProducts).map(key => {
            if (data.includes(key)) {
              let paduValueArray: [] = globalProducts[key];
              paduValueArray.forEach(value => {
                if (value['products'] != null && !allProducts.includes(value['products'])) {
                  allProducts.push(value['products']);
                }
              });
            }
          });
        }
        filteredRelationshipOverview['capabilities'] = allCapabilities.length;
        filteredRelationshipOverview['organizations'] = allOrganizations.length;
        filteredRelationshipOverview['platforms'] = allPlatforms.length;
        filteredRelationshipOverview['products'] = allProducts.length;
        this.setFilteredRelationshipOverview(filteredRelationshipOverview);
      });
    }
    return filteredRelationshipOverview;
  }

  getCostInfraMonthlyTrend(body, type) {
    this.showloaderFortrendChart = true;
    const url = this.apiUrl + this.configV4.routerPath.getCostMonthlyTrend;
    return this.http
      .post<any>(url, body, { params: { type,Year:this.CurrentYear } })
      .pipe(
        map(data => {
          this.infraMonthlyTrend = data['costDataInfra'];
          this.tcoMonthlyTrend = data['costDataTco'];
          this.laborMonthlyTrend = data['laborData'];
          if (data) {
            this.showloaderFortrendChart = false;
            this.configureTcoMonthlyTrend(type);
            this.configurelaborMonthlyTrend(type);
            return this.configureInfraMonthlyTrend(type);
          } else {
            this.configureTcoMonthlyTrend(type);
            this.configurelaborMonthlyTrend(type);
            return this.configureInfraMonthlyTrend(type);
          }
        })
      );
  }

  setFilteredInfraMonthlyTrend(data) {
    this.filteredInfraMonthlyTrend.next(data);
  }

  getFilteredInfraMonthlyTrend() {
    return this.filteredInfraMonthlyTrend;
  }

  configureInfraMonthlyTrend(type) {
    let filteredData = [];
    if(type == 'leader') {
      filteredData = [];
      let infraData = this.infraMonthlyTrend;
      if (infraData) {
        for (let [key, value] of Object.entries(infraData)) {
          let valueArray: any = value;
          let costObj = {};
          costObj['group'] = key;
          valueArray.forEach(element => {
            Object.keys(element).map(objKey => {
              if (objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                costObj[`${objKey}`] = 0;
              }
            });
          });
          valueArray.forEach(element => {
            Object.keys(element).map(objKey => {
              if (objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                costObj[`${objKey}`] += element[objKey];
              }
            });
          });
          filteredData.push(costObj);
        }
        this.setFilteredInfraMonthlyTrend(filteredData);
      }
    }
    else {
      this.paduValues.subscribe(data => {
        filteredData = [];
        let infraData = this.infraMonthlyTrend;
        if (infraData) {
          for (let [key, value] of Object.entries(infraData)) {
            let valueArray: any = value;
            let costObj = {};
            costObj['group'] = key;
            valueArray.forEach(element => {
              if (data.includes(element['Agg_PADU'])) {
                Object.keys(element).map(objKey => {
                  if (objKey != 'Agg_PADU' && objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                    costObj[`${objKey}`] = 0;
                  }
                });
              }
            });
            valueArray.forEach(element => {
              if (data.includes(element['Agg_PADU'])) {
                Object.keys(element).map(objKey => {
                  if (objKey != 'Agg_PADU' && objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                    costObj[`${objKey}`] += element[objKey];
                  }
                });
              }
            });
            filteredData.push(costObj);
          }
          this.setFilteredInfraMonthlyTrend(filteredData);
        }
      });
    }
    return filteredData;
  }

  getCostTcoMonthlyTrend(body, type) {
    this.showloaderFortrendChart = true;
    const url = this.apiUrl + this.configV4.routerPath.costTcoMonthlyTrend;
    return this.http
      .post<any>(url, body, { params: { type } })
      .pipe(
        map(data => {
          this.tcoMonthlyTrend = data;
          if (data) {
            this.showloaderFortrendChart = false;
            return this.configureTcoMonthlyTrend(type);
          } else {
            return this.configureTcoMonthlyTrend(type);
          }
        })
      );
  }

  setFilteredTcoMonthlyTrend(data) {
    this.filteredTcoMonthlyTrend.next(data);
  }

  getFilteredTcoMonthlyTrend() {
    return this.filteredTcoMonthlyTrend;
  }

  configureTcoMonthlyTrend(type) {
    let filteredData = [];
    if(type == 'leader') {
      filteredData = [];
      let tcoData = this.tcoMonthlyTrend;
      if (tcoData) {
        for (let [key, value] of Object.entries(tcoData)) {
          let valueArray: any = value;
          let costObj = {};
          costObj['group'] = key;
          valueArray.forEach(element => {
            Object.keys(element).map(objKey => {
              if (objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                costObj[`${objKey}`] = 0;
              }
            });
          });
          valueArray.forEach(element => {
            Object.keys(element).map(objKey => {
              if (objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                costObj[`${objKey}`] += element[objKey];
              }
            });
          });
          filteredData.push(costObj);
        }
        this.setFilteredTcoMonthlyTrend(filteredData);
      }
    }
    else {
      this.paduValues.subscribe(data => {
        filteredData = [];
        let tcoData = this.tcoMonthlyTrend;
        if (tcoData) {
          for (let [key, value] of Object.entries(tcoData)) {
            let valueArray: any = value;
            let costObj = {};
            costObj['group'] = key;
            valueArray.forEach(element => {
              if (data.includes(element['Agg_PADU'])) {
                Object.keys(element).map(objKey => {
                  if (objKey != 'Agg_PADU' && objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                    costObj[`${objKey}`] = 0;
                  }
                });
              }
            });
            valueArray.forEach(element => {
              if (data.includes(element['Agg_PADU'])) {
                Object.keys(element).map(objKey => {
                  if (objKey != 'Agg_PADU' && objKey != 'group' && objKey != 'cost_category' && objKey != 'Month') {
                    costObj[`${objKey}`] += element[objKey];
                  }
                });
              }
            });
            filteredData.push(costObj);
          }
          this.setFilteredTcoMonthlyTrend(filteredData);
        }
      });
    }
    return filteredData;
  }
  setFilteredCategoryDetails(data) {
    this.filteredCategoryDetails.next(data);
  }

  getFilteredCategoryDetails() {
    return this.filteredCategoryDetails;
  }

  configureCategoryDetails(type) {
    let filteredData = {};
    if(type == 'leader') {
        filteredData = [];
        let categoryDetailsApp = {
          Category: 'App/App-Suite',
          Applications: 0,
          TCO_FY: 0
        };
        let categoryDetailsInfra = {
          Category: 'Infra',
          Applications: 0,
          TCO_FY: 0
        };
        let categoryDetailsProdcution = {
          Category: 'Production',
          Applications: 0,
          TCO_FY: 0
        };
        let categoryDetailsInDev = {
          Category: 'In Development',
          Applications: 0,
          TCO_FY: 0
        };
        let appDetails = this.categoryDetails['appDetails'];
        if (appDetails) {
          Object.keys(appDetails).map(paduKey => {
              let paduObj = appDetails[paduKey];
              paduObj.forEach(catData => {
                if (catData['Category'] == 'Application' || catData['Category'] == 'Application Suite') {
                  categoryDetailsApp['Applications'] += catData['Applications'] || 0;
                  categoryDetailsApp['TCO_FY'] += catData['TCO_FY'] || 0;
                }
                if (catData['Category'] == 'Infrastructure') {
                  categoryDetailsInfra['Applications'] += catData['Applications'] || 0;
                  categoryDetailsInfra['TCO_FY'] += catData['TCO_FY'] || 0;
                }
                if (catData['App_Lifecycle_Status'] == 'Production') {
                  categoryDetailsProdcution['Applications'] += catData['Applications'] || 0;
                  categoryDetailsProdcution['TCO_FY'] += catData['TCO_FY'] || 0;
                }
                if (catData['App_Lifecycle_Status'] == 'In Development') {
                  categoryDetailsInDev['Applications'] += catData['Applications'] || 0;
                  categoryDetailsInDev['TCO_FY'] += catData['TCO_FY'] || 0;
                }
              });
          });
        }

        filteredData['categoryDetailsApp'] = categoryDetailsApp;
        filteredData['categoryDetailsInfra'] = categoryDetailsInfra;
        filteredData['categoryDetailsProdcution'] = categoryDetailsProdcution;
        filteredData['categoryDetailsInDev'] = categoryDetailsInDev;
        this.setFilteredCategoryDetails(filteredData);
    }
    else {
      this.paduValues.subscribe(data => {
        filteredData = [];
        let categoryDetailsApp = {
          Category: 'App/App-Suite',
          Applications: 0,
          TCO_FY: 0
        };
        let categoryDetailsInfra = {
          Category: 'Infra',
          Applications: 0,
          TCO_FY: 0
        };
        let categoryDetailsProdcution = {
          Category: 'Production',
          Applications: 0,
          TCO_FY: 0
        };
        let categoryDetailsInDev = {
          Category: 'In Development',
          Applications: 0,
          TCO_FY: 0
        };
        let appDetails = this.categoryDetails['appDetails'];
        if (appDetails) {
          Object.keys(appDetails).map(paduKey => {
            if (data.includes(paduKey)) {
              let paduObj = appDetails[paduKey];
              paduObj.forEach(catData => {
                if (catData['Category'] == 'Application' || catData['Category'] == 'Application Suite') {
                  categoryDetailsApp['Applications'] += catData['Applications'] || 0;
                  categoryDetailsApp['TCO_FY'] += catData['TCO_FY'] || 0;
                }
                if (catData['Category'] == 'Infrastructure') {
                  categoryDetailsInfra['Applications'] += catData['Applications'] || 0;
                  categoryDetailsInfra['TCO_FY'] += catData['TCO_FY'] || 0;
                }
                if (catData['App_Lifecycle_Status'] == 'Production') {
                  categoryDetailsProdcution['Applications'] += catData['Applications'] || 0;
                  categoryDetailsProdcution['TCO_FY'] += catData['TCO_FY'] || 0;
                }
                if (catData['App_Lifecycle_Status'] == 'In Development') {
                  categoryDetailsInDev['Applications'] += catData['Applications'] || 0;
                  categoryDetailsInDev['TCO_FY'] += catData['TCO_FY'] || 0;
                }
              });
            }
          });
        }

        filteredData['categoryDetailsApp'] = categoryDetailsApp;
        filteredData['categoryDetailsInfra'] = categoryDetailsInfra;
        filteredData['categoryDetailsProdcution'] = categoryDetailsProdcution;
        filteredData['categoryDetailsInDev'] = categoryDetailsInDev;
        this.setFilteredCategoryDetails(filteredData);
      });
    }
    return filteredData;
  }

  exporttoexcel(type, cond) {
    const url = this.apiUrl + this.configV4.routerPath.getExportToExcelBlob;
    return this.http.post<any>(url, cond, { responseType: 'blob' as 'json', params: { type } });
    // { params: { type } }
  }
  exporttoexcelinfra(body, flag): Observable<any> {
    let url;
    if (flag == 1) {
      url = this.apiUrl + this.configV4.routerPath.getExportToExcelBlobinfra;
    } else {
      url = this.apiUrl + this.configV4.routerPath.getExportToExcelBlobinfraService;
    }
    return this.http.post<any>(url, body, { responseType: 'blob' as 'json' });
  }
  exporttoexceltco(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getExportToExcelBlobtco;
    return this.http.post<any>(url, body, { responseType: 'blob' as 'json' });
  }

  configurePaduCostData(type) {
    let paduCostData = [];
    if(type == 'leader') {
        paduCostData = [];
        this.allPaduSummaryData.forEach(element => {
            let paduObj = {
              Agg_PADU: element['Agg_PADU'],
              Dev_Labor_FY: element['Dev_Labor_FY'] || 0,
              ISA_FY: element['ISA_FY'] || 0,
              Infra_Charges_FY: element['Infra_Charges_FY'] || 0,
              OM_Labor_FY: element['OM_Labor_FY'] || 0
            };
            paduCostData.push(paduObj);
        });
        this.setFilteredPaduCostData(paduCostData);
    }
    else {
      this.paduValues.subscribe(data => {
        paduCostData = [];
        this.allPaduSummaryData.forEach(element => {
          if (data.includes(element['Agg_PADU'])) {
            let paduObj = {
              Agg_PADU: element['Agg_PADU'],
              Dev_Labor_FY: element['Dev_Labor_FY'] || 0,
              ISA_FY: element['ISA_FY'] || 0,
              Infra_Charges_FY: element['Infra_Charges_FY'] || 0,
              OM_Labor_FY: element['OM_Labor_FY'] || 0
            };
            paduCostData.push(paduObj);
          }
        });
        this.setFilteredPaduCostData(paduCostData);
      });
    }
  }

  setFilteredPaduCostData(data) {
    this.filteredPaduCostData.next(data);
  }

  getFilteredPaduCostData() {
    return this.filteredPaduCostData;
  }
  setDecomData(data) {
    this.decomData.next(data);
  }

  exporttoexceltcm(body, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getExportToExceltcm;
    return this.http.post<any>(url, body, { responseType: 'blob' as 'json', params: { type } });
  }
  public getServiceInfraTableData(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getServiceInfraTableData;
    return this.http.post<any>(url, body);
  }
  public getcapsdata(body, type, level): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getcapsdataservice;
    return this.http.post<any>(url, body, { params: { type, level } });
  }
  public getcapsdataapss(body, type, level): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getcapsdataapss;
    return this.http.post<any>(url, body, { params: { type, level } });
  }
  public getPaduMatrics(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getPaduMatrics;
    return this.http.post<any>(url, body);
  }
  public getLeadersList(searchLeader): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getLeadersList + `?search=${searchLeader}`;
    return this.http.get<any>(url);
  }
  public getLeaderHierarchy(body): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getLeaderHierarchy;
    return this.http.post<any>(url, body);
  }
}
