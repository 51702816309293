import { Component, Input, forwardRef, HostBinding, Optional, AfterViewChecked } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
  ControlContainer,
  FormGroup,
  NgForm
} from '@angular/forms';

@Component({
  selector: 'app-checkboxgroup',
  templateUrl: './techops-checkboxgroup.component.html',
  styleUrls: ['./techops-checkboxgroup.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechopsCheckboxGroupComponent),
      multi: true
    }
  ]
})
export class TechopsCheckboxGroupComponent implements ControlValueAccessor, AfterViewChecked {
  @HostBinding('attr.id')
  externalId = '';

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  private _ID = '';

  @Input() _value = '';
  @Input() list: any = [];
  @Input() isRequired: boolean = false;
  @Input() disabled: boolean;
  @Input() debugg = false;
  @Input() name = 'input';
  @Input() formref: NgForm;
  @Input() label = '';
  @Input() selectedvalue: any;
  @Input()
  public controlName: string;
  public formcontrol: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor(@Optional() private controlContainer: ControlContainer) {}

  ngAfterViewChecked() {
    setTimeout(() => {
      if (this.formref.controls[this.name]) {
        if (this.isRequired && this.selectedvalue === undefined) {
          this.formref.controls[this.name].setErrors({ required: true });
        } else {
          this.formref.controls[this.name].setErrors(null);
        }
      }
    });
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  updateValue(event) {
    this.selectedvalue = event.value;
    this.value = event.value;
    this.formcontrol = this.form.controls[this.name];
    ///this.form.get(this.name).setValue(this.selectedvalue);
    
  }
  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }
}
