import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppHubService } from 'src/app/techopsv4/application-hub/app-hub.service';
import { PortfolioHubService } from 'src/app/techopsv4/services/portfolioHubV4Service';
import { environment } from 'src/environments/environment';
import { OptimizeService } from '../../optimize.service';
import { SharedService } from '../../services/shared.service';
import { SharedAuthService } from '../../shared.auth.service';
import { MaintenanceService } from '../../../techopsv4/services/maintenanceService';
@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {
  @Input() path;
  @Input() appName;
  public userNameFullDetails;
  public userNameInitials;
  hrefShareFeedback: string;
  public selectedRoute: string;
  isAdminRole: boolean = environment.isAdminRole;
  @ViewChild('notificationModal', { static: false }) notificationModal;
  @ViewChild('urgentActionReqModal', { static: false }) urgentActionReqModal;
  acccess = {
    hasAppEditorSecureGrpAccess: false,
    hasModelEditorSecureGrpAccess: false,
    hasAdminSecureGrpAccess: false
  }
  optimizationAccess: boolean;
  workQueuesRoles = [];
  formattedRole: string = '';
  showLoader: boolean;
  isFundingOwnerAdmin: boolean = false;
  fundingOwnerAccess: boolean;
  enterpriseInitiativesList: any = [];
  initiativeSubscription: any;
  notificationSubscription: any;
  notifications: any = [];
  refreshState: any;
  oldBucketDetails: any = [];
  showNotificationDetailPopup: boolean = false;
  notificationList: any = [];

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private optimize: OptimizeService,
    private portfolioHubService: PortfolioHubService,
    public sharedAuthService: SharedAuthService,
    private appHubService: AppHubService,
    private maintenanceService: MaintenanceService
  ) {

    sessionStorage.setItem("UrgentNotificationShown", 'false');
    route.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationStart;
        })
      )
    this.route.events.subscribe((event: NavigationStart) => {
      if (event instanceof NavigationStart) {
        if (event['url'] && event['url'] == '/landing-page') {
          this.selectedRoute = 'landing-page';
        } else {
          this.checkCurrentPath(event['url']);
        }
        const msId = localStorage.getItem('MsId');
        this.userNameFullDetails = JSON.parse(localStorage.getItem('currentUser'));
        this.hrefShareFeedback = `mailto:techops_support@ds.uhc.com?subject=${this.userNameFullDetails.replace(/^"(.*)"$/, '$1')} (${msId}) has shared a Feedback!`;
        this.createInitials(this.userNameFullDetails);
      }
    });
    this.initiativeSubscription = this.maintenanceService.enabledInitiativeList.subscribe((data) => {
      this.enterpriseInitiativesList = data;
    });


  }
  ngOnDestroy() {

    if (this.initiativeSubscription) {
      this.initiativeSubscription.unsubscribe();
    }

  }
  createInitials(userNameFullDetails) {
    let userName = userNameFullDetails.replace(/,/g, '');
    let initialsArray = userName.match(/\b(\w)/g);
    let slicedArray = initialsArray.length > 1 ? initialsArray.slice(0, 2) : initialsArray;
    this.userNameInitials = slicedArray.join('');
  }

  async ngOnInit(): Promise<void> {
    this.sharedAuthService.notificationSource.subscribe(data => {
      if (data) {
        this.getNotification();
        this.getNotificationList();
      }
    });
    this.userNameFullDetails = JSON.parse(localStorage.getItem('currentUser'));
    this.createInitials(this.userNameFullDetails);
    await this.hasEditProductAccess();
    this.checkFlagAccess();
    this.checkCurrentPath();
    this.maintenanceService.setEnabledInitiativesData();

  }
  ngAfterViewInit() {
  }

  checkCurrentPath(url?: string) {
    let currentPath = url ? url : window.location.pathname;
    if (currentPath.includes('product-home')) {
      this.selectedRoute = 'capModel';
    } else if (currentPath.includes('optimization')) {
      this.selectedRoute = 'maintenance';
    } else if (currentPath.includes('maintenance')) {
      this.selectedRoute = 'maintenance';
    } else if (currentPath.includes('application-hub')) {
      this.selectedRoute = 'application-hub'
    } else if (currentPath.includes('reports')) {
      this.selectedRoute = 'reports'
    } else if (currentPath.includes('portfolio-hub')) {
      this.selectedRoute = 'portfolio-hub'
    } else if (currentPath.includes('work-queues')) {
      this.selectedRoute = 'work-queues';
    }
  }

  async hasEditProductAccess() {
    this.showLoader = true;
    const msId = localStorage.getItem('MsId');
    const secureGroups = JSON.parse(localStorage.getItem('secureGroups'));
    msId && await this.sharedService.getAccessData(msId, { 'secureGroups': secureGroups }).subscribe(accessData => {
      const {
        isModelEditor = false,
        isWriteAccess = false,
        isAdminRole = false,
        isFundingOwnerAdmin = false,
        fundingAccess,
        data,
        AppEditor_AllRoles = {},
        AllRoles = {},
        workQueueAccessDetails = {},
      } = accessData || {};
      localStorage.setItem('AppEditor_AllRoles', JSON.stringify(AppEditor_AllRoles));
      localStorage.setItem('AllRoles', JSON.stringify(AllRoles));
      localStorage.setItem('accessData', JSON.stringify(data));
      this.formattedRole = this.getAuthorizationForEA(isAdminRole, isWriteAccess, data);
      this.checkWorkQueueAccess(accessData);
      localStorage.setItem('isModelEditor', isModelEditor);
      localStorage.setItem('isAdminRole', isAdminRole);
      localStorage.setItem('isWriteAccess', isWriteAccess);
      localStorage.setItem('isFundingOwnerAdmin', isFundingOwnerAdmin);
      localStorage.setItem('fundingAccess', JSON.stringify(fundingAccess));
      localStorage.setItem('workQueueAccessDetails', JSON.stringify(workQueueAccessDetails));
      // localStorage.setItem('tiles', JSON.stringify(tiles));
      this.acccess.hasAdminSecureGrpAccess = isAdminRole;
      this.acccess.hasAppEditorSecureGrpAccess = isWriteAccess;
      this.acccess.hasModelEditorSecureGrpAccess = isModelEditor;
      this.fundingOwnerAccess = (accessData?.fundingAccess?.length > 0 && isFundingOwnerAdmin) ? true : false;

      if (isAdminRole) {
        this.isAdminRole = true;
      }
      this.acccess.hasAdminSecureGrpAccess = this.isAdminRole;
      this.showLoader = false;
      this.getNotification();
      this.getNotificationList();
    }, error => {
      console.error(error);
      this.showLoader = false;
    });
    // this.sharedService.getAccessData(msId).subscribe(accessData => {
    //   const { data = {}} = accessData || {};
    //   localStorage.setItem('accessData', JSON.stringify(data));
    //   this.checkWorkQueueAccess(accessData);
    // });


  }

  getAuthorizationForEA(isAdminRole, isWriteAccess, userAccessData) {
    if (userAccessData != null) {
      if (userAccessData["Role"] === 'Admin' && isAdminRole === true) {
        return 'Admin';
      }
      else if (isWriteAccess && ['Chief Architect', 'Architect Delegate'].includes(userAccessData["Role"])) {
        return (userAccessData["Role"] === 'Chief Architect') ? 'CA' : 'AD';
      }
    }
    else {
      return '';
    }

  }
  showNotificationPopup() {
    this.showNotificationDetailPopup = true;
    this.notificationModal.show();
  }
  closeNotificationModal() {
    this.showNotificationDetailPopup = false;
    this.notificationModal.hide();
  }

  showUregentActionPopup() {
    let currentPath = window.location.href;
    let flag = sessionStorage.getItem("UrgentNotificationShown");
    if ((flag == null || flag != 'true') && !currentPath.includes('wqOpen') && !currentPath.includes('admin-dashboard')) {
      sessionStorage.setItem("UrgentNotificationShown", 'true');
      this.urgentActionReqModal.show();
    }

  }

  checkWorkQueueAccess(accessData) {
    accessData && accessData['workQueueAccessDetails'].forEach(role => {
      this.workQueuesRoles.push(role['V_Role']);
    });
  }
  getNotification() {
    this.showLoader = true;
    const msId = localStorage.getItem('MsId');
    this.sharedService.getNotification(msId).subscribe((response) => {
      this.showLoader = false;
      this.notifications = response?.result?.notificationMaster ? response?.result?.notificationMaster : [];
      if (this.notifications.length > 0) {
        let WQNotification = this.notifications.filter(ele => ele.Content.toLowerCase() == "workqueues")
        this.checkForOlderBucket(WQNotification);
      }
    }, (error) => {
      this.showLoader = false;
    })
  }

  getNotificationList() {
    this.showLoader = true;
    const msId = localStorage.getItem('MsId');
    this.sharedService.getNotificationList(msId).subscribe((response) => {
      this.showLoader = false;
      this.notificationList = response?.result?.notificationMaster;
    }, (error) => {
      this.showLoader = false;
    })

  }

  checkForOlderBucket(wqNotification) {
    if (wqNotification.length > 0) {
      this.oldBucketDetails = wqNotification[0].notifications.filter(element => element.oldBucket == true);
      if (this.oldBucketDetails.length > 0)
        this.showUregentActionPopup();
    }
  }

  checkFlagAccess() {
    let body = {
      MSID: localStorage.getItem('MsId'),
      Page_Name: 'Optimization'
    };
    this.optimize.getAccessFlag(body).subscribe(
      res => {
        if (res) {
          if (res == 1) {
            this.optimizationAccess = true;
          } else {
            this.optimizationAccess = false;
          }
        }
      },
      err => {
        console.error(err, 'erreo...');
        this.optimizationAccess = false;
      }
    );
  }

  redirectView(value) {
    this.selectedRoute = value;
    switch (value) {
      // case 'optimization':
      //   this.selectedRoute = 'maintenance';
      //   this.route.navigate([{ outlets: { primary: 'optimization' } }]);
      //   break;
      case 'work-queues':
        this.selectedRoute = 'work-queues';
        this.route.navigate([{ outlets: { primary: 'work-queues' } }]);
        // this.route.navigateByUrl('/work-queues?page=my-request');
        break;
      case 'application-hub':
        this.appHubService.applicationHubFromKnowYourFinances = false;
        this.route.navigate([{ outlets: { primary: 'application-hub' } }]);
        break;
      case 'portfolio-hub':
        this.portfolioHubService.porfolioSelectedFromFinance = false;
        this.route.navigate([{ outlets: { primary: 'portfolio-hub' } }]);
        break;
      case 'reports':
        this.route.navigate(['/reports']);
        break;
      case 'optCapModel':
        this.selectedRoute = 'capModel';
        this.route.navigate([{ outlets: { primary: 'product-home' } }], {
          queryParams: { q: 'Optum Capability Model' }
        });
        break;
      case 'techCapModel':
        this.selectedRoute = 'capModel';
        this.route.navigate([{ outlets: { primary: 'product-home' } }], {
          queryParams: { q: 'Technology Capability Model' }
        });
        break;
      case 'prodCapModel':
        this.selectedRoute = 'capModel';
        this.route.navigate([{ outlets: { primary: 'product-home' } }], {
          queryParams: { q: 'UHC Product Capability Model' }
        });
        break;
    }
  }

  redirectToHome() {
    this.selectedRoute = 'landing-page';
    this.route.navigate([{ outlets: { primary: 'landing-page' } }]);
  }

  redirectToRelease() {
    this.selectedRoute = 'release-note';
    this.route.navigate([{ outlets: { primary: 'release-note' } }]);
  }

  navigateToBulkMaintenance() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenance/bulk' } }]);
  }

  navigateToGroupMaintenance() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenance/group' } }]);
  }

  redirectToUserManual() {
    window.open(environment.docUrl + "user-guide/overview/");
  }

  navigateToLoborOrgMaintenance() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenanceV4/lob' } }]);
  }

  navigateToOptimization() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenanceV4/optimization' } }]);
  }

  navigateToFundingOwner() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenanceV4/Capital-Demand' } }]);
  }
  navigateToForecastManagement() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenanceV4/forecast-management' } }]);
  }

  navigateToInitiatives() {
    this.selectedRoute = 'maintenance';
    this.route.navigate([{ outlets: { primary: 'maintenanceV4/initiatives' } }]);
  }

}
