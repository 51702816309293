<app-header2 *ngIf="successSSOLogin" [path]="currentPath" [appName]="title3"> </app-header2>
<div class="main-container mainContainer-Padding">
  <app-loader *ngIf="appLoader"></app-loader>
  <!-- <app-header *ngIf="successSSOLogin" [path]="currentPath" [appName]="title3"></app-header> -->
  
  <ng-container *ngIf="successSSOLogin">
    <router-outlet name="sidebar"></router-outlet>
    <div
      [ngClass]="{'add-page-background': isPathApplicationInsights,
                'add-no-background': !isPathApplicationInsights,
                'container-no-padding' : currentUrl === '/landing-page' }"
      class="container container-backgroundcolor">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</div>
<!-- <app-uhc-footer *ngIf="currentPath === 'productMappingView(sidebar:padu)' "></app-uhc-footer> -->
<app-footer2 *ngIf="successSSOLogin" [path]="currentPath"></app-footer2>
<span id="base-timer-label" class="base-timer__label"> </span>
<div class="modal-container">
  <div id="SessionModal" class="SessionModal modal">
    <div class="modal-content">
      <div>
        <h6 style="padding:5px;">
          Your session <span id="countdown"> </span>, Please click on Continue to stay Signed In.
        </h6>
      </div>
      <div style="display: flex;justify-content: space-evenly;">
        <!-- <div ><h3>Time Left:</span></h3></div> -->
        <button
          mat-mdc-raised-button 
          class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
          (click)="continue()"
          style="margin-top: 10px;"
        >
          Continue
        </button>
        <!-- <button mat-mdc-raised-button 
        class="searchbuttonClass uitk-c-button  uitk-u-margin-right--base uitk-u-margin-bottom--xs"
        (click)='logout()' style='margin-top: 10px;'>
        Logout
      </button> -->
      </div>
    </div>
  </div>
  <div id="DataChangeModal" class="DataChangeModal modal">
    <div class="modal-content">
      <div>
        <h6 style="padding:5px;">
            The information you're looking at is outdated. Please click Refresh to reload the page.
        </h6>
      </div>
      <div style="display: flex;justify-content: space-evenly;">
        <!-- <div ><h3>Time Left:</span></h3></div> -->
        <button
          mat-mdc-raised-button 
          class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
          (click)="refesh()"
          style="margin-top: 10px;"
        >
          Refresh
        </button>
        <!-- <button mat-mdc-raised-button 
        class="searchbuttonClass uitk-c-button  uitk-u-margin-right--base uitk-u-margin-bottom--xs"
        (click)='logout()' style='margin-top: 10px;'>
        Logout
      </button> -->
      </div>
    </div>
  </div>
</div>
