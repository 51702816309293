import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnChanges,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import * as d3 from 'd3';
import { DecimalPipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
// import { data3 } from 'dist/tcmhubv2/assets/json/app-view-mockData';

@Component({
  selector: 'app-line-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './line-chart.component.html',
  styleUrls: ['line-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent implements OnInit, OnChanges, OnDestroy {
  public margin: any;
  public yAxis: any;
  public xAxis: any;
  public xScale: any;
  public svg: any;
  public barHeight: any;
  public y0: any;
  public trenddata: any;
  public serviceName: any;
  parsemonth;
  dataIndex;
  predict;
  @Input() trend = false;
  numberFormatted: any;
  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;
  @Input() ServName: any;
  CurrentYear= new Date().getFullYear();
  constructor(private container: ElementRef, private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    // Draw the chart
  }
  ngOnChanges() {
    this.eventsSubscription = this.events.subscribe(data => {
      //{'serviceName':serviceName, 'trenddata':this.linechartsorteddata}
      this.serviceName = data['serviceName'];
      this.trenddata = data['trenddata'];
      this.linechart(this.trenddata, this.container);
      this.eventsSubscription.unsubscribe();
    });
  }
  linechart(data, elem) {
    // let forecast = [
    //   { date: '2018-04-01' },
    //   { date: '2018-05-01' },
    //   { date: '2018-06-01' },
    //   { date: '2018-07-01' },
    //   { date: '2018-08-01' },
    //   { date: '2018-09-01' },
    // ];

    const parseTime = d3.timeParse('%Y-%m-%d');
    this.parsemonth = d3.timeParse('%B');

    data = data.map(d => {
      return {
        date: parseTime(d.date),
        volume: d.volume
      };
    });

     this.predict = (data, newX) => {
      const round = n => Math.round(n * 100) / 100;

      const sum = data.reduce(
        (acc, pair) => {
          const x = pair[0];
          const y = pair[1];

          if (y !== null) {
            acc.x += x;
            acc.y += y;
            acc.squareX += x * x;
            acc.product += x * y;
            acc.len += 1;
          }

          return acc;
        },
        { x: 0, y: 0, squareX: 0, product: 0, len: 0 }
      );

      const rise = sum.len * sum.product - sum.x * sum.y;
      const run = sum.len * sum.squareX - sum.x * sum.x;
      const gradient = run === 0 ? 0 : round(rise / run);
      const intercept = round(sum.y / sum.len - (gradient * sum.x) / sum.len);

      return round(gradient * newX + intercept);
    };

     this.dataIndex = data.map((d, i) => [i, d.volume]);

    // forecast = forecast.map((d, i) => {
    //   return {
    //     date: parseTime(d.date),
    //     volume: predict(dataIndex, dataIndex.length - 1 + i),
    //   }
    // });

    // forecast.unshift(data[data.length - 1]);

    const chart = d3.select(elem.nativeElement).select('.chart');
    const margin = { top: 45, right: 5, bottom: 5, left: 60 };
    const width = 700 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;
    const innerChart = chart.append('g').attr('transform', `translate(${margin.left} ${margin.top})`);

    const x = d3.scaleTime().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([height, 0]);

    const line = d3
      .line()
      .x(d => x(d.date))
      .y(d => y(d.volume))
      .curve(d3.curveMonotoneX); // apply smoothing to the line;

    x.domain([d3.min(data, d => d.date), d3.max(data, d => d.date)]);
    y.domain([0, d3.max(data, d => d.volume)]);

    innerChart
      .append('g')
      .attr('transform', `translate(0 ${height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(5)
          .tickFormat(d3.timeFormat('%b'))
      );

    innerChart
      .append('g')
      .call(d3.axisLeft(y).tickFormat(d => '$' + d))
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', '6')
      .attr('dy', '0.71em');

    innerChart
      .append('path')
      .data([data])
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 3)
      .attr('d', function(d) {
        return line(d);
      });

    innerChart
      .selectAll('.dot')
      .data(data)
      .enter()
      .append('circle') // Uses the enter().append() method
      .attr('class', 'dot') // Assign a class for styling
      .attr('cx', d => {
        return x(d.date);
      })
      .attr('cy', d => {
        return y(d.volume);
      })
      .attr('r', 5)
      .append('svg:title') // TITLE APPENDED HERE
      .text(d => {
        return d.volume;
      });

    innerChart
      .selectAll('.text')
      .data([data])
      .enter()
      .append('text') // Uses the enter().append() method
      .attr('class', 'label') // Assign a class for styling
      .attr('x', (d) => {
        return x(d.date);
      })
      .attr('y', d => {
        return y(d.volume);
      })
      .attr('dy', '-5');

    // innerChart.append('path')
    //   .datum(forecast)
    //   .attr('fill', 'none')
    //   .attr('stroke', 'tomato')
    //   .attr('stroke-dasharray', '10,7')
    //   .attr('stroke-width', 1.5)
    //   .attr('d', line);
  }
  hidepopup() {
    const ele = this.container.nativeElement.querySelector('.hover_bkgr_fricc') as HTMLInputElement;
    ele.style.display = 'none';
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}