import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { tcmHubConfigDetails } from '../../../config';


@Component({
  selector: 'app-stackchart',
  templateUrl: './stackchart.component.html',
  styleUrls: ['./stackchart.component.css']
})
export class StackchartComponent implements OnInit {
  public CurrentYear = tcmHubConfigDetails.CurrentYear;
  devom = 0;
   bar;
  @Input() data = [{
    group: 'Jan',
    'om': '12',
    'dev': '1',
  },
  {
    group: 'Feb',
    'om': '6',
    'dev': '6',
  },
  {
    group: 'Mar',
    'om': '11',
    'dev': '28',
  },
  {
    group: 'Apr',
    'om': '19',
    'dev': '6',
  },
  {
    group: 'May',
    'om': '12',
    'dev': '1',
  },
  {
    group: 'Jun',
    'om': '6',
    'dev': '6',
  },
  {
    group: 'Jul',
    'om': '11',
    'dev': '28',
  },
  {
    group: 'Aug',
    'om': '12',
    'dev': '1',
  },
  {
    group: 'Sep',
    'om': '6',
    'dev': '6',
  },
  {
    group: 'Oct',
    'om': '11',
    'dev': '28',
  },
  {
    group: 'Nov',
    'om': '12',
    'dev': '1',
  },
  {
    group: 'Dec',
    'om': '6',
    'dev': '6',
  }
  ];
  // List of subgroups = header of the csv files = soil condition here
  //const subgroups = ['om', 'dev', '2012']
  @Input() subgroups = ['om', 'dev']
  // List of groups = species here = value of the first column called group -> I show them on the X axis
  groups = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  svg;
  color;
  x;
  y;
  tooltip;
  @Input() label = 'Labor';
  type = 'om';
  @Input() name: any;
  total: any = 0;

  constructor(private container: ElementRef, private http: HttpClient, private decimalPipe: DecimalPipe) { }
  /**
 * @author: Anjit
 * @description: format the number as per notation
 * @augments: number
 * @returns: number with notation
 */
  decimalFormatter(num, digit) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1E3, symbol: 'K' },
      { value: 1E6, symbol: 'M' },
      { value: 1E9, symbol: 'B' }
    ];

    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digit) + si[i].symbol;
  }

  ngOnInit(): void {

  }
  ngOnChanges() {
    if (this.data) {
      this.total = 0;
      d3.select(this.container.nativeElement).selectAll(".my_dataviz2 svg g").remove();
      this.createchart(this.data);
    }
  }
  ngAfterViewInit() {
    this.textbind();
  }
  /**
   * @author: Anjit
   * @description: create bar chart with 3 year trend
   * @augments: 3 year data array
   * @returns: svg chart
   */
  createchart(data) {
    let groupmax = [];
    this.subgroups.forEach(element => {
      this.total = this.sum(element, 'false') + this.total;
    });
    this.devom = this.total;
    this.total = this.decimalFormatter(this.total, 1);
    this.data.forEach(element => {
      groupmax.push(element.om + element.dev)
    });
    // function chartinit() {
    // set the dimensions and margins of the graph
    const margin = {
      top: 10,
      right: 30,
      bottom: 20,
      left: 50
    },
      width = 960 - margin.left - margin.right,
      height = 350 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    this.svg = d3.select(this.container.nativeElement).select(".my_dataviz2 svg")
      .append("g")
      .attr('class', "rotate-translate")
      .attr("transform", `translate(${margin.left},${margin.top})`);


    let stackedData = d3.stack()
      .keys(this.subgroups)(data)

    // Add X axis
    this.x = d3.scaleBand()
      .domain(this.groups)
      .range([0, width])
      .padding([0.1])
    this.svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(this.x).tickSize(0));

    // Add Y axis
    this.y = d3.scaleLinear()
      .domain([0, d3.max(groupmax)])
      .range([height, margin.top]);

    this.svg.append("g")
      .call(d3.axisLeft(this.y).tickFormat((d) => '$' + (this.decimalFormatter(d, 1))));


    // color palette = one color per subgroup
    this.color = d3.scaleOrdinal()
      .domain(this.subgroups)
      .range(['#075786', '#9fc5d9'])


    // ----------------
    // Create a tooltip
    // ----------------
    // this.tooltip = d3.select("#my_dataviz")
    //   .append("div")
    //   .style("opacity", 0)
    //   .attr("class", "tooltip")
    //   .style("background-color", "white")
    //   .style("border", "solid")
    //   .style("border-width", "1px")
    //   .style("border-radius", "5px")
    //   .style("padding", "10px")



    this.bar = this.svg.append("g")
      .attr('class', 'chart-container')
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter().append("g")
      .attr("fill", (d) => {
        return this.color(d.key);
      })
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function (d) {
        return d;
      })
      .enter().append("rect")
      .attr('class', 'stack-bar')
      .attr("x", (d) => {
        return this.x(d.data.group);
      })
      .attr("y", ([y1, y2]) => Math.min(this.y(y1), this.y(y2)))
      .attr("height", ([y1, y2]) => Math.abs(this.y(y1) - this.y(y2)))
      .attr("width", this.x.bandwidth())
      .attr("stroke", "grey").append("text")
      .attr("x", (d) => {
        return this.x(d.data.group) + 10;
      })
      .attr("y", (d) => {
        return this.y(d[1]);
      }).attr('fill', '#fff')
      .attr('font-family', 'sans-serif')
      .attr('font-size', '1vw')
      .attr('font-weight', 'bold')
      .attr("dy", "1em")
      .text(function (d) {
        return d[1];
      });


    d3.select(this.container.nativeElement).selectAll("g.tick")
      .append("line")
      .attr("class", "gridline")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width)
      .attr("y2", 0);
    //}

    this.textbind();

  }
  textbind() {
    let node = <any>this.container.nativeElement.querySelectorAll('.stack-bar');
    for (let e of node) {
      let text = parseFloat(e.firstChild.innerHTML);
      e.firstChild.innerHTML = this.decimalFormatter(text, 1);
      let html = e.innerHTML;
      if (text !== 0)
        e.insertAdjacentHTML("afterend", html);
      e.innerHTML = '';
    }
  }
  update(type) {
    let datum = this.data;
    let checkedvalues = [];
    let e = <any>this.container.nativeElement.querySelector('.omdevfilter .mat-slide-toggle-input');
    let key = [];
    if (type == 'fitler') {
      if (e.checked) {
        key = ['dev'];
        this.type = 'dev';
        //  this.container.nativeElement.querySelector('.slider').style.backgroundColor = '#075786';
        checkedvalues = {
          ...checkedvalues,
          [`${key}`]: 0
        }
      } else {
        key = ['om'];
        this.type = 'om';
        //  this.container.nativeElement.querySelector('.slider').style.backgroundColor = '#9fc5d9';
        checkedvalues = {
          ...checkedvalues,
          [`${key}`]: 0
        }
      }
    }
    if (type == 'reset') {
      e.checked = false;
      //  this.container.nativeElement.querySelector('.slider').style.backgroundColor = '#ddd';

    }
    datum = datum.map((p) => {
      return {
        ...p,
        ...checkedvalues
      }
    });
    let stackedData = d3.stack()
      .keys(this.subgroups)(datum)

    this.svg.selectAll(".chart-container g").remove();

    this.svg.select('.chart-container')
      .selectAll("g")
      .data(stackedData)
      .enter().append("g")
      .attr("fill", (d) => {
        return this.color(d.key);
      })
      .selectAll("rect")
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data(function (d) {
        return d;
      })
      .enter().append("rect")
      .attr('class', 'stack-bar')
      .attr("x", (d) => {
        return this.x(d.data.group);
      })
      .attr("y", (d) => {
        return this.y(d[1]);
      })
      .attr("height", (d) => {
        return this.y(d[0]) - this.y(d[1]);
      })
      .attr("width", this.x.bandwidth())
      .attr("stroke", "grey").append("text")
      .attr("x", (d) => {
        return this.x(d.data.group) + 10;
      })
      .attr("y", (d) => {
        return this.y(d[1]);
      }).attr('fill', '#fff')
      .attr('font-family', 'sans-serif')
      .attr('font-size', '1vw')
      .attr('font-weight', 'bold')
      .attr("dy", "1em")
      .text(function (d) {
        return d[1];
      });
    this.textbind();
  }
  sum(key, format = 'true') {
    if (this.data) {
      let total = 0;
      this.data.forEach(item => {
        total += item[key];
      });

      if (format == 'true')
        return this.decimalFormatter(total, 1);
      else
        return total;
    }
  }
}
