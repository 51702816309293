import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appModernizationDetails } from '../../config';
import { environment } from '../../../environments/environment';
import { map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BulkImportService {
  private apiUrl: string;
  private config: any;
  constructor(private http: HttpClient) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
  }


  public ImportFile(body, uploadType): Observable<any> {
    let url;
    if (uploadType === 'Capability_Mapping') {
      url = this.apiUrl + this.config.routerpath.businessSupportBulkImport;
    } 
    else if((uploadType === 'LOB_Update'))
    {
      url = this.apiUrl + this.config.routerpath.businessLobSublobBulkImportPreview;
    } else if((uploadType === 'Padu_Update')) {
      url = this.apiUrl + this.config.routerpath.paduRoadmapBulkImport;
    } else if((uploadType === 'Padu_Save')) {
      url = this.apiUrl + this.config.routerpath.paduRoadmapBulkSave;
    } 
    else if((uploadType === 'primary_Update'))
    {
      url = this.apiUrl + this.config.routerpath.primaryUploadBulkImport;
    }else if((uploadType === 'primary_Save'))
    {
      url = this.apiUrl + this.config.routerpath.primaryUploadBulkImportUpdate;
    }
    else if(uploadType==='Edit_Model'){
      url = this.apiUrl + this.config.routerpath.editModelBulkImport;
    }
    else if(uploadType==='LOB_Save'){
      url = this.apiUrl + this.config.routerpath.businessLobSublobBulkImport;
    } 
    else {
      url = this.apiUrl + this.config.routerpath.bulkImport;
    }
    
    let headers = new HttpHeaders({
      'Content-Type': ''
    });
    let options = {
      headers: headers
    };
    return this.http.post<any>(url, body, options).pipe(
      map(data => { return data; }));
  }
}
