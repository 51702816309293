import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { appModernizationDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  public addCapability = new Subject();
  private apiUrl: string;
  private config: any;
  orgList$ = new BehaviorSubject<any>({});
  constructor(private http: HttpClient,) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
  }

  setAddCapability(value) {
    this.addCapability.next(value);
  }


  /**
   * GetLookup's
   */
  public GetLookUpValues(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetLoBLookUp;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public GetLobArchitectMappingList(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.GetLoBArchitectMapping;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  public SaveLobMapping(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.SaveLobMapping;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  public UpdateLobMapping(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.UpdateLobMapping;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  /**
 * Delete Mappings
 */
  public DeleteMappings(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.DeleteLobMapping;
    let options: any = {};
    options['body'] = body;
    return this.http.delete(url, options).pipe(
      map(data => {
        return data;
      })
    );
  }

  public CreateLoB(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.Create_LoB;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  public CreateSubLoB(payload): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.Create_Sub_LoB;
    return this.http.post(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }
  
  public getOrganizationList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getOrgList;
    return this.http.get(url).pipe(
      map(data => {
        this.setOrgListSubjectValues(data);
        return data;
      })
    );
  }
  // public getLeaderList(ucmgId): Observable<any> {
  //    const url = this.apiUrl + this.config.routerpath.capitalDemandLeader;
  //   return this.http.post(url,ucmgId)
  //   .pipe(
  //     map(data => {
  //       this.setLeaderListSubjectValues(data);
  //       return data;
  //     })
  //   );
  // }
  getOrgListSubjectValues() {
    return this.orgList$;
  }

  setOrgListSubjectValues(data) {
    this.orgList$.next(data);
  }
  // getLeaderListSubjectValues() {
  //   return this.leaderList$;
  // }

  // setLeaderListSubjectValues(data) {
  //   this.leaderList$.next(data);
  // }
  public addNewOrganization(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getOrgList;
    return this.http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  // public addNewLeader(body): Observable<any> {
  //   const url = this.apiUrl + this.config.routerpath.addCapitalDemandLeader;
  //   return this.http.post(url, body).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }
  
 
  // public editUcmg(body,id): Observable<any> {
  //   const url = this.apiUrl + this.config.routerpath.addUcmg+`/${id}`;
  //   return this.http.put(url, body).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }
  public editExistingOrganization(id, body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getOrgList+`/${id}`;
    return this.http.put(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }
  // public editExistingLeader(body): Observable<any> {
  //   const url = this.apiUrl + this.config.routerpath.capitalDemandLeader;
  //   return this.http.put(url, body).pipe(
  //     map(data => {
  //       return data;
  //     })
  //   );
  // }

  public deleteOrganization(body, id): Observable<any> {
  const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.config.routerpath.getOrgList + `/${id}`;
    return this.http.delete<any>(url, options);
  }

  // public deleteCapitalDemand(id): Observable<any> {
    
  //     const url: string = this.apiUrl + this.config.routerpath.deleteDemand + `/${id}`;
  //     return this.http.delete<any>(url);
  //   }

  // public deleteUcmgLeader(params): Observable<any> {
  //   const options = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json'
  //       }),
  //       params
  //     }
  //     const url: string = this.apiUrl + this.config.routerpath.capitalDemandLeader;
  //     return this.http.delete<any>(url, options);
  //   }
}




