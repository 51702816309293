import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { tcmHubConfigDetails } from '../../config';
import { appModernizationDetails } from 'src/app/config';
import * as _ from 'lodash';
import { adminV4APIs } from '../../configV4';

@Injectable({
  providedIn: 'root'
})
export class AdminService {



  private apiUrl: string;
  private config: any;
  private confi: any;
  private configV4: any;
  constructor(private http: HttpClient) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
    this.confi = tcmHubConfigDetails;
    this.configV4 = adminV4APIs;
   }

  
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*'
    })
  };
  
  public getUserRolesService(MSId) : Observable<any> {
    const apiUrl = this.apiUrl + this.configV4.getAllUserRoles;
    return this.http.get(apiUrl, {params: {MSId}});
  }

  public updateUserRole(MSId, Role, body) : Observable<any> {
    const apiUrl = this.apiUrl + this.configV4.updateUserRole;
    return this.http.put(apiUrl, body, {params: {MSId, Role}});
  }

  public getneddrefresh(obj) : Observable<any>{
    const apiUrl = `${this.apiUrl}/${this.confi.routerpath.getneddrefresh_V1}`;
    return this.http.post(apiUrl, obj);
  }
  // public getneddrefresh(): Observable<any> {
  //   // const url = this.apiUrl + this.config.routerpath.;
  //   // return this.http.get(url).pipe(
  //   //   map(data => {
  //   //     return data;
  //   //   })
  //   // );
  //   console.log("Hiiii");
  //   const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.getneddrefresh;
  //   const appList = this.http.get(appListUrl).pipe(map(res => {
  //     return res;
  //   })
  //   );
  //   return appList;
  // }

  public getGLrefresh(obj){
    // const url = this.apiUrl + this.config.routerpath.;
    // return this.http.get(url).pipe(
    //   map(data => {
    //     return data;
    //   })
    // );
    // console.log("Hi");
    // const appListUrl: string = tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.getneddrefresh;
    // const appList = this.http.get(appListUrl).pipe(map(res => {
    //   return res;
    // })
    // );
    // return appList;
    const apiUr = `${this.apiUrl}/${this.config.routerpath.getGLrefresh_V1}`;
    return this.http.post(apiUr, obj);
  }
  
  public getnddrefersh(body)  {
    const url = `${this.apiUrl}/${this.config.routerpath.getnddrefresh}`;
    return this.http.post<any>(url,body);
  }
  
  getJobDetls(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getJobDetls;
    return this.http.get<any>(url);
  }

  getMonthlyJobDetls(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getMonthlyJobDetls;
    return this.http.get<any>(url);
  }
  adiDataRfrsh(body){
    const url = this.apiUrl + this.config.routerpath.adiDataRefresh;
    return this.http.post<any>(url,body);
  }
  epRefreshA(body){
    const url = this.apiUrl + this.config.routerpath.epRefresh;
    return this.http.get<any>(url,body);
  }
  techPlmRefreshA(body){
    const url = this.apiUrl + this.config.routerpath.techPlmRefresh;
    return this.http.get<any>(url,body);
  }
  yearPaduRefreshA(body){
    const url = this.apiUrl + this.config.routerpath.yearPaduRefresh;
    return this.http.get<any>(url,body);
  }
  techOpsFlagsA(body){
    const url = this.apiUrl + this.config.routerpath.techOpsFlag;
    return this.http.get<any>(url,body);
  }
  techLandscapeRefreshA(body){
    const url = this.apiUrl + this.config.routerpath.techLandscapeRefresh;
    return this.http.post<any>(url,body);
  }
  cdcReportRefreshA(body){
    const url = this.apiUrl + this.config.routerpath.cdcReportRefresh;
    return this.http.get<any>(url,body);
  }
  tech360RefreshA(body){
    const url = this.apiUrl + this.config.routerpath.tech360Refresh;
    return this.http.get<any>(url,body);
  }
  epDeltaRefreshA(body){
    const url = this.apiUrl + this.config.routerpath.epDeltaRefresh;
    return this.http.get<any>(url,body);
  }
  getDropDownText(id, object){
    const selObj = _.filter(object, function (o) {
        return (_.includes(id,o.id));
    });
    return selObj;
  }

  getAccess() {
    let pageType = 'admin-dashboard';
    const url = this.apiUrl + this.config.routerpath.adminAccess + '/' + pageType;
    return this.http.get<any>(url);
  }
  getUserRoleChangeAccess() {
    let pageType = 'User_Role_Change';
    const url = this.apiUrl + this.config.routerpath.adminAccess + '/' + pageType;
    return this.http.get<any>(url);
  }

  getReportManagementAccess(pageType) {
    const url = this.apiUrl + this.config.routerpath.adminAccess + '/' + pageType;
    return this.http.get<any>(url);
  }
  getWorkQueueData(){
    const url = this.apiUrl + this.config.routerpath.workQueueData;
    return this.http.get<any>(url);
  }
  getAppAttrData(){
    const url = this.apiUrl + this.config.routerpath.appAttr;
    return this.http.get<any>(url);
  }
  updateWorkQueueRole(body){
    const url = this.apiUrl + this.config.routerpath.updateWorkQueueUrl+"/"+body.workqueue_id;
    return this.http.put<any>(url,body);
  }
  updateAppAttribute(body){
    const url = this.apiUrl + this.config.routerpath.updateAppAttrUrl+"/"+body.attribute_id;
    return this.http.put<any>(url,body);
  }
  getLookupNoHryData(){
    const url = this.apiUrl + this.config.routerpath.getLookupNoHryDataUrl;
    return this.http.get<any>(url);
  }
  getLookupWithHryData(){
    const url = this.apiUrl + this.config.routerpath.getLookupWithHryDataUrl;
    return this.http.get<any>(url);
  }

  updateLookupWithHry(payload: any) {
    const url = this.apiUrl + this.config.routerpath.updateLookupHryUrl+"/"+payload.ID;
    return this.http.put<any>(url,payload);
  }
  updateLookupNoHry(payload: any) {
    const url = this.apiUrl + this.config.routerpath.updateLookupNoHryUrl+"/"+payload.ID;
    return this.http.put<any>(url,payload);
  }

  addLookupWithHry(payload: any) {
    const url = this.apiUrl + this.config.routerpath.getLookupWithHryDataUrl;
    return this.http.post<any>(url,payload);
  }
  addLookupWithNoHry(payload: any) {
    const url = this.apiUrl + this.config.routerpath.getLookupNoHryDataUrl;
    return this.http.post<any>(url,payload);
  }
  getReports(){
    const url = this.apiUrl + this.config.routerpath.getReportsUrl;
    return this.http.get<any>(url);
  }
  addReports(payload){
    const url = this.apiUrl + this.config.routerpath.getReportsUrl;
    return this.http.post<any>(url,payload);
  }

  updateReports(payload: any){
    const url = this.apiUrl + this.config.routerpath.updateReportsUrl+"/"+payload.id;
    return this.http.put<any>(url,payload);
  }
  deleteRecord(payload:any){
    const url = this.apiUrl + this.config.routerpath.updateReportsUrl+"/"+payload.data.id;
    return this.http.delete<any>(url,payload.data);
  }

  endPointRefreshGet(endPoint:any,body){
    const url = this.apiUrl + endPoint;
    if(body.createdById) {
      let createdById = body.createdById;
      let createdByName = body.createdByName;
      if(body.year) {
        let year = body.year;
        return this.http.get<any>(url,{ params: { createdById, createdByName, year }});
      }
      else if(body.date) {
        let date = body.date;
        return this.http.get<any>(url,{ params: { createdById, createdByName, date }});
      } 
      else if(body.refreshDate) {
        let refreshDate = body.refreshDate;
        return this.http.get<any>(url,{ params: { createdById, createdByName, refreshDate }});
      }
      return this.http.get<any>(url,{ params: { createdById, createdByName}});
    }
    else 
      return this.http.get<any>(url);
  }
  endPointRefreshPost(endPoint:any,body){
    const url = this.apiUrl + endPoint;
    return this.http.post<any>(url,body);
  }
  getTemplatesList(): Observable<any> {
    const url = this.apiUrl + this.configV4.getTemplatesList;
    return this.http.get<any>(url);
  }
  generateBulkTemplate(id) {
    const url = this.apiUrl + this.configV4.generateBulkTemplate + id;
    return this.http.get<any>(url);
  }
  runPostSubmit(id) {
    const url = this.apiUrl + this.configV4.runPostSubmit + id;
    return this.http.get<any>(url);
  }
}