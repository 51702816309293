import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-secure-grp',
  templateUrl: './error-secure-grp.component.html',
  styleUrls: ['./error-secure-grp.component.css']
})
export class ErrorSecureGrpComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  raiseSecure(){
    window.open(`https://secure.uhc.com/`, '_blank');
  }

  login(){
    this.router.navigate([{ outlets: { primary: 'login'} }]);
  }
}
