<form [formGroup]="form" (ngSubmit)="onSubmit($event)">
    <div class="row">
        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-10 col-sm-12 col-lg-10 col-xl-10 col-xs-10 col-sm-12 col-lg-10 col-xl-10 col-xs-10">

            <span class="form-title" for="name">Capability Name <span class="required-asterisk">*</span> </span><br>
            <input type="text" id="name" (keyup)="changedName($event)" placeholder="Enter the name" formControlName="name"><br>
        </div>
        <!-- <div class="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-2">
            <span class="form-title" for="rank">Rank<span class="required-asterisk">*</span></span><br>
            <input type="number" id="rank" formControlName="rank"><br>
        </div> -->
    </div><br />
    <div class="row">
        <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
            <span class="form-title" for="description">Capability Description  <span class="required-asterisk">*</span></span>
            <textarea id="description" placeholder="Enter the Description" formControlName="description" rows="4"
                cols="50" minlength="3">{{form.value.description}}</textarea>
        </div>
    </div>

    
    <div style="text-align: center;">
        <button [disabled]="checkForChanges() || !form.valid" class="m-1 uitk-c-button uitk-c-button--default 
            mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" type="submit">
            Update
        </button>
    </div>
</form>