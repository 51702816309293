<div class="footer2 mainDiv mainDiv-height col-md-12 col-xs-12 col-sm-12 col-lg-12 col-xl-12">
    <div class="col-md-9 col-sm-12 col-lg-9 col-xl-9 col-xs-9">
        ©{{currentYear}} UnitedHealth Group. All rights reserved.
    </div>
    <div class="contact col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3">
        <div class="contactRow row  contactusrow-align" style="width: 100%;">
        <div class="contactusDiv contactusDiv_width">
            Contact Us:
        </div>
        <div  class="contactusDiv-images contactusDiv-images-margin contactusDiv-images-cursor">
            <a href="{{outlookHref}}" target="_blank" rel="noopener noreferrer">
                <img class="footer-Image" src="../../../../assets/imagesV4/footer-outlook.svg"  matTooltip="Outlook" matTooltipPosition="above">
            </a>
        </div>
        <div  class="contactusDiv-images contactusDiv-images-margin contactusDiv-images-cursor">
            <a onclick="window.open('https://teams.microsoft.com/l/channel/19%3ahyqBUCKo9V2xNwyKCXa2hIUNGPgh24c1lsC4AjQiX2o1%40thread.tacv2/General?groupId=2ddaff3a-b209-451a-8405-5c0530c43e91&tenantId=db05faca-c82a-4b9d-b9c5-0f64b6755421')" target="_blank">
                <img class="footer-Image" src="../../../../assets/imagesV4/footer-Teams.svg" matTooltip="Teams" matTooltipPosition="above">   
            </a>
        </div>
        <div  class="contactusDiv-images contactusDiv-images-margin contactusDiv-images-cursor">
            <a onclick="window.open('https://web.yammer.com/main/org/optum.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMTc2MzM4OTIzNTIifQ/new')" target="_blank">
                <img class="footer-Image" src="../../../../assets/imagesV4/footer-yammer.svg" matTooltip="Yammer" matTooltipPosition="above">
            </a>
        </div>
        <div  class="contactusDiv-images contactusDiv-images-margin contactusDiv-images-cursor">
            <a onclick="window.open('https://github.com/uhg-internal/UHC-Tech-Ops-Hub-TOH-UHCTechOps')" target="_blank">
                <img class="footer-Image" src="../../../../assets/imagesV4/footer-github.svg" matTooltip="Github" matTooltipPosition="above">
            </a>
        </div>
        </div>
    </div>
</div>
