<div class="row">
  <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" *ngIf="!nodata" title="Click on the chart to be redirected to HAD dashboard" >
    <!-- <span class="chart-header">Application Health: </span> -->
    <span class="summary-sub-title">Application Health</span>
  
    <div class="health-chart-section" id="barchart" style="cursor: pointer;">
      <span
      class="chart-Precentage"
    
      style="
    padding: 1%;
    color: #013B71;
  
    /* border-radius: 50%; */
    text-align: center;
    font-size: 18px;margin: -3px;
    line-height: 30px;"
      >{{ percentage }}%</span
    >
    </div>
    <span class="chart-footer"
      ><span class="chart-Precentage">{{ actualPoints }} / {{ possiblePoints }}</span> possible points</span
    >
  </div>
   
  <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" *ngIf="nodata" title="Click chart to view full Had Dashboard!">
    <span class="chart-header" style="text-align:center;display: block;"> 
      <img  style="height: 18px;" src="/assets/images/warning-icon.svg" />
      Health data is not available right now.</span>
  </div>
</div>
