
    <div class="global-dropdown dropdown-main-div">
        <mat-form-field appearance="outline" (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" *ngIf="isMultiSelect === 'true' && isAppllyFilter === 'false' && !isFromForecast" class="select-dropdown">
            <mat-label *ngIf="showPlaceholder == 'true'"><label style="margin-top: -10%;">{{placeholder}}</label></mat-label>
            <mat-select (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder"  panelClass="global-select-panel" [disabled]="disableDD === true" (keydown)="$event.stopPropagation()" 
            (selectionChange)="onSelectionChange($event, placeholder)"
                [(ngModel)]="selected" #filterSelect="ngModel" multiple (blur) = "resetInputValue()">
            <div>
                <input *ngIf="searchFlag === 'true'" matinput type="text" (blur) = "resetInputValue()"
                (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt" class="mat-input-search" placeholder="Search">
            </div>
            <div class="table-data-font">

                <ng-container *ngIf="placeholder !== 'Select Group'">

                    <mat-checkbox *ngIf="selectAllFlag === true" class="mat-option select-all-dropdown"
                    [indeterminate]="!allChecked"
                    [checked]="isChecked(filterSelect, dropdownData)"
                    (click)="clickedButton($event)"
                    (change)="toggleSelection(checkbox, filterSelect, dropdownData, placeholder)" #checkbox> Select all </mat-checkbox>
                    <mat-option class="global-mat-option" *ngFor="let data of dropdownData | search : searchTxt" title="{{data.name}}" 
                    [value]="data"><label style="margin:0%">{{data.name}}</label></mat-option>
                </ng-container>

                
            </div>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" *ngIf="isMultiSelect === 'true' && isAppllyFilter === 'false' && isFromForecast" class="select-dropdown forecast-dropdowns-custom">
            <mat-label *ngIf="showPlaceholder == 'true'"><label style="margin-top: -10%;">{{placeholder}}</label></mat-label>
            <mat-select (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder"  panelClass="global-select-panel" [disabled]="disableDD === true" (keydown)="$event.stopPropagation()" 
             [formControl]="selectedOption" multiple (blur) = "resetInputValue()">
            <div>
                <input *ngIf="searchFlag === 'true'" matinput type="text" (blur) = "resetInputValue()"
                (keydown)="$event.stopPropagation()" (keyup)="searchDropdownForecast()" [(ngModel)]="searchTxt" class="mat-input-search" placeholder="Search">
            </div>
            <mat-select-trigger>
                <span>{{ textTodisplay }}</span>
            </mat-select-trigger>
            <div class="table-data-font">
                <mat-checkbox *ngIf="selectAllFlag === true" class="mat-option select-all-dropdown"
                [indeterminate]="!allChecked"
                [checked]="isCheckedForecast()"
                (click)="clickedButton($event)"
                (change)="toggleSelectionForecast(checkbox)" #checkbox> Select all </mat-checkbox>
                <mat-option *ngFor="let data of dropdownData.slice(0,200) | search : searchTxt" title="{{data.name}}" 
                    [value]="data" class="forecast-dropdowns-mat-option">
                    <mat-checkbox [checked]="isCheckedForecastIndividual(data)" (change)="onselectionForecast($event, data)">
                        {{data.name}}
                    </mat-checkbox>
                    <!-- <label class="forecast-dropdown-label"></label> -->
                </mat-option>                
            </div>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" *ngIf="isMultiSelect !== 'true' && isAppllyFilter === 'false'" class="select-dropdown">
            <mat-label><label>{{placeholder}}</label></mat-label>
            <mat-select (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" panelClass="global-select-panel" [disabled]="disableDD == true" [(ngModel)]="selected" (keydown)="$event.stopPropagation()" (selectionChange)="onSelectionChange($event, placeholder)">
                <input *ngIf="searchFlag === 'true'" class="mat-input-search" type="text" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt" matinput placeholder="Search" />

                <ng-container *ngIf="placeholder !== 'Select Group'">
                    <mat-option *ngFor="let data of dropdownData | search : searchTxt" [value]="data">
                        <mat-label><label style="margin:0%">{{data.name}}</label></mat-label></mat-option>
                </ng-container>
                

                <ng-container *ngIf="placeholder === 'Select Group'">

                    <mat-option matTooltipPosition="right" class="global-mat-option" *ngFor="let data of dropdownData | search : searchTxt" [value]="data">
                        
                        <i matTooltipPosition="right" aria-hidden="true"></i>
                        <span class="uitk-icon uitk-icon-group sm-icon group-icon" style="margin-right: 3%" *ngIf="data.visibility=== 'Public'"></span>
                        <label style="margin:0%">{{data.name}}</label>
                    </mat-option>
    
                    <mat-option class="global-mat-option hide-checkbox" *ngIf="sharedgroups" [disabled]="true">
                        <i class="uitk-icon uitk-icon-share sm-icon uitk-c-label__icon share " style="display: contents;"> Shared
                          Groups</i>
                      </mat-option>
                      <mat-option class="global-mat-option" matTooltipPosition="right" [value]="shared" *ngFor="let shared of sharedgroups | search : searchTxt">
                        <i matTooltipPosition="right" style="opacity: 0.65;" aria-hidden="true" [matTooltip]="shareby(shared)"></i>
                        <span class="uitk-icon uitk-icon-group sm-icon group-icon" style="margin-right: 3%" *ngIf="shared.visibility=== 'Public'"> </span>
                        <label style="margin:0%"> {{shared.name}}</label>
                      </mat-option>
                </ng-container>
            </mat-select>

            
        </mat-form-field>

        <!--below dropdown is for capability-appview page (padu status, select model)-->
        <mat-form-field appearance="outline" (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" *ngIf="isTechViewFilter === 'false' &&  isMultiSelect === 'true' && isAppllyFilter === 'true'" class="select-dropdown">
            <mat-label><label style="margin-top: -10%;">{{placeholder}}</label></mat-label>
            <mat-select (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" panelClass="global-select-panel portfolio-cap-select-panel" style="max-height:150px !important" [disabled]="disableDD === true" (keydown)="$event.stopPropagation()" (selectionChange)="onSelectionChange($event, placeholder)"
                [(ngModel)]="selected" #filterSelect="ngModel" #matSelect = 'matSelect' multiple>
            <div>
                <input *ngIf="searchFlag === 'true'" matinput type="text" (blur)="resetInputValue()" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt" class="mat-input-search" placeholder="Search">
            </div>
            <div class="table-data-font">

                <ng-container *ngIf="placeholder !== 'Select Group'">

                    <mat-checkbox *ngIf="selectAllFlag === true" [disabled]="allUncheck" class="mat-option select-all-dropdown"
                    [indeterminate]="!allChecked"
                    [checked]="isChecked(filterSelect, dropdownData)"
                    (change)="toggleWithFilterButton(checkbox, filterSelect, dropdownData)" #checkbox> Select all </mat-checkbox>
                    <mat-option class="global-mat-option" *ngFor="let data of dropdownData | search : searchTxt" title="{{data.name}}"
                    [value]="data"  [disabled]="selected.length < 2 && selected[0] == data" (click)="checkMandateOption(data)"><label style="margin:0%">{{data.name}}</label></mat-option>
                   
                </ng-container>
                 

                
            </div>
            <div>
            <span *ngIf="placeholder !== 'Select The Model to view'" style="cursor:pointer; float: right;padding-right: 21px;" class="localApplyButton" [title]="applyTitle"
                                                    (click)="applyPaduFilter()">Apply</span>
                    <span *ngIf="placeholder !== 'Select The Model to view'" style="cursor:pointer; float: right;padding-right: 21px;" class="localApplyButton"
                                                    (click)="clearPaduFilter()">Clear</span>
            </div>
            </mat-select>
        </mat-form-field>
        
		<!--below dropdown is for Technology View Environment Filter-->
	    <mat-form-field appearance="outline" (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" 
	                    *ngIf="isTechViewFilter === 'true' && 
	                            isMultiSelect === 'true' && 
	                            isAppllyFilter === 'true'"
	                    class="select-dropdown">
	        <mat-label>
	                <label style="margin-top: 1%;">{{placeholder}}</label>
	        </mat-label>
	        <mat-select (click)="clickOnDropDown($event,Pixeltracking)" [attr.Pixel-tracking]="Pixeltracking" [attr.Pixel-title]="placeholder" panelClass="global-select-panel portfolio-cap-select-panel" 
	                    style="max-height:150px !important" 
	                    [disabled]="disableDD === true" 
	                    (keydown)="$event.stopPropagation()" 
	                    (selectionChange)="onSelectionChange($event, placeholder)"
	                    [(ngModel)]="selected" #filterSelect="ngModel" #matSelect = 'matSelect' multiple>
	            <div class="table-data-font" style="min-width:200px">
	                <ng-container>
	                    <mat-checkbox [disabled]="allChecked" class="mat-option select-all-dropdown"
	                                    [indeterminate]="!allChecked"
	                                    [checked]="isChecked(filterSelect, dropdownData)"
	                                    (change)="toggleWithFilterButton(checkbox, filterSelect, dropdownData)" 
	                                    #checkbox> Select all 
	                    </mat-checkbox>
	                    <mat-option class="global-mat-option"
	                                *ngFor="let data of dropdownData" 
	                                title={{data}}
	                                [value]="data"
                                    [disabled]="selected.length < 2 && selected[0] == data"                             
	                                (click)="checkMandateForEnvFilter(data)">
	                            <label style="margin:0%;">{{data}}</label>
	                    </mat-option>
	                </ng-container>      
	            </div>
	            <div>
	                <span style="cursor: pointer; float: right; padding-right: 21px;" 
	                    class="localApplyButton" [title]="applyTitle"
	                    (click)="applyEnvFilter()">Apply
	                </span>
	                <span style="cursor:pointer; float: right; padding-right: 21px;" 
	                        class="localApplyButton"
	                        (click)="clearEnvFilter()">Clear
	                </span>
	            </div>
	        </mat-select>
	    </mat-form-field>
        
    </div>

  
