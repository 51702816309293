import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { AppHubUtilService } from 'src/app/shared/services/app-hub-util.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-release-note',
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.css']
})
export class ReleaseNoteComponent implements OnInit {

  refreshData;
  data;
  showLoader = true;
  constructor(private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private util: AppHubUtilService,
    private sharedService: SharedService,) {
    iconRegistry.addSvgIcon(
      'chrome',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons8-chrome.svg')
    );

    iconRegistry.addSvgIcon(
      'internet-explorer',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons8-internet-explorer.svg')
    );
  }

  refreshHeaderColumns = [
    { columnDef: 'type', header: 'Data Source', width: '50%', cell: (element: any) => `${element.type}` },
    { columnDef: 'date', header: 'Data As Of', width: '50%', cell: (element: any) => `${this.util.getFormatDate(element.date)}` }
  ];


  ngOnInit() {
    this.sharedService.refreshDate().subscribe((date) => {
      this.data = date;
      this.refreshData = new MatTableDataSource(this.data);
      this.showLoader = false;
    });

  }
}
