import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { JobSchedulingComponent } from './job-scheduling/job-scheduling.component';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LookupEditorComponent } from './lookup-editor/lookup-editor.component';
import {MatMenuModule} from '@angular/material/menu';
import { AdminTableDataComponent } from './shared-data/table-data/admin-table-data.component';
import { SharedComponentsModule } from 'src/app/shared/shared-components.module';
import { ModalModule } from 'ng-modal-lib-sab';
import { EditDataPopupComponent } from './shared-data/edit-data-popup/edit-data-popup.component';
import { MatSortModule } from '@angular/material/sort';
import { ReportManagementComponent } from './report-management/report-management.component';
import { AddReportComponent } from './report-management/add-report/add-report.component';
import { MatRadioModule } from '@angular/material/radio';
import { BulkTemplatesComponent } from './bulk-templates/bulk-templates.component';

@NgModule({
  declarations: [
    AdminComponent,
    JobSchedulingComponent,
    LookupEditorComponent,
    AdminTableDataComponent,
    EditDataPopupComponent,
    ReportManagementComponent,
    AddReportComponent,
    BulkTemplatesComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatDatepickerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatMenuModule,
    SharedComponentsModule,
    ModalModule,
    MatSortModule,
    MatRadioModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class AdminModule { }
