import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TcmUtilsService {
  constructor(private http: HttpClient) {}

  resultCalculatePercentages: any = 0;

  sortOnProperty(property) {
    return function(a, b) {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  calculatePercentages(num1, num2) {
    let divider;
    if (num1 == 0) {
      divider = 0;
    } else if (num2 == 0) {
      divider = 0;
    } else {
      divider = num2;
    }
    if (divider) {
      this.resultCalculatePercentages = Math.abs(((num1 - num2) / divider) * 100).toFixed(0);
    }
    if (this.resultCalculatePercentages > 0) {
      return '+' + this.resultCalculatePercentages;
    } else {
      return this.resultCalculatePercentages;
    }
  }

  calculateMboScore(chargesProjected, targetCharges) {
    let mboScore;
    if (chargesProjected == 0 || targetCharges == 0) {
      let diff = targetCharges - chargesProjected;
      if(diff==0){
        mboScore = 3;
        return mboScore;
      }
    }
    let diff = targetCharges - chargesProjected;
    let division = diff / targetCharges;
    let variancePercentgae = division * 100;
    if (variancePercentgae >= -5 && variancePercentgae <= 5) {
      mboScore = 5;
    }
    // if (variancePercentgae <= 0 && variancePercentgae <= -4.99) {
    //   mboScore = 5;
    // }
    if (variancePercentgae >= -9 && variancePercentgae <= -5) {
      mboScore = 4;
    }

    if (variancePercentgae >= 5 && variancePercentgae <= 9) {
      mboScore = 4;
    }

    if (variancePercentgae >= -12 && variancePercentgae <= -9) {
      mboScore = 3;
    }
    if (variancePercentgae >= 9 && variancePercentgae <= 12) {
      mboScore = 3;
    }
    if (variancePercentgae >= -15 && variancePercentgae <= -12) {
      mboScore = 2;
    }
    if (variancePercentgae >= 12 && variancePercentgae <= 15) {
      mboScore = 2;
    }
    if (variancePercentgae <= -15 || variancePercentgae >= 15) {
      mboScore = 1;
    }

    return mboScore;
  }

  sortarraybykey(array) {
    const filterarray = array.sort((a, b) => {
      return Math.abs(((b.key === null) as any) - ((a.key === null) as any)) || ('' + a.key).localeCompare(b.key);
    });
    return filterarray;
  }
}
