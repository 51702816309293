import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PADUUtilsService {

  public loader = new Subject<boolean>();
  public paduStatus = new Subject<any>();

  constructor(private http: HttpClient) { }
  sortOnProperty(property) {
    return function (a, b) {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  calculateMboScore(ytdCharges, ytdTargets) {

    if (ytdCharges === 0 || ytdTargets === 0) {
      return 0;
    }
    if (ytdCharges > 0 && ytdTargets <= 0) {
      return 3;
    }
    let variancePer = (ytdCharges - ytdTargets) / 100;
    if (variancePer > -6 && variancePer < 6) {
      return 3;
    }

    if (variancePer >= 6 && variancePer < 10) {
      return 4;
    }

    if (variancePer >= 10) {
      return 5;
    }

    if (variancePer <= -6 && variancePer > -10) {
      return 2;
    }
    if (variancePer <= 10) {
      return 1;
    }
    // return 3 - (ytdCharges - ytdTargets) /ytdTargets /0.035;

  }

  toggleLoader(value) {
    this.loader.next(value);
  }

  setPaduStatus(value) {
    this.paduStatus.next(value);
  }



  getQuarter(d?) {
    d = d || new Date();
    return Math.floor(d.getMonth() / 3) + 1;
  }
}
