import { Injectable } from '@angular/core';
import { of, Subject, Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { appModernizationDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppHubCacheService } from './app-hub-cache.service';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private apiUrl: string;
  private config: any;
  constructor(
    private http: HttpClient,
    private cache: AppHubCacheService
  ) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
  }
  // Observable string sources
  private emitChangeSource = new Subject<any>();
  private emitTabChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  changeTabChange$ = this.emitTabChangeSource.asObservable();

  public notificationSource = new Subject<any>();

  // notificationSource$ = this.notificationSource.asObservable();
  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  emitTabChange(targetApp: string, view: string, params: any) {
    this.emitTabChangeSource.next({
      targetApp,
      view,
      params
    });
  }



  public createUserStats(msid: string, body): Observable<any> {
    const apiUrl = `${this.apiUrl}${this.config.routerpath.createUserStats}/${msid}`;
    return this.http.post(apiUrl, body);
  }

  public refreshDate(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.dataRefreshDate;
    const cache = this.cache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.cache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }
    // const url = this.apiUrl + this.config.routerpath.dataRefreshDate;
    // return this.http.get<Array<any>>(url);
  }

  getList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.globalFilter;
    return this.http.get<any>(url);
  }

  getOrganisationList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.orgList;
    return this.http.get<any>(url);
  }
  getAccessData(msId: string, secureGroups = {}): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getAccessData + msId;
    return this.http.post<any>(url, secureGroups);
  }
  getReportAccessData(msId: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getReportAccessData + msId;
    return this.http.get<any>(url);
  }

  isEnabled(arg, selectedLOB, selectedSUBLOB): boolean {
    const isWriteAccess = JSON.parse(localStorage.getItem('isWriteAccess'));

    if (environment.disableEditAppInsight) {
      return false;
    }

    if (!isWriteAccess) {
      return false;
    }

    const accessData = JSON.parse(localStorage.getItem('accessData'));
    if (accessData) {
      if (accessData.Role == 'Admin') {
        return accessData[arg];
      } else {
        let isLobMatched = false;
        let isSUBLobMatched = false;
        if (selectedLOB) {
          isLobMatched = accessData.lob.find(item => item == selectedLOB);
        }
        if (selectedSUBLOB) {
          isSUBLobMatched = accessData.subLob.find(item => item == selectedSUBLOB);
        }

        return accessData[arg] && isLobMatched && isSUBLobMatched;
      }
    }
  }

  getReleaseNotesData(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.releaseNoteDetails;
    return this.http.get<any>(url);
  }

  public getDynamicContent(body): Observable<any> {
    const apiUrl = `${this.apiUrl}${this.config.routerpath.dynamicContent}`;
    return this.http.post(apiUrl, body);
  }
  getNotification(msId:string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.notificationUrl + msId;
    return this.http.get<any>(url);

  }

  getNotificationList(msId:string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.notificationListUrl+ msId;
    return this.http.get<any>(url);
  }

  public setNotificationData() {
    const msId = localStorage.getItem('MsId');
    this.getNotification(msId).subscribe((data: any) => {
      if (data && data.result) {
        this.notificationSource.next(data?.result?.notificationMaster);
      }
    }, error => {
      console.error('error', error);
      this.notificationSource.next([]);
    });
  }


}
