import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductMappingService } from '../product-mapping.service';
import { ToastrService } from 'ngx-toastr';
import { DailogModalComponent } from 'src/app/shared/elements/dailog-modal/dailog-modal.component';


@Component({
  selector: 'app-product-mapping-edit',
  templateUrl: './product-mapping-edit.component.html',
  styleUrls: ['./product-mapping-edit.component.css']
})

export class ProductMappingEditComponent implements OnInit {

  mode: string = '';
  title: string = '';
  selectedElement: boolean = false;
  addNewNodes: boolean = false;
  showLoader: boolean = true;
  anyChangeInData: boolean = false;
  hierarchyNode = [];
  parentNodeId: any = {};
  isfirstLoad: boolean = true;
  productFamilyId: string = '';
  groupModel: string = '';
  moveEnabled: boolean = false;
  moveNodeVertical: boolean = false;
  allProductModelData = [];
  move: any = {
    initialLevelDetail: {},
    movedLevelDetail: {}
  }
  disableAllIcon: boolean = false;
  arrOfL0 = [];
  openWqReq = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public toaster: ToastrService,
    public dialogConfirmation: MatDialog,

    private route: Router, private service: ProductMappingService, private fb: FormBuilder,
    private router: ActivatedRoute, public dialog: MatDialogRef<ProductMappingEditComponent>
  ) {
    this.productFamilyId = data.productFamilyId;
    this.groupModel = data.groupModel;
    this.mode = data.title;
    this.allProductModelData = data.allProductModelData;
    this.openWqReq = data.openWqReq;
    this.title = 'Hey! What do you like to edit?'
  }
  // get productFamilyFormGroup() {
  //   return this.form.get('product_family') as FormArray;
  // }

  ngOnInit() {
    if (this.mode === 'edit') {
      this.getDetailsOfProductFamily(this.productFamilyId);
    } else {
      this.hierarchyNode = [{
        addNewNodes: true,
        children: [],
        description: "",
        id: "",
        level: 0,
        name: "",
        rank: 1,
        showChildren: false,
        showDescription: false
      }];
      this.showLoader = false;
    }

  }

  createData(node) {
    return [{ ...node, ...{ showChildren: false, showDescription: false, addNewNodes: false } }]
  }
  getDetailsOfProductFamily(productFamilyId) {
    this.service.getDetailsOfProductFamily(productFamilyId).subscribe(data => {
      this.hierarchyNode = data;
      this.showLoader = false;

    });
  }
  showChildNodes(node) {
    let newNodeLevel = -1;
    this.addNewNodes = !node.addNewNodes;
    const ifOneAddNewNodeExist = node.children.findIndex(element => element.id === '' && element.addNewNodes === true);
    switch (node.level) {
      case 0:
        newNodeLevel = 1;
        break

      case 1:
        newNodeLevel = 2;
        break;

      case 2:
        newNodeLevel = 3;
        break;

    }
    if (ifOneAddNewNodeExist < 0) {
      node.children.unshift(
        { id: '', name: '', description: '', children: [], level: newNodeLevel, rank: node.children.length + 1, addNewNodes: this.addNewNodes, showDescription: false, showChildren: false })
      node.showChildren = true;

    }
    else {
      alert('One add capability form already present');
      node.showChildren = true;
    }

    node.showDescription = false;
  }


  toggleNodes(node) {
    node.showChildren = !node.showChildren;
    node.showDescription = false;

    node.highlightNode = (node.children.length > 0) ? true : false;

    if (!node.showChildren) {
      node.children = node.children.map(capL1 => {
        if (capL1.showChildren) {
          return { ...capL1, showChildren: false };
        }
        return capL1;
      }
      )
    }
  }

  showDescription(node) {

    if (node.showChildren && node.level > 0) {
      node.showChildren = false;
    }
    node.showDescription = !node.showDescription;
  }

  closePopup() {
    this.dialog.close(this.anyChangeInData);
  }

  nameChange(obj) {
    if (obj.addNewNodes) {
      obj.row.name = obj.value;
    }
  }

  receivedFormValue(event) {
    this.parentNodeId = {};
    const payload: any = {};
    payload.rank = event.additionalInfo.rank;
    payload.level = event.additionalInfo.level;
    payload.name = event.changedValue.name;
    payload.description = event.changedValue.description;
    payload.origname = event.changedValue.origname.trim();


    if (event.editMode === 'update') {
      const existingId = event.additionalInfo.id;
      const { capabilityL0Id, capabilityL1Id, capabilityL2Id } = this.findNodeParent(payload.level, event);
      payload.capabilityL0Id = capabilityL0Id;
      payload.capabilityL1Id = capabilityL1Id;
      payload.capabilityL2Id = capabilityL2Id;
      payload.updatedById = localStorage.getItem('MsId'),
        payload.updatedByName = JSON.parse(localStorage.getItem('currentUser'))
      this.showLoader = true;
      this.service.updateProduct(existingId, payload).subscribe({
        next: () => {
          event.additionalInfo.name = payload.name,
            event.additionalInfo.description = payload.description
          this.showLoader = false;
          this.anyChangeInData = true;
          this.toaster.success("Successfully updated.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
        },
        error: error => {
          console.log(error, "error occured while update")
          this.showLoader = false;
          this.anyChangeInData = true;
          let err = (error.error.errors[0].message == "Master_key must be unique") ? 'Capability already exists' : error.errors;
          this.toaster.error(err, 'The Product capability name already exists in same hierarchy.', { timeOut: 10000, positionClass: 'toast-top-center' });
        }
      })
    }
    else {

      const { capabilityL0Id, capabilityL1Id, capabilityL2Id } = this.findNodeParent(payload.level, event);
      payload.capabilityL0Id = capabilityL0Id;
      payload.capabilityL1Id = capabilityL1Id;
      payload.capabilityL2Id = capabilityL2Id;
      if (this.mode !== 'edit') {
        payload.group_model = this.groupModel
      }
      payload.createdById = localStorage.getItem('MsId'),
        payload.createdByName = JSON.parse(localStorage.getItem('currentUser'))
      this.showLoader = true;
      this.service.addNewProductCapability(payload).subscribe({
        next: data => {
          event.additionalInfo.addNewNodes = false;
          event.additionalInfo.name = payload.name,
            event.additionalInfo.description = payload.description
          switch (payload.level) {
            case 0:
              event.additionalInfo.id = data.data.capabilityL0Id
              break;
            case 1:
              event.additionalInfo.id = data.data.capabilityL1Id;
              break;
            case 2:
              event.additionalInfo.id = data.data.capabilityL2Id;
              break;
            case 3:
              event.additionalInfo.id = data.data.capabilityL3Id;
              break;

          }
          this.showLoader = false;
          this.anyChangeInData = true;
          this.toaster.success("Successfully added.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
        },
        error: error => {
          console.log(error, "error occured while update")
          this.showLoader = false;
          this.anyChangeInData = true;
          let err = (error.error.errors[0].message == "Master_key must be unique") ? 'Capability already exists' : error.error.errors;

          this.toaster.error(err, '', { timeOut: 10000, positionClass: 'toast-top-center' });
        }
      })
      // console.log( this.findNodeParent(payload.level-1,event.additionalInfo))
    }
  }

  findNodeParent(level, node) {

    if (level === 1) {
      this.parentNodeId.capabilityL0Id = node.hierarchyNode[0].id;

    }
    else if (level === 2) {
      this.parentNodeId.capabilityL0Id = node.hierarchyNode[0].id;
      this.parentNodeId.capabilityL1Id = node.parentNode.id

    }
    else if (level === 3) {

      this.parentNodeId.capabilityL0Id = this.hierarchyNode[0].id;
      for (let child of node.hierarchyNode[0].children) {
        const filteredNode = child.children.filter(d => d.id === node.parentNode.id)
        if (filteredNode.length) {
          this.parentNodeId.capabilityL1Id = child.id
        }

      }
      this.parentNodeId.capabilityL2Id = node.parentNode.id
      // this.parentNodeId.capabilityL0 = this.hierarchyNode[0].id;
      // this.parentNodeId.capabilityL1 = node.parentNode.id


    }

    return this.parentNodeId;

  }

  selectedNode(node) {
    if (Object.keys(node).includes('selectedNode')) {
      node['selectedNode'] = !node.selectedNode;
    }
    else {
      node['selectedNode'] = true;
    }
    // alert('Node clicked');
  }

  deleteNode(event) {
    const payload: any = {
      level: event.node.level,
      updatedById: localStorage.getItem('MsId'),
      updatedByName: JSON.parse(localStorage.getItem('currentUser'))

    }

    if (event.node.id) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '400px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.minHeight = '200px';
      dialogConfig.height = 'auto';
      dialogConfig.autoFocus=false;
      dialogConfig.panelClass = 'delete-conf-popup';


      dialogConfig.data = {
        title: 'Delete Confirmation!',
        subTitle: `Are you sure to delete ${event.node.name}?`,
        buttonName: 'Delete'
      };

      const dialogRef = this.dialogConfirmation.open(DailogModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {

        if (result.data === 'save') {

          payload.deleteCap = true,
            this.showLoader = true;
          this.service.deleteProduct(event.node.id, payload).subscribe({
            next: () => {
              this.deleteNodeFromView(event.node, event.parentNode)

              this.showLoader = false;
              this.anyChangeInData = true;
              this.toaster.success("Successfully deleted.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
            },
            error: error => {
              console.log(error, "error occured while update")
              this.showLoader = false;
              this.anyChangeInData = true;


              if (error.error.errors === 'All Mapped application will be moved to parent capability') {


                this.deleteL2L3Capability(event.node, event.parentNode)


              }
              else {

                this.toaster.error(error.error.errors, '', { timeOut: 10000, positionClass: 'toast-top-center' });
                this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
              }
            }
          })
        }
      });

    }
    else {
      this.deleteNodeFromView(event.node, event.parentNode)
    }

  }

  deleteL2L3Capability(node, parentNode) {
    const payload: any = {
      level: node.level,
      updatedById: localStorage.getItem('MsId'),
      updatedByName: JSON.parse(localStorage.getItem('currentUser'))

    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '400px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '200px';
    dialogConfig.height = 'auto';
    dialogConfig.panelClass = 'delete-conf-popup-move';


    dialogConfig.data = {
      title: 'Delete Confirmation!',
      subTitle: `Associated application under ${node.name} will be moved in its parent capability. Are you sure to continue?`,
      buttonName: 'Delete'
    };

    const dialogRef = this.dialogConfirmation.open(DailogModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result.data === 'save') {
        payload.deleteCap = true,
          this.showLoader = true;
        this.service.deleteProductandMoveAssociatedApp(node.id, payload).subscribe({
          next: () => {
            this.deleteNodeFromView(node, parentNode)

            this.showLoader = false;
            this.anyChangeInData = true;
            this.toaster.success("Successfully deleted.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
          },
          error: error => {
            console.log(error, "error occured while update")
            this.showLoader = false;
            this.anyChangeInData = true;
            this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });

            if (error.error.errors) {

              this.toaster.error(error.error.errors, '', { timeOut: 10000, positionClass: 'toast-top-center' });

            }
          }
        })



      }
    });
  }

  deleteNodeFromView(node, parentNode: any = {}) {
    switch (node.level) {
      case 0:{
        this.hierarchyNode = [];
        break;
      }
      case 1:{
        this.hierarchyNode[0].children = this.hierarchyNode[0].children.filter(d => d.id !== node.id)
        break;
      }
      case 2:{
        const index = this.hierarchyNode[0].children.findIndex(d => d.id === parentNode.id);
        this.hierarchyNode[0].children[index].children = this.hierarchyNode[0].children[index].children.filter(d => d.id !== node.id);
        break;
      }
      case 3:{

        // const levelIds = this.findNodeParent(parentNode.level, parentNode);
        const grandParentIndex = this.hierarchyNode[0].children.findIndex(d => {
          for (let child of d.children) {
            if (child.id === parentNode.id) {
              return true
            }
          }
        });
        // const indexOfGrandParent = this.hierarchyNode[0].children.findIndex(d => d.id === levelIds.capabilityL1Id);
        // console.log(indexOfGrandParent);
        // const indexOfParent = this.hierarchyNode[0].children[indexOfGrandParent].children.findIndex(d => d.id === levelIds.capabilityL2Id);
        const indexOfParent = this.hierarchyNode[0].children[grandParentIndex].children.findIndex(d => d.id === parentNode.id);
        this.hierarchyNode[0].children[grandParentIndex].children[indexOfParent].children = this.hierarchyNode[0].children[grandParentIndex].children[indexOfParent].children.filter(d => d.id !== node.id)
        break;
      }
    }
  }

  moveNode(event) {
    this.title = 'Hey! Where do you like to move?';
    this.move.initialLevelDetail.node = event.node;
    this.move.initialLevelDetail.parentNode = event.parentNode;
    this.moveEnabled = true;
  }

  moveNodeVertically(e) {
    if (e) {
      this.moveNodeVertical = e;
      // this.moveEnabled = false;
    }
  }

  checksameh(movedname, tomovedchildren) {
    for (let i = 0; i < tomovedchildren.length; i++) {
      if (tomovedchildren[i].name === movedname)
        return true;
    }
    return false;
  }


  moveCapability(e) {
    const movedname = this.move.initialLevelDetail.node.name;
    const tomovedname = e.node.name;
    const parenttomovedname = e.parent.name;
    const tomovedchildren = e.node.children;

    if (movedname === tomovedname) {
      this.toaster.error("The Product capability name already exists in same hierarchy.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
      this.closePopup();
      this.showLoader = false;
      this.disableAllIcon = true;
      this.anyChangeInData = true;
    }
    else if (movedname === parenttomovedname) {
      this.toaster.error("The Product capability name already exists in same hierarchy.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
      this.closePopup();
      this.showLoader = false;
      this.disableAllIcon = true;
      this.anyChangeInData = true;
    }
    else if (this.checksameh(movedname, tomovedchildren)) {
      this.toaster.error("The Product capability name already exists in same hierarchy.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
      this.closePopup();
      this.showLoader = false;
      this.disableAllIcon = true;
      this.anyChangeInData = true;
    }
    else {
      const payload = {
        level: this.move.initialLevelDetail.node.level,
        capId: this.move.initialLevelDetail.node.id,
        targetCapLevel: e.node.level,
        targetCapId: e.node.id,
        updatedById: localStorage.getItem('MsId'),
        updatedByName: JSON.parse(localStorage.getItem('currentUser'))
      };
      // this.showLoader = true;
      this.service.moveCapability(payload).subscribe({
        next: () => {
          e.node.children.push(this.move.initialLevelDetail.node);
          this.moveEnabled = false;
          this.getDetailsOfProductFamily(this.productFamilyId);
          this.disableAllIcon = true;
          this.showLoader = false;
          this.anyChangeInData = true;
          this.toaster.success("Successfully moved", '', { timeOut: 10000, positionClass: 'toast-top-center' });
        },
        error: error => {
          console.log(error, "error occured while update")
          this.showLoader = false;
          this.disableAllIcon = true;
          this.anyChangeInData = true;
          this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });

          if (error.error.errors) {

            this.toaster.error(error.error.errors, '', { timeOut: 10000, positionClass: 'toast-top-center' });

          }
        }
      });
    }

  }

  cancelCapability() {
    this.showLoader = true;
    setTimeout(() => {
      this.moveEnabled = false;
      this.moveNodeVertical = false;
      this.showLoader = false;
      this.title = 'Hey! What do you like to edit?';
    }, 1000)

  }


}

