import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { NgbdToastGlobal } from './toast-global.component';
import { ToastsContainer } from './toasts-container.component';

@NgModule({
  imports: [NgbModule, CommonModule],
  declarations: [NgbdToastGlobal, ToastsContainer],
  bootstrap: [NgbdToastGlobal]
})
export class NgbdToastGlobalModule { }
