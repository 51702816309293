import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { appModernizationDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { AppHubCacheService } from './app-hub-cache.service';

@Injectable({
  providedIn: 'root'
})
export class AppModernizationService {
  private apiUrl: string;
  private config: any;
  constructor(private http: HttpClient, private dataCache: AppHubCacheService) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
  }
  private appModerFilter$ = new BehaviorSubject({
    appFilter$: [], lobLevelFilter$: [],
    orgFilter$: [], productFilter$: [], productFamilyFilter$: []
  });

  filterValues() {
    return this.appModerFilter$;
  }

  setFilterValue(obj: {
    appFilter$: Array<any>, lobLevelFilter$: Array<any>, orgFilter$: Array<any>,
    productFilter$: Array<any>, productFamilyFilter$: Array<any>
  }) {
    this.appModerFilter$.next(obj);
  }

  public getGlobalFilterLOBAPP(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.globalAppLobFilter;
    // return this.http.get<any>(url);

    const cache = this.dataCache.getCache(url);
    const getData$ = cache
      ? of(cache)
      : this.http.get<Array<any>>(url).pipe(
        map(data => {
          this.dataCache.setCache(url, data);
          return data;
        })
      );

    const getotheroverview$ = this.getOtherGlobalFilter();
    return zip(getData$, getotheroverview$).pipe(
      map(data => {
        return {
          appLob: data[0],
          otherFilter: data[1]
        };
      })
    );
  }

  getOtherGlobalFilter() {
    const url = this.apiUrl + this.config.routerpath.globalOtherFilter;
    const cache = this.dataCache.getCache(url);
    const getAllData$ = cache
      ? of(cache)
      : this.http.get<Array<any>>(url).pipe(
        map(data => {
          this.dataCache.setCache(url, data);
          return data;
        })
      );

    return getAllData$;
  }

  getModernizationAppDetails(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.globalOtherFilter;
    return this.http.post<any>(url, body);
  }

  getModernizationFilter(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.globalFilter;
    return this.http.get<any>(url);
  }

  getRoadmapProductData(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.roadmapProductTableDetail;
    return this.http.post<any>(url, body);
  }

  getApplicationRoadmapFilter(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.appDetailRoadmap;
    const cache = this.dataCache.getCache(url);
    const getAllData$ = cache
      ? of(cache)
      : this.http.get<Array<any>>(url).pipe(
        map(data => {
          this.dataCache.setCache(url, data);
          return data;
        })
      );

    return getAllData$;
  }

  public getLobList(): Observable<Array<any>> {
    const url = this.apiUrl + this.config.routerpath.lobList;
    const cache = this.dataCache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          return data;
        })
      );
    } else {
      return of(cache);
    }
  }

  getAppRoadmapList(): Observable<any> {
    if (!sessionStorage.getItem('appRoadmaplist')) {
      const appListUrl: string = this.apiUrl + this.config.routerpath.appDetailRoadmap;
      const appRoadmaplist = this.http.get(appListUrl).pipe(
        map(res => {
          sessionStorage.setItem('appRoadmaplist', JSON.stringify(res));
          return res;
        })
      );
      return appRoadmaplist;
    } else {
      return of(JSON.parse(sessionStorage.getItem('appRoadmaplist')));
    }
  }
  saveroadmapdata(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.roadmapsave;
    return this.http.post<any>(url, body);
  }
  updateroadmapdata(body, id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.roadmapupdate + '/' + id;
    return this.http.put<any>(url, body);
  }
  getroadmapdata(id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.roadmapget + '/' + id;
    return this.http.get<any>(url);
  }
  savemapproductdatadata(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.mapproductdatasave;
    return this.http.post<any>(url, body);
  }
  updatemapproductdatadata(body, id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.mapproductdataupdate + '/' + id;
    return this.http.put<any>(url, body);
  }
  getmapproductdatadata(id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.mapproductdataget + '/' + id;
    return this.http.get<any>(url);
  }
}
