import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthStore } from '../auth.store';
// import { StateService } from '../../shared/state.service';

@Component({
  selector: 'authenticate',
  templateUrl: './authenticate.component.html'
})
export class AuthenticateComponent implements OnInit {
  public statusText: string;

  constructor(private AuthStore: AuthStore, private router: Router, private route: ActivatedRoute, ) {

    // this.AuthStore.authStatusObservable.subscribe(status => {
    //   if(status) {
    //     this.stateService.goToPreviousState();
    //   }
    // });
  }

  ngOnInit() {
    // this.AuthStore.login();
  }

}
