import { Component, OnInit, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { tcmHubConfigDetails } from '../../../config';

import * as d3 from 'd3';

@Component({
  selector: 'app-trend-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.css']
})
export class TrendChartComponent implements OnInit {
  @Input() CurrentYear = (tcmHubConfigDetails.CurrentYear);
  @Input() name = '';
  @Input() type: 'tco';
  @Input() class: 'tco';
  margin;
  svg;
  bar;
  @Input() data: any = [
    { group: 'Jan', '2021': '120000', '2022': '100000', '2023': '130000' },
    { group: 'Feb', '2021': '600000', '2022': '600000', '2023': '330000' },
    { group: 'Mar', '2021': '110000', '2022': '280000', '2023': '120000' },
    { group: 'Apr', '2021': '190000', '2022': '600000', '2023': '100000' },
    { group: 'May', '2021': '120000', '2022': '100000', '2023': '130000' },
    { group: 'Jun', '2021': '600000', '2022': '600000', '2023': '330000' },
    { group: 'Jul', '2021': '110000', '2022': '280000', '2023': '120000' },
    { group: 'Aug', '2021': '120000', '2022': '100000', '2023': '130000' },
    { group: 'Sep', '2021': '600000', '2022': '600000', '2023': '330000' },
    { group: 'Oct', '2021': '110000', '2022': '280000', '2023': '120000' },
    { group: 'Nov', '2021': '120000', '2022': '100000', '2023': '130000' },
    { group: 'Dec', '2021': '600000', '2022': '600000', '2023': '330000' },
  ];
  @Input() subgroups: any = [`${this.CurrentYear - 2}`, `${this.CurrentYear - 1}`, `${this.CurrentYear}`];
  groups: any = [];
  width;
  height;
  x;
  y;
  xSubgroup;
  color;
  parentDiv;
  @Input() barcolor: any = ['#addffc', '#52bdfc', '#0e8bd4'];
  @Input() yearFilter: boolean = true;
  maxKey: any;
  @Input() showTitle: boolean = true;
  @Input() showBorder: boolean = true;
  @Input() showCurrancy: boolean = true;
  currancy: any = '$';
  constructor(private container: ElementRef, private http: HttpClient, private decimalPipe: DecimalPipe) { }
  /**
 * @author: Anjit
 * @description: format the number as per notation
 * @augments: number
 * @returns: number with notation
 */
  decimalFormatter(num, digit) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1E3, symbol: 'K' },
      { value: 1E6, symbol: 'M' },
      { value: 1E9, symbol: 'B' }
    ];

    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digit) + si[i].symbol;
  }
  ngOnInit() {
  }
  ngAfterViewInit() {

  }
  ngOnChanges() {
    if (this.name != 'Service Monthly Trend'){
      this.subgroups = [this.CurrentYear - 2, this.CurrentYear - 1, this.CurrentYear];
    }
    if (this.data) {
      if (!this.showCurrancy) {
        this.currancy = '';
      } else {
        this.currancy = '$';
      }

      d3.select(this.container.nativeElement).selectAll(".my_dataviz svg g").remove();
      if (this.yearFilter) {
        // this.container.nativeElement.querySelector('.first-year').checked = false;
        //  this.container.nativeElement.querySelector('.second-year').checked = false;
      }
      if (this.data){
        // if (this.CurrentYear.toString() in (this.data[0])){
        //   this.maxKey = this.CurrentYear.toString();
        // }
        // else{
        //   this.maxKey = (this.CurrentYear - 1).toString();
        // }
    
        let currentYearKey = this.data.length > 0 && this.CurrentYear.toString() in (this.data[0]) ? this.CurrentYear.toString() : null;
        let previousYearKey = this.data.length > 0 && (this.CurrentYear - 1).toString() in (this.data[0]) ? (this.CurrentYear - 1).toString() : null;
        let initialYearKey = this.data.length > 0 && (this.CurrentYear - 2).toString() in (this.data[0]) ? (this.CurrentYear - 2).toString() : null;
        
        let currentYearMax = currentYearKey != null ? d3.max(this.data, d => d[currentYearKey]) : 0;
        let previousYearMax = previousYearKey != null ? d3.max(this.data, d => d[previousYearKey]) : 0;
        let initialYearMax = initialYearKey != null ? d3.max(this.data, d => d[initialYearKey]) : 0;

        if (!currentYearMax) currentYearMax = 0;
        if (!previousYearMax) previousYearMax = 0;
        if (!initialYearMax) initialYearMax = 0;
        
        if (currentYearMax >= previousYearMax && currentYearMax >= initialYearMax) {
          this.maxKey = currentYearKey;
        }
        else if (previousYearMax >= currentYearMax && previousYearMax >= initialYearMax) {
          this.maxKey = previousYearKey;
        }
        else {
          this.maxKey = initialYearKey;
        }
      }
      
      let maxdata = d3.max(this.data, d => d[this.maxKey]);
      if (maxdata)
        this.createchart(this.data);
    }
  }
  /**
   * @author: Anjit
   * @description: filter the chart as per the year selected
   * @augments: 3 year data array
   * @returns: svg chart
   */
  update() {
    let datum = this.data;
    let checkedvalues = [];
    let nodes = <any>this.container.nativeElement.querySelectorAll('.years');
    let checkedcount = 0;
    for (const e of nodes) {
      let key = [e.defaultValue];
      if (!e.checked) {
        checkedvalues = { ...checkedvalues, [`${key}`]: 0 };
        this.container.nativeElement.querySelector(`.sum${key}`).style.display = 'none';
        e.disabled = false;
      } else {
        checkedcount++;
        this.container.nativeElement.querySelector(`.sum${key}`).style.display = 'inline-block';
        if (checkedcount < 2) {
          e.disabled = true;
        } else {
          for (const e2 of nodes) {
            e2.disabled = false;
          }
        }

      }
    }
    datum = datum.map((p) => { return { ...p, ...checkedvalues } });

    d3.select(this.container.nativeElement).selectAll(".bar-lines g").remove();

    d3.select(this.container.nativeElement).selectAll(".bar-lines").append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(datum)
      .join("g")
      .attr("transform", d => `translate(${this.x(d.group)}, 0)`)
      .selectAll("rect")
      .data((d) => {
        return this.subgroups.map((key) => {
          return { key: key, value: d[key], month: d['group'] };
        });
      })
      .join("rect")
      .attr("y", () => {
        return this.y(0);
      }).on("mouseover", (d) => {
        let cost = this.decimalFormatter(d.value, 1);
        let html = `Year: ${d.key} <br> Month: ${d.month} <br> Cost: ${this.currancy}${cost}`;
        let tooltipDiv = d3.select(this.container.nativeElement).select(".trendtooltip");

        tooltipDiv.transition()
          .duration(200)
          .style("opacity", 1);

        tooltipDiv.html(html)
          .style('z-index', '9999')
          .style("cursor", "pointer") 
      })
      .on("mouseout", () => {

        let tooltipDiv = d3.select(this.container.nativeElement).select(".trendtooltip");
        tooltipDiv.transition()
          .duration(500)
          .style("top", 20)
          .style("left", '23vw')
          .style("opacity", 0);
      })
      .transition()
      .duration(1000)
      .attr("x", d => this.xSubgroup(d.key))
      .attr("y", d => this.y(d.value))
      .attr("width", this.xSubgroup.bandwidth())
      .attr("height", d => this.height - this.y(d.value))
      .attr("fill", d => this.color(d.key));


  }
  /**
   * @author: Anjit
   * @description: create bar chart with 3 year trend
   * @augments: 3 year data array
   * @returns: svg chart
   */
  createchart(data) {
    // set the dimensions and margins of the graph
    this.margin = { top: 5, right: 0, bottom: 15, left: 70 };

    this.width = 740 - this.margin.left - this.margin.right;
    this.height = 350 - this.margin.top - this.margin.bottom;

    // append the svg object to the body of the page
    this.svg = d3.select(this.container.nativeElement).select(".my_dataviz svg")
      //.attr("width", this.width + this.margin.left + this.margin.right)
      //.attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);

    // Parse the Data

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    this.groups = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Add X axis
    this.x = d3.scaleBand()
      .domain(this.groups)
      .range([0, this.width])
      .padding([0.1]);
    this.svg.append("g")
      .attr("transform", `translate(0, ${this.height})`)
      .call(d3.axisBottom(this.x).tickSize(0))
      .selectAll("text")
      .attr("y", 8)
      .style("text-anchor", "start");

    // Add Y axis
    this.y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d[this.maxKey])])
      .range([this.height, 0]);

    this.svg.append("g")
      .call(d3.axisLeft(this.y).tickFormat((d) => `${this.currancy}` + (this.decimalFormatter(d, 1))));

    // Another scale for subgroup position?
    this.xSubgroup = d3.scaleBand()
      .domain(this.subgroups)
      .range([0, this.x.bandwidth()])
      .padding([0.05]);

    // color palette = one color per subgroup
    this.color = d3.scaleOrdinal()
      .domain(this.subgroups)
      .range(this.barcolor);

    //Show the bars
    this.bar = this.svg.append("g")
      .attr("class", "bar-lines")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .join("g")
      .attr("transform", d => `translate(${this.x(d.group)}, 0)`)
      .selectAll("rect")
      .data((d) => {
        return this.subgroups.map(function (key) {
          return { key: key, value: d[key] };
        });
      })
      .join("rect")
      .attr("x", d => this.xSubgroup(d.key))
      .attr("y", d => this.y(d.value))
      .attr("width", this.xSubgroup.bandwidth())
      .attr("height", d => this.height - this.y(d.value))
      .attr("fill", d => this.color(d.key)).on("mouseover", (d) => {
        let cost = this.decimalFormatter(d.value, 1);
        let html = `Year:${d.key} <br> Month:${d.month} <br> Cost: ${this.currancy}${cost}`;
        let tooltipDiv = d3.select(this.container.nativeElement).select(".trendtooltip");
        tooltipDiv.transition()
          .duration(200)
          .style("top", 20)
          .style("left", '23vw')
          .style("opacity", 1);

        tooltipDiv.html(html)
          .style('z-index', '9999')
          .style("cursor", "pointer")
      })
      .on("mouseout", () => {
        let tooltipDiv = d3.select(this.container.nativeElement).select(".trendtooltip");
        tooltipDiv.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // d3.select(this.container.nativeElement).selectAll("g.tick")
    //   .append("line")
    //   .attr("class", "gridline")
    //   .attr("x1", 0)
    //   .attr("y1", 0)
    //   .attr("x2", this.width)
    //   .attr("y2", 0);


    d3.select(this.container.nativeElement).select(".my_dataviz").append("div")
      .attr("class", "trendtooltip")
      .style("opacity", 0)
      .style("top", 0);
    if (this.yearFilter) {
      this.update();
    }
  }
  sum(key) {
    if (this.data) {
      let total = 0;
      this.data.forEach(item => {
        total += item[key];
      });
      return this.decimalFormatter(total, 1);
    } else {
      return 0;
    }
  }
}
