<div class="uitk-l-grid__col uitk-l-grid__row main-menu-position filter-sec" style="padding-top: 0;
padding-bottom: 0;
margin-top: 5px;">
    <div id="tp-body-section">
        <ng-container *ngFor="let filter of COMMON_FILTERS_LIST">
            <ng-container *ngIf="filter.isActive && filter.type =='single-select'">
                <section class="tp-section">
                    <mat-form-field appearance="outline">
                        <mat-label>{{filter.name}}</mat-label>
                        <mat-select (keydown)="$event.stopPropagation()" class="virtual-scroll"
                            (selectionChange)="onChangeFilter(filter)" [(ngModel)]=" filter.value"
                            [disabled]="filter.disable" panelClass="labor-hub-class">
                            <mat-option *ngFor="let option of filter.options" [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </section>
            </ng-container>

            <ng-container *ngIf="filter.isActive && filter.type =='select'">
                <!-- //New Logic -->
                <section class="tp-section">
                    <mat-form-field appearance="outline">
                        <mat-label>{{filter.name}}</mat-label>
                        <ng-container *ngIf="filter.virtualScroll;  else normalBlock">
                            <mat-select (keydown)="$event.stopPropagation()" class="virtual-scroll"
                                (selectionChange)="selectItem($event,filter)"
                                (openedChange)="openedChange(filter,$event,viewPort1)" [(ngModel)]="filter.value"
                                multiple [disabled]="filter.disable" panelClass="labor-hub-class">
                                <input (keydown)="$event.stopPropagation()" matInput type="text" class="searchoption"
                                    [(ngModel)]="filter.search" placeholder="Search" />

                                <cdk-virtual-scroll-viewport [itemSize]="8" minBufferPx="200"
                                    (scrolledIndexChange)="scrolledIndexChange($event,filter)" maxBufferPx="400"
                                    [style.height.px]="8 * 28">
                                    <mat-option (onSelectionChange)="toggleAllSelection($event,filter)" value="All">
                                        All
                                    </mat-option>
                                    <mat-option *cdkVirtualFor="let option of filter.options | filter: filter.search"
                                        (onSelectionChange)="optionSelection($event,filter)" selected="true"
                                        [title]="option.value" [value]="option.value">
                                        {{ option.label }}
                                    </mat-option>
                                </cdk-virtual-scroll-viewport>
                            </mat-select>
                        </ng-container>

                        <ng-template #normalBlock>
                            <mat-select (keydown)="$event.stopPropagation()" class="virtual-scroll"
                                (openedChange)="openedChange(filter,$event,viewPort1)"
                                (selectionChange)="selectItem($event,filter)" [(ngModel)]="filter.value" multiple
                                [disabled]="filter.disable" panelClass="labor-hub-class">
                                <input (keydown)="$event.stopPropagation()" matInput type="text" class="searchoption"
                                    [(ngModel)]="filter.search" placeholder="Search" />

                                <mat-option (onSelectionChange)="toggleAllSelection($event,filter)" value="All">
                                    All
                                </mat-option>
                                <mat-option *ngFor="let option of filter.options | filter: filter.search"
                                    (onSelectionChange)="optionSelection($event,filter)" [value]="option.value">
                                    {{ option.label }}
                                </mat-option>
                            </mat-select>
                        </ng-template>
                    </mat-form-field>
                </section>
            </ng-container>
            <ng-container *ngIf="filter.isActive && filter.type =='date'">
                <section class="tp-section date-filter-sec">
                    <!-- //start date datepicker -->
                    <mat-form-field class="from-date-picker" appearance="outline">
                        <mat-label>{{PAGE=='fteview' ? 'From Month' : 'From Date'}}</mat-label>
                        <!-- min:{{filter.minDate}}
                        max:{{filter.maxDate}} -->
                        <input disabled matInput [min]="filter.minDate" [max]="filter.maxDate"
                            [(ngModel)]="filter.value.begin" [matDatepicker]="picker1" placeholder="From Date">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker disabled="false"
                            (monthSelected)="chosenMonthHandler($event, picker1,filter,'from')" #picker1
                            startView="year" [startAt]="filter.value.begin"></mat-datepicker>
                    </mat-form-field>
                    <!-- //end date datepicker -->
                    <mat-form-field class="to-date-picker" appearance="outline">
                        <mat-label>{{PAGE=='fteview' ? 'To Month' : 'To Date'}}</mat-label>
                        <input disabled matInput [min]="filter.value.begin ? filter.value.begin : filter.minDate"
                            [max]="filter.maxDate" [(ngModel)]="filter.value.end" [matDatepicker]="picker2"
                            placeholder="To Date">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker disabled="false"
                            (monthSelected)="chosenMonthHandler($event, picker2,filter,'to')" #picker2 startView="year"
                            [startAt]="filter.value.end"></mat-datepicker>
                    </mat-form-field>
                </section>
            </ng-container>
            <ng-container *ngIf="filter.isActive && filter.type =='checkbox'">
                <section class="tp-section checkbox-filter-sec">

                    <mat-checkbox color="primary" class="example-margin" [(ngModel)]="filter.value">
                        {{filter.name}}
                    </mat-checkbox>

                </section>
            </ng-container>
        </ng-container>

        <section class="tp-section tp-section-button filter-footer">
            <button mat-mdc-raised-button 
                class="searchbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                (click)="getApplicationOfSelected()" [disabled]="!appliedFilters().enabled">
                Search
            </button>
            <!-- <button mat-mdc-raised-button 
                class="searchbuttonClass uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                (click)="resetAppFilters()">
                Reset
            </button> -->
            <button mat-mdc-raised-button 
                class="searchbuttonClass m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
                (click)="resetAppFiltersNew()">
                Reset
            </button>
        </section>
    </div>
</div>