import { environment } from '../../environments/environment';

export let apphubV4APIs = {

  environment: environment.environmentName,

  envApiUrl: environment.apiUrl,
  routerPath: {
    paduRoadmap: '/v4/apphub/paducaps/padu-roadMap',
    associatedCapabilities: '/v4/apphub/paducaps/capability-Details',
    capabilityApplications: '/v4/apphub/paducaps/capability',
    mastercardDetails: '/v4/apphub/master-card-details',
    applicationwithcapabilityPADU: '/v4/apphub/paducaps/applicationswithCapabilityPADU',
    paduandroadmapupdate: '/v4/apphub/paducaps/update-padu-and-roadmap',
    appsonSearch: '/v4/apphub/app-search',
    appsForEditWorkqueues: '/v4/apphub/app-search-edit',
    updateDemographicDetails: '/v4/apphub/update-attribute-details',
    getArchitectDetails: '/v4/apphub/architects',
    applicationRelationshipSummary: '/v4/apphub/applicationRelationships-Summary',
    applicationHealthServiceSummary: '/v4/apphub/applicationHealthService-Summary',
    applicationRoadMapSummary: '/v4/apphub/applicationRoadMap-Summary',
    applicationCostViewSummary: '/v4/apphub/applicationCostView-Summary',
    applicationTechPaduSummary: '/v4/apphub/applicationTechPadu-Summary',
    applicationPlmSummary: '/v4/apphub/applicationplm-Summary',
    applicationTechPaduSummay: '/v4/apphub/applicationTechPadu-Summary',
    getcostdata: '/v4/apphub/cost/costView-details',
    specificAppPLMData: '/v4/apphub/techpaduplm/plm-details',
    // editCaps: '/v4/apphub/paducaps/edit-capability',
    addcaps: '/v4/apphub/paducaps/add-capability',
    getCapabilityHierarchy: '/v4/apphub/paducaps/getCapabilityHierarchy',
    primaryUpdate: '/v4/apphub/paducaps/appCapprimaryChange',
    infraCostViewDetails: '/v4/apphub/cost/infracostView-details',
    getTcoCostViewDetails: "/v4/apphub/cost/tcocostView-details",
    modelListV1: '/v4/apphub/paducaps/modelListV1',
    getOrgList: '/v4/apphub/paducaps/org',
    editCaps: '/v4/apphub/paducaps/edit-capability',
    mergeCaps: '/v4/apphub/paducaps/merge-capability',
    deleteApplicationOrganizationAssociation: '/v4/apphub/paducaps/appCapabilityAssociationRemove',
    infraServiceDetails: '/v4/apphub/cost/costViewInfraService-details',
    applicationBPAAssetsSummay: '/v4/apphub/applicationBPAAssets-Summary',
    costViewInfraServiceMonthlyTrend: '/v4/apphub/cost/costViewInfraServiceMonthlyTrend-details',
    costViewInfraServiceSUI: '/v4/apphub/cost/costViewInfraServiceSUI-details',
    addcomment: '/v4/apphub/cost/addcomment',
    updatecomment: '/v4/apphub/cost/updatecomment',
    deletecomment: '/v4/apphub/cost/deletecomment',
    getcomments: '/v4/apphub/cost/getcomments',
    facilitatorMail: '/v4/apphub/cost/facilitatorMail',
    getPlatformDetails: '/v4/apphub/relations/applicationRelationships-platform-details',
    getBusinessProducts: '/v4/pim/getTaxonomyDetails',
    getApplicationAttributes: '/v4/apphub/application-attributes',
    getInitiativeList: '/v4/configurable/list'
  }

}

export let portfoliohubV4APIs = {

  environment: environment.environmentName,

  envApiUrl: environment.apiUrl,
  routerPath: {
    getOwners: '/v4/portfoliohubv4/filterownerlists',
    getSegments: '/v4/portfoliohubv4/filtersegmentlists',
    getGroups: '/v4/portfoliohubv4/filtergrouplists',
    getPADUSummary: '/v4/portfoliohubv4/padusummary',
    getdecommsummary: '/v4/portfoliohubv4/summary/decomoverview',
    getapplisttabledata: '/v4/portfoliohubv4/padu/padu-ApplicationsList',
    getapplisttabledatacount: '/v4/portfoliohubv4/padu/padu-ApplicationsList-count',
    getCategoryDetails: '/v4/portfoliohubv4/padu/padu-Apps',
    getPaduKpiDetails: '/v4/portfoliohubv4/padu/padu-kpi',
    getPADUYOY: '/v4/portfoliohubv4/padu//padu-yoy',
    getAppPaduYoy: '/v4/portfoliohubv4/padu/app-padu-yoy',
    getInfraDetails: '/v4/portfoliohubv4/cost/costInfra',
    getTcoDetails: '/v4/portfoliohubv4/cost/costTco',
    getTcoExpansionDetails: '/v4/portfoliohubv4/cost/costTCOExpansion',
    getTcmExpansionDetails: '/v4/portfoliohubv4/cost/costTCMExpansion',
    tcotcmExpansiontabledatacount: '/v4/portfoliohubv4/cost/expansionCount',
    getPaduCostDetails: '/v4/portfoliohubv4/padu/padu-Cost',
    getPaduKpiNoPrimary:'/v4/portfoliohubv4/padu/padu-kpiNoPrimary',


    getInfraTableCount:'/v4/portfoliohubv4/cost/expansionCount',
    getInfraTableData:'/v4/portfoliohubv4/cost/costInfraExpansion',
    getExportToExcelBlobinfra:'/v4/portfoliohubv4/cost/padu-infra-expot-to-excel',
    getExportToExcelBlobtco:'/v4/portfoliohubv4/cost/padu-tco-expot-to-excel',

    getRelationshipsOverView: '/v4/portfoliohubv4/summary/RelationshipsOverView',
    getLaborMonthlyTrend:'/v4/portfoliohubv4/cost/costLaborMonthlyTrend',

    getCostInfraMonthlyTrend:'/v4/portfoliohubv4/cost/costInfraMonthlyTrend',
    costTcoMonthlyTrend: '/v4/portfoliohubv4/cost/costTcoMonthlyTrend',
    getExportToExcelBlob:'/v4/portfoliohubv4/padu/padu-ApplicationsList-expot-to-excel',

    getCostMonthlyTrend: '/v4/portfoliohubv4/cost/costMonthlyTrend',
    getExportToExceltcm:'/v4/portfoliohubv4/cost/costTcmExporttoExcel',
    getServiceInfraTableData:'/v4/portfoliohubv4/cost/costServiceInfraExpansion',
    getExportToExcelBlobinfraService:'/v4/portfoliohubv4/cost/padu-infra-service-expot-to-excel',
    getcapsdataservice:'/v4/portfoliohubv4/capability/leveldata',
    getcapsdataapss:'/v4/portfoliohubv4/capability/appList',
    getAppsByCapId: '/v4/portfoliohubv4/capability/applicationlist',
    getPaduMatrics:'/v4/portfoliohubv4/padu/app-padu-assessed',
    getLeadersList: '/v4/PorfolioHubV4/leaders/leader-search',
    getLeaderHierarchy: '/v4/PorfolioHubV4/leaders/leader-heigharchy-apps',
  }

}

export let TechnologyAPIs = {
  environment: environment.environmentName,
  envApiUrl: environment.apiUrl,
  routerPath: {
    getTechnologyDetails: '/techHub/technologies/search/'
  }
}

export let maintenanceV4APIs = {
  deleteSubLob: '/v4/lobmaintenance/lob/delete',
  addLob: '/v4/lobmaintenance/lob',
  checkLob: '/v4/lobmaintenance/lobbyName',
  bulklob: '/v4/lobmaintenance/bulk/edit',
  lobLeaderDecom: '/v4/lobmaintenance/lobLeaderDecom',
  saveLobLeaderDecom: '/v4/lobmaintenance/saveLobLeadersMapping',
  fetCapitalDemandList: '/v4/maintenance/capital-demand',
  fetchCapitalDemandInvestmentList: '/v4/maintenance/capital-demand/investment-list',
  fetchCIOMappingList: '/v4/lobmaintenance/nested-listing-cio',
  addRemoveUsers : '/v4/lobmaintenance/add-remove-lob-architect',
  getDecomLobAccess: '/v4/decomlob/getDecomLobAccess',
  getDecomLobDetails : '/v4/decomlob/decomDetails',
  getDecomLobMasterMapping : '/v4/decomlob/lobDecomMaster',
  updateDecomLob: '/v4/decomlob/updateDecomLob',
  fcServiceListUrl : "/v4/forecastmanagement/service-details",
  getForecastFilters: '/v4/forecastmanagement/getFilters',
  getForecastDetails: '/v4/forecastmanagement/getForecastDetails',
  updateFCurl : '/v4/forecastmanagement/service-details',
  getForecastMasterData: '/v4/forecastmanagement/forecast-master',
  getServicesList: '/v4/forecastmanagement/getServicesList',
  addService: '/v4/forecastmanagement/addService',
  getMasterCardStats: '/v4/forecastmanagement/mastercardStats',
  getApplicationStats: '/v4/forecastmanagement/getApplicationStats',
  getForecacastData: '/v4/forecastmanagement/forecast-values',
  addForecast: '/v4/forecastmanagement//addForecast',
  updateForecastMaster: '/v4/forecastmanagement//updateForecastMaster',
  getFieldData : '/v4/maintenance/enterprise-initiatives/column-master',
  updateColumnOrder : '/v4/maintenance/enterprise-initiatives',
  updateField: '/v4/configurable/update-columns',
  getAllUsedWords : '/v4/maintenance/enterprise-initiatives/getUsedValues'
}

export let optimizeAPIs = {
    getKpiData: '/v3/tcm-optimize/getoptimizekpi',
    getOptimizeData: '/v3/tcm-optimize/getoptimzedetails',
    getoptimizedapp: '/v3/tcm-optimize/getoptimizedappDetails',
    neutralizeflag: '/v3/tcm-optimize/neutralizeflag',
    bulkneutralizeflag: '/v3/tcm-optimize/bulkneutralizeflag',
    flagAccess: '/v3/tcm-optimize/getaccessFlag',
    flagAccessData:'/v3/tcm-optimize/getaccessFlagData',
    addflagAccessData: '/v3/tcm-optimize/addAccessFlagPageData',
    updatedMonth: '/v3/tcm-app-view/getYearmonth',
    yearMonth: '/v3/tcm-app-view/getYearmonth'
}

export let workQueuesAPIs = {
  routerPath: {
    updateLobRequest : '/v4/wqlob/WQ_lob_insert',
    getWorkqueueReqDetails: '/v4/wqlob/WQ_request_details',
    getWQRequests: '/v4/wqlob/WQ_request_list',
    getRequests: '/v4/wqlob//WQ_lists',
    getWorkQueueAccess: '/v4/wqlob/WQ_access_details',
    getOpenRequestDetailsForAnApp: '/v4/wqlob/WQ_application_open_requests',
    cancelOpenRequest: '/v4/wqlob/WQ_lob_update',
    UpdateOpenWQRequest: '/v4/wqlob/WQ_lob_update',
    updateBulkOpenWQRequest: '/v4/wqlob/Bulk_WQ_lob_update',
    getDashboardWorkqueue:'/v4/wqlob/WQ_dashboard',
    updatePaduUpdateRequest: '/v4/wqlob/WQ_padu_update',
    insertPADUCreation: "/v4/wqlob/WQ_padu_insert",
    getAssociatedCapability: '/v4/wqlob/WQ_cap_open_requests',
    addUpdateWorkqueueCapability:'/v4/wqlob/WQ_cap_submit',
    updateCapWorkQue: '/v4/wqlob/WQ_Cap_Update'
  }
}

export let configurableAttributesAPIs = {
  getInitiativeDetails : '/v4/configurable/initiatives',
  updateInitiative: '/v4/configurable/update-initiative',
  enableFieldUrl: '/v4/configurable/enabled-columns'
}
