import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { SharedService } from '../shared';

export class DynamicContentModel {
  id: number;
  PageName: string;
  Catagory: string;
  Content: string;
  Occurance: number;
  visibility: string;
  icon: string;
  Description: string;
  SubTitle: string;
  CostData: string;
  Role_Visibility: string;
  Url_Path: string;
  Audience: string;
}
@Component({
  selector: 'app-landing-page-v4',
  templateUrl: './landing-page-v4.component.html',
  styleUrls: ['./landing-page-v4.component.css']
})
export class LandingPageV4Component implements OnInit {
  public dynamicContentData : any = {}; 
  constructor( private route: Router,private sharedService: SharedService) { 
    const returnUrl = localStorage.getItem('returnUrl') || null;
    if (Cookie.get('codeCreated')!='') {
      Cookie.delete('codeCreated');
      if (returnUrl) {
        localStorage.removeItem('returnUrl'); 
        setTimeout(() => {
          this.route.navigateByUrl(returnUrl);
      }, 300);
        }
     }
  }

  ngOnInit(): void {
    localStorage.removeItem('groupList');
    localStorage.removeItem('segmentList');
    localStorage.removeItem('sublob');
    this.getDynamicContentForLandingPage();
  }

  getDynamicContentForLandingPage() {
      let reqBody = {
        "PageName": "Landing Page"
      }
      this.sharedService.getDynamicContent(reqBody).subscribe({
        next: data => {
          if(data) {
            if(localStorage.getItem('landingPageContent') != JSON.stringify(data)) {
              localStorage.setItem('landingPageContent',JSON.stringify(data));
              this.dynamicContentData = JSON.parse(localStorage.getItem('landingPageContent'));
            } else {
              this.dynamicContentData = JSON.parse(localStorage.getItem('landingPageContent'));
            }
          }
        },
        error: err => {
          console.log("Error while loading data", err);
        }
      })
    
    
  }

}
