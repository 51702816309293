import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  public showSpinnerSubject: Subject<boolean> = new Subject<boolean>();

  public show() {
    this.showSpinnerSubject.next(true);
  }

  public hide() {
    this.showSpinnerSubject.next(false);
  }
}
