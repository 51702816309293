import { Injectable } from '@angular/core';
import { tcmHubConfigDetails } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class OptimizeService {
  private apiUrl: string;
  private config: any;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*'
    })
  };
  constructor(private http: HttpClient) {
    this.config = tcmHubConfigDetails;
    this.apiUrl = environment.apiUrl;
  }
  public kpidetailsapi(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getKpiData}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public optimizeData(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.getOptimizeData}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public neutralizeFlag(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.neutralizeflag}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public bulkneutralizeFlag(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.bulkneutralizeflag}`;
    return this.http.post(apiUrl, body, this.httpOptions).toPromise();
  }
  public getAccessFlag(body) {
   
      const apiUrl = `${this.apiUrl}/${this.config.routerpath.flagAccess}`;
      const configList = this.http.post(apiUrl, body).pipe(
        map(res => {
          localStorage.setItem('flagAccess', JSON.stringify(res));
          return res;
        })
      );
      return configList;
  }
  public getAccessFlagData(body) {
      const apiUrl = `${this.apiUrl}/${this.config.routerpath.flagAccessData}`;
      const configList = this.http.post(apiUrl, body).pipe(
        // map(res => {
        //   localStorage.setItem('flagAccess', JSON.stringify(res));
        //   return res;
        // })
      );
      return configList;
  }
  public addAccessFlagData(body) {
    const apiUrl = `${this.apiUrl}/${this.config.routerpath.addflagAccessData}`;
    const configList = this.http.post(apiUrl, body);
    return configList;
}
  public getReclassAccess(body) {
    if (!localStorage.getItem('reclassAccess')) {
      const apiUrl = `${this.apiUrl}/${this.config.routerpath.flagAccess}`;
      const configList = this.http.post(apiUrl, body).pipe(
        map(res => {
          localStorage.setItem('reclassAccess', JSON.stringify(res));
          return res;
        })
      );
      return configList;
    } else {
      return of(JSON.parse(localStorage.getItem('flagAccess')));
    }
  }
  public getCurrentYear() {
    let config = JSON.parse(localStorage.getItem('config'));
    if (!(config && config.currentYear > 0)) {
      const apiUrl = `${this.apiUrl}/v3/tcm-app-view/getYearmonth`;
      const configList = this.http.get(apiUrl).pipe(
        map(res => {
          localStorage.setItem('config', JSON.stringify(res));
          return res;
        })
      );
      return configList;
    } else {
      return of(JSON.parse(localStorage.getItem('config')));
    }
  }
  public getUpdatedMonth() {
    const apiUrl = `${this.apiUrl}/v3/tcm-app-view/getYearmonth`;
    const configList = this.http.get(apiUrl).pipe(
      map(res => {
        return res;
      })
    );
    return configList;
  }
  public getCurrentPath(path) {
    if (path == '') {
      let config = JSON.parse(localStorage.getItem('config'));
      if (config) {
        if (!config.avialableData) {
          this.getUpdatedMonth().subscribe((res: any) => {
            localStorage.setItem('config', JSON.stringify(res));
          });
        }
      }
    }
    switch (path) {
      case 'landing-page':
        this.updateConfig();
        break;
      case 'service(sidebar:tcmhub)':
        this.updateConfig();
        break;
      case 'optimization(sidebar:optimization)':
        this.updateConfig();
        break;
    }
  }
  public async updateConfig() {
    
    let config = JSON.parse(localStorage.getItem('config'));
    if (!config) {
      this.getUpdatedMonth().subscribe((res: any) => {
        localStorage.setItem('config', JSON.stringify(res));
        window.location.reload();
      });
    } else {
      this.getUpdatedMonth().subscribe((res: any) => {
        if (config.currentMonth && res.currentMonth != config.currentMonth) {
          localStorage.setItem('config', JSON.stringify(res));
          const modal = document.getElementById('DataChangeModal');
          modal.style.display = 'block';
        }else{
          const modal = document.getElementById('DataChangeModal');
          modal.style.display = 'none';
        }
      });
    }
  }
}
