<div class="maintenance-table-container">
    <div class="table-container" style="overflow-x: scroll;" [ngClass]="TABLE_CONFIG['TABLE_CLASS']">


        <table style="min-width: 130%;" mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort class="mat-elevation-z8"
            multiTemplateDataRows>

            <!-- Checkbox Column -->
            <ng-container matColumnDef="cehckbox">
                <!-- <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th> -->
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!row.isNew">
                        <!-- <mat-checkbox  (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null" (change)="$event ? selecttoggle($event, row) : null"
                        [checked]="selection.isSelected(row)">
                      </mat-checkbox> -->
                    </ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="col.key" *ngFor="let col of COLUMNS">
                <ng-container *ngIf="col.key === 'checkbox' && col.page === 'checkboxEnabledPage'; 
                                else defaultHeaderColumn">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!row.isNew">
                            <mat-checkbox  (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" (change)="$event ? selecttoggle() : null"
                            [checked]="selection.isSelected(row)">
                          </mat-checkbox>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-template #defaultHeaderColumn>
                    <th mat-sort-header mat-header-cell *matHeaderCellDef>

                        {{col['label']}}
                        <span *ngIf="col.filter" [matMenuTriggerFor]="menu">
                            <img *ngIf="!col.searchValue" class="stkh-table-filter-img"
                                src="../../../../assets/images/table_filter.png">
    
                            <img *ngIf="col.searchValue" class="stkh-table-filter-img"
                                src="../../../../assets/images/table_filter_applied.png">
    
                        </span>
                        <mat-menu #menu>
                            <div class="menu-filter-popup" (click)="$event.stopPropagation()"
                                (keydown)="$event.stopPropagation()">
                                <ng-container [ngSwitch]="dataSchema[col.key]">
                                    <ng-container *ngSwitchCase="'select'">
                                        <input matInput type="text" class="searchoption"
                                            (keydown)="$event.stopPropagation()" class="form-control filter-search-bar"
                                            [(ngModel)]="col.search" placeholder="Search" />
                                        <mat-selection-list [disableRipple]="true" class="filter-list-container"
                                            [(ngModel)]="col.searchValue">
                                            <mat-list-option checkboxPosition="before"
                                                *ngFor="let option of getFiltersList(col,'filter') | filter:col.search"
                                                [value]="option.key">
                                                {{ option.value }}</mat-list-option>
                                        </mat-selection-list>
                                        <span style="cursor:pointer; float: right;padding-right: 21px;"
                                            class="localApplyButton" (click)="applyLocalFilter(col)">Apply</span>
                                        <span style="cursor:pointer; float: right;padding-right: 21px;"
                                            class="localApplyButton" (click)="clearLocalFilter(col)">Clear</span>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </mat-menu>
                    </th>
                </ng-template>
                
                <ng-container *ngIf="dataSource?.data.length > 0">

                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.isEdit">
                            <div class="btn-edit" *ngIf="col.key === 'isEdit'; else dataField">
                                <span class="save-screen-icon" title="save" (click)="saveRecord(element)">
                                    <mat-icon class="icon-crud">save</mat-icon>
                                </span>
                                <span class="cancel-screen-icon" title="cancel" (click)="deleteRecord(element);">
                                    <mat-icon class="icon-crud">cancel</mat-icon>
                                </span>
                                <span class="cancel-screen-icon" *ngIf="TABLE_CONFIG && TABLE_CONFIG?.deleteFeature" title="delete" (click)="removeRecord(element);">
                                    <mat-icon class="icon-crud">delete</mat-icon>
                                </span>
                            </div>
                            <ng-template #dataField>
                                <ng-container *ngIf="col.edit || element.isNew;else defaultViewColumn">

                                    <ng-container [ngSwitch]="dataSchema[col.key]">
                                        <ng-container *ngSwitchCase="'select'">
                                            <mat-form-field [ngClass]="col.key " class="inline-form-edit"
                                                floatLabel="never">
                                                <ng-container *ngIf="element.isNew">

                                                    <mat-select
                                                        placeholder="Select {{col.label}} {{getRequiredString(col)}}"
                                                        [(ngModel)]="element[col.labelKey ? col.labelKey : col.key]"
                                                        (selectionChange)="onChangeInputField(col,$event,element)">

                                                        <mat-option class="create-new-option"
                                                            *ngIf="checkCondition(col,element)"
                                                            (click)="createNew(col,element)" value="new">
                                                            Create New {{col.label}}
                                                        </mat-option>
                                                        <input matInput type="text" (keyup)="$event.stopPropagation()"
                                                            (keydown)="$event.stopPropagation()"
                                                            class="form-control filter-search-bar lob-search"
                                                            [(ngModel)]="col.search" placeholder="Search" />
                                                        <mat-option
                                                            *ngFor="let option of getFiltersList(col,'',element) | filter:col.search"
                                                            [value]="option" [disabled]="option.disable">

                                                            {{option.value}}

                                                        </mat-option>
                                                    </mat-select>
                                                </ng-container>

                                                <ng-container *ngIf="!element.isNew">
                                        
                                                    <ng-container
                                                        *ngIf=" ((!element['addsublob'] && col.key=='SubLineofBusiness' ) || (col.key!='SubLineofBusiness'))  &&  element[col.labelKey]">
                                                        <input [(ngModel)]="element[col.key]" matInput type="text"
                                                            class="form-edit-field"
                                                            placeholder="{{col.label}} {{getRequiredString(col)}}">                   
                                                    </ng-container>
                                                   
                                                    <ng-container
                                                        *ngIf="!element[col.labelKey] || (element['addsublob'] && col.key=='SubLineofBusiness')">
                                                        <mat-select
                                                            placeholder="Select {{col.label}} {{getRequiredString(col)}}"
                                                            [(ngModel)]="element[col.labelKey ? col.labelKey : col.key]"
                                                            (selectionChange)="onChangeInputField(col,$event,element)">

                                                            <mat-option class="create-new-option"
                                                                *ngIf="checkCondition(col,element)"
                                                                (click)="createNew(col,element)" value="new">
                                                                Create New {{col.label}}
                                                            </mat-option>
                                                            <input matInput type="text"
                                                                (keyup)="$event.stopPropagation()"
                                                                (keydown)="$event.stopPropagation()"
                                                                class="form-control filter-search-bar lob-search"
                                                                [(ngModel)]="col.search" placeholder="Search" />
                                                            <mat-option
                                                                *ngFor="let option of getFiltersList(col,'',element) | filter:col.search"
                                                                [value]="option" [disabled]="option.disable">
                                                                {{option.value}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </ng-container>

                                                </ng-container>
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container class="example-chip-list" *ngSwitchCase="'autocomplete'">
                                            <mat-form-field [ngClass]="col.key " class="inline-form-edit"
                                                floatLabel="never">
                                                <mat-chip-grid #chipList>
                                                    <mat-chip-row class="stk-mat-chip"
                                                        *ngFor="let option of element[col.key]"
                                                        (removed)="remove(option,element,col)">
                                                        <span title="{{option.msid}}" style="cursor: pointer;">
                                                            {{option.givenName}} {{option.sn}}
                                                            </span>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip-row>
                                                </mat-chip-grid>     
                                            </mat-form-field>
                                            <input style = "padding-bottom: 1.2rem;" placeholder="Add {{col.label}} {{getRequiredString(col)}}"
                                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                                    (keyup)="keyUpSearch($event,col)"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            <mat-autocomplete showPanel=true class="lob-options-list"
                                                    #auto="matAutocomplete"
                                                    (optionSelected)="selected($event,col,element)">
                                                    <mat-option *ngFor="let option of col.options"
                                                        title="{{option.key}}<{{option.name}}>" [value]="option">
                                                        {{option.key}}<{{option.name}}>  
                                                    </mat-option>
                                                </mat-autocomplete>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="'checkbox'">
                                            <mat-checkbox class="example-margin" [disabled]="true"></mat-checkbox>
                                        </ng-container>

                                        <ng-container *ngSwitchDefault>
                                           
                                            <mat-form-field *ngIf="col.key === 'org_name'" [ngClass]="col.key" class="inline-form-edit"
                                                floatLabel="never">
                                                <input  
                                                    placeholder="{{col.key === 'org_name' ?'Enter Organization': col.key }}"
                                                    [type]="dataSchema[col.key]" matInput
                                                    [(ngModel)]="element[col.key]">                           
                                            </mat-form-field>
                                            <span *ngIf="col.key === 'org_id'">
                                                {{element[col.key]}}
                                            </span>
                                           
                                        </ng-container>
                                    </ng-container>

                                </ng-container>
                                <ng-template #defaultViewColumn>
                                    {{element[col.key]}}
                                </ng-template>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="!element.isEdit">
                            <div [ngSwitch]="col.key">
                                <div class="btn-edit" *ngSwitchCase="'isEdit'">
                                    <div *ngIf="col.tabName === 'EditProductMaintenance'; else defaultValue">
                                        <div *ngIf="pageName === 'ProductMaintenance'">
                                            <span class="edit-screen-icon" >
                                                <mat-icon class="icon-crud">edit</mat-icon>
                                            </span>
                                        </div>
                                        <div *ngIf="pageName === 'ProductFamilyMaintenance'">
                                            <span class="edit-screen-icon" >
                                                <mat-icon class="icon-crud">edit</mat-icon>
                                            </span>
                                        </div>
                                        <div *ngIf="pageName === 'ProductLineMaintenance'">
                                            <span class="edit-screen-icon" >
                                                <mat-icon class="icon-crud">edit</mat-icon>
                                            </span>
                                        </div>
                                        <div *ngIf="pageName === 'ProductTypeMaintenance'">
                                            <span class="edit-screen-icon" >
                                                <mat-icon class="icon-crud">edit</mat-icon>
                                            </span>
                                        </div>
                                    </div>

                                    <ng-template #defaultValue>
                                        <span class="edit-screen-icon" (click)="enableEditView(element)">
                                            <mat-icon class="icon-crud">edit</mat-icon>
                                        </span>
                                    </ng-template>
                                    
                                </div>

                                <span *ngSwitchCase="'ChiefArchitect'">

                                    <mat-chip-listbox>
                                        <mat-chip-option class="stk-mat-chip-view" *ngFor="let option of element[col.key]">
                                             <span title="{{option.msid}}" style="cursor: pointer;">
                                            {{option.givenName}} {{option.sn}}
                                            </span>
                                        </mat-chip-option>
                                    </mat-chip-listbox>
                                </span>
                                <span *ngSwitchCase="'ArchitectDelegate'">
                                    <mat-chip-listbox>
                                        
                                        <mat-chip-option class="stk-mat-chip-view" *ngFor="let option of element[col.key]">
                                            <span title="{{option.msid}}" style="cursor: pointer;">
                                            {{option.givenName}} {{option.sn}}
                                            </span>
                                        </mat-chip-option>
                                        
                                    </mat-chip-listbox>
                                </span>
                                <span *ngSwitchCase="'productLeadList'">
                                    <ng-container>
                                        <div class="stk-mat-chip-view-lead">
                                            <div *ngFor="let option of element[col.key]">{{option.name}}</div>
                                        </div>
                                    </ng-container>
                                </span>
                                <span *ngSwitchCase="'engineeringLeadList'">
                                    <ng-container> 
                                        <div class="stk-mat-chip-view-lead">
                                            <div *ngFor="let option of element[col.key]">{{option.name}}</div>
                                        </div>
                                    </ng-container>
                                </span>
                                <span *ngSwitchCase="'productPortfolioLeadList'">
                                    <ng-container>
                                        <div class="stk-mat-chip-view-lead">
                                            <div *ngFor="let option of element[col.key]">{{option.name}}</div>
                                        </div>
                                    </ng-container>
                                </span>
                                <ng-container *ngSwitchCase="'all-checkbox'">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(element) : null"
                                        (change)="$event ? selecttoggle() : null"
                                        [checked]="selection.isSelected(element)">
                                    </mat-checkbox>

                                </ng-container>
                                <span *ngSwitchDefault>
                                    <ng-container *ngIf="col.link; else defaultCon">
                                        <a [href]="getNavigationLink(col,element)" target="blank" class="link-click">
                                            {{element[col.key]}}
                                        </a>
                                    </ng-container>
                                    <ng-template #defaultCon>
                                        {{element[col.key]}}
                                    </ng-template>
                                </span>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <ng-container matColumnDef="nodata">
                <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length" style="text-align: center;">
                    <div class="no-records-found">
                        <div class="img-align">
                            <img class="image-align" src="/assets/images/warning-icon.svg" />
                        </div>
                        <div class=" data-title">
                            <div class="errorTitle">No Data Available</div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-footer-row [hidden]="dataSource && dataSource.data.length >0" *matFooterRowDef="['nodata']">
            </tr>

            <ng-container *ngIf="TABLE_CONFIG.expandable">
                <tr mat-row *matRowDef="let elements; columns: ['paduexpandedDetail'];" class="example-detail-row"></tr>
            </ng-container>
        </table>
        <ng-container *ngIf="dataSource && dataSource.data.length">
            <ng-container *ngIf="TABLE_CONFIG && TABLE_CONFIG.serverSidePagination; else paginationBlock">
                <mat-paginator style="height:30px; border-radius: 20px; font-size: 20px !important; margin-right: 5px;"
                    #pagination [pageIndex]="pageIndex" (page)="fetchMoreItems($event)" [length]="dataTotalItems"
                    [pageSizeOptions]="[10,20,40,50]" [pageSize]="pageSize" class="tech-table-pagination"
                    showFirstLastButtons>
                </mat-paginator>
            </ng-container>
            <ng-template #paginationBlock>
                <mat-paginator #pagination [pageSize]="TABLE_CONFIG.pageSize" showFirstLastButtons hidePageSize>
                </mat-paginator>
            </ng-template>

        </ng-container>

    </div>
</div>
