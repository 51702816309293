<div class="release-notes-v4">
    <div class="container  main-div-with-white-bkg  no-border-card">
        <ng-container *ngIf="!showLoader">
            <div class="row">
                <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                    <mat-card appearance="outlined" class="card-body release-content ">
                        <div class="row">
                            <div class="col-6">
                                <span class="release-main-title tabl-header release-notes-heading">Release Notes</span>
                            </div>
                            <div class="col-6 info-btn-col">
                                <button [attr.Pixel-tracking]="true" [attr.Pixel-title]="'View Previous Releases'" class="release-button m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"(click)="showHideNotes()">{{releaseInfoText}}</button>
                            </div>
                        </div>

                        <mat-card-content class="release-note-content">
                            <div class="release-note-info">
                                <div class="release-date-div"><span class="tabl-header tabl-header-font">Release
                                        Date : </span> <span>{{currentReleaseNote.releaseDate}}</span>
                                </div>
                                <div class="release-features-div">
                                    <!-- <span class="tabl-header tabl-header-font">Features :</span> -->
                                    <ul class="list-content" style="font-weight: bold;">
                                        <li *ngFor="let notes of currentReleaseNote.titlePointsMap | keyvalue" class="li-padding">
                                            <span>{{notes.key}}</span>
                                            <ul class="description-list">
                                                <li *ngFor="let desc of notes.value" class="margin-15">{{desc}}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="release-note-info" *ngIf="viewPreviousReleases">
                                <div class="release-date-div"><span class="tabl-header tabl-header-font">Release
                                        Date : </span> <span>{{previousReleaseNote1.releaseDate}}</span>
                                </div>
                                <div class="release-features-div">
                                    <!-- <span class="tabl-header tabl-header-font">Features :</span> -->
                                    <ul class="list-content" style="font-weight: bold;">
                                        <li *ngFor="let notes of previousReleaseNote1.titlePointsMap | keyvalue" class="li-padding">
                                            <span>{{notes.key}}</span>
                                            <ul class="description-list">
                                                <li *ngFor="let desc of notes.value" class="margin-15">{{desc}}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="release-note-info" *ngIf="viewPreviousReleases">
                                <div class="release-date-div"><span class="tabl-header tabl-header-font">Release
                                        Date : </span> <span>{{previousReleaseNote2.releaseDate}}</span>
                                </div>
                                <div class="release-features-div">
                                    <!-- <span class="tabl-header tabl-header-font">Features :</span> -->
                                    <ul class="list-content" style="font-weight: bold;">
                                        <li *ngFor="let notes of previousReleaseNote2.titlePointsMap | keyvalue" class="li-padding">
                                            <span>{{notes.key}}</span>
                                            <ul class="description-list">
                                                <li *ngFor="let desc of notes.value" class="margin-15">{{desc}}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
                                    <app-data-table [dataSource]="refreshData" [columns]="refreshHeaderColumns">
                                    </app-data-table>
                                </div>
                            </div>
                        </mat-card-content>
                        <div style="padding-left: 2%;">
                            <span class="tabl-header tabl-header-font">Browsers supported: </span>
                        </div>
                        <mat-card-content class="browser-supported browser-div">
                            <div class="browser-explorer">
                                <div>
                                    <img class="icon" src="../../../assets/images/icons8-microsoft-edge.svg" />
                                    <p class="version">Microsoft Edge</p>
                                </div>
                            </div>
                            <div class="browser-chrome">
                                <div>
                                    <img class="icon" src="../../../assets/images/icons8-chrome.svg" />
                                    <p class="version">Google Chrome</p>
                                </div>
                            </div>
                        </mat-card-content>
                        <div style="padding-left: 2%;">
                            <p>Note** For better user experience,use Google Chrome for accessing dashboards</p>
                        </div>
                    </mat-card>
                </div>
            </div>
        </ng-container>
        <div *ngIf="showLoader" class="row loader-container">
            <app-loader></app-loader>
        </div>
    </div>
</div>