import { Component,Inject,OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsService } from '../reports.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.css']
})
export class EditReportComponent implements OnInit {
  reportName: any;
  description: any;
  url: any;
  enableButton:boolean=false;
  anyChangeInData: boolean = false;
  
  constructor(    
  @Inject(MAT_DIALOG_DATA) public data:any,
  public dialog: MatDialogRef<EditReportComponent>,
  private reportsService: ReportsService,
  public toaster: ToastrService,) { }

  ngOnInit(): void {
  }
  closePopup() {
    this.dialog.close({anyChangeInData: this.anyChangeInData});
  }

  modelChanged() {
    this.enableButton=true;
    // document.querySelector('.textarea_text').addEventListener('input', () => {
    //   let text = (<HTMLInputElement>document.querySelector('.textarea_text')).value;
    //   (<HTMLInputElement> document.querySelector('.textarea_text')).value = text.charAt(0).toUpperCase() + text.slice(1);
    //  })
    }

    updateReport(){
      this.reportsService.updateReport(this.data).subscribe(
        () => {
          this.toaster.success('Successfully updated.', '', {
            timeOut: 10000,
            positionClass: 'toast-top-center'
          });
          this.anyChangeInData=true;
          console.log("closing popup");
          this.closePopup();
        },
        err => {
          console.log(err, 'error occured while update');
          this.toaster.error('Internal server error.', '', { timeOut: 10000, positionClass: 'toast-top-center' });
        })
      }
}
