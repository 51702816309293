<mat-horizontal-stepper #stepper>
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Analyze your spends</ng-template>

            <div>
                <button mat-button matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Validate your chargeback</ng-template>

            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Optimize</ng-template>

        <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
        </div>
    </mat-step>
</mat-horizontal-stepper>