import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ProductMappingService } from '../product-mapping.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CapabilityService } from '../../capability/capability.service';
import { AppHubUtilService } from 'src/app/shared';
import { AppViewService } from '../../application-view/app-view.service';

@Component({
  selector: 'app-product-suites-edit',
  templateUrl: './product-suites-edit.component.html',
  styleUrls: ['./product-suites-edit.component.css']
})
export class ProductSuitesEditComponent implements OnInit {

  showLoader: boolean = false;
  anyChangeInData: boolean = false;
  productSuiteList: Array<any> = [];
  selectedProductSuite: any;
  selectedGroupModel: string = '';
  hierarchialData = [];
  initialValue;
  selectedProductFamily;
  selectedIdList: Array<string> = [];
  selectedoption = []
  childdataList: Array<string> = [];
  editMode: string = 'new';
  deletedSuite: boolean = false;
  isInitialLoad: boolean = true;
  TreeCheckboxDataSource = {};
  selectedCheckbox: any = [{}];
  selectedPrvsChekbox: any = [{}];
  productSuitedata;
  capL1Obj = {}
  regexExp = /^[a-zA-Z].*[\s.]*$/g;

  formConfig = [
    {
      name: 'Product Suite Name',
      rank: 'Rank',
      description: 'Product Suite Description',
      id: 'suiteId',
      showMultiSelect: true
    }
  ]
  tempobj = {}
  form: any = this.fb.group({

    name: ['', [Validators.required, Validators.pattern(this.regexExp.source)]],
    rank: [{ value: null, disabled: true }],
    description: ['', [Validators.required, Validators.pattern(this.regexExp.source)]],
    selectedList: [{ value: [], disabled: false }],
    // validates date format yyyy-mm-dd

  })
  allProductFamily: Array<any> = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public toaster: ToastrService,
    private productService: ProductMappingService, private fb: FormBuilder,
    public dialog: MatDialogRef<ProductSuitesEditComponent>,
    private service: CapabilityService,
    private cdRef: ChangeDetectorRef,
    private appUtil: AppHubUtilService,
    private appservice: AppViewService,

  ) {
    this.productSuiteList = data.productSuite;
    this.selectedGroupModel = data.groupModel;
    let rank: any = this.productSuiteList.length + 1;
    this.form.patchValue({
      rank: rank
    })

  }

  ngOnInit() {
    this.loadAllProductFamily();
  }

  loadAllProductFamily() {
    const body: any = {};
    this.tempobj = {};
    this.showLoader = true;

    body['Group_Model'] = this.selectedGroupModel;
    this.service.GetCapabilities(body).subscribe((results: any) => {
      this.allProductFamily = results.data
      // this.allProductFamily = results.data.map(({ name, id, desc, Rank }) => ({ name, id, desc, Rank }));
      let treeobj = {}
      // var tempobj={}
      for (let i = 0; i < this.allProductFamily.length; i++) {
        // this.selectedoption.push(this.allProductFamily[i].name)
        treeobj[this.allProductFamily[i].name] = {}
        this.tempobj[this.allProductFamily[i].name] = this.allProductFamily[i].id
        for (let j = 0; j < this.allProductFamily[i].children.length; j++) {

          treeobj[this.allProductFamily[i].name][this.allProductFamily[i].children[j].name] = null
          this.tempobj[this.allProductFamily[i].children[j].name] = this.allProductFamily[i].children[j].id
        }
      }
      this.TreeCheckboxDataSource =
        this.appUtil.sanitize(
          treeobj
        )
      if (this.TreeCheckboxDataSource)
        this.appservice.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
      this.showLoader = false;
    })
  }

  closePopup() {
    this.dialog.close({ anyChangeInData: this.anyChangeInData, Productsuiteid: null });
  }
  closePopupnew(Productsuiteid) {
    this.dialog.close({ anyChangeInData: this.anyChangeInData, Productsuiteid: Productsuiteid });
  }

  loadProductSuiteDetails() {
    this.isInitialLoad = false;
    this.deletedSuite = false;
    this.selectedoption = [];
    this.selectedPrvsChekbox = [];
    const body: any = {};
    if (this.selectedProductSuite) {
      body['Product_Suite'] = this.selectedProductSuite.Product_Suite_ID;
    }

    body['Group_Model'] = this.selectedGroupModel;
    this.showLoader = true;
    this.editMode = 'edit'
    this.service.GetCapabilities(body).subscribe((results: any) => {
      const obj: any = {};
      obj.id = this.selectedProductSuite.Product_Suite_ID,
        obj.name = this.selectedProductSuite.productSuite,
        obj.description = this.selectedProductSuite.productSuiteDesc,
        obj.rank = this.selectedProductSuite.Product_Suite_Key
      this.hierarchialData = [obj];

      if (results.data && results.data.length) {
        this.hierarchialData[0].selectedList = results.data.map(({ name, id, desc, Rank, children }) => ({ name, id, desc, Rank, children }));

        for (let i = 0; i < results.data.length; i++) {

          if (results.data[i]['children'].length > 0) {
            for (let j = 0; j < results.data[i]['children'].length; j++) {
              let tempObj = {}
              this.selectedoption.push(results.data[i]['children'][j]['name'])
              tempObj['productFamilyID'] = results.data[i]['id']
              tempObj['Capability_L1_ID'] = results.data[i]['children'][j]['id']
              // if(Object.keys(tempObj).length!=0){
              this.selectedPrvsChekbox.push(tempObj)
              // }
              // for(let key in tempObj){
              //   if(typeof tempObj[key]!=undefined && tempObj[key]!=null){
              //     this.selectedPrvsChekbox.push(tempObj)
              //   }
              // }
            }
          } else {
            // this.selectedoption.push(results.data[i]['name'])
          }

        }

      }
      else {
        this.hierarchialData[0].selectedList = [];
      }
      this.selectedIdList = this.hierarchialData[0].selectedList.map(d => d.name);
      this.form.setValue({
        name: (this.hierarchialData[0].name) ? this.hierarchialData[0].name : '',
        rank: (this.hierarchialData[0].rank) ? this.hierarchialData[0].rank : '',
        description: (this.hierarchialData[0].description) ? this.hierarchialData[0].description : '',
        selectedList: (this.hierarchialData[0].selectedList) ? this.hierarchialData[0].selectedList : ''
      });
      this.initialValue = [...this.hierarchialData];

      this.showLoader = false;
    })
  }
  multipleList(event) {
    this.selectedCheckbox = [{}]
    this.capL1Obj = {}
    for (let i = 0; i < event.length; i++) {
      let obj = {}
      this.capL1Obj[this.tempobj[event[i][0]]] = ''
      obj['productFamilyID'] = this.tempobj[event[i][0]]
      obj['Capability_L1_ID'] = this.tempobj[event[i][1]]
      for (let key in obj) {
        if (typeof obj[key] != 'undefined' && obj[key] != null) {
          this.selectedCheckbox[i] = obj

        }
      }
    }
  }

  ngAfterViewChecked() {
    //explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges();
  }


  fetchSpecificSuiteDetails() {
    this.loadProductSuiteDetails();
  }


  onSubmit() {
    const payload: any = {};
    let finalDeletedValue
    let finaleSelectedVal
    if (this.selectedCheckbox.length > 0) {
      let SelectedC1Id = this.selectedCheckbox.map(x => x.Capability_L1_ID);
      finalDeletedValue = this.selectedPrvsChekbox.filter(x => {
        return !SelectedC1Id.includes(x.Capability_L1_ID);
      })

    }
    finaleSelectedVal = this.selectedCheckbox.filter(x => !this.selectedPrvsChekbox.some(y => y.Capability_L1_ID === x.Capability_L1_ID));
    // console.log('finaleSelectedVal-=-=>',Object.keys(finaleSelectedVal[0]).length)
    if (finaleSelectedVal.length > 0 && Object.keys(finaleSelectedVal[0]).length == 0) {
      finaleSelectedVal = [];
    }
    payload.Product_Suite_ID = this.editMode === 'edit' ? this.hierarchialData[0].id : null;
    payload.productSuite = this.form.value.name && this.form.value.name.trim();
    payload.productSuiteDesc = this.form.value.description && this.form.value.description.trim();
    payload.group_model = this.selectedGroupModel;
    payload.Product_Suite_Key = this.form.get('rank').value
    payload.SelectedL1 = this.capL1Obj
    // payload.productFamilyDetails = this.form.value.selectedList.map(d => {
    //   const obj: any = {};
    //   obj.productFamilyID = d.id
    //   obj.cap = 'C1-7'
    //   return obj
    // })
    payload.productFamilyDetails = finaleSelectedVal
    payload.finalDeletedValue = finalDeletedValue
    payload.createdById = localStorage.getItem('MsId');
    payload.createdByName = JSON.parse(localStorage.getItem('currentUser'));



    const sameMatchCase = this.productSuiteList.filter(d => d.productSuite.toLowerCase() === this.form.value.name.toLowerCase().trim())
    if (sameMatchCase.length > 0 && this.editMode === 'new') {
      this.toaster.error(`This ProductSuite name already Exists ${this.form.value.name}`, '', { timeOut: 10000, positionClass: 'toast-top-center' });

      // alert(`${this.form.value.name} already exists..`)

      return;
    }
    this.showLoader = true;
    this.productService.editAddProductSuite(payload).subscribe({
      next: data => {
        if (Object.prototype.hasOwnProperty.call(data,'errors')) {
          console.log("error occured while update")
          this.showLoader = false;
          this.anyChangeInData = true;
          this.toaster.error(data.message, '', { timeOut: 10000, positionClass: 'toast-top-center' });
        } else {
          this.showLoader = false;
          this.anyChangeInData = true;
          this.loadProductSuiteDetails();
          if (this.editMode === 'new') {

            this.hierarchialData.push({
              id: data.productSuiteId,
              name: this.form.value.name,
              description: this.form.value.description,
              rank: this.form.get('rank').value,
              // selectedList: this.form.get('selectedList').value
            })
            this.editMode = 'edit'
            this.productSuiteList.push({
              Product_Suite_ID: data.productSuiteId,
              Product_Suite_Key: this.form.get('rank').value,
              productSuite: this.form.value.name,
              productSuiteDesc: this.form.value.description
            });
          }
        }

        this.toaster.success("Successfully updated.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
        this.closePopupnew(payload.Product_Suite_ID);
        this.closePopup();

        // }
        // else 
        // {
        //   this.toaster.error(data.message, '', { timeOut: 10000, positionClass: 'toast-top-center' });
        // }
      },
      error: error => {
        console.log(error, "error occured while update")
        this.showLoader = false;
        this.anyChangeInData = true;
        this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
      }
    })
  }

  addNewProductSuite() {
    this.isInitialLoad = false;
    this.showLoader = true;
    this.deletedSuite = false;
    this.editMode = 'new';
    this.hierarchialData = [];
    this.selectedoption = []
    this.selectedCheckbox = [];
    this.selectedPrvsChekbox = [];
    this.capL1Obj = {}
    this.form.reset();
    setTimeout(() => {
      this.showLoader = false;
      this.selectedIdList = [];
      this.selectedProductSuite = '';
      this.form.patchValue({
        name: '',
        description: '',
        rank: this.productSuiteList.length + 1,
        selectedList: []
      });
    }, 1000)

  }

  deleteProductSuite() {
    const payload = {
      createdById: localStorage.getItem('MsId'),
      createdByName: JSON.parse(localStorage.getItem('currentUser'))

    }

    if (confirm(`Are you sure to delete ${this.hierarchialData[0].name} ?`)) {
      this.showLoader = true;
      this.productService.deleteProductSuite(this.hierarchialData[0].id, payload).subscribe({
        next: () => {
          this.deletedSuite = true;
          this.productSuiteList = this.productSuiteList.filter(d => d.Product_Suite_ID != this.hierarchialData[0].id);
          this.selectedProductSuite = '';
          this.showLoader = false;
          this.anyChangeInData = true;
          this.toaster.success("Successfully deleted.", '', { timeOut: 10000, positionClass: 'toast-top-center' });
          // this.closePopupnew(this.hierarchialData[0].id);
          this.closePopup();
        },
        error: error => {
          this.deletedSuite = false;
          console.log(error, "error occured while update")
          this.showLoader = false;
          this.anyChangeInData = true;
          this.toaster.error("Internal server error.", '', { timeOut: 10000, positionClass: 'toast-top-center' });

          if (error.error.errors) {
            this.toaster.error(error.error.errors, '', { timeOut: 10000, positionClass: 'toast-top-center' });
          }
        }
      })
    }

  }
  mouseEnter(capability) {
    capability.showViewTypeOver = true;
  }

  mouseLeave(capability) {
    capability.showViewTypeOver = false;
  }
  collapseExpand(level, status) {
    if (level && level.children) {
      level.display = status;
    }
  }

  collapseExpandName(level) {
    if (level && level.children) {
      level.display = !level.display;
    }
  }
  ParseName(name: string, number = null) {
    number = number ? number : 23;
    if (name && name.length) {
      return name.length > number ? name.substring(0, number) + '...' : name;
    } else {
      return '';
    }
  }
  GetLevelName(level) {
    if (level.level) {
      return '(' + level.level + ')';
    } else {
      return '';
    }
  }
}
