<div>

  <nav class="header-2 navbar navbar-expand-sm nav-color headermainDiv-height">
    <span class="navbar-brand pointer-event appName-font" (click)="redirectToHome()">
      <img class="toh-logo" src="../../../../assets/imagesV4/Techlog_Svg.svg" />
      {{appName}}
    </span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end pr-2" id="navbarNavDropdown">
      <ul class="navbar-nav ">
        <li class="nav-item" (click)="redirectView('portfolio-hub')">
          <span class="nav-link header-menu header-menu-font"
            [ngClass]="{'nav-item-active': selectedRoute == 'portfolio-hub'}">
            Portfolio Hub
          </span>
        </li>
        <li class="nav-item" (click)="redirectView('application-hub')">
          <span class="nav-link header-menu header-menu-font"
            [ngClass]="{'nav-item-active': selectedRoute == 'application-hub'}">
            Application Hub
          </span>
        </li>
        <li class="nav-item" (click)="redirectView('reports')">
          <span class="nav-link header-menu header-menu-font"
            [ngClass]="{'nav-item-active': selectedRoute == 'reports'}">
            Reports
          </span>
        </li>
        <li class="nav-item dropdown menu-dropdown">
          <span class="nav-link dropdown-toggle header-menu header-menu-font" id="navbarDropdownMenuLink"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            [ngClass]="{'nav-item-active': selectedRoute == 'capModel'}">
            Capability Models
          </span>
          <div class="dropdown-menu lg-dropdown pt-1" aria-labelledby="navbarDropdownMenuLink">
            <span class="dropdown-item" (click)="redirectView('optCapModel')">Optum Capability Model</span>
            <span class="dropdown-item" (click)="redirectView('techCapModel')">Technology Capability Model</span>
            <span class="dropdown-item" (click)="redirectView('prodCapModel')">UHC Product Capability Model</span>
          </div>
        </li>
        <li class="nav-item dropdown">
          <span class="nav-link dropdown-toggle header-menu header-menu-font" id="navbarDropdownMenuLink1"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            [ngClass]="{'nav-item-active': selectedRoute == 'maintenance'}">
            Maintenance
          </span>
          <div class="dropdown-menu pt-1 maintenance-dropdown" aria-labelledby="navbarDropdownMenuLink1">
            <span class="dropdown-item" (click)="navigateToGroupMaintenance()">App Groups / Platforms</span>
            <span class="dropdown-item" (click)="navigateToLoborOrgMaintenance()">LOB / Org Repository</span>
            <span class="dropdown-item" (click)="navigateToOptimization()">Optimization</span>
            <span class="dropdown-item" (click)="navigateToBulkMaintenance()"
              *ngIf="acccess.hasAdminSecureGrpAccess || acccess.hasAppEditorSecureGrpAccess || acccess.hasModelEditorSecureGrpAccess">Bulk
              Edit</span>
            <span class="dropdown-item" *ngIf="fundingOwnerAccess" (click)="navigateToFundingOwner()">Capital
              Demand</span>

            <span class="dropdown-item" *ngIf="enterpriseInitiativesList.length"
              (click)="navigateToInitiatives()">Enterprise Initiatives</span>

          <span class="dropdown-item" (click)="navigateToForecastManagement()">Forecast Management</span>

        </div>
      </li>
      <li class="nav-item" *ngIf="this.workQueuesRoles.includes(formattedRole) == true">
        <span class="nav-link header-menu header-menu-font" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" (click)="redirectView('work-queues')" aria-expanded="false" [ngClass]="{'nav-item-active': selectedRoute == 'work-queues'}">
          Work Queues
        </span>
        <!-- <div class="dropdown-menu lg-dropdown pt-1" aria-labelledby="navbarDropdownMenuLink">
          <span class="dropdown-item" (click)="redirectView('optimization')">Optimization</span>
          <span class="dropdown-item" (click)="redirectView('work-queues')">My Work Queues</span>
        </div> -->
        </li>
      </ul>
    </div>

    <div class="nav-item dropdown pr-2">
      <span id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        [ngClass]="{'nav-item-active': selectedRoute == 'help'}">
        <mat-icon class="cursor-pointer">notifications_none</mat-icon>
        <div *ngIf="notifications.length>0" class="redcircle"></div>
      </span>
      <div class="dropdown-menu dropdown-menu-right md-dropdown noti-dropdown"
        aria-labelledby="navbarDropdownMenuLink2">
        <div class="notification-header">Notifications</div>
        <section class="notification-content" *ngIf="notifications.length>0">
          <ng-container *ngFor="let eachNotificaation of notifications">
            <div class="notification">
              <div class="notification-title d-flex popup-title" 
                *ngIf="eachNotificaation.Popup=='True'||eachNotificaation.Popup=='true'">
                <p>{{eachNotificaation.Content}}</p>
                <span class="pull-right notification-count" (click)="showNotificationPopup()">{{eachNotificaation.total_count}}</span>
              </div>
              <div class="notification-title d-flex"
                *ngIf="eachNotificaation.Popup!='True'&& eachNotificaation.Popup!='true'">
                <p>{{eachNotificaation.Content}}</p>
                <span class="pull-right notification-count">{{eachNotificaation.total_count}}</span>
              </div>

              <ng-container *ngFor="let description of eachNotificaation.notifications">
                <p class="notification-desc" [innerHTML]="description.Notification_message | safeHtml"></p>
              </ng-container>

            </div>
          </ng-container>
        </section>
        <section class="notification-content no-notification" *ngIf="notifications.length==0">
          <p>You have no notification right now</p>
        </section>
      </div>
    </div>
    <div class="nav-item dropdown pr-2">
      <span id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        [ngClass]="{'nav-item-active': selectedRoute == 'help'}">
        <img class="help-icon" src="../../../../assets/imagesV4/question_mark.svg" />
      </span>
      <div class="dropdown-menu dropdown-menu-right pt-1 sm-dropdown" aria-labelledby="navbarDropdownMenuLink2">
        <span class="dropdown-item" (click)="redirectToRelease()">
          <span class="uitk-icon uitk-icon-consent sm-icon pr-1"></span>
          Release Notes
        </span>
        <a class="dropdown-item" href="https://uhctops.ideas.aha.io/portal_session/new" target="_blank"
          [ngClass]="{'nav-item-active': selectedRoute == 'maintenance'}" rel="noopener noreferrer">
          <span class="uitk-icon uitk-icon-idea sm-icon pr-1"></span>
          Submit an idea
        </a>
        <span class="dropdown-item" (click)=redirectToUserManual()>
          <span class="uitk-icon uitk-icon-document sm-icon pr-1"></span>
          Documentation
        </span>
        <span class="dropdown-submenu dropdown-menu-right">
          <span class="dropdown-item">
            <span class="uitk-icon uitk-icon-thumbs_up sm-icon pr-1"></span>
            Feedback
            <ul class="submenu dropdown-menu dropdown-menu-right feedback-menu sm-dropdown pt-1">
              <li><a class="dropdown-item"
                  href="https://teams.microsoft.com/l/channel/19%3ahyqBUCKo9V2xNwyKCXa2hIUNGPgh24c1lsC4AjQiX2o1%40thread.tacv2/General?groupId=2ddaff3a-b209-451a-8405-5c0530c43e91&tenantId=db05faca-c82a-4b9d-b9c5-0f64b6755421"
                  target="_blank" rel="noopener noreferrer">
                  <img class="icon-image" src="../../../../assets/imagesV4/ms_teams.svg" />
                  <span class="pl-2">Mircosoft Teams</span>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href={{hrefShareFeedback}} target="_blank" rel="noopener noreferrer">
                  <img class="icon-image" src="../../../../assets/imagesV4/outlook.svg" />
                  <span class="pl-2">Outlook</span>
                </a>
              </li>
            </ul>
          </span>
        </span>

      </div>
    </div>



    <span class="name-initials" data-toggle="tooltip" data-placement="bottom"
      title="{{userNameFullDetails}}">{{userNameInitials}} </span>
  </nav>
  <div class="stk-table-loader landing-page-header-loader" *ngIf="showLoader">
    <app-loader>
    </app-loader>
  </div>
</div>
<app-modal [maximizable]="true" [inViewport]="true" #notificationModal class="modal-demo notification-popup">
  <ng-container class="app-modal-body ">
    <div class="d-flex close-icon" (click)="closeNotificationModal()"><mat-icon >close</mat-icon></div>
    <app-notification-popup [notificationList]="notificationList" *ngIf="showNotificationDetailPopup"></app-notification-popup>
  </ng-container>
</app-modal>
<app-modal [maximizable]="true" [inViewport]="true" #urgentActionReqModal class="urgent-action-popup">
  <ng-container class="app-modal-header">Urgent Action Required</ng-container>
  <ng-container class="app-modal-body ">
    <div *ngIf="oldBucketDetails.length>0" class="notification-details">
      <p class="notification-desc old-bucket" [innerHTML]="oldBucketDetails[0].Notification_message | safeHtml"></p>
    </div>
  </ng-container>
</app-modal>