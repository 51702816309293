import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppHubUtilService } from 'src/app/shared';
import { FilterSelectionService } from 'src/app/shared/services/filter-selection.service';
import { ReplaySubject } from 'rxjs';
import { TreeCheckboxComponent } from 'src/app/shared/elements/tree-checkbox/tree-checkbox.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DailogModalComponent } from 'src/app/shared/elements/dailog-modal/dailog-modal.component';
import { DatePipe } from '@angular/common';
import { AppHubService as AppHubV4Service } from 'src/app/techopsv4/services/appHubV4Service';
import { ConstantsData } from 'src/app/constants/fields';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-cap-pop-up',
  templateUrl: './cap-pop-up.component.html',
  styleUrls: ['./cap-pop-up.component.css'],
  providers: [NgModel]
})
export class CapPopUpComponent implements OnInit {
  currentYear = new Date().getUTCFullYear();
  @Input() defaultPaduData:any = {};
  @Input() isEdit;
  @Input() selectedModel;
  @Input() excludeModel = [];
  @Input() mappedCapability;
  @Input() selectedCapHierarchy;
  selectedCapability;
  @Input() selectedOrg;
  @Input() openCapProposalWQReqArr = [];
  capabilityHierarchyList: any = [];
  TreeCheckboxDataSource = {};
  datasorcelist = new MatTableDataSource([]);
  @Input() application;
  @Output() dataReceived: EventEmitter<any> = new EventEmitter();
  @Output() reset: EventEmitter<any> = new EventEmitter();
  destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() selectedCapOrgs;
  isPrimary;
  public orgDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public term;
  public deletedRow = [];
  public ModelCaps: any = [];
  public paduOptions: any = [{ label: 'Preferred', value: 'Preferred' },
  { label: 'Acceptable', value: 'Acceptable' },
  { label: 'Discouraged', value: 'Discouraged' },
  { label: 'Unacceptable', value: 'Unacceptable' },
  { label: 'Retired', value: 'Retired' }
  ];
  editablePaduValues = ['Preferred', 'Acceptable', 'Discouraged', 'Unacceptable', 'Retired'];
  @ViewChild('mySelect', { static: false }) mySelect;
  @ViewChild('searchInput', { static: false}) searchInput: ElementRef;
  displayedColumns: string[] = ['organisation', 'current', 'transition_1', 'transition_2', 'transition_3', 'transition_4', 'transition_5', 'transition_6','delete'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(TreeCheckboxComponent, { static: false }) treeCapability: TreeCheckboxComponent;
  primary: any;
  excludedOrgs = [];
  oldExcludedOrgs = [];
  Primarydisable = true;
  searchCapability: any;
  searchOrganization: any;
  Orglist: any = [];
  applyDisable: any = true;
  primaryChange = 'No';
  modelChange = 'No';
  capChange = 'No';
  appId: any;
  appName: any;
  oldCapability: any;
  oldCapHierarchy: any;
  oldGroupModel: any;
  tapData:any;
  switchMapData:any;

  PADUFullName = ConstantsData.PADUFullName;
  @Input() modelData = [];
  @Input() capListForApp = [];
  @Input() selectedCapRowData = {};
  masterOrgList = [];
  showLoader: boolean;
  oldSelectedModel : string = '';
  constructor(
    private filterService: FilterSelectionService,
    private appUtil: AppHubUtilService,
    public toaster: ToastrService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private ngmodel:NgModel,
    private AppHubV4Service: AppHubV4Service
  ) { }

  openRequest = [];
  showWQHyperlink:boolean = false;
  wqList:Array<any> = [];
  isInitialCall : boolean = false;
  @Input() isWQRequestViewAccess:boolean;

  ngOnInit() {
    if (this.isEdit) {
      this.oldCapability = this.selectedCapability;
      this.oldCapHierarchy = this.selectedCapHierarchy;
      this.oldGroupModel = this.selectedModel;
    }
    this.loadCapHierarchyData();
  }
  ngOnChanges() {
    this.applyDisable = true;
    this.showWQHyperlink = false;
    this.primary = this.capListForApp.length == 0 ? 'Yes' : 'No';
    this.displayedColumns = ['organisation', 'current', 'transition_1', 'transition_2', 'transition_3', 'transition_4', 'transition_5', 'transition_6','delete'];
    this.dataSource = new MatTableDataSource([]);
    this.selectedModel = this.selectedCapRowData['group_model'] || '';
    this.selectedCapability = this.selectedCapRowData['selectedCapability'] || '';
    this.isPrimary = this.selectedCapRowData['primary_status'] == 'Yes' ? 'Yes' : 'No';
    this.orgDataSource.data = _.cloneDeep(this.selectedCapOrgs);
    if (this.selectedCapOrgs) {
      this.excludedOrgs = [];
      this.selectedCapOrgs.forEach(e => {
        this.excludedOrgs.push(e.org_id);
        this.oldExcludedOrgs.push(e.org_id);
      });
    }
    if (this.isEdit) {
      this.primaryChange = 'No';
      this.primary = this.isPrimary;
      this.oldCapability = this.selectedCapability;
      this.oldCapHierarchy = this.selectedCapHierarchy;
      this.oldGroupModel = this.selectedModel;
      this.Primarydisable = false;
      this.appId = this.selectedCapOrgs[0].app_id;
      this.appName = this.selectedCapOrgs[0].application;
      this.isInitialCall = true;
      this.showWQOpenRequestHyperlink(this.selectedCapability);
      if(this.openCapProposalWQReqArr.length > 0){
        this.getCapProposalWQreq();
      }
    }
    this.checkFuturePaduVisibility();
    if(this.oldSelectedModel && this.oldSelectedModel != this.selectedModel){
      this.getNewMappedCapabilityList();
    }
    this.oldSelectedModel = this.selectedModel;
  }

  loadCapHierarchyData(){
    if (!this.capabilityHierarchyList.length) {
      this.showLoader = true;
      this.AppHubV4Service.getCapabilityHierarchy().subscribe(res => {
        this.capabilityHierarchyList = res;
        this.TreeCheckboxDataSource = this.appUtil.tree(res, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME']);
        this.getNewMappedCapabilityList();
      });
    }
    else {
      this.getNewMappedCapabilityList();
    }
    if (!this.Orglist.length) {
      this.AppHubV4Service.getOrganizationList().subscribe(res => {
        this.Orglist = res;
        this.masterOrgList = res;
      });
    }
  }

  getSelectedCapbilityFromDropdown(event) {
    this.showWQHyperlink = false;
    this.selectedCapHierarchy = event;
    
    if (event.capabilityL3 !== null) {
      this.selectedCapability = event.capabilityL3;
    } else if (event.capabilityL2 !== null) {
      this.selectedCapability = event.capabilityL2;
    } else if (event.capabilityL1 !== null) {
      this.selectedCapability = event.capabilityL1;
    } else {
      this.selectedCapability = event.capabilityL0;
    }
    this.mySelect.close();
    this.applyDisable = false;
  }
  
  async getNewMappedCapabilityList() {
    if(!this.isEdit)
      this.showWQHyperlink = false;
    if(this.openCapProposalWQReqArr && this.openCapProposalWQReqArr.length > 0){ //get capability open work queue request
      this.getCapProposalWQreq();
    }
    if (this.capListForApp) {
      this.selectedCapability = '';
      const filterCapability = this.capListForApp.filter(d => {
        if (d.group_model === this.selectedModel) {
          return d.capability;
        }
      });

      this.mappedCapability = filterCapability.map(d => d.selectedCapability+'^'+d.selectedCapabilityID);
      const capHier = this.selectedModel
        ? this.capabilityHierarchyList.filter(d => d.group_model === this.selectedModel)
        : this.capabilityHierarchyList;
      this.ModelCaps = capHier;
      this.TreeCheckboxDataSource = this.appUtil.replaceNull(
        this.appUtil.sanitize(
          this.appUtil.tree(capHier, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME'])
        ),
        null
      );
      if (this.TreeCheckboxDataSource){
        this.AppHubV4Service.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
      }
    }
    this.showLoader = false;
    if (this.isEdit) this.selectedCapability = this.oldCapability;
  }
  addOrg() {
    let paduValues = {};
    this.AppHubV4Service.getPaduData().subscribe(res => {
      this.defaultPaduData = res['app_id'] ? res['lowest_padu'] : this.defaultPaduData;
    })
    if(this.defaultPaduData && Object.keys(this.defaultPaduData).length > 0) {
      Object.keys(this.defaultPaduData).forEach(key =>{
        if(this.PADUFullName[this.defaultPaduData[key]] == 'To Be Retired') {
          paduValues[key] = 'Retired';
        } else {
          paduValues[key] = this.PADUFullName[this.defaultPaduData[key]];
        }
      })
    }
    this.excludedOrgs = [...new Set(this.excludedOrgs)];
    this.dataSource.data.forEach(e => {
      if (e.org_id != '') this.excludedOrgs.push(e.org_id);
    });
    let res = {
      organisation: '',
      current: paduValues['current'],
      transition_1: paduValues['transition_1'],
      transition_2: paduValues['transition_2'],
      transition_3: paduValues['transition_3'],
      transition_4: paduValues['transition_4'],
      transition_5: paduValues['transition_5'],
      transition_6: paduValues['transition_6'],
    }
    if (this.isEdit) {
      this.orgDataSource.data.forEach(e => {
        if (e.org_id != '') this.excludedOrgs.push(e.org_id);
      });
      res['app_id'] = this.appId;
      res['application'] = this.appName;
      this.orgDataSource.data.push(res);
      this.orgDataSource = new MatTableDataSource(this.orgDataSource.data);
      this.applyDisable = false;
    } else {
      this.dataSource.data.push(res);
      this.dataSource = new MatTableDataSource(this.dataSource.data);
    }
  }
  removeSelectedOrgRow(row) {
      let index = this.dataSource.data.indexOf(row);
      this.dataSource.data.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.excludedOrgs = this.dataSource['data'] ? this.dataSource['data'].map(org => org.org_id) : [];
  }
  onItemChange(event) {
    this.primaryChange = this.isPrimary === event ? 'No' : 'Yes';
    this.primary = event;

  }

  validateOrgChangesForAdd() {
    let inserts = [];
    let capIds = this.getIdsCapability([this.selectedCapHierarchy])[0];
    this.dataSource.data.forEach(org => {
      if (org['org_id'] && org['organisation']) {
        let payload = {
          ...org,
          ...capIds,
          'org_id': org['org_id'],
          'app_id': this.application.app_id,
          'app_name': this.application.app_name,
          'group_model': this.selectedModel,
          'primary_status': this.primary,
          'createdById': localStorage.getItem('MsId') || sessionStorage.getItem('MsId'),
          'createdByName': JSON.parse(localStorage.getItem('currentUser')) || sessionStorage.getItem('currentUser'),
          'createdAt': new Date().toISOString(),
          'updatedColumns': 'Add Capability'
        }
        inserts.push(payload);
      }
    });
    return inserts;
  }
  validateOrgChangesForEdit() {
    let changedOnes = {
      updates: [],
      inserts: [],
      deletes: []
    };
    if (this.selectedCapOrgs.length == 0 && this.orgDataSource.data.length != 0) {
      this.orgDataSource.data.forEach(org => {
        if (org['org_id'] && org['org_id'] != '') {
          let payload = {
            ...this.getIdsCapability([this.selectedCapHierarchy])[0],
            ...org,
          }
          changedOnes.inserts.push(payload);
        }
      });
    }
    else if (this.orgDataSource.data.length == 0 && this.selectedCapOrgs.length != 0) {
      this.selectedCapOrgs.forEach(org => {
        changedOnes.deletes.push(org);
      });
    }

    else if (this.orgDataSource.data.length != 0 && this.selectedCapOrgs.length != 0) {
      this.selectedCapOrgs.forEach(org => {
        let index = this.orgDataSource.data.findIndex(row => row.org_id === org.org_id);
        if (index < 0) {
          if (changedOnes.deletes.findIndex(row => row.org_id === org.org_id) < 0){
            changedOnes.deletes.push(org);
          }
        }
        else{
          let row = this.orgDataSource.data[index];
          row['updatedColumns'] = [];
          for (let i = 0; i < 7; i++) {
            if (i == 0){
              if (row['current'] != org['current']) {
                row['updatedColumns'] ? row['updatedColumns'].push('current') : row['updatedColumns'] = ['current'];
              }
            }
            else {
              if (row['transition_' + i] != org['transition_' + i]) {
                row['updatedColumns'] ? row['updatedColumns'].push('transition_' + i) : row['updatedColumns'] = ['transition_' + i];
              }
            }
          }
          if (row['updatedColumns'] && row['updatedColumns'].length > 0 && changedOnes.updates.findIndex(row => row.org_id === org.org_id) < 0) {
            changedOnes.updates.push(row);
          }
        }
      });
  
      this.orgDataSource.data.forEach(org => {
        let index = this.selectedCapOrgs.findIndex(row => row.org_id === org.org_id);
        if (index < 0) {
          if (org['org_id'] && org['org_id'] != ''){
            if (changedOnes.inserts.findIndex(row => row.org_id === org.org_id) < 0) {
              changedOnes.inserts.push(org);
            }
          }
        }
        else {
          let row = this.selectedCapOrgs[index];
          org['updatedColumns'] = [];
          for (let i = 0; i < 7; i++) {
            if (i == 0){
              if (row['current'] != org['current']) {
                org['updatedColumns'] ? org['updatedColumns'].push('current') : org['updatedColumns'] = ['current'];
              }
            }
            else {
              if (row['transition_' + i] != org['transition_' + i]) {
                org['updatedColumns'] ? org['updatedColumns'].push('transition_' + i) : org['updatedColumns'] = ['transition_' + i];
              }
            }
          }
          if (org['updatedColumns'] && org['updatedColumns'].length > 0 && changedOnes.updates.findIndex(row => row.org_id === org.org_id) < 0) {
            changedOnes.updates.push(org);
          }      
        }
      });
    }
    changedOnes['inserts'].forEach(e => {
      if (e['check']) delete e['check'];
      if (e['tempOrgID']) delete e['tempOrgID'];
      if (e['id']) delete e['id'];

    });
    return changedOnes;
  }
  checkRowForPaduChange(row) {
    let index = this.selectedCapOrgs.findIndex(org => org.org_id === row.org_id);
    if (index > -1) {
      let oldOrg = this.selectedCapOrgs[index];
      let currentOrg = this.orgDataSource.data.find(org => org.org_id === row.org_id);
      let check = false;
      for (let i = 0; i < 7; i++) {
        if (i == 0){
          if (oldOrg['current'] != currentOrg['current']) {
            check = true;
          }
        }
        else {
          if (oldOrg['transition_' + i] != currentOrg['transition_' + i]) {
            check = true;
          }
        }
      }
      if(check)
        this.applyDisable = false;
    }

  }

  apply() {
    let dataArr:any;
    if(this.isEdit){
      dataArr = this.orgDataSource;
    }else{
      dataArr = this.dataSource;
    }
    if (!this.isEdit && dataArr && dataArr.data.length == 0) {
      this.toaster.error('Please Add Organization with the selected capability.', '', {
        timeOut: 10000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    const checkorg = obj => obj.organisation === '';
    if (dataArr && dataArr.data.some(checkorg)) {
      this.toaster.error('Please Select Organization!', '', {
        timeOut: 10000,
        positionClass: 'toast-top-center'
      });
      return;
    }
    this.capChange = 'No';
    this.modelChange = 'No';
    this.primaryChange = 'No';
    let editObj = this.validateOrgChangesForEdit();
    let editBody = {
      insertOrg: [],
      updateOrg: [],
      deleteOrg: {},
      primaryUpdate: {},
      updateCap: {}
    };
    if (this.isEdit) {
      let primaryData = {};
      let capData = {};

      editObj['updates'].forEach(e => {
        let data = {
          updatedAt: new Date().toISOString(),
          updatedById: localStorage.getItem('MsId'),
          updatedByName: JSON.parse(localStorage.getItem('currentUser')),
          current: e.current,
          transition_1: e.transition_1,
          transition_2: e.transition_2,
          transition_3: e.transition_3,
          transition_4: e.transition_4,
          transition_5: e.transition_5,
          transition_6: e.transition_6,
          updatedColumns: e.updatedColumns.join(',')
        }
        let cond = {
          'Capability L0_Id': this.selectedCapRowData['capabilityL0Id'],
          'Capability L1_Id': this.selectedCapRowData['capabilityL1Id'],
          'Capability L2_Id': this.selectedCapRowData['capabilityL2Id'],
          'Capability L3_Id': this.selectedCapRowData['capabilityL3Id'],
          'Group Model': this.selectedCapRowData['group_model'],
          'ASK GLOBAL ID': this.application.app_id,
          'Organization_ID': e['org_id']
        }
        editBody.updateOrg.push({
          data: data,
          cond: cond
        });
      });
      editObj['inserts'].forEach(e => {
        editBody['insertOrg'].push(
          {
            ...e,
            capabilityL0Id: this.selectedCapRowData['capabilityL0Id'],
            capabilityL1Id: this.selectedCapRowData['capabilityL1Id'],
            capabilityL2Id: this.selectedCapRowData['capabilityL2Id'],
            capabilityL3Id: this.selectedCapRowData['capabilityL3Id'],
            capabilityL0: this.selectedCapRowData['capabilityL0'],
            capabilityL1: this.selectedCapRowData['capabilityL1'],
            capabilityL2: this.selectedCapRowData['capabilityL2'],
            capabilityL3: this.selectedCapRowData['capabilityL3'],
            group_model: this.selectedCapRowData['group_model'],
            primary_status: this.primary,
            createdAt: new Date().toISOString(),
            createdById: localStorage.getItem('MsId'),
            createdByName: JSON.parse(localStorage.getItem('currentUser')),
            updatedColumns: 'Add Org'
          }
        );
      });
      let length = editObj['deletes'].map(d => d.org_id).length;
      if (length > 0){
        editBody.deleteOrg = {
          "cond": [{
            'Capability L0_Id': this.selectedCapRowData['capabilityL0Id'],
            'Capability L1_Id': this.selectedCapRowData['capabilityL1Id'],
            'Capability L2_Id': this.selectedCapRowData['capabilityL2Id'],
            'Capability L3_Id': this.selectedCapRowData['capabilityL3Id'],
            'Group Model': this.selectedCapRowData['group_model'],
            'ASK GLOBAL ID': this.application.app_id,
            'Organization_ID': editObj['deletes'].map(d => d.org_id)
          }],
          "data": {
            'deletedById': localStorage.getItem('MsId') || sessionStorage.getItem('MsId'),
            'deletedByName': JSON.parse(localStorage.getItem('currentUser')) || sessionStorage.getItem('currentUser'),
            'deletedAt': new Date().toISOString(),
            'updatedColumns': 'Delete Org'
          }
        };
      }
      if (this.primary != this.isPrimary) {
        this.primaryChange = 'Yes';
        primaryData = {
          "cond": {
            'Capability L0_Id': this.selectedCapRowData['capabilityL0Id'],
            'Capability L1_Id': this.selectedCapRowData['capabilityL1Id'],
            'Capability L2_Id': this.selectedCapRowData['capabilityL2Id'],
            'Capability L3_Id': this.selectedCapRowData['capabilityL3Id'],
            'Group Model': this.selectedCapRowData['group_model'],
            'ASK GLOBAL ID': this.application.app_id,
          },
          "data": {
            'primary_status': this.primary,
            'updatedById': localStorage.getItem('MsId'),
            'updatedByName': JSON.parse(localStorage.getItem('currentUser')),
            'updatedAt': new Date().toISOString(),
            'updatedColmns': 'Primary_Status'
          }
        };
        editBody.primaryUpdate = primaryData;
      }
      if (!(this.selectedCapability === this.selectedCapRowData['selectedCapability'])) {
        this.capChange = 'Yes';
        capData = {
          "cond": {
            'Capability L0_Id': this.selectedCapRowData['capabilityL0Id'],
            'Capability L1_Id': this.selectedCapRowData['capabilityL1Id'],
            'Capability L2_Id': this.selectedCapRowData['capabilityL2Id'],
            'Capability L3_Id': this.selectedCapRowData['capabilityL3Id'],
            'Group Model': this.selectedCapRowData['group_model'],
            'ASK GLOBAL ID': this.application.app_id
          },
          "data": {
            ...this.getIdsCapability([this.selectedCapHierarchy])[0],
            'updatedById': localStorage.getItem('MsId'),
            'updatedByName': JSON.parse(localStorage.getItem('currentUser')),
            'updatedAt': new Date().toISOString(),
            'updatedColumns': 'Capability'
          }
        };
        if (!(this.selectedCapRowData['group_model'] === this.selectedModel)) {
          this.modelChange = 'Yes';
          capData['data']['group_model'] = this.selectedModel;
          capData['data']['updatedColumns'] += ',Group Model';
        }
        editBody.updateCap = capData;
      }
      if (Object.keys(editBody['deleteOrg']).length == 0 && editBody['updateOrg'].length == 0 && editBody['insertOrg'].length == 0 && Object.keys(editBody['primaryUpdate']).length == 0 && Object.keys(editBody['updateCap']).length == 0) {
        this.toaster.error('No Changes Made', '', {
          timeOut: 5000,
          positionClass: 'toast-top-center'
        });
        return;
      }
      let subTitliMsg = 'Organisation(s) added - ' + editBody.insertOrg.length + '<br/> Organisation(s) Updated -' + (editBody.updateOrg.length) + '<br/> Organisation(s) Removed - ' + ((editBody.deleteOrg['cond'] && editBody.deleteOrg['cond'][0]['Organization_ID']) ? editBody.deleteOrg['cond'][0]['Organization_ID'].length : 0) + '<br /> Primary change - ' + this.primaryChange + '<br /> Capabilty change - ' + this.capChange + '<br /> Model change - ' + this.modelChange;
      subTitliMsg.replace('\n/g', '<br/>');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '400px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.minHeight = '200px';
      dialogConfig.height = 'auto';
      dialogConfig.autoFocus=false;
      dialogConfig.data = {
        title: 'Update Confirmation!',
        subTitle: subTitliMsg,
        buttonName: 'Continue'
      };
      const dialogRef = this.dialog.open(DailogModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.data === 'save') {
          this.AppHubV4Service.editCaps(editBody).subscribe(res => {
            if(res) {
              this.toaster.success('Capability edited successfully to the application.', '', {
                timeOut: 10000,
                positionClass: 'toast-top-center'
              });
              this.AppHubV4Service.notifyAboutOrganisationChange();
              this.dataReceived.emit();
            }
          })
        }
      });


    }
    else {
      const addPayload = this.validateOrgChangesForAdd();
      this.AppHubV4Service.addcaps(addPayload).subscribe(
        res => {
          if(res) {
            this.toaster.success('Capability added successfully to the application.', '', {
              timeOut: 10000,
              positionClass: 'toast-top-center'
            });
            this.AppHubV4Service.notifyAboutOrganisationChange();
            this.dataReceived.emit();
          }
        }, err => {
          console.log(err, 'error occured while update');
          this.toaster.error('Internal server error.', '', {
            timeOut: 10000,
            positionClass: 'toast-top-center'
          });
        });
    }
  }
  getIdsCapability(capabilityData) {
    capabilityData.map(d => {
      if (d.capabilityL3) {
        const findfilteredlist = this.capabilityHierarchyList.filter(f => d.capabilityL0 === f.capabilityL0 && d.capabilityL1 === f.capabilityL1 && d.capabilityL2 === f.capabilityL2 && d.capabilityL3 === f.capabilityL3 && f.group_model === this.selectedModel);
        d['capabilityL0Id'] = findfilteredlist[0].capabilityL0Id;
        d['capabilityL1Id'] = findfilteredlist[0].capabilityL1Id;
        d['capabilityL2Id'] = findfilteredlist[0].capabilityL2Id;
        d['capabilityL3Id'] = findfilteredlist[0].capabilityL3Id;
      }
      else if (d.capabilityL2) {
        const findfilteredlist = this.capabilityHierarchyList.filter(f => d.capabilityL0 === f.capabilityL0 && d.capabilityL1 === f.capabilityL1 && d.capabilityL2 === f.capabilityL2 && f.group_model === this.selectedModel);
        d['capabilityL0Id'] = findfilteredlist[0].capabilityL0Id;
        d['capabilityL1Id'] = findfilteredlist[0].capabilityL1Id;
        d['capabilityL2Id'] = findfilteredlist[0].capabilityL2Id;
        d['capabilityL3Id'] = null;
      }
      else if (d.capabilityL1) {
        const findfilteredlist = this.capabilityHierarchyList.filter(f => d.capabilityL0 === f.capabilityL0 && d.capabilityL1 === f.capabilityL1 && f.group_model === this.selectedModel);
        d['capabilityL0Id'] = findfilteredlist[0].capabilityL0Id;
        d['capabilityL1Id'] = findfilteredlist[0].capabilityL1Id;
        d['capabilityL2Id'] = null;
        d['capabilityL3Id'] = null;

      }
      return d;
    })
    return capabilityData;
  }
  orgChange(row, event) {
    row['organisation'] = event.value['org_name'];
    row['org_id'] = event.value['org_id'];
    if (this.isEdit) {
      this.excludedOrgs = this.orgDataSource['data'] ? this.orgDataSource['data'].map(org => org.org_id) : [];
    } else {
      this.excludedOrgs = [];
      this.oldExcludedOrgs.forEach((item) => {
        this.excludedOrgs.push(item);
      });
      this.dataSource.data.forEach((item) => {
        if (item.org_id != '') {
          this.excludedOrgs.push(item.org_id);
        }
      });
    }
    this.excludedOrgs = [...new Set(this.excludedOrgs)];
    this.applyDisable = false;
  }
  searchOrganizationByName(event) {
    this.searchOrganization = event.target.value;
    if (!this.searchOrganization) return this.Orglist = this.masterOrgList;
    this.Orglist = this.masterOrgList.filter(item => {
      return item['org_name'].toString().toLowerCase().indexOf(this.searchOrganization.toLowerCase()) > -1
    });
  }
  searchCapabilityByName(event) {
    this.searchCapability = event.target.value;
    if (this.searchCapability) {
      let filteredTreeData = this.ModelCaps.filter((d) => {
        return d.capabilityL0.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1 ||
          d.capabilityL1 ?.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1 ||
            d.capabilityL2 ?.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1 ||
              d.capabilityL3 ?.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1
      });
      Object.assign([], filteredTreeData).forEach(ftd => {
        let str = (<string>ftd.group_model);
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex((t) => {
            return t.capabilityL0.toLocaleLowerCase() ||
              t.capabilityL1 ?.toLocaleLowerCase() ||
                t.capabilityL2 ?.toLocaleLowerCase() ||
                  t.capabilityL3 ?.toLocaleLowerCase()
          }) === -1) {
            const obj = this.ModelCaps.find((t) => {
              return t.capabilityL0.toLocaleLowerCase() ||
                t.capabilityL1 ?.toLocaleLowerCase() ||
                  t.capabilityL2 ?.toLocaleLowerCase() ||
                    t.capabilityL3 ?.toLocaleLowerCase()
            });
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });

      this.TreeCheckboxDataSource = this.appUtil.replaceNull(
        this.appUtil.sanitize(
          this.appUtil.tree(filteredTreeData, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME'])
        ),
        null
      );
      if (this.TreeCheckboxDataSource)
        this.AppHubV4Service.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
      this.treeCapability.filterChanged(this.searchCapability);
    } else {
      this.selectedCapability = '';
      const filterCapability = this.capListForApp.filter(d => {
        if (d.group_model === this.selectedModel) {
          return d.capability;
        }
      });

      this.mappedCapability = filterCapability.map(d => d.selectedCapability+'^'+d.selectedCapabilityID);
      const capHier = this.selectedModel
        ? this.capabilityHierarchyList.filter(d => d.group_model === this.selectedModel)
        : this.capabilityHierarchyList;

      this.TreeCheckboxDataSource = this.appUtil.replaceNull(
        this.appUtil.sanitize(
          this.appUtil.tree(capHier, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME'])
        ),
        null
      );
      this.treeCapability.filterChanged('');
      if (this.TreeCheckboxDataSource)
        this.AppHubV4Service.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
    }

  }
  deleteOrg(row) {
    if (this.isEdit) {
      this.applyDisable = false;
      if (row['org_id'] && row['org_id'] != '') {
        this.deletedRow.push(row);
      }
      let index = this.orgDataSource.data.indexOf(row);
      this.orgDataSource.data.splice(index, 1);
      this.orgDataSource = new MatTableDataSource(this.orgDataSource.data);
      this.excludedOrgs = this.orgDataSource['data'] ? this.orgDataSource['data'].map(org => org.org_id) : [];

    }else{
      this.removeSelectedOrgRow(row)
    }
    }
  Reset() {
    this.primary = this.isPrimary;
    this.deletedRow = [];
    this.orgDataSource.data = _.cloneDeep(this.selectedCapOrgs);
    this.selectedCapability = this.selectedCapRowData['selectedCapability'];
    this.selectedCapHierarchy = this.oldCapHierarchy;
    this.excludedOrgs = this.oldExcludedOrgs;
    this.selectedModel = this.oldGroupModel;
    this.getNewMappedCapabilityList();
    this.applyDisable = true;
  }

  resetsearch(event) {
    if (event == false) {
      this.term = '';
      this.Orglist = this.masterOrgList;
    }
  }
  resetValue(){
    this.term = "";
    this.ngmodel.reset(this.term)
  }
  checkFuturePaduVisibility(){
    this.paduOptions = [{ label: 'Preferred', value: 'Preferred' },
      { label: 'Acceptable', value: 'Acceptable' },
      { label: 'Discouraged', value: 'Discouraged' },
      { label: 'Unacceptable', value: 'Unacceptable' },
      { label: 'Retired', value: 'Retired' }
    ];
    this.editablePaduValues = ['Preferred', 'Acceptable', 'Discouraged', 'Unacceptable', 'Retired'];
    this.AppHubV4Service.getPaduData().subscribe(res => {
      if (res && res['AppStrategy'] && ['Planned Enterprise Solution', 'Planned LOB Strategic Solution'].includes(res['AppStrategy'])) {
        this.paduOptions.push({ label: 'Future', value: 'Future' });
        this.editablePaduValues.push('Future');
      }
    });
  }
  onCapabilitySelectDropdown(){
    if(this.searchInput)
      this.searchInput.nativeElement.focus();
  }

  getCapProposalWQreq(){
    this.openRequest = [];
    this.openCapProposalWQReqArr.forEach(val=>{
      if(val.selectedCapability && val.group_model == this.selectedModel ){
        this.openRequest.push(val.selectedCapability);
      }
    })
  }


  showWQOpenRequestHyperlink(capabilityName){
    this.wqList = [];
    this.showWQHyperlink = false;
    const filterData = this.openCapProposalWQReqArr.filter((val)=>{
      if(val.capabilityL3 == capabilityName)
        return val
      else if(val.capabilityL2 == capabilityName && this.checkHierarchyExist(2,3,val))
        return val
      else if(val.capabilityL1 == capabilityName  && this.checkHierarchyExist(1,3,val))
        return val
      else if(val.capabilityL0 == capabilityName  && this.checkHierarchyExist(0,3,val))
        return val
    })
    if(filterData && filterData.length > 0){
      this.showWQHyperlink = true;
      this.wqList = filterData;
    }else{
      this.isInitialCall = false;
    }
  }

  checkHierarchyExist(index,maxIndex,val){
    let isExist:boolean = true;
    for(let i=index;i<maxIndex; i++){
      if(val['capabilityL' + (index + 1)] != null){
        isExist = false;
      }
    }
    return isExist;
  }

  navigateToWQlistPage(){
    const wqlist = this.wqList.map(val=>val['Request_Id']);
    const baseUrl = window.location.origin;
    const urlparams = wqlist.join(',');
    const wqUrl = baseUrl + '/work-queues?req=wq&id=' + JSON.stringify(urlparams);
    window.open(wqUrl, '_blank');
  }

}
