<section class="reportManag">
    <div class="pull-right" *ngIf="hasReportAdminAccess">
        <button class="uitk-c-button uitk-c-button--default mat-mdc-raised-button uitk-u-margin-right--base uitk-u-margin-bottom--xs techops-global-button add-report-btn" (click)="addReports()">Add</button>
    </div>
    <div class="clear"></div>
    <ng-container *ngIf="report_dataSource">
        <app-admin-table-data [columns]="REPORT_COL" [dataSource]="report_dataSource"
            [displayedColumns]="report_displayedColumns" [tableName]="'REPORT_MANAG'"  (editWorkQueue)="editReports($event)" (deleteWorkQueue)="deleteReports($event)"></app-admin-table-data>
    </ng-container>

    <app-modal [maximizable]="true" [inViewport]="true" #addEditReportPopup class="modal-demo lookup-editor-section-modal" (closeModal)="closeModal()">
    <ng-container class="app-modal-header">
        <div style="display: flex; width: 100%;">
            <div style="width: 98%;">
                {{popupHeader}}
            </div>
        </div>
    </ng-container>
    <ng-container class="app-modal-body">
        <app-add-report [dataToEdit]="dataToEdit" [editFlag]="editFlag"
            (emitReqPayload)="emitReqPayload($event)" [formfields]="formfields" [tableName]="tableName"
            [closePopup]="closePopup"></app-add-report>
    </ng-container>
</app-modal>
</section>