import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-search-view-default-text',
  templateUrl: './search-view-default-text.component.html',
  styleUrls: ['./search-view-default-text.component.css']
})
export class SearchViewDefaultTextComponent implements OnInit {

  @Input() viewName: string;
  constructor() { }

  ngOnInit() {
  }

}
