import { Component, Input, forwardRef, HostBinding, Optional } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
  ControlContainer,
  FormGroup,
  NgForm
} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './techops-input.component.html',
  styleUrls: ['./techops-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechopsInputComponent),
      multi: true
    }
  ]
})
export class TechopsInputComponent implements ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';

  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }

  private _ID = '';

  @Input() _value = '';
  @Input() isRequired: boolean = false;
  @Input() disabled: boolean;
  @Input() pattern = '';
  @Input() type: string = '';
  @Input() debugg = false;
  @Input() name = 'input';
  @Input() formref: NgForm;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() label = '';
  @Input()
  public controlName: string;
  public formcontrol: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor(@Optional() private controlContainer: ControlContainer) {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  updateValue(value) {
    this.value = value;
    this.formcontrol = this.form.controls[this.name];
  }
  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }
}
