
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { AuthModule } from './auth/auth.module';
@NgModule({
  declarations: [
  ],
  imports: [
    LoginRoutingModule,
    CommonModule,
    AuthModule
  ],
  providers: [],
  bootstrap: [],

})
export class LoginModule { }
