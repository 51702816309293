import { Component, Inject,OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from 'src/app/shared';
import { MatPaginator } from '@angular/material/paginator';
import { AppViewService } from 'src/app/padu-reporting/application-view/app-view.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-editable-data-table-expand',
  templateUrl: './editable-data-table-expand.component.html',
  styleUrls: ['./editable-data-table-expand.component.css']
})
export class EditableDataTableExpandComponent implements OnInit {
  //displayedColumns: string[] = ['ApplicationID', 'ApplicationName', 'CapabilityPADU'];
  dataSource!: MatTableDataSource<any>;
  selection = new SelectionModel<PeriodicElement>(true, []);
  selectionSharing = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  capabilityID: any;
  applicationDetails: any;
  viewClickLoader: boolean = false;
  model: any;
  OrganizationID: any;
  app_id : string;

  displayedColumns=[
    {
      columnDef: 'ApplicationID',
      header: 'ApplicationID',
      width: '20%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      cell: (element: any) =>`${element.ApplicationID}`
    },
    {
      columnDef: 'ApplicationName',
      header: 'ApplicationName',
      width: '50%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'test':element.ApplicationName
        };
      },
      cell: (element: any) =>`${element.ApplicationName}`
    },
    {
      columnDef: 'CapabilityPADU',
      header: 'Capability PADU',
      width: '20%',
      cellStyle: () => {
        return {
          'center-text': `More Details`
        };
      },
      spanCellStyle: element => {
        return {
          'badge-padu-p-view': element.CapabilityPADU === 'Preferred',
                'badge-padu-a-view': element.CapabilityPADU === 'Acceptable',
                'badge-padu-d-view': element.CapabilityPADU === 'Discouraged',
                'badge-padu-u-view': element.CapabilityPADU === 'Unacceptable',
                'badge-padu-r-view': element.CapabilityPADU === 'Retired',
                'badge-padu-i-view': element.CapabilityPADU === 'Infrastructure',
                'badge-padu-pp-view': element.CapabilityPADU === 'PADU Pending',
                'badge-padu-tbr-view': element.CapabilityPADU === 'To Be Retired'
        };
      },
      cell: (element: any) => `${element.CapabilityPADU}`
    }];
 

  constructor(private appViewService: AppViewService, @Inject(MAT_DIALOG_DATA) data) {
    this.capabilityID = data.capabilityID;
    this.model=data.model
    this.OrganizationID=data.OrganizationID
    this.app_id=data.app_id
  }

  ngOnInit(): void {
    this.viewClickLoader =true;
    this.appViewService.getOrganisationApplicationDetails(this.capabilityID,this.model,this.OrganizationID,this.app_id).subscribe(app => {
      this.applicationDetails = app;
      this.dataSource = new MatTableDataSource(this.applicationDetails);
      this.selection = new SelectionModel<PeriodicElement>(true, []);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function(record, filter) {
        return record.ApplicationName.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
      };
      this.viewClickLoader =false;
    });
  }

  searchDataSet(event) {
    const filterValue = event ? (event.target as HTMLInputElement).value : '';
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
