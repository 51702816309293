import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(items: any[], filter:any): any {
   if(!items || !filter || filter.value.length==0){
    return items;
   }
   return items.filter(item =>(typeof item!="object" && String(item).toLowerCase().includes(filter.value.toLowerCase()))
    || (typeof item=="object" && item[filter.key].toLowerCase().includes(filter.value.toLowerCase())));
   
  }

}
