<mat-dialog-content class="mat-typography">
    <div class="modal-actions pull-right">
        <mat-icon class="material-icons pgm-close" style="cursor: pointer;" matTooltip="Close"
            (click)="hideMoreContent()">close
        </mat-icon>
    </div>
    <div>
        Only applications present in AIDE have been captured here. For full chargeback information, please refer to
        Application Cost Data section
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Application PADU: </div>
        <p style="margin-top:5px;">Editable by EA or Auto generated based on AIDE OR NEDD. </p>
        <li style="margin-left: 0px; list-style-type: disc;">PADU is a classification mechanism for Applications by business & technical
            characteristics: </li>
        <li> <span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Acceptable – EA Update Values </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Preferred – EA Update Values </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Discouraged – EA Update Values </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Unacceptable – EA Update Values </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Retired – Either updated by EA or Auto generated based on App Life Cycle status</li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>To Be Retired – Auto generated based on NEDD Decom Date, Decom Forecast Date provided by EA
        </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span><a [tpp] href="https://architecture.uhg.com/docs/padu-overview.html">for more details.</a> </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">RoadMap : </div>
        <li style="margin-left: 0px; list-style-type: disc;">Plan of Action for an Application updated by EA or Auto generated based on AIDE:
        </li>
        <li> <span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Decommission Complete – Roadmap will be auto updated to this value if the lifecycle status is
            Decommissioned in AIDE. </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Decommission planned – This option can be selected by EA if there is planned Decommission for the
            Application. </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Re-Architect - This option can be selected by EA if there is plan to Re-Architect the Application.
        </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Ready for Decom - This option can be selected by EA if the Application is ready for Decom but there
            is no plan yet. </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Replace - This option can be selected by EA if there is plan to Replace this Application with anther
            Application. </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Replacement App: </div>
        <li style="margin-left: 0px; list-style-type: disc;">An Active application from AIDE which is not same as selected Application can be
            updated by EA </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Initiative / Program: </div>
        <li style="margin-left: 0px; list-style-type: disc;">Update If the Roadmap for the Application is going to be implemented as part of a
            defined initiative or Program. </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Business maintained: </div>
        <li style="margin-left: 0px; list-style-type: disc;">logically calculated based on internal segment of Tech Owner. However, it is
            Overridable by EA. </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Tech/Business Alignment Needed: </div>
        <li style="margin-left: 0px; list-style-type: disc;">Updated by EA whether the Application needs Tech/Business alignment (Yes/No).
        </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">NonIntegrated Tech: </div>
        <li style="margin-left: 0px; list-style-type: disc;">Update to Yes or No by EA if the Application is Nonintegrated Tech </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Comments: </div>
        <li style="margin-left: 0px; list-style-type: disc;">Notes updated by EA for future Reference. </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Decomm Status:   </div>
        <li style="margin-left: 0px; list-style-type: disc;">Daily Updated based on below criteria  </li>
        <li> <span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Auto Updated to ‘Decomm Requested’ if there is an active request in NEDD.  </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Auto Updated to ‘Decom Forecasted’ if there is no active record in NEDD but it is forecasted by EA to be decommissioned in a certain quarter and Year.   </li>
        <li><span>
                <mat-icon class="customIconSize">done</mat-icon>
            </span>Auto Updated to ‘Not Marked For decom’ if there is none of the above two </li>
       
  
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Decom Date: </div>
        <li style="margin-left: 0px; list-style-type: disc;">When Decomm Status is ‘Decomm Requested’ Decom Date and Additional dates and Decom
            ID from NEED are visible on standard display and in ‘View More Pop Up’. </li>
    </div>
    <div style="font-size: 12px;">
        <div style="font-weight: bold;font-size: 14px;padding-top: 10px;">Decom Forecasted By: </div>
        <li style="margin-left: 0px; list-style-type: disc;">EA can update if the Application is forecasted to be decommissioned in a certain
            QTR of a year and is visible on the screen accordingly. Notes updated by EA for future Reference. </li>
    </div>
</mat-dialog-content>