import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PixelService {
  constructor(private http: HttpClient) {}
  //@input event  get event from router navigation
  //@input type   passing type from router navigation start and end
  //@desc pixel tracker function, call it in start and end event of nevigation
  async startPixelTracking(event, type, path: string = '') {
    let ProperName = {
      '/': 'Landing Page',
      '/landing-page': 'Landing Page',
      '/portfolio-hub': 'Portfolio Hub',
      '/application-hub': 'Application Hub',
      '/reports': 'Reports',
      '/product-home': 'Capability Model',
      '/product-home?q=Optum%20Capability%20Model': 'Optum Capability Model',
      '/product-home?q=Technology%20Capability%20Model': 'Technology Capability Model',
      '/product-home?q=UHC%20Product%20Capability%20Model': 'UHC Product Capability Model',
      '/maintenance/group': 'Maintenance Group',
      '/maintenanceV4/lob': 'Maintenance lob',
      '/maintenance/bulk': 'Maintenance Bulk',
      '/optimization': 'Optimization',
      '/release-note': 'Release Note',
      '/reclassification': 'Reclassification',
      '/work-queues': 'Work Queues'
    };

    let PageName = ProperName[event.url] || '';
    if (PageName == '') {
      if (event.url.includes('/reports')) PageName = 'TechopsHUB Power BI Report';
      if (event.url.includes('/portfolio-hub')) PageName = 'Portfolio Hub';
      if (event.url.includes('/portfolio-hub/capability/application-view'))
        PageName = 'Portfolio Hub Capability Application Detail';
      if (event.url.includes('/application-hub')) PageName = 'Application Hub';
      if (event.url.includes('/product-home/detail-view/Optum%20Capability%20Model'))
        PageName = 'Capability Application Detail(Optum Capability Model)';
      if (event.url.includes('/product-home/detail-view/Technology%20Capability%20Model'))
        PageName = 'Capability Application Detail(Technology Capability Model)';
      if (event.url.includes('/product-home/detail-view/UHC%20Product%20Capability%20Model'))
        PageName = 'Capability Application Detail(UHC Product Capability Model)';
      if (event.url.includes('/product-home/stakeholder-view/Optum%20Capability%20Model'))
        PageName = 'Capability Stackholder Detail(Optum Capability Model)';
      if (event.url.includes('/product-home/stakeholder-view/Technology%20Capability%20Model'))
        PageName = 'Capability Stackholder Detail(Technology Capability Model)';
      if (event.url.includes('/product-home/stakeholder-view/UHC%20Product%20Capability%20Model'))
        PageName = 'Capability Stackholder Detail(UHC Product Capability Model)';
      if (event.url.includes('/maintenance/viewGroup')) PageName = 'Maintenance Group Detail';
      if (event.url.includes('/maintenance/editGroup')) PageName = 'Maintenance Group Edit';
      if (event.url.includes('/maintenance/createGroup')) PageName = 'Maintenance Group Create';
      if (event.url.includes('reclassification-summary')) PageName = 'Reclassification Summary';
      if (event.url.includes('reclassification-mpwr-summary')) PageName = 'Reclassification MPWR';
      if (event.url.includes('reclassification-request')) PageName = 'Reclassification Request';
      if (event.url.includes('/cost-view-service')) PageName = 'Application Server SUI Cost detail';
      if (event.url.includes('/landing-page')) PageName = 'Landing Page';
      if (event.url.includes('/technology-insights')) PageName = 'Technology Insights';
      if (event.url.includes('/work-queues')) PageName = 'Work Queues';
    }
    let viewUrl = window.location.host + event.url;
    let pixelCusData = {};
    if (
      sessionStorage.getItem('MsId') != undefined &&
      sessionStorage.getItem('MsId') != null &&
      sessionStorage.getItem('MsId') != ''
    ) {
      let role = 'Others';
      if (localStorage.getItem('accessData') && localStorage.getItem('accessData') != 'null') {
        role = JSON.parse(localStorage.getItem('accessData')).Role;
      }
      const customdata = {
        MsId: sessionStorage.getItem('MsId'),
        CurrentUser: sessionStorage.getItem('currentUser'),
        Role: role,
        PageUrl: viewUrl,
        Query: window.location.search || 'Home Page',
        PageName: PageName
      };
      if (type == 'click') {
        customdata['EventType'] = path;
      }
      pixelCusData = {
        namespace: environment.DARID,
        ...customdata
      };
    }
    while (!(Object.prototype.hasOwnProperty.call(window,'Rakanto') && Object.prototype.hasOwnProperty.call(window.Rakanto,'event'))) {
      await new Promise(resolve => setTimeout(resolve, 20));
    }
    window?.Rakanto.event('sendCustomData', pixelCusData);
  }
  sendclicktopixel(title){
    let event={url:''};
    event.url= window.location.pathname; 
    this.startPixelTracking(event, 'click',title);
  }
}
