export class ConstantsData {
    public static PADUFullName = {
        PP: 'PADU Pending',
        A: 'Acceptable',
        P: 'Preferred',
        U: 'Unacceptable',
        D: 'Discouraged',
        I: 'Infrastructure',
        R: 'Retired',
        TBR: 'To Be Retired'
    };
    public static PADUShortName = {
        'PADU Pending': 'PP',
        'Acceptable': 'A',
        'Preferred': 'P',
        'Unacceptable': 'U',
        'Discouraged': 'D',
        'Infrastructure': 'I',
        'Retired': 'R',
        'To Be Retired': 'TBR'
    };
    public static PADUArray = [
        { name: 'Preferred', value: 'P' },
        { name: 'Acceptable', value: 'A' },
        { name: 'Discouraged', value: 'D' },
        { name: 'Unacceptable', value: 'U' },
        { name: 'Infrastructure', value: 'I' },
        { name: 'PADU Pending', value: 'PP' },
        // { name: 'Retired', value: 'R' }
    ];
}

export interface User {
    isSelected: boolean;
    id: number;
    name: string;
    email: string;
    phone: string;
    isEdit: boolean;
}

export const USER_SCHEMA = {
    isSelected: "isSelected",
    name: "text",
    email: "text",
    age: "number",
    phone: "text",
    isEdit: "isEdit"
}

export const APP_PADU_SCHEMA = {
    isSelected: "isSelected",

    agg_padu: "text",
    app_id: "text",
    app_name: "text",
    business_criticality: "text",
    business_owner: "text",
    lifecycle_status: "text",
    lob: "text",
    lob_flag: "text",
    current: "text", transition_1: "text", transition_2: "text", transition_3: "text", transition_4: "text",

    service_id: "text",
    slo: "text",
    tcmAmount: "number",
    tech_owner: "text",
    tmdb: "text",
    isEdit: "isEdit"
}

export const USER_INFO = [
    { id: 1, "name": "John Smith", "occupation": "Advisor", "dateOfBirth": "1984-05-05", "age": 36 }
];

export interface YearData {
    avialableData: string;
    currentMonth: string;
    currentMonthFull: string;
    currentMonthIndex: number;
    currentYear: number;
    nextRefreshData: string;
    previousMonth: string;
    previousYear: number;
    refreshDate: string;
    forcastCycle: string;
}
// All Types Column data for Bulk Edit
export const Bulk_Fields = {
    App_Demographics_TABLE_COLUMNS_PASS: [
        {
        key: 'GLOBAL APP ID',
        labelKey: 'GLOBAL APP ID',
        label: 'GLOBAL APP ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'App Lifecycle Status',
        labelKey: 'App Lifecycle Status',
        label: 'App Lifecycle Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Application PADU',
        labelKey: 'Application PADU',
        label: 'Application PADU',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'LOB ID',
        labelKey: 'LOB ID',
        label: 'LOB ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Line Of Business',
        labelKey: 'Line Of Business',
        label: 'Line Of Business',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Sub LOB ID',
        labelKey: 'Sub LOB ID',
        label: 'Sub LOB ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Sub Line Of Business',
        labelKey: 'Sub Line Of Business',
        label: 'Sub Line Of Business',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Category_ID',
        labelKey: 'Category_ID',
        label: 'Category_ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Category',
        labelKey: 'Category',
        label: 'Category',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Application_Type_Id',
        labelKey: 'Application_Type_Id',
        label: 'Application_Type_Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Application Type',
        labelKey: 'Application Type',
        label: 'Application Type',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Application_Hosting_ID',
        labelKey: 'Application_Hosting_ID',
        label: 'Application_Hosting_ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Application Hosting',
        labelKey: 'Application Hosting',
        label: 'Application Hosting',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Status',
        labelKey: 'Status',
        label: 'Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        }
    ],
    App_Demographics_TABLE_COLUMNS_FAIL: [
    {
      key: 'Message',
      labelKey: 'Message',
      label: 'Message',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'GLOBAL APP ID',
      labelKey: 'GLOBAL APP ID',
      label: 'GLOBAL APP ID',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'App Lifecycle Status',
      labelKey: 'App Lifecycle Status',
      label: 'App Lifecycle Status',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Application PADU',
      labelKey: 'Application PADU',
      label: 'Application PADU',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'LOB ID',
      labelKey: 'LOB ID',
      label: 'LOB ID',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Line Of Business',
      labelKey: 'Line Of Business',
      label: 'Line Of Business',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Sub LOB ID',
      labelKey: 'Sub LOB ID',
      label: 'Sub LOB ID',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Sub Line Of Business',
      labelKey: 'Sub Line Of Business',
      label: 'Sub Line Of Business',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Category_ID',
      labelKey: 'Category_ID',
      label: 'Category_ID',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Category',
      labelKey: 'Category',
      label: 'Category',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Application_Type_Id',
      labelKey: 'Application_Type_Id',
      label: 'Application_Type_Id',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Application Type',
      labelKey: 'Application Type',
      label: 'Application Type',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Application_Hosting_ID',
      labelKey: 'Application_Hosting_ID',
      label: 'Application_Hosting_ID',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Application Hosting',
      labelKey: 'Application Hosting',
      label: 'Application Hosting',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    },
    {
      key: 'Status',
      labelKey: 'Status',
      label: 'Status',
      addNew: false,
      filter: false,
      search: '',
      searchValue: '',
      edit: true,
      validations: [{ required: false }]
    }
    ],
    AppPadu_Roadmap_TABLE_COLUMNS_PASS: [
        {
          key: 'GLOBAL APP ID',
          labelKey: 'GLOBAL APP ID',
          label: 'GLOBAL APP ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'LOB ID',
          labelKey: 'LOB ID',
          label: 'LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Sub LOB ID',
          labelKey: 'Sub LOB ID',
          label: 'Sub LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU',
          labelKey: 'Application PADU',
          label: 'Application PADU',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T1',
          labelKey: 'Application PADU T1',
          label: 'Application PADU T1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T2',
          labelKey: 'Application PADU T2',
          label: 'Application PADU T2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T3',
          labelKey: 'Application PADU T3',
          label: 'Application PADU T3',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T4',
          labelKey: 'Application PADU T4',
          label: 'Application PADU T4',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T5',
          labelKey: 'Application PADU T5',
          label: 'Application PADU T5',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T6',
          labelKey: 'Application PADU T6',
          label: 'Application PADU T6',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Decom Forecast QTR',
          labelKey: 'Decom Forecast Qtr',
          label: 'Decom Forecast Qtr',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Decom Forecast Year',
          labelKey: 'Decom Forecast Year',
          label: 'Decom Forecast Year',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Notes / Comments',
          labelKey: 'Notes / Comments',
          label: 'Notes / Comments',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'RoadMap',
          labelKey: 'RoadMap',
          label: 'RoadMap',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Replacement App',
          labelKey: 'Replacement App',
          label: 'Replacement App',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Initiative / Program',
          labelKey: 'Initiative / Program',
          label: 'Initiative / Program',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Business Owned',
          labelKey: 'Business Owned',
          label: 'Business Owned',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Tech/Business Alignment Needed',
          labelKey: 'Tech/Business Alignment Needed',
          label: 'Tech/Business Alignment Needed',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Non Integrated Tech',
          labelKey: 'Non Integrated Tech',
          label: 'Non Integrated Tech',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Migration Forecast QTR',
          labelKey: 'Migration Forecast QTR',
          label: 'Migration Forecast QTR',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Migration Forecast Year',
          labelKey: 'Migration Forecast Year',
          label: 'Migration Forecast Year',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application Strategy',
          labelKey: 'Application Strategy',
          label: 'Application Strategy',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Status',
          labelKey: 'Status',
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        }
    ],
    AppPadu_Roadmap_TABLE_COLUMNS_FAIL: [
        {
          key: 'Error Message',
          labelKey: 'Error Message',
          label: 'Message',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'GLOBAL APP ID',
          labelKey: 'GLOBAL APP ID',
          label: 'GLOBAL APP ID',
          addNew: false,
          filter: true,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'LOB ID',
          labelKey: 'LOB ID',
          label: 'LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Sub LOB ID',
          labelKey: 'Sub LOB ID',
          label: 'Sub LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU',
          labelKey: 'Application PADU',
          label: 'Application PADU',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T1',
          labelKey: 'Application PADU T1',
          label: 'Application PADU T1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T2',
          labelKey: 'Application PADU T2',
          label: 'Application PADU T2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T3',
          labelKey: 'Application PADU T3',
          label: 'Application PADU T3',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T4',
          labelKey: 'Application PADU T4',
          label: 'Application PADU T4',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T5',
          labelKey: 'Application PADU T5',
          label: 'Application PADU T5',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application PADU T6',
          labelKey: 'Application PADU T6',
          label: 'Application PADU T6',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Decom Forecast QTR',
          labelKey: 'Decom Forecast Qtr',
          label: 'Decom Forecast Qtr',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Decom Forecast Year',
          labelKey: 'Decom Forecast Year',
          label: 'Decom Forecast Year',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Notes / Comments',
          labelKey: 'Notes / Comments',
          label: 'Notes / Comments',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'RoadMap',
          labelKey: 'RoadMap',
          label: 'RoadMap',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Replacement App',
          labelKey: 'Replacement App',
          label: 'Replacement App',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Initiative / Program',
          labelKey: 'Initiative / Program',
          label: 'Initiative / Program',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Business Owned',
          labelKey: 'Business Owned',
          label: 'Business Owned',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Tech/Business Alignment Needed',
          labelKey: 'Tech/Business Alignment Needed',
          label: 'Tech/Business Alignment Needed',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Non Integrated Tech',
          labelKey: 'Non Integrated Tech',
          label: 'Non Integrated Tech',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Migration Forecast QTR',
          labelKey: 'Migration Forecast QTR',
          label: 'Migration Forecast QTR',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Migration Forecast Year',
          labelKey: 'Migration Forecast Year',
          label: 'Migration Forecast Year',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Application Strategy',
          labelKey: 'Application Strategy',
          label: 'Application Strategy',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Status',
          labelKey: 'Status',
          label: 'Status',
          addNew: false,
          filter: true,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        }
    ],
    Capability_Mapping_TABLE_COLUMNS_PASS: [
        {
        key: 'GLOBAL APP ID',
        labelKey: 'GLOBAL APP ID',
        label: 'GLOBAL APP ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'LOB ID',
        labelKey: 'LOB ID',
        label: 'LOB ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Sub LOB ID',
        labelKey: 'Sub LOB ID',
        label: 'Sub LOB ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'MASTER SEGMENT',
        labelKey: 'MASTER SEGMENT',
        label: 'MASTER SEGMENT',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'App Lifecycle Status',
        labelKey: 'App Lifecycle Status',
        label: 'App Lifecycle Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Group Model',
        labelKey: 'Group Model',
        label: 'Group Model',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Organization ID',
        labelKey: 'Organization ID',
        label: 'Organization ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L0 Id',
        labelKey: 'Capability L0 Id',
        label: 'Capability L0 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L1 Id',
        labelKey: 'Capability L1 Id',
        label: 'Capability L1 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L2 Id',
        labelKey: 'Capability L2 Id',
        label: 'Capability L2 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L3 Id',
        labelKey: 'Capability L3 Id',
        label: 'Capability L3 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU',
        labelKey: 'Capability PADU',
        label: 'Capability PADU',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T1',
        labelKey: 'Capability PADU T1',
        label: 'Capability PADU T1',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T2',
        labelKey: 'Capability PADU T2',
        label: 'Capability PADU T2',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T3',
        labelKey: 'Capability PADU T3',
        label: 'Capability PADU T3',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T4',
        labelKey: 'Capability PADU T4',
        label: 'Capability PADU T4',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T5',
        labelKey: 'Capability PADU T5',
        label: 'Capability PADU T5',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T6',
        labelKey: 'Capability PADU T6',
        label: 'Capability PADU T6',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Status',
        labelKey: 'Status',
        label: 'Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        }
    ],
    Capability_Mapping_TABLE_COLUMNS_FAIL: [
        {
        key: 'Message',
        labelKey: 'Message',
        label: 'Message',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'GLOBAL APP ID',
        labelKey: 'GLOBAL APP ID',
        label: 'GLOBAL APP ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'LOB ID',
        labelKey: 'LOB ID',
        label: 'LOB ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Sub LOB ID',
        labelKey: 'Sub LOB ID',
        label: 'Sub LOB ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'MASTER SEGMENT',
        labelKey: 'MASTER SEGMENT',
        label: 'MASTER SEGMENT',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'App Lifecycle Status',
        labelKey: 'App Lifecycle Status',
        label: 'App Lifecycle Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Group Model',
        labelKey: 'Group Model',
        label: 'Group Model',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Organization ID',
        labelKey: 'Organization ID',
        label: 'Organization ID',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L0 Id',
        labelKey: 'Capability L0 Id',
        label: 'Capability L0 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L1 Id',
        labelKey: 'Capability L1 Id',
        label: 'Capability L1 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L2 Id',
        labelKey: 'Capability L2 Id',
        label: 'Capability L2 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability L3 Id',
        labelKey: 'Capability L3 Id',
        label: 'Capability L3 Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU',
        labelKey: 'Capability PADU',
        label: 'Capability PADU',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T1',
        labelKey: 'Capability PADU T1',
        label: 'Capability PADU T1',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T2',
        labelKey: 'Capability PADU T2',
        label: 'Capability PADU T2',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T3',
        labelKey: 'Capability PADU T3',
        label: 'Capability PADU T3',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T4',
        labelKey: 'Capability PADU T4',
        label: 'Capability PADU T4',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T5',
        labelKey: 'Capability PADU T5',
        label: 'Capability PADU T5',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Capability PADU T6',
        labelKey: 'Capability PADU T6',
        label: 'Capability PADU T6',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        },
        {
        key: 'Status',
        labelKey: 'Status',
        label: 'Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
        }
    ],
    Model_Edit_TABLE_COLUMNS_PASS: [
        {
          key: 'Group_Model',
          labelKey: 'Group_Model',
          label: 'Group_Model',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L0_Id',
          labelKey: 'Capability_L0_Id',
          label: 'Capability_L0_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L0',
          labelKey: 'Capability_L0',
          label: 'Capability_L0',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L0_Desc',
          labelKey: 'Capability_L0_Desc',
          label: 'Capability_L0_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L1_Id',
          labelKey: 'Capability_L1_Id',
          label: 'Capability_L1_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L1',
          labelKey: 'Capability_L1',
          label: 'Capability_L1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L1_Desc',
          labelKey: 'Capability_L1_Desc',
          label: 'Capability_L1_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L2_Id',
          labelKey: 'Capability_L2_Id',
          label: 'Capability_L2_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L2',
          labelKey: 'Capability_L2',
          label: 'Capability_L2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L2_Desc',
          labelKey: 'Capability_L2_Desc',
          label: 'Capability_L2_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L3_Id',
          labelKey: 'Capability_L3_Id',
          label: 'Capability_L3_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L3',
          labelKey: 'Capability_L3',
          label: 'Capability_L3',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L3_Desc',
          labelKey: 'Capability_L3_Desc',
          label: 'Capability_L3_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Status',
          labelKey: 'Status',
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        }
    ],
    Model_Edit_TABLE_COLUMNS_FAIL: [
        {
          key: 'Message',
          labelKey: 'Message',
          label: 'Message',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Group_Model',
          labelKey: 'Group_Model',
          label: 'Group_Model',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L0_Id',
          labelKey: 'Capability_L0_Id',
          label: 'Capability_L0_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L0',
          labelKey: 'Capability_L0',
          label: 'Capability_L0',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L0_Desc',
          labelKey: 'Capability_L0_Desc',
          label: 'Capability_L0_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L1_Id',
          labelKey: 'Capability_L1_Id',
          label: 'Capability_L1_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L1',
          labelKey: 'Capability_L1',
          label: 'Capability_L1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L1_Desc',
          labelKey: 'Capability_L1_Desc',
          label: 'Capability_L1_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L2_Id',
          labelKey: 'Capability_L2_Id',
          label: 'Capability_L2_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L2',
          labelKey: 'Capability_L2',
          label: 'Capability_L2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L2_Desc',
          labelKey: 'Capability_L2_Desc',
          label: 'Capability_L2_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L3_Id',
          labelKey: 'Capability_L3_Id',
          label: 'Capability_L3_Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L3',
          labelKey: 'Capability_L3',
          label: 'Capability_L3',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability_L3_Desc',
          labelKey: 'Capability_L3_Desc',
          label: 'Capability_L3_Desc',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Status',
          labelKey: 'Status',
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        }
    ],
    Primary_Upload_TABLE_COLUMNS_PASS: [
        {
          key: 'GLOBAL APP ID',
          labelKey: 'GLOBAL APP ID',
          label: 'GLOBAL APP ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'App Lifecycle Status',
          labelKey: 'App Lifecycle Status',
          label: 'App Lifecycle Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L0 Id',
          labelKey: 'Capability L0 Id',
          label: 'Capability L0 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L0',
          labelKey: 'Capability L0',
          label: 'Capability L0',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L1 Id',
          labelKey: 'Capability L1 Id',
          label: 'Capability L1 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L1',
          labelKey: 'Capability L1',
          label: 'Capability L1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L2 Id',
          labelKey: 'Capability L2 Id',
          label: 'Capability L2 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L2',
          labelKey: 'Capability L2',
          label: 'Capability L2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L3 Id',
          labelKey: 'Capability L3 Id',
          label: 'Capability L3 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L3',
          labelKey: 'Capability L3',
          label: 'Capability L3',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Group Model',
          labelKey: 'Group Model',
          label: 'Group Model',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
  
        {
          key: 'LOB ID',
          labelKey: 'LOB ID',
          label: 'LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Sub LOB ID',
          labelKey: 'Sub LOB ID',
          label: 'Sub LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Primary Status',
          labelKey: 'Primary Status',
          label: 'Primary Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'LONG APP NAME',
          labelKey: 'LONG APP NAME',
          label: 'LONG APP NAME',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Organization ID',
          labelKey: 'Organization ID',
          label: 'Organization ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Status',
          labelKey: 'Status',
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        }
    ],
    Primary_Upload_TABLE_COLUMNS_FAIL: [
        {
          key: 'Message',
          labelKey: 'Message',
          label: 'Message',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'GLOBAL APP ID',
          labelKey: 'GLOBAL APP ID',
          label: 'GLOBAL APP ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'App Lifecycle Status',
          labelKey: 'App Lifecycle Status',
          label: 'App Lifecycle Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L0 Id',
          labelKey: 'Capability L0 Id',
          label: 'Capability L0 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L0',
          labelKey: 'Capability L0',
          label: 'Capability L0',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L1 Id',
          labelKey: 'Capability L1 Id',
          label: 'Capability L1 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L1',
          labelKey: 'Capability L1',
          label: 'Capability L1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L2 Id',
          labelKey: 'Capability L2 Id',
          label: 'Capability L2 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L2',
          labelKey: 'Capability L2',
          label: 'Capability L2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L3 Id',
          labelKey: 'Capability L3 Id',
          label: 'Capability L3 Id',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Capability L3',
          labelKey: 'Capability L3',
          label: 'Capability L3',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Group Model',
          labelKey: 'Group Model',
          label: 'Group Model',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
  
        {
          key: 'LOB ID',
          labelKey: 'LOB ID',
          label: 'LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Sub LOB ID',
          labelKey: 'Sub LOB ID',
          label: 'Sub LOB ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Primary Status',
          labelKey: 'Primary Status',
          label: 'Primary Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'LONG APP NAME',
          labelKey: 'LONG APP NAME',
          label: 'LONG APP NAME',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Organization ID',
          labelKey: 'Organization ID',
          label: 'Organization ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        },
        {
          key: 'Status',
          labelKey: 'Status',
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ required: false }]
        }
    ],
    Configurable_TABLE_COLUMNS_PASS: [
        {
          key: 'ASK_GLOBAL_ID',
          labelKey: "ASK_GLOBAL_ID",
          label: 'ASK_GLOBAL_ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'LONG_APP_NAME',
          labelKey: "LONG_APP_NAME",
          label: 'LONG_APP_NAME',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Category',
          labelKey: "Category",
          label: 'Category',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Application_Type',
          labelKey: "Application_Type",
          label: 'Application_Type',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Application_Hosting',
          labelKey: "Application_Hosting",
          label: 'Application_Hosting',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'App Lifecycle_Status',
          labelKey: "App Lifecycle_Status",
          label: 'App Lifecycle_Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'SERVICE_LEVEL_OWNER',
          labelKey: "SERVICE_LEVEL_OWNER",
          label: 'SERVICE_LEVEL_OWNER',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Technical_Owner',
          labelKey: "Technical_Owner",
          label: 'Technical_Owner',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Business_Owner',
          labelKey: "Business_Owner",
          label: 'Business_Owner',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Master_Segment',
          labelKey: "Master_Segment",
          label: 'Master_Segment',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'LOB_ID',
          labelKey: "LOB_ID",
          label: 'LOB_ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'CIO/LOB',
          labelKey: "CIO/LOB",
          label: 'CIO/LOB',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'SubLOB_ID',
          labelKey: "SubLOB_ID",
          label: 'SubLOB_ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Line_Of_Business',
          labelKey: "Line_Of_Business",
          label: 'Line_Of_Business',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G15',
          labelKey: "G15",
          label: 'G15',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G16',
          labelKey: "G16",
          label: 'G16',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G17',
          labelKey: "G17",
          label: 'G17',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G18',
          labelKey: "G18",
          label: 'G18',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G19',
          labelKey: "G19",
          label: 'G19',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G20',
          labelKey: "G20",
          label: 'G20',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'App Workload Type',
          labelKey: "App Workload Type",
          label: 'App Workload Type',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'CI1',
          labelKey: "CI1",
          label: 'CI1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'CI2',
          labelKey: "CI2",
          label: 'CI2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'App R Disposition',
          labelKey: "App R Disposition",
          label: 'App R Disposition',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Preferred CSP',
          labelKey: "Preferred CSP",
          label: 'Preferred CSP',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Oracle Database R',
          labelKey: "Oracle Database R",
          label: 'Oracle Database R',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Oracle DB Decom Yr Complete',
          labelKey: "Oracle DB Decom Yr Complete",
          label: 'Oracle DB Decom Yr Complete',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Oracle Source',
          labelKey: "Oracle Source",
          label: 'Oracle Source',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Migration Driver',
          labelKey: "Migration Driver",
          label: 'Migration Driver',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Funding',
          labelKey: "Funding",
          label: 'Funding',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Migration Estimate',
          labelKey: "Migration Estimate",
          label: 'Migration Estimate',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Funding Source',
          labelKey: "Funding Source",
          label: 'Funding Source',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Comments',
          labelKey: "Comments",
          label: 'Comments',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'GRdio_Test15',
          labelKey: "Rdio_Test",
          label: 'Rdio_Test',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E12',
          labelKey: "E12",
          label: 'E12',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E13',
          labelKey: "E13",
          label: 'E13',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E14',
          labelKey: "E14",
          label: 'E14',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E15',
          labelKey: "E15",
          label: 'E15',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E16',
          labelKey: "E16",
          label: 'E16',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E17',
          labelKey: "E17",
          label: 'E17',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E18',
          labelKey: "E18",
          label: 'E18',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E19',
          labelKey: "E19",
          label: 'E19',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E20',
          labelKey: "E20",
          label: 'E20',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E21',
          labelKey: "E21",
          label: 'E21',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E22',
          labelKey: "E22",
          label: 'E22',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Plan Year for Migration',
          labelKey: "Plan Year for Migration",
          label: 'Plan Year for Migration',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E24',
          labelKey: "E24",
          label: 'E24',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E25',
          labelKey: "E25",
          label: 'E25',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E26',
          labelKey: "E26",
          label: 'E26',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E27',
          labelKey: "E27",
          label: 'E27',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Status',
          labelKey: "Status",
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        }
    ],
    Configurable_TABLE_COLUMNS_FAIL: [
        {
          key: 'Message',
          labelKey: "Message",
          label: 'Message',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'ASK_GLOBAL_ID',
          labelKey: "ASK_GLOBAL_ID",
          label: 'ASK_GLOBAL_ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'LONG_APP_NAME',
          labelKey: "LONG_APP_NAME",
          label: 'LONG_APP_NAME',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Category',
          labelKey: "Category",
          label: 'Category',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Application_Type',
          labelKey: "Application_Type",
          label: 'Application_Type',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Application_Hosting',
          labelKey: "Application_Hosting",
          label: 'Application_Hosting',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'App Lifecycle_Status',
          labelKey: "App Lifecycle_Status",
          label: 'App Lifecycle_Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'SERVICE_LEVEL_OWNER',
          labelKey: "SERVICE_LEVEL_OWNER",
          label: 'SERVICE_LEVEL_OWNER',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Technical_Owner',
          labelKey: "Technical_Owner",
          label: 'Technical_Owner',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Business_Owner',
          labelKey: "Business_Owner",
          label: 'Business_Owner',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Master_Segment',
          labelKey: "Master_Segment",
          label: 'Master_Segment',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'LOB_ID',
          labelKey: "LOB_ID",
          label: 'LOB_ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'CIO/LOB',
          labelKey: "CIO/LOB",
          label: 'CIO/LOB',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'SubLOB_ID',
          labelKey: "SubLOB_ID",
          label: 'SubLOB_ID',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Line_Of_Business',
          labelKey: "Line_Of_Business",
          label: 'Line_Of_Business',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G15',
          labelKey: "G15",
          label: 'G15',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G16',
          labelKey: "G16",
          label: 'G16',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G17',
          labelKey: "G17",
          label: 'G17',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G18',
          labelKey: "G18",
          label: 'G18',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G19',
          labelKey: "G19",
          label: 'G19',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'G20',
          labelKey: "G20",
          label: 'G20',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'App Workload Type',
          labelKey: "App Workload Type",
          label: 'App Workload Type',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'CI1',
          labelKey: "CI1",
          label: 'CI1',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'CI2',
          labelKey: "CI2",
          label: 'CI2',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'App R Disposition',
          labelKey: "App R Disposition",
          label: 'App R Disposition',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Preferred CSP',
          labelKey: "Preferred CSP",
          label: 'Preferred CSP',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Oracle Database R',
          labelKey: "Oracle Database R",
          label: 'Oracle Database R',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Oracle DB Decom Yr Complete',
          labelKey: "Oracle DB Decom Yr Complete",
          label: 'Oracle DB Decom Yr Complete',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Oracle Source',
          labelKey: "Oracle Source",
          label: 'Oracle Source',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Migration Driver',
          labelKey: "Migration Driver",
          label: 'Migration Driver',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Funding',
          labelKey: "Funding",
          label: 'Funding',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Migration Estimate',
          labelKey: "Migration Estimate",
          label: 'Migration Estimate',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Funding Source',
          labelKey: "Funding Source",
          label: 'Funding Source',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Comments',
          labelKey: "Comments",
          label: 'Comments',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'GRdio_Test15',
          labelKey: "Rdio_Test",
          label: 'Rdio_Test',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E12',
          labelKey: "E12",
          label: 'E12',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E13',
          labelKey: "E13",
          label: 'E13',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E14',
          labelKey: "E14",
          label: 'E14',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E15',
          labelKey: "E15",
          label: 'E15',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E16',
          labelKey: "E16",
          label: 'E16',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E17',
          labelKey: "E17",
          label: 'E17',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E18',
          labelKey: "E18",
          label: 'E18',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E19',
          labelKey: "E19",
          label: 'E19',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E20',
          labelKey: "E20",
          label: 'E20',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E21',
          labelKey: "E21",
          label: 'E21',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E22',
          labelKey: "E22",
          label: 'E22',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Plan Year for Migration',
          labelKey: "Plan Year for Migration",
          label: 'Plan Year for Migration',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E24',
          labelKey: "E24",
          label: 'E24',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E25',
          labelKey: "E25",
          label: 'E25',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E26',
          labelKey: "E26",
          label: 'E26',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'E27',
          labelKey: "E27",
          label: 'E27',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        },
        {
          key: 'Status',
          labelKey: "Status",
          label: 'Status',
          addNew: false,
          filter: false,
          search: '',
          searchValue: '',
          edit: true,
          validations: [{ 'required': false }]
        }
        
        
    ],
    Forecast_Table_Columns_Pass: [
      {
        key: 'Update Type',
        labelKey: "Update Type",
        label: 'Update Type',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'ASK_Global_Id',
        labelKey: "ASK_Global_Id",
        label: 'ASK_Global_Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'LONG_APP_NAME',
        labelKey: "LONG_APP_NAME",
        label: 'LONG_APP_NAME',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'service',
        labelKey: 'service',
        label: 'service',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'year',
        labelKey: 'year',
        label: 'year',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Jan',
        labelKey: 'QNT_Jan',
        label: 'QNT_Jan',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Feb',
        labelKey: 'QNT_Feb',
        label: 'QNT_Feb',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Mar',
        labelKey: 'QNT_Mar',
        label: 'QNT_Mar',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Apr',
        labelKey: 'QNT_Apr',
        label: 'QNT_Apr',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_May',
        labelKey: 'QNT_May',
        label: 'QNT_May',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Jun',
        labelKey: 'QNT_Jun',
        label: 'QNT_Jun',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Jul',
        labelKey: 'QNT_Jul',
        label: 'QNT_Jul',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Aug',
        labelKey: 'QNT_Aug',
        label: 'QNT_Aug',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Sep',
        labelKey: 'QNT_Sep',
        label: 'QNT_Sep',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Oct',
        labelKey: 'QNT_Oct',
        label: 'QNT_Oct',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Nov',
        labelKey: 'QNT_Nov',
        label: 'QNT_Nov',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Dec',
        labelKey: 'QNT_Dec',
        label: 'QNT_Dec',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'Status',
        labelKey: 'Status',
        label: 'Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
      }
    ],
    Forecast_Table_Columns_Fail: [
      {
        key: 'Message',
        labelKey: "Message",
        label: 'Message',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'Update Type',
        labelKey: "Update Type",
        label: 'Update Type',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'ASK_Global_Id',
        labelKey: "ASK_Global_Id",
        label: 'ASK_Global_Id',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'LONG_APP_NAME',
        labelKey: "LONG_APP_NAME",
        label: 'LONG_APP_NAME',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'service',
        labelKey: 'service',
        label: 'service',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'year',
        labelKey: 'year',
        label: 'year',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Jan',
        labelKey: 'QNT_Jan',
        label: 'QNT_Jan',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Feb',
        labelKey: 'QNT_Feb',
        label: 'QNT_Feb',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Mar',
        labelKey: 'QNT_Mar',
        label: 'QNT_Mar',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Apr',
        labelKey: 'QNT_Apr',
        label: 'QNT_Apr',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_May',
        labelKey: 'QNT_May',
        label: 'QNT_May',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Jun',
        labelKey: 'QNT_Jun',
        label: 'QNT_Jun',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Jul',
        labelKey: 'QNT_Jul',
        label: 'QNT_Jul',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Aug',
        labelKey: 'QNT_Aug',
        label: 'QNT_Aug',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Sep',
        labelKey: 'QNT_Sep',
        label: 'QNT_Sep',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Oct',
        labelKey: 'QNT_Oct',
        label: 'QNT_Oct',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Nov',
        labelKey: 'QNT_Nov',
        label: 'QNT_Nov',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'QNT_Dec',
        labelKey: 'QNT_Dec',
        label: 'QNT_Dec',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ 'required': false }]
      },
      {
        key: 'Status',
        labelKey: 'Status',
        label: 'Status',
        addNew: false,
        filter: false,
        search: '',
        searchValue: '',
        edit: true,
        validations: [{ required: false }]
      }
    ]
}