import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appModernizationDetails } from '../../config';
import { environment } from '../../../environments/environment';
import { Observable, of} from 'rxjs';
import { IArchitectDetailsEntity } from '../interface/interface';
import { AppHubCacheService } from './app-hub-cache.service';
import { map, retry } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private apiUrl: string;
  private config: any;
  public subject = new BehaviorSubject(false);
  constructor(private http: HttpClient, private cache: AppHubCacheService) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
  }

  public getAppListFilter(): Observable<any> {
    // const obj = {
    //   q: decodeURI(search),
    //   page: page,
    //   per_page:itemPerPage,
    //   search_in: search_in
    // }
    // const url: string = this.apiUrl + this.config.routerpath.applist;

    // return this.http.get<any>(url,  { params: obj }).pipe(
    //   map(data => {
    //     this.subject.next(true);
    //     return data;
    //   })
    // );
    if (!sessionStorage.getItem('applicationList')) {
      const appListUrl: string = this.apiUrl + this.config.routerpath.applist;
      const applicationList = this.http.get(appListUrl).pipe(
        retry(3),
        map(
          res => {
            sessionStorage.setItem('applicationList', JSON.stringify(res));
            this.subject.next(true);
            return res;
          })
      );
      return applicationList;
    } else {
      this.subject.next(true);
      return of(JSON.parse(sessionStorage.getItem('applicationList')));
    }

  }

  updateSubjectData(theChangedProp: boolean) {
    this.subject.next(theChangedProp);
  }


  public getLobList(withModal = false): Observable<Array<any>> {
    let url = this.apiUrl + this.config.routerpath.lobList;
    if (withModal) {
      url = this.apiUrl + this.config.routerpath.lobListWithModal;
    }
    const cache = this.cache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          const lobList = withModal ? data : this.formatLobList(data);
          this.cache.setCache(url, lobList);
          return lobList;
        })
      );
    } else {
      return of(cache);
    }
  }


  public getCioSubLobList(): Observable<any> {
    const url = this.apiUrl + '/lob-view/loblist';
    const cache = this.cache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.cache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }

    // if (!sessionStorage.getItem('cioLobList')) {
    //   const cioListUrl: string =  this.apiUrl + this.config.routerpath.lobListWithModal;
    //   const cioList = this.http.get(cioListUrl).pipe(map(res => {
    //     sessionStorage.setItem('cioLobList', JSON.stringify(res));
    //     return res;
    //   })
    //   );
    //   return cioList;
    // } else {
    //   return  of(JSON.parse(sessionStorage.getItem('cioLobList')));
    // }
  }



  public getGroupmodel(): Observable<Array<string>> {
    const url = this.apiUrl + this.config.routerpath.modelList;
    const cache = this.cache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.cache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }
  }

  public getGroupmodelV1(): Observable<Array<string>> {
    const url = this.apiUrl + this.config.routerpath.modelListV1;
    const cache = this.cache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.cache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }
  }

  private formatLobList(lobList: Array<string>): Array<string> {
    const lobConfig = this.config.lobListOrder;
    const sortedLob = [];
    const mapObj = {
      uhc: lobConfig.sortOrder.uhcOption.length,
      optum: lobConfig.sortOrder.optumOption.length
    };
    lobList.forEach((lob, i) => {
      if (!lobConfig.skip.find(val => val.toLowerCase() === lob.toLowerCase())) {
        if (lob.toLowerCase().indexOf('uhc ') > -1) {
          const index = lobConfig.sortOrder.uhcOption.indexOf(lob);
          if (index > -1) {
            sortedLob.push({ lob, index });
          } else {
            sortedLob.push({ lob, index: mapObj.uhc });
          }
          mapObj.uhc += 1;
        } else if (lob.toLowerCase().indexOf('optum') > -1) {
          const index = lobConfig.sortOrder.optumOption.indexOf(lob);
          if (index > -1) {
            sortedLob.push({ lob, index: 200 + index });
          } else {
            sortedLob.push({ lob, index: 200 + mapObj.optum });
          }
          mapObj.optum += 1;
        } else if (lobConfig.sortOrder.midOption.find(val => val.toLowerCase() === lob.toLowerCase())) {
          const index = lobConfig.sortOrder.midOption.indexOf(lob);
          sortedLob.push({ lob, index: 100 + index });
        } else {
          sortedLob.push({ lob, index: 300 + i });
        }
      }
    });
    const result = sortedLob.sort((a, b) => a.index - b.index).map(lob => lob.lob);

    return result;
  }

  public getSubject() {
    return this.subject;
  }

  public setSubject(value) {
    this.subject.next(value);
  }

  public updateLobDetails(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updateLobDetails;
    const body = req;
    return this.http.put<any>(url, body);
  }
  public getArchitectDetails(body): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getArchitectDetails;
    // const cache = this.cache.getCache(url);

    return this.http.post<IArchitectDetailsEntity>(url, body);

  }
  public getUsernameMSID(msId): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getMSIDName;

    return this.http.get<any>(url + msId);

  }

  public getLobSubLobList(): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.lobListWithSegment;
    const cache = this.cache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.cache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }
  }

  public getPaginatedAppList(page, search): Observable<any> {
    if (search) {
      let body = { page: page, search: search }
      const url: string = `${this.apiUrl}${this.config.routerpath.getAllPaginatedAppList}`;
      return this.http.post<any>(url, body);
      // const url: string = `${this.apiUrl}${this.config.routerpath.getAllPaginatedAppList}?page=${page}&search=${search}`;
      // return this.http.get<any>(url);
    }
  }

  public getAppName(appId): Observable<any> {
      const url: string = `${this.apiUrl}${this.config.routerpath.getApplicationName}`;
      return this.http.get<any>(url, { params: { appId } });
      
  }
}
