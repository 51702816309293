import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { workQueuesAPIs } from '../configV4';


@Injectable({
    providedIn: 'root'
})
export class WorkQueuesService {
    private apiUrl: string;
    public configV4: any;
    wqAccessData$ = new BehaviorSubject<any>({});
    wqOpenReqData$ = new BehaviorSubject<any>({});
    initialCapabilityData:any = null;

    constructor(private http: HttpClient, private http2: HttpClient, handler: HttpBackend) {
        this.configV4 = workQueuesAPIs;
        this.apiUrl = environment.apiUrl;
        this.http2 = new HttpClient(handler);
      }
    public updateLobWorkQueueRequest(body): Observable<any> {
        const url = this.apiUrl + this.configV4.routerPath.updateLobRequest;
        return this.http.post<any>(url, body);
      }

    getWorkQueueReqDetailsForPopUp(body) : Observable<any> {
        const url = this.apiUrl + this.configV4.routerPath.getWorkqueueReqDetails;
        return this.http.post<any>(url, body);
    }
    public getWQRequests(body) {
        const url = environment.apiUrl + this.configV4.routerPath.getWQRequests;
        return this.http.post<any>(url, body);
    }
    public getRequests(body) {
        const url = environment.apiUrl + this.configV4.routerPath.getRequests;
        return this.http.post<any>(url, body);
    }
    public getWorkQueueTypesAndAccessDetails(){
      const url = environment.apiUrl + this.configV4.routerPath.getWorkQueueAccess;
      return this.http.get<any>(url).pipe(
        map(data => {
          this.setWqData(data);
          return data;
        })
      )
    }
    getWqData() {
      return this.wqAccessData$;
    }
    setWqData(obj) {
      this.wqAccessData$.next(obj);
    }
    public getOpenRequestDetailsForAnApplication(body){
      const url = environment.apiUrl + this.configV4.routerPath.getOpenRequestDetailsForAnApp;
      return this.http.post<any>(url, body).pipe(
        map(data => {
          this.setOpenReqData(data);
          return data;
        })
      );
    }
    setOpenReqData(data){
      this.wqOpenReqData$.next(data);
    }
    getOpenReqData(){
      return this.wqOpenReqData$;
    }
    public updateOpenRequest(body) {
      const url = this.apiUrl + this.configV4.routerPath.UpdateOpenWQRequest;
      return this.http.post<any>(url, body);
    }
    public UpdateOpenWQRequest(body) {
      const url = this.apiUrl + this.configV4.routerPath.cancelOpenRequest;
      return this.http.post<any>(url, body );
    }
    public UpdateBulkOpenWQRequest(body) {
      const url = this.apiUrl + this.configV4.routerPath.updateBulkOpenWQRequest;
      return this.http.post<any>(url, body );
    }
    public getDashboardWorkqueueData(body){
      const url = this.apiUrl + this.configV4.routerPath.getDashboardWorkqueue;
      return this.http.post<any>(url,body);
    }
    public updatePaduUpdateRequest(body){
      const url = this.apiUrl + this.configV4.routerPath.updatePaduUpdateRequest;
      return this.http.post<any>(url,body);
    }
    public createPaduUpdate(body) {
      const url = this.apiUrl + this.configV4.routerPath.insertPADUCreation;
      return this.http.post<any>(url,body);
    }
    public getAssociatedCapabilityOpenRequest(body) {
      const url = this.apiUrl + this.configV4.routerPath.getAssociatedCapability;
      return this.http.post<any>(url,body).pipe(map((data)=>{
        const output: any = [];
        data.forEach(item => {
          const rowItem: any = {};
          const currentPADU = item.Current_PADU.split(',');
          if (item.Capability_L3 == "NULL" || item.Capability_L3 == "null" || item.Capability_L3 == null) {
            if (item.Capability_L2 == "NULL" || item.Capability_L2 == "null" || item.Capability_L2 == null) {
              rowItem.capability = item.Capability_L1;
              rowItem.selectedCapabilityID = item.Capability_L1_Id;
              rowItem.selectedCapability = item.Capability_L1;
            } else {
              rowItem.capability = item.Capability_L2;
              rowItem.selectedCapabilityID = item.Capability_L2_Id;
              rowItem.selectedCapability = item.Capability_L2;
            }
          } else {
            rowItem.capability = item.Capability_L3;
            rowItem.selectedCapabilityID = item.Capability_L3_Id;
            rowItem.selectedCapability = item.Capability_L3;
          }
          rowItem.WQID = item.WQID;
          rowItem.capabilityL3 = item.Capability_L3;
          rowItem.capabilityL2 = item.Capability_L2;
          rowItem.capabilityL1 = item.Capability_L1;
          rowItem.capabilityL0 = item.Capability_L0;
          rowItem.current_padu = item.Current_PADU;
          rowItem.organization = item.Organization_Name;
          rowItem.capabilityL0Id = item.Capability_L0_Id;
          rowItem.capabilityL1Id = item.Capability_L1_Id;
          rowItem.capabilityL2Id = item.Capability_L2_Id;
          rowItem.capabilityL3Id = item.Capability_L3_Id;
          rowItem.org_id = item.Organization_ID;
          rowItem.group_model = item.Group_Model;
          rowItem.transition_1 = currentPADU[1];
          rowItem.transition_2 = currentPADU[2];
          rowItem.transition_3 = currentPADU[3];
          rowItem.transition_4 = currentPADU[4];
          rowItem.transition_5 = currentPADU[5];
          rowItem.transition_6 = currentPADU[6];
          rowItem.primary_status = item.Primary_Status;
          rowItem.Request_Id = item.Request_Id;
          // rowItem.no_of_organisations = item.organisations.length;
          // rowItem.organisations = item.organisations;
          // rowItem.indeterminate = false;
          rowItem.expand = false;
          rowItem.id = item.id;
          output.push(rowItem);
        })
        return output;
      }))
    }

    public addUpdateCapabilityWorkqueue(body) {
      const url = this.apiUrl + this.configV4.routerPath.addUpdateWorkqueueCapability;
      return this.http.post<any>(url,body);
    }

    public onCapabilityUpdateReq(body) {
      const url = this.apiUrl + this.configV4.routerPath.updateCapWorkQue;
      return this.http.post<any>(url,body);
    }

    setInitialDataForCapabilityProposal(res){
      this.initialCapabilityData = res;
    }
    getInitialDataForCapabilityProposal(){
      return this.initialCapabilityData;
    }
}