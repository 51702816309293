import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { appModernizationDetails } from 'src/app/config';
import { AppHubCacheService } from 'src/app/shared/services/app-hub-cache.service';
import { environment } from 'src/environments/environment';
import pdfMake from "pdfmake/build/pdfmake";
import { AppHubUtilService } from 'src/app/shared/services/app-hub-util.service';


@Injectable({
  providedIn: 'root'
})
export class ProductMappingService {
  private apiUrl: string;
  private config: any;
  constructor(private http: HttpClient, private dataCache: AppHubCacheService, private util: AppHubUtilService) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;

  }


  getProductMappingFilter(): Observable<any> {
    // if (!sessionStorage.getItem('appProductMapping')) {
    //   const productListUrl: string = this.apiUrl + this.config.routerpath.productMappingFilter;
    //   const appProductlist = this.http.get(productListUrl).pipe(
    //     map(res => {
    //       const prod = JSON.stringify({
    //         product: res});
    //       sessionStorage.setItem('appProductMapping', prod);
    //       return res;
    //     })
    //   );
    //   return appProductlist;
    // } else {
    //   return of(JSON.parse(sessionStorage.getItem('appProductMapping')));
    // }

    const url = this.apiUrl + this.config.routerpath.productMappingFilter;


    // const cache = this.dataCache.getCache(url);
    // console.log(cache);
    // const getAllData$ = cache
    //   ? of(cache)
    //   : this.http
    //     .get<Array<any>>(url)
    //     .pipe(
    //       retry(3),
    //       map(data => {
    //         this.dataCache.setCache(url, data);
    //         return data;
    //       })
    //     );
    // return getAllData$;
    // const url: string = this.apiUrl + this.config.routerpath.productMappingFilter;
    return this.http.get<any>(url);
  }
  /**
     * API call to fetch all the employees of labor hub
     * @param obj 
     */
  getProductMappingData(body, data): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getProductMappingTableData + `?page=${data.pageNumber}&per_page=${data.pageSize}`;
    // const obj = {
    //   page:data.pageNumber,
    //   per_page: data.pageSize
    // }
    return this.http.post<any>(url, body).pipe(
      retry(3),
      map((data) => {
        return data;
      })
    );
    
  }

  getProductMappingEditData(params): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getProductMappingDetailforEdit;
    return this.http.get<any>(url, { params: { askId: params.askId, organization: params.organization } });
  }

  getUsernameMSID(msId): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getMSIDName;
    return this.http.get<any>(url + msId);
  }

  saveProductMappingData(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getSaveProductMapping;
    // const urlProductFilterData: string = this.apiUrl + this.config.routerpath.productMappingFilter;
    // const cache = this.dataCache.getCache(urlProductFilterData);
    return this.http.post<any>(url, body);
    // return this.http.post<any>(url, body).pipe( map(data => {
    //   cache.unshift({
    //     app_id: data[0].app_id,
    //     application: data[0].application,
    //     cio: data[0].cio,
    //     lob: data[0].lob,
    //     organization: data[0].organization,
    //     product: data[0].product,
    //     product_family: data[0].product_family
    //   });
    //   return data;
    // }));
  }

  getProductGridData(org?: string): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getProductGridDetail;
    if (org) {
      return this.http.get<any>(url, { params: { organization: org } });
    } else {
      return this.http.get<any>(url);
    }

  }

  getProduct360BasedOnProdCap(id): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getProduct360BasedOnProdCap;

    return this.http.get<any>(url, { params: { productCapId: id } });
  }


  editMapping(id, body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.editMapping + id;
    // const urlProductFilterData: string = this.apiUrl + this.config.routerpath.productMappingFilter;
    //   const cache = this.dataCache.getCache(urlProductFilterData);
    //   console.log(body);
    //   const objIndex = cache.findIndex((obj => (obj.app_id === body.app_id && obj.organization === body.organization
    //      && obj.product_family.toLowerCase() === body.product_family.toLowerCase() && obj.product.toLowerCase() === body.product.toLowerCase())));
    //   return this.http.put<any>(url , body).pipe(map(data => {
    //     if (objIndex > -1) {
    //       cache[objIndex].product_family = data[0].product_family;
    //       cache[objIndex].productFamilyDecscription = data[0].productFamilyDecscription;
    //       cache[objIndex].product = data[0].product;
    //       cache[objIndex].productDescription = data[0].productDescription;
    //       cache[objIndex].lob = data[0].lob;
    //       cache[objIndex].cio = data[0].cio;
    // }
    //    return data;
    // }));
    return this.http.put<any>(url, body);
  }

  deleteMapping(id): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.deleteMapping + id;
    return this.http.delete<any>(url);
  }

  getMappingWithDescription(): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.prodFamWithDesList;
    return this.http.get<any>(url);
  }

  getBasicApplicationData(askId): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.basicAppInfo;
    return this.http.post<any>(url, askId);
  }

  fetchAllProductMapping(): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.fetchAllProductMappingData;
    return this.http.get<any>(url);
  }

  getSpecificProductFamilyDetail(id): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.getProductOfProductFamily;
    return this.http.get<any>(url, { params: { productFamilyId: id } });
  }

  updateProductAndProductFamily(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.updateProductAndProductFamily;
    return this.http.put<any>(url, body);
  }

  fetchAllProductMappingExport(): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.fetchAllProductMappingExport;
    return this.http.get<any>(url);
  }

  getProductSuitesList() {
    const url: string = this.apiUrl + this.config.routerpath.getProductSuitesList;
    return this.http.get<any>(url);

  }


  saveProductSuiteData(body): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.saveProductSuiteInfo;
    return this.http.put<any>(url, body);
  }

  getSpecificProductSuiteMapping(id: string): Observable<any> {
    const url: string = this.apiUrl + this.config.routerpath.saveProductSuiteInfo;

    return this.http.get<any>(url, { params: { productSuiteId: id } });

  }



  deleteProductCapability(id, body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.config.routerpath.deleteProductCapabilityId + id;
    return this.http.delete<any>(url, options);
  }

  deleteProductandMoveAssociatedApp(id,body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.config.routerpath.deleteProductandMoveAssociatedApp + id;
    return this.http.delete<any>(url, options);
  }

  getLobInProductMapping() {
    const url: string = this.apiUrl + this.config.routerpath.getImpactedLobInfo;
    return this.http.get<any>(url, { params: { segment: 'UHC' } });

  }

  // give all application list along with their current padu

  appPaduList() {
    const url: string = this.apiUrl + this.config.routerpath.getAppPaduList;
    return this.http.get<any>(url);

  }

  saveProduct360(body) {
    const url: string = this.apiUrl + this.config.routerpath.saveProduct360;
    return this.http.post<any>(url, body);

  }

  getStakeholderDataBasedOnProductId(body) {
    const url: string = this.apiUrl + this.config.routerpath.getStakeholderDataBasedOnProductId;
    return this.http.post<any>(url, body);
  }
  fullDataUHCExportPDF(defaultGridData, productSuites, typeOfExport) {
    let documentDefinition = {
      footer: function (currentPage, pageCount) {
        return {
          text: 'Page No :' + currentPage.toString() + ' of ' + pageCount, alignment: 'right', fontSize: 8, margin: [0, 0, 20, 0]
        }

      },
      header: function () {
        // computations...
        return {
          columns: [
            {
              table: {

                widths: ['100%'],
                body: [
                  [{ text: 'UHC COMMON PRODUCT MODEL', textDecoration: 'underline', color: '#002677', bold: true, fontSize: 12, alignment: 'center' }],

                ]
              },
              layout: {
                hLineColor: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? '#fff' : '#fff';
                },
                vLineColor: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? '#fff' : '#fff';
                },
              }
            }
            // { text: 'UHC COMMON PRODUCT MODEL (2.1)', bold:true, color: '#002677', width: '100%', alignment: 'center', margin: [0,10,0,0]},

          ],
        };
      },



      content: [

        {
          table: {

            widths: ['auto', '*'],
            body: [
              [{ text: 'Product Suite', fillColor: '#013B71', color: '#fff', bold: true, fontSize: 11, margin: [5, 7, 5, 7], alignment: 'center' }, { text: 'Product Suite Description', fillColor: '#013B71', color: '#fff', bold: true, fontSize: 11, margin: [5, 7, 5, 7], alignment: 'center' }],

            ]
          },
          layout: {
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? '#002677' : '#002677';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? '#002677' : '#002677';
            },
          }
        }
        ,
        this.generateProductSuiteBasedOnType(productSuites, typeOfExport),


        {
          pageBreak: 'before',
          text: '',
          bold: true,
          fontSize: 12,
          // pageOrientation: 'landscape',
          color: '#002677',
          fontFamily: 'UHC-Serif-Headline-Semibold',
          alignment: 'left',
          margin: [0, 0, 0, 10]

        },


        // this.generateSingleProductFamilyAndProduct(defaultGridData),
        this.createNumberOfColumns(1, defaultGridData, typeOfExport),

      ],

      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 20, 0, 10],
          decoration: 'underline'
        },
        name: {
          fontSize: 16,
          bold: true
        },
        jobTitle: {
          fontSize: 14,
          bold: true,
          italics: true
        },
        sign: {
          margin: [0, 50, 0, 10],
          alignment: 'right',
          italics: true
        },
        tableHeader: {
          bold: true,
        },

        card: {
          margin: [10, 10, 10, 20],
          columnGap: 20,
          // fillColor: '#F5F5F5'

        },

        // pageMargins: [40,40,40,60]
      }
    };



    pdfMake.createPdf(documentDefinition).download('UHC Common Product Model');
  }

  generateSingleProductFamilyAndProduct(data) {
    data.forEach(d => {



      return {
        ...this.generateProductFamilyAndProduct(d)
      }
    })
  }
  createProductSuiteData(value, productSuites) {
    let array = [];
    array = productSuites;

    switch (value) {
      case 1:{
        let arr1 = [];

        const filtered1 = array.filter(function (element, index) {
          return (index % 2 === 0);

        });



        filtered1.forEach(d => {
          arr1.push([{ text: d.productSuite, fontSize: 15, color: '#333', fillColor: '#F4F9FF', margin: [4, 4, 4, 4], alignment: 'center' }])
        });


        return arr1;
      }
      case 2:{

        let arr2 = [];

        const filtered = array.filter(function (element, index) {
          return (index % 2 !== 0);

        });



        filtered.forEach(d => {
          arr2.push([{ text: d.productSuite, fontSize: 15, color: '#333', fillColor: '#F4F9FF', margin: [4, 4, 4, 4], alignment: 'center' }])
        });
        return arr2;
      }
      case 3:{

        let arr3 = [];

        const filtered3 = array.filter(function (element, index = 0) {
          index = index + 2;
          return index;
        });



        filtered3.forEach(d => {
          arr3.push([{ text: d.productSuite, fontSize: 15, color: '#333', fillColor: '#F4F9FF', margin: [4, 4, 4, 4], alignment: 'center' }])
        });
        return arr3;
      }
    }

  }

  createNumberOfColumns(number, defaultGridData, typeOfExport) {
    const combineAllCards = [];
    

    const column1 = this.createEachTable(defaultGridData, typeOfExport);
    // const column2 = this.createEachTable(filtered2,typeOfExport);
    // const column3 = this.createEachTable(filtered3,typeOfExport);
    combineAllCards.push(column1);
    // combineAllCards.push(column2);
    // combineAllCards.push(column3);
    return {
      columns: [
        ...combineAllCards
      ],

    };
  }

  createEachTable(productModelStructureArray, typeOfExport) {
    const card = [];

    let exportTypeBasedHeaderRow = 0;
    productModelStructureArray.forEach(arr => {
      if (typeOfExport === 'fulldata') {
        exportTypeBasedHeaderRow = arr.children.length + 1;
      }
      else {
        exportTypeBasedHeaderRow = arr.children.length
      }
      card.push(
        {
          margin: [0, 10, 0, 0],
          table: {
            headerRows: exportTypeBasedHeaderRow,
            widths: [110, 'auto'],
            keepWithHeaderRows: true,
            body: this.createCardContent(arr),
          },
          layout: {
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? '#d2d2d2' : '#d2d2d2';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? '#d2d2d2' : '#d2d2d2';
            },
            fillColor: function (i) {
              return (i % 2 === 0) ? '#F9F9F9' : null;
            }
          }
        }
      );
    });


    return card;

  }

  createCardContent(productCardContent) {

    const content = [];

    content.push([{ text: productCardContent.name, fontSize: 10, fillColor: '#013B71', color: '#fff', bold: true, margin: [4, 4, 4, 4] }, { text: productCardContent.desc, fontSize: 10, color: '#fff', margin: [4, 4, 4, 4], fillColor: '#013B71', bold: true }])
    // if(typeOfExport === 'fulldata')
    // {
    //   content.push([{ text: productCardContent.description, fontSize: 10, color: '#333',  fillColor: '#F4F9FF', margin: [4, 4, 4, 4], alignment: 'left' }])
    // }
    productCardContent.children.forEach(e => {
      content.push([{ text: e.name, fontSize: 10, color: '#013B71', bold: true, margin: [4, 4, 4, 4], }, { text: e.desc, fontSize: 10, color: '#333', margin: [4, 4, 4, 4] },
      ]);
    });


    return content;
  }

  // generate full table data for product family and capability
  generateProductFamilyAndProduct(uhcProductList) {
    const card = [];
    card.push(
      {
        margin: [10, 10, 10, 10],
        table: {

          widths: ['*', 'auto'],
          body: this.createProductFamilyAndProductData([uhcProductList]),


        }
      });

    return {
      ...card[0],
    }


  }

  createProductFamilyAndProductData(uhcProductList) {
    let arr = [];
    uhcProductList.forEach(d => {
      arr.push([{ text: d.name, fontSize: 10, color: '#013B71' }, { text: d.description, fontSize: 9, color: '#424242' }])
    });
    return arr;
  }
  // generate suite structure based on type
  generateProductSuiteBasedOnType(suitesList, typeOfExport) {
    const card = [];


    if (typeOfExport === 'nameonly') {
      card.push(
        {
          width: 'auto',
          margin: [30, 20, 20, 20],
          table: {
            heights: 40,
            widths: [200],

            body: this.createProductSuiteData(1, suitesList)
          }
        },
        {
          width: 'auto',
          margin: [30, 20, 20, 20],
          table: {
            heights: 40,
            widths: [200],

            body: this.createProductSuiteData(2, suitesList)
          }
        }


      );

      return {
        columns: [
          ...card
        ],

      };
    }

    else {
      card.push(
        {
          table: {
            widths: [100, 'auto'],
            body: this.createSuiteData(suitesList),


          },
          layout: {
            hLineColor: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? '#eee' : '#eee';
            },
            vLineColor: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? '#eee' : '#eee';
            },

            fillColor: function (i) {
              return (i % 2 === 0) ? '#F9F9F9' : null;
            }
          }
        });

      return {
        ...card[0]
      }
    }

  }






  createSuiteData(suitesList) {
    let arr = [];
    suitesList.forEach(d => {
      arr.push([{ text: d.productSuite, fontSize: 10, color: '#013B71', margin: [4, 4, 4, 4], bold: true }, { text: d.productSuiteDesc, fontSize: 10, margin: [4, 4, 4, 4], color: '#333' }])
    });
    return arr;
  }


  getDetailsOfProductFamily(id) {
    const url: string = this.apiUrl + this.config.routerpath.getDetailsOfProductFamily + id;
    return this.http.get<any>(url).pipe(
      map(data => {
        let finalObj: any = {};
        finalObj.name = data.capabilityL0,
          finalObj.description = data.capabilityL0Desc,
          finalObj.level = 0,
          finalObj.id = data.capabilityL0Id,
          finalObj.rank = data.L0_Rank,
          finalObj.addNewNodes = false,
          finalObj.showDescription = false,
          finalObj.showChildren = false
        let arr = []
        data.capL1.forEach(d => {
          let level1obj: any = {};
          level1obj.name = d.capabilityL1,
            level1obj.description = d.capabilityL1Desc,
            level1obj.rank = d.L1_Rank,
            level1obj.level = 1,
            level1obj.id = d.capabilityL1Id,
            level1obj.addNewNodes = false,
            level1obj.showDescription = false,
            level1obj.showChildren = false
          arr.push(level1obj);
          let arrlevel2 = []
          if (d.capL2.length) {
            d.capL2.forEach(capL2 => {
              let level2obj: any = {};
              level2obj.name = capL2.capabilityL2,
                level2obj.description = capL2.capabilityL2Desc,
                level2obj.children = capL2.capL3,
                level2obj.id = capL2.capabilityL2Id,
                level2obj.level = 2,
                level2obj.rank = capL2.L2_Rank,
                level2obj.addNewNodes = false,
                level2obj.showDescription = false,
                level2obj.showChildren = false
              arrlevel2.push(level2obj);
              let arrlevel3 = [];
              if (capL2.capL3.length) {
                capL2.capL3.forEach(capL3 => {
                  let level3obj: any = {};
                  level3obj.name = capL3.capabilityL3,
                    level3obj.description = capL3.capabilityL3Desc,
                    level3obj.children = [],
                    level3obj.level = 3,
                    level3obj.id = capL3.capabilityL3Id,
                    level3obj.rank = capL3.L3_Rank,
                    level3obj.addNewNodes = false,
                    level3obj.showDescription = false,
                    level3obj.showChildren = false
                  arrlevel3.push(level3obj);

                })
              }
              level2obj.children = capL2.capL3.length ? arrlevel3 : []
            })
          }
          level1obj.children = d.capL2.length ? arrlevel2 : []
        })
        finalObj.children = arr;
        // output = output.filter(row => row.appName0);
        return [finalObj];

      })
    );
  }


  updateProduct(id, body) {
    const url: string = this.apiUrl + this.config.routerpath.getDetailsOfProductFamily + id;
    return this.http.put<any>(url, body);

  }

  deleteProduct(id, body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.config.routerpath.getDetailsOfProductFamily + id;
    return this.http.delete<any>(url, options);

  }

  deleteProductSuite(id, body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url: string = this.apiUrl + this.config.routerpath.deleteProductSuite + id;
    return this.http.delete<any>(url, options);

  }

  addNewProductCapability(body) {
    const url: string = this.apiUrl + this.config.routerpath.getDetailsOfProductFamily;
    return this.http.post<any>(url, body)
  }

  editAddProductSuite(body) {
    const url: string = this.apiUrl + this.config.routerpath.editAddProductSuite;
    return this.http.put<any>(url, body)
  }

  moveCapability(body){
    const url: string = this.apiUrl + this.config.routerpath.moveCapability;
    return this.http.post<any>(url, body)
  }
  
}





