<div class="col">
  <div class="homeclass">
    <div class="alert alert-primary" role="alert">
      Alert ! {{ CurrentMonthFull }} {{ CurrentYear }} Data is refreshed
    </div>
    <div>
      <a href="http://orbit-ssrs-prod-int.optum.com/reports/browse/Optum%20ET/TCM" style="margin-top:4px;"
        target="blank" class="text-white btn btn-primary btn-sm">
        Extract Chargeback Data (SSRS)
      </a>

      <a href="https://uhgazure.sharepoint.com/:w:/s/technologycostmanagement/EdeMH4xRy_VMo0ahCYyhVbMBNpetB-W05hLhIchvNgwTkA?e=khAF62"
        style="margin-top:4px;margin-left: 5px;" target="blank" class="text-white btn btn-primary btn-sm">
        <i class="fa fa-download" style="font-size:12px; padding-right: 4px;"></i>
        Download TCM Hub Manual
      </a>

    </div>
  </div>

  <div class="tableClass col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
    <app-data-table [dataSource]="dataSource" [columns]="columns"></app-data-table>

  </div>

</div>