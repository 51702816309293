import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { environment } from 'src/environments/environment';
import { BookmarkDialogPopupComponent } from '../shared/components/bookmark-dialog-popup/bookmark-dialog-popup.component';
import { Bookmark } from '../shared/interface/interface';
import { OptimizeService } from '../shared/optimize.service';
import { BookmarkServiceService } from '../shared/services/bookmark-service.service';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  cardNumber = '';
  cardNumber2 = '';
  changeText = true;
  changeTextLob = false;
  changeTextExtract = false;
  changeTxtAppHub = false;
  changeTextCIO = false;
  changeTextCAP = false;
  changeTextDecom = false;
  showLoader = true;
  loading = false;
  bookmarkClicked: boolean;
  bookmarkList: Array<Bookmark> = [];
  allBookmarkList: Array<Bookmark> = [];
  isViewMore = false;
  isBookmarkList = false;
  tcmAvailableData: string = '';
  tcmNextRefresh: string = '';
  
  defaultText: string = '';
  
  tcmLobView: string = `LOB view provides the split of apps across the different PADU statuses, as well as the breakdown of Infrastructure, O&M, and Dev chargebacks amongst the PADU values. It also provides  Infrastructure consumption and spend details of UHC/ Optum LOBs, broken down by application and service level. The user can also view the breakdown of apps on the path for decommission along with the accumulated benefit associated.`;
  tcmSSRSView: string = `The SSRS portal enables the download of raw data related to application and LOB chargebacks. The multiple reports available span across infrastructure details, MBO comparisons, O&M Labor chargeback, and TCO (Total Cost of Ownership).`;
  appAppView: string = `Manage the PADU roadmap for your Application. Also, manage the associated business capabilities for your application.`;
  appCapView: string = `Explore the Business Capability landscape across the enterprise. Also, get to know the associated assets and stakeholders for each of these capabilities.`;
  optimizationView: string = `Optimization portal allows TCM Team to review and neutralize erratic service Trend alerts`;
  reClassifyView: string = `Reclassification Portal allows users to submit and Track their Chargeback correction requests`;
  portfolioView:string=`The Portfolio Hub offers a comprehensive view of PADU Compliance and Roadmap, Cost, Relationships, Decommission, and PLM for a portfolio of applications categorized by LOB, Platform, Custom App Groups, and Application Owners/SLOs/BOs. Additionally, it enables users to view the breakdown of Cost and Applications to gain useful and actionable insights.`
  
  ssrcLink = environment.reportURL;
  optimizationAccess: boolean = true;
  Reclassification: boolean = true;
  portfoliohub: boolean = true;
  ApplicationHub:boolean =true;
  Reports:boolean=true;
  CapabilityModels:boolean=true;
  Optimization:boolean=true;
  PortfolioHubOld:boolean=true;
  tiles: any=[];
  errGetYearMonth: any;
  
  constructor(
    private iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private route: Router,
    private dialog: MatDialog,
    private bookService: BookmarkServiceService,
    private optimize: OptimizeService,
    private sharedService: SharedService,
  ) {
    
    const historyUrl = Cookie.get('returnUrl') || null;
    if (Cookie.get('codeCreated')!='') {
      Cookie.delete('codeCreated');
      if (historyUrl) {
        Cookie.delete('returnUrl'); 
        this.route.navigateByUrl(historyUrl);
         
        }
     }
    
    // this.getBookmarkList();
    iconRegistry.addSvgIcon(
      'tcm-app-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/search-svgrepo-com.svg')
    );

    iconRegistry.addSvgIcon('tcm-lob-logo', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/team.svg'));

    iconRegistry.addSvgIcon(
      'tcm-extraction-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/data-extraction.svg')
    );

    iconRegistry.addSvgIcon('uhc-logo', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/uhc-logo.svg'));

    iconRegistry.addSvgIcon('app-cap-logo', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/cap-icon.svg'));

    iconRegistry.addSvgIcon('app-decom-logo', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/remove.svg'));
  }

  ngOnInit() {
    // this.service.getProductMappingFilter().subscribe(d => {

    // });

    this.checkFlagAccess();
    this.getYearMonth();
    localStorage.removeItem('groupList');
    localStorage.removeItem('segmentList');
    localStorage.removeItem('sublob');
    this.tileslists();
   
  }
  tileslists(){
    if(JSON.parse(localStorage.getItem('tiles'))){
      this.tiles = JSON.parse(localStorage.getItem('tiles'));
    }else{
      const msId = localStorage.getItem('MsId');
      const secureGroups = JSON.parse(localStorage.getItem('secureGroups'));
      msId && this.sharedService.getAccessData(msId, { 'secureGroups' : secureGroups } ).subscribe(accessData => {
        const { tiles={}} = accessData || {};
        this.tiles =tiles;
        localStorage.setItem('tiles', JSON.stringify(tiles));
  
      });
    }
    
  }
  infoDetailSection(value) {
    switch (value) {
      case 'tcm-app':
        this.defaultText = `Application view presents the infrastructure consumption and spend details of UHC/ Optum applications at service level.`;
        break;

      case 'tcm-lob':
        this.defaultText = `LOB view presents the infrastructure consumption and spend details of UHC/ Optum LOBs, broken down by application and service level`;
        break;

      case 'tcm-data-extract':
        this.defaultText = `The SSRS portal enables the download of raw data related to application and LOB chargebacks. The multiple reports available span across infrastructure details, MBO comparisons, O&M Labor chargeback, and TCO (Total Cost of Ownership).`;
        break;

      case 'app-appView':
        this.defaultText = `Application view encompasses Capability PADU (current and future), PLM, and other app details`;
        break;

      case 'app-cio':
        this.changeTextCIO = true;
        this.defaultText = `LOB view provides the split of apps across the different PADU statuses, as well as the breakdown of Infrastructure, O&M, and Dev chargebacks amongst the PADU values. The user can also view the breakdown of apps on the path for decommission along with the accumulated benefit associated.`;
        break;

      case 'app-cap':
        this.changeTextCAP = true;
        this.defaultText = `Capability View allows the user to identify and consolidate applications across the PADU spectrum and LOBs that cater to a similar set of capabilities. The user can also click on the grids for a further deep-dive into a specific capability.`;
        break;

      case 'app-decom':
        this.changeTextDecom = true;
        this.defaultText = `Decommissioned View provides information about the applications that are decommissioned. `;
        break;

      case 'app-product-mapping':
        this.changeTextDecom = true;
        this.defaultText = `Product Mapping View provides the application to product mapping for UHC business segment. Architects can also raise access to edit/ add mapping.`;
        break;
    }
    return this.defaultText;
  }

  redirectView(value) {
    switch (value) {
      case 'tcmhub-AppView':
        this.route.navigate([{ outlets: { primary: 'application-cost' } }]);
        break;
      case 'Portfolio Hub Old':
        this.route.navigate([{ outlets: { primary: 'cio-cost' } }]);
        break;
      case 'app-AppView':
        this.route.navigate(['application-insights/selectFilter']);
        break;
      case 'Reports':
        this.route.navigate(['/reports']);
        break;

      case 'app-lob':
        this.route.navigate([{ outlets: { primary: 'cio-app-insights'} }]);
        break;

      case 'Capability Models':
        this.route.navigate([{ outlets: { primary: 'product-home' } }]);
        break;

      case 'app-decom':
        window.open(`${this.ssrcLink}`, '_blank');
        break;
        // this.route.navigate([{ outlets: { primary: 'decommission-app'} }]);
        // break;

      case 'app-product-mapping':
        this.route.navigate(['/UHC-View']);
        break;
      // case 'app-product-mapping':
      //   this.route.navigate([{ outlets: { primary: 'uhcproduct-home' } }]);
      //   break;
      case 'Optimization':
        this.route.navigate([{ outlets: { primary: 'optimization' } }]);
        break;
      case 'Reclassification Portal':
        this.route.navigate([{ outlets: { primary: 'reclassification' } }]);
        break;
      case 'Application Hub':
        this.route.navigate([{ outlets: { primary: 'application-hub' } }]);
        break;
        case 'Portfolio Hub':
        this.route.navigate([{ outlets: { primary: 'portfolio-hub' } }]);
        break;
    }
  }
  public tcmbookmarkList;

  getBookmarkList() {
    const msId = localStorage.getItem('MsId');
    this.bookService.getBookmarkList(msId).subscribe(data => {
      this.allBookmarkList = data;
      this.bookmarkList = this.allBookmarkList;

      this.tcmbookmarkList = this.bookmarkList.filter(item => {
        return item.bookmarkType === 'tcm_hub';
      });
      this.bookmarkList = this.bookmarkList.filter(objFromA => {
        return !this.tcmbookmarkList.find(objFromB => {
          return objFromA.id === objFromB.id;
        });
      });
      this.isBookmarkList = true;
      this.showLoader = false;
    });
  }

  // viewMoreLess() {
  //   this.isViewMore = !this.isViewMore;
  //   if (this.isViewMore) {
  //     this.bookmarkList = [];
  //     this.bookmarkList = this.bookmarkList.concat(this.allBookmarkList);
  //   } else {
  //     this.bookmarkList = this.allBookmarkList.slice(0, 5);
  //   }
  // }

  deleteBookmark(id: number) {
    if (confirm('Are you sure want to delete.')) {
      this.bookService.deleteBookmark(id).subscribe(() => {
        this.bookmarkList = this.bookmarkList.filter(b => b.id !== id);
        this.allBookmarkList = this.allBookmarkList.filter(b => b.id !== id);
        this.bookmarkList = this.allBookmarkList;
      });
    }
  }
  deletetcmBookmark(id: number) {
    if (confirm('Are you sure want to delete.')) {
      this.bookService.deleteBookmark(id).subscribe(() => {
        this.tcmbookmarkList = this.tcmbookmarkList.filter(b => b.id !== id);
      });
    }
  }
  renameBookmark(bookmark: Bookmark) {
    const { id, name } = bookmark;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Rename Bookmark',
      data: [
        {
          fieldName: 'Bookmark',
          fieldValue: name,
          value: 'bookName',
          action: 'rename',
          bookmarkId: id
        }
      ],
      submitName: 'Save'
    };
    dialogConfig.width = '500px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.minHeight = '250px';
    dialogConfig.height = 'auto';
    const dialogRef = this.dialog.open(BookmarkDialogPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(newname => {
      this.bookmarkList.map(t => {
        if (t.id === id && newname) {
          t.name = newname;
        }
        return t;
      });
      this.tcmbookmarkList.map(t => {
        if (t.id === id && newname) {
          t.name = newname;
        }
        return t;
      });
    });
  }

  redirect(bookmark: Bookmark) {
    const { path, appId, model, lob, padu, id, capL2, cio_lob, applicationnames } = bookmark;
    let redirectPath = [];
    let queryParams = {};
    switch (path) {
      case 'app':
        redirectPath = [{ outlets: { primary: 'application-insights' } }];
        queryParams = { askId: appId };
        break;
      case 'lob':
        redirectPath = [{ outlets: { primary: 'cio-app-insights' } }];
        queryParams = { cio: cio_lob, lob, padu };
        break;
      case 'cap':
        redirectPath = [{ outlets: { primary: 'product-home' } }];
        queryParams = { filterId: id };
        break;
      case 'capDetail':
        redirectPath = [{ outlets: { primary: 'product-home/capability-details' } }];
        queryParams = { capName: capL2, filterId: id };
        break;
      case 'tcm_lob':
        redirectPath = [{ outlets: { primary: 'cio-cost', sidebar: 'tcmhub' } }];
        queryParams = { lob: applicationnames.split(','), cio: 'NULL' !== model ? model : null };
        break;
    }
    this.route.navigate(redirectPath, { queryParams });
  }
  
  checkFlagAccess() {
    let body = {
      MSID: localStorage.getItem('MsId'),
      Page_Name: 'Optimization'
    };
    this.optimize.getAccessFlag(body).subscribe(
      res => {
        if (res) {
          if (res == 1) {
            this.optimizationAccess = true;
          } else {
            this.optimizationAccess = false;
          }
        }
      },
      err => {
        console.log(err, 'erreo...');
        this.optimizationAccess = false;
        this.Reclassification = false;
      }
    );
  }
 
  getYearMonth() {
    this.optimize.getCurrentYear().subscribe(
      (res: any) => {
        if (res) {
          this.tcmAvailableData = JSON.parse(localStorage.getItem('config')).avialableData;
          this.tcmNextRefresh = JSON.parse(localStorage.getItem('config')).nextRefreshData;
          return res;
        }
      },
      err => {
        this.errGetYearMonth = err;
        localStorage.removeItem('config');
      }
    );
  }
}