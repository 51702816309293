import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OptimizeService } from 'src/app/shared/optimize.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SharedAuthService } from 'src/app/shared/shared.auth.service';
import { PortfolioHubService } from 'src/app/techopsv4/services/portfolioHubV4Service';
import { DynamicContentModel } from '../landing-page-v4.component';

@Component({
  selector: 'app-landing-page-services',
  templateUrl: './landing-page-services.component.html',
  styleUrls: ['./landing-page-services.component.css']
})
export class LandingPageServicesComponent implements OnInit {
  tiles: any;
  optimizationAccess: boolean;
  Reclassification: boolean;
  tcmAvailableData: any;
  tcmNextRefresh: any;
  tempTiles: any = [];
  tilesLength: any;
  @Input() dynamicContentData;
  public bodyTitle: DynamicContentModel = {} as DynamicContentModel;
  public services: string[] = [];
  public serviceObjects: DynamicContentModel[] = [];
  constructor(
    private optimize: OptimizeService,
    private sharedService: SharedService,
    private route: Router,
    private portfolioHubService: PortfolioHubService,
    private sharedAuthService: SharedAuthService
  ) {}

  ngOnInit(): void {
    this.checkFlagAccess();
    this.getYearMonth();
    localStorage.removeItem('groupList');
    localStorage.removeItem('segmentList');
    // this.tileslists();
  }

  ngOnChanges() {
    if (this.dynamicContentData) {
      if (this.dynamicContentData.Body_Title) {
        this.bodyTitle = this.dynamicContentData.Body_Title[0] ? this.dynamicContentData.Body_Title[0] : {};
      }
      if (this.dynamicContentData.Body_Content && this.dynamicContentData.Body_Content.length > 0) {
        this.serviceObjects = this.dynamicContentData.Body_Content;
        this.serviceObjects = this.serviceObjects.sort((a, b) => (a.Occurance < b.Occurance ? -1 : 1));
        this.services = this.serviceObjects.map(a => {
          if (a.visibility == 'True') {
            return a.Content;
          }
        });
      }
      if (this.dynamicContentData.Tile && this.dynamicContentData.Tile.length > 0) {
        let tilesObjects: DynamicContentModel[] = this.dynamicContentData.Tile;
        tilesObjects = tilesObjects.sort((a, b) => (a.Occurance < b.Occurance ? -1 : 1));
        let tilesArray = [];
        tilesObjects.forEach(tile => {
          let newTile = {};
          newTile['Tile'] = tile.Content;
          newTile['SubTitle'] = tile.SubTitle;
          newTile['Description'] = tile.Description;
          newTile['CostData'] = tile.CostData;
          newTile['Visibility'] = this.getTileVisibility(tile);
          newTile['icon'] = tile.icon;
          newTile['order'] = tile.Occurance;
          newTile['Url_Path'] = tile.Url_Path;
          tilesArray.push(newTile);
        });
        this.tiles = tilesArray;
        // this.tileslists();
      }
    }
  }
  tileslists() {
    if (JSON.parse(localStorage.getItem('tiles'))) {
      this.tiles = JSON.parse(localStorage.getItem('tiles'));
      this.tempTiles = this.tiles.filter(x => x.Visibility === 'True');
      this.tilesLength = this.tempTiles.length;
    }
  }

  checkFlagAccess() {
    let body = {
      MSID: localStorage.getItem('MsId'),
      Page_Name: 'Optimization'
    };
    this.optimize.getAccessFlag(body).subscribe(
      res => {
        if (res) {
          if (res == 1) {
            this.optimizationAccess = true;
          } else {
            this.optimizationAccess = false;
          }
        }
      },
      err => {
        console.log(err, 'erreo...');
        this.optimizationAccess = false;
        this.Reclassification = false;
      }
    );
  }

  getYearMonth() {
    this.optimize.getCurrentYear().subscribe(
      (res: any) => {
        if (res) {
          this.tcmAvailableData = JSON.parse(localStorage.getItem('config')).avialableData;
          this.tcmNextRefresh = JSON.parse(localStorage.getItem('config')).nextRefreshData;
          const ocuurance1 = this.tcmNextRefresh.indexOf('-');
          const ocuurance2 = this.tcmNextRefresh.indexOf('-', 3);
          this.tcmNextRefresh = this.tcmNextRefresh.replace(
            this.tcmNextRefresh.substr(ocuurance1 + 1, ocuurance2 - ocuurance1 - 1),
            this.tcmNextRefresh.substr(ocuurance1 + 1, 3)
          );
          return res;
        }
      },
      err => {
        console.log('Error:', err);
        localStorage.removeItem('config');
      }
    );
  }

  navigate(event) {
    if (event.Tile == 'Portfolio Hub') {
      this.portfolioHubService.porfolioSelectedFromFinance = false;
    }
    this.route.navigateByUrl(event.Url_Path);
  }
  getTileVisibility(tile) {
    if (
      tile.Audience == 'EA' &&
      !tile.Role_Visibility.split(',').includes(this.sharedAuthService.getAuthorizationForEA())
    )
      return 'False';
    else if (tile.Audience == 'CD' && !this.sharedAuthService.getAuthorizationForCD()) return 'False';
    else if (tile.Audience == 'Public' && tile.Role_Visibility != 'All') return 'False';
    else return tile.visibility;
  }
}
