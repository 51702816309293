<div class="merge-cap-popup">
  <div class="cap-hyperlink-block" *ngIf="showWQHyperlink">
    <p>There is an open request for this capability. Therefore a new request can not be created. Click <span class="cap-hyperlink-txt" (click)="navigateToWQlistPage()">here</span> to view request details.</p>
  </div>
  <div class="row-new">
    <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3">
      <label class="section-name tabl-header tabl-header-font">
        Select Group Model
      </label>
      <mat-form-field [floatLabel]="'never'" class="custom-dropdown">
        <input style="border:none;width:100%" class="edit text-notavailable-option option-size"
          *ngIf=" !modelData.includes(selectedModel)" [value]="selectedModel ? selectedModel : ''" />
        <mat-select [placeholder]="selectedModel? '': 'Select Group Model'"
          (selectionChange)="filterCapabilityBasedOnGroupModel()" [disabled]="(showWQHyperlink && isCapabilityChanged)" [(ngModel)]="selectedModel">

          <mat-option class="dropDownList" *ngFor="let model of modelData" [value]="model">
            <ng-container *ngIf="!excludeModel.includes(model)">
              <label>{{model}}</label>
            </ng-container>
          </mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3">
      <label class="section-name tabl-header tabl-header-font">
        Capability
      </label>
      <span title="{{ childSelectedCapability }}">
        <p class="capability-default-name">{{ childSelectedCapability }}</p>
      </span> 
    </div>
    <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3">
      <label class="section-name tabl-header tabl-header-font">
        Capability to be Merged Into
      </label>
      <span *ngIf="filteredCapability.length == 0" title="{{ noAssCapability }}">
        <p class="capability-default-name">{{ noAssCapability }}</p>
      </span> 
      <mat-form-field *ngIf="filteredCapability.length > 0" [floatLabel]="'never'" class="custom-dropdown">
        <input class="edit edit-font" style="width: 92%;
            height: 75%;
            position: absolute;
            top: 0px;
            background: transparent;
            border: none;"  [placeholder]="parentSelectedCapability? '' : 'Please Select Capability'" />
        <mat-select panelClass="global-select-panel" [disabled]="!selectedModel || (showWQHyperlink && isCapabilityChanged)" #mySelect disableRipple [(ngModel)]="parentSelectedCapability" (selectionChange)="getMergedCapability($event)">
          <div>
            <input matinput type="text" class="mat-input-search w-100"
                placeholder="Search for a Capability" (keyup)="searchCapabilityByName($event)" [(ngModel)]="searchTxt" (keydown)="$event.stopPropagation()">
          </div>
          <mat-option class="dropDownList" *ngFor="let capability of filteredCapability" [value]="capability">
            <ng-container>
              <label *ngIf="capability?.capability;else noResults">{{capability.capability}}</label>
              <ng-template #noResults><label>No Results Found</label></ng-template>
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="align-self-end merge-btn col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 text-align-right">
      <button (click)="merge()" [disabled]="!parentSelectedCapability || showWQHyperlink" mat-mdc-raised-button 
      class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs">
        <p class="marginbott merge-button">Merge</p>
      </button>
    </div>
  </div>
  <div class="note-div">
    <p> Note: If there are same organizations present in both capabilities, organizations and respective PADUs in Capability Selected will be removed and 
      those in Capability merged into will be retained.</p>
  </div>
</div>