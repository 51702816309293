<div [ngClass]="getTableClass('main')">
  <mat-form-field *ngIf="filterConfig" class="data-filter" appearance="outline">
    <!-- <mat-label>{{ filterConfig.labelText }}</mat-label> -->
    <input matInput [(ngModel)]="searchField" (keyup)="filterConfig.filterCallBack($event)"
      placeholder="{{ filterConfig.placeHolderText }}" />
    <!-- <button style="background: none; border: none; float: right; " (click)="onClickClearSearchField()"
    *ngIf="searchField">
    <mat-icon style="font-size: 9px;">close</mat-icon>
  </button> -->
    <button mat-button *ngIf="searchField" matSuffix mat-icon-button aria-label="Clear"
      (click)="onClickClearSearchField()">
      <mat-icon style="margin-right: 15px; width: 14px; height: 14px;">close</mat-icon>
    </button>
  </mat-form-field>
 
  <ng-container *ngIf="hidecolumn && !productView" class="data-column" appearance="outline">
    <mat-checkbox class="example-margin" style="float: right;
  padding: 0;
  margin: 0;" (change)="showhidecolumn($event)">Show All Columns</mat-checkbox>
  </ng-container>
 
  <div class="table-container" [ngClass]="getTableClass()">
    <table *ngIf="(dataSource?.data.length > 0 || dataSource?.filteredData.length > 0) && dataReady" mat-table
      [dataSource]="dataSource" (matSortChange)="sortData($event)" matSort multiTemplateDataRows
      class="mat-elevation-z8">
      <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns; let i = index">
        <ng-container *ngIf="SelectAll && i == 0">
          <th mat-header-cell *matHeaderCellDef class="blue-header" [style.text-align]="'center'"
            [style.color]="'white'" [style.width]="column.width" [style.display]="column.display">
            <mat-checkbox *ngIf="displaySelectAll && i == 0" (change)="$event ? masterToggle() : null" color="primary"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" [style.text-align]="'center'" [style.display]="column.display">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              (change)="$event ? selecttoggle() : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <th [ngClass]="{ 'blue-header': expandTable && expandTable.class === 'expandedtableview',
                    'header-font-bold' : column.fontWeight === 'bold','table-light-background': expandTable && expandTable.class === 'lightBg'}" mat-header-cell
          mat-sort-header [style.width]="column.width" [style.display]="column.display" [disabled]="!column.sort"
          *matHeaderCellDef [class.table-right-actions-column]="column.columnDef == 'edit'">
          <span
            *ngIf="capabilityModel === 'yes' && column.header === 'Environment'"
            style="position: relative;">
            {{ column.header }}
          </span>
          <span
            *ngIf="capabilityModel === 'yes' && column.header !== 'Current PADU' && column.header !== 'Organization' && column.header !== 'Environment'"
            style="color: #013B71; font-family: 'UHC-Sans-Bold' !important;position: relative; left: 28%;"  [style.padding-left]="column.paddingLeft">
            {{ column.header }}
          </span>
          <span *ngIf="capabilityModel === 'yes' && column.header === 'Organization'"
            style="color: #013B71; font-family: 'UHC-Sans-Bold' !important;position: relative; left: 20%;">
            {{ column.header }}
          </span>
          <span *ngIf="capabilityModel === 'yes' && column.header === 'Current PADU'" [style.padding-left]="column.paddingLeft"
            style="position: relative; left: 7px;">
            {{ column.header }}
          </span>
          <span class="flex-row">

            <span class="flex-row">
              <span *ngIf="capabilityModel !== 'yes'">
                {{ column.header }}
              </span>
              <!-- {{ column.header }} -->

              <span class="data-table-search-icon"
                *ngIf="column.columnDef == 'edit' && !tableConfig.isPaginatorAttached">
                <input *ngIf="isSearchEnabled" style="height: 30px !important;" class="search " type="text"
                  placeholder="Application Name" (keyup)="childSearchCallback($event)" />

                <mat-icon *ngIf="!isSearchEnabled" style="transform: translateX(8.5rem);"
                  (click)="isSearchEnabled = true" class="material-icon">search
                </mat-icon>

                <mat-icon *ngIf="isSearchEnabled" (click)="isSearchEnabled = false; closeSearch()"
                  class="material-icon-close">close</mat-icon>
              </span>

              <span class="data-table-search-icon"
                *ngIf="column.columnDef == 'edit' && tableConfig.isPaginatorAttached">
                <span class="data-table-search-icon">
                  <input *ngIf="isSearchEnabled; else footerPagination" class="search" type="text"
                    placeholder="Application Name" (keyup)="childSearchCallback($event)" />
                </span>
                <span style="float: right; ">
                  <mat-icon *ngIf="isSearchEnabled" (click)="isSearchEnabled = false; closeSearch()"
                    class="material-icon-close">close</mat-icon>
                  <mat-icon *ngIf="!isSearchEnabled" (click)="isSearchEnabled = true" class="material-icon">search
                  </mat-icon>
                </span>
              </span>
              
              <!-- <span class="data-table-search-icon"  *ngIf="column.columnDef=='edit'">
                <input  class="search" type="text"  placeholder="Application Name"
                (keyup)="searchConfig.searchCallBack($event)" 
                >
                <mat-icon class="material-icon">search</mat-icon>
               
               
             
              </span> -->
              <span *ngIf="checkToolTip(column)" class="uitk-icon uitk-icon-info_hollow lbr-table-tool"
                [matTooltip]="getToolTipContent(column)" aria-hidden="false" aria-label="information  icon">
              </span>

              <span *ngIf="checkLocalFilerValid(column.columnDef)" (click)="filterDropdown(column)"
                [matMenuTriggerFor]="menu">
                <img *ngIf="!filterApplied" class="stkh-table-filter-img" src="../../../../assets/images/table_filter.png">
                
                <!-- *ngIf="!col.searchValue" -->
                <span *ngIf="filterApplied">
                  <img src="../../../../assets/images/table_filter_applied.png" class="stkh-table-filter-img">
                </span>
                <!-- <span class="uitk-icon uitk-icon-menu sm-icon menu-icon"></span> -->
              </span>
              <mat-menu #menu>
                <div class="menu-filter-popup" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                  <input matInput type="text" class="searchoption" (keyup)="valueSearchSave(searchValue, column)"
                    (keydown)="$event.stopPropagation()" [(ngModel)]="searchValue" placeholder="Search" />
                  <mat-selection-list [disableRipple]="true" class="filter-list-container" [(ngModel)]="filValue">
                    <mat-list-option checkboxPosition="before" *ngFor="let shoe of filterList | filter: searchValue"
                      [value]="shoe">
                      {{ shoe.name }}</mat-list-option>
                  </mat-selection-list>
                  <span style="cursor:pointer; float: right;padding-right: 21px;" class="localApplyButton"
                    (click)="applyLocalFilter(filValue, column)">Apply</span>
                  <span style="cursor:pointer; float: right;padding-right: 21px;" class="localApplyButton"
                    (click)="clearLocalFilter(filValue, column)">Clear</span>
                </div>
              </mat-menu>
            </span>
            <span *ngIf="(column.columnDef=='SUI_Count' || column.columnDef=='currentMonthYTD')&&  filterApplied" (click)="clearLocalFilter(filValue, column)">

              <i title="Reset Filters"
                class="font-weight-bold ng-tns-c441-21 pl-1 sm-icon stkh-table-filter-img uitk-icon uitk-icon-refresh"></i>
            </span>
            <br />
            <span *ngIf="column.customHeaderStyle">
              <span [ngStyle]="column.customHeaderStyle">
                {{ column.formattedText }}
              </span>
            </span>
          </span>
        </th>

        <td [matTooltipDisabled]="!column.description" [matTooltip]="getTooltipInfo(column, element)"
          matTooltipPosition="right" matTooltipClass="custom-tooltip" mat-cell [style.width]="column.width"
          [style.display]="column.display" (click)="getRecord(element, column)"
          [ngClass]="evaluateFunction(column, element)" *matCellDef="let element">
          <span class="future-padu-label" *ngIf="column.additionalHeader"> {{ column.additionalHeader }}</span>
          
          <span *ngIf="column.type == 'html'" style="text-align: center;"
            [ngClass]="ClickIgnoreColumns?.includes(column.columnDef) ? '' : getClassHtmlClassName(column, element)"
            (click)="getcellRecord(element, column)" [innerHtml]="column.cell(element)"></span>
          <span *ngIf="checkEditFeasibility(column)" style="font-size: 16px; cursor: pointer;color: #002677;"
            class="uitk-icon uitk-icon-edit" (click)="editAction(element, column)">
          </span>
          <span *ngIf="checkDeleteRow(column)" style="font-size: 16px; cursor: pointer;color: #002677;"
            class="uitk-icon uitk-icon-trash_delete" (click)="getRecord(element, column)">
          </span>

          <span>
            <mat-icon *ngIf="checkIfDecomData(column.columnDef, element)"
              [ngStyle]="{ color: 'red', 'font-size': '16px', cursor: 'pointer' }" [matTooltip]="decomData()"
              matTooltipPosition="right" matTooltipClass="custom-tooltip">
              outlined_flag</mat-icon>
            <mat-icon *ngIf="checkIfPLMVulData(column.columnDef, element)"
              [ngStyle]="{ color: 'red', 'font-size': '16px', cursor: 'pointer' }" [matTooltip]="PLMData()"
              matTooltipPosition="right" matTooltipClass="custom-tooltip" style="font-size: 16px;"
              (click)="redirectToAppView(element)">
              warning_amber</mat-icon>
             <span
              *ngIf="checkIfColisService(column.columnDef)" class="i-icon">
              <img class="image-icon" src="/assets/appHub/Rectangle Copy 20@2x.svg" 
                    [matTooltip]="getServiceInfo(element)" matTooltipClass="my-custom-tooltip"
                    matTooltipPosition="above" aria-hidden="false" aria-label="information  icon"/>
        
        </span> 
          </span>
          <span *ngIf="checkForHover(column.columnDef)" class="uitk-icon uitk-icon-info_hollow plus-icon"
            title="expand" (click)="getColumValue(column.columnDef, element)"
            (click)="isExpand === true ? (expandedElement = expandedElement === element ? null : element) : ''">
          </span>
          <span *ngIf="column.type != 'html'" [ngClass]="evaluateSpanSpecificClass(column, element)">
            {{ column.cell(element) }}</span>
        </td>
      </ng-container>
      <ng-container *ngFor="let extraHeader of tableHeaders">
        <ng-container [matColumnDef]="extraHeader.def">
          <th mat-header-cell *matHeaderCellDef class="text-center" [attr.colspan]="extraHeader.colspan">
            {{ extraHeader.name }}
          </th>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
          style="padding: 0px !important;">
          <div class="app-detail" [@detailExpand]="element == expandedElement ? 'expanded ' : 'collapsed'">
            <div class="child-header">
              <span class="app-title " style="margin-left: 5px;">Global App ID </span>
              <span class="app-title " style="margin-left: 79px;">Line of Business</span>
            </div>
            <div class="child-content">
              <span class="infra-title" style="margin-left: 5px;">{{ element.ASK_GLOBAL_ID }}</span>
              <span class="infra-title" style="margin-left: 39px;">{{ element.lob }}</span>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="tableHeaders.length">
        <tr mat-header-row *matHeaderRowDef="tableHeaderColumn"></tr>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"
        [ngClass]="{ 'hight-top': element.appName3 == selectedRowIndex }"
        [class.example-expanded-row]="expandedElement === element"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
        [ngClass]="[isExpand == true && rowExpandable == true ? 'example-detail-row' : 'd-none']"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
    </table>

    <ng-container *ngIf="!dataReady">
      <!-- <div class="no-records-found">
        <div class="img-align">
          <img class="image-align" src="/assets/images/warning-icon.svg" />
        </div>
        <div class=" data-title">
          <div class="errorTitle">No Data Available</div>
        </div>
      </div> -->
      <div style="text-align: center;">
        <app-loader>
        </app-loader>
      </div>
    </ng-container>

  </div>
</div>
<ng-container *ngIf="!tableConfig?.isPaginatorAttached">
  <ng-container *ngIf="dataSource">
    <mat-paginator *ngIf="dataSource.data.length" #pagination [pageIndex]="pageIndex" (page)="fetchMoreItems($event)"
      [length]="dataSource.data.length" [pageSizeOptions]="[5,10,15]" [pageSize]="pageSize" showFirstLastButtons>
    </mat-paginator>
  </ng-container>
  <!--<ng-template #paginationBlock>
    <mat-paginator #pagination *ngIf="pageSize.length > 0" [pageSize]="pageSize[0]" [pageSizeOptions]="[5, 10, 15]">
    </mat-paginator>
  </ng-template> -->
</ng-container>

<ng-template #footerPagination>
  <ng-container>
    <mat-paginator style="height:30px; border-radius: 20px; font-size: 20px !important; margin-right: 5px;"
      *ngIf="dataSource.data.length" #pagination [pageIndex]="pageIndex" (page)="fetchMoreItems($event)"
      [length]="tableTotalItems" [pageSizeOptions]="pageSize" [pageSize]="pageSize[0]" class="tech-table-pagination"
      showFirstLastButtons hidePageSize>
    </mat-paginator>
  </ng-container>

  <!--<ng-template #paginationBlock>
    <mat-paginator *ngIf="pageSize.length > 0" #pagination [pageSize]="pageSize[0]" [hidePageSize]="!pageSize[1]"
      [pageSizeOptions]="pageSize" showFirstLastButtons></mat-paginator>
  </ng-template> -->
</ng-template>
