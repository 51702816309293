import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintServiceService {

  constructor() { }
  printPage: EventEmitter<any> = new EventEmitter();
  emitNavChangeEvent() {
      this.printPage.emit();
  }
  getNavChangeEmitter() {
      return this.printPage;
  }
}
