<div class="row capability_proposal_block" [ngClass]="{'pt-0':fromAssociateCapabilities}">
    <div class="col-12 pt-3 pb-3">
        <section>
            <!-- <label class="example-margin">Align:</label> -->
          <span *ngIf="fromAssociateCapabilities" class="capability-proposal-txt">Capability Proposal:  </span>
          <span>
            <mat-radio-group [(ngModel)]="capabilityAssociation" (change)="onCapabilityTypeChange()">
              <mat-radio-button class="example-margin" [disabled]="fromAssociateCapabilities" value="add">Add Capability Association</mat-radio-button>
              <mat-radio-button class="example-margin" [disabled]="fromAssociateCapabilities" value="edit">Edit Capability Association</mat-radio-button>
            </mat-radio-group>
          </span>
      </section>
    </div>
    <div class="capability-fields pb-1">
      <div class="w-20 pr-2">
        
        <span class="form-inline-new" for="Select Apploication">
          <label class="section-name tabl-header tabl-header-font">
            Select Application
            <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
          </label>
        </span>
        <mat-form-field appearance="outline" class="select-dropdown demographics-appId-drodown">
            <mat-select panelClass="global-select-panel" (selectionChange)="getSelectedApplication($event)"
                [(ngModel)]="selectedApplication" [disabled]="fromAssociateCapabilities" [placeholder]=" selectedApplication ? '' : 'Select an Application'">
                <div>
                    <input matinput type="text" [(ngModel)]="applicationSearch" class="mat-input-search w-100"
                        placeholder="Search for an Application Name or ID" (keydown)="$event.stopPropagation()"
                        (keyup)="searchApplicationData($event)" (blur)="resetInputValueForApplicationList()">
                </div>
                <div class="table-data-font">
                  <mat-option class="global-mat-option no-cursor" value ="" *ngIf="appList?.length == 0 && !isLoader"><label>No Results Found</label></mat-option>
                    <mat-option class="global-mat-option" *ngFor="let app of appList"
                        title="{{ app.app_id }} - {{ app.app_name }}" [value]="app.app_id + '|' + app.app_name">
                        <label style="margin:0%">{{ app.app_id }} - {{ app.app_name }}</label></mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="w-20 pr-2">
      <label class="section-name tabl-header tabl-header-font">
        Select Group Model
        <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
      </label>
        <mat-form-field [floatLabel]="'never'" appearance="outline" class="select-dropdown demographics-appId-drodown">
            <mat-select [disabled]="selectedApplication == '' || isEditWorkflow"  [placeholder]="selectedModel? '': 'Select Group Model'"
                (selectionChange)="getNewMappedCapabilityList()" [(ngModel)]="selectedModel">
                <ng-container *ngIf="!isEdit">
                  <mat-option class="dropDownList" *ngFor="let model of groupModelArr" [value]="model">
                    <label>{{model}}</label>
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="isEdit">
                  <mat-option class="dropDownList" *ngFor="let model of groupModelArrForEdit" [value]="model">
                    <label>{{model}}</label>
                  </mat-option>
                  <mat-option class="dropDownList" *ngIf="groupModelArrForEdit?.length == 0 && isAllDataLoaded" value="">
                    <label>No Results Found</label>
                  </mat-option>
                </ng-container>
                
                
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-20 pr-2">
      <label class="section-name tabl-header tabl-header-font">
        Select Capability
        <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
      </label>
        <mat-form-field [floatLabel]="'never'" [style.opacity]="(selectedApplication == '' || selectedModel == '' || isEditWorkflow) ? '0.5' : ''"  appearance="outline" class="select-dropdown demographics-appId-drodown" (click)="onCapabilitySelectDropdown()">
            <input class="edit edit-font tree-dropdown-cap" [value]="selectedCapability1" placeholder="Please Select Capability" />
            <mat-select panelClass="global-select-panel" [disabled]="selectedApplication == '' || selectedModel == '' || isEditWorkflow"  [(ngModel)]="capability" #mySelect disableRipple>
              <div>
                <input #searchInput matinput type="text" class="mat-input-search w-100"
                    placeholder="Search for a Capability" (keyup)="searchCapabilityByName($event)" [(ngModel)]="capability" (keydown)="$event.stopPropagation()">
              </div>
                <mat-option class="dropDownList" style="height: auto !important; overflow: auto; background-color: white;" >
                    <label>
                      <app-tree-checkbox [multiple]="false" [List]="mappedCapability" 
                        [openWQRequest] = "openRequest"
                        (multipleSelectedData)="multipleList($event)"
                        (showOpenRequest)="showWQOpenRequestHyperlink($event)"
                        (selectedTreeData)="getSelectedCapbilityFromDropdown($event)"
                        [selectedCapability]="selectedCapability1">
                      </app-tree-checkbox>
                    </label>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="w-10 pr-2" >
        <div [hidden]="isEdit">
          <label class="section-name tabl-header tabl-header-font" style="padding-left: 1rem;">
            Primary
          </label>
            <div class="row marginLeft" style=" padding-left:1.5rem;padding-top: 5px;">
                <div class="m-1">
                    <input [disabled]="true" style="height:15px; width:15px;" type="radio" name="imgsel" value="Yes"
                        [checked]="primary==='Yes'" />
                    <label class="align-middle radio-button-cap"> Yes </label>
                </div>

                <div class="m-1">
                    <input [disabled]="true" [checked]="primary==='No'"
                        style="height:15px; width:15px; padding-left: 15px;" type="radio" name="imgsel" value="No" />
                    <label class="align-middle radio-button-cap"> No </label>
                </div>
            </div>

        </div>
    </div>
    <div class="w-30 pt-4 text-align-right">
        <button style="margin-top: 5px;" mat-mdc-raised-button [disabled]="selectedApplication == '' || selectedModel == '' || selectedCapability1 == '' || isOpenWQRequest"
            class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
            (click)="addOrganisationRow()">Add Organization</button>

        <button *ngIf="isEdit" style="margin-top: 5px;" mat-mdc-raised-button [disabled]="isOpenWQRequest"
            class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
            (click)="reset()">Reset</button>
    </div>
    </div>
</div>
<div class ="capability-loader" *ngIf="isLoader">
  <app-loader></app-loader>
</div>
<div class="row capability_proposal_table_block">
    <div class="table-container w-100 cap-popup-table-header-color" >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="organisation">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;
                text-align: center !important;"> <label class = "marginforheader">Organization</label></th>
                <td mat-cell *matCellDef="let element" style="width: 10%;
                text-align: center !important;">
                  <mat-form-field [floatLabel]="'never'" appearance="outline" class="select-dropdown demographics-appId-drodown org-dropdown">
                    <!-- <span class="text-notavailable-option option-size"
                      *ngIf="!orgList.includes(element.organisation)">{{element.organisation}}</span> -->
                    <mat-select panelClass="global-select-panel" (openedChange)="resetsearch($event)" [disabled]="isOpenWQRequest || (isEdit && element.isEdit && element.organisation != '')"
                      [placeholder]="element.organisation? '': 'Select the organization'" [(ngModel)]="element.organisation"
                      (selectionChange)="getselectedOrganisation(element,$event)">
                      <div>
                        <input matinput type="text" class="mat-input-search w-100"
                            placeholder="Search for an Organization"(keyup)="searchOrganizationByName($event)" [(ngModel)]="term" 
                            (blur)="resetValue()" (keydown)="$event.stopPropagation()">
                      </div>
                      <mat-option value="" class="no-cursor" *ngIf="orgList?.length == 0 && !isLoader"><label>No Results Found</label></mat-option>
                      <mat-option  [disabled]="checkexist(org.org_id)" *ngFor="let org of orgList" [value]="org.org_id">
                        <label>{{org?.org_name}}</label>
                      </mat-option>
        
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>
            <ng-container matColumnDef="current">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">Current PADU</label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;color: #fff" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.current === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.current === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.current === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.current === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.current === 'Retired',
                        'global-badge-padu-i-pop-up': element?.current=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.current === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.current === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.current=== 'Infrastructure' || element?.current=== 'PADU Pending' || (element?.current === 'Future' && editablePaduValues.includes('Future') != true)">{{element.current}}</span>
                    <mat-select [placeholder]="element.current ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.current? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.current" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option':(option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>
            <ng-container matColumnDef="transition_1">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 1}} </label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.transition_1 === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.transition_1 === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.transition_1 === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.transition_1 === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.transition_1 === 'Retired',
                        'global-badge-padu-i-pop-up': element?.transition_1=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.transition_1 === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.transition_1 === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.transition_1=== 'Infrastructure' || element?.transition_1=== 'PADU Pending' || (element?.transition_1 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_1}}</span>
                    <mat-select [placeholder]="element.transition_1 ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.transition_1? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.transition_1" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option': (option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="transition_2">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 2}} </label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.transition_2 === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.transition_2 === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.transition_2 === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.transition_2 === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.transition_2 === 'Retired',
                        'global-badge-padu-i-pop-up': element?.transition_2=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.transition_2 === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.transition_2 === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.transition_2=== 'Infrastructure' || element?.transition_2=== 'PADU Pending' || (element?.transition_2 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_2}}</span>
                    <mat-select [placeholder]="element.transition_2 ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.transition_2? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.transition_2" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option': (option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="transition_3">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 3}} </label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.transition_3 === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.transition_3 === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.transition_3 === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.transition_3 === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.transition_3 === 'Retired',
                        'global-badge-padu-i-pop-up': element?.transition_3=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.transition_3 === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.transition_3 === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.transition_3=== 'Infrastructure' || element?.transition_3=== 'PADU Pending' || (element?.transition_3 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_3}}</span>
                    <mat-select [placeholder]="element.transition_3 ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.transition_3? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.transition_3" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option': (option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="transition_4">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 4}} </label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.transition_4 === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.transition_4 === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.transition_4 === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.transition_4 === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.transition_4 === 'Retired',
                        'global-badge-padu-i-pop-up': element?.transition_4=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.transition_4 === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.transition_4 === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.transition_4=== 'Infrastructure' || element?.transition_4=== 'PADU Pending' || (element?.transition_4 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_4}}</span>
                    <mat-select [placeholder]="element.transition_4 ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.transition_4? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.transition_4" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option': (option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="transition_5">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 5}} </label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.transition_5 === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.transition_5 === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.transition_5 === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.transition_5 === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.transition_5 === 'Retired',
                        'global-badge-padu-i-pop-up': element?.transition_5=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.transition_5 === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.transition_5 === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.transition_5=== 'Infrastructure' || element?.transition_5=== 'PADU Pending' || (element?.transition_5 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_5}}</span>
                    <mat-select [placeholder]="element.transition_5 ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.transition_5? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.transition_5" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option': (option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="transition_6">
                <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef> <label class = "marginforheader">{{currentYear + 6}} </label></th>
                <td class="text-align-center" mat-cell *matCellDef="let element">
                  <span class="padu-badge-padding" style="display: block !important;" [style.opacity]="isOpenWQRequest ? '0.5' : ''" [ngClass]="{
                        'global-badge-padu-p-pop-up': element?.transition_6 === 'Preferred',
                        'global-badge-padu-a-pop-up': element?.transition_6 === 'Acceptable',
                        'global-badge-padu-d-pop-up':element?.transition_6 === 'Discouraged',
                        'global-badge-padu-u-pop-up':element?.transition_6 === 'Unacceptable',
                        'global-badge-padu-r-pop-up': element?.transition_6 === 'Retired',
                        'global-badge-padu-i-pop-up': element?.transition_6=== 'Infrastructure',
                        'global-badge-padu-pp-pop-up': element?.transition_6 === 'PADU Pending',
                        'global-badge-padu-f-pop-up': element?.transition_6 === 'Future'}">
                    <span class="text-notavailable-option no-option-text-display"
                      *ngIf="element?.transition_6=== 'Infrastructure' || element?.transition_6=== 'PADU Pending' || (element?.transition_6 === 'Future' && editablePaduValues.includes('Future') != true)">{{element.transition_6}}</span>
                    <mat-select [placeholder]="element.transition_6 ? '' : 'PADU Status'" [disabled]="isOpenWQRequest"
                      [ngClass]="element.transition_6? 'mat-select-padu': 'custom-dropdown padu-dropdown'"
                      [(ngModel)]="element.transition_6" (selectionChange)="checkrow(element)" panelClass="cap-padu-dropdown">
                      <mat-option *ngFor="let option of paduOptions" [value]="option?.value">
                        <span class="add-padding" [ngClass]="{
                         'global-badge-padu-p-pop-up-option': option?.value === 'Preferred',
                         'global-badge-padu-a-pop-up-option':option?.value === 'Acceptable',
                         'global-badge-padu-d-pop-up-option':option?.value === 'Discouraged',
                         'global-badge-padu-u-pop-up-option': option?.value === 'Unacceptable',
                         'global-badge-padu-r-pop-up-option': option?.value === 'Retired',
                         'global-badge-padu-i-pop-up-option': option?.value === 'I',
                         'global-badge-padu-pp-pop-up-option': (option?.value === 'PP' || option?.value =='PADU Pending'),
                         'global-badge-padu-tbr-pop-up-option': option?.value === 'TobeRetired',
                         'global-badge-padu-f-pop-up-option': option?.value === 'Future'
                        }"> {{ option?.label}}</span>
                      </mat-option>
                    </mat-select>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef style="width:2%; text-align: center !important"></th>
                <!-- <p style="margin-bottom: 2%">Remove</p> -->
                <td mat-cell *matCellDef="let row" style="width:4%; text-align: center !important">
                  <mat-icon class="cap-wq-delete" [class.disabled]="isOpenWQRequest" (click)="deleteCap(row)">
                    delete
                  </mat-icon>
                </td>
              </ng-container>
          
         
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            >
            <!-- [ngClass]="selection.isSelected(row) ? 'highlightRow' : 'unhighlightRow'" -->
          </tr>
        </table>

        
      </div>
      <div class="col-5 p-0 mt-5">
        <label class="section-name tabl-header tabl-header-font">
          Justification
          <span class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
        </label>
        <mat-form-field appearance="outline" class="demographics-justification textarea-css padu-justification">
            <p class="m-0"><textarea class="textarea_text lob-change-textarea" [disabled]="isOpenWQRequest"
                    rows="3" maxlength="300" [(ngModel)]="justification" matInput placeholder="" style="padding-left: 1%;width: 98% !important"></textarea></p>
        </mat-form-field>
    </div>
</div>
<div class="row capability_submit_block">
  <button  mat-mdc-raised-button [disabled]="isDataUpdated()"
            class="uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
            (click)="submitRequest()">Apply</button>
</div>