import { Component, HostListener, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { NavigationEnd, Router, ActivatedRoute, ParamMap, NavigationStart } from '@angular/router';
import { SpinnerOverlayService } from './spinner-overlay.service';
import { Title } from '@angular/platform-browser';
import jwt_decode from 'jwt-decode';
import { Cookie } from 'ng2-cookies';
import { AuthService } from '../app/login/auth/auth.service';
//import { Angulartics2Piwik } from 'angulartics-piwik';
import { OptimizeService } from './shared/optimize.service';
import { IdletimeService } from './shared/services/idletime.service';
import { SharedService } from './shared';
import { PixelService } from './shared/services/pixel.service';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
declare global {
  interface Window {
    Rakanto: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [IdletimeService]
})
export class AppComponent implements OnInit {
  title = 'tcmhubv2';
  loading = false;
  loggedUser = JSON.parse(localStorage.getItem('currentUser')) || true;
  sidebar = false;
  tcmhub = true;
  padu = false;
  title1 = 'TCM Hub';
  title2 = 'Application Hub';
  title3 = 'TechOps Hub';
  isPathApplicationInsights: boolean = false;
  appLoader: boolean;
  loggedIn;
  currentPath: boolean = false;
  path: string = '';
  showSpinner: boolean = false;
  successSSOLogin: boolean = false;
  MsId = localStorage.getItem('MsId');
  code: any;
  accessToken: any;
  idToken: any;
  result: any;
  ssoenabled: boolean = false;
  public browserRefresh = false;
  idleTimerLeft: string;
  idelTimeSeconds: string;
  secondTimerLeft: string;
  timeRemain: number;
  FULL_DASH_ARRAY = 283;
  intialvalue = 120;
  currentUrl: any = '';
  role = 'Others';
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    private titleService: Title,
    private router: Router,
    private spinnerService: SpinnerOverlayService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    //private angulartics2Piwik: Angulartics2Piwik,
    private tcmoptimise: OptimizeService,
    private ngIdle: IdletimeService,
    private sharedService: SharedService,
    private PixelService: PixelService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(this.platformId));

    if (environment.enableSSO) {
      this.ssoenabled = environment.enableSSO;
      this.checkSSOCookie();
    } else {
      this.successSSOLogin = true;
      this.router.navigate([{ outlets: { primary: 'login' } }]);
    }

    console.log('Environment Name :' + environment.environmentName + ' api Url: ' + environment.apiUrl);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.currentUrl = event['url'];
        if (this.currentUrl == '/') this.currentUrl = '/landing-page';
        // this.startPixelTracking(event,'start');
      }
      if (event instanceof NavigationEnd) {
        if (sessionStorage.getItem('MsId')) this.PixelService.startPixelTracking(event, 'end', '');
        const pathArray = window.location.pathname.split('/')[1];
        const pathSplit = window.location.pathname.split('/');
        let subPath = '';

        if (pathSplit && pathSplit.length && typeof pathSplit[2] != 'undefined') {
          subPath = pathSplit[2];
        }

        if (pathArray === 'uhcproduct-home(sidebar:padu)' || pathArray === 'uhcproduct-home') {
          this.currentPath = true;
        } else {
          this.currentPath = false;
        }
        if (
          ['application-insights', 'application-insights(sidebar:padu)', 'maintenance'].includes(pathArray) ||
          ['stakeholder-view', 'detail-view'].includes(subPath)
        ) {
          this.isPathApplicationInsights = true;
        } else {
          this.isPathApplicationInsights = false;
        }

        // if (pathArray)
        this.tcmoptimise.getCurrentPath(pathArray);
        this.checklatest();
      }
      // if(event instanceof NavigationStart){
      //   console.log("entes.....")
      //   const pathArray = window.location.pathname.split('/')[1];
      //   console.log(pathArray,"pathArray...")
      // }
    });
  }
  @HostListener('document:click', ['$event.target'])
  handleDocumentClicks(target) {
    let event = { url: '' };
    let title = target.getAttribute('Pixel-title');
    event.url = window.location.pathname || title;
    let Tracker = target.getAttribute('Pixel-tracking') ? true : false;
    let tabcheck = !target['parentNode']?.['parentNode']?.['parentNode']?.['parentNode']?.['parentNode']?.['parentNode']
      ? target['parentNode']?.['parentNode']?.['parentNode']?.['parentNode']?.['parentNode']?.[
          'parentNode'
        ]?.getAttribute('Pixel-tracking')
      : false;
    if (tabcheck) {
      Tracker = true;
      title = target.innerText;
    }
    console.log(Tracker, title);
    if (Tracker) this.PixelService.startPixelTracking(event, 'click', title);
    //do some logic here
  }
  ngOnInit() {
    this.spinnerService.showSpinnerSubject.subscribe(val => {
      this.appLoader = val;
    });
    this.loggedIn = localStorage.getItem('MsId');
    this.initTimer(this.intialvalue);
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  checkSSOCookie() {
    this.showSpinner = true;
    this.successSSOLogin = false;
    const at = sessionStorage.getItem('token');
    if (!at || at === 'undefined') {
      //cookie is empty, obtain tokens
      const params = new URLSearchParams(window.location.search);
      this.code = params.get('code');
      Cookie.set('codeCreated', JSON.stringify(true));
      if (!this.code) {
        setTimeout(() => {  
           //no code in params
        window.location.href = environment.sso_keys.url; }, 300); 
        if (!localStorage.getItem('returnUrl')) {
          localStorage.setItem('returnUrl', window.location.pathname + window.location.search);
      }
      } else {
        // remove the code from routes params after getting its value

        this.activatedRoute.queryParamMap.subscribe(paramMap => {
          this.removeSSOCode(paramMap, 'code');
          // ...
        });

        //code + no tokens! Getting all tokens now       
        setTimeout(() => {
          this.setAllTokens(this.code);
        }, 3000);     
        this.showSpinner = false;
      }
    } else {
      //tokens present, check expiry of access token
      let expireDate = new Date(Cookie.get('expires_in')).getTime();
      let currDate = new Date().getTime();

      if (expireDate <= currDate) {
        //expired, obtain new access token
        let refreshTkn = Cookie.get('refresh_token');
        this.setAccessToken(refreshTkn);
        this.showSpinner = false;
      } else {
        this.successSSOLogin = true;

        // this.router.navigate([{ outlets: { primary: 'landing-page' } }]);
        this.showSpinner = false;
      }
    }
  }

  private removeSSOCode(paramMap: ParamMap, key: string) {
    if (!paramMap.has(key)) {
      return;
    }
    this.removeParamFromUrl(paramMap, key);
  }

  private removeParamFromUrl(paramMap: ParamMap, key: string) {
    if (!paramMap.has(key)) {
      return;
    }

    const queryParams = {};
    paramMap.keys.filter(k => k != key).forEach(k => (queryParams[k] = paramMap.get(k)));
    this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.activatedRoute });
  }

 setAllTokens(code) {
    //sets all tokens when cookie is empty
    this.auth.tokenExchange(code).subscribe(
      data => {
        if (data) {
          Cookie.set('refresh_token', data.refresh_token);
          this.setUserDetails(data);
          this.showSpinner = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  setUserDetails(accessdata) {
    const { sn, givenName, middleName = '', access_token, token, mail, cn, expires_in, secureGroups } = accessdata;
    // console.log(this.idToken);

    const fullName = `${sn} , ${givenName} ${middleName}`;
    sessionStorage.setItem('currentUser', JSON.stringify(fullName));
    sessionStorage.setItem('loggedInUserEmail', JSON.stringify(mail));
    sessionStorage.setItem('token', JSON.stringify(token).slice(1, -1));
    sessionStorage.setItem('MsId', cn);

    localStorage.setItem('currentUser', JSON.stringify(fullName));
    localStorage.setItem('loggedInUserEmail', JSON.stringify(mail));
    // localStorage.setItem('token', JSON.stringify(token).slice(1, -1));
    // localStorage.setItem('accessData', JSON.stringify(data));
    // sessionStorage.setItem('writeLob',  response.writeLobs);
    // localStorage.setItem('isWriteAccess', JSON.stringify(isWriteAccess));
    localStorage.setItem('MsId', cn);
    localStorage.setItem('secureGroups', JSON.stringify(secureGroups));
    Cookie.set('MsId', cn);
    Cookie.set('fullName', fullName);
    let config = localStorage.getItem('config');
    if (config) localStorage.removeItem('config');
    localStorage.removeItem('reclassAccess');
    localStorage.removeItem('flagAccess');
    this.successSSOLogin = true;

    // this.router.navigate([{ outlets: { primary: 'landing-page' } }]);
    // let groups = this.idToken.groups;
    // console.log(groups);
    // for (var i in groups) {
    //   if (groups[i].includes('github_users')) {
    //     Cookie.set('github_user', 'true');
    //     break;
    //   } else {
    //     Cookie.set('github_user', 'false');
    //   }
    // }
    window?.Rakanto.event('setUserIdentity', { authSystem: 'MSAD', userId: sessionStorage.getItem('MsId') });
    this.saveAccessToken({ access_token, exp: expires_in });
  }

  decodeIDtoken(tkn) {
    this.idToken = jwt_decode(tkn.id_token);
    Cookie.set('MsId', this.idToken.msid);
    Cookie.set('fullName', this.idToken.name);
    localStorage.setItem('MsId', this.idToken.msid);
    localStorage.setItem('currentUser', this.idToken.name);
    localStorage.setItem('loggedInUserEmail', this.idToken.email);
    //localStorage.setItem('accessData', this.idToken.accessData);
    // localStorage.setItem("employeeType", this.idToken.employeeType);
    // let groups = this.idToken.groups;
    // console.log(groups);
    // for (var i in groups) {
    //   if (groups[i].includes('github_users')) {
    //     Cookie.set('github_user', 'true');
    //     break;
    //   } else {
    //     Cookie.set('github_user', 'false');
    //   }
    // }
    this.saveAccessToken(tkn);
  }

  saveAccessToken(access_token) {
    if (localStorage.getItem('accessData') && localStorage.getItem('accessData') != 'null') {
      this.role = JSON.parse(localStorage.getItem('accessData')).Role;
    }
    //saves access token & its expiry into cookies
    this.accessToken = access_token;
    let currenttime = new Date();
    let expiry = currenttime.getTime() + this.accessToken.exp * 1000;
    Cookie.set('access_token', this.accessToken.access_token);
    Cookie.set('expires_in', String(expiry));
    this.successSSOLogin = true;
    this.showSpinner = false;
    this.router.navigateByUrl('/landing-page');
  }

  setAccessToken(token) {
    //retrieves new access token on expiry of prev
    this.auth.getNewAccessToken(token).subscribe(
      data => {
        this.saveAccessToken(data);
        this.showSpinner = false;

        // Access token expired, new access token obtained!
      },
      error => {
        //if token expires or invalid parameters sent
        if (error) {
          Cookie.deleteAll();
          window.location.href = environment.sso_keys.url;
        }
      }
    );
  }
  initTimer(firstTimerValue): void {
    // Timer value initialization
    this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = firstTimerValue;
    // end

    // Watcher on timer
    this.ngIdle.initilizeSessionTimeout();
    this.ngIdle.userIdlenessChecker.subscribe((status: string) => {
      this.initiateFirstTimer(status);
    });
  }
  initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;

      case 'STOPPED_TIMER':
        this.showSendTimerDialog();
        break;

      default:
        this.idleTimerLeft = this.formatTimeLeft(Number(status));
        break;
    }
  };
  showSendTimerDialog(): void {
    this.setTime();
    const modal = document.getElementById('SessionModal');
    modal.style.display = 'block';
  }
  setTime() {
    let totalSeconds = 0;
    totalSeconds = 180;
    let downloadTimer = setInterval(function() {
      if (totalSeconds <= 0) {
        clearInterval(downloadTimer);
        sessionStorage.removeItem('token');
        localStorage.removeItem('config');
        localStorage.removeItem('landingPageContent');
        Cookie.delete('refresh_token');
        document.getElementById('countdown').innerHTML = 'has been expired ';
      } else {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        document.getElementById('countdown').innerHTML = `will expire in ${minutes}:${seconds}`;
      }
      totalSeconds -= 1;
    }, 1000);
  }
  continue(): void {
    if (window.location.pathname && window.location.pathname.split('/')[1] != '') {
      // Cookie.set('historyUrl', window.location.pathname + window.location.search);
    }
    const modal = document.getElementById('SessionModal');
    modal.style.display = 'none';

    // stop second timer and initiate first timer again
    IdletimeService.runSecondTimer = false;
    this.ngIdle.initilizeSessionTimeout();
    if (!sessionStorage.getItem('token')) {
      this.router.navigate(['/']);
      window.location.reload();
    }
  }
  formatTimeLeft = (time: number) => {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);
      this.setCircleDasharray(seconds);
      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        seconds -= min * 60;
      }
      return `${min}:${seconds}`;
    }
  };
  formatSeconds(time: number) {
    if (time > 0) {
      let seconds = Math.trunc(time / 1000);

      this.setCircleDasharray(seconds);

      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        seconds -= min * 60;
      }
      return [seconds, min];
    }
  }

  setCircleDasharray = (elapsedTime: number) => {
    const inputValue: any = this.intialvalue;
    const timeLimit = inputValue * 60;

    this.timeRemain = elapsedTime / timeLimit;
  };
  saveUserLogoutStats(msId: string) {
    const body = { logOut_at: new Date().toISOString() };
    this.sharedService.createUserStats(msId, body).subscribe(() => {});
  }
  refreshStorage(access_token) {
    //saves access token & its expiry into cookies
    this.accessToken = access_token;
    let currenttime = new Date();
    let expiry = currenttime.getTime() + this.accessToken.exp * 1000;
    Cookie.set('access_token', this.accessToken.access_token);
    Cookie.set('expires_in', String(expiry));
    this.successSSOLogin = true;
    this.showSpinner = false;

    let msID = localStorage.getItem('MsId');
    let UserEmail = localStorage.getItem('loggedInUserEmail');
    let currentUser = localStorage.getItem('currentUser');
    let token = localStorage.getItem('token');
    if (currentUser != null && !sessionStorage.getItem('currentUser')) {
      sessionStorage.setItem('currentUser', currentUser);
    }
    if (msID != null && !sessionStorage.getItem('MsId')) {
      sessionStorage.setItem('MsId', msID);
    }
    if (UserEmail != null && !sessionStorage.getItem('loggedInUserEmail')) {
      sessionStorage.setItem('loggedInUserEmail', UserEmail);
    }
    if (token != null && !sessionStorage.getItem('token')) {
      sessionStorage.setItem('token', token);
    }
  }
  updateAccess(token) {
    //retrieves new access token on expiry of prev
    this.auth.getNewAccessToken(token).subscribe(
      data => {
        this.showSpinner = false;
        this.refreshStorage(data);
        // Access token expired, new access token obtained!
      },
      error => {
        //if token expires or invalid parameters sent
        if (error) {
          window.location.reload();
        }
      }
    );
  }
  refesh() {
    window.location.reload();
  }

  checklatest() {
    let minuteCount = (minutesAfterHour): any => {
      const now = new Date();
      const minutes = now.getMinutes();

      if (minutes == minutesAfterHour) {
        this.tcmoptimise.updateConfig();
      }
    };
    minuteCount(59);
  }
}
