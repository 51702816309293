import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ElementRef,
  OnChanges,
  ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-accordian-table',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './accordian-table.component.html',
  styleUrls: ['accordian-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordianTableComponent implements OnInit, OnChanges {
  public PrevYear = new Date().getFullYear() -1;
  @Input() data: any;
  @Input() suidata: any;
  pageOfItems: Array<any>;
  @Input() Pagination = true;
  @Input() pageSize = 5;
  /* @Input() suidata: any;
   public page = 1;
   dataSource: any = new MatTableDataSource<any>([]);
   displayedColumns1: string[] = [
     'LONG_APP_NAME',
     'SV_DESC',
     'CS_SERVICE_UNIQUE_IDENTIFIER',
     'CS_SUI_DESCRIPTION',
     'CHARGES',
   ];
   newSuiData: any = [];
   oldSuiData: any;
   @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
   @ViewChildren(MatSort) sort = new QueryList<MatSort>();
 */
  constructor(private container: ElementRef) { }

  ngOnChanges() {
    if (this.data) {
      // console.log(this.data);
      this.pageOfItems = this.data;
    }
  }
  ngOnInit() {
    if (!this.Pagination) {
      this.pageOfItems = this.data;
    }
  }

  getColor(mobscore) {
    if (mobscore >= 3) {
      return 'green';
    } else {
      return 'red';
    }
  }

  accordiontoggle(event) {
    const panel = event.target.parentNode.parentNode.nextElementSibling;
    // console.log(event.target.parentNode.parentNode);
    if (panel.style.display === 'table-row') {
      panel.style.display = 'none';
      event.target.parentNode.parentNode.classList.remove('active-row');
      event.target.classList.remove('uitk-icon-arrow_down');
      event.target.classList.add('uitk-icon-arrow_right');
    } else {
      panel.style.display = 'table-row';
      event.target.classList.add('uitk-icon-arrow_down');
      event.target.classList.remove('uitk-icon-arrow_right');
      event.target.parentNode.parentNode.classList.add('active-row');
    }
    /*const panel2 = event.target.parentNode.parentNode.nextElementSibling.nextElementSibling;
    // console.log(event.target.parentNode.parentNode);
    if (panel2.style.display === 'table-row') {
      panel2.style.display = 'none';
    } else {
      panel2.style.display = 'table-row';
    }
    */
  }
  onChangePage(pageOfItems: Array<any>) {
    // console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
    /*this.page = event.pager;
    setTimeout(() => {
      this.pageOfItems.forEach((item, index) => {
        this.parsesuidata(this.suidata[item.name], index, item.name);
      });
    });
    */
  }
  /*parsesuidata(data, index, suiindex) {
    this.newSuiData[suiindex] = data;
    const pageindex = this.pageSize * (this.page - 1);
    const pagerindex = index + pageindex;
    this.dataSource[suiindex] = new MatTableDataSource<any>(data);
    this.dataSource[suiindex].paginator = this.paginator.toArray()[index];
    this.dataSource[suiindex].sort = this.sort.toArray()[index];
    // console.log(this.dataSource[suiindex]);
  }
  change_order(event, index, suiindex) {
    const pageindex = this.pageSize * (this.page - 1);
    const pagerindex = index + pageindex;
    if (event.direction === 'asc') {
      const result = this.newSuiData[suiindex].sort((a, b) => {
        const x = a.SUI_TYPE.toString().toLowerCase() - b.SUI_TYPE.toString().toLowerCase();
        return x;
      });
      this.dataSource[suiindex] = new MatTableDataSource<any>(result);
      this.dataSource[suiindex].paginator = this.paginator.toArray()[index];
      this.dataSource[suiindex].sort = this.sort.toArray()[index];
      //  console.log(event,result);
    } else {
      const result = this.newSuiData[suiindex].sort((a, b) => {
        const x = b.SUI_TYPE.toString().toLowerCase() - a.SUI_TYPE.toString().toLowerCase();
        return x;
      });
      this.dataSource[suiindex] = new MatTableDataSource<any>(result);
      this.dataSource[suiindex].paginator = this.paginator.toArray()[index];
      this.dataSource[suiindex].sort = this.sort.toArray()[index];
      //  console.log(event,result);
    }
    // console.log(this.dataSource[suiindex]);
  }
  */
}
