import { Component, OnInit, Inject } from '@angular/core';
import { ProductMappingService } from 'src/app/padu-reporting/product-mapping-view/product-mapping.service';
import { AppModernizationService } from '../../services/app-modernization.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {
  showLoader: boolean = false;
  productList = [];
  selectedProduct;
  view;
  buttonName: string = '';
  productCap = '';
  productCapList = [];
  listSubscription: any;
  constructor(@Inject(MAT_DIALOG_DATA) data, private service: ProductMappingService, private appModernService: AppModernizationService,
    public dialog: MatDialogRef<PopupComponent>) {
    this.view = data.title;
    switch (this.view) {
      case 'p360':
        this.buttonName = 'Product 360';
        break;

      case 'app-view':
        this.buttonName = 'View by Applications';
        break;
      case 'stakeholder-view':
        this.buttonName = 'View by Stakeholder';
        break;


    }
  }

  ngOnInit() {
    this.showLoader = true;
    this.listSubscription = this.service.getProductGridData().subscribe(d => {
      this.productList = [...new Map(d.map(item =>
        [item['productId'], item])).values()];
      // this.productList = d.productList;
      this.productCapList = this.productList;
      this.showLoader = false;

    });

  }

  save() {
    this.dialog.close(this.selectedProduct);
  }
  close() {

    this.dialog.close(undefined);
  }

  filterInputSearch(name) {
    switch (name) {
      case 'product-cap':
        this.productList = this.productCapList.filter(obj => obj.product.toLowerCase().indexOf(this.productCap.toLowerCase()) > -1);
        break;
    }

  }

  clearSearch(name) {
    switch (name) {

      case 'product-cap':
        this.productCap = '';
        this.filterInputSearch('product-cap');
        break;
    }
  }

  ngOnDestroy() {
    this.listSubscription.unsubscribe();
  }

}
