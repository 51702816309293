<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
    <div class="card m-3">
        <div class="card-body">
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <ng-container *ngFor="let data of formData">
                    <div class="form-group">
                        <label>{{data.fieldName}}</label>
                        <input type="text" formControlName="{{data.value}}" class="form-control" />
                    </div>
                </ng-container>
                <mat-dialog-actions class="text-center" align="end">
                    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" mat-dialog-close>Cancel</button>
                    <button  class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"  type="submit"  cdkFocusInitial>{{submitName}}</button>
                </mat-dialog-actions>
            </form>
        </div>
    </div>
</mat-dialog-content>
<!-- <div class="uitk-c-page-notification--error">
<div class="uitk-c-page-notification__title--error" mat-dialog-title>
<span aria-hidden="true" class="uitk-icon uitk-c-page-notifications__icon ng-tns-c98-0 uitk-icon-alert_filled" ng-reflect-ng-class="uitk-icon-alert_filled"></span>
{{title}}
</div>
</div> -->
