<section *ngIf="addReportForm" class="reportform pt-2">
    <form [formGroup]="addReportForm">
        <div class="row m-0">
            <div class="col-3 mb-3" *ngFor="let field of formfields">
                <label class="section-name tabl-header tabl-header-font">
                    {{field.label}}
                </label>
                <span *ngIf="isRequired(field.fcname,field.dependentField,field.depFieldVal,field.mandCheck)"
                    class="mandate-asterisk uitk-icon uitk-icon-asterisk sm-icon"></span>
                <div>
                    <ng-container [ngSwitch]="field.inputType">
                        <ng-container *ngSwitchCase="'singleSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <input style="border:none;width:100%"
                                    class="edit text-notavailable-option option-size" />
                                <mat-select [placeholder]='field.placeholder' [name]="field.fcname"
                                    [formControlName]="field.fcname">
                                    <mat-option class="dropDownList" *ngFor="let model of field.dropdownData"
                                        [value]="model">
                                        <ng-container>{{model}}</ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multiSelect'">
                            <mat-form-field appearance="outline" class="custom-dropdown">
                                <mat-select panelClass="global-select-panel" [name]="field.fcname"
                                    [formControlName]="field.fcname" multiple>
                                    <div class="table-data-font">
                                        <mat-option class="global-mat-option" *ngFor="let data of field.dropdownData"
                                            title="{{data}}" [value]="data"><label
                                                style="margin:0%">{{data}}</label></mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <mat-form-field appearance="outline" class="lookup-text-box">
                                <div>
                                    <input type="text" matInput [name]="field.fcname" [formControlName]="field.fcname"
                                        [placeholder]='field.placeholder' />
                                </div>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'">
                            <mat-form-field appearance="outline" class="lookup-text-box">
                                <div>
                                    <input type="number" matInput [name]="field.fcname" [formControlName]="field.fcname"
                                        [placeholder]='field.placeholder' />
                                </div>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'textarea'">
                            <!-- <mat-form-field appearance="outline"> -->
                                <div>
                                <textarea type="textarea" rows="1" cols="1" class="form-control textarea-text" [name]="field.fcname"
                                    [formControlName]="field.fcname" [placeholder]='field.placeholder'></textarea>
                                </div>
                            <!-- </mat-form-field> -->
                        </ng-container>
                        <ng-container *ngSwitchCase="'yesnoradio'">
                            <mat-radio-group [name]="field.fcname" [formControlName]="field.fcname">
                                <mat-radio-button color="primary" value='Yes'>Yes</mat-radio-button>
                                <mat-radio-button class="ml-2" color="primary" value='No'>No</mat-radio-button>
                            </mat-radio-group>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="form-footer">
            <div class="footer-buttons">
                <button mat-mdc-raised-button
                    class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs pull-right"
                    type="button" (click)="submitForm()" [disabled]="addReportForm.invalid">
                    Save
                </button>
            </div>
        </div>
    </form>
</section>