
<ng-container *ngIf="!showLoader">
  
    <div mat-dialog-title>
        <div class="row">
            <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-10 col-sm-12 col-lg-10 col-xl-10 col-xs-10 col-sm-12 col-lg-10 col-xl-10 col-xs-10">
                <h5>Select Product Capability </h5>
            </div>
            <div class="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-2">
                <span class="uitk-icon uitk-icon-close md-icon" (click)="close()"
                    style="color: #6F6F6F; font-weight: 900; float: right; cursor: pointer;"></span>
            </div>
        </div>
        

    </div>


            <div mat-dialog-content>
                <div class="card m-3">
                    <div class="card-body">
                      
                            <!-- <ng-container *ngFor="let data of formData"> -->
                                <div class="form-group">
                                    <label>Product Capability</label>
                                    <mat-form-field appearance="outline">
                                        <!-- <mat-label>Select Organization</mat-label> -->
                                        <mat-select  [(ngModel)]="selectedProduct" placeholder="Search product capability"
                                          >
                                          <input matInput type="text" class="searchoptionInput" (keyup)="filterInputSearch('product-cap')"
                                          (keydown)="$event.stopPropagation()" [(ngModel)]="productCap"
                                          placeholder="Search Product Capability" />
              
                                      <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch('product-cap')">
                                          <mat-icon class="cross-icon-input">close</mat-icon>
                                      </button>
              
                                            <mat-option  *ngFor="let product of productList" [value]="product">
                                                {{product.product}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            <!-- </ng-container> -->
                            <!-- <mat-dialog-actions class="text-center" align="end">
                                <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" mat-dialog-close>Cancel</button>
                                <button  class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs"  type="submit"  cdkFocusInitial>{{submitName}}</button>
                            </mat-dialog-actions> -->
                      
                    </div>
                </div>
            </div>
            <div class="row">
                <mat-dialog-actions class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12" style="display: flex; justify-content: center;">
                    <button style="font-size: 12px;  font-family: 'UHC-Sans-Bold' !important;"
                       
                        type="submit" (click)="save()"
                        class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs edit-button">{{buttonName}}</button>      
                </mat-dialog-actions>
            </div>
</ng-container>

<div *ngIf="showLoader" class="row loader-container">
    <app-loader style="stroke:  #002677 !important;"></app-loader>
</div>