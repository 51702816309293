
<div class="global-dropdown dropdown-main-div">
    
    <!-- <mat-form-field appearance="outline" *ngIf="isMultiSelect === 'true'" class="select-dropdown">
        <mat-label><label>{{placeholder}}</label></mat-label>
        <mat-select panelClass="global-select-panel" [disabled]="disableDD === true" (keydown)="$event.stopPropagation()" (selectionChange)="onSelectionChange($event, placeholder)"
            [(ngModel)]="selected" #filterSelect="ngModel" multiple>
        <div>
            <input *ngIf="searchFlag === 'true'" matinput type="text" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt" class="mat-input-search" placeholder="Search">
        </div>
        <div class="table-data-font">
            <ng-container >
                <mat-checkbox class="mat-option"
                [disableRipple]="false"
                [indeterminate]="isIndeterminate(filterSelect, dropdownData)"
                [checked]="isChecked(filterSelect, dropdownData)"
                (click)="$event.stopPropagation()"
                (change)="toggleSelection(checkbox, filterSelect, dropdownData, placeholder)" #checkbox> select all </mat-checkbox>
                <mat-option class="global-mat-option" *ngFor="let data of dropdownData | search : searchTxt" [value]="data"><label>{{data.name}}</label></mat-option>
            </ng-container>
        </div>
        </mat-select>
    </mat-form-field> -->

    <mat-form-field appearance="outline" *ngIf="isMultiSelect !== 'true'" class="select-dropdown">
        <mat-label><label>{{placeholder}}</label></mat-label>

        <!-- <div *ngIf="!dropdownData">
            <input matInput type="text" class="mat-input-search" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt" 
            placeholder="Search (min 3 char)" (keyup)="OnSearch($event, placeholder)"/>
        </div> -->
        <mat-select panelClass="global-select-panel" [disabled]="disableDD == true" (keydown)="$event.stopPropagation()" (selectionChange)="onSelectionChange($event, placeholder)"
        [(ngModel)]="selected" #filterSelect="ngModel">
            <div>
                <input matInput type="text" class="mat-input-search m-0 pl-2" (keydown)="$event.stopPropagation()" [(ngModel)]="searchTxt" 
                placeholder="Search (min 3 char)" (keyup)="OnSearch($event)"/>
            </div>
            
          <mat-option *ngFor="let data of dropdownData" [value]="data">
            <label>{{data}}</label>
          </mat-option>
      </mat-select>
    </mat-form-field>
</div>