import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { appModernizationDetails, tcmHubConfigDetails } from '../../config';
import { AppHubCacheService } from '../../shared';
import { apphubV4APIs } from '../configV4';
import { OptimizeService } from 'src/app/shared/optimize.service';

@Injectable({
    providedIn: 'root'
})
export class CostService {
    private apiUrl: string;
    private config: any;
    public routeSelected = new Subject<boolean>();
    public lobChangeSubject = new Subject<any>();
    public primaryUpdateubject = new Subject<any>();
    public organisationAddSubject = new Subject<any>();
    public organisationDeleteSubject = new Subject<any>();
    public tcoData = new Subject<any>();
    public configV4: any;
    public config2: any;
    public excelData: any;
    public PaduRoadMapData: any;
    public associatedCapabiltes: any;
    public costData:any;
    public tcmViewData:any;
    public ForcastCycle: any;
    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: '*/*'
        })
    };
    CurrentYear = tcmHubConfigDetails.CurrentYear;
    PrevYear = tcmHubConfigDetails.PrevYear;
    CurrentMonth = tcmHubConfigDetails.CurrentMonth;
    PreMonth = tcmHubConfigDetails.PreMonth;
    CurrentMonthPrevYear = tcmHubConfigDetails.CurrentMonthPrevYear;
    Month = tcmHubConfigDetails.Month;
    constructor(
        private http: HttpClient,
        private http2: HttpClient,
        private dataCache: AppHubCacheService,
        handler: HttpBackend,
        private optimize:OptimizeService
    ) {
        this.configV4 = apphubV4APIs;
        this.config = appModernizationDetails;
        this.config2 = tcmHubConfigDetails;
        this.apiUrl = environment.apiUrl;
        this.http2 = new HttpClient(handler);
        this.getYearMonth();
    }
    /* to get month and year
    */
    getYearMonth() {
        this.optimize.getCurrentYear().subscribe(
          (res: any) => {
            if (res) {
                this.CurrentYear=res.currentYear;
                this.Month=res.currentMonth;
                this.PreMonth =res.previousMonth;    
                this.ForcastCycle = res.forcastCycle;        
            }
          },
          err => {
            console.error(err);
            localStorage.removeItem('config');
          }
        );
      }
    /*
     * @author: Anjit
     * @description: to get TCO and Infra cost details
     * @augments: Application ID
     * @returns: TCO  or Infra cost details of  given Apllication based on request type
     */
    public getcostdata(appId: string, year: string): Observable<any> {
        const url = this.apiUrl + this.configV4.routerPath.getcostdata;
        return this.http.get(url, { params: { appId, year } });
    }
    public getTcoCostDetails(appId): Observable<any> {
        const url = this.apiUrl + this.configV4.routerPath.getTcoCostViewDetails;
        return this.http.get<any>(url, { params: { appId,Year:this.CurrentYear,Month:this.Month,PrivsMonth:this.PreMonth  } })
        .pipe(
            map(data => {
              if (data) {
                this.setTcoData(data);
                return data;
              } else {
                this.setTcoData(data);
                return data;
              }
            })
          );
    }

    setTcoData(data) {
        this.tcoData.next(data);
    }
    
    getTcoData() {
        return this.tcoData;
    }
    /*
 * @author: Anjit
 * @description: to get Labour cost details
 * @augments: Application ID
 * @returns:  Labour cost details of  given Apllication based on request type
 */
    public getlabourdata(appId, type: string): Observable<any> {
        const url = this.apiUrl + this.configV4.routerPath.getlabourdata;
        return this.http.get<any>(url, { params: { appId, type } });
    }

    /* @author: Anjit
     * @description: To send the mail to faccilitor mail
     * @augments: Application body
     * @returns:  send mail 
     */
    facilitatorMail(body: any) {
        let currentUser = sessionStorage.getItem('currentUser') || localStorage.getItem('currentUser');
        let loggedInUserEmail = sessionStorage.getItem('loggedInUserEmail') || localStorage.getItem('loggedInUserEmail');
        body.currentUser = currentUser;
        body.loggedInUserEmail = loggedInUserEmail;
        const apiUrl = this.apiUrl + this.config2.routerpath.facilitatorMail;
        let mailPromise = this.http.post<any>(apiUrl, body, this.httpOptions).toPromise();
        mailPromise
            .then(function (data) {
                if(data) {
                  return 'An email has been sent to the TCM team, someone will reach out to you';
                }
            })
            .catch(function (err) {
                console.error(err);
                return 'Error occured while sending the mail, please try again later';
            });
    }
    /*
* @author: Anjit
* @description: add comment for service 
* @augments: Application ID, Service, comment
* @returns: success
*/
    public addcomment(body) {
        const apiUrl = `${this.apiUrl}${this.configV4.routerPath.addcomment}`;
        return this.http.post(apiUrl, body, this.httpOptions).toPromise();
    }
    /*
* @author: Anjit
* @description: delete the comment 
* @augments: Application ID, Service name
* @returns: success
*/
    public updatecomment(body, id) {
        const apiUrl = `${this.apiUrl}${this.configV4.routerPath.updatecomment}/${id}`;
        return this.http.patch(apiUrl, body, this.httpOptions).toPromise();
    }
    /*
    * @author: Anjit
    * @description: to get TCO and Infra cost details
    * @augments: Application ID
    * @returns: TCO  or Infra cost details of  given Apllication based on request type
    */
    public deletecomment(id) {
        const apiUrl = `${this.apiUrl}${this.configV4.routerPath.deletecomment}/${id}`;
        return this.http.delete(apiUrl, this.httpOptions).toPromise();
    }
    /*
 * @author: Anjit
 * @description: to get  all comment 
 * @augments: service name and application id
 * @returns: comments list
 */
    public getcomments(body) {
        const apiUrl = `${this.apiUrl}${this.configV4.routerPath.getcomments}`;
        return this.http.post(apiUrl, body, this.httpOptions).toPromise();
    }
}
