import { Component, EventEmitter, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { AppHubService } from 'src/app/techopsv4/application-hub/app-hub.service';
import { ConstantsData } from 'src/app/constants/fields';
import { AppViewService } from 'src/app/padu-reporting/application-view/app-view.service';
import { PADUUtilsService } from '../../services/paduUtils.service';
import { SharedService } from '../../services/shared.service';
import { SidenavService } from '../../services/sidenav.service';
@Component({
  selector: 'app-padu-edit-modal',
  templateUrl: './padu-edit-modal.component.html',
  styleUrls: ['./padu-edit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaduEditModalComponent implements OnInit {

  paduData: any;
  paduEditColumns: any;
  paduStatusCol: any;
  selectedLOB: any;
  selectedSUBLOB: any;
  public paduOptions: any = [
    { label: 'Preferred', value: 'P' },
    { label: 'Acceptable', value: 'A' },
    { label: 'Discouraged', value: 'D' },
    { label: 'Unacceptable', value: 'U' },
    { label: 'Retired', value: 'R' },
    { label: 'To Be Retired', value: 'TBR' },
  ];
  public replacementOptions: any = [];
  PADUFullName = ConstantsData.PADUFullName;
  PADUShortName = ConstantsData.PADUShortName;
  currentPADU: any;
  transition: any[] = [];
  PaduClasess = {
    "P": "badge-padu-p",
    "A": "badge-padu-a",
    "D": "badge-padu-d",
    "U": "badge-padu-u",
    "R": "badge-padu-r",
    "I": "badge-padu-i",
    "PP": "badge-padu-pp",
    "TBR": "badge-padu-tbr"
  }

  onSave = new EventEmitter();
  onRoadMapSave = new EventEmitter();
  // public RoadmapOptions: any = [
  //   { label: 'Replace', value: 'Replace' },
  //   { label: 'Decommission Planned', value: 'Decommission Planned' },
  //   { label: 'Re-Architect', value: 'Re-Architect' },
  //   { label: 'Ready for Decom', value: 'Ready for Decom' },
  //   // { label: 'Decom in Progress', value: 'Decom in Progress' },
  //   // { label: 'Decommed', value: 'Decommed' },
  //   // { label: 'Retired', value: 'Retired' },
  // ];\

  public RoadmapOptions :any =[
    {label: 'Enterprise Solution', value: 'Enterprise Solution'},
    {label: 'Re-Architect', value: 'Re-Architect'},
    {label: 'Simplification Target', value: 'Simplification Target'},
    {label:'Ongoing Decomm Effort',value:'Ongoing Decomm Effort'},
    {label:'Accelerated Decom Opportunity',value:'Accelerated Decom Opportunity'}]

  public YesNoOptions: any = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  public QuarterList: any = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' },
  ];

  public YearsList: any[] = [];

  loader: boolean = false;
  loaderSubscription: any;
  paduStatusSubscription: any;
  roadMapValue: any;
  initiativeProgram: any;
  replacementApp: any;
  businessMaintained: any;
  alignmentNeeded: any;
  nonintegratedTech: any;
  comments: any;
  decomforcastQurtr: any;
  decomforcastyear: any;
  FuturePaduYears: any[] = [1, 2, 3, 4, 5, 6];
  searchValue: any;
  subject = new Subject<string>();
  destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  showLoader: boolean = false;
  currentQuarter: any;
  RoadMapEditButtonStatus: boolean = true;
  PaduEditButtonStatus: boolean = true;
  businessOwnedManual = {
    oldValue: null,
    newValue: null,
    isValueDifferent: false,
    dropdownClicked: false
  }

  public indexOfColumn = {
    'current': 'current',
    'transition0': 0,
    'transition1': 1,
    'transition2': 2,
    'transition3': 3,
    'transition4': 4,
    'transition5': 5
  }

  public columnToKey = {
    0: 'agg_paduT1_v1',
    1: 'agg_paduT2_v1',
    2: 'agg_paduT3_v1',
    3: 'agg_paduT4_v1',
    4: 'agg_paduT5_v1',
    5: 'agg_paduT6_v1'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toaster: ToastrService,
    private navService: SidenavService,
    public paduService: PADUUtilsService,
    public dialog: MatDialogRef<PaduEditModalComponent>,
    private service: AppViewService,
    public sharedService: SharedService,
    public apphubservice: AppHubService) {

    this.currentQuarter = this.paduService.getQuarter();
    this.loaderSubscription = this.paduService.loader.subscribe((data) => {
      this.loader = data;
    });

    this.paduStatusSubscription = this.paduService.paduStatus.subscribe((data) => {
      this.currentPADU = data.current;
      this.transition[0] = data.transition_1;
      this.transition[1] = data.transition_2;
      this.transition[2] = data.transition_3;
      this.transition[3] = data.transition_4;
      this.transition[4] = data.transition_5;
      this.transition[5] = data.transition_6;
      if (data.decom_forcast_by) {
        let forcast = data.decom_forcast_by.split(' ');
        if (forcast && forcast.length > 1 && forcast[0] && forcast[1]) {
          this.decomforcastyear = parseInt(forcast[1]);
          this.decomforcastQurtr = forcast[0];
        }
      }

    });

  }



  ngOnInit() {
    let currentYear = new Date().getUTCFullYear();
    this.RoadmapOptions = this.sortArray(this.RoadmapOptions, 'label');
    let option = { label: currentYear, value: currentYear };
    this.YearsList.push(option);
    this.FuturePaduYears.forEach((year) => {
      let options = { disable: false, label: currentYear + year, value: currentYear + year };
      this.YearsList.push(options);
    });


    this.paduData = this.data.paduData;
    this.paduEditColumns = this.data.paduEditColumns;
    this.paduStatusCol = this.paduEditColumns.map(element => {
      if (element.columnDef != 'Edit')
        return element.columnDef
    });
    this.paduStatusCol = this.paduStatusCol.filter((ele) => ele);
    this.selectedLOB = this.data.paduData[0].selectedLOB;
    this.selectedSUBLOB = this.data.paduData[0].selectedSUBLOB;

    this.currentPADU = this.paduData[0].current
    this.transition[0] = this.paduData[0].transition_1;
    this.transition[1] = this.paduData[0].transition_2;
    this.transition[2] = this.paduData[0].transition_3;
    this.transition[3] = this.paduData[0].transition_4;
    this.transition[4] = this.paduData[0].transition_5;
    this.transition[5] = this.paduData[0].transition_6;

    this.roadMapValue = this.paduData[0]['RoadMap'];
    // this.replacementApp = this.paduData[0]['Replacement_App'] ? this.paduData[0]['Replacement_App'] : '';
    this.businessMaintained = this.paduData[0]['Business_Owned'] ? this.paduData[0]['Business_Owned'] : '';
    this.alignmentNeeded = this.paduData[0]['Tech_Business_Alignment_Needed'] ? this.paduData[0]['Tech_Business_Alignment_Needed'] : '';
    this.nonintegratedTech = this.paduData[0]['Non_Integrated_Tech'] ? this.paduData[0]['Non_Integrated_Tech'] : '';
    this.comments = this.paduData[0]['Notes_Comments'];
    this.initiativeProgram = this.paduData[0]['Initiative_Program_Replacement'] ? this.paduData[0]['Initiative_Program_Replacement'] : '';
    this.decomforcastyear = '';
    this.decomforcastQurtr = '';
    if (this.paduData[0].decom_forcast_by) {
      let forcast = this.paduData[0].decom_forcast_by.split(' ');
      if (forcast && forcast.length > 1 && forcast[0] && forcast[1]) {
        this.decomforcastyear = parseInt(forcast[1]);
        this.decomforcastQurtr = forcast[0];
      }
    }
    if (this.paduData[0]['Replacement_App'].substring(0, 3) == 'AID')
    this.replacementApp = this.paduData[0]['Replacement_App'].substring(7, this.paduData[0]['Replacement_App'].length)
  else if (this.paduData[0]['Replacement_App'].substring(0, 3) == 'TOH')
    this.replacementApp = this.paduData[0]['Replacement_App'].substring(6, this.paduData[0]['Replacement_App'].length)
  else
    this.replacementApp = this.paduData[0]['Replacement_App'];

    if (this.replacementApp && this.replacementApp.toLowerCase() != 'no mapping') {
      this.navService.getPaginatedAppList(-1, this.replacementApp).subscribe(searchObj => {
        let results = searchObj.result;
        results = results.filter((ele) => ele.app_id != this.paduData[0]['app_id']);
        this.replacementOptions = results;
      })
    }

    this.subject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchText => {
        this.showLoader = true;
        return this.navService.getPaginatedAppList(-1, searchText)
      })
    ).pipe(takeUntil(this.destroy$))
      .subscribe(searchObj => {
        this.showLoader = false;
        let results = searchObj.result;
        results = results.filter((ele) => ele.app_id != this.paduData[0]['app_id']);
        this.replacementOptions = results;
      });

  }

  ngOnDestroy() {
    if (typeof this.loaderSubscription != 'undefined') {
      this.loaderSubscription.unsubscribe();
    }

  }

  closePopup() {
    this.dialog.close({ type: 'cancel' });
  }

  getClassName(value) {
    if (this.PaduClasess[value]) {
      return this.PaduClasess[value];
    }
    return '';
  }

  submitForm() {
    let payload = {
      'type': "update",
      'currentPADU': this.currentPADU,
      'transition': this.transition
    }
    this.onSave.emit(payload);
    // this.dialog.close(payload); // Disabled Close explicit
  }

  submitRoadmap() {

    if (this.decomforcastQurtr && !this.decomforcastyear) {
      this.toaster.error("Please select Decom forcast year");
      return
    }
    if (this.decomforcastyear && !this.decomforcastQurtr) {
      this.toaster.error("Please select Decom forcast Quarter");
      return
    }
    this.paduData[0]['RoadMap'] = this.roadMapValue && this.roadMapValue != 'No Roadmap' ? this.roadMapValue : null;
    this.paduData[0]['Replacement_App'] = this.replacementApp && this.replacementApp != 'No Mapping' ? this.replacementApp : null;
    this.paduData[0]['Initiative_Program_Replacement'] = this.initiativeProgram && this.initiativeProgram != 'No Mapping' ? this.initiativeProgram : null;
    if (this.businessOwnedManual.isValueDifferent && this.businessOwnedManual.dropdownClicked) {
      this.paduData[0]['Business_Owned'] = this.businessMaintained && this.businessMaintained != 'No Mapping' ? this.businessMaintained : null;
    }
    else {
      delete this.paduData[0]['Business_Owned']
    }
    this.paduData[0]['Tech_Business_Alignment_Needed'] = this.alignmentNeeded && this.alignmentNeeded != 'No Mapping' ? this.alignmentNeeded : null;
    this.paduData[0]['Non_Integrated_Tech'] = this.nonintegratedTech && this.nonintegratedTech != 'No Mapping' ? this.nonintegratedTech : null;
    this.paduData[0]['Notes_Comments'] = this.comments ? this.comments : null;
    this.paduData[0]['decom_forecasr_Qtr'] = this.decomforcastQurtr ? this.decomforcastQurtr : null;
    this.paduData[0]['decom_forcast_year'] = this.decomforcastyear ? this.decomforcastyear : null;
    this.onRoadMapSave.emit(this.paduData);
  }

  keyUpSearch(event) {
    
    let str=this.replacementApp;
    if(str.substring(0, 3) == 'AID')
    {
    str= str.substring(7, str.length)
    }
  else if(str.substring(0, 3) == 'TOH')
    {
    str= str.substring(6, str.length)
    }
    this.RoadMapEditButtonStatus= false;
    if (event.target.value.length >= 3 ) {
      // this.subject.next(event.target.value);
      this.apphubservice.getAppList(-1,event.target.value).subscribe((res: any) => {
        this.replacementOptions = res.result;
        this.replacementOptions = this.replacementOptions.filter((ele) => ele.app_id !=this.replacementApp);
        
      })
    }
        if(event.target.value===str)
        {
          this.RoadMapEditButtonStatus = true;
        }
      
    
  }

  onChangeQuarter(event) {
    let selectedQuarter = event.value;
    if (selectedQuarter) {
      let currentYear = new Date().getUTCFullYear();
      let qrtr = selectedQuarter.split('Q')[1];
      if (qrtr < this.currentQuarter) {
        if (this.decomforcastyear == currentYear) {
          this.decomforcastyear = '';
        }
        this.YearsList = this.YearsList.map((ele) => {
          if (ele.value == currentYear) {
            ele.disable = true;
          }
          return ele;
        });
      } else {
        this.YearsList = this.YearsList.map((ele) => {
          ele.disable = false;
          return ele;
        });
      }
    }
  }

  modelChanged(event, column, fieldName?) {
    if (event != column || column == '') {
      this.RoadMapEditButtonStatus = false;
    }

    if (fieldName && fieldName === 'businessMaintained') {
      this.businessOwnedManual.newValue = event;
      this.businessOwnedManual.oldValue = column;
      this.businessOwnedManual.isValueDifferent = this.businessOwnedManual.newValue !== this.businessOwnedManual.oldValue;
      this.businessOwnedManual.dropdownClicked = true;
    }
    return;
  }

  modelChangedPadu(event, column) {
    if (event != column || column == '') {
      this.PaduEditButtonStatus = false;
    }

    return;
  }


  sortArray(arr, sortByName) {

    arr.sort((a, b) => {
      const nameA = a[sortByName].toUpperCase(); // ignore upper and lowercase
      const nameB = b[sortByName].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return arr;
  }
  valueChange(event, column) {

    let countTBR = this.transition.filter(d => d == 'TBR').length;
    if (this.currentPADU === 'TBR') {
      countTBR++;
    }
    if (countTBR > 1) {

      this.loader = true;
      this.service.getPADUWithoutToBeRetired(this.paduData[0].app_id).subscribe(d => {
        if (d.length) {

          if (this.indexOfColumn[column] === 'current') {
            this.currentPADU = 'TBR';
            this.transition = this.transition.map(d =>  d == 'R');
          }
          else {
            for (let x = 0; x < this.indexOfColumn[column]; x++) {
              this.transition[x] = this.PADUShortName[d[0][this.columnToKey[x]]]
            }
            for (let x = this.indexOfColumn[column] + 1; x <= 5; x++) {
              this.transition[x] = 'R'
            }
            this.currentPADU = this.PADUShortName[d[0]['agg_padu_v1']]
          }
        }

        this.loader = false;
      }, err => {
        console.log(err);
        this.loader = false;
      })
    }

  }
 
}
