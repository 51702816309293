import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppHubService } from 'src/app/techopsv4/services/appHubV4Service';
import { ConstantsData } from 'src/app/constants/fields';
import { appModernizationDetails} from '../../../../config';
import { BehaviorSubject, ReplaySubject, Subject, zip } from 'rxjs';
import { AppHubUtilService} from 'src/app/shared';
import { FilterSelectionService } from 'src/app/shared/services/filter-selection.service';
import { TreeCheckboxComponent } from 'src/app/shared/elements/tree-checkbox/tree-checkbox.component';
import { WorkQueuesService } from 'src/app/techopsv4/services/workQueuesService';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DailogModalComponent } from 'src/app/shared/elements/dailog-modal/dailog-modal.component';
import { SharedAuthService } from 'src/app/shared/shared.auth.service';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

export interface capProposal {
  organisation: any;
  current: string;
  transition_1: string;
  transition_2: string;
  transition_3: string;
  transition_4: string;
  transition_5: string;
  transition_6: string;
  isEdit: boolean;
  oldOrganisationData: any;
}

@Component({
  selector: 'app-capability-proposal',
  templateUrl: './capability-proposal.component.html',
  styleUrl: './capability-proposal.component.css',
})


export class CapabilityProposalComponent implements OnInit,OnChanges,OnDestroy {
  config = appModernizationDetails;
  selectedApplicationId: string = '';
  selectedApplicationName: string = ''
  selectedModel: string = '';
  applicationSearch: string;
  primary: string = '';
  @Input('selectedWorqueue') selectedWorqueue: any;
  @Output('closePopup') closePopup = new EventEmitter();
  @Output('showOpenRequest') showOpenRequest = new EventEmitter();
  isEdit: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  PADUFullName = ConstantsData.PADUFullName;
  defaultPaduData = {}
  displayedColumns: string[] = ['organisation', 'current', 'transition_1', 'transition_2', 'transition_3', 'transition_4', 'transition_5', 'transition_6','delete']
  currentYear = new Date().getUTCFullYear();
  paduOptions: Array<any> = []
  deletedRow:Array<any> = [];
  orgList: Array<any> = []
  organisationList: Array<any> = []
  term: string = ''
  capability: string = ''
  capabilityAssociation: string = 'add';
  groupModelArr: Array<string> = [];
  groupModelArrForEdit: Array<string> = [];
  TreeCheckboxDataSource = {};
  selectedCapability1: string = '';
  selectedCapHierarchy: any;
  mappedCapability: any = [];
  capabilityHierarchyList: any;
  organisationDataForReset:any;
  ModelCaps: any;
  tableData = [];
  switchMapData: any;
  tapData: any;
  destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchCapability: any;
  openRequest:Array<any> = [];
  OpenRequestArr: Array<any> = [];
  selectedApplication:any = '';
  userAccessData:any;
  @ViewChild(TreeCheckboxComponent, { static: false }) treeCapability: TreeCheckboxComponent;
  @ViewChild('searchInput') searchInput:ElementRef;
  isAdmin:any;
  subLobId:any;
  excludeOrg:Array<string> = [];
  isOpenWQRequest:boolean = false;
  originalCapabilityHierarchyList = [];
  isLoader:boolean = false;
  subject = new Subject<string>();
  appListDefault:Array<any> = [];
  appList:Array<any> = [];
  @Input() fromAssociateCapabilities = false;
  @Input() selectedApp:any;
  @Input() isEditWorkflow;
  @Input() capabilityData:any;
  justification: string = '';
  initialCount = 0;
  isAllDataLoaded:boolean = false;
  counter:BehaviorSubject<number> = new BehaviorSubject(this.initialCount);
  editablePaduValues = ['Preferred', 'Acceptable', 'Discouraged', 'Unacceptable', 'Retired'];
  isInitialLoading:boolean = false;
  @Output() updateSubLobDataAndAccess = new EventEmitter();
  
  public currentLobDetails = {
    'currentSubLob' : '',
    'currentSubLobId' : '',
    'currentLob' : '',
    'currentLobId' : ''
  }

  constructor(private appHubService: AppHubService, private appUtil: AppHubUtilService, private filterService: FilterSelectionService, private workQueuesService: WorkQueuesService, private toaster: ToastrService, private dialog:MatDialog, private sharedAuthService:SharedAuthService) { }
  ngOnDestroy(): void {
    this.subject.unsubscribe();
    this.resetFormValues('');
  }

  ngOnInit(): void {
    this.getInitialData();
    if(!this.fromAssociateCapabilities){
      this.subject.pipe(
        debounceTime(500),
        switchMap(() => {
          return this.appHubService.getAppListForEdit(0, this.applicationSearch)
        })
      ).pipe(takeUntil(this.destroy$))
        .subscribe(searchObj => {
          this.appList = searchObj.result;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetFormValues('');
    this.isLoader = true;
    this.userAccessData = JSON.parse(localStorage.getItem('accessData'));
    this.isAdmin = JSON.parse(localStorage.getItem('isAdminRole'));
    this.subLobId = this.userAccessData.subLobId;
    let search = this.selectedApp ? this.selectedApp.app_id : 'UHC';
    const appData = this.getInitialApplicationList(search);
    appData.subscribe(data => {
      this.appListDefault = data.result;
      this.appList = data.result;
      if(this.fromAssociateCapabilities){
        this.isInitialLoading = true;
        if(this.selectedApp && this.selectedApp.app_id){
          this.selectedApplication = this.appList[0].app_id + '|' + this.appList[0].app_name;

          let ev = {
            value : this.selectedApplication
          }
          this.getSelectedApplication(ev)
        }
      }
      
    });
  }

  getInitialApplicationList(search){
    return this.appHubService.getAppListForEdit(0, search)
  }

  getInitialData() { 
    let initialData = this.workQueuesService.getInitialDataForCapabilityProposal();
    if (initialData) {
      this.SetInitialData(initialData);
    } else {
      const model = this.appHubService.getGroupmodelV1();
      const capabilityModel = this.appHubService.getCapabilityHierarchy();
      const org = this.appHubService.getOrganizationList();
      zip(model, capabilityModel, org).subscribe(res => {
        this.workQueuesService.setInitialDataForCapabilityProposal(res);
        this.SetInitialData(res)
        return true;
      })
    }
  }

  SetInitialData(res){
    this.groupModelArr = res[0];
    this.capabilityHierarchyList = res[1];
    this.originalCapabilityHierarchyList = res[1];
    this.initialCount = this.initialCount + 1;
    this.counter.next(this.initialCount)
    if (res[2] && res[2].length > 0) {
      this.organisationList = res[2];
      this.orgList = res[2];
    }
    this.isLoader = false;
  }

  getorganisationDetail(orgId) {
    let filter = this.organisationList.filter((item) => {
      return (item.org_id == orgId);
    });
    if (filter) {
      return filter[0];
    }
  }

  onCapabilityTypeChange(isresetRequired:boolean = true){
    if(isresetRequired)
      this.resetFormValues('');
    this.isAllDataLoaded = false;
    this.justification = '';
    if(this.capabilityAssociation  == 'add'){
      this.isEdit = false;
    }
    else{
      this.isEdit = true;
    }
  }

  resetFormValues(type){
    this.selectedModel = '';
    this.capability = '';
    this.selectedCapability1 = '';
    this.dataSource.data = [];
    this.openRequest = [];
    this.OpenRequestArr = [];
    this.excludeOrg = [];
    this.deletedRow = [];
    this.groupModelArrForEdit = [];
    this.justification = '';
    if(type != 'Application'){
      this.selectedApplication = '';
      this.applicationSearch = '';
      let event = {
        target:{
        value:''
        }
      };
      this.searchApplicationData(event);
    }
    this.updateOpenWorkRequestLink(false,[]);
  }

  async getSelectedApplication(ev) {
    this.resetFormValues('Application');
    this.tableData = [];
    let appValue = ev.value.split('|');
    this.selectedApplicationId = appValue[0];
    this.selectedApplicationName = appValue[1];
    this.isLoader = true;
    const capabilityRedundency$ = await this.appHubService.getCapabilityRedundency(this.selectedApplicationId);
    const wqOpenReq = await this.workQueuesService.getAssociatedCapabilityOpenRequest({'Application_Id': this.selectedApplicationId});
    const appData = await this.appHubService.Mastercarddetails(this.selectedApplicationId);
    const appPaduData = await this.appHubService.fetchAppPaduAndRoadmap(this.selectedApplicationId);
    this.getCapabilityDetailsForAnApplication(capabilityRedundency$);//get current application existing capabilities
    this.getAssociatedCapabilityForWQwithOpenRequest(wqOpenReq); //get current open Work queue request
    appData.subscribe((data: any)=>{
      this.currentLobDetails.currentSubLobId = data.SubLOB_ID;
      this.currentLobDetails.currentLobId = data.LOB_ID;
      const isUserHasAccess = this.checkAccess(this.currentLobDetails.currentSubLobId);
      this.updateSubLobDataAndAccess.emit({access:isUserHasAccess,subLobID: this.currentLobDetails.currentSubLobId})
    })
    appPaduData.subscribe(res => {
      this.defaultPaduData = res['app_id'] ? res['lowest_padu'] : this.defaultPaduData;
      this.checkFuturePaduVisibility(res);
    })
    if(this.isEditWorkflow){
      this.capabilityAssociation = 'edit';
      // this.onCapabilityTypeChange(false);
      this.isEdit = true;
      if(this.capabilityData){
        this.organisationDataForReset = [];
        this.selectedModel = this.capabilityData.group_model;
        
      }
    }
    this.counter.subscribe(res=>{
      if(res == 3){
        this.isAllDataLoaded = true;
        if(this.isEditWorkflow && this.capabilityData){
          this.getNewMappedCapabilityList();
          this.selectedCapability1 = this.capabilityData.selectedCapability;
          this.showWQOpenRequestHyperlink(this.selectedCapability1);
          this.selectedCapHierarchy = {
            capabilityL0: this.capabilityData.capabilityL0,
            capabilityL1: this.capabilityData.capabilityL1,
            capabilityL2: this.capabilityData.capabilityL2,
            capabilityL3: this.capabilityData.capabilityL3,
          }
        }
      }
    })
    
    this.isLoader = false;
  }

  resetInputValueForApplicationList(){
    let blurData;
    if(this.applicationSearch) {
      blurData = this.appList.filter(x =>{
        if(x.app_id.toString().toLowerCase().includes(this.applicationSearch.toLowerCase()) || x.app_name.toString().toLowerCase().includes(this.applicationSearch.toLowerCase())){
          return x
        }
      })
    }
    if (blurData && blurData.length < 1) {
      this.applicationSearch = "";
      if(this.selectedApplicationId && this.selectedApplicationName){
        const search = this.selectedApplicationName.substring(0,4);
        const appData = this.getInitialApplicationList(search);
        appData.subscribe((data)=>{
          this.appList = data.result;
          this.selectedApplication = this.selectedApplicationId + '|' + this.selectedApplicationName;
        })
      }else{
        this.appList = this.appListDefault;
      }
    }
  }

  searchApplicationData(event) {
    if (event.target.value.length >= 3) {
      // this.selectedApplication = event.target.value
      this.subject.next(event.target.value);
    }
    else if (event.target.value.length == 0){
      this.appList = this.appListDefault;
    }
  }

  getAssociatedCapabilityForWQwithOpenRequest(result) {
    result.subscribe((result: any) => {
      this.openRequest = [];
      this.OpenRequestArr = [];
      if(result && result.length > 0){
        result.forEach(element => {
          if(element.WQID == this.selectedWorqueue['Id']){
            // let index = null;
            // if(element.capabilityL3Id){
            //   index = 3;
            // }
            // else if (element.capabilityL2Id){
            //   index = 2;
            // }
            // else if (element.capabilityL1Id){
            //   index = 1;
            // }
            // else if (element.capabilityL0Id){
            //   index = 0;
            // }
            if (element.selectedCapability ) {
              let capabilityName = element.selectedCapability;

              if (capabilityName){
                this.openRequest.push(capabilityName);
                this.groupModelArrForEdit.push(element.group_model);
                this.OpenRequestArr.push(element);
              }
            }
          }
        });
        this.groupModelArrForEdit = [...new Set(this.groupModelArrForEdit)]
        this.groupModelArrForEdit = this.groupModelArrForEdit.sort();
      }
      this.initialCount = this.initialCount + 1;
      this.counter.next(this.initialCount);
    })
  }


  resetsearch(event) {
    if (event == false) {
      this.term = '';
      this.orgList = this.organisationList;
    }
  }
  

  getCapabilityDetailsForAnApplication(capabilityRedundency$) {
    capabilityRedundency$.subscribe(res => {
      this.tableData = res;
      this.initialCount = this.initialCount + 1;
      this.counter.next(this.initialCount);
      if (this.tableData.length == 0) {
        this.primary = 'Yes';
      } else
        this.primary = 'No';
      if(this.isEdit){
        this.tableData.forEach((val)=>{
          this.groupModelArrForEdit.push(val.group_model)
        })
        this.groupModelArrForEdit = [...new Set(this.groupModelArrForEdit)]
        this.groupModelArrForEdit = this.groupModelArrForEdit.sort();
      }
    },
      error => {
        console.log("error", error)
      })
  }

  onCapabilitySelectDropdown() {
    if(this.searchInput)
      this.searchInput.nativeElement.focus();
  }


  reset() {
    this.deletedRow = [];
    this.dataSource.data = [];
    this.excludeOrg = [];
    this.organisationDataForReset.forEach((val) => {
      this.addOrganisationRow(val.organisations)
    });
  }

  searchOrganizationByName(event) {
    let searchOrganization = event.target.value;
    if (searchOrganization) {
      this.orgList = this.organisationList.filter(item => {
        return item.org_name.toString().toLowerCase().indexOf(searchOrganization.toLowerCase()) > -1
      });
    } else {
      return this.orgList = this.organisationList;
    }
  }
  resetValue() {
    this.term = "";
  }

  addOrganisationRow(editValues:any = null) {
    if(editValues && editValues.length > 0){
      editValues.forEach((val)=>{
        val.select = true;
        val.org = {
          org_id:val.org_id,
          org_name : val.organisation
        }
        val.isEdit = true;
        this.addRow(val)
        this.excludeOrg.push(val.org_id);
      })
    }
    else if (this.defaultPaduData && Object.keys(this.defaultPaduData).length > 0) {
      let paduValues = {};
      Object.keys(this.defaultPaduData).forEach(key => {
        if (this.PADUFullName[this.defaultPaduData[key]] == 'To Be Retired') {
          paduValues[key] = 'Retired';
        } else {
          paduValues[key] = this.PADUFullName[this.defaultPaduData[key]];
        }
      })
      this.addRow(paduValues)
    }
  }

  addRow(paduValues){
    let res = {
      organisation: paduValues.org_id ? paduValues.org_id : '',
      current: paduValues['current'],
      transition_1: paduValues['transition_1'],
      transition_2: paduValues['transition_2'],
      transition_3: paduValues['transition_3'],
      transition_4: paduValues['transition_4'],
      transition_5: paduValues['transition_5'],
      transition_6: paduValues['transition_6'],
      delete: '',
      select: paduValues.select ? true : false,
      isEdit: paduValues.isEdit ? true : false,
      oldOrganisationData : paduValues
    }
    this.dataSource.data.push(res);
    this.dataSource = new MatTableDataSource(this.dataSource.data);
  }

  getselectedOrganisation(row,ev){
    if(ev.value){
      this.excludeOrg = []
      this.dataSource.data.forEach((item) => {
        this.excludeOrg.push(item.organisation);
      })
    }
    this.checkrow(row)
  }

  checkrow(row) {
    if(this.isEdit){
      if(row.organisation){
        row.isEdit = this.checkIsOriginalData(row,false);
      }
    }
  }

  checkFuturePaduVisibility(res) {
    this.paduOptions = [{ label: 'Preferred', value: 'Preferred' },
    { label: 'Acceptable', value: 'Acceptable' },
    { label: 'Discouraged', value: 'Discouraged' },
    { label: 'Unacceptable', value: 'Unacceptable' },
    { label: 'Retired', value: 'Retired' }
    ];
    this.editablePaduValues = ['Preferred', 'Acceptable', 'Discouraged', 'Unacceptable', 'Retired'];
    if (res && res['AppStrategy'] && ['Planned Enterprise Solution', 'Planned LOB Strategic Solution'].includes(res['AppStrategy'])) {
      this.paduOptions.push({ label: 'Future', value: 'Future' });
      this.editablePaduValues.push('Future');
    }
  }

  checkexist(org) {
    if (this.excludeOrg.includes(org)) {
      return true;
    } else {
      return false;
    }
  }

  showWQOpenRequestHyperlink(capabilityName){
    const filterData = this.OpenRequestArr.filter((val)=>{
      if(val.capabilityL3 == capabilityName)
        return val
      else if(val.capabilityL2 == capabilityName && this.checkHierarchyExist(2,3,val))
        return val
      else if(val.capabilityL1 == capabilityName && this.checkHierarchyExist(1,3,val))
        return val
      else if(val.capabilityL0 == capabilityName && this.checkHierarchyExist(0,3,val))
        return val
    })
    if(filterData && filterData.length > 0){
      this.isOpenWQRequest = true;
      this.navigateToRequestDetails(filterData);
      this.updateOpenWorkRequestLink(true,filterData);
    }else{
      this.isInitialLoading = false;
    }
  }

  checkHierarchyExist(index,maxIndex,val){
    let isExist:boolean = true;
    for(let i=index;i<maxIndex; i++){
      if(val['capabilityL' + (index + 1)] != null){
        isExist = false;
      }
    }
    return isExist;
  }

  updateOpenWorkRequestLink(openRequest:boolean , openWorkRequestArr:Array<any>){
    this.navigateToRequestDetails(openWorkRequestArr);
    this.isOpenWQRequest = openRequest;
  }


  getSelectedCapbilityFromDropdown(event) {
    let ev = {
      target:{
        value:''
      }
    };
    this.searchCapabilityByName(ev);//calling to get the search list again
    this.updateOpenWorkRequestLink(false,[]);
    this.dataSource.data = [];
    this.selectedCapHierarchy = event;
    let index = null;
    let capabilityList = [];
    if (event.capabilityL3 !== null) {
      this.selectedCapability1 = event.capabilityL3;
      index = 3;
    } else if (event.capabilityL2 !== null) {
      this.selectedCapability1 = event.capabilityL2;
      index = 2;
    } else if (event.capabilityL1 !== null) {
      this.selectedCapability1 = event.capabilityL1;
      index = 1;
    } else {
      this.selectedCapability1 = event.capabilityL0;
      index = 0;
    }
    this.treeCapability.filterChanged('');
    if(this.isEdit){
      this.organisationDataForReset = [];
      capabilityList = this.capabilityHierarchyList.filter(element => (element['capabilityL' + index] == this.selectedCapability1 && (index < 4 && element['capabilityL' + (index +1)] == null)));
      if (capabilityList && capabilityList.length > 0){
        this.organisationDataForReset = capabilityList;
        capabilityList.forEach((val) => {
          this.addOrganisationRow(val.organisations)
        });
      }
    }
  }

  async getNewMappedCapabilityList() {
    this.excludeOrg = [];
    this.updateOpenWorkRequestLink(false,[]);
    this.capabilityHierarchyList = this.originalCapabilityHierarchyList;
    this.TreeCheckboxDataSource = this.appUtil.tree(this.capabilityHierarchyList, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME']);
    this.selectedCapability1 = '';
    this.capability = '';
    this.dataSource.data = [];
    if (this.tableData) {
      if(this.isEdit){
        this.tableData = this.OpenRequestArr.concat(this.tableData);
      }
      this.selectedCapability1 = '';
      const filterCapability = this.tableData.filter(d => {
        if (d.group_model === this.selectedModel) {
          return d.capability;
        }
      });
      if(this.isEdit){
        this.TreeCheckboxDataSource = this.appUtil.tree(filterCapability, ['capabilityL0', 'capabilityL1', 'capabilityL2', 'capabilityL3']);
        this.capabilityHierarchyList = filterCapability;
        this.ModelCaps = filterCapability;
        this.mappedCapability = filterCapability.map(d => d.selectedCapability + '^' + d.selectedCapabilityID);
        this.TreeCheckboxDataSource = this.appUtil.replaceNull(
          this.appUtil.sanitize(
            this.appUtil.tree(filterCapability, ['capabilityL0', 'capabilityL1', 'capabilityL2', 'capabilityL3'])
          ),
          null
        );
      }else{
        this.mappedCapability = filterCapability.map(d => d.selectedCapability + '^' + d.selectedCapabilityID);
        const capHier = this.selectedModel
          ? this.capabilityHierarchyList.filter(d => d.group_model === this.selectedModel)
          : this.capabilityHierarchyList;
        this.ModelCaps = capHier;
        this.TreeCheckboxDataSource = this.appUtil.replaceNull(
          this.appUtil.sanitize(
            this.appUtil.tree(capHier, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME'])
          ),
          null
        );
      }

      if (this.TreeCheckboxDataSource)
        this.appHubService.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);

      if(this.isEditWorkflow && this.capabilityData){ //setting data on edit(coming from App Hub)
        this.addOrganisationRow(this.capabilityData.organisations);
        this.organisationDataForReset.push(this.capabilityData);
      }
    }
  }

  multipleList(ev) {
    console.log("multiple-list", ev)
  }

  searchCapabilityByName(event) {
    this.searchCapability = event.target.value;
    if (this.searchCapability) {
      let filteredTreeData = this.ModelCaps.filter((d) => {
        return d.capabilityL0.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1 ||
          d.capabilityL1?.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1 ||
          d.capabilityL2?.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1 ||
          d.capabilityL3?.toLocaleLowerCase().indexOf(this.searchCapability.toLocaleLowerCase()) > -1
      });
      Object.assign([], filteredTreeData).forEach(ftd => {
        let str = (<string>ftd.group_model);
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex((t) => {
            return t.capabilityL0.toLocaleLowerCase() ||
              t.capabilityL1?.toLocaleLowerCase() ||
              t.capabilityL2?.toLocaleLowerCase() ||
              t.capabilityL3?.toLocaleLowerCase()
          }) === -1) {
            const obj = this.ModelCaps.find((t) => {
              return t.capabilityL0.toLocaleLowerCase() ||
                t.capabilityL1?.toLocaleLowerCase() ||
                t.capabilityL2?.toLocaleLowerCase() ||
                t.capabilityL3?.toLocaleLowerCase()
            });
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });
      if(this.isEdit){
        this.TreeCheckboxDataSource = this.appUtil.replaceNull(
          this.appUtil.sanitize(
            this.appUtil.tree(filteredTreeData, ['capabilityL0', 'capabilityL1', 'capabilityL2', 'capabilityL3'])
          ),
          null
        );
      }else{
        this.TreeCheckboxDataSource = this.appUtil.replaceNull(
          this.appUtil.sanitize(
            this.appUtil.tree(filteredTreeData, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME'])
          ),
          null
        );
      }
      
      if (this.TreeCheckboxDataSource)
        this.appHubService.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
      this.treeCapability.filterChanged(this.searchCapability);
    } else {
      this.selectedCapability1 = '';
      const filterCapability = this.tableData.filter(d => {
        if (d.group_model === this.selectedModel) {
          return d.capability;
        }
      });

      this.mappedCapability = filterCapability.map(d => d.selectedCapability + '^' + d.selectedCapabilityID);
      const capHier = this.selectedModel
        ? this.capabilityHierarchyList.filter(d => d.group_model === this.selectedModel)
        : this.capabilityHierarchyList;

      if (this.isEdit) {
        this.TreeCheckboxDataSource = this.appUtil.replaceNull(
          this.appUtil.sanitize(
            this.appUtil.tree(capHier, ['capabilityL0', 'capabilityL1', 'capabilityL2', 'capabilityL3'])
          ),
          null
        );
      } else {
        this.TreeCheckboxDataSource = this.appUtil.replaceNull(
          this.appUtil.sanitize(
            this.appUtil.tree(capHier, ['capabilityL0_NAME', 'capabilityL1_NAME', 'capabilityL2_NAME', 'capabilityL3_NAME'])
          ),
          null
        );
      }
      this.treeCapability.filterChanged('');
      if (this.TreeCheckboxDataSource)
        this.appHubService.setTreeStructureDropdownValues(this.TreeCheckboxDataSource);
    }

  }

  submitRequest() {
    if(this.validateData()){
        const body = this.getPayload();
        let subTitliMsg = ''
        if(this.isEdit)
         subTitliMsg = '<p class="dialog-text text-left">PADU Update - ' + body['update'].length + ' Organisation (' + body['update'].length +' Request)' +'<br/><br/>' + 'Organisation(s) added - ' + body['insert'].length + ' (' + body['insert'].length +' Request)'+ '<br/><br/>' + 'Organisation(s) Removed - ' + body['dlt'].length + ' (' + body['dlt'].length +' Request)</p>'
        else
         subTitliMsg = '<p class="dialog-text">Organisation(s) added - ' + body['insert'].length  + ' ('+ body['insert'].length + 'Request)</p>'+ '<br/>' + '<p class="dialog-message">' + body['insert'].length + ' workqueue requests will be generated.'
        
        subTitliMsg.replace('\n/g', '<br/>');
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = 'auto !important';
        dialogConfig.hasBackdrop = true;
        dialogConfig.minHeight = '200px';
        dialogConfig.height = 'auto';
        dialogConfig.autoFocus=false;
        dialogConfig.panelClass = "capability-dialog";
        dialogConfig.maxWidth = '600px !important';
        dialogConfig.data = {
          subTitle: subTitliMsg,
          buttonName: 'Continue'
        };
        if(this.isEdit){
          dialogConfig.data.title = 'Update Confirmation!';
        }
        const dialogRef = this.dialog.open(DailogModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.data === 'save') {
            this.workQueuesService.addUpdateCapabilityWorkqueue(body).subscribe((result: any) => {
              if(result){
                this.toaster.success("Request is generated successfully.", '', { timeOut: 5000, positionClass: 'toast-top-center' });
                let ev = {
                  'message': 'data inserted successfully'
                }
                this.closePopup.emit(ev)
              }else{
                this.toaster.error("Something went wrong.", '', { timeOut: 5000, positionClass: 'toast-top-center' });
              }
            },
            error=>{
              this.toaster.error("Internal Server Error.", '', { timeOut: 5000, positionClass: 'toast-top-center' });
            })
          }
        });
    }
  }

  getCapabilityIds(d) {
    let capabilitiesId: any = {}
    this.capabilityHierarchyList.filter((f => {
      if (d.capabilityL0 === f.capabilityL0 && d.capabilityL1 === f.capabilityL1 && d.capabilityL2 === f.capabilityL2 && d.capabilityL3 === f.capabilityL3 && f.group_model === this.selectedModel) {
        capabilitiesId = f;
      }
    }))
    return capabilitiesId
  }

  validateData(){
    if (this.dataSource.data.length == 0 && !this.isEdit) {
      this.toaster.error('Please Add Organization with the selected capability.','', { timeOut: 5000, positionClass: 'toast-top-center' });
      return false;
    }
    const checkorg = obj => obj.organisation === '';
    if (this.dataSource.data.some(checkorg)) {
      this.toaster.error('Please Select Organization!','',{ timeOut: 5000, positionClass: 'toast-top-center' });
      return false;
    }
    return true;
  }

  getPayload() {
    
    const updateAccess = this.checkCapabilityUpdateAccess();
    const obj = {};
    const capabilitiesId = this.getCapabilityIds(this.selectedCapHierarchy)
    let deletedArr = [];
    let updatedArr = [];
    let insertedArr = [];
    const orgArr = [];
    this.dataSource.data.forEach((item,index) => {
      const orgDetail = this.getorganisationDetail(item.organisation)
      const params = {
        item: item,
        index:index,
        capabilitiesId: capabilitiesId,
        updateAccess: updateAccess,
        orgDetail: orgDetail,
        currentPadu: '',
        proposedPadu: ''
      }
      let isUpdated:boolean = false;
      let isAdd:boolean = true;
      if(this.isEdit){
        let isOldData = this.checkIsOriginalData(item);
        if(isOldData){
          if(item.isEdit){
            isAdd = false;
            orgArr.push(item.organisation);
          }
        }else{
          if(item.isEdit){
            isUpdated = true;
            orgArr.push(item.organisation);
          }
        }
      }
      if(isUpdated){
        params.proposedPadu = this.getPADUString(item);
        params.currentPadu = this.getPADUString(item.oldOrganisationData)
        updatedArr.push(this.getPayloadObject(params));
      }else if(isAdd){
        params.proposedPadu = '';
        params.currentPadu = this.getPADUString(item)
        insertedArr.push(this.getPayloadObject(params));
      }
    })
    if(this.deletedRow && this.deletedRow.length > 0){
      const arrayUniqueByKey = [...new Map(this.deletedRow.map(item =>
        [item['organisation'], item])).values()];
      this.deletedRow = arrayUniqueByKey;
      this.deletedRow.forEach((val)=>{
        if(orgArr.indexOf(val.organisation) == -1){
          const orgDetail = this.getorganisationDetail(val.organisation)
        const currentPadu = val.current + ',' + val.transition_1 + ',' + val.transition_2 + ',' + val.transition_3 + ',' + val.transition_4 + ',' + val.transition_5 + ',' + val.transition_6
     
        const params = {
          item: val,
          index:null,
          capabilitiesId: capabilitiesId,
          updateAccess: updateAccess,
          orgDetail: orgDetail,
          currentPadu: currentPadu,
          proposedPadu: ''
        }
        deletedArr.push(this.getPayloadObject(params))
        }
      })
    }
    obj['update'] = updatedArr;
    obj['insert'] = insertedArr;
    obj['dlt'] = deletedArr;
    return obj
  }

  getPADUString(item){
      return item.current + ',' + item.transition_1 + ',' + item.transition_2 + ',' + item.transition_3 + ',' + item.transition_4 + ',' + item.transition_5 + ',' + item.transition_6
  }

  getPayloadObject(obj){
    const item = obj.item;
    const index = obj.index;
    
    return {
      "organisation": obj.orgDetail ? obj.orgDetail.org_name : null,
      "Current_PADU": obj.currentPadu,
      "Proposed_PADU": obj.proposedPadu,
      "Application_Id": this.selectedApplicationId,
      "application": this.selectedApplicationName,
      "Organization_ID": item.organisation ? item.organisation : null,
      "Capability_L0_Id": obj.capabilitiesId['capabilityL0Id'],
      "Capability_L1_Id": obj.capabilitiesId['capabilityL1Id'],
      "Capability_L2_Id": obj.capabilitiesId['capabilityL2Id'],
      "Capability_L3_Id": obj.capabilitiesId['capabilityL3Id'],
      "Group_Model": this.selectedModel,
      "Primary_Status": (this.primary == 'Yes' && index == 0) ? this.primary : 'No',
      "created_by_Id": localStorage.getItem('MsId') || sessionStorage.getItem('MsId'),
      "created_by": JSON.parse(localStorage.getItem('currentUser')) || sessionStorage.getItem('currentUser'),
      "created_at": new Date().toISOString(),
      "Req_Justification": this.justification,
      "Req_Status": obj.updateAccess ? "Approved" : "Open",
      "Req_generated_From": "TechOpsUI",
      "WQID": this.selectedWorqueue['Id'],
      "User_Role": this.userAccessData.Role,
      "Approval_From": obj.updateAccess ? "Auto":"Source",
      "Ap_OR_R_Justification": obj.updateAccess ? 'Auto Approved as the requestor is ' + (this.userAccessData.Role == 'Admin' ? 'Admin' : 'LOB Architect.') : ""
    }
  }

  checkIsOriginalData(el,checkAllData:boolean = true){
    const oldOrganisationData = [];
    this.organisationDataForReset.forEach(val=>{
      val.organisations.forEach(element => {
        oldOrganisationData.push(element);
      });
    });
    let isExist = false;
    oldOrganisationData.forEach((val)=>{
      if(checkAllData){
        if(val.org_id == el.organisation && val.current == el.current && val.transition_1 == el.transition_1 && val.transition_2 == el.transition_2 && val.transition_3 == el.transition_3 && val.transition_4 == el.transition_4 && val.transition_5 == el.transition_5 && val.transition_6 == el.transition_6){
          isExist = true;
        }
      }else{
        if(val.org_id == el.organisation){
          isExist = true;
        }
      }
    })
    return isExist;
  }

  checkCapabilityUpdateAccess(){
    if (this.selectedWorqueue && this.selectedWorqueue['Create_Req_Roles'].split(',').includes(this.sharedAuthService.getAuthorizationForEA()) == true){
      if (this.userAccessData.Role == "Admin" && this.isAdmin){
        return true;
      }
      else{
        return (this.subLobId.includes(this.currentLobDetails.currentSubLobId) == true) ? true : false;
      }
    }
    return false;
  }

  navigateToRequestDetails(arr){
    this.showOpenRequest.emit(arr);
  }

  deleteCap(row){
    // this.selection.select(row)
    const orgIndex = this.excludeOrg.indexOf(row.organisation);
    this.excludeOrg.splice(orgIndex, 1);
    row.createdById = localStorage.getItem('MsId'),
    row.createdByName = JSON.parse(localStorage.getItem('currentUser'));
    if(row.isEdit)
      this.deletedRow.push(row);
    let index = this.dataSource.data.indexOf(row);
    this.dataSource.data.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.dataSource.data);
  }

  isDataUpdated(){
    let isBtnDisabled = false;
    const checkorg = obj => obj.organisation === '';
    if((this.dataSource &&(this.dataSource.data.length == 0 && !this.isEdit))|| this.justification == '' || this.isOpenWQRequest){
      isBtnDisabled = true;
    }else if(this.dataSource.data.some(checkorg)){
      isBtnDisabled = true;
    }
    else{
      const body = this.getPayload();
      if(body['dlt'].length == 0 && body['insert'].length == 0 && body['update'].length == 0){
        isBtnDisabled = true;
      }
    } 
    return isBtnDisabled;
  }

  checkAccess(subLobId){
    let userHasAccess:boolean = false;
    const isAdminRole = localStorage.getItem('isAdminRole');
    const accessData = JSON.parse(localStorage.getItem('accessData'));
    if(isAdminRole == 'true' && accessData && accessData.Role == 'Admin'){
      userHasAccess = true;
    }else{
      if(accessData && accessData.subLobId && accessData.subLobId.length > 0){
        const subLobArr = accessData.subLobId.toString().toLowerCase().split(',');
        if(subLobArr.indexOf(subLobId.toLowerCase()) != -1)
          userHasAccess = true;
      }
    }
    return userHasAccess;
  }

}
