<div class="landing-page-container">
  <div class="row" style="padding-top: 2%;">
    <ng-container *ngFor="let tile of tiles">
      <ng-container *ngIf="tile.Visibility=='True'">
        <div *ngIf="tile.Tile=='Optimization' && optimizationAccess;"
          class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure ">
          <div class="card-container card-container-height tcm-app-color" (click)="redirectView(tile.Tile)">
            <img class="landingPage-image" src="{{tile.icon}}"
              [ngStyle]="(tile.Tile=='Reclassification Portal')? {'width':'auto'}:''" />
            <span class="view-name table-data-font">{{(tile.Tile=='Portfolio Hub Old')?'Portfolio Hub':tile.Tile}}<br />
              <div style="text-indent:8px; margin-bottom:-10%;" class="portfolio-sub-name">
                {{ tile.SubTitle }}
              </div>
            </span>
            <div class="tooltipCard "
              style="width: 290px;  box-shadow: 1px 0.23vw 0.47vw 0 rgba(0, 0, 0, 0.25) !important;">
              <div (click)="$event.stopPropagation()" class="tooltip-content">
                <p>{{ tile.Description }}</p>
                <ng-container *ngIf="tile.CostData=='Yes'">
                  <span class="nextRefreshDate">Next Cost Data Refresh: {{tcmNextRefresh}}</span><br />
                  <span class="availableDate">Cost Data Available: {{tcmAvailableData}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tile.Tile!='Optimization'" class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-3 card-structure ">
          <div class="card-container card-container-height tcm-app-color" (click)="redirectView(tile.Tile)">
            <img class="landingPage-image" src="{{tile.icon}}"
              [ngStyle]="(tile.Tile=='Reclassification Portal')? {'width':'auto'}:''" />
            <span class="view-name table-data-font">{{(tile.Tile=='Portfolio Hub Old')?'Portfolio Hub':tile.Tile}}<br />
              <div style="text-indent:8px; margin-bottom:-10%;" class="portfolio-sub-name">
                {{ tile.SubTitle }}
              </div>
            </span>
            <div class="tooltipCard "
              style="width: 290px;  box-shadow: 1px 0.23vw 0.47vw 0 rgba(0, 0, 0, 0.25) !important;">
              <div (click)="$event.stopPropagation()" class="tooltip-content">
                <p>{{ tile.Description }}</p>
                <ng-container *ngIf="tile.CostData=='Yes'">
                  <span class="nextRefreshDate">Next Cost Data Refresh: {{tcmNextRefresh}}</span><br />
                  <span class="availableDate">Cost Data Available: {{tcmAvailableData}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>


  </div>
</div>