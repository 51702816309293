import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaintenanceService } from 'src/app/shared/services/maintenance.service';

@Component({
  selector: 'app-maintenance-modal',
  templateUrl: './maintenance-modal.component.html',
  styleUrls: ['./maintenance-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MaintenanceModalComponent implements OnInit {

  modalTitle: string;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  fileAttr = 'Choose File';
  SaveForm: FormGroup;
  fileToUpload: File = null;
  showLoader: boolean = false;
  createType: any;
  Label: string;
  MasterData: any;
  LobList: any[] = [];
  SubLobList: any[] = [];
  errorMessage: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toaster: ToastrService,
    public dialog: MatDialogRef<MaintenanceModalComponent>,
    public maintenanceService: MaintenanceService,
    private formBuilder: FormBuilder,
  ) {

  }

  ngOnInit() {
    this.errorMessage = '';
    if (this.data && this.data.title) {
      this.modalTitle = this.data.title;
      this.createType = this.data.Action_Type;
      this.Label = this.data.label;
      this.MasterData = this.data.MasterData;


      this.LobList = this.MasterData.map((masele) => {
        if (masele.subLob && masele.subLob.length) {
          masele.subLob.map((sube) => {
            this.SubLobList.push(sube.sub_lob);
          })
        }
        return masele.lob;
      });
    }

    this.SaveForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  closePopup(data = {}) {
    this.dialog.close(data);
  }

  submitForm() {
    this.errorMessage = '';
    let formData = this.SaveForm.value;
    if (formData && formData.name) {
      let lobResult;
      let sublobResult;
      switch (this.createType) {
        
        case 'Add_CIOLOB':
          lobResult = this.LobList.find(element => {
            return element && element.toLowerCase() === formData.name.toLowerCase();
          });
          if (lobResult) {
            this.SaveForm.get('name').setErrors({ 'invalid': true })
            this.errorMessage = "LOB already exists, Please try with other name."
            return;
          }

          break;
        case 'Add_SubLineofBusiness':
          sublobResult = this.SubLobList.find(element => {
            return element && element.toLowerCase() === formData.name.toLowerCase();
          });
          if (sublobResult) {
            this.SaveForm.get('name').setErrors({ 'invalid': true })
            this.errorMessage = "Sub LOB already exists, Please try with other name."
            return;
          }

          break;
      }
    }
    let payload: any = {
      data: formData,
      type: this.createType,
      status: "create"
    }
    this.closePopup(payload)
    // this.showLoader = true;
    // this.closePopup(formData)
    // this.maintenanceService.ImportFile(formData).subscribe((data) => {
    //   this.closePopup();
    //   this.showLoader = false;
    //   this.toaster.success("Thank you for submitting the request. You will be notified via email on status", '', { positionClass: 'toast-top-center', timeOut: 2000, })
    // }, (error) => {
    //   this.closePopup();
    //   this.showLoader = false;
    //   this.toaster.error("Something went wrong, Please try again later.", '', { positionClass: 'toast-top-center', timeOut: 1000, })
    // });
  }

}
