import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-admin-table-data',
  templateUrl: './admin-table-data.component.html',
  styleUrls: ['./admin-table-data.component.css']
})
export class AdminTableDataComponent {
  @Input() columns: any = [];
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() displayedColumns: any = [];
  @Input() tableName: string = "";
  @Output() editWorkQueue: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkQueue: EventEmitter<any> = new EventEmitter();
  @Output() sortChangeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  @ViewChild('pagination') paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filterList: any;
  searchValue: string;
  filterLists: any[];
  Allfilter = [];
  selectedColumn: any;
  triggerIndex = 0;
  index = 0;
  filterApplied = [];
  filterValues = [];
  showResetFilters = false;
  filValue = [];
  filSubValue = [];
  comingFromClearFilter: boolean = false;
  searchTxt = '';
  searchSubTxt = '';
  oldDataSource: any[];
  ReportFilterApplied: boolean = false;
  Report_TypeFilterApplied: boolean = false;
  Page_nameFilterApplied: boolean = false;
  ManagedbyFilterApplied: boolean = false;
  Embed_typeFilterApplied: boolean = false;
  reportFilter = [];
  reportTypeFilter = [];
  updatedByFilter = [];
  managedByFilter = [];
  pageNameFilter = [];
  embedTypeFilter = [];
  Finalfilter = [];
  previousSelection = [];


  ngOnInit() {
    this.displayedColumns = this.columns.map(element => element.columnDef);

  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.oldDataSource = this.dataSource.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnChanges() {
    if (this.dataSource) {
      this.oldDataSource = this.dataSource.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.clearallFilter();
    }
  }

  sortData(event) {
    let sort: any[] = [];
    if (event.direction) {
      sort = [[event.active, event.direction]];
    }
    this.sortChangeEvent.emit(sort);
  }


  filterDropdown(filtercolumn) {
    let column = filtercolumn.columnDef;
    this.selectedColumn = column;
    this.searchValue = '';
    let index;

    if (column == 'Report')
      index = 0;
    else if (column == 'Report_Type')
      index = 1;
    else if (column.columnDef == 'Managedby')
      index = 2;
    else if (column.columnDef == 'Page_name')
      index = 3;
    else if (column.columnDef == 'Embed_type')
      index = 4;
    this.trigger.toArray().forEach((item: MatMenuTrigger, i: number) => {
      if (i !== index && item.menuOpen) {
        item.closeMenu()
      }
    });

    if (column == 'Report') {
      if (this.Report_TypeFilterApplied || this.Page_nameFilterApplied || this.ManagedbyFilterApplied || this.Embed_typeFilterApplied) {
        this.filterList = this.dataSource.data.map((item) => { return item.Report });
      }
      else {
        this.filterList = this.oldDataSource.map((item) => { return item.Report });
      }
      this.filValue = this.reportFilter;
      this.trigger.toArray()[0].openMenu();
    }
    if (column == 'Report_Type') {
      if (this.Page_nameFilterApplied || this.ReportFilterApplied || this.ManagedbyFilterApplied || this.Embed_typeFilterApplied) {
        this.filterList = this.dataSource.data.map((item) => { return item.Report_Type });
      }
      else {
        this.filterList = this.oldDataSource.map((item) => { return item.Report_Type });
      }
      this.filValue = this.reportTypeFilter;
      this.trigger.toArray()[1].openMenu();
    }
    if (column == 'Managedby') {
      if (this.Page_nameFilterApplied || this.ReportFilterApplied || this.Report_TypeFilterApplied || this.Embed_typeFilterApplied) {
        this.filterList = this.dataSource.data.map((item) => { return item.Managedby });
      }
      else {
        this.filterList = this.oldDataSource.map((item) => { return item.Managedby });
      }
      this.filValue = this.managedByFilter;
      this.trigger.toArray()[2].openMenu();
    }
    if (column == 'Page_name') {
      if (this.Report_TypeFilterApplied || this.ReportFilterApplied || this.ManagedbyFilterApplied || this.Embed_typeFilterApplied) {
        this.filterList = this.dataSource.data.map((item) => { return item.Page_name });
      }
      else {
        this.filterList = this.oldDataSource.map((item) => { return item.Page_name });
      }

      this.filValue = this.updatedByFilter;
      this.trigger.toArray()[3].openMenu();
    }
    if (column == 'Embed_type') {
      if (this.Report_TypeFilterApplied || this.ReportFilterApplied || this.ManagedbyFilterApplied || this.Page_nameFilterApplied) {
        this.filterList = this.dataSource.data.map((item) => { return item.Embed_type });
      }
      else {
        this.filterList = this.oldDataSource.map((item) => { return item.Embed_type });
      }
      this.filValue = this.updatedByFilter;
      this.trigger.toArray()[4].openMenu();
    }
    this.filterList = new Set([...this.filterList]);
    this.filterLists = Array.from(this.filterList);
  }

  selectionChange(event) {
    let newevent;

    if (this.selectedColumn == 'Report') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Report') this.Allfilter.splice(i, 1) });
      if (event.source._value.length > 0) {
        newevent = this.checkPrevious(event);
        this.reportFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Report', value: newevent.source._value })
      } else {
        this.reportFilter = [];
      }
      this.trigger.toArray()[0].openMenu();
    }

    if (this.selectedColumn == 'Report_Type') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Report_Type') this.Allfilter.splice(i, 1) });
      if (event.source._value.length > 0) {
        newevent = this.checkPrevious(event);
        this.reportTypeFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Report_Type', value: newevent.source._value })
      } else {
        this.reportTypeFilter = [];
      }
      this.trigger.toArray()[1].openMenu();
    }

    if (this.selectedColumn == 'Managedby') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Managedby') this.Allfilter.splice(i, 1) });
      if (event.source._value.length > 0) {
        newevent = this.checkPrevious(event);
        this.managedByFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Managedby', value: newevent.source._value })
      } else {
        this.managedByFilter = [];
      }
      this.trigger.toArray()[2].openMenu();
    }
    if (this.selectedColumn == 'Page_name') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Page_name') this.Allfilter.splice(i, 1) });
      if (event.source._value.length > 0) {
        newevent = this.checkPrevious(event);
        this.pageNameFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Page_name', value: newevent.source._value })
      } else {
        this.pageNameFilter = [];
      }
      this.trigger.toArray()[3].openMenu();
    }
    if (this.selectedColumn == 'Embed_type') {
      this.Allfilter.forEach((a, i) => { if (a.column == 'Embed_type') this.Allfilter.splice(i, 1) });
      if (event.source._value.length > 0) {
        newevent = this.checkPrevious(event);
        this.embedTypeFilter = newevent.source._value;
        this.Allfilter.push({ column: 'Embed_type', value: newevent.source._value })
      } else {
        this.embedTypeFilter = [];
      }
      this.trigger.toArray()[4].openMenu();
    }
  }
  getfilterAppliedDtls(column: string) {
    return this[column + 'FilterApplied'];
  }
  checkPrevious(event) {
    if (this.previousSelection) {
      for (let i = 0; i < this.previousSelection.length; i++) {
        //If previous selection is in DropDownData and not in event then only add previous selection in Event Values(Server Side Search)
        if (this.filterLists.includes(this.previousSelection[i]) && event.source._value.length > 0
          && !event.source._value.includes(this.previousSelection[i]))  //Checked cond1
        {
          event.source._value.push(this.previousSelection[i]);
        }
        else {//Unchecked cond1
          let uncheckedElementName = event.source.options['_results'][0]['_mostRecentViewValue'];
          let unchekcedStatus = event.source.options['_results'][0]['_selected'];
          if (!unchekcedStatus) {
            if (this.previousSelection[i] == uncheckedElementName) // Need to add more conditions as per geeric use eg Report_Type
            {
              this.previousSelection.splice(i, 1);
              i--;
            }
          }
        }
      }
    }
    if (event.source._value.length == 0) {//Unchecked cond2
      for (let i = 0; i < this.previousSelection.length; i++) {
        if (this.filterLists.includes(this.previousSelection[i])) {
          event.source._value.push(this.previousSelection[i]);
        }
      }
    }
    return event;
  }

  applyLocalFilter() {
    this.searchTxt = '';
    let filterdata = this.oldDataSource;
    this.Finalfilter.forEach((a, i) => { if (a.column == this.selectedColumn) this.Finalfilter.splice(i, 1) });
    this.Allfilter.forEach((element) => {
      if (element.column == this.selectedColumn) {
        this.Finalfilter.push(element);
      }
    });
    this.Finalfilter.forEach((e1) => {
      if (e1.column == 'Report') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.Report));
          this.filterLists = filterdata.map((item) => { return item.Report });
          this.ReportFilterApplied = true;
          this.showResetFilters = true;
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[0].closeMenu();
        }
      }

      if (e1.column == 'Report_Type') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.Report_Type));
          this.filterLists = filterdata.map((item) => { return item.Report_Type });
          this.Report_TypeFilterApplied = true;
          this.showResetFilters = true;
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[1].closeMenu();
        }
      }

      if (e1.column == 'Page_name') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.Page_name));
          this.filterLists = filterdata.map((item) => { return item.Page_name });
          this.Page_nameFilterApplied = true;
          this.showResetFilters = true;
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[3].closeMenu();
        }
      }

      if (e1.column == 'Managedby') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.Managedby));
          this.filterLists = filterdata.map((item) => { return item.Managedby });
          this.ManagedbyFilterApplied = true;
          this.showResetFilters = true;
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[2].closeMenu();
        }
      }
      if (e1.column == 'Embed_type') {
        if (e1.value.length > 0) {
          filterdata = filterdata.filter((item) => e1.value.some(e => e == item.Embed_type));
          this.filterLists = filterdata.map((item) => { return item.Embed_type });
          this.Embed_typeFilterApplied = true;
          this.showResetFilters = true;
          this.dataSource = new MatTableDataSource(filterdata);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.trigger.toArray()[4].closeMenu();
        }
      }
    });
    if (this.Finalfilter.length == 0) {
      this.dataSource.data = this.oldDataSource;
      this.dataSource.paginator = this.paginator;
      this.showResetFilters = false;
    }
    if (this.trigger.toArray()[0]) {
      this.trigger.toArray()[0].closeMenu();
    }
    if (this.trigger.toArray()[1]) {
      this.trigger.toArray()[1].closeMenu();
    }
    if (this.trigger.toArray()[2]) {
      this.trigger.toArray()[2].closeMenu();
    }
    if (this.trigger.toArray()[3]) {
      this.trigger.toArray()[3].closeMenu();
    }
    if (this.trigger.toArray()[4]) {
      this.trigger.toArray()[4].closeMenu();
    }
  }

  clearLocalFilter() {
    this.searchTxt = '';
    this.previousSelection = [];
    if (this.trigger.toArray()[0]) {
      this.trigger.toArray()[0].closeMenu();
    }
    if (this.trigger.toArray()[1]) {
      this.trigger.toArray()[1].closeMenu();
    }
    if (this.trigger.toArray()[2]) {
      this.trigger.toArray()[2].closeMenu();
    }
    if (this.trigger.toArray()[3]) {
      this.trigger.toArray()[3].closeMenu();
    }
    if (this.trigger.toArray()[4]) {
      this.trigger.toArray()[4].closeMenu();
    }

    if (this.selectedColumn == 'Report') {
      this.reportFilter = [];
      this.ReportFilterApplied = false;
      this.Finalfilter.forEach((a, i) => { if (a.column == 'Report') this.Finalfilter.splice(i, 1) });
      this.Allfilter.forEach((a, i) => { if (a.column == 'Report') this.Allfilter.splice(i, 1) });
    }
    if (this.selectedColumn == 'Report_Type') {
      this.reportTypeFilter = [];
      this.Report_TypeFilterApplied = false;
      this.Finalfilter.forEach((a, i) => { if (a.column == 'Report_Type') this.Finalfilter.splice(i, 1) });
      this.Allfilter.forEach((a, i) => { if (a.column == 'Report_Type') this.Allfilter.splice(i, 1) });
    }

    if (this.selectedColumn == 'Managedby') {
      this.managedByFilter = [];
      this.ManagedbyFilterApplied = false;
      this.Finalfilter.forEach((a, i) => { if (a.column == 'Managedby') this.Finalfilter.splice(i, 1) });
      this.Allfilter.forEach((a, i) => { if (a.column == 'Managedby') this.Allfilter.splice(i, 1) });
    }
    if (this.selectedColumn == 'Page_name') {
      this.pageNameFilter = [];
      this.Page_nameFilterApplied = false;
      this.Finalfilter.forEach((a, i) => { if (a.column == 'Page_name') this.Finalfilter.splice(i, 1) });
      this.Allfilter.forEach((a, i) => { if (a.column == 'Page_name') this.Allfilter.splice(i, 1) });
    }
    if (this.selectedColumn == 'Embed_type') {
      this.embedTypeFilter = [];
      this.Embed_typeFilterApplied = false;
      this.Finalfilter.forEach((a, i) => { if (a.column == 'Embed_type') this.Finalfilter.splice(i, 1) });
      this.Allfilter.forEach((a, i) => { if (a.column == 'Embed_type') this.Allfilter.splice(i, 1) });
    }
    this.applyLocalFilter();
  }

  clearallFilter() {
    if (this.trigger) {
      if (this.trigger.toArray()[0]) {
        this.trigger.toArray()[0].closeMenu();
      }
      if (this.trigger.toArray()[1]) {
        this.trigger.toArray()[1].closeMenu();
      }
      if (this.trigger.toArray()[2]) {
        this.trigger.toArray()[2].closeMenu();
      }
      if (this.trigger.toArray()[3]) {
        this.trigger.toArray()[3].closeMenu();
      }
      if (this.trigger.toArray()[4]) {
        this.trigger.toArray()[4].closeMenu();
      }
    }

    this.reportFilter = [];
    this.reportTypeFilter = [];
    this.updatedByFilter = [];
    this.managedByFilter = [];

    this.ReportFilterApplied = false;
    this.Report_TypeFilterApplied = false;
    this.Page_nameFilterApplied = false;
    this.ManagedbyFilterApplied = false;
    this.Embed_typeFilterApplied = false;
    this.showResetFilters = false;
    this.Allfilter = [];
    this.Finalfilter = [];
    this.dataSource.data = this.oldDataSource;
    this.dataSource.paginator = this.paginator;;
    this.dataSource.sort = this.sort;
  }

  addRole(element) {
    let tableDataToEdit = {
      'tableName': this.tableName,
      'data': element
    }
    console.log(tableDataToEdit)
    this.editWorkQueue.emit(tableDataToEdit);
  }

  deleteRole(element) {
    let tableDataToEdit = {
      'tableName': this.tableName,
      'data': element
    }
    console.log(tableDataToEdit)
    this.deleteWorkQueue.emit(tableDataToEdit);
  }

}
