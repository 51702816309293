import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class PostAuthGuard  {
  activeAuthGuardVar:any;
  RouterStateSnapshot:any;

  constructor(private router: Router, private AuthService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.activeAuthGuardVar = route;
    this.RouterStateSnapshot = state;
    // not logged in so redirect to login page with the return url
    return true;
  }
}
