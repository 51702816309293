<!-- <div>
  <h4>Version 1.0</h4>

  <p>The Application Hub is a platform to review the PADU ratings, capability details, and chargeback summary for an app
    or LOB.</p>

  <p>All chargeback data has been pulled from MPWR.</p>

  <p>The PADU and capability data has been pulled from Alfabet.</p>

  <p>Application decom status has been pulled from ASK.</p>

  <p>Includes data from Jan 20-Mar 20. Last refreshed on April 15th 2020.</p>
</div> -->

<div class="container">
  <ng-container *ngIf="!showLoader">
    <div class="row">
      <h5 class="release-main-title">Release Notes</h5>
    </div>


    <div class="row">
      <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">

        <mat-card appearance="outlined" class="box-shadow release-content ">




          <span style="font-size: 14px; float: right;">Release Date: <strong>7th September 2023</strong></span>


          <mat-card-content>
            <!-- <h6 class="enhance-title"><strong><u>Enhancements: </u></strong></h6>

          <h6 class="enhance-title"><strong><u>UI</u></strong></h6>


          <ul class="list-content">
            <li>Health of the application will now be shown in Application Hub’s App View.</li>
          </ul> -->

            <!-- <h6 class="issue-fix-title">
              <strong><u style="margin-bottom: 4%;">Enhancements</u></strong>
            </h6> -->
            <!-- <div class="row">
              <div class="col-md-1 col-sm-12 col-lg-1, col-xl-1 col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                <span >Redesigned App Insights Page</span>
              </div>
            </div> -->

            <h6 class="enhance-title">Features</h6>
            <ul class="list-content">



              

         

              <div>
                <li><b>Portfolio Hub</b>
                  <ul>
                    <li>PADU YoY Insights are now available in Application PADU tab and each PADU area is clickable to get relevant App list.</li>
                  </ul>
                </li>
              </div>

              <div>
                <li><b>Application hub</b>
                  <ul>
                    <li>A Capability and all its Organizations can now be merged into another Associated Capability.</li>
                  </ul>
                </li>
              </div>

              <div>
                <li><b>Reports</b>
                  <ul>
                    <li>3 new Power BI Reports are added to Compliance Reports section-</li>
                    <li>Applications with missing LOB.</li>
                    <li>Missing Capability Mapping.</li>
                    <li>Capabilities with No App Mapped.</li>
                  </ul>
                </li>
              </div>
              
              <div>
                <li><b>Capability Models</b>
                  <ul>
                    <li>Stakeholders can now be added at all Capability Levels.</li>
                  </ul>
                </li>
              </div>

              
              <!-- <div>
                <li><b>Bulk Model Edit.</b>
                  <ul>
                    <li>Backend Code restructure for Bulk Model Edit.</li>
                  </ul>
                </li>
              </div>
              
              <div>
                <li><b>Reports page .</b>
                  <ul>
                    <li>Added edit functionality for Sharepoint type reports for the Owners.</li>
                  </ul>
                </li>
              </div> -->
            </ul>


            <!-- <h6 class="enhance-title">Bug fixes</h6>
            <ul class="list-content">

              <div>
                <li>Swagger Application API</li>
              </div>
              <div>
                <li>User Manual and Guide hidden as part of maintenance.</li>
              </div>
            </ul> -->

            <div class="row">
              <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-6">
                <app-data-table [dataSource]="refreshData" [columns]="refreshHeaderColumns"> </app-data-table>
              </div>
            </div>
          </mat-card-content>
          <h6>
            <strong><u>Browsers supported:</u> </strong>
          </h6>
          <mat-card-content class="browser-supported">
            <div class="browser-explorer browser-position">
              <img class="icon" src="/assets/images/icons8-microsoft-edge.svg" />
              <p class="version">Microsoft Edge</p>
            </div>

            <div class="browser-chrome">
              <img class="icon" src="/assets/images/icons8-chrome.svg" />
              <p class="version">Google Chrome</p>
            </div>
          </mat-card-content>
          <div>
            <p>Note** For better user experience,use Google Chrome for accessing dashboards</p>
          </div>
          <!-- <mat-card-content>
            <h6 class="issue-fix-title">
              <strong><u>New Additions</u></strong>
            </h6>
            <div class="row">
              <div class="col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xs-8 hubcard">
                The Labor Hub provides insight into labor trends. Development, O&M, and non-billable hour data is
                presented by leader and GL department.
                <p class="hubrelease">Released Date: <strong>4th August 2021</strong></p>
              </div>
            </div>
          </mat-card-content> -->
        </mat-card>
      </div>
    </div>
  </ng-container>
  <div *ngIf="showLoader" class="row loader-container">
    <app-loader></app-loader>
  </div>
</div>
