import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tcmHubConfigDetails } from '../../../config';
import { SharedService } from '../../../shared/services/shared.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'login-stub',
  templateUrl: './login-stub.component.html',
  styleUrls: ['./login-stub.component.less']
})
export class LoginStubComponent implements OnInit {
  model: any = {};
  loading = false;
  public nodeEndUrl = tcmHubConfigDetails.envApiUrl; // "http://localhost:1337";
  user: any = {};
  code: string;
  isInternalAccess = false;
  public sso: any;
  public tempUser: any;
  public token: any;

  public layer7at: string;
  private accessToken: any;
  public loader = false;
  public isIE: boolean;
  public isEdge: boolean;
  showSpinner: boolean = false;
  currentDateinMs: any;
  uniqueSessionId: any;
  loginSessionVar: any;
  public self: any;

  // public isSafari: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sharedService: SharedService,
    private auth: AuthService
  ) {


    // const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    // if (isIEOrEdge) {
    // window.location.href = '/browserError';
    // }
  }

  ngOnInit() {

//const historyUrl = Cookie.get('historyUrl') || null;
    this.isIE = window.navigator.userAgent.indexOf('Trident') > -1;
    this.isEdge = window.navigator.userAgent.indexOf('Edge') > -1;
    // this.isSafari = window.navigator.userAgent.indexOf('Safari') >= 0;
    // if (sessionStorage.getItem('currentUser')) {
    //   // logged in so return true
    //   if (historyUrl) {
    //     Cookie.delete('historyUrl'); 
    //     this.router.navigateByUrl(historyUrl);         
    //     }
    //   this.router.navigate([{ outlets: { primary: 'landing-page' } }]);
    //   return true;
    // }
    const rememberData = JSON.parse(localStorage.getItem('remember'));
    if (rememberData) {
      this.model = rememberData;
    }
  }
  logout() {
    sessionStorage.removeItem('currentUser');
    // window.location.href = environment.sso_keys.url;
  }

  login() {
    this.loader = true;
    this.self = this;

    /*  ************ CODE **************/

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*'
      })
    };
    if (this.model.remember) {
      localStorage.setItem('remember', JSON.stringify(this.model));
    }
    const loggedInUsername = this.model.username;
    /* add  environment.tcmKey second paremeter to enable encrypt. we remove due to CODEQL issue*/
    //const pwdforuser = CryptoJS.AES.encrypt(this.model.password).toString();
    // const mailId = this.model.mailId
    return this.http
      .post<any>(
        this.self.nodeEndUrl + '/auth/login',
        JSON.stringify({
          Username: this.model.username,
          Password:  this.model.password,
          //  mailId: mailId,
        }),
        httpOptions
      )
      .toPromise()
      .then((response) => {
        let sn = response.sn;
        let givenName = response.givenName;
        let middleName = response.middleName;
        if (!sn) {
          sn = '';
        }
        if (!givenName) {
          givenName = '';
        }
        if (!middleName) {
          middleName = '';
        }
        sessionStorage.setItem('currentUser', JSON.stringify(sn + ', ' + givenName + ' ' + middleName));
        sessionStorage.setItem('loggedInUserEmail', JSON.stringify(response.mail));
        sessionStorage.setItem('token', JSON.stringify(response.token).slice(1, -1));
        localStorage.setItem('currentUser', JSON.stringify(sn + ', ' + givenName + ' ' + middleName));
        localStorage.setItem('loggedInUserEmail', JSON.stringify(response.mail));
        localStorage.setItem('token', JSON.stringify(response.token).slice(1, -1));
        const currentDate = new Date();
        this.currentDateinMs = new Date();
        this.uniqueSessionId = loggedInUsername + currentDate.toString().replace(/ /g, '');
        //   sessionStorage.setItem('currentSessionUniqueId', uniqueSessionId);
        const MsId = loggedInUsername;
        sessionStorage.setItem('MsId', MsId);
        localStorage.setItem('MsId', MsId);
      
      })
      .then((data) => {
        this.loginSessionVar = data;
        this.updateUserStats(loggedInUsername);
        sessionStorage.setItem('selectedApp', 'All');
        sessionStorage.setItem('selectedLob', 'All');

       // self.router.navigate([{ outlets: { primary: 'landing-page' } }]);
      })
      .catch((data) => {
        this.self.loader = false;
        if (data.status === 401 && data.error && data.error.message === 'Restricted Access') {
          // alert('You don\'t have access to view dashboard');
          this.self.router.navigate([{ outlets: { primary: 'error-NotPartSecure' } }]);
        } else {
          alert('Error occured while logging. Please check your login details.');
        }
      });
  }

  updateUserStats(msId: string) {
    const body = {
      logIn_at: new Date().toISOString(),
      email: JSON.parse(sessionStorage.getItem('loggedInUserEmail')),
      name: JSON.parse(sessionStorage.getItem('currentUser')),

    };
    this.sharedService.createUserStats(msId, body).subscribe(() => {
    });
  }
}