import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { tcmHubConfigDetails } from '../../../config';
import { Subject } from 'rxjs';
import { formatNumber } from '@angular/common';
@Component({
  selector: 'app-service-bar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './service-bar.component.html',
  styleUrls: ['service-bar.component.css']
})
export class ServiceBarChartComponent implements OnInit {
  constructor(private container: ElementRef, private http: HttpClient) { }
public PrevYear = new Date().getFullYear()-1;
public CurrentYear = new Date().getFullYear();

  public margin: any;
  public yAxis: any;
  public xAxis: any;
  public xScale: any;
  public svg: any;
  public barHeight: any;
  public y0: any;
  @Input() data: any = [{ num: 8822, num2: 35697, mbo: 20000, label1: `${this.PrevYear} YTD`, label2: `${this.PrevYear} FY`, serviceName: "Intel Virtual Machine", applicationName: ["Optum Pay"] }];
  @Input() trenddata: any;
  lincChartdata: any = [];
  @Input() trend = false;
  @Input() mbotooltip = false;
  @Input() yoytooltip = false;
  @Input() mbobartooltip = false;
  @Input() symbal = '';
  @Input() mbobar = false;
  //@Input() footertext: any;
  @Input() variance: any;
  eventsSubject: Subject<void> = new Subject<void>();

  valueformatted: any;
  linechartsorteddata: [];
  public ServName = '';
  ngOnInit() {
    // Draw the chart
    this.barchart(this.data, this.container);
  }
  emitEventToChild(data) {
    this.eventsSubject.next(data);
  }

  private numberFormat(value: any): any {
    if (value) {
      return formatNumber(Number(value), 'en-US', '1.0-0')
    }
  }
  barchart(data, elem) {

    // Margin conventions
    this.margin = { top: 10, right: 50, bottom: 20, left: 0 };
    // const box = document.querySelector('.g-chart');

    // var widther = window.outerWidth;
    // const widther = box.parentElement.offsetWidth;
    //const width = widther - this.margin.left - this.margin.right;
    const width = 380 - this.margin.left - this.margin.right;
    const height = 55 - this.margin.top - this.margin.bottom;

    this.barHeight = 25;

    // Appends the svg to the chart-container div
    this.svg = d3
      .select(elem.nativeElement)
      .select('.g-chart')
      .append('svg')
      .attr('width', width + this.margin.left + this.margin.right)
      .attr('height', height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // Creates the xScale
    this.xScale = d3.scaleLinear().range([0, width]);

    // Creates the yScale
    this.y0 = d3.scaleBand().range([height, 0], 1);

    // Defines the y axis styles
    this.yAxis = d3.axisLeft(this.y0);

    // Defines the y axis styles
    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickFormat(d => {
        return d + '%';
      })
      .tickSize(height);

    // FORMAT data
    data.forEach(d => {
      d.num = +d.num;
      d.num2 = +d.num2;
    });

    // Sets the max for the xScale
    const maxX = d3.max(data, d => {
      return d.num2;
    });

    // Gets the min for bar labeling

    // Defines the xScale max
    this.xScale.domain([0, maxX]);

    // Appends the y axis
  

    // Appends the x axis
    // const xAxisGroup = this.svg
    //   .append('g')
    //   .attr('class', 'x1axis')
    //   .call(this.xAxis);

    // Binds the data to the bars
    // const categoryGroup = this.svg
    //   .selectAll('.g-category-group')
    //   .data(data)
    //   .enter()
    //   .append('g')
    //   .attr('class', 'g-category-group')
    //   .attr('transform', () => {
    //     return 'translate(0,0)';
    //   });

    // Appends background bar
    // const bars2 = categoryGroup
    //   .append('rect')
    //   .attr('width', d => {
    //     if (d.num2 > 0) {
    //       return this.xScale(d.num2);
    //     } else if (d.num2 == 0) {
    //       return this.xScale(d.num2) - 65;
    //     } else if (d.num == 0) {
    //       return null;
    //     }
    //   })
    //   .attr('height', this.barHeight - 1)
    //   .attr('class', 'g-num2')
    //   .style('fill', d => {
    //     if (this.variance) {
    //       return '#1984163d';
    //     }
    //   })
    //   .attr('transform', 'translate(0,4)');

    // Appends main bar
    // const bars = categoryGroup
    //   .append('rect')
    //   .attr('width', d => {
    //     if (d.num > d.num2) {
    //       return this.xScale(d.num2);
    //     } else if (d.num == 0 && d.num2 == 0) {
    //       return this.xScale(d.num) - 115, this.xScale(d.num2) - 115;
    //     } else if (d.num == 0 && d.num2 != 0) {
    //       return this.xScale(d.num) + 50;
    //     } else if (d.num != 0 && d.num2 == 0) {
    //       return this.xScale(d.num2) + 50;
    //     } else {
    //       return this.xScale(d.num);
    //     }
    //   })
    //   .attr('height', this.barHeight - 1)
    //   .attr('class', 'g-num')
    //   .attr('transform', 'translate(0,4)')
    //   .on('mouseover', handleMouseOver)
    //   .on('mouseout', handleMouseOut)
    //   .on('click', handleclick);

    if (this.mbobar) {
    //   const bars3 = categoryGroup
    //     .append('rect')
    //     .attr('width', 3)
    //     .attr('height', this.barHeight + 10)
    //     .attr('class', 'g-num')
    //     .style('fill', 'green')
    //     .attr('transform', d => {
    //       if (d.mbo > d.num2) {
    //         return 'translate(' + this.xScale(d.num2) + ',-1)';
    //       } else {
    //         return 'translate(' + this.xScale(d.mbo) + ',-1)';
    //       }
    //     });

      // let barNumbers3 = categoryGroup
      //   .append('text')
      //   .text(d => {
      //     return this.symbal == 'K' ? d.mbo + this.symbal : this.symbal + d.mbo;
      //   })
      //   .attr('x', d => {
      //     if (d.mbo > d.num2) {
      //       return  349;
      //     } else {
      //       return this.xScale(d.mbo);
      //     }})
      //   .style('fill', '#10005d')
      //   .attr('y', 42)
      //   .attr('class', 'g-labels-mbotext');
    }
    // Binds data to labels
    // const labelGroup = this.svg
    //   .selectAll('g-num')
    //   .data(data)
    //   .enter()
    //   .append('g')
    //   .attr('class', 'g-label-group')
    //   .attr('transform', () => {
    //     return 'translate(0,0)';
    //   });

    // Appends main bar labels
    // const barLabels1 = labelGroup
    //   .append('text')
    //   .text(d => {
    //     return d.label1;
    //   })
    //   .attr('x', 5)
    //   .style('fill', '#6c757d')
    //   .attr('y', 0)
    //   .attr('class', 'g-labels');

    // const barLabels2 = labelGroup
    //   .append('text')
    //   .text(d => {
    //     return d.label2;
    //   })
    //   .attr('x', d => {
    //     if (minX > 32) {
    //       return this.xScale(d.num2) - 67;
    //     } else {
    //       return this.xScale(d.num2) - 70;
    //     }
    //   })
    //   .style('fill', '#6c757d')
    //   .attr('y', 0)
    //   .attr('class', 'g-labels');

    /// bind the numbers
    // let barNumbers1 = labelGroup
    //   .append('text')
    //   .text(d => {
    //     return this.numberFormat(d.num);
        //return this.symbal == 'K' ? (d.num).toLocaleString() + this.symbal : this.symbal + (d.num).toLocaleString();
      // })
      // .attr('x', 5)
      // .style('fill', 'white')
      // .style('display', d => {
      //   if (d.num == 0) {
      //     return '0K';
          //return 'none';
      //   } else {
      //     return 'block';
      //   }
      // })
      // .attr('y', 21)
      // .attr('class', 'g-labels-text')
      // .on('mouseover', handleMouseOver)
      // .on('mouseout', handleMouseOut)
      // .on('click', handleclick);

    // let barNumbers2 = labelGroup
    //   .append('text')
    //   .text(d => {
    //     return this.numberFormat(d.num2);
        //return this.symbal == 'K' ? (d.num2).toLocaleString() + this.symbal : this.symbal + (d.num2).toLocaleString();
      // })
      // .attr('x', 260)
      // .style('fill', '#10005d')
      // .attr('y', 21)
      // .attr('class', 'g-labels-text');

    // const tooltip2 = d3
    //   .select(elem.nativeElement)
    //   .select('.g-chart')
    //   .append('div')
    //   .style('position', 'absolute')
    //   .style('visibility', 'hidden')
    //   .style('display', () => {
    //     if (this.mbotooltip) {
    //       return '';
    //     } else if (this.yoytooltip) {
    //       return '';
    //     } else {
    //       return 'none';
    //     }
    //   })
    //   .style('background-color', 'white')
    //   .style('border', 'solid')
    //   .style('border-width', '1px')
    //   .style('border-radius', '5px')
    //   .style('padding', '10px')
    //   .html(() => {
    //     let tooltip = '';
    //     if (this.mbotooltip) {
    //       return (tooltip = '<p>Click to view Monthly Chargeback</p>');
    //     }
        // else if (this.yoytooltip) {
        //   return (tooltip = '<p>25% of 2019 service chargeback</p>');
        // } else if (this.mbobartooltip) {
        //   return (tooltip = '<p>25% of 2019 service chargeback</p>');
        // }
      //   else {
      //     return (tooltip = '<p style="display:none"></p>');
      //   }
      // });

    // RESPONSIVENESS
    //d3.select(window).on('resize', resized);

    
    // Create Event Handlers for mouse
   

   
    // function handleclick(d) {
    //  // console.log('clicked', d);
    //   //  this.linechart(this.trenddata);
    //   this.makeServiceDataCall(d.serviceName, d.applicationName, elem);
    // }
  }
  async makeServiceDataCall(serviceName, appName, elem) {
    if (serviceName) {
      this.lincChartdata = [];
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*'
        })
      };
      const appListUrl: string =
        tcmHubConfigDetails.envApiUrl + '/' + tcmHubConfigDetails.routerpath.getServiceDetailsByNameV2;
      const servicPromise = this.http
        .post<any>(
          appListUrl,
          JSON.stringify({
            applications: appName,
            serviceName: serviceName
          }),
          httpOptions
        )
        .toPromise();
      this.lincChartdata = await servicPromise;
      let chartData:any=[];
      let config:any=localStorage.getItem("config");
      if(config){
        let index=JSON.parse(config).currentMonthIndex;
        let monthsMap = {
          0: "Infra_ACT_Jan",
          1: "Infra_ACT_Feb",
          2: "Infra_ACT_Mar",
          3: "Infra_ACT_Apr",
          4: "Infra_ACT_May",
          5: "Infra_ACT_Jun",
          6: "Infra_ACT_Jul",
          7: "Infra_ACT_Aug",
          8: "Infra_ACT_Sep",
          9: "Infra_ACT_Oct",
          10: "Infra_ACT_Nov",
          11: "Infra_ACT_Dec"
          }
       
         for(let i=1;i<=index+1;i++){
          let data={
            date:`${this.CurrentYear}-${i}-01`,
            "volume":this.lincChartdata[0][monthsMap[i-1]]
           }
            chartData.push(data)
         }
        
      }
      this.linechartsorteddata =chartData.sort((a, b) => {
        return <any>new Date(a.date) - <any>new Date(b.date);
      });
      this.ServName = serviceName;
      this.emitEventToChild({ serviceName: serviceName, trenddata: this.linechartsorteddata });
      const ele = elem.nativeElement.querySelector('.hover_bkgr_fricc') as HTMLInputElement;
      if (ele && this.linechartsorteddata) {
        ele.style.display = 'block';
      }
    }
  }
}
