import { Component, OnInit, ViewEncapsulation, Input, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-category-bar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './category-bar.component.html',
  styleUrls: ['category-bar.component.css']
})
export class CategoryBarComponent implements OnInit {
  @Input() catdata: any;
  config: any;
  constructor(private container: ElementRef, private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    // Draw the chart
    const sampleData = [
      { label: 'Big Data', value: 33, distance: 5 },
      { label: 'Cloud', value: 55, distance: 60 },
      { label: 'Database', value: 233, distance: 50 },
      { label: 'Mainframe', value: 56, distance: 60 },
      { label: 'Storage', value: 89, distance: 70 },
      { label: 'Process Enablement', value: 78, distance: 60 },
      { label: 'Other', value: 126, distance: 120 }
    ];
    // render chart
    this.stackedBar('.chart', sampleData, this.config);
  }

  stackedBar(bind, data, config) {
    // group data for chart and filter out zero values
    function groupData(data, total) {
      // use scale to get percent values
      const percent = d3
        .scaleLinear()
        .domain([0, total])
        .range([0, 100]);
      // filter out data that has zero values
      // also get mapping for next placement
      // (save having to format data for d3 stack)
      let cumulative = 0;
      let labelcumulative = 0;
      const _data = data
        .map(d => {
          cumulative += d.value;
          labelcumulative += d.distance;
          return {
            value: d.value,
            // want the cumulative to prior value (start of rect)
            cumulative: cumulative - d.value,
            labelcumulative: labelcumulative,
            label: d.label,
            percent: percent(d.value)
          };
        })
        .filter(d => d.value > 0);
      return _data;
    }
    config = {
      f: d3.format('.1f'),
      margin: { top: 20, right: 10, bottom: 20, left: 10 },
      width: 800,
      height: 100,
      barHeight: 20,
      colors: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#803e1c', '#93bb04'],
      ...config
    };
    const { f, margin, width, height, barHeight, colors } = config;
    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;
    const halfBarHeight = barHeight / 2;

    const total = d3.sum(data, d => d.value);
    const _data = groupData(data, total);

    // set up scales for horizontal placement
    const xScale = d3
      .scaleLinear()
      .domain([0, total])
      .range([0, w]);

    // create svg in passed in div
    const selection = d3
      .select(bind)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // stack rect for each data value
    selection
      .selectAll('rect')
      .data(_data)
      .enter()
      .append('rect')
      .attr('class', 'rect-stacked')
      .attr('x', d => xScale(d.cumulative))
      .attr('y', h / 4 - halfBarHeight)
      .attr('height', barHeight)
      .attr('width', d => xScale(d.value))
      .style('fill', (d, i) => colors[i])
      .on('click', handleclick);

    // add values on bar
    // selection.selectAll('.text-value')
    //   .data(_data)
    //   .enter().append('text')
    //   .attr('class', 'text-value')
    //   .attr('text-anchor', 'middle')
    //   .attr('x', d => xScale(d.cumulative) + (xScale(d.value) / 2))
    //   .attr('y', (h / 2) + (halfBarHeight * 1.1) + 20)
    //   .text(d => d.value)

    // add some labels for percentages
    selection
      .selectAll('.text-percent')
      .data(_data)
      .enter()
      .append('text')
      .attr('class', 'text-percent')
      .attr('text-anchor', 'middle')
      .attr('x', d => xScale(d.cumulative) + xScale(d.value) / 2)
      .attr('y', h / 4 + 5)
      .text(d => f(d.percent) + ' %')
      .on('click', d => {
        handleclick(d);
      });

    // add the labels
    selection
      .selectAll('.text-label')
      .data(_data)
      .enter()
      .append('text')
      .attr('class', 'text-label')
      .attr('text-anchor', 'left')
      .attr('x', d => xScale(d.labelcumulative))
      .attr('y', 0)
      .text(d => d.label);

    // add the circles
    selection
      .selectAll('svg')
      .data(_data)
      .enter()
      .append('circle')
      .attr('class', 'circle-label')
      .attr('cx', d => xScale(d.labelcumulative) - 5)
      .attr('cy', -3)
      .attr('r', 3)
      .style('fill', (d, i) => colors[i]);
    // Create Event Handlers for mouse
    

    
    function handleclick(d) {
      console.log('clicked', d);
    }
  }
}
