<mat-tree class="tree-select" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="40">
    <button mat-icon-button disabled></button>
    <mat-checkbox *ngIf="multiple" style="white-space: normal !important" class="checklist-leaf-node tree-option"
      [checked]="checklistSelection.isSelected(node)" [disabled]="List.includes(node.item)"
      (change)=" fetchSelectedValue(node)"><span [innerHTML]="node.item | highlight: searchString"></span>
      {{fetchAssocLevel(node)}}
    </mat-checkbox>


    <button *ngIf="!multiple" mat-raised-button [disabled]="List.includes(node.item)"
      class="checklist-leaf-node tree-option" (click)="getLevel(node) === 0 ? $event.stopPropagation() : treeNodeOptionClick(node)">
      <span [innerHTML]="node.item | highlight: searchString"></span> {{fetchAssocLevel(node)}}
    </button>
  </mat-tree-node>



  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
    <button class=" mat-treenode-button-margin" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox *ngIf="multiple  && (getLevel(node) >= 0)" [disabled]="List.includes(node.item)"
      (click)="$event.stopPropagation();" [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node);">
      <span [innerHTML]="node.item | highlight: searchString"></span> {{fetchAssocLevel(node)}}
    </mat-checkbox>
    <!-- <button *ngIf="multiple  && (getLevel(node) == 0)" mat-raised-button [disabled]="List.includes(node.item)"
      class="checklist-leaf-node tree-option" (click)="$event.stopPropagation(); ">
      <span [innerHTML]="node.item | highlight: searchString"></span>
    </button> -->
    <button mat-raised-button *ngIf="!multiple" [disabled]="List.includes(node.item)"
      class="checklist-leaf-node tree-option"
      (click)="getLevel(node) === 0 ? $event.stopPropagation() :treeNodeOptionClick(node)"><span
        [class.disabled]="List.includes(node.item)"><span [innerHTML]="node.item | highlight: searchString"></span>
        {{fetchAssocLevel(node)}}</span></button>

  </mat-tree-node>
</mat-tree>
