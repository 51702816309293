import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/shared';

@Component({
  selector: 'app-bulk-templates',
  templateUrl: './bulk-templates.component.html',
  styleUrl: './bulk-templates.component.css'
})
export class BulkTemplatesComponent {
  @ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  columns = [
    { key: 'bulk_id', display_name:'ID', width: '4%', align: 'center' },
    { key: 'Bulk_Edit', display_name: 'Bulk Edit', width: '12%', align: 'left' },
    { key: 'TestCases_Count', display_name: 'Total TestCases', width: '10%', align: 'center' },
    { key: 'Present_TestCases_Count', display_name: 'Generated TestCases', width: '10%', align: 'center' },
    { key: 'lastUpdatedAt', display_name: 'Updated At', width: '12%', type: 'date', align: 'center' },
    { key: 'lastUpdatedBy', display_name: 'Updated By', width: '12%', align: 'center' },
    { key: 'generate_template', display_name: 'Generate', width: '10%', align: 'center' },
    { key: 'postSubmit_lastUpdatedAt', display_name: 'Post Submit Updated At', width: '12%', align: 'center' },
    { key: 'post_submit', display_name: 'Post Submit', width: '10%', align: 'center' },
  ];
  displayedColumns = this.columns.map(c => c.key);

  constructor(
    private adminService: AdminService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.getTemplates();
  }

  getTemplates() {
    this.adminService.getTemplatesList().subscribe((res) => {
      if(res) {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  generateTemplate(element) {
    this.adminService.generateBulkTemplate(element.bulk_id).subscribe((res) => {
      if(res) {
        this.getTemplates();
        this.showSuccessMsg();
      }
    },
    (error) => {
      console.error(error);
      this.showErrMsg(error);
    });
  }

  postSubmit(element) {
    this.adminService.runPostSubmit(element.bulk_id).subscribe((res) => {
      if(res) {
        this.getTemplates();
        this.showSuccessMsg();
      }
    },
    (error) => {
      console.error(error);
      this.showErrMsg(error);
    });
  }

  showErrMsg(error) {
    this.toastrService.error('Something Went Wrong', '', {
      timeOut: 5000,
      positionClass: 'toast-top-center'
    });
  }
  
  showSuccessMsg() {
    this.toastrService.success('Successfully Updated', '', {
      timeOut: 5000,
      positionClass: 'toast-top-center'
    });
  }
}
