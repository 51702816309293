import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { YearData } from 'src/app/constants/fields';
import { environment } from '../../../environments/environment';
import { appModernizationDetails, tcmHubConfigDetails } from '../../config';
import { AppHubCacheService } from '../../shared';
import { CapabilityApplicationDetail, CapabilityOrganisationDetail, EditAuthUser, IApplicationDetail, ICapabilityPaginatedAppListEntity, IRiskRecordApp, IScanStatusApp, ITotalCOunt, PLMDetails, IArchitectDetailsEntity } from '../../shared/interface/interface';
import { apphubV4APIs } from '../configV4';
import { OptimizeService } from 'src/app/shared/optimize.service';

@Injectable({
  providedIn: 'root'
})
export class AppHubService {
  private apiUrl: string;
  private config: any;
  public routeSelected = new Subject<boolean>();
  public lobChangeSubject = new Subject<any>();
  public primaryUpdateubject = new Subject<any>();
  public organisationAddSubject = new Subject<any>();
  public organisationDeleteSubject = new Subject<any>();
  public configV4: any;
  public excelData: any;
  public portfolioExcelData: any;
  public PaduRoadMapData: any;
  public associatedCapabiltes: any =[];
  public techPaduData: any;
  public PLMData: any;
  public infraData: any
  public yearMonthData: YearData = {} as YearData;
  CurrentYear = tcmHubConfigDetails.CurrentYear;
  PrevYear = tcmHubConfigDetails.PrevYear;
  CurrentMonth = tcmHubConfigDetails.CurrentMonth;
  PreMonth = tcmHubConfigDetails.PreMonth;
  CurrentMonthPrevYear = tcmHubConfigDetails.CurrentMonthPrevYear;
  Month = tcmHubConfigDetails.Month;
  public PaduRoadMapData$ = new BehaviorSubject<any>({});
  public refreshMasterCardSubject = new Subject<any>();
  public techPaduPlmData$ = new Subject<any>();
  public applicationHubFromKnowYourFinances: boolean = false;
  public initiativesFromOurServices: boolean = false;
  constructor(
    private http: HttpClient,
    private http2: HttpClient,
    private dataCache: AppHubCacheService,
    handler: HttpBackend,
    private optimize:OptimizeService
  ) {
    this.configV4 = apphubV4APIs;
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
    this.http2 = new HttpClient(handler);
    this.getYearMonth();
  }
  getYearMonth() {
    this.optimize.getCurrentYear().subscribe(
      (res: any) => {
        if (res) {
          this.CurrentYear=res.currentYear;
          this.Month=res.currentMonth;
          this.PreMonth =res.previousMonth;
        }
      },
      err => {
        console.error(err);
        localStorage.removeItem('config');
      }
    );
  }
  paduData = this.PaduRoadMapData$.asObservable();
  selectedCapbilityFromHierarchy$ = new BehaviorSubject<any>('')
  private paduandRoadMaplifecycleDataSource = new BehaviorSubject<string>('');
  private paduandRoadMapselectedLOBDataSource = new BehaviorSubject<string>('');
  private paduandRoadMapselectedSUBLOBDataSource = new BehaviorSubject<string>('');
  lifecycle = this.paduandRoadMaplifecycleDataSource.asObservable()
  selectedLOB = this.paduandRoadMapselectedLOBDataSource.asObservable()
  selectedSUBLOB = this.paduandRoadMapselectedSUBLOBDataSource.asObservable()
  treeData$ = new BehaviorSubject<any>({});
 
  getcapabilityHierarchyDropdownValues() {
    return this.selectedCapbilityFromHierarchy$;
  }

  setcapabilityHierarchyDropdownValues(object: { cap: object, alreadyMappedCapability: Array<string> }) {
    this.selectedCapbilityFromHierarchy$.next(object);
  }

  

  getTreeStructureDropdownValues() {
    return this.treeData$;
  }

  setTreeStructureDropdownValues(obj) {

    this.treeData$.next(obj);
  }


  setRouteSelected(value: boolean) {
    this.routeSelected.next(value);
  }

  public getCapabilityCount(appId: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capabilityCount;
    // if (!model) {
    //   return of(0);
    // }
    return this.http
      .get<ITotalCOunt>(url, { params: { appId } })
      .pipe(
        map(data => {
          const total: any = {};
          data.capData.forEach(item => {
            total[item.current] = item.count;
          });
          total.total = data.totalCap;
          return total;
        })
      );
  }

  public getApplicationDetails(appId: string, model: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.appDetails;
    return this.http.get<IApplicationDetail>(url, { params: { appId, model } });
  }

  public getCapabilityAppList(body, params): Observable<any> {

    const queryParams = Object.keys(params).map((key) => {
      return `${key}=${params[key]}`;
    }).join('&')

    const url = this.apiUrl + this.config.routerpath.capabilityPaginatedAppList + `?${queryParams}`;

    if (!body.model) {
      return of([]);
    }
    return this.http
      .post<ICapabilityPaginatedAppListEntity>(url, body)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public getCapabilityRedundency(appId: string): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.associatedCapabilities
    let count = 10000;
    // if (!model) {
    //   return of([]);
    // }
    return this.http
      .get<any>(url, { params: { appId } })
      .pipe(
        map(data => {
          const output: any = [];
          data.forEach(item => {
            const rowItem: any = {};
            if (item.capabilityL3 == "NULL" || item.capabilityL3 == "null" || item.capabilityL3 == null) {
              if (item.capabilityL2 == "NULL" || item.capabilityL2 == "null" || item.capabilityL2 == null) {
                rowItem.capability = item.capabilityL1;
                rowItem.selectedCapabilityID = item.capabilityL1Id;
                rowItem.selectedCapability = item.capabilityL1;
              } else {
                rowItem.capability = item.capabilityL2;
                rowItem.selectedCapabilityID = item.capabilityL2Id;
                rowItem.selectedCapability = item.capabilityL2;
              }
            } else {
              rowItem.capability = item.capabilityL3;
              rowItem.selectedCapabilityID = item.capabilityL3Id;
              rowItem.selectedCapability = item.capabilityL3;
            }
            rowItem.capabilityL3 = item.capabilityL3;
            rowItem.capabilityL2 = item.capabilityL2;
            rowItem.capabilityL1 = item.capabilityL1;
            rowItem.capabilityL0 = item.capabilityL0;
            rowItem.current_padu = item.current;
            rowItem.organization = item.organisation;
            rowItem.capabilityL0Id = item.capabilityL0Id;
            rowItem.capabilityL1Id = item.capabilityL1Id;
            rowItem.capabilityL2Id = item.capabilityL2Id;
            rowItem.capabilityL3Id = item.capabilityL3Id;
            rowItem.org_id = item.org_id;
            rowItem.group_model = item.group_model;
            rowItem.transition_1 = item.transition_1;
            rowItem.transition_2 = item.transition_2;
            rowItem.transition_3 = item.transition_3;
            rowItem.transition_4 = item.transition_4;
            rowItem.transition_5 = item.transition_5;
            rowItem.transition_6 = item.transition_6;
            rowItem.primary_status = item.primary_status;
            rowItem.no_of_organisations = item.organisations.length;
            rowItem.organisations = item.organisations;
            rowItem.tempOrgID = count;
            rowItem.indeterminate = false;
            rowItem.expand = false;
            rowItem.organisations.forEach(orgItem => {
              orgItem.tempOrgID = count;
              orgItem.check = false
            });
            rowItem.id = item.id;
            count += 1;
            output.push(rowItem);
          });
          // output = output.filter(row => row.appName0);
          return output;
        })
      );
  }

  private uniqueApplications(value, index, self) {
    return (
      (value.current === 'P' || value.current === 'A') && self.findIndex(item => item.app_id === value.app_id) === index
    );
  }

  public getEditAuthUser(): Observable<any> {
    const msId = localStorage.getItem('MsId');
    const url = this.apiUrl + this.config.routerpath.allowEdit + msId;

    const cache = this.dataCache.getStaticCache(url);
    if (cache) {
      return of(cache);
    }
    return this.http.get<EditAuthUser>(url).pipe(
      map(result => {
        this.dataCache.setCache(url, result, true);
        return of(result);
      })
    );
  }

  public getCapabilityHierarchy(): Observable<any> {

    const url = this.apiUrl + this.configV4.routerPath.getCapabilityHierarchy;

    const cache = this.dataCache.getStaticCache(url);
    if (cache) {
      return of(cache);
    }
    return this.http.get<any>(url).pipe(
      map(result => {
        const output: any = [];


        if (result.length) {

          result.forEach(d => {
            const rowItem: any = {};

            rowItem.group_model = d.group_model;
            rowItem.capabilityL0 = (d.capabilityL0 !== '') ? d.capabilityL0 : null;
            rowItem.capabilityL1 = (d.capabilityL1 !== '') ? d.capabilityL1 : null;
            rowItem.capabilityL2 = (d.capabilityL2 !== '') ? d.capabilityL2 : null;
            rowItem.capabilityL3 = (d.capabilityL3 !== '') ? d.capabilityL3 : null;
            rowItem.capabilityL0Id = (d.capabilityL0Id !== '') ? d.capabilityL0Id : null;
            rowItem.capabilityL1Id = (d.capabilityL1Id !== '') ? d.capabilityL1Id : null;
            rowItem.capabilityL2Id = (d.capabilityL2Id !== '') ? d.capabilityL2Id : null;
            rowItem.capabilityL3Id = (d.capabilityL3Id !== '') ? d.capabilityL3Id : null;
            rowItem.capabilityL0_NAME = (d.capabilityL0_NAME !== '') ? d.capabilityL0_NAME : null;
            rowItem.capabilityL1_NAME = (d.capabilityL1_NAME !== '') ? d.capabilityL1_NAME : null;
            rowItem.capabilityL2_NAME = (d.capabilityL2_NAME !== '') ? d.capabilityL2_NAME : null;
            rowItem.capabilityL3_NAME = (d.capabilityL3_NAME !== '') ? d.capabilityL3_NAME : null;
            output.push(rowItem);
          })

        }

        // output = output.filter(row => row.appName0);
        this.dataCache.setCache(url, output);
        return output;



      })
    );
  }
  // public heimdall(askid: string) {
  //   console.log(askid);
  //   const url = 'https://heimdall-api.origin-elr-core.optum.com/applications/' + askid + '/risk-records';
  //   console.log(url)
  //   return this.http.get(url);
  // }
  /**
   * @author Anjit
   * Had Tools API listing
   * @param ServiceId
   */
  public HadDetails(ServiceId: string) {
    return this.http2.get('https://had-api.optum.com/ScoringData', { params: { ServiceId } });
  }

  public getRiskRecordHeimdall(askid: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getRiskRecord + askid;
    // return this.http.get<IApplicationDetail>(url);
    return this.http
      .get<Array<IRiskRecordApp>>(url)
      .pipe(

        map(data => {

          const output: any = [];


          if (data.length) {

            data.forEach(d => {
              const rowItem: any = {};

              rowItem.riskRecordNumber = d.riskRecordNumber;
              rowItem.dateCreated = d.dateCreated;
              rowItem.controlStandardsImpacted = d.controlStandardsImpacted;
              rowItem.expirationDate = d.expirationDate;
              rowItem.reviewStatus = d.reviewStatus;
              rowItem.timesRenewed = d.timesRenewed;
              rowItem.requestOwner = d.requestOwner;
              rowItem.link = d.link;
              rowItem.applicationName = d.applicationName;
              rowItem.askId = d.askId;


              output.push(rowItem);
            })

          }
          // output = output.filter(row => row.appName0);
          return output;

        })
      );
  }

  public getScanStatusApp(askid: string): Observable<any> {
    const url = `${this.apiUrl}${this.config.routerpath.getScanStatus}${askid}`;
    // return this.http.get<IApplicationDetail>(url);
    return this.http
      .get<Array<IScanStatusApp>>(url)

      .pipe(

        map(data => {
          const output: any = [];
          if (data['_embedded']['issueSummaries'].length) {

            data['_embedded']['issueSummaries'].forEach(d => {
              const rowItem: any = {};
              rowItem.securityToolProductName = d.securityToolProductName;
              rowItem.critical = d.critical;
              rowItem.severe = d.severe;
              rowItem.high = d.high;
              rowItem.medium = d.medium;
              rowItem.low = d.low;
              rowItem.toolStatusDate = d.toolStatusDate;
              rowItem.securityToolVendorName = d.securityToolVendorName;
              const issSummaryArray: any = [];
              d.issueSummaryDetails.forEach(data => {
                const rowItem1: any = {};
                rowItem1.linkDisplayName = data.linkDisplayName;
                rowItem1.critical = data.critical;
                rowItem1.severe = data.severe;
                rowItem1.high = data.high;
                rowItem1.medium = data.medium;
                rowItem1.low = data.low;
                rowItem1.toolStatusDate = data.toolStatusDate;

                rowItem1.link = data.link;
                issSummaryArray.push(rowItem1);
              });

              rowItem.issueSummaryDetails = issSummaryArray;
              // rowItem.link = d.link;
              // rowItem.applicationName = d.applicationName;



              output.push(rowItem);
            })

          }
          // output = output.filter(row => row.appName0);
          return output;

        })
      );
  }
  public updatePADUSimplication(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updatePaduSimplification;
    const body = req;
    return this.http.post<any>(url, body);
  }

  public updatePADURoadMap(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updatePaduRoadMap;
    const body = req;
    return this.http.post<any>(url, body);
  }

  public updateApplicationAsscCapability(req: any, id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updateApplicationAsscCapability + `/${id}`;
    const body = req;
    return this.http.put<any>(url, body);
  }

  addNewApplicationCapabilityMapping(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updateApplicationAsscCapability;
    const body = req;
    return this.http.post<any>(url, body);
  }

  deleteApplicationCapabilityMapping(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url = this.apiUrl + this.configV4.routerPath.deleteApplicationOrganizationAssociation;
    return this.http.delete<any>(url, options);
  }

  specificAppPLMData(appId): Observable<Array<PLMDetails>> {
    const url = this.apiUrl + this.configV4.routerPath.specificAppPLMData;
    return this.http.get<any>(url, { params: { appId } });
  }
  public getPADUWithoutToBeRetired(appId): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getPADUWithoutToBeRetired;
    return this.http.get<any>(url, { params: { appId } });
  }
  public getCapabilityApplicationDetails(capabilityId: string, model: string, app_ID: string): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.capabilityApplications;
    return this.http.get<CapabilityApplicationDetail>(url, { params: { capabilityId, model, app_ID } });
  }

  public getCapabilityOrganisationDetails(capabilityId: string, model: string, app_ID: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.organisationDetails;
    return this.http.get<CapabilityOrganisationDetail>(url, { params: { capabilityId, model, app_ID } });
  }

  public getOrganisationApplicationDetails(capabilityId: string, model: string, organizationID: string, app_ID: string): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationwithcapabilityPADU;
    return this.http.get<CapabilityApplicationDetail>(url, { params: { capabilityId, model, organizationID, app_ID } });
  }

  public updatePrimarystatus(req: any): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.primaryUpdate;
    const body = req;
    return this.http.put<any>(url, body);
  }

  notifyMasterCardUpdate(appId) {
    this.refreshMasterCardSubject.next({update: true, appId});
  }
  // notifyMasterCardUpdate() {
  //   this.refreshMasterCardSubject.next({update: true });
  // }
  addcaps(req: any): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.addcaps;
    const body = req;
    return this.http.post<any>(url, body);
  }
  editCaps(editBody: any): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.editCaps;
    return this.http.post<any>(url, editBody);
  }
  mergeCaps(editBody: any): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.mergeCaps;
    return this.http.post<any>(url, editBody);
  }


  fetchAppPaduAndRoadmap(appId): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.paduRoadmap;
    return this.http.get<any>(url, { params: { appId } });
  }

  Mastercarddetails(appId: string): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.mastercardDetails;
    return this.http.get<IApplicationDetail>(url, { params: { appId } });
  }

  paduandRoadMaplifecycle(lifecycle: string) {
    this.paduandRoadMaplifecycleDataSource.next(lifecycle);
  }
  paduandRoadMapselectedLOB(selectedLOB: string) {
    this.paduandRoadMapselectedLOBDataSource.next(selectedLOB);
  }
  paduandRoadMapselectedSUBLOB(selectedSUBLOB: string) {
    this.paduandRoadMapselectedSUBLOBDataSource.next(selectedSUBLOB);
  }

  public updatePaduandRoadMap(req: any): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.paduandroadmapupdate;
    const body = req;
    return this.http.post<any>(url, body);
  }

  public getAppList(page, search): Observable<any> {
    if (search) {
      let body = { page: page, search: search }
      const url: string = this.apiUrl + this.configV4.routerPath.appsonSearch;
      return this.http.post<any>(url, body);
    }
  }
  public getAppListForEdit(page, search): Observable<any> {
    if (search) {
      let body = { page: page, search: search }
      const url: string = this.apiUrl + this.configV4.routerPath.appsForEditWorkqueues;
      return this.http.post<any>(url, body);
    }
  }
  public getArchitectDetails(body): Observable<any> {

    const url = this.apiUrl + this.configV4.routerPath.getArchitectDetails;
    // const cache = this.cache.getCache(url);

    return this.http.post<IArchitectDetailsEntity>(url, body);

  }
  public updateDemographicDetails(req: any): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.updateDemographicDetails;
    const body = req;
    return this.http.put<any>(url, body);
  }
  public filter$ = new BehaviorSubject({ lob: '', appName: '', askId: '', capability: '', model: '', padu: '', cio: '', infraApp: '' });

  filterValues() {
    return this.filter$;
  }

  setFilterValue(obj: { lob: string, appName: string, askId: string, capability: string, model: string, padu: string, cio: string, infraApp: string, category: string, lifecycle: string }) {
    this.filter$.next(obj);
  }

  public getSummaryAppRelationships(appId): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationRelationshipSummary;
    return this.http.get<any>(url, { params: { appId } });
  }


  public getApplicationHealthServiceSummary(appId): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationHealthServiceSummary;
    return this.http.get<any>(url, { params: { appId} });
  }

  public getapplicationRoadMapSummary(appId): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationRoadMapSummary;
    return this.http.get<any>(url, { params: { appId } });
  }

  public getApplicationCostViewSummary(appId, year): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationCostViewSummary;
    return this.http.get<any>(url, { params: { appId, year } });
  }

  public getInfraCostViewDetails(appId): Observable<any> {
    // const url = this.apiUrl + this.configV4.routerPath.infraCostViewDetails;getTcoCostViewDetails
    const url = this.apiUrl + this.configV4.routerPath.getTcoCostViewDetails;
    return this.http.get<any>(url, { params: { appId,Year:this.CurrentYear,Month:this.Month,PrivsMonth:this.PreMonth } });
  }

  public getApplicationTechPaduDetails(appId): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationTechPaduSummary;
    return this.http.get<any>(url, { params: { appId } }).pipe(
      map(data => {
        this.setTechPaduPlmData(data);
        return data;
      })
    )
  }

  setTechPaduPlmData(data): void {
    this.techPaduPlmData$.next(data);
  }

  getTechPaduPlmData(): Observable<any> {
    return this.techPaduPlmData$;
  }

  public set yearData(yearData: YearData) {
    this.yearMonthData = yearData;
  }

  public get yearData(): YearData {
    return this.yearMonthData;
  }

  public getGroupmodelV1(): Observable<Array<string>> {
    const url = this.apiUrl + this.configV4.routerPath.modelListV1;
    const cache = this.dataCache.getCache(url);
    if (!cache) {
      return this.http.get<Array<string>>(url).pipe(
        map(data => {
          this.dataCache.setCache(url, data);
          return data;
        })
      );
    } else {
      return of(cache);
    }
  }

  public getOrganizationList(): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getOrgList;
    return this.http.get(url).pipe(
      map(data => {
        return data;
      })
    );
  }
  public getInfraServiceDetails(appId, type): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.infraServiceDetails;
    return this.http.get<any>(url, { params: { appId, type,Year:this.CurrentYear,Month:this.Month } });
  }

  public getCostViewInfraServiceMonthlyTrend(appId, type, serviceName): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.costViewInfraServiceMonthlyTrend;
    return this.http.get<any>(url, { params: { appId, type, serviceName,Year:this.CurrentYear,Month:this.Month } });
  }

  public getBPAAssetsSummary(appName): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationBPAAssetsSummay;
  
    return this.http.get<any>(url, { params: { appName } })
  }


  public getCostViewInfraServiceSUI(appId, type, serviceName): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.costViewInfraServiceSUI;
    return this.http.get<any>(url, { params: { appId, type, serviceName,Year:this.CurrentYear,Month:this.Month } });
  }

  public getApplicationPlmSummary(appId): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.applicationPlmSummary;
    return this.http.get<any>(url, { params: { appId } });
  }

  public getAppDemographicDropdownValues() : Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getApplicationAttributes;
    return this.http.get<any>(url, { params: {  } });
  }

  public getInitiativeAllList(): Observable<any> {
    const url = this.apiUrl + this.configV4.routerPath.getInitiativeList;
    return this.http.get<any>(url, { params: {} });
  }
  
  public checkLobSubLobRequestApproval(approvalRoles, Role, ownedLobSubLob, source, destination){
    if (approvalRoles != '' && approvalRoles.includes(Role) == true){
      if ( Role != 'Admin'){
        if (source === 'No Mapping'){
          if (ownedLobSubLob.includes(destination) != true){
            return {'id' : 3, 'message': "Generated Request needs Approval from Proposed LOB Owner", 'approvalFrom' : 'Destination'};
          }
          else {
            return {'id' : 4, 'message': "Generated Request will be Auto Approved", 'approvalFrom' : 'Auto'};
          }
        }
        else{
          if (ownedLobSubLob.includes(source) != true && ownedLobSubLob.includes(destination) != true){
            return {'id' : 1, 'message': "Generated Request needs Approval from Current and Proposed LOB Owner", 'approvalFrom' : 'Both'};
          }
          else if (ownedLobSubLob.includes(source) != true && ownedLobSubLob.includes(destination) == true){
            return {'id' : 2, 'message': "Generated Request needs Approval from Current LOB Owner", 'approvalFrom' : 'Source'};
          }
          else if (ownedLobSubLob.includes(source) == true && ownedLobSubLob.includes(destination) != true){
            return {'id' : 3, 'message': "Generated Request needs Approval from Proposed LOB Owner", 'approvalFrom' : 'Destination'};
          }
          else {
            return {'id' : 4, 'message': "Generated Request will be Auto Approved", 'approvalFrom' : 'Auto'};
          }
        }
      }
      else{
        return {'id' : 4, 'message': "Generated Request will be Auto Approved", 'approvalFrom' : 'Auto'};
      }
    }
    else{
      return {'id' : 1, 'message': "Generated Request needs Approval from Current and Proposed LOB Owner", 'approvalFrom' : 'Both'};
    }
    
  }
}

