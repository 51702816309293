import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface AppHostingEnvL1Model {
  id: string,
  l1Name: string
}

export interface AppHostingEnvDropdownModel {
  id: string,
  l0Name: string,
  selected: boolean;
  indeterminate: boolean;
  subEnvs: AppHostingEnvL1Model[];
}
@Component({
  selector: 'app-attributes-dropdown',
  templateUrl: './attributes-dropdown.component.html',
  styleUrls: ['./attributes-dropdown.component.css']
})
export class AttributesDropdownComponent implements OnInit {
  @Input() dropdownLabel;
  @Input() dropdownData: object = {};
  @Input() selectedDropdownValues: string[] = [];
  @Output() selectionChanged : EventEmitter<any> = new EventEmitter();
  public selectedObj:any;
  @Input() hostingEnvList : AppHostingEnvDropdownModel[] = []; 
  public originalHostingEnvList : AppHostingEnvDropdownModel[] = []; 
  public selectedEnvsIdMap : Map<string,string[]> = new Map<string,string[]>();
  public selectedEnvsNameMap : Map<string,string[]> = new Map<string,string[]>();
  public selectedSubEnvs : any[] = [];
  hostingEnvs = new FormControl();
  public previousSelectedHostingEnvs :any[]= [];
  isExpandCategory: boolean[] = [];
  selectedValue: string[] = [];
  public optionSelected = true;
  public searchAppTypeTxt;
  public selectedL0 : string[] = [];
  public selectedL1 : string[] = [];
  public dropdownL1Ids : string[] = [];
  public previousEnvDataMap : Map<string,string[]> = new Map<string,string[]>();
  public allEnvsSelected : boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.hostingEnvs.setValue([]);
    this.dropdownL1Ids = [];
    this.selectedEnvsIdMap = new Map<string,string[]>();
    this.selectedEnvsNameMap = new Map<string,string[]>();
    this.hostingEnvList.forEach(group => {
      let ids = group.subEnvs.map(obj => obj.id);
      this.dropdownL1Ids.push(...ids);
    })
    this.configurePreSelectedData();
  }
  
  configurePreSelectedData() {
    this.selectedL0 = [];
    this.selectedL1 = [];
    this.selectedDropdownValues.forEach(value => {
      let splitString = value.split("-");
      let l0 = splitString[0];
      if(l0 && !this.selectedL0.includes(l0.trim())) {
        this.selectedL0.push(l0.trim());
      }
      let l1 = splitString[1];
      if(l1 && !this.selectedL1.includes(l1.trim())) {
        this.selectedL1.push(l1.trim());
      }
    });

    let states = this.hostingEnvs.value;
    states = states ? states : [];
    this.hostingEnvList.forEach(env => {
      let preSelectedSubEnvIds : string[] = [];
      let preSelectedSubEnvNames : string[] = [];
      env.subEnvs.forEach(subEnv => {
        if(this.selectedL1.includes(subEnv.l1Name)) {
          preSelectedSubEnvIds.push(subEnv.id);
          preSelectedSubEnvNames.push(subEnv.l1Name);
          if(!states.includes(subEnv.id)) {
            states.push(subEnv.id)
          }
        }
      });
      if(preSelectedSubEnvNames.length > 0) {
        if(preSelectedSubEnvNames.length == env.subEnvs.length) {
          env.selected = true;
          env.indeterminate = false;
        } else {
          env.selected = false;
          env.indeterminate = true;
        }
      }
      if(preSelectedSubEnvIds.length > 0) this.selectedEnvsIdMap.set(env.id,preSelectedSubEnvIds);
      if(preSelectedSubEnvNames.length > 0) this.selectedEnvsNameMap.set(env.l0Name,preSelectedSubEnvNames);
    });
    if(this.dropdownL1Ids.length > 0 && this.dropdownL1Ids.length == states.length) {
      this.allEnvsSelected = true;
    }
    
    this.previousSelectedHostingEnvs = states;
    this.hostingEnvs.setValue(states);
    this.originalHostingEnvList = JSON.parse(JSON.stringify(this.hostingEnvList));
    this.configureSelectedValue();
  }

  toggleSelection(event: any, group: AppHostingEnvDropdownModel) {
    let states = this.hostingEnvs.value;
    states = states ? states : [];
    let subEnvIds : string[] = group.subEnvs.map(o => o.id);
    let subEnvNames : string[] = group.subEnvs.map(o => o.l1Name);
    if(event.checked) {
      // states.push(...subEnvIds);
      subEnvIds.forEach(env => {
        if(!states.includes(env)) {
          states.push(env);
        }
      });
      group.selected = event.checked;
      group.indeterminate = false;
      this.updateOriginGrpValue(group);
      this.selectedEnvsIdMap.set(group.id,subEnvIds);
      this.selectedEnvsNameMap.set(group.l0Name,subEnvNames);
      if(this.dropdownL1Ids.length > 0 && this.dropdownL1Ids.length == states.length) {
        this.allEnvsSelected = true;
      } 
    } else {
      group.selected = event.checked;
      this.updateOriginGrpValue(group);
      this.allEnvsSelected = false;
      subEnvIds.forEach((x: string) => states.splice(states.indexOf(x), 1));
      this.selectedEnvsIdMap.delete(group.id);
      this.selectedEnvsNameMap.delete(group.l0Name);
    }
   
    this.hostingEnvs.setValue(states);
    this.emitOnChangeEvent();
    this.configureSelectedValue();
  }

  onEnvChange(selected,group : AppHostingEnvDropdownModel,subenv : AppHostingEnvL1Model) {
    let states : any[] = this.hostingEnvs.value;
    states = states ? states : [];
    if(selected) {
      let subEnvIds = group.subEnvs.map(o => o.id);
      let allSelected = subEnvIds.every(a => states.includes(a));
      group.selected = allSelected;
      group.indeterminate = !allSelected;
      this.updateOriginGrpValue(group);
      if(this.selectedEnvsIdMap.get(group.id) == null) {
        this.selectedEnvsIdMap.set(group.id,[subenv.id]);
      } else {
        this.selectedEnvsIdMap.get(group.id).push(subenv.id);
      }
      if(this.selectedEnvsNameMap.get(group.l0Name) == null) {
        this.selectedEnvsNameMap.set(group.l0Name,[subenv.l1Name]);
      } else {
        this.selectedEnvsNameMap.get(group.l0Name).push(subenv.l1Name);
      }
      if(this.dropdownL1Ids.length > 0 && this.dropdownL1Ids.length == states.length) {
        this.allEnvsSelected = true;
      } 
    } else {
      this.allEnvsSelected = false;
      let subEnvsIdArray = this.selectedEnvsIdMap.get(group.id);
      if(subEnvsIdArray.length > 0) {
        subEnvsIdArray.splice(subEnvsIdArray.indexOf(subenv.id),1);
      }
      let subEnvsNameArray = this.selectedEnvsNameMap.get(group.l0Name);
      if(subEnvsNameArray.length > 0) {
        subEnvsNameArray.splice(subEnvsNameArray.indexOf(subenv.l1Name),1);
      }
      if(subEnvsIdArray.length > 0) {
        group.selected = false;
        group.indeterminate = true;
        this.updateOriginGrpValue(group);
        this.selectedEnvsNameMap.set(group.l0Name,subEnvsNameArray);
        this.selectedEnvsIdMap.set(group.id,subEnvsIdArray);
      } else {
        group.selected = false;
        group.indeterminate = false;
        this.updateOriginGrpValue(group);
        this.selectedEnvsNameMap.delete(group.l0Name);
        this.selectedEnvsIdMap.delete(group.id);
      }
    }
    this.emitOnChangeEvent();
    this.configureSelectedValue();
    
  }

  configureSelectedValue() {
    let selectedValuesArray : string[] = [];
    this.selectedEnvsNameMap.forEach((value: string[],key:string) => {
      let valueArr : string[]=[];
      value.map(o => {
        valueArr.push(key+' - '+o);
      });
      selectedValuesArray.push(...valueArr);
    });
    this.selectedValue = selectedValuesArray;
  }

  emitOnChangeEvent() {
    this.selectionChanged.emit({updatedValues: this.selectedEnvsIdMap});
  }

  selectAllEnvs(event) {
    let states : any[] = this.hostingEnvs.value;
    states = states ? states : [];
    if(event.checked) {
      this.allEnvsSelected = true;
      this.hostingEnvList.forEach(group => {
        let subEnvIds : string[] = group.subEnvs.map(o => o.id);
        let subEnvNames : string[] = group.subEnvs.map(o => o.l1Name);
        group.subEnvs.forEach(subEnv => {
          if(!states.includes(subEnv.id)) {
            states.push(subEnv.id);
          }
        });
        group.selected = event.checked;
        group.indeterminate = false;
        this.selectedEnvsIdMap.set(group.id,subEnvIds);
        this.selectedEnvsNameMap.set(group.l0Name,subEnvNames);
      });
      this.emitOnChangeEvent();
    } else {
      this.allEnvsSelected = false;
      this.hostingEnvList.forEach(group => {
        group.selected = event.checked;
        let subEnvIds : string[] = group.subEnvs.map(o => o.id);
        subEnvIds.forEach((x: string) => states.splice(states.indexOf(x), 1));
        this.selectedEnvsIdMap.delete(group.id);
        this.selectedEnvsNameMap.delete(group.l0Name);
      });
      this.emitOnChangeEvent();
    }
    this.hostingEnvs.setValue(states);
  }

  checkIndeterminate() {
    // hostingEnvs && hostingEnvs.value && hostingEnvs.value.length  > 0 && hostingEnvs.value.length != dropdownL1Ids.length
    let states = this.hostingEnvs.value;
    if(states.length > 0 && states.length != this.dropdownL1Ids.length) {
      return true;
    } else {
      return false;
    }
  }

  filterData() {
    this.checkGrpCheckBox();
    // if(this.searchAppTypeTxt.length == 0)  {
    //   this.hostingEnvList = this.originalHostingEnvList;
    //   return;
    // }
    this.hostingEnvList = [];
    this.originalHostingEnvList.forEach(obj => {
      let l0Index = obj.l0Name.toLowerCase().indexOf(this.searchAppTypeTxt.toLowerCase());
      if(obj.l0Name && l0Index > -1) {
        this.hostingEnvList.push(obj);
      } else if(obj.subEnvs.length > 0) {
        let newObj = JSON.parse(JSON.stringify(obj));
        let subEnvs =  obj.subEnvs.filter(item => {
          if(item.l1Name ) {
            return item.l1Name.toLowerCase().indexOf(this.searchAppTypeTxt.toLowerCase()) > -1;
          }
        });
        if(subEnvs.length > 0) {
          newObj.subEnvs = subEnvs;
          this.hostingEnvList.push(newObj);
        }
      }

    })
  }

  updateOriginGrpValue(group : AppHostingEnvDropdownModel) {
    this.originalHostingEnvList.map(obj => {
      if(obj.l0Name == group.l0Name) {
        obj.selected = group.selected;
        obj.indeterminate = group.indeterminate;
      }
    });
  }

  onChange(event) {
    if(this.searchAppTypeTxt) {
      if(this.previousSelectedHostingEnvs) {
        this.previousSelectedHostingEnvs.forEach(obj => {
          this.originalHostingEnvList.forEach(env => {
            let index = env.subEnvs.findIndex(obj1 => obj1.id  == obj);
            if(index > -1 && event.value.length>0) {
              event.value.push(obj);
            } else  {//Unchecked cond1
                let uncheckedElementName = event.source.options['_results'][0]['value'];
                let unchekcedStatus= event.source.options['_results'][0]['_selected'];
                if(!unchekcedStatus)
                {
                  if(obj ==uncheckedElementName) { // Need to add more conditions as per geeric use eg applicationName
                      let index = this.previousSelectedHostingEnvs.findIndex( obj => obj == uncheckedElementName);
                      if(index > -1) {
                        this.previousSelectedHostingEnvs.splice(index,1);
                      }
                    }    
                }
            }
          })
          
        });
        if(event.value.length==0) {//Unchecked cond2
          for(let i=0;i<this.previousSelectedHostingEnvs.length;i++)
          {
            this.originalHostingEnvList.forEach(env => {
              let index = env.subEnvs.findIndex(obj1 => obj1.id  == this.previousSelectedHostingEnvs[i]);
              if(index > -1) {
                event.value.push(this.previousSelectedHostingEnvs[i]);
              }
            })
            
          }
        }   
      }
    }
    this.checkGrpCheckBox();
    this.previousSelectedHostingEnvs = event.value;
  }

  checkGrpCheckBox() {
    this.originalHostingEnvList.forEach(obj => {
      let values = this.hostingEnvs.value;
      let subEnvIds = obj.subEnvs.map(o => o.id);
      let allSelected = subEnvIds.every(a => values.includes(a));
      let selectedSubEnvs = subEnvIds.filter(env => {
        if(values.includes(env)) {
          return true;
        }
      })
      let indeterminate = selectedSubEnvs.length > 0 && selectedSubEnvs.length != subEnvIds.length ? true : false;
      obj.selected = allSelected;
      obj.indeterminate = indeterminate;
    });
  }
}
