import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { SidenavService, SharedService } from 'src/app/shared';
import { SharedAuthService } from 'src/app/shared/shared.auth.service';
import { AppHubService } from 'src/app/techopsv4/application-hub/app-hub.service';
import { WorkQueuesService } from 'src/app/techopsv4/services/workQueuesService';
import { AppHostingEnvDropdownModel, AppHostingEnvL1Model } from 'src/app/shared/elements/work-queue-popup/attributes-dropdown/attributes-dropdown.component';
import { DatePipe } from '@angular/common';
import { ConstantsData } from 'src/app/constants/fields';
import { AppViewService } from 'src/app/padu-reporting/application-view/app-view.service';
import { AppHubService as AppHubV4Service } from 'src/app/techopsv4/services/appHubV4Service';

export interface AttributeDropdownObj {
  Id: string,
  Name: string
}

export interface AppHostingPayloadModel {
  L0_ID: string;
  L1_ID: string;
}

@Component({
  selector: 'app-work-queue-popup',
  templateUrl: './work-queue-popup.component.html',
  styleUrls: ['./work-queue-popup.component.css']
})
export class WorkQueuesPopUpComponent implements OnInit {
  public searchLobValue: any;
  public searchSubLobValue: any;
  public showSubLobError: boolean;
  public selectedLOB: any = {};
  public selectedSUBLOB: any;
  public lobItemList = [];
  public subLobItemList = [];
  @Input() selectedLobObj: any ;
  public showLoaderSaveLobDetails: boolean = false;
  public appTypeDropdownValues: AttributeDropdownObj[] = [];
  public categoryDropdownValues: AttributeDropdownObj[] = [];
  public selectedCategoryObj: any = {};
  public preSelectedCategoryObj: any = {};
  public selectedAppTypeObj: any = {};
  public preSelectedAppTypeObj : any = {};
  @Output('hidePopup') hidePopup  = new EventEmitter<any>();
  public hostingEnvLabel = "Application Hosting Environment";
  public appHostingEnvDropdownValues: any ;
  @Input() popUpData: any;
  public searchAppTypeTxt;
  public searchTxt;
  public searchTxtSubLob;
  public searchCatTxt;
  public selectedAppHostingEnvList: any[]= [];
  public preSelectedEnvsIdMap : Map<string,string[]> = new Map<string,string[]>();
  public updatedEnvsIdMap : Map<string,string[]> = new Map<string,string[]>();
  public hostingEnvList : AppHostingEnvDropdownModel[] = [];
  public allAppTypeSelected: boolean = false;
  public applicationDetail;
  public hostingEnvUpdated: boolean = false;
  public previousSelectedAppType: any[] = [];
  public valueUpdated : boolean = false; 
  public selectedOption = '';
  public lobChecked = false;
  public attributeChecked = false;
  public applicationId = '';
  @Input() workQueueTypes: any = [];
  public currentLobDetails = {
    'currentSubLob' : '',
    'currentSubLobId' : '',
    'currentLob' : '',
    'currentLobId' : ''
  }
  public proposedLOB: any;
  public proposedSubLOB: any;
  @Input() fromAppHubEdit = false;
  // fromAppHubEdit =  false;
  @Input() fromWorkQueue = false;
  public workQueueSelected: any = [];
  public searchTxtWorkQueue = '';
  public appSearch = '';
  subject = new Subject<string>();
  destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public appList = [];
  // @Input() userAccessData;
  userAccessData: any;
  lobApproval: any;
  subLobApproval: any;
  approvalMessage = '';
  showApprovalMessage = false;
  appListDefault = [];
  justificationLobUpdate = '';
  disableApplyLob = true;
  approvalFrom: any;
  @Output('requestGenerated') requestGenerated  = new EventEmitter<any>();
  @Output('WQrequestGenerative') WQrequestGenerative = new EventEmitter<any>();
  @Output('showWQList') showWQList = new EventEmitter<any>();
  appHostingflag: boolean = false;
  appCategoryflag: boolean= false;
  appTypeflag: boolean= false;
  showLoader: boolean = false;
  disableLobRadioBtn: boolean = false;
  @Input() openRequestId = '';
  showOpenRequestMessage: boolean = false;
  disableJustificationMsg: boolean;
  disableProposedLob: boolean;
  attributeDataLoaded = false;
  @Output('showRequestDetails') showRequestDetails = new EventEmitter<any>();
  workQueueAccessDetailsLoaded = false;
  tempWorkQueueList: any;
  appHostingFlag: boolean = false;
  RoleAccessMessage = {
    "showRoleMessage": false,
    "Message": '',
    "Roles": ''
  };
  @Input() reloadOpenRequests = false;
  @Input() fromPADUEdit = false;
  @Input() paduWorkQueueDetails: any;
  openRequestArr:Array<any> = [];
  public paduOptions: any = [
    { label: 'Preferred', value: 'P' },
    { label: 'Acceptable', value: 'A' },
    { label: 'Discouraged', value: 'D' },
    { label: 'Unacceptable', value: 'U' }
  ];
  currentYear=new Date().getUTCFullYear();
  public replacementOptions: any = [];
  public YearsList: any[];
  public YearsListnxtyr: any[] = [
    {label:this.currentYear+1,value:this.currentYear+1},
    {label:this.currentYear+2,value:this.currentYear+2},
    {label:this.currentYear+3,value:this.currentYear+3},
    {label:this.currentYear+4,value:this.currentYear+4},
    {label:this.currentYear+5,value:this.currentYear+5},
    {label:this.currentYear+6,value:this.currentYear+6},
  ];
  public YearsListcurryr: any[] = [
    {label:this.currentYear,value:this.currentYear},
    {label:this.currentYear+1,value:this.currentYear+1},
    {label:this.currentYear+2,value:this.currentYear+2},
    {label:this.currentYear+3,value:this.currentYear+3},
    {label:this.currentYear+4,value:this.currentYear+4},
    {label:this.currentYear+5,value:this.currentYear+5},
    {label:this.currentYear+6,value:this.currentYear+6},
  ];
  quarter : any;
  transition: any[]=[];
  transitionEdit: any[]=[true,true,true,true,true,true,true];
  decomforcastEdit: any=true;
  PaduClasess = {
    "P": "paduandRoadMapEdit-global-badge-padu-p",
    "A": "paduandRoadMapEdit-global-badge-padu-a",
    "D": "paduandRoadMapEdit-global-badge-padu-d",
    "U": "paduandRoadMapEdit-global-badge-padu-u"
  }
  currentPADUEdit: any;
  transition_0_Edit: any;
  transition_1_Edit: any;
  transition_2_Edit: any;
  transition_3_Edit: any;
  transition_4_Edit: any;
  transition_5_Edit: any;
  transition_6_Edit: any;
  paduUpdated:any;
  currentPaduForAnApp = {
    currentPADU_1: '',
    currentPADU_2: '',
    currentPADU_3: '',
    currentPADU_4: '',
    currentPADU_5: '',
    currentPADU_6: '',
    currentPADU_7: '',
    currentDecomQtr: '',
    currentDecomYear: '',
  }

  @ViewChild('paduSelectYearWiseSecond') paduSelectYearWiseSecond;
  @ViewChild('paduSelectYearWiseThird') paduSelectYearWiseThird;
  @ViewChild('paduSelectYearWiseForth') paduSelectYearWiseForth;
  @ViewChild('paduSelectYearWiseFifth') paduSelectYearWiseFifth;
  @ViewChild('paduSelectYearWiseSixth') paduSelectYearWiseSixth;
  @ViewChild('paduSelectYearWise') paduSelectYearWise;
  @ViewChild('paduSelect') paduSelect;
  RoadMapEditButtonStatus: boolean = true;
  PADUFullName = ConstantsData.PADUFullName;
  justificationPaduUpdate = '';
  public QuarterList: any = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' },
  ];
  paduData:any;
  decomforcastQurtr_ui = '';
  decomforcastYear_ui:any = '';
  public indexOfColumn = {
    'current': 'current',
    'transition0': 0,
    'transition1': 1,
    'transition2': 2,
    'transition3': 3,
    'transition4': 4,
    'transition5': 5
  };
  public columnToKey = {
    0: 'agg_paduT1_v1',
    1: 'agg_paduT2_v1',
    2: 'agg_paduT3_v1',
    3: 'agg_paduT4_v1',
    4: 'agg_paduT5_v1',
    5: 'agg_paduT6_v1'
  }
  PADUShortName = ConstantsData.PADUShortName;
  exsistedPADU:any;
  PaduPending:any = [];
  paduUpdatedTransition:any = [];
  paduUpdatedColumn:any;
  PADUUpdateAccessDetails:any;
  role:any;
  isAdmin:any;
  requestDetails = {};
  subLobId;
  workQueueAccessDataLoaded: boolean = false;
  disablePADUFields = false;
  decomForecastByFormatted: string;
  showPADUFields = false;
  roadmapOptions: any = ['Simplification Target', 'Accelerated Decom Opportunity', 'Ongoing Decom Effort'];
  roadmapData = {
    'current': '',
    'proposed': '',
    'required': false,
    'disabled': true,
  };
  showPaduUpdateInfo:string = '';
  @Input() fromAssociateCapabilities = false;
  @Input() selectedApp:any;
  @Input() selectedWorqueue:any;
  @Input() isEditWorkflow = false;
  @Input() capabilityData:any;
  isWQRequestViewAccess:boolean = true;
  selectedsublobId : any;
  
  constructor(
    private appHubService: AppHubService,
    private navService: SidenavService,
    private dialog: MatDialog,
    public toaster: ToastrService,
    public sharedService: SharedService,
    public sharedAuthService: SharedAuthService,
    public WorkQueuesService: WorkQueuesService,
    public datePipe: DatePipe,
    private service: AppViewService,
    public appHubV4Service: AppHubV4Service,
  ) {
    this.searchLobValue = { key: 'lob', value: '' };
    this.searchSubLobValue = { key: 'sub_lob', value: '' }
    this.userAccessData = JSON.parse(localStorage.getItem('accessData'));
  }

  ngOnInit(): void {
    this.getLOBList();
    this.subject.pipe(
      debounceTime(500),
      switchMap(() => {
        return this.appHubService.getAppListForEdit(0, this.appSearch)
      })
    ).pipe(takeUntil(this.destroy$))
      .subscribe(searchObj => {
        this.appList = searchObj.result;
      });
  }

  ngOnChanges() {
    if(this.fromAssociateCapabilities){
      this.workQueueSelected = this.selectedWorqueue;
    }
    this.quarter = Math.floor((new Date().getMonth() + 3) / 3);
    this.showLoader = true;
    this.appHostingFlag = false;
    this.userAccessData = JSON.parse(localStorage.getItem('accessData'));
    this.showApprovalMessage = false;
    this.transition = [];
    if(this.fromAppHubEdit) {
      this.setDefaultValue();
      this.applicationDetail = this.popUpData["applicationDetail"];
      this.valueUpdated = false;
      this.searchAppTypeTxt = "";
      this.selectedOption = '';
      this.attributeChecked = false;
      this.lobChecked = false;
      this.applicationId = this.popUpData["applicationDetail"]["app_id"];
      this.preSelectedEnvsIdMap = new Map();
      if (this.workQueueAccessDetailsLoaded){
        // this.checkOpenRequests();
        this.loadLobDetails('reload', this.applicationId);
      }
      else{
        this.checkWorkQueueAccess();
      }  
      this.getLOBList();
      this.resetLobDetails();
    }
    if (this.fromWorkQueue){
      if (this.reloadOpenRequests){
        if (this.workQueueSelected['Id'] == 1){
          this.loadLobDetails('reload', this.applicationId);
        }
        else if (this.workQueueSelected['Id'] == 4){
          this.checkOpenRequests();
          this.getExsistedPaduDataForAppId();
        }
      }
      else{
        this.showLoader = true;
        this.tempWorkQueueList = this.workQueueTypes;
        if (!this.appListDefault.length){
          this.appHubService.getAppListForEdit(0, 'UHC').subscribe(data => {
            this.appListDefault = data.result;
          });
        }
        this.showLoader = false;
        // console.log('workQueueTypeDetailsForCreate', this.workQueueTypeDetailsForCreate);
        this.workQueueSelected = '';
        this.resetLobDetails();
        this.justificationPaduUpdate = '';
      }
      // this.workQueueTypes = this.workQueueTypeDetailsForCreate;
    }
    if (this.fromPADUEdit){
      this.workQueueSelected = this.paduWorkQueueDetails;
      this.applicationId = this.paduWorkQueueDetails['app_id'];
      this.showLoader = false;
      this.showOpenRequestMessage = this.openRequestId != '' ? true : false;
      this.showPaduUpdateInfo = '';
      this.getExsistedPaduDataForAppId();
    }
    const accessData = JSON.parse(localStorage.getItem('accessData'));
    if (accessData){
      this.role = accessData["Role"];
      this.subLobId = accessData.subLobId;
    }
    this.isAdmin = JSON.parse(localStorage.getItem('isAdminRole'));
  }

  setDefaultValue() {
    if(this.selectedCategoryObj) {
      if(this.preSelectedCategoryObj.Name != this.selectedCategoryObj.Name) {
        this.selectedCategoryObj = this.preSelectedCategoryObj;
        this.valueUpdated = false;
      }
    }
    if(this.selectedAppTypeObj) {
      if(this.selectedAppTypeObj.Name != this.preSelectedAppTypeObj.Name) {
        this.selectedAppTypeObj = this.preSelectedAppTypeObj;
        this.valueUpdated = false;
      }
    }
    this.configureSelectedAppHostingEnv();
    // for(let item of this.updatedEnvsIdMap) {
    //   for(let item1 of this.preSelectedEnvsIdMap) {
    //     if(item[0] === item1[0]) {
    //       this.appHostingFlag = false;
    //       checkKey = true;
    //     } 
    //   }
    //   // console.log('item', key);
    // }
    // if(checkKey) {
    //   this.updatedEnvsIdMap = this.preSelectedEnvsIdMap;
    //   this.selectedAppHostingEnvList = [];
    //   this.selectedAppHostingEnvList = this.popUpData["applicationHostingEnv"];
    // }
  }

  disableApplyBtn() {
    if((this.popUpData['selectedCategoty'] == this.selectedCategoryObj['Name']) &&
      ((this.popUpData['applicationType'] == 'No Mapping' ? null : this.popUpData['applicationType']) == this.selectedAppTypeObj['Name']) && 
      !this.appHostingFlag) {
      return true;
    } else {
      return false;
    }

  }

  checkWorkQueueAccess(){
    this.workQueueAccessDetailsLoaded = true;
    this.WorkQueuesService.getWorkQueueTypesAndAccessDetails().subscribe(data => {
      if (data){
        let workQueueTypes = data.filter(workQueue => {
          if (workQueue.Id === 1 && workQueue.Create_new_Drop_Down === 'Yes'){
            if (workQueue.Create_Req_Roles.split(',').includes(this.sharedAuthService.getAuthorizationForEA())){
              return workQueue;
            }
            else{
              this.RoleAccessMessage['Roles'] = workQueue.Create_Req_Roles;
              this.RoleAccessMessage['showRoleMessage'] = true;
              this.RoleAccessMessage['Message'] = 'An LOB Update Request can be created by ' + this.RoleAccessMessage['Roles'] + ' only';
            }
          }
        })
        this.workQueueSelected = workQueueTypes.length > 0 ? workQueueTypes[0]: [];
        if (!this.workQueueSelected["Id"]) {
          this.disableLobRadioBtn = true;
          this.showLoader = false;
        }
        this.checkOpenRequests();
      }
    });
    
    
  }

  checkOpenRequests(){
    if (this.fromAppHubEdit){
      if (this.openRequestId == ''){
        this.showOpenRequestMessage = false;
        this.disableLobRadioBtn = false;
        if (!this.RoleAccessMessage['showRoleMessage']){
          this.lobChecked = true;
          this.selectedOption = 'lob';
        }
        else{
          this.lobChecked = false;
          this.selectedOption = '';
        }
      } 
      else{
        this.showOpenRequestMessage = true;
        this.disableLobRadioBtn = true;
      }
      this.showLoader = false;
    }
    else if (this.fromWorkQueue){
      this.openRequestId = '';
      this.WorkQueuesService.getOpenRequestDetailsForAnApplication({"Application_Id": this.applicationId}).subscribe(data => {
        const lobOpenRequests = data.filter(req => {
          if (((req.Work_queue_Id == 1 || req.Work_queue_Id == 2) && this.workQueueSelected.Id == 1) || (this.workQueueSelected.Id == 4 && req.Work_queue_Id == 4)){
            return req;
          }
        })
        this.openRequestId = lobOpenRequests[0] ? lobOpenRequests[0]['Request_Id'] : '';
        this.disableJustificationMsg = this.openRequestId != '' ? true : false;
        this.disableProposedLob = this.openRequestId != '' ? true : false;
        this.showOpenRequestMessage = this.openRequestId != '' ? true : false;
      });
      this.showLoader = false;
    }
  }

  searchLobByName(event) {
    this.searchLobValue = { key: 'lob', value: event.target.value };
  }

  searchWorkQueueByName() {
    this.workQueueTypes = this.tempWorkQueueList.filter(x => x.Work_Queue_Name.toString().toLowerCase().indexOf(this.searchTxtWorkQueue.toLowerCase()) > -1);
  }

  onSubLobChanged(event) {
    this.showSubLobError = false;
    if (this.lobApproval.id != 1){
      this.subLobApproval = this.appHubService.checkLobSubLobRequestApproval(this.workQueueSelected['Approve_Req_Roles']?.split(','), this.sharedAuthService.getAuthorizationForEA(), this.userAccessData.subLobId, this.currentLobDetails.currentSubLobId, event["value"]["SubLobID"]);
      this.approvalMessage = this.subLobApproval.message;
      this.showApprovalMessage = true;
      this.approvalFrom = this.subLobApproval.approvalFrom;
    }
    this.disableApplyForLob();
    // this.valueUpdated = true;
  }

  public onLobChanged(event) {
    this.searchTxtSubLob = '';
    this.subLobItemList = event.value.subLob;
    this.proposedSubLOB = "";
    this.showSubLobError = false;
    this.disableApplyLob =  true;
    this.lobApproval = this.appHubService.checkLobSubLobRequestApproval(this.workQueueSelected['Approve_Req_Roles']?.split(','), this.sharedAuthService.getAuthorizationForEA(), this.userAccessData.lobId, this.currentLobDetails.currentLobId, event["value"]["lob_id"]);
    this.approvalMessage = this.lobApproval.message;
    this.showApprovalMessage = this.lobApproval['id'] == 1 ? true : false;
    this.approvalFrom = this.lobApproval.approvalFrom;
  }

  getLOBList() {
    this.showLoaderSaveLobDetails = true;
    const lobList$ = this.navService.getLobSubLobList();
    lobList$.subscribe(listsLob => {
      if (listsLob && listsLob.length > 0) {
        this.lobItemList = listsLob;
        this.sendLobDataToSubjects();
        this.showLoaderSaveLobDetails = false;
      }
    });
  }

  sendLobDataToSubjects() {
    const updateEventObj = {
      selectedLOB: this.proposedLOB,
      selectedSUBLOB: this.proposedSubLOB
    }
    this.appHubService.lobChangeSubject.next(updateEventObj)
  }

  getDropdownValues() {
  if (!this.attributeDataLoaded){
    this.attributeDataLoaded = true;
    this.appHubService.getAppDemographicDropdownValues().subscribe({
      next: res => {
        let Non_heirarchyAttributes : any[] = res.Non_heirarchyAttributes ? res.Non_heirarchyAttributes : [];
        let Master : any[] = res.Master ? res.Master : [];
        if(Non_heirarchyAttributes.length > 0) {
          let appTypes : any[] = Non_heirarchyAttributes.filter(obj => obj.attribute == 'Application Type');
          let categories : any[] = Non_heirarchyAttributes.filter(obj => obj.attribute == 'Category');
          if(appTypes.length > 0) {
            this.appTypeDropdownValues = appTypes.filter(obj => obj.Edit_dropdown == 'Yes');
            if(this.popUpData.applicationType && this.popUpData.applicationType.length > 0) {
              this.selectedAppTypeObj = this.appTypeDropdownValues.filter(obj => obj.Name == this.popUpData.applicationType)[0] || {};
              this.preSelectedAppTypeObj = this.appTypeDropdownValues.filter(obj =>  obj.Name == this.popUpData.applicationType)[0] || {};
              // if(this.appTypeDropdownValues.length > 0 && this.selectedAppTypeObj.length == this.appTypeDropdownValues.length) {
              //   this.allAppTypeSelected = true;
              // }
            }
          }
          if(categories.length > 0 ) {
            this.categoryDropdownValues = categories.filter(obj => obj.Edit_dropdown == 'Yes');
            this.selectedCategoryObj = this.categoryDropdownValues.find(item => item.Name == this.popUpData.selectedCategoty) 
                          ? this.categoryDropdownValues.find(item => item.Name == this.popUpData.selectedCategoty) : {};
            this.preSelectedCategoryObj = this.categoryDropdownValues.find(item => item.Name == this.popUpData.selectedCategoty)
                          ? this.categoryDropdownValues.find(item => item.Name == this.popUpData.selectedCategoty) : {};
            this.previousSelectedAppType = this.preSelectedAppTypeObj;
          }
        }
        for(const ele of Master){
          if(ele.Attribute_Id == 2) 
          {
              this.appTypeflag = true;
          }
          if(ele.Attribute_Id == 1)
          {
            this.appCategoryflag = true;
          }
          if(ele.Attribute_Id == 3)
          {
            this.appHostingflag = true;
          }
        } 
        // console.log(this.appCategoryflag,this.appTypeflag,this.appHostingflag);
        if(res.Application_Hosting && Object.keys(res.Application_Hosting).length > 0 ) {
          this.appHostingEnvDropdownValues = res.Application_Hosting;
          this.configureSelectedAppHostingEnv();
        }
      },
      error: () => {
        console.log("Error in getting data !!")
      }
    })
  }
    
  }

  onCategoryChanged() {
    if(this.popUpData.selectedCategoty == this.selectedCategoryObj.Name) {
      this.valueUpdated = false;
    } else {
      this.valueUpdated = true;
    }
    // if(this.selectedSUBLOB != null && this.selectedSUBLOB.length > 0)
  }

  saveChangesForAttributes() {
    let apiPayload = {
      "createdById": localStorage.getItem('MsId'),
      "createdByName": JSON.parse(localStorage.getItem('currentUser')),
      "app_id": this.applicationDetail.app_id
    };

    apiPayload['oldCategoryId'] = this.preSelectedCategoryObj.Id ? this.preSelectedCategoryObj.Id : null;
    apiPayload['newCategoryId'] = this.selectedCategoryObj.Id ? this.selectedCategoryObj.Id : null;

    let envHostingPayload = this.createHostingEnvPayload();
    apiPayload['finalDeletedValueAppHosting'] = envHostingPayload['finalDeletedValueAppHosting'] ? envHostingPayload['finalDeletedValueAppHosting'] : [];
    apiPayload['AddDataAppHosting'] = envHostingPayload['AddDataAppHosting'] ? envHostingPayload['AddDataAppHosting'] : [];
    
    let appTypePayload = this.createAppTypePayload();
    apiPayload['AddDataAppType'] = appTypePayload['AddDataAppType'] ? appTypePayload['AddDataAppType'] : [];
    apiPayload['finalDeletedValueAppType'] = appTypePayload['finalDeletedValueAppType'] ? appTypePayload['finalDeletedValueAppType'] : [];

    // console.log(JSON.stringify(apiPayload));
    this.appHubService.updateDemographicDetails(apiPayload).subscribe(() => {
      this.hostingEnvUpdated = false;
      this.sendLobDataToSubjects();
      this.toaster.success("Successfully updated.", '', { timeOut: 5000, positionClass: 'toast-top-center' });
      this.hidePopup.emit('attributes');
      this.attributeDataLoaded = false;
    }, (err) => {
      console.log(err, "error occured while update")
      this.showLoaderSaveLobDetails = false;

      this.toaster.error("Internal server error.", '', { timeOut: 5000, positionClass: 'toast-top-center' });
    });
    
  }

  onAppTypeChanged() {
    if(this.popUpData.applicationType == this.selectedAppTypeObj.Name) {
      this.valueUpdated = false;
    } else {  
      this.valueUpdated = true;
    }
    // if(this.selectedSUBLOB != null && this.selectedSUBLOB.length > 0)
    // if(this.searchAppTypeTxt) {
    //   if(this.previousSelectedAppType) {
    //     this.previousSelectedAppType.forEach(obj => {
    //       if(this.appTypeDropdownValues.includes(obj) && event.value.length>0) {
    //         event.value.push(obj);
    //       } else  {//Unchecked cond1
    //           let uncheckedElementName = event.source.options['_results'][0]['_mostRecentViewValue'];
    //           let unchekcedStatus= event.source.options['_results'][0]['_selected'];
    //           if(!unchekcedStatus)
    //           {
    //             if(obj['Name']==uncheckedElementName) { // Need to add more conditions as per geeric use eg applicationName
    //                 let index = this.previousSelectedAppType.findIndex( obj => obj.Name == uncheckedElementName);
    //                 if(index > -1) {
    //                   this.previousSelectedAppType.splice(index,1);
    //                 }
    //               }    
    //           }
    //       }
    //     });
    //     if(event.value.length==0) {//Unchecked cond2
    //       for(let i=0;i<this.previousSelectedAppType.length;i++)
    //       {
    //         if(this.appTypeDropdownValues.includes(this.previousSelectedAppType[i]))
    //         {
    //           event.value.push(this.previousSelectedAppType[i]);
    //         }
    //       }
    //     }   
    //   }
    // }
    
    // if (this.selectedAppTypeObj.length == this.appTypeDropdownValues.length) {
    //   this.allAppTypeSelected = true;
    // } else {
    //   this.allAppTypeSelected = false;
    // }
    // this.previousSelectedAppType = event.value;
  }

  configureSelectedAppHostingEnv() {
    this.hostingEnvList = [];
    if(this.appHostingEnvDropdownValues && Object.keys(this.appHostingEnvDropdownValues).length > 0) {
      for(let key of Object.keys(this.appHostingEnvDropdownValues)) {
        let arr: any[] = this.appHostingEnvDropdownValues[key];
        let hostingEnvObj :  AppHostingEnvDropdownModel = {} as AppHostingEnvDropdownModel;
        let filtredArray = arr.filter(obj => obj.Edit_dropdown == 'Yes');
        if(filtredArray && filtredArray.length > 0) {
          let hostingEnvL1List: AppHostingEnvL1Model[] = [];
          filtredArray.forEach(obj => {
            if (!hostingEnvObj.id && !hostingEnvObj.l0Name) {
              hostingEnvObj.id = obj['L0'];
              hostingEnvObj.l0Name = obj['L0_Name'];
            }
            let hostingEnvL1Obj: AppHostingEnvL1Model = {} as AppHostingEnvL1Model;
            hostingEnvL1Obj.id = obj['L1'];
            hostingEnvL1Obj.l1Name = obj['L1_Name'];
            hostingEnvL1List.push(hostingEnvL1Obj);
          });
          hostingEnvObj.subEnvs = hostingEnvL1List;
        }
        if(Object.keys(hostingEnvObj).length > 0 && hostingEnvObj.subEnvs.length > 0) {
          this.hostingEnvList.push(hostingEnvObj);
        }
      }
      
    }
    // console.log(this.hostingEnvList);
    this.configurePreSelectedData()
  }

  configurePreSelectedData() {
    let selectedL0: string[] = [];
    let selectedL1: string[] = [];
    this.selectedAppHostingEnvList.forEach(value => {
      let splitString = value.split("-");
      let l0 = splitString[0];
      if(l0 && !selectedL0.includes(l0.trim())) {
        selectedL0.push(l0.trim());
      }
      let l1 = splitString[1];
      if(l1 && !selectedL1.includes(l1.trim())) {
        selectedL1.push(l1.trim());
      }
    });

    this.hostingEnvList.forEach(env => {
      let preSelectedSubEnvIds : string[] = [];
      env.subEnvs.forEach(subEnv => {
        if(selectedL1.includes(subEnv.l1Name)) {
          preSelectedSubEnvIds.push(subEnv.id);
          
        }
      });
      if(preSelectedSubEnvIds.length > 0) this.preSelectedEnvsIdMap.set(env.id,preSelectedSubEnvIds);
    });
  }

  onHostingEnvUpdated(event) {
    if(event.updatedValues) {
      this.hostingEnvUpdated = true;
      // if(this.selectedSUBLOB != null && this.selectedSUBLOB.length > 0)
        this.valueUpdated = true;
      
      this.updatedEnvsIdMap = event.updatedValues;
      this.appHostingFlag = false;
      if (this.updatedEnvsIdMap.size != this.preSelectedEnvsIdMap.size) {
        this.appHostingFlag = true;
      }
      else{
        for (let [key] of this.updatedEnvsIdMap) {
          if (this.preSelectedEnvsIdMap.has(key)) {
            let updatedIds: string[] = this.updatedEnvsIdMap.get(key);
            let preSelectedIds: string[] = this.preSelectedEnvsIdMap.get(key);
            if (updatedIds.length != preSelectedIds.length) {
              this.appHostingFlag = true;
            }
            else{
              for (let i = 0; i < updatedIds.length; i++) {
                if (updatedIds[i] != preSelectedIds[i]) {
                  this.appHostingFlag = true;
                  break;
                }
              }
            }
          }
          else{
            this.appHostingFlag = true;
          }
        }
      }

      // for(let item of this.updatedEnvsIdMap) {
      //   console.log('this.updatedEnvsIdMap item', item);
      //   for(let item1 of this.preSelectedEnvsIdMap) {
      //     console.log('this.preSelectedEnvsIdMap item', item1);

      //     if(item[0] == item1[0] && item[1] == item1[1]) {
      //       this.appHostingFlag = false;
      //     } else {
      //       this.appHostingFlag = true;
      //     }
      //   }
        // console.log('item', key);
    }
  }

  createHostingEnvPayload() {
    let payload = {};
    let finalDeletedValueAppHosting : AppHostingPayloadModel[] = [];
    let AddDataAppHosting: AppHostingPayloadModel[] = [];
    if(this.updatedEnvsIdMap.size > 0) {
      //check if existing values are removed 
      this.preSelectedEnvsIdMap.forEach((value: string[], key: string) => {
        if (this.updatedEnvsIdMap.has(key)) {
          let updatedIds: string[] = this.updatedEnvsIdMap.get(key);
          value.forEach(obj => {
            if (!updatedIds.includes(obj)) {
              finalDeletedValueAppHosting.push({ L0_ID: key, L1_ID: obj });
            }
          })
        } else {
          value.forEach(obj => {
            finalDeletedValueAppHosting.push({ L0_ID: key, L1_ID: obj });
          })
        }
      });

      // check newly values are added
      this.updatedEnvsIdMap.forEach((value: string[], key: string) => {
        if (this.preSelectedEnvsIdMap.has(key)) {
          let updatedIds: string[] = this.preSelectedEnvsIdMap.get(key);
          value.forEach(obj => {
            if (!updatedIds.includes(obj)) {
              AddDataAppHosting.push({ L0_ID: key, L1_ID: obj });
            }
          })
        } else {
          value.forEach(obj => {
            AddDataAppHosting.push({ L0_ID: key, L1_ID: obj });
          })
        }
      });
    } else if(this.updatedEnvsIdMap.size == 0 && this.hostingEnvUpdated){ 
        this.preSelectedEnvsIdMap.forEach((value: string[], key: string) => {
          value.forEach(obj => {
            finalDeletedValueAppHosting.push({ L0_ID: key, L1_ID: obj });
          })
      });
    }
    
    payload['finalDeletedValueAppHosting'] = finalDeletedValueAppHosting;
    payload['AddDataAppHosting'] = AddDataAppHosting;
    return payload;
  }

  createAppTypePayload() {
    let appTypePayload = {};
    // let preSelectedAppTypeIds: string[] = this.preSelectedAppTypeObj.map(obj => obj.Id);
    // let selectedAppTypeIds : string[]  = this.selectedAppTypeObj.map(obj => obj.Id);

    let preSelectedAppTypeIds: string = this.preSelectedAppTypeObj && this.preSelectedAppTypeObj['Id'] 
                  ? this.preSelectedAppTypeObj['Id'] : null;
    let selectedAppTypeIds : string  = this.selectedAppTypeObj && this.selectedAppTypeObj['Id'] 
                  ? this.selectedAppTypeObj['Id'] : null;

    let finalDeletedValueAppType: any[] = [];
    let AddDataAppType: any[] = [];
    // preSelectedAppTypeIds.forEach(id => {
    //   if(!selectedAppTypeIds.includes(id)) {
    //     finalDeletedValueAppType.push({'AT_ID': id});
    //   }
    // });
    // selectedAppTypeIds.forEach(id => {
    //   if(!preSelectedAppTypeIds.includes(id)) {
    //     AddDataAppType.push({'AT_ID': id});
    //   }
    // });
    if(preSelectedAppTypeIds  != null) {
      finalDeletedValueAppType.push({'AT_ID': preSelectedAppTypeIds});
    }
    if(selectedAppTypeIds != null) {
      AddDataAppType.push({'AT_ID': selectedAppTypeIds});
    }
    appTypePayload['finalDeletedValueAppType'] = finalDeletedValueAppType;
    appTypePayload['AddDataAppType'] = AddDataAppType;
    return appTypePayload;
  }

  selectAllCategories(event) {
    if(event.checked) {
      this.allAppTypeSelected = true;
      this.selectedAppTypeObj = this.appTypeDropdownValues;
    } else {
      this.allAppTypeSelected = false;
      this.selectedAppTypeObj = [];
    }
  }
  selectionTypeChange(type){
    this.resetLobDetails();
    if (type.value == 'lob'){
      this.lobChecked = true;
      this.attributeChecked = false;
      this.selectedOption = 'lob';
    }
    else{
      this.selectedAppHostingEnvList = this.popUpData["applicationHostingEnv"];
      this.getDropdownValues();
      this.appHostingFlag = false;
      this.showOpenRequestMessage = false;
      this.showLoader = false;
      this.lobChecked = false;
      this.attributeChecked = true;
      this.selectedOption = 'attributes';
    }

  }
  onWorkQueueChanged(workQueue){
    this.appList = this.appListDefault;
    this.workQueueSelected = workQueue.value;
    this.resetLobDetails();
    if(workQueue.value['Id'] == 4) {
      this.getWorkQueueAccessDetails();
    }
  }

  searchApplication(event) {
    if (event.target.value.length >= 3) {
      this.appSearch = event.target.value
      this.subject.next(event.target.value);
    }
    else if (event.target.value.length == 0){
      this.appList = this.appListDefault;
      this.proposedLOB = '';
      this.proposedSubLOB = '';
    }
  }
  loadLobDetails(type, app){
    this.applicationId = type == 'select' ? app.value : this.applicationId;
    this.checkOpenRequests();
    if (this.workQueueSelected.Id == 4){
      this.getExsistedPaduDataForAppId();
    }
    this.appHubService.Mastercarddetails(this.applicationId).subscribe(data => {
      this.currentLobDetails.currentLob = data["cio_lob"];
      this.currentLobDetails.currentSubLob = data["lob"];
      this.currentLobDetails.currentSubLobId = data["SubLOB_ID"],
      this.currentLobDetails.currentLobId = data["LOB_ID"],
      this.proposedLOB = '';
      this.proposedSubLOB = '';
      this.justificationLobUpdate = '';
      this.disableApplyLob = true;
      this.showApprovalMessage = false;
      this.searchTxt = '';
      this.justificationPaduUpdate = '';
    })
  }
  saveChangesForLobUpdate(){
    let createdAtDate = new Date().toISOString();
    let payload = {
      "Application_Id": this.applicationId,
      "Current_LOB_ID": this.currentLobDetails.currentLobId,
      "Current_SubLOB_ID": this.currentLobDetails.currentSubLobId,
      "Proposed_LOB_ID": this.proposedLOB.lob_id,
      "Proposed_SubLOB_ID": this.proposedSubLOB.SubLobID,
      "Req_Justification": this.justificationLobUpdate,
      "Req_Status": "Open",
      "Req_generated_From": "TechopsUI",
      "created_at": createdAtDate,
      "created_by": JSON.parse(localStorage.getItem('currentUser')),
      "created_by_Id": localStorage.getItem('MsId'),
      "WQID": this.workQueueSelected.Id,
      "User_Role": this.userAccessData.Role,
      "Approval_From": this.approvalFrom
    };
    if (this.approvalFrom == 'Source' || this.approvalFrom == 'Destination'){
      payload['partial_Updated_from'] = this.approvalFrom == 'Source' ? 'Destination' : 'Source';
      payload['partial_updated_at'] = createdAtDate;
      payload['partial_Updated_by'] = JSON.parse(localStorage.getItem('currentUser'));
      payload['partial_Updated_comments'] = 'Auto approved as the requestor is ' + (this.approvalFrom == 'Destination' ? 'Current' : 'Proposed') + ' LOB Architect. Awaiting approval from ' + (this.approvalFrom == 'Destination' ? 'Proposed' : 'Current') + ' LOB Architect';
      payload['partial_Updated_by_Id'] = localStorage.getItem('MsId');
    }
    if (this.approvalFrom == 'Auto'){
      payload["Ap_OR_R_Justification"] = this.userAccessData['Role'] == 'Admin' ? 'Auto approved as the requestor is Admin' : 'Auto approved as the requestor is both Current and Proposed LOB Architect.';
    }
    // console.log(payload);
    this.WorkQueuesService.updateLobWorkQueueRequest(payload).subscribe(result => {
      if (result["Status"] === 'AutoApproved'){
        this.toaster.success('Request is Generated Successfully and Auto Approved.', '', { timeOut: 5000, positionClass: 'toast-top-center' });
        this.requestGenerated.emit(result);
      }
      else if (result["message"] === 'data inserted successfully'){
        this.toaster.success('Request is generated successfully.', '', { timeOut: 5000, positionClass: 'toast-top-center' });
        this.requestGenerated.emit(result);
      }
      else if(result["Status"] === "SequelizeUniqueConstraintError"){
        this.toaster.error('A Work Queue Request Already Exists for this Application.', '', { timeOut: 5000, positionClass: 'toast-top-center' });
      }
    }, () => {
      this.toaster.error('Internal Server Error', '', { timeOut: 5000, positionClass: 'toast-top-center' });
    });
    this.workQueueAccessDetailsLoaded = false;
  }

  disableApplyForLob(){
    if (!this.applicationId || !this.proposedSubLOB || !this.justificationLobUpdate.trim()){
      this.disableApplyLob =  true;
    }
    else{
      this.disableApplyLob =  false;
    }
  }
  resetLobDetails(){
    if (this.fromAppHubEdit){
      this.currentLobDetails.currentLob = this.popUpData["selectedLob"];
      this.currentLobDetails.currentSubLobId = this.popUpData["selectedSubLobId"],
      this.currentLobDetails.currentLobId = this.popUpData["selectedLobId"],
      this.currentLobDetails.currentSubLob = this.popUpData["selectedSubLob"];
      this.setDefaultValue();
    }
    else if (this.fromWorkQueue){
      this.applicationId = '';
      this.appList = this.appListDefault;
      this.currentLobDetails.currentLob = '';
      this.currentLobDetails.currentLobId = '';
      this.currentLobDetails.currentSubLob = '';
      this.currentLobDetails.currentSubLobId = '';
      this.showOpenRequestMessage = false;
    }
    this.proposedLOB = '';
    this.proposedSubLOB = '';
    this.justificationLobUpdate = '';
    this.showApprovalMessage = false;
    this.disableApplyLob =  true;
    this.appSearch = '';
    this.searchTxt = '';
    this.searchTxtSubLob = '';
  }
  navigateToRequestDetails(){
    if(this.workQueueSelected['Id'] == 5 && this.openRequestArr.length > 0){
        if(this.fromAssociateCapabilities){
          let ev = {};
          if(this.isWQRequestViewAccess){
            ev = {
              'message': 'show open work queue request',
              'requestIDArr': this.openRequestArr
            };
          }else{
            ev['message'] = 'show lob maintenance page';
          }
          this.showWQList.emit(ev);
        }else{
          let wqlist = this.openRequestArr;
          wqlist = wqlist.map(val=>val['Request_Id']);
          const baseUrl = window.location.origin;
          const urlparams = wqlist.join(',');
          let wqUrl = '';
          if(this.isWQRequestViewAccess){
            wqUrl = baseUrl + '/work-queues?req=wq&id=' + JSON.stringify(urlparams);
          }else{
            wqUrl = baseUrl + '/maintenanceV4/lob/?subLOBId=' + this.selectedsublobId;
          }
          window.open(wqUrl, '_blank');
        }
        // this.openRequestArr = [];
    }else{
      this.showRequestDetails.emit(this.openRequestId);
    }
  }

  navigateToOpenRequest(arr){
    if(arr.length > 0){
      this.openRequestArr = arr;
      this.showOpenRequestMessage = true;
    }else{
      this.openRequestArr = [];
      this.showOpenRequestMessage = false;
    }
  }

  //create new work request PADU Update//
  openPaduDropdown(Obj) {
    switch(Obj) {
      case "paduSelect":
      this.paduSelect.open();
      break;

      case "paduSelectYearWise":
      this.paduSelectYearWise.open();
      break;

      case "paduSelectYearWiseSecond":
      this.paduSelectYearWiseSecond.open();
      break;

      case "paduSelectYearWiseThird":
      this.paduSelectYearWiseThird.open();
      break;

      case "paduSelectYearWiseForth":
      this.paduSelectYearWiseForth.open();
      break;

      case "paduSelectYearWiseFifth":
      this.paduSelectYearWiseFifth.open();
      break;

      case "paduSelectYearWiseSixth":
      this.paduSelectYearWiseSixth.open();
      break;
      
      default: 
      break;
    }
  }
  modelChangedPadu(event, column, modelName) {
    console.log('PADU Change:',  column, event, this.getPADUFullName(this.transition[0]));
    if(modelName == 'transition[0]') {
      this.transition[0] = this.getPADUFullName(event);
    } else if(modelName == 'transition[1]') {
      this.transition[1] = this.getPADUFullName(event);
    } else if(modelName == 'transition[2]') {
      this.transition[2] = this.getPADUFullName(event);
    } else if(modelName == 'transition[3]') {
      this.transition[3] = this.getPADUFullName(event);
    } else if(modelName == 'transition[4]') {
      this.transition[4] = this.getPADUFullName(event);
    } else if(modelName == 'transition[5]') {
      this.transition[5] = this.getPADUFullName(event);
    } else if(modelName == 'transition[6]') {
      this.transition[6] = this.getPADUFullName(event);
    } 
    if ((event != column || column == '')) {
      this.RoadMapEditButtonStatus = false;
    }
    return;
  }
  /*-------------------------To get Padu Style Classes---------------------------- */
  getClassName(value) {
    if (this.PaduClasess[value]) {
      return this.PaduClasess[value];
    }
    return '';
  }
  getPADUFullName(padu){
    return this.PADUFullName[padu];
  }
  setEditEnable() {
    let k;
    if (this.currentPaduForAnApp['currentPADU_1'] === 'TBR'){
      k=0
    }
    else{
      for (let j = 1; j <= 6; j++) {
        if (this.transition[j] === 'TBR' ) {
          k=j
        }
      }
    }
          
    if (!(this.paduData.Decomm_Status === 'Not Marked For Decom')) {
      if (this.paduData.Decomm_Status === 'Decom Requested') {
        if (this.paduData.Decomm_Date) {
          let i;
          if (this.currentPaduForAnApp['currentPADU_1'] === 'TBR') {
            this.transitionEdit[0] = false
          }
          for (i = k; i <= 6; i++) {
            if (this.transition[i] === 'TBR' || this.transition[i] === 'R') {
              this.transitionEdit[i] = false
            }
          }
          this.decomforcastEdit = false
        }
        else {
          if (this.decomForecastByFormatted != ' ' && this.decomForecastByFormatted) {
            let i;
            if (this.currentPaduForAnApp['currentPADU_1'] === 'TBR' ) {
              this.transitionEdit[0] = false
            }
            for (i = k; i <= 6; i++) {
              if (this.transition[i] === 'TBR' || this.transition[i] === 'R') {
                this.transitionEdit[i] = false
              }
            }
          }
        }

      }
      else if (this.paduData.Decomm_Status === 'Decom Forecasted') {
        let i;
        if (this.currentPaduForAnApp['currentPADU_1'] === 'TBR' ) {
          this.transitionEdit[0] = false
        }
        for (i = k; i <= 6; i++) {
          if (this.transition[i] === 'TBR' || this.transition[i] === 'R') {
            this.transitionEdit[i] = false
          }
        }
      }
    }
    this.currentPADUEdit = this.transitionEdit[0]
    this.transition_1_Edit = this.transitionEdit[1];
    this.transition_2_Edit = this.transitionEdit[2];
    this.transition_3_Edit = this.transitionEdit[3];
    this.transition_4_Edit = this.transitionEdit[4];
    this.transition_5_Edit = this.transitionEdit[5];
    this.transition_6_Edit = this.transitionEdit[6];
  }
  getExsistedPaduDataForAppId() {
    this.transition = [];
    this.showPADUFields = false;
    this.showPaduUpdateInfo = '';
    this.appHubV4Service.fetchAppPaduAndRoadmap(this.applicationId).subscribe((result:any) => {
      // console.log('PADU Data:', result);
      this.exsistedPADU = result?.existsData;
      this.paduData = result;
      this.decomForecastByFormatted = this.paduData.decom_forcast_by != null ? this.paduData.decom_forcast_by : ' ';
      this.decomforcastQurtr_ui = this.paduData.decom_forcast_by != null ? this.paduData.decom_forcast_by.split(' ')[0] : '';
      this.decomforcastYear_ui = this.paduData.decom_forcast_by != null ? parseInt(this.paduData.decom_forcast_by.split(' ')[1]) : '';
      this.getcurryearlist(this.decomforcastQurtr_ui);
      this.currentPaduForAnApp = {
        currentPADU_1: this.paduData.lowest_padu.current,
        currentPADU_2: this.paduData.lowest_padu.transition_1,
        currentPADU_3: this.paduData.lowest_padu.transition_2,
        currentPADU_4: this.paduData.lowest_padu.transition_3,
        currentPADU_5: this.paduData.lowest_padu.transition_4,
        currentPADU_6: this.paduData.lowest_padu.transition_5,
        currentPADU_7: this.paduData.lowest_padu.transition_6,
        currentDecomQtr: this.decomforcastQurtr_ui,
        currentDecomYear: this.decomforcastYear_ui,
      };
      this.transition[0] = this.paduData.lowest_padu.current;
      this.transition[1] = this.paduData.lowest_padu.transition_1;
      this.transition[2] = this.paduData.lowest_padu.transition_2;
      this.transition[3] = this.paduData.lowest_padu.transition_3;
      this.transition[4] = this.paduData.lowest_padu.transition_4;
      this.transition[5] = this.paduData.lowest_padu.transition_5;
      this.transition[6] = this.paduData.lowest_padu.transition_6;
      this.transitionEdit=[true,true,true,true,true,true,true];
      this.setEditEnable();
      this.showPADUFields = true;
      this.roadmapData['current'] = this.paduData['RoadMap'];
      this.roadmapData['proposed'] = this.paduData['RoadMap'];
      this.checkIfRoadmapIsRequired();
    })

  }
  modelChanged(event, text) {
    if (text == 'decomforcastQurtr_ui'){
      this.decomforcastYear_ui = '';
      // if(event.value && text) {
      this.decomforcastQurtr_ui = event.value;
      this.getcurryearlist(this.decomforcastQurtr_ui);
      // }
    }
    else if (text == 'decomforcastYear_ui'){
      this.decomforcastYear_ui = event.value;
    }
    this.checkIfRoadmapIsRequired();
  }
  checkIfRoadmapIsRequired(){
    if (this.decomforcastQurtr_ui && this.decomforcastYear_ui && 
        ((this.decomforcastQurtr_ui != this.currentPaduForAnApp['currentDecomQtr'] || this.decomforcastYear_ui != this.currentPaduForAnApp['currentDecomYear']) 
        || !this.roadmapOptions.includes(this.roadmapData['proposed']))) {
      this.roadmapData['disabled'] = false;
      if (this.checkPADUUpdateAccess()){
        this.roadmapData['required'] = true;
      }
    }
    else{
      this.roadmapData['proposed'] = this.roadmapData['current'];
      this.roadmapData['disabled'] = true;
      this.roadmapData['required'] = false;
    }
    this.roadmapData['proposed'] = (!this.decomforcastQurtr_ui && !this.decomforcastYear_ui) ? 'No Mapping' : this.roadmapData['current'];
  }
  getcurryearlist(currqtr){
    currqtr = currqtr.charAt(1);
    currqtr = parseInt(currqtr);
    if(currqtr>=this.quarter)
    {
      this.YearsList = this.YearsListcurryr;
    }
    else{
      this.YearsList = this.YearsListnxtyr;
    }
  }
  toggleDisabledApplyBtn() {
    let transitionDisable:boolean = this.transition[0] == this.currentPaduForAnApp['currentPADU_1'] && this.transition[1] == this.currentPaduForAnApp['currentPADU_2'] && this.transition[2] == this.currentPaduForAnApp['currentPADU_3'] 
    && this.transition[3] == this.currentPaduForAnApp['currentPADU_4'] && this.transition[4] == this.currentPaduForAnApp['currentPADU_5'] && this.transition[5] == this.currentPaduForAnApp['currentPADU_6'] && 
    this.transition[6] == this.currentPaduForAnApp['currentPADU_7'] && this.decomforcastQurtr_ui == this.currentPaduForAnApp['currentDecomQtr'] && this.decomforcastYear_ui == this.currentPaduForAnApp['currentDecomYear'];
    if (this.paduData.Decomm_Status && !(this.paduData.Decomm_Status.toLowerCase() == 'decom requested' && this.paduData.Decomm_Date != null)){
      if ((this.decomforcastYear_ui != '' && this.decomforcastQurtr_ui == '') || (this.decomforcastYear_ui == '' && this.decomforcastQurtr_ui != '')){
        transitionDisable = true;
      }
      if ((this.decomforcastYear_ui != '' && this.decomforcastQurtr_ui != '') && (this.roadmapData['required'] && this.roadmapOptions.includes(this.roadmapData['proposed']) != true)){
        transitionDisable = true;
      }
    }
    transitionDisable = transitionDisable != true ? (this.justificationPaduUpdate.trim() == '' ? true : false) : true;

    return transitionDisable;
  }
  updatedPaduValues(){
    this.paduUpdatedTransition['Agg PADU_v1'] = this.transition[0] === this.currentPaduForAnApp['currentPADU_1']? 'Same as DB' : this.getPADUFullName(this.transition[0]);
    this.paduUpdatedTransition['Agg PADU T1_v1'] = this.transition[1] === this.currentPaduForAnApp['currentPADU_2'] ? 'Same as DB' : this.getPADUFullName(this.transition[1]);
    this.paduUpdatedTransition['Agg PADU T2_v1'] = this.transition[2] === this.currentPaduForAnApp['currentPADU_3']? 'Same as DB' : this.getPADUFullName(this.transition[2]);
    this.paduUpdatedTransition['Agg PADU T3_v1'] = this.transition[3] === this.currentPaduForAnApp['currentPADU_4']? 'Same as DB' : this.getPADUFullName(this.transition[3]);
    this.paduUpdatedTransition['Agg PADU T4_v1'] = this.transition[4] === this.currentPaduForAnApp['currentPADU_5'] ? 'Same as DB' : this.getPADUFullName(this.transition[4]);
    this.paduUpdatedTransition['Agg PADU T5_v1'] = this.transition[5] === this.currentPaduForAnApp['currentPADU_6'] ? 'Same as DB' : this.getPADUFullName(this.transition[5]);
    this.paduUpdatedTransition['Agg PADU T6_v1'] = this.transition[6] === this.currentPaduForAnApp['currentPADU_7']? 'Same as DB' : this.getPADUFullName(this.transition[6]);
    this.paduUpdatedTransition['Decom Forecats QTR'] = this.decomforcastQurtr_ui === this.currentPaduForAnApp['currentDecomQtr'] ? 'Same as DB' : this.decomforcastQurtr_ui === "" ? null :this.decomforcastQurtr_ui;
    this.paduUpdatedTransition['Decom Forecats Year'] = this.decomforcastYear_ui === this.currentPaduForAnApp['currentDecomYear'] ? 'Same as DB' : this.decomforcastYear_ui === "" ? null :this.decomforcastYear_ui;
    let {updatedValues, updatedColumns} = this.formatUpdatedValuesPayload(this.paduUpdatedTransition);
    this.paduUpdatedTransition = updatedValues;
    this.paduUpdatedColumn= updatedColumns;
  }
  saveChangesForPaduUpdate(){
    let createdAtDate = new Date().toISOString();
    let proposedPADU = this.getPADUFullName(this.transition[0]) + ',' + this.getPADUFullName(this.transition[1]) + ',' + 
                       this.getPADUFullName(this.transition[2]) + ',' + this.getPADUFullName(this.transition[3]) + ',' + 
                       this.getPADUFullName(this.transition[4]) + ',' + this.getPADUFullName(this.transition[5]) + ',' + 
                       this.getPADUFullName(this.transition[6]) + `,${this.decomforcastQurtr_ui},${this.decomforcastYear_ui}`;
    let currentPADU = this.getPADUFullName(this.currentPaduForAnApp['currentPADU_1']) + ',' + this.getPADUFullName(this.currentPaduForAnApp['currentPADU_2']) + ',' + 
                      this.getPADUFullName(this.currentPaduForAnApp['currentPADU_3']) + ',' + this.getPADUFullName(this.currentPaduForAnApp['currentPADU_4']) + ',' + 
                      this.getPADUFullName(this.currentPaduForAnApp['currentPADU_5']) + ',' + this.getPADUFullName(this.currentPaduForAnApp['currentPADU_6']) + ',' + 
                      this.getPADUFullName(this.currentPaduForAnApp['currentPADU_7']) + `,${this.currentPaduForAnApp['currentDecomQtr']},${this.currentPaduForAnApp['currentDecomYear']}`;
    this.updatedPaduValues();
    let paduUpdatePayload= { 
      "Current_PADU": currentPADU,
      "Proposed_PADU": proposedPADU,
      "Req_Justification": this.justificationPaduUpdate,
      "Req_generated_From": "TechopsUI",
      "created_at": createdAtDate,
      "created_by": JSON.parse(localStorage.getItem('currentUser')),
      "created_by_Id": localStorage.getItem('MsId'),
      "WQID": 4,
      "User_Role": this.userAccessData.Role,
      "Application_Id": this.applicationId,
      "Current_Roadmap": this.roadmapData['current'],
      "Proposed_Roadmap": this.roadmapData['proposed'],
    }
    if(this.checkPADUUpdateAccess()) {
      paduUpdatePayload["padu_updated_columns"] = this.paduUpdatedColumn;
      paduUpdatePayload["padu_updated_values"] = this.paduUpdatedTransition;
      paduUpdatePayload["Approval_From"] = "Auto";
      paduUpdatePayload["Ap_OR_R_Justification"] = 'Auto Approved as the requestor is ' + (this.userAccessData.Role == 'Admin' ? 'Admin' : 'LOB Architect.');
      paduUpdatePayload['Req_Status'] = 'Approved';
      if (this.roadmapData['current'] != this.roadmapData['proposed']){
        paduUpdatePayload["roadmap_updated_columns"] = '[Roadmap]';
        paduUpdatePayload["roadmap_updated_values"] = {
          "Roadmap":"[Roadmap] = " + (this.roadmapData['proposed'] == 'No Mapping' ? 'Null' : this.roadmapData['proposed']),
          "Replacement_App":"[Replacement_App] = Same as DB",
          "Initiative / Program for Replacement":"[Initiative / Program for Replacement] = Same as DB",
          "Tech/Business Alignment Needed":"[Tech/Business Alignment Needed] = Same as DB",
          "Non-Integrated Tech":"[Non-Integrated Tech] = Same as DB",
          "Notes / Comments":"[Notes / Comments] = Same as DB",
          "Business Owned_Manual":"[Business Owned_Manual] = Same as DB",
          "App_Strategy":"[App_Strategy] = Same as DB",
          "Migration_Forecast_QTR":"[Migration_Forecast_QTR] = Same as DB",
          "Migration_Forecast_Year":"[Migration_Forecast_Year] = Same as DB"
        };
      }

    } else {
      paduUpdatePayload['Req_Status'] = 'Open';
      paduUpdatePayload["Approval_From"] = 'Source';
      paduUpdatePayload["Ap_OR_R_Justification"] = "";
    }
    this.WorkQueuesService.createPaduUpdate(paduUpdatePayload).subscribe((result:any) => {
      if (result["Status"] === 'AutoApproved'){
        this.toaster.success('Request is Generated Successfully and Auto Approved.', '', { timeOut: 5000, positionClass: 'toast-top-center' });
        this.requestGenerated.emit(result);
        this.hidePopup.emit('padu update');
      }
      else if (result["message"] === 'data inserted successfully'){
        this.toaster.success('Request is generated successfully.', '', { timeOut: 5000, positionClass: 'toast-top-center' });
        this.hidePopup.emit('padu update');
        this.requestGenerated.emit(result);
      }
      else if(result["Status"] === "SequelizeUniqueConstraintError"){
        this.toaster.error('A Work Queue Request Already Exists for this Application.', '', { timeOut: 5000, positionClass: 'toast-top-center' });
      }
    })
  }
  formatUpdatedValuesPayload(updatedValues) {
    let payload = {};
    let updatedColumns = '';
    let keys = Object.keys(updatedValues);
    keys.forEach(element => {
      if(updatedValues[element] != 'Same as DB' && updatedValues[element] !== undefined) {
        updatedColumns += updatedColumns == '' ? '['+element+']' : ',['+element+']';
        payload[element] = '['+element+'] = '+updatedValues[element];
      }else{
        payload[element] = '['+element+'] = '+'Same as DB';
      } 
    })
    updatedValues = payload;
    return {updatedValues, updatedColumns};
  }
  checkPADUUpdateAccess(){
    if (this.PADUUpdateAccessDetails && this.PADUUpdateAccessDetails['Approve_Req_Roles'].split(',').includes(this.sharedAuthService.getAuthorizationForEA()) == true){
      if (this.role == "Admin" && this.isAdmin){
        return true;
      }
      else{
        return (this.subLobId.includes(this.currentLobDetails.currentSubLobId) == true) ? true : false;
      }
    }
    return false;
  }
  getWorkQueueAccessDetails(){
    if (!this.workQueueAccessDataLoaded){
      this.WorkQueuesService.getWorkQueueTypesAndAccessDetails().subscribe(workQueuesData => {
        if (workQueuesData){
          let result = workQueuesData;
          result.forEach(workQueue => {
              if(workQueue['Id'] === 4) {
              this.PADUUpdateAccessDetails = workQueue;
              // this.checkPADUUpdateAccess();
            }
          });
        }
        this.workQueueAccessDataLoaded = true;
      });
    }
  }

  resetInputValueForSelectingWorkqueue(): void {
    let blurData;
    if(this.searchTxtWorkQueue) {
      blurData = this.workQueueTypes.filter(x =>{
        return x.name.toString().toLowerCase().includes(this.searchTxtWorkQueue.toLowerCase());
      })
    }
    if (blurData && blurData.length < 1) {
      this.searchTxtWorkQueue = "";
      this.searchWorkQueueByName();
    }
  }

  resetInputValueForProposedLOB(): void {
    let blurData;
    if(this.searchTxt) {
      blurData = this.lobItemList.filter(x =>{
        return x.lob.toString().toLowerCase().includes(this.searchTxt.toLowerCase());
      })
    }
    if (blurData && blurData.length < 1) {
      this.searchTxt = "";
    }
  }

  resetInputValueForProposedSUBLOB(): void {
    let blurData;
    if(this.searchTxtSubLob) {
      blurData = this.subLobItemList.filter(x =>{
        return x.sub_lob.toString().toLowerCase().includes(this.searchTxtSubLob.toLowerCase());
      })
    }
    if (blurData && blurData.length < 1) {
      this.searchTxtSubLob = "";
    }
  }

  resetInputValueForApplicationList(): void {
    let blurData;
    if(this.appSearch) {
      blurData = this.appList.filter(x =>{
        if(x.app_id.toString().toLowerCase().includes(this.appSearch.toLowerCase()) || x.app_name.toString().toLowerCase().includes(this.appSearch.toLowerCase())){
          return x
        }
      })
    }
    if (blurData && blurData.length < 1) {
      this.appSearch = "";
      this.appList = this.appListDefault;
    }
  }
  roadmapChange(value){
    this.roadmapData['proposed'] = value['value'];
    if (this.roadmapData['current'] == 'Re-Architect' && this.roadmapData['proposed'] != 'Re-Architect'){
      this.showPaduUpdateInfo = 'As the Roadmap is being changed from Re Architect to ' + this.roadmapData['proposed'] + ', the Migration Forecast Date will be reset to null.';
    }
  }

  onAssociatedRequestGenerated(ev){
    if(this.fromAssociateCapabilities){
      this.WQrequestGenerative.emit(ev);
    }else{
      this.requestGenerated.emit(ev);
    }
  }

  routeToLOBmaintenance(){
    const baseUrl = window.location.origin;
    const wqUrl = baseUrl + '/maintenanceV4/lob/?subLOBId=' + this.selectedsublobId;
    window.open(wqUrl, '_blank');
  }

  updateSubLobandAccess(ev){
    if(ev){
      this.selectedsublobId = ev.subLobID;
      this.isWQRequestViewAccess = ev.access;
    }
  }
}
