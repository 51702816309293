import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { appModernizationDetails } from '../../config';
import { AppHubCacheService } from '../../shared';
import { CapabilityApplicationDetail, EditAuthUser, IApplicationDetail, ICapabilityPaginatedAppListEntity, IRiskRecordApp, IScanStatusApp, ITotalCOunt, PLMDetails, CapabilityOrganisationDetail } from '../../shared/interface/interface';

@Injectable({
  providedIn: 'root'
})
export class AppViewService {

  private apiUrl: string;
  private config: any;
  public routeSelected = new Subject<boolean>();
  public lobChangeSubject = new Subject<any>();
  public primaryUpdateubject = new Subject<any>();
  public organisationAddSubject = new Subject<any>();
  public organisationDeleteSubject = new Subject<any>();

  constructor(
    private http: HttpClient,
    private http2: HttpClient,
    private dataCache: AppHubCacheService,
    handler: HttpBackend
  ) {
    this.config = appModernizationDetails;
    this.apiUrl = environment.apiUrl;
    this.http2 = new HttpClient(handler);
  }
  selectedCapbilityFromHierarchy$ = new BehaviorSubject<any>('')

  treeData$ = new BehaviorSubject<any>({});
  getcapabilityHierarchyDropdownValues() {
    return this.selectedCapbilityFromHierarchy$;
  }

  setcapabilityHierarchyDropdownValues(object: { cap:any, alreadyMappedCapability: Array<string> }) {
    this.selectedCapbilityFromHierarchy$.next(object);
  }
  getTreeStructureDropdownValues() {
    return this.treeData$;
  }

  setTreeStructureDropdownValues(obj) {
  
    this.treeData$.next(obj);
  }


  setRouteSelected(value: boolean) {
    this.routeSelected.next(value);
  }

  public getCapabilityCount(appId: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capabilityCount;
    // if (!model) {
    //   return of(0);
    // }
    return this.http
      .get<ITotalCOunt>(url, { params: { appId } })
      .pipe(
        map(data => {
          const total: any = {};
          data.capData.forEach(item => {
            total[item.current] = item.count;
          });
          total.total = data.totalCap;
          return total;
        })
      );
  }

  public getApplicationDetails(appId: string, model: string, year: number): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.appDetails;
    return this.http.get<IApplicationDetail>(url, { params: { appId, model, year} });
  }
  

  public getCapabilityAppList(body, params): Observable<any> {

    const queryParams = Object.keys(params).map((key) => {
      return `${key}=${params[key]}`;
    }).join('&')

    const url = this.apiUrl + this.config.routerpath.capabilityPaginatedAppList + `?${queryParams}`;

    if (!body.model) {
      return of([]);
    }
    return this.http
      .post<ICapabilityPaginatedAppListEntity>(url, body)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public getCapabilityRedundency(appId: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capabilityRedudency;
    let count = 10000;
    // if (!model) {
    //   return of([]);
    // }
    return this.http
      .get<any>(url, { params: { appId } })
      .pipe(
        map(data => {
          const output: any = [];
          data.forEach(item => {
            const rowItem: any = {};
            if (item.capabilityL3 == "NULL" || item.capabilityL3 == "null" || item.capabilityL3 == null) {
              if (item.capabilityL2 == "NULL" || item.capabilityL2 == "null" || item.capabilityL2 == null) {
                rowItem.capability = item.capabilityL1;
                rowItem.selectedCapabilityID = item.capabilityL1Id;
                rowItem.selectedCapability = item.capabilityL1;
              } else {
                rowItem.capability = item.capabilityL2 ;
                rowItem.selectedCapabilityID = item.capabilityL2Id;
                rowItem.selectedCapability = item.capabilityL2;
              }
            } else {
              rowItem.capability = item.capabilityL3;
              rowItem.selectedCapabilityID = item.capabilityL3Id;
              rowItem.selectedCapability = item.capabilityL3;
            }
            rowItem.capabilityL3 = item.capabilityL3;
            rowItem.capabilityL2 = item.capabilityL2;
            rowItem.capabilityL1 = item.capabilityL1;
            rowItem.capabilityL0 = item.capabilityL0;
            rowItem.current_padu = item.current;
            rowItem.organization = item.organisation;
            rowItem.capabilityL0Id = item.capabilityL0Id;
            rowItem.capabilityL1Id = item.capabilityL1Id;
            rowItem.capabilityL2Id = item.capabilityL2Id;
            rowItem.capabilityL3Id = item.capabilityL3Id;
            rowItem.org_id = item.org_id;
            rowItem.group_model = item.group_model;
            rowItem.transition_1 = item.transition_1;
            rowItem.transition_2 = item.transition_2;
            rowItem.transition_3 = item.transition_3;
            rowItem.transition_4 = item.transition_4;
            rowItem.transition_5 = item.transition_5;
            rowItem.transition_6 = item.transition_6;
            rowItem.primary_status = item.primary_status;
            rowItem.no_of_organisations = item.organisations.length;
            rowItem.organisations = item.organisations;
            rowItem.tempOrgID = count;
            rowItem.indeterminate = false;
            rowItem.organisations.forEach(orgItem => {
              orgItem.tempOrgID = count;
              orgItem.check = false
            });
            rowItem.id = item.id;
            count += 1;
            output.push(rowItem);
          });
          // output = output.filter(row => row.appName0);
          return output;
        })
      );
  }

  private uniqueApplications(value, index, self) {
    return (
      (value.current === 'P' || value.current === 'A') && self.findIndex(item => item.app_id === value.app_id) === index
    );
  }

  public getEditAuthUser(): Observable<any> {
    const msId = localStorage.getItem('MsId');
    const url = this.apiUrl + this.config.routerpath.allowEdit + msId;

    const cache = this.dataCache.getStaticCache(url);
    if (cache) {
      return of(cache);
    }
    return this.http.get<EditAuthUser>(url).pipe(
      flatMap(result => {
        this.dataCache.setCache(url, result, true);
        return of(result);
      })
    );
  }

  public getCapabilityHierarchy(): Observable<any> {

    const url = this.apiUrl + this.config.routerpath.getCapabilityHierarchy;

    const cache = this.dataCache.getStaticCache(url);
    if (cache) {
      return of(cache);
    }
    return this.http.get<any>(url).pipe(
      map(result => {
        const output: any = [];


        if (result.length) {

          result.forEach(d => {
            const rowItem: any = {};

            rowItem.group_model = d.group_model;
            rowItem.capabilityL0 = (d.capabilityL0 !== '') ? d.capabilityL0 : null;
            rowItem.capabilityL1 = (d.capabilityL1 !== '') ? d.capabilityL1 : null;
            rowItem.capabilityL2 = (d.capabilityL2 !== '') ? d.capabilityL2 : null;
            rowItem.capabilityL3 = (d.capabilityL3 !== '') ? d.capabilityL3 : null;
            rowItem.capabilityL0Id = (d.capabilityL0Id !== '') ? d.capabilityL0Id : null;
            rowItem.capabilityL1Id = (d.capabilityL1Id !== '') ? d.capabilityL1Id : null;
            rowItem.capabilityL2Id = (d.capabilityL2Id !== '') ? d.capabilityL2Id : null;
            rowItem.capabilityL3Id = (d.capabilityL3Id !== '') ? d.capabilityL3Id : null;
            output.push(rowItem);
          })

        }

        // output = output.filter(row => row.appName0);
        this.dataCache.setCache(url, output);
        return output;



      })
    );
  }

  // public heimdall(askid: string) {
  //   console.log(askid);
  //   const url = 'https://heimdall-api.origin-elr-core.optum.com/applications/' + askid + '/risk-records';
  //   console.log(url)
  //   return this.http.get(url);
  // }
  /**
   * @author Anjit
   * Had Tools API listing
   * @param ServiceId
   */
  public HadDetails(ServiceId: string) {
    return this.http2.get('https://had-api.optum.com/ScoringData', { params: { ServiceId } });
  }

  public getRiskRecordHeimdall(askid: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getRiskRecord + askid;
    // return this.http.get<IApplicationDetail>(url);
    return this.http
      .get<Array<IRiskRecordApp>>(url)
      .pipe(

        map(data => {

          const output: any = [];


          if (data.length) {

            data.forEach(d => {
              const rowItem: any = {};

              rowItem.riskRecordNumber = d.riskRecordNumber;
              rowItem.dateCreated = d.dateCreated;
              rowItem.controlStandardsImpacted = d.controlStandardsImpacted;
              rowItem.expirationDate = d.expirationDate;
              rowItem.reviewStatus = d.reviewStatus;
              rowItem.timesRenewed = d.timesRenewed;
              rowItem.requestOwner = d.requestOwner;
              rowItem.link = d.link;
              rowItem.applicationName = d.applicationName;
              rowItem.askId = d.askId;


              output.push(rowItem);
            })

          }
          // output = output.filter(row => row.appName0);
          return output;

        })
      );
  }

  public getScanStatusApp(askid: string): Observable<any> {
    const url = `${this.apiUrl}${this.config.routerpath.getScanStatus}${askid}`;
    // return this.http.get<IApplicationDetail>(url);
    return this.http
      .get<Array<IScanStatusApp>>(url)

      .pipe(

        map(data => {
          const output: any = [];
          if (data['_embedded']['issueSummaries'].length) {

            data['_embedded']['issueSummaries'].forEach(d => {
              const rowItem: any = {};
              rowItem.securityToolProductName = d.securityToolProductName;
              rowItem.critical = d.critical;
              rowItem.severe = d.severe;
              rowItem.high = d.high;
              rowItem.medium = d.medium;
              rowItem.low = d.low;
              rowItem.toolStatusDate = d.toolStatusDate;
              rowItem.securityToolVendorName = d.securityToolVendorName;
              const issSummaryArray: any = [];
              d.issueSummaryDetails.forEach(data => {
                const rowItem1: any = {};
                rowItem1.linkDisplayName = data.linkDisplayName;
                rowItem1.critical = data.critical;
                rowItem1.severe = data.severe;
                rowItem1.high = data.high;
                rowItem1.medium = data.medium;
                rowItem1.low = data.low;
                rowItem1.toolStatusDate = data.toolStatusDate;

                rowItem1.link = data.link;
                issSummaryArray.push(rowItem1);
              });

              rowItem.issueSummaryDetails = issSummaryArray;
              // rowItem.link = d.link;
              // rowItem.applicationName = d.applicationName;



              output.push(rowItem);
            })

          }
          // output = output.filter(row => row.appName0);
          return output;

        })
      );
  }
  public updatePADUSimplication(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updatePaduSimplification;
    const body = req;
    return this.http.post<any>(url, body);
  }

  public updatePADURoadMap(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updatePaduRoadMap;
    const body = req;
    return this.http.post<any>(url, body);
  }

  public updateApplicationAsscCapability(req: any, id): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updateApplicationAsscCapability + `/${id}`;
    const body = req;
    return this.http.put<any>(url, body);
  }

  addNewApplicationCapabilityMapping(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.updateApplicationAsscCapability;
    const body = req;
    return this.http.post<any>(url, body);
  }

  deleteApplicationCapabilityMapping(body) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    }
    const url = this.apiUrl + this.config.routerpath.deleteApplicationOrganizationAssociation;
    return this.http.delete<any>(url, options);
  }

  specificAppPLMData(appId): Observable<Array<PLMDetails>> {
    const url = this.apiUrl + this.config.routerpath.specificAppPLMData;
    return this.http.get<any>(url, { params: { appId } });
  }
  public getPADUWithoutToBeRetired(appId): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.getPADUWithoutToBeRetired;
    return this.http.get<any>(url, { params: { appId } });
  }

  public getCapabilityApplicationDetails(capabilityId: string, model: string, app_ID: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.capabilityDetails;
    return this.http.get<CapabilityApplicationDetail>(url, { params: { capabilityId, model, app_ID } });
  }

  public getCapabilityOrganisationDetails(capabilityId: string, model: string, app_ID: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.organisationDetails;
    return this.http.get<CapabilityOrganisationDetail>(url, { params: { capabilityId, model, app_ID } });
  }

  public getOrganisationApplicationDetails(capabilityId: string, model: string, organizationID: string, app_ID: string): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.organisationApplicationDetails;
    return this.http.get<CapabilityApplicationDetail>(url, { params: { capabilityId, model, organizationID, app_ID } });
  }

  public updatePrimarystatus(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.primaryUpdate;
    const body = req;
    return this.http.put<any>(url, body);
  }

  notifyAboutprimaryChange() {
    this.primaryUpdateubject.next({});
  }

  getAboutprimaryChange() {
    return this.primaryUpdateubject.asObservable();
  }

  notifyAboutorganisationAdd() {
    this.organisationAddSubject.next({});
  }

  getAboutorganisationAdd() {
    return this.organisationAddSubject.asObservable();
  }


  addcaps(req: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.addcaps;
    const body = req;
    return this.http.post<any>(url, body);
  }

  notifyAboutorganisationDelete() {
    this.organisationDeleteSubject.next({});
  }

  getAboutorganisationDelete() {
    return this.organisationDeleteSubject.asObservable();
  }
  editCaps(editBody: any): Observable<any> {
    const url = this.apiUrl + this.config.routerpath.editCaps;
    return this.http.post<any>(url, editBody);
  }

}



