<div class="unauth-body">

    
    <p class="unauth-text">
        <img class="icon" src="/assets/images/cancel.svg" /> Sorry, You do not have access to view this dashboard
    </p>
    <!-- <span>PAGE NOT FOUND</span> -->
    <div class="request-form" style="color: blue; font-size: 12px; height: 40px;">
        In order to access the UHC Tech Ops Hub, please request access to the Secure Group:
        UHC_TechOps_Hub
    </div>

    <div class="login">
    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" style=" margin-right: 20px; min-width: 100px;" (click)="login()" 
    title="Click to go back" > Login</button>

    <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" style="min-width: 100px;" (click)="raiseSecure()" 
    title="Click to raise secure access" > Raise Request</button>
        
</div>



