import { Component, Input} from '@angular/core';
import html2canvas from 'html2canvas';
import {PrintServiceService} from '../../services/print-service.service';
@Component({
  selector: 'app-print-component',
  templateUrl: './print-component.component.html',
  styleUrls: ['./print-component.component.css']
})
export class PrintComponentComponent  {

  @Input() printName: string;
  @Input() printDivId: string;
  @Input() printHeight: string;
  @Input() printWidth: string;
  @Input() appview: boolean;
  // @Input() hasFilter: string;
  private canvas: any;
  private subscription: any;
  constructor(public printService: PrintServiceService) {}

  ngOnInit() {
    this.subscription = this.printService.getNavChangeEmitter().subscribe(() => this.printDiv());
  }

  public printDiv() {
    const region = document.getElementById(this.printDivId);
    html2canvas(region).then(canvas => {
      canvas.style.width = this.printWidth;
      canvas.style.height = this.printHeight;
      this.canvas = canvas;
      if (this.canvas) {
        this.popup(this.canvas);
      }
    });
  }

  public popup(canvas: any) {
    let popupWin;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
                              <html>
                                <head>
                                  <title>${this.printName}</title>
                                <style>
                                canvas {
                                    max-width:100%;
                                    max-height: 90%;
                                    display: block;
                                    }
                        </style>
                                </head>
                                <body>
                                <app-mast-head></app-mast-head>
                               </body>
                              </html>`);
    popupWin.document.body.appendChild(canvas);
    popupWin.print();
    popupWin.close();

    // to split each tile
    // for (let i = 0; i < region.length; i++) {
    //   html2canvas(region[i]).then((canvas) => {
    //       canvas.style.width = '1200px';
    //       canvas.style.height = '500px';
    //       popupWin.document.body.appendChild(canvas);
    //       if (popupWin.document.body.childNodes.length - 1 === region.length) {
    //           popupWin.print();
    //       }
    //   });
    // }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
