<div class="comments">
  <ul>
    <li *ngFor="let comment of comments; let i = index">
      <header>
        <h1>{{ comment.author?.name }}</h1>
        <img *ngIf="usericon" src="{{ getGravatar() }}" />
        <span>{{ comment.created_at | date: 'dd MMM yyyy hh:mma' }}</span>
        <div>
          <div class="edit-delete" *ngIf="comment.author?.email == loggedusermail">
            <i class="edit-icon uitk-icon uitk-icon-edit sm-icon" (click)="editform(i)" title="edit comment" ></i>
            <i
              class="delete-icon uitk-icon uitk-icon-trash_delete sm-icon"
              (click)="delParentCom(comment.RowID)"
             
              title="delete comment"
            ></i>
          </div>
          <i  title="reply comment" class="edit-icon uitk-icon uitk-icon-email_reply sm-icon" *ngIf="comment?.childs?.length < 2" (click)="replyform(i)"></i>
        </div>
      </header>
      <p>{{ comment.content }}</p>
      
      <ng-container *ngIf="comment?.reply == true">
        <textarea
          placeholder="Comment"
          maxlength="160"
          name="replyComment-{{ i }}"
          class="replyComment-{{ i }} form-control"
          required
        ></textarea>
        <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="addreply(comment.RowID, i)">
          Submit
        </button>
        <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="closereply(i)">Close</button>
      </ng-container>
      <ng-container *ngIf="comment?.edit == true">
        <textarea
          placeholder="Comment"
          maxlength="160"
          name="editComment-{{ i }}"
          class="editComment-{{ i }} form-control"
          required
          >{{ comment.content }}</textarea
        >
        <button
          class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
          (click)="editcomment(comment.RowID, i)"
        >
          Submit
        </button>
        <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="closeedit(i)">Close</button>
      </ng-container>
      <ul class="child-thread">
        <li *ngFor="let com of comment.childs; let j = index">
          <header>
            <h1>{{ com.author?.name }}</h1>
            <img *ngIf="usericon" src="{{ getGravatar() }}" />
            <span>{{ com.created_at | date: 'dd MMM yyyy hh:mma' }}</span>
            <div>
              <i  title="reply comment" class="edit-icon uitk-icon uitk-icon-email_reply sm-icon" *ngIf="comment?.childs?.length < 2" (click)="replychildform(i, j)"></i>
              <div class="edit-delete" *ngIf="com.author?.email == loggedusermail">
                <i
                class="edit-icon uitk-icon uitk-icon-edit sm-icon"
                  (click)="editchildform(i, j)"
                
                  title="edit comment"
                ></i>
                <i
                class="delete-icon uitk-icon uitk-icon-trash_delete sm-icon"
                  (click)="delchildCom(com.RowID)"
                 
                  title="delete comment"
                ></i>
              </div>
            </div>

          </header>
          <p>{{ com.content }}</p>
       
          <ng-container *ngIf="com?.reply == true">
            <textarea
              placeholder="Comment"
              maxlength="160"
              name="replyComment-{{ i }}{{ j }}"
              class="replyComment-{{ i }}{{ j }} form-control"
              required
            ></textarea>
            <button
              class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
              (click)="addchildreply(comment.RowID, i, j)"
            >
              Submit
            </button>
            <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="closechildreply(i, j)">
              Close
            </button>
          </ng-container>
          <ng-container *ngIf="com?.edit == true">
            <textarea
              placeholder="Comment"
              maxlength="160"
              name="editComment-{{ i }}{{ j }}"
              class="editComment-{{ i }}{{ j }} form-control"
              required
              >{{ com.content }}</textarea
            >
            <button
              class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs"
              (click)="editchildcomment(com.RowID, i, j)"
            >
              Submit
            </button>
            <button class="m-1 uitk-c-button uitk-c-button--default mat-mdc-raised-button techops-global-button uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="closechildedit(i, j)">
              Close
            </button>
          </ng-container>
        </li>
      </ul>
    </li>
  </ul>
  <form name="form" *ngIf="comments?.length==0" (ngSubmit)="f.form.valid && addNewComment(f)" #f="ngForm" novalidate>
    <!-- <input placeholder="First and second name" type="text" [(ngModel)]="newComment.author.name" required> -->
    <textarea
      placeholder="Comment"
      maxlength="160"
      #comm="ngModel"
      name="newComment"
      [(ngModel)]="newComment"
      required
    ></textarea>
    <!-- <input type="text" placeholder="Email" ng-pattern="/^.+@.+\.[a-z]+$/" [(ngModel)]="newComment.author.email" required>
      <input type="text" placeholder="Website" ng-pattern="/^.+\.[a-z]+$/" [(ngModel)]="newComment.author.website"> -->
    <img *ngIf="usericon" id="newCommentAvatar" src="{{ getGravatar() }}" />
    <button [disabled]="comments.length >= 1" type="submit">Add comment</button>

    <div *ngIf="f.submitted && !comm.valid" class="help-block uitk-c-input__message--error">
      Comment is required
    </div>
  </form>
</div>
