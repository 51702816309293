<div class="table-container editable-table-header-color">
  <table *ngIf="dataSource?.data.length > 0 " mat-table class="mat-elevation-z8" [dataSource]="dataSource"
    multiTemplateDataRows>
    <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of columns; let i = index">
      <ng-container
        *ngIf="column.columnDef === 'select' && isEditAccess  && !isDecommissionedApp">
        <th mat-header-cell *matHeaderCellDef class="tabl-header" [style.width]="column.width"
          [style.display]="column.display" [style.padding-left]="column.paddingLeft">
          <mat-checkbox
            *ngIf="isEditAccess  && !isDecommissionedApp"

            [disabled]="disableOtherIcon" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="table-data" [style.width]="column.width"
          [style.display]="column.display" [style.padding-left]="column.paddingLeft">
          <mat-checkbox

            *ngIf="isEditAccess  && !isDecommissionedApp"

            [disabled]="disableOtherIcon && row.id" (click)="$event.stopPropagation()"
            (change)="$event ? selecttoggle($event, row) : null"
            [checked]="selection.isSelected(row)" [indeterminate]="row.indeterminate">
          </mat-checkbox>
        </td>
      </ng-container>
      <th mat-header-cell *matHeaderCellDef class="tabl-header" [style.width]="column.width">
        <span *ngIf="column.columnDef !=='expandIcon'"> {{column.header}}</span>
        <span style="    position: absolute;
      z-index: 99999;
      opacity: 0;
      cursor: pointer;"
          *ngIf="(column.header=='Group Model' || column.header=='Capability'|| column.header=='Primary') && popup && Popupp"
          (click)="filterDropdown(column)" [matMenuTriggerFor]="contextMenu" #trigger="matMenuTrigger">
          clickme
        </span>
        <span *ngIf="column.header=='Group Model' && GMFilter.length==0">
          <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
        </span>
        <span *ngIf="column.header=='Capability' && CPFilter.length==0">
          <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
        </span>
        <span *ngIf="column.header=='Primary' && PRMFilter.length==0">
          <img src="../../../../assets/images/table_filter.png" class="stkh-table-filter-img">
        </span>
        <span *ngIf="column.header=='Group Model' && GMFilter.length>0">
          <img class="stkh-table-filter-img" src="../../../../assets/images/table_filter_applied.png">
        </span>
        <span *ngIf="column.header=='Capability' && CPFilter.length>0">
          <img class="stkh-table-filter-img" src="../../../../assets/images/table_filter_applied.png">
        </span>
        <span *ngIf="column.header=='Primary' && PRMFilter.length>0">
          <img class="stkh-table-filter-img" src="../../../../assets/images/table_filter_applied.png">
        </span>


        <mat-menu #contextMenu="matMenu">
          <div class="menu-filter-popup" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
            <input matInput type="text" class="searchoption" (keydown)="$event.stopPropagation()"
              (keyup)="search($event.target.value)" [(ngModel)]="searchValue" placeholder="Search" />
            <mat-selection-list class="filter-list-container" (selectionChange)="selectionChange($event,column)"
              [disableRipple]="true" [(ngModel)]="filValue">
              <ng-container *ngIf="!searchValue">
                <mat-list-option checkboxPosition="before" *ngFor="let row of filterLists" [value]="row">
                  {{ row }}</mat-list-option>
              </ng-container>
                <ng-container *ngIf="searchValue">
                  <mat-list-option checkboxPosition="before" *ngFor="let row of searchfilterLists" [value]="row">
                    {{ row }}</mat-list-option>
                </ng-container>
            </mat-selection-list>
            <span style="cursor:pointer; float: right;padding-right: 70px;" class="small localApplyButton"
              (click)="applyLocalFilter()">Apply</span>
            <span style="cursor:pointer; float: right;padding-right: 22px;" class="small localApplyButton"
              (click)="clearLocalFilter(column)">Clear</span>
          </div>
        </mat-menu>
        <span *ngIf="column.header=='Action' && Allfilter.length>0" (click)="clearallFilter()">
          <i title="Reset Filters"
            class="font-weight-bold ng-tns-c441-21 p-4 sm-icon stkh-table-filter-img uitk-icon uitk-icon-refresh"></i>
        </span>
      </th>



      <td [ngClass]="evaluateFunction(column, element)" mat-cell *matCellDef="let element" [style.width]="column.width"
        [style.text-align]="column.display" [style.padding-left]="column.paddingLeft" class="table-data">


        <div [ngSwitch]="dataSchema[column.columnDef]">

         <div *ngSwitchCase="'isEdit'" class="actionColumn align-items-center">
            <button mat-icon-button matTooltip="View Applications with the same Capability" matTooltipClass="my-custom-tooltip"
            matTooltipPosition="above" (click)="navigateToViewApplications(element)">
              <span >
                <!-- <mat-icon class="uitk-icon material-symbols-outlined sm-icon cancel-screen-icon icon-curd"></mat-icon> -->
                <img  src="../../../../../assets/images/icon_3.svg" class="stkh-img actionItems excelandinfo-Icon-font">
              </span>
            </button>

            <button mat-icon-button class="actionItems-Expand-Margin " *ngIf="!element.expand" (click)="expand(element)"
              [disabled]="disableOtherIcon" title="Click to Expand">
              <mat-icon class="actionItems-Expand addcircle">
                add_circle_outline</mat-icon>
            </button>
            <button mat-icon-button class="actionItems-Expand-Margin" *ngIf="element.expand" (click)="collap(element)"
              [disabled]="disableOtherIcon" title="Click to Collapse">
              <mat-icon class="actionItems-Expand addcircle">
                remove_circle_outline</mat-icon>
            </button>

            <button

              *ngIf="(isEditAccess || isDelegateAccess) && !isDecommissionedApp"

              [class.disableEdit]="isDecommissionedApp" mat-icon-button [disabled]="disableOtherIcon"
              (click)="loadDropdown(element);" title="Click to Edit">
              <mat-icon class="actionItems excelandinfo-Icon-font">
                create
              </mat-icon>
            </button>
            <button

              *ngIf="isEditAccess  && !isDecommissionedApp"

              [class.disableEdit]="isDecommissionedApp" mat-icon-button [disabled]="disableOtherIcon"
              (click)="loadDropdownMerge(element)" title="Click to Merge">
              <img src="../../../../assets/images/merge.svg" style="width: 24px; height: 24px;">
            </button>
          </div>
          <div *ngSwitchCase="'isEditProduct'" class="actionColumn">
            <button mat-icon-button class="actionItems-Expand-Margin" *ngIf="!element.expand" (click)="expand(element)"
              [disabled]="disableOtherIcon">
              <mat-icon class="actionItems-Expand addcircle">
                add_circle_outline</mat-icon>
            </button>
            <button mat-icon-button class="actionItems-Expand-Margin" *ngIf="element.expand" (click)="collap(element)"
              [disabled]="disableOtherIcon">
              <mat-icon class="actionItems-Expand addcircle">
                remove_circle_outline</mat-icon>
            </button>
          </div>
          <span *ngSwitchDefault>
            <span *ngIf="column.type==='image'">
              <img
                *ngIf="isEditAccess  && isPrimaryCapability(element)"
                href="" class="image-icon primary-Yes" src="{{column.cell(element)}}"
                (click)="primaryUpdate(element,isDecommissionedApp)" />
              <img
                *ngIf="isEditAccess   && !isPrimaryCapability(element)"

                href="" class="image-icon center-image" src="{{column.cell(element)}}"
                (click)="primaryUpdate(element,isDecommissionedApp)" />

              <img

                *ngIf="!isEditAccess  && !isDecommissionedApp"

                href="" class="image-icon center-image" src="{{column.cell(element)}}" />
            </span>
            
            <span [ngClass]="evaluateSpanSpecificClass(column, element)" *ngIf="column.type!=='image'">
              <span *ngIf="column.columnDef==='capability'">
                <button style="text-align: left;" class="button-tooltip heigh-widt" matTooltip="{{getCapabiltyInfo(element)}}"
                  matTooltipClass="my-custom-tooltip" matTooltipPosition="above" aria-hidden="false"
                  aria-label="information  icon">
                  <p class="capability-Level" style="font-size: 12px !important;">{{getCapabiltyLevel(element) }}</p>
                </button>
              </span>
              <span *ngIf="column.columnDef==='taxonomyName'" class="uitk-icon uitk-icon-info_hollow health md-icon for-media tab-Image platform-info"
                matTooltipPosition="below" aria-hidden="false" [matTooltip]="element.taxonomyDescription"
                matTooltipClass="custom-tooltip"
                style="cursor: pointer;">
              </span>
              {{column.cell(element)}}
            </span>

          </span>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="paduexpandedDetail">
      <td style="padding-left: 1px;" mat-cell *matCellDef="let element" class="table-data"
        [attr.colspan]="displayedColumns.length">
        <div  class="example-element-detail paduexpandedDetail" [@detailExpand]="element.expand? 'expanded':'collapsed'">
          <div class="example-element-description"
            style="width: 100% !important;  border-left:0.03vw solid #013B71; margin-bottom: 1%;">
            {{element.description}}
            <ng-container>
              <app-table-data *ngIf="!businessProduct" [pageSize]="pageSizes"
                [SelectAll]="isEditAccess  && !isDecommissionedApp"

                [displaySelectAll]="false" [dataSource]="element.orgdatasource"
                (cellClick)="onOrganisationClick($event,element)" [expandTable]="{class: 'expandedtableview'}"
                (onCheckClick)="checkboxClick($event) " [columns]="associatedCapExpandedTablecolumns"
                [capabilitySelected]="isCapabilitySelected" [orgData]="orgData">
              </app-table-data>
              <app-table-data *ngIf="businessProduct" [pageSize]="pageSizes"
                [displaySelectAll]="false" [dataSource]="element.productsDataSource" [expandTable]="{class: 'expandedtableview'}"
                [columns]="expandedProductColumns" [tableConfig]="tableConfig">
              </app-table-data>
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.student-expanded-row]="element.isExpanded"
      class="example-element-row"></tr>
    <tr style="height: 0px;" mat-row *matRowDef="let row;  columns: ['paduexpandedDetail'];"
      [class.student-expanded-row]="row.isExpanded" class="example-detail-row"></tr>


  </table>
  <ng-container *ngIf="dataSource.data.length <= 0">
    <app-data-unavailable></app-data-unavailable>
  </ng-container>
  <ng-container *ngIf="pageSizes.length > 0">
    <mat-paginator [pageSizes]="pageSizes[0]" [pageSizeOptions]="[5,10,15]"  showFirstLastButtons></mat-paginator>
  </ng-container>

</div>

<ng-container >
  <app-modal [maximizable]="true" #editCpWQPopup class="modal-demo" (closeModal)="hideModal()">
    <ng-container class="app-modal-header">
      <div style="display: flex;width: 100%;">
        <div style="width: 98%;">
          Edit Capability
        </div>
        <div style="display: flex; align-items: center;">
          <button
            class="popup-info-button uitk-icon uitk-icon-info_hollow health md-icon for-media excelandinfo-Icon-font info-icon icon-size" 
            matTooltipPosition="left" title="Info">
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container class="app-modal-body" *ngIf="fromAssociateCapabilities">
      <app-work-queue-popup (showWQList)="showWQList($event)" [capabilityData]="capabilityData" [isEditWorkflow]="true" (WQrequestGenerative) ="hideCPworkqueuePopup()" [selectedWorqueue]="capabilityWorkqueueDetails" [selectedApp]="selectedApp" [fromAssociateCapabilities]="fromAssociateCapabilities" (closePopup)="hideCPworkqueuePopup()">
      </app-work-queue-popup>
    </ng-container>
  </app-modal>
</ng-container>

<app-modal (closeModal)="closeModal()" [maximizable]="true" #appCapAsscPopup class="modal-demo">
  <ng-container class="app-modal-header">Applications associated with the Group Model and Capability</ng-container>
  <ng-container class="app-modal-body">
    <app-editable-table-view *ngIf="appviewData?.capabilityID && !businessProduct" [appviewData]="appviewData"></app-editable-table-view>
  </ng-container>

</app-modal>
<app-modal (closeModal)="closeModal()" [maximizable]="true" #appOrgAsscPopup class="modal-demo" >
  <ng-container class="app-modal-header">Applications associated with the Group Model, Capability and Organization
  </ng-container>
  <ng-container class="app-modal-body">
    <app-editable-table-expand *ngIf="orgData" [orgData]="orgData"></app-editable-table-expand>
  </ng-container>

</app-modal>
<app-modal [maximizable]="true" [inViewport]="true" #capAddEditPopup class="modal-demo" (closeModal)="hidePopup('edit'); closeModal()">
  <ng-container class="app-modal-header" *ngIf="iseditClicked">Associated Capabilities Edit</ng-container>
  <ng-container class="app-modal-header" *ngIf="!iseditClicked">Associated Capabilities Add</ng-container>
  <ng-container class="app-modal-body">
    <app-cap-pop-up *ngIf="openPopup" [isEdit]="iseditClicked" [selectedOrg]="selectedOrg" 
      [selectedCapHierarchy]="editCapHierarchy" [mappedCapability]='mappedCapability' 
      [excludeModel]="excludeModel" [application]="app_ID" [selectedCapOrgs]="clonedOrgList"
      [isPrimary]="isPrimary" (dataReceived)="Saveupdate()" [openCapProposalWQReqArr]="openCapProposalWQReqArr" [defaultPaduData]="defaultPaduData"
      [modelData]="modelData" [isWQRequestViewAccess]="isWQRequestViewAccess" [capListForApp]="dataSource['data']" [selectedCapRowData]="clonedSelectedCapRow">
    </app-cap-pop-up>
  </ng-container>
</app-modal>
<app-modal (closeModal)="closeModal(); hidePopup('merge')" [maximizable]="true" [inViewport]="true" #capMergePopup class="modal-demo">
  <ng-container class="app-modal-header">Associated Capabilities Merge</ng-container>
  <ng-container class="app-modal-body">
    <app-merge-cap-popup *ngIf="ismergeClicked" [clonedSelectedCapRowMerge]="clonedSelectedCapRowMerge"
      [excludeModel]="excludeModel" [application]="app_ID" (dataReceived)="SaveupdateMerge()" 
      [capListForApp]="dataSource['data']" [modelData]="modelData" [openCapProposalWQReqArr]="openCapProposalWQReqArr">
    </app-merge-cap-popup>
  </ng-container>
</app-modal>
