<ng-container *ngIf="hierarchyNode && !showLoader">
    <div [ngClass]="(hierarchyNode && !hierarchyNode[0].showChildren && mode !== 'new' && hierarchyNode[0].id !== '' && !moveEnabled) ? 'align-center': 'align-left' "
        id="wrapper"><span class="node-label">
            <div (click)="selectedNode(hierarchyNode[0])"
                [ngClass]="[mode === 'new' && hierarchyNode[0].id === '' ? 'increase-width node' : 'node cursor' , (hierarchyNode[0]?.selectedNode)?'add-dotted-border':'remove-border']">
                <div class="node-title-section">
                    <label style="font-size: 0.8rem;">{{hierarchyNode[0].name}}
                        (L{{hierarchyNode[0].level}})</label><br />
                    <span class="add-level cursor" *ngIf="!hierarchyNode[0].addNewNodes && !moveEnabled"
                        (click)="showChildNodes(hierarchyNode[0])">+ Add
                        L{{hierarchyNode[0].level + 1}}</span>

                    <ng-container *ngIf="!moveEnabled && !disableAllIcon ; else moveEnabledIcon">
                        <span class="delete-trash cursor" (click)="deleteNode(hierarchyNode[0])"
                            *ngIf="mode == 'edit' && hierarchyNode[0]?.selectedNode && !this.hierarchyNode[0].children.length"><img
                                class="delete-trash-icon" src="../../../../assets/appHub/delete.png">Delete</span>
                    </ng-container>
                    <ng-template #moveEnabledIcon>
                        <span *ngIf="hierarchyNode[0]?.selectedNode && !disableAllIcon" (click)="cancelMove()"
                            class="cancel-icon cursor"><img class="cancel-move-icon"
                                src="../../../../assets/appHub/cancel.png">
                            Cancel
                        </span>
                        <span *ngIf="hierarchyNode[0]?.selectedNode && !disableAllIcon"
                            (click)="moveData(hierarchyNode[0],hierarchyNode[0])" class="paste-icon cursor"><img class="paste-move-icon"
                                src="../../../../assets/appHub/paste.png">
                            Paste
                        </span>


                    </ng-template>

                </div>
                <!-- <span *ngIf="hierarchyNode[0].showDescription">{{hierarchyNode[0].description}}</span> -->
                <app-product-form *ngIf="hierarchyNode[0].showDescription" (formValue)="receivedFormValue($event,hierarchyNode[0].name)"
                    (nameChange)="nameChange($event)" [formData]="hierarchyNode[0]"></app-product-form>

                <span [ngStyle]="{'pointer-events' : moveEnabled ? 'none' : 'cursor'}" class="node-sub-section"
                    (click)="showDescription(hierarchyNode[0])">
                    {{hierarchyNode[0].showDescription ? 'Less Detail' : 'More Detail'}}</span>

            </div>
            <div [ngClass]="{'highlight-node':hierarchyNode[0].highlightNode && hierarchyNode[0].showChildren}"
                class="node-expand-button cursor" (click)="toggleNodes(hierarchyNode[0])">
                <mat-icon class="expand-branch-icon">keyboard_arrow_down</mat-icon>
                {{hierarchyNode[0].children.length}}
            </div>
        </span>
        <div *ngIf="hierarchyNode[0].showChildren" class="branch lv1">
            <div [ngClass]="node.addNewNodes || node.showDescription ? 'entry edit-node': 'entry'"
                *ngFor="let node of hierarchyNode[0].children"><span class="node-label">
                    <div (click)="selectedNode(node)"
                        [ngClass]="(node?.selectedNode)?'add-dotted-border':'remove-border' " class="node">

                        <div class="node-title-section">
                            <label style="font-size: 0.8rem;">{{node.name}} (L{{node.level}})</label><br />

                            <span class="add-level cursor" *ngIf="!node.addNewNodes && !moveEnabled"
                                (click)="showChildNodes(node)">+
                                Add L{{node.level+1}}</span>
                            <ng-container *ngIf="!disableAllIcon">
                                <ng-container *ngIf="!moveEnabled; else moveEnabledIcon">
                                    <span class="delete-trash cursor" (click)="deleteNode(node)"
                                        *ngIf="node?.selectedNode && !node.children.length"><img
                                            class="delete-trash-icon"
                                            src="../../../../assets/appHub/delete.png">Delete</span>
                                    <span class="move cursor" (click)="moveNode(node,hierarchyNode[0])"
                                        *ngIf="node?.selectedNode && node.id"><img
                                            class="delete-trash-icon"
                                            src="../../../../assets/appHub/move.png">Move</span>
                                </ng-container>
                                <ng-template #moveEnabledIcon>
                                    <span *ngIf="node?.selectedNode" (click)="cancelMove()"
                                        class="cancel-icon cursor"><img class="cancel-move-icon"
                                            src="../../../../assets/appHub/cancel.png">
                                        Cancel
                                    </span>
                                    <span *ngIf="node?.selectedNode" (click)="moveData(node,hierarchyNode[0])"
                                        class="paste-icon cursor"><img class="paste-move-icon"
                                            src="../../../../assets/appHub/paste.png">
                                        Paste
                                    </span>


                                </ng-template>
                            </ng-container>
                        </div>


                        <app-product-form *ngIf="node.showDescription || node.addNewNodes"
                            [parentNode]="hierarchyNode[0]" (formValue)="receivedFormValue($event,node.name)"
                            (nameChange)="nameChange($event)" [formData]="node"></app-product-form>

                        <span class="node-sub-section cursor" (click)="showDescription(node)">
                            {{node.showDescription || node.addNewNodes ? 'Less Detail' : 'More Detail'}}</span>


                    </div>

                    <div [ngClass]="{'highlight-node':node.highlightNode && node.showChildren}"
                        class="node-expand-button cursor" (click)="toggleNodes(node)">
                        <mat-icon class="expand-branch-icon">keyboard_arrow_down</mat-icon>
                        {{node.children.length}}
                    </div>
                </span>
                <div *ngIf="node.showChildren &&node.children.length" class="branch lv2">
                    <div [ngClass]="child.addNewNodes || child.showDescription ? 'entry edit-node': 'entry'"
                        *ngFor="let child of node.children"><span class="node-label">
                            <div (click)="selectedNode(child)"
                                [ngClass]="(child?.selectedNode)?'add-dotted-border':'remove-border'" class="node">

                                <div class="node-title-section">
                                    <label style="font-size: 0.8rem;">{{child.name}}
                                        (L{{child.level}})</label><br />
                                    <span class="add-level cursor" *ngIf="!child.addNewNodes && !moveEnabled"
                                        (click)="showChildNodes(child)">+ Add L{{child.level+1}}</span>
                                    <ng-container *ngIf="!disableAllIcon">
                                        <ng-container *ngIf="!moveEnabled; else moveEnabledIcon">
                                            <span class="delete-trash cursor" (click)="deleteNode(child, node)"
                                                *ngIf="child?.selectedNode && !child.children.length"><img
                                                    class="delete-trash-icon"
                                                    src="../../../../assets/appHub/delete.png">Delete</span>
                                            <span class="move cursor" (click)="moveNode(child,node)"
                                                *ngIf="child?.selectedNode && child.id"><img
                                                    class="delete-trash-icon"
                                                    src="../../../../assets/appHub/move.png">Move</span>
                                        </ng-container>
                                        <ng-template #moveEnabledIcon>
                                            <span *ngIf="child?.selectedNode" (click)="cancelMove()"
                                                class="cancel-icon cursor"><img class="cancel-move-icon"
                                                    src="../../../../assets/appHub/cancel.png">
                                                Cancel
                                            </span>
                                            <span *ngIf="child?.selectedNode" (click)="moveData(child,node)"
                                                class="paste-icon cursor"><img class="paste-move-icon"
                                                    src="../../../../assets/appHub/paste.png">
                                                Paste
                                            </span>


                                        </ng-template>
                                    </ng-container>
                                </div>


                                <app-product-form *ngIf="child.showDescription || child.addNewNodes" [parentNode]="node"
                                    (formValue)="receivedFormValue($event,child.name)" (nameChange)="nameChange($event)"
                                    [formData]="child"></app-product-form>
                                <p class="node-sub-section cursor" (click)="showDescription(child)">
                                    {{child.showDescription || child.addNewNodes ? 'Less Detail' : 'More Detail'}}
                                </p>


                            </div>

                            <div [ngClass]="{'highlight-node':child.highlightNode && child.showChildren}"
                                class="node-expand-button cursor" (click)="toggleNodes(child)">
                                <mat-icon class="expand-branch-icon">keyboard_arrow_down</mat-icon>
                                {{child.children.length}}
                            </div>

                        </span>
                        <div *ngIf="child.showChildren &&child.children.length" class="branch lv3">
                            <div [ngClass]="children.addNewNodes || children.showDescription ? 'entry sole edit-node': 'entry sole'"
                                *ngFor="let children of child.children"><span class="node-label">
                                    <div (click)="selectedNode(children)"
                                        [ngClass]="(children?.selectedNode)?'add-dotted-border':'remove-border'"
                                        class="node">

                                        <div class="node-title-section">
                                            <label style="font-size: 0.8rem;">{{children.name}}
                                                (L{{children.level}})</label><br />
                                            <ng-container *ngIf="!disableAllIcon">
                                                <ng-container *ngIf="!moveEnabled; else moveEnabledIcon">
                                                    <span class="delete-trash cursor"
                                                        *ngIf="children?.selectedNode  && !children.children.length"
                                                        (click)="deleteNode(children, child)"><img
                                                            class="delete-trash-icon"
                                                            src="../../../../assets/appHub/delete.png">Delete</span>
                                                    <span class="move cursor" (click)="moveNode(children,child)"
                                                        *ngIf="children?.selectedNode && children.id"><img
                                                            class="delete-trash-icon"
                                                            src="../../../../assets/appHub/move.png">Move</span>
                                                </ng-container>

                                                <ng-template #moveEnabledIcon>
                                                    <span *ngIf="children?.selectedNode" (click)="cancelMove()"
                                                        class="cancel-icon cursor"><img class="cancel-move-icon"
                                                            src="../../../../assets/appHub/cancel.png">
                                                        Cancel
                                                    </span>
                                                    <span *ngIf="children?.selectedNode" (click)="moveData(children,child)"
                                                        class="paste-icon cursor"><img class="paste-move-icon"
                                                            src="../../../../assets/appHub/paste.png">
                                                        Paste
                                                    </span>
                                                </ng-template>
                                            </ng-container>

                                        </div>
                                        <app-product-form *ngIf="children.showDescription|| children.addNewNodes"
                                            [parentNode]="child" (formValue)="receivedFormValue($event,children.name)"
                                            (nameChange)="nameChange($event)" [formData]="children">
                                        </app-product-form>



                                        <span class="node-sub-section cursor"
                                            (click)="showDescription(children)">{{children.showDescription || children.addNewNodes ? 'Less Detail' : 'More Detail'}}</span>


                                    </div>


                                </span></div>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    </div>
</ng-container>
<div *ngIf="showLoader" class="row move-model-capabilities">
    <app-loader style="stroke:  #002677 !important;"></app-loader>
</div>

<div *ngIf="showMoveLoader" class="row move-model-capabilities">
    <app-loader style="stroke:  #002677 !important;"></app-loader>
</div>