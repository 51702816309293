import { Component, OnInit, ElementRef, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { tcmHubConfigDetails } from '../../../config';

import * as d3 from 'd3';

@Component({
  selector: 'app-trend-chart-forecast',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './trend-chart-forecast.component.html',
  styleUrls: ['./trend-chart-forecast.component.css']
})
export class TrendChartForecastComponent implements OnInit {
  public CurrentYear = tcmHubConfigDetails.CurrentYear ? tcmHubConfigDetails.CurrentYear : new Date().getUTCFullYear();
  @Input() name: '';
  @Input() type: 'tco';
  @Input() class: 'tco';
  @Input() TcoData;
  @Input() InfraData;
  margin;
  svg;
  @Input() data: any = [
    { group: 'Jan', '2023': '120000', '2024': '100000', '2025': '130000' },
    { group: 'Feb', '2023': '600000', '2024': '600000', '2025': '330000' },
    { group: 'Mar', '2023': '110000', '2024': '280000', '2025': '120000' },
    { group: 'Apr', '2023': '190000', '2024': '600000', '2025': '100000' },
    { group: 'May', '2023': '120000', '2024': '100000', '2025': '130000' },
    { group: 'Jun', '2023': '600000', '2024': '600000', '2025': '330000' },
    { group: 'Jul', '2023': '110000', '2024': '280000', '2025': '120000' },
    { group: 'Aug', '2023': '120000', '2024': '100000', '2025': '130000' },
    { group: 'Sep', '2023': '600000', '2024': '600000', '2025': '330000' },
    { group: 'Oct', '2023': '110000', '2024': '280000', '2025': '120000' },
    { group: 'Nov', '2023': '120000', '2024': '100000', '2025': '130000' },
    { group: 'Dec', '2023': '600000', '2024': '600000', '2025': '330000' }
  ];
  data1: any =[];
  @Input() subgroups: any = [`${this.CurrentYear - 1}`, `${this.CurrentYear}`, `${this.CurrentYear + 1}`];
  legends: any = [`${this.CurrentYear - 1} Actuals`, `${this.CurrentYear} Actuals`, `${this.CurrentYear} FC(5+7)`, `${this.CurrentYear + 1} FC(5+7)`];
  groups: any = [];
  public temphide: boolean = false;

  width;
  height;
  x;
  y;
  xSubgroup;
  color;
  parentDiv;
  bar;
  forecastCheck: any = [];
  @Input() barcolor: any = ['#666261', '#494544', '#007C89'];
  barcolor1: any = ['#666261', '#31A6B2', '#007C89'];
  legendcolor: any = ['#666261', '#494544', '#31A6B2', '#007C89'];
  @Input() yearFilter: boolean = true;
  maxKey: any;
  @Input() showTitle: boolean = true;
  @Input() showBorder: boolean = true;
  @Input() showCurrancy: boolean = true;
  currancy: any = '$';
  @Output('exportToExcel') eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('container', { static: true }) container1: ElementRef;
  
  constructor(private container: ElementRef, private http: HttpClient, private decimalPipe: DecimalPipe) {}

  decimalFormatter(num, digit) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' }
    ];

    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digit) + si[i].symbol;
  }

  removeData(){
    d3.select(this.container.nativeElement)
        .selectAll('.my_dataviz svg g')
        .remove();
    console.log('remove data');
  }

  ngOnInit() {
    this.CurrentYear = tcmHubConfigDetails.CurrentYear ? tcmHubConfigDetails.CurrentYear : new Date().getUTCFullYear();
  }

  dataConvert(data){
    const config = JSON.parse(localStorage.getItem('config'));
    const configMonth = config['currentMonth'].toString();
    let configIndex=0;
    for (const [index, entry] of data.entries()) {
      if (entry.group.toLowerCase() === configMonth) {
          configIndex = index;
      }
    }
    return configIndex;
  }

  ngAfterViewInit() {}

  ngOnChanges() {
    if (this.data) {
      if (!this.showCurrancy) {
        this.currancy = '';
      } else {
        this.currancy = '$';
      }

      d3.select(this.container.nativeElement)
        .selectAll('.my_dataviz svg g')
        .remove();

      if (this.yearFilter) {
        // this.container.nativeElement.querySelector('.first-year').checked = false;
        //  this.container.nativeElement.querySelector('.second-year').checked = false;
      }

      if (this.data) {
        let currentYearKey = this.CurrentYear.toString() in this.data[0] ? this.CurrentYear.toString() : null;
        let previousYearKey = (this.CurrentYear - 1).toString() in this.data[0] ? (this.CurrentYear - 1).toString() : null;
        let nextYearKey = (this.CurrentYear + 1).toString() in this.data[0] ? (this.CurrentYear + 1).toString() : null;

        let currentYearMax = currentYearKey != null ? d3.max(this.data, d => d[currentYearKey]) : 0;
        let previousYearMax = previousYearKey != null ? d3.max(this.data, d => d[previousYearKey]) : 0;
        let nextYearMax = nextYearKey != null ? d3.max(this.data, d=>d[nextYearKey]) : 0;
        this.maxKey = previousYearMax>currentYearMax && previousYearMax>nextYearMax ? previousYearMax:
        currentYearMax>nextYearMax && currentYearMax>previousYearMax ? currentYearMax: nextYearMax;
      }
      //console.log(this.maxKey)
      //console.log(this.data)
      //let maxdata = d3.max(this.data, d => d[this.maxKey]);
      this.createchart(this.data);
    }
  }

  update() {
    let datum = this.data;
    let checkedvalues = [];
    let nodes = <any>this.container.nativeElement.querySelectorAll('.years');
    let checkedcount = 0;
    let configIndex = this.dataConvert(this.data);

    for (const e of nodes) {
      let key = [e.defaultValue];
      if (!e.checked) {
        checkedvalues = { ...checkedvalues, [`${key}`]: 0 };
        this.container.nativeElement.querySelector(`.sum${key}`).style.display = 'none';
        e.disabled = false;
      } else {
        checkedcount++;
        this.container.nativeElement.querySelector(`.sum${key}`).style.display = 'inline-block';
        if (checkedcount < 2) {
          e.disabled = true;
        } else {
          for (const e2 of nodes) {
            e2.disabled = false;
          }
        }
      }
    }

    datum = datum.map(p => {
      return { ...p, ...checkedvalues };
    });

    d3.select(this.container.nativeElement)
      .selectAll('.bar-lines g')
      .remove();

    d3.select(this.container.nativeElement)
      .selectAll('.bar-lines')
      .append('g')
      .selectAll('g')
      .data(datum)
      .join('g')
      .attr('transform', d => `translate(${this.x(d.group)}, 0)`)
      .selectAll('rect')
      .data(d => {
        return this.subgroups.map(key => {
          return { key: key, value: d[key], month: d['group'] };
        });
      })
      .join('rect')
      .attr('y', () => {
        return this.y(0);
      })
      .on('mouseover', d => {
        let cost = this.decimalFormatter(d.value, 1);
        let html = `Year:${d.key} <br> Month:${d.month} <br> Cost: ${this.currancy}${cost}`;
        let tooltipDiv = d3.select(this.container.nativeElement).select('.trendtooltip');

        tooltipDiv
          .transition()
          .duration(200)
          .style('opacity', 1);

        tooltipDiv
          .html(html)
          .style('z-index', '9999')
          .style('cursor', 'pointer');
      })
      .on('mouseout', () => {
        let tooltipDiv = d3.select(this.container.nativeElement).select('.trendtooltip');
        tooltipDiv
          .transition()
          .duration(500)
          .style('top', 20)
          .style('left', '23vw')
          .style('opacity', 0);
      })
      .transition()
      .duration(1000)
      .attr('x', d => this.xSubgroup(d.key))
      .attr('y', d => this.y(d.value))
      .attr('width', this.xSubgroup.bandwidth())
      .attr('height', d => this.height - this.y(d.value))
      .attr('fill', d => {
        const monthIndex = this.groups.indexOf(d.month);
        //debugger;
        if(monthIndex>configIndex){
          //console.log(this.barcolor1[this.subgroups.indexOf(d.key)]);
        return this.barcolor1[this.subgroups.indexOf(d.key)];
        }else{
          //console.log(this.barcolor[this.subgroups.indexOf(d.key)]);
        return this.barcolor[this.subgroups.indexOf(d.key)];
        }
      })
  }

    createchart(data) {
      let configIndex = this.dataConvert(this.data);
      //console.log(configIndex)

      this.margin = { top: 40, right: 450, bottom: 25, left: 50 };
    
      this.width = 1240 - this.margin.left - this.margin.right;
      this.height = 350 - this.margin.top - this.margin.bottom;
    
      this.svg = d3
        .select(this.container.nativeElement)
        .select('.my_dataviz svg')
        .append('g')
        .attr('transform', `translate(0,0)`);
    
      this.groups = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
      this.x = d3
        .scaleBand()
        .domain(this.groups)
        .range([0, this.width])
        .padding([0.1]);
      this.svg
        .append('g')
        .attr('transform', `translate(0, ${this.height})`)
        .call(d3.axisBottom(this.x).tickSize(0))
        .selectAll('text')
        .attr('y', 8)
        .style('text-anchor', 'start');
      this.maxKey=this.maxKey==0?10:this.maxKey;
      this.y = d3
        .scaleLinear()
        .domain([0, this.maxKey])
        .range([this.height, 0]);
    
      this.svg.append('g').call(d3.axisLeft(this.y).tickFormat(d => `${this.currancy}` + this.decimalFormatter(d, 1)));
    
      this.xSubgroup = d3
        .scaleBand()
        .domain(this.subgroups)
        .range([0, this.x.bandwidth()])
        .padding([0.05]);

    
      this.color = d3
        .scaleOrdinal()
        .domain(this.subgroups)
        .range(this.barcolor);
    
      this.bar = this.svg
        .append('g')
        .attr('class', 'bar-lines')
        .selectAll('g')
        .data(data)
        .join('g')
        .attr('transform', d => `translate(${this.x(d.group)}, 0)`)
        .selectAll('rect')
        .data(d => {
          return this.subgroups.map(function(key) {
            return { key: key, value: d[key], month: d['group'] };
          });
        })
        .join('rect')
        .attr('x', d => this.xSubgroup(d.key))
        .attr('y', d => this.y(d.value))
        .attr('width', this.xSubgroup.bandwidth())
        .attr('height', d => this.height - this.y(d.value))
        .attr('fill', d => {
          const monthIndex = this.groups.indexOf(d.month);
          //debugger;
          if(monthIndex>configIndex){
            //console.log(this.barcolor1[this.subgroups.indexOf(d.key)]);
          return this.barcolor1[this.subgroups.indexOf(d.key)];
          }else{
            //console.log(this.barcolor[this.subgroups.indexOf(d.key)]);
          return this.barcolor[this.subgroups.indexOf(d.key)];
          }
        })
        .on('mouseover', d => {
          let cost = this.decimalFormatter(d.value, 1);
          let html = `Year:${d.key} Cost: ${this.currancy}${cost}`;
          let tooltipDiv = d3.select(this.container.nativeElement).select('.trendtooltip');
          tooltipDiv
            .transition()
            .duration(200)
            .style('top', 20)
            .style('left', '23vw')
            .style('opacity', 1);
    
          tooltipDiv
            .html(html)
            .style('z-index', '9999')
            .style('cursor', 'pointer')
            .style('color', '#333333');
        })
        .on('mouseout', () => {
          let tooltipDiv = d3.select(this.container.nativeElement).select('.trendtooltip');
          tooltipDiv
            .transition()
            .duration(500)
            .style('opacity', 0);
        });
    
      d3.select(this.container.nativeElement)
        .select('.my_dataviz')
        .append('div')
        .attr('class', 'trendtooltip')
        .style('opacity', 0)
        .style('top', 0);
    
      if (this.yearFilter) {
        this.update();
      }
    
      // Add legend at the bottom
      const legend = this.svg
        .append('g')
        .attr('class', 'legend')
        .attr('transform', `translate(0, ${this.height + 40})`);
    
      const legendData = this.legends.map((key, index) => ({
        key,
        color: this.legendcolor[index]
      }));
    
      const legendItem = legend
        .selectAll('.legend-item')
        .data(legendData)
        .enter()
        .append('g')
        .attr('class', 'legend-item')
        .attr('transform', (d, i) => `translate(${i * 180}, 0)`); // Adjust the 150 value to control the spacing
    
      legendItem
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', 18)
        .attr('height', 18)
        .attr('fill', d => d.color);
    
      legendItem
        .append('text')
        .attr('x', 24)
        .attr('y', 9)
        .attr('dy', '0.35em')
        .text(d => d.key);
    }
  
  sum(key) {
    if (this.data && this.data.length > 0) {
      let total = 0;
      this.data.forEach(item => {
        total += item[key];
      });
      return this.decimalFormatter(total, 1);
    } else {
      return 0;
    }
  }
  
  download() {
    this.eventEmitter.emit(true);
  }
}