import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Inject,
  SimpleChanges,
  QueryList,
  ViewChildren
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { AppHubUtilService } from '../../services/app-hub-util.service';
import { ProductMappingService } from 'src/app/padu-reporting/product-mapping-view/product-mapping.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
export interface TableFilterConfig {
  placeHolderText: string;
  labelText: string;
  filterCallBack: (event: any) => any;
  filterPredicate?: (item: any, filter: string) => boolean;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
  isSearchEnabled = false;
  constructor(
    public util: AppHubUtilService,
    private route: Router,
    private router: ActivatedRoute,
    private service: ProductMappingService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) { }
  searchField;
  @Input() capabilityModel;
  @Input() checked: boolean = false;
  public product_app_view = true;
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @Input() columns: Array<{
    columnDef: string;
    header: string;
    width: string;
    type: string;
    spanCellStyle:any;
    display: string;
    cellStyle:any;
    cell: any;
  }>;
  @Input() TABLE_CONFIG;
  @Input() pageSize;
  @Input() pageIndex: number = 0;
  @Input() tableHeaders: Array<any> = [];
  @Input() filterConfig: TableFilterConfig;
  @Input() searchConfig?: any;
  @Input() tableConfig: any;
  @Input() localFilterConfig: any;
  @Input() expandTable;
  @Input() capabilitySelected: any;
  // @ViewChild('pagination', { static: false }) paginator;
  @ViewChild('pagination', { static: true, read: true }) DynamicPaginator;
  // @ViewChild(MatPaginator, { static: true, read: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatSort, { static: false }) DynamicSort: MatSort;
  @Output() rowClick = new EventEmitter<any>();
  @Output() cellClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() localFilterServerSidePageEvent: EventEmitter<any> = new EventEmitter();
  @Input() sortConfig: Sort;
  @Input() localFilter: boolean = false;
  displayedColumns: Array<string>;
  tableHeaderColumn: Array<string>;
  selection = new SelectionModel<PeriodicElement>(true, []);
  @Input() SelectAll = false;
  @Input() displaySelectAll = true;
  @Input() setStakeholderView = false;
  @Output() onCheckClick: EventEmitter<any> = new EventEmitter();
  @Output() onChildClick: EventEmitter<any> = new EventEmitter();
  allSelected: boolean = false;
  public dataReady = false;
  public oldlength: any;
  public isExpand: boolean = false;
  tooltipInfo = 'Benefits';
  decomColumn: any;
  plmColumn: any;
  model: string;
  // @Input() capabilityModel;
  @Input() hidecolumn = false;
  @Input() productView = '';
  @Input() initialTableData;
  @Input() FinalTableData;
  @Input() stackHolderColumns;
  finalTableData = [];
  @Input() togglecolumn: any = [];
  defaultData;
  filterList = [];
  filValue = [];
  writeAcess = JSON.parse(localStorage.getItem('isWriteAccess'));
  searchValue = '';
  firstClickOnFilter = false;
  isCheckedShowAllColumn = true;
  selectedFilteredValue = {
    appName: ''
  };
  public selectedRowIndex: any = '';
  @Input() rowExpandable: any;
  tableTotalItems;
  @Output() paginationClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() sortChangeEvent: EventEmitter<any> = new EventEmitter();
  prodInitial: any;
  prodFamilyInitial: any;
  productId: any;
  productFamilyId: any;
  expandedElement: any;
  tempOrgID = 0;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  filterApplied = false;
  ClickIgnoreColumns = [
    'Request',
    'static_columns',
    'column1',
    'MOM',
    'Roster_Status'
    //'mnth_jan', 'mnth_feb', 'mnth_march', 'mnth_apr', 'mnth_may',
    //'mnth_june', 'mnth_july', 'mnth_aug', 'mnth_sep', 'mnth_oct', 'mnth_nov', 'mnth_dec'
  ];

  ngOnChanges(changes: SimpleChanges) {
    this.dataReady = false;
    if (changes['capabilitySelected']) {
      let valueChange = false;
      if (this.capabilitySelected && this.capabilitySelected.type && this.capabilitySelected.orgIDS) {
        this.dataSource.data.forEach(row => {
          if (this.capabilitySelected.type == 'allDeSelected') {
            this.selection.clear();
            valueChange = true;
          } else if (this.capabilitySelected.type == 'allSelected' && this.capabilitySelected.orgIDS.length > 0
            && this.capabilitySelected.orgIDS.includes(row.tempOrgID)) {
            valueChange = true;
            this.selection.select(row);
          }
          else if (this.capabilitySelected.orgIDS.length > 0 && this.capabilitySelected.orgIDS.includes(row.tempOrgID)) {
            valueChange = true;
            if (this.capabilitySelected.type == "selected") {
              this.selection.select(row);
            } else {
              this.selection.deselect(row);
            }
          }
        });
        if (valueChange) {
          this.onCheckClick.emit({ check: false, data: this.selection.selected, tempOrgID: this.tempOrgID });
        }
      }
    }

    // if(!this.dataReady) {
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length) {



      if (this.searchConfig && this.searchConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.searchConfig.filterPredicate;
      }
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }
      if (this.dataSource.data[0].tempOrgID) {
        this.tempOrgID = this.dataSource.data[0].tempOrgID;
      }

      if (this.tableConfig && this.tableConfig.serverSidePagination) {
        this.tableTotalItems = this.tableConfig.totalItems;
        this.dataSource.paginator = this.DynamicPaginator;

        if (this.sortConfig && this.DynamicSort) {
          this.DynamicSort.active = this.sortConfig.active;
          this.DynamicSort.direction = this.sortConfig.direction;
          // this.DynamicSort.sortChange.emit(this.sortConfig);
          this.dataSource.sort = this.DynamicSort;
        }
      } else {
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort;
        if (this.sortConfig && this.sort) {
          this.sort.active = this.sortConfig.active || '';
          this.sort.direction = this.sortConfig.direction;
          this.sort.sortChange.emit(this.sortConfig);
        }
      }
      this.dataReady = true;
    } else {
      this.dataReady = false;
    }

    if (this.checked) this.viewChange(this.checked);

    // if (this.productView === 'product_view') {
    //   let unq_objs = [...new Map(this.dataSource.data.map(o => [o["app_id"], o])).values()];
    //   this.dataSource.data = unq_objs;
    // }

    // }
    this.removeLinkToTechPadu();
  }


  removeLinkToTechPadu() {
    let element = this._document.querySelectorAll('.link-click');
    if (element && element.length > 0) {
      element.forEach(ele => {
        let childNode = ele.getElementsByClassName('no-link');
        if (childNode && childNode.length > 0) {
          ele.classList.replace('link-click', 'no-link');
        }
      });
    }
  }
  getRecord(row, col) {
    const obj = { row, col };
    this.rowClick.emit(obj);
  }
  getcellRecord(row, col) {
    const obj = { row, col };
    this.cellClick.emit(obj);
  }

  checkIfDecomData(column: string, row) {
    let isDecomeData = false;
    switch (column) {
      case 'appName0':
        isDecomeData = row.appDecom0 && row.appDecom0.length > 0;
        this.decomColumn = row.appDecom0;
        break;
      case 'appName1':
        isDecomeData = row.appDecom1 && row.appDecom1.length > 0;
        this.decomColumn = row.appDecom1;
        break;
      case 'appName2':
        isDecomeData = row.appDecom2 && row.appDecom2.length > 0;
        this.decomColumn = row.appDecom2;
        break;

      case 'app_name':
        isDecomeData = row.TCM_Decoms && row.TCM_Decoms.length > 0 || (row.Marked_for_Decom && row.Marked_for_Decom.toLowerCase() === 'yes');
        if (row.Marked_for_Decom && row.Marked_for_Decom.toLowerCase() === 'yes') {
          const obj: any = {};
          obj.decom_date = row.decom_date;
          obj.benefit = row.benefit;
          this.decomColumn = [obj];
          break;
        }
        this.decomColumn = row.TCM_Decoms;
        break;

      case 'application':
        isDecomeData = (row.TCM_Decoms && row.TCM_Decoms.length > 0) || (row.Marked_for_Decom && row.Marked_for_Decom.toLowerCase() === 'yes');
        if (row.Marked_for_Decom && row.Marked_for_Decom.toLowerCase() === 'yes') {
          const obj: any = {};
          obj.decom_date = row.decom_date;
          obj.benefit = row.benefit;
          this.decomColumn = [obj];
          break;
        }

        this.decomColumn = row.TCM_Decoms;
        break;
      case 'appName3':
        isDecomeData = row.TCM_Decoms && row.TCM_Decoms.length > 0;

        this.decomColumn = row.TCM_Decoms;
        break;
    }
    return isDecomeData;
  }

  checkIfPLMVulData(column: string, row) {
    let isPLMVul = false;
    switch (column) {
      case 'app_name':
        if (
          row.PLM_Prods &&
          row.PLM_Prods.length > 0 &&
          row.PLM_Prods[0].overall_compliance_status === 'Vulnerable'
        ) {
          this.plmColumn = row.PLM_Prods;
          isPLMVul = true;
        }
        break;
      case 'appName0':
        if (row.appPlm0 && row.appPlm0.length > 0 && row.appPlm0[0].overall_compliance_status === 'Vulnerable') {
          this.plmColumn = row.appPlm0;
          isPLMVul = true;
        }
        break;
      case 'appName1':
        if (row.appPlm1 && row.appPlm1.length > 0 && row.appPlm1[0].overall_compliance_status === 'Vulnerable') {
          this.plmColumn = row.appPlm1;
          isPLMVul = true;
        }
        break;
      case 'appName2':
        if (row.appPlm2 && row.appPlm2.length > 0 && row.appPlm2[0].overall_compliance_status === 'Vulnerable') {
          this.plmColumn = row.appPlm2;
          isPLMVul = true;
        }
        break;
      case 'application':
        if (
          row.PLM_Prods &&
          row.PLM_Prods.length > 0 &&
          row.PLM_Prods[0].overall_compliance_status === 'Vulnerable'
        ) {
          this.plmColumn = row.PLM_Prods;
          isPLMVul = true;
        }
        break;
    }
    return isPLMVul;
  }

  checkIfColisService(column: string) {
    let isServiceCol = false;
    if (column == 'Service_Code') {
      isServiceCol = true
    }
    else {
      isServiceCol = false
    }
    return isServiceCol;
  }

  ngOnInit() {
    this.allSelected = false;
    this.displayedColumns = this.columns.map(element => element.columnDef);
    this.tableHeaderColumn = this.tableHeaders.map(item => item.def);
    this.router.queryParams.subscribe(details => {
      this.model = details.model || '';
    });
    this.defaultData = this.dataSource && this.dataSource.data;
    // this.initialTableData = this.dataSource.data;
    // console.log(this.initialTableData );
    // if(this.productView === 'product_view'){
    //   let unq_objs = [...new Map(this.dataSource.data.map(o =>[o["app_id"], o])).values()];
    //   this.dataSource.data = unq_objs;
    //  }
    this.prodInitial = this.router.snapshot.queryParamMap.get('product');
    this.prodFamilyInitial = this.router.snapshot.queryParamMap.get('product_family');
    this.productId = this.router.snapshot.queryParamMap.get('product_id');
    this.productFamilyId = this.router.snapshot.queryParamMap.get('family_id');
  }
  ngDoCheck() {
    this.removeLinkToTechPadu();
  }
  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data.length) {
      if (this.filterConfig && this.filterConfig.filterPredicate) {
        this.dataSource.filterPredicate = this.filterConfig.filterPredicate;
      }

      if (this.tableConfig && this.tableConfig.serverSidePagination) {

        this.tableTotalItems = this.tableConfig.totalItems;
        this.dataSource.paginator = this.DynamicPaginator;
        if (this.sortConfig && this.DynamicSort) {
          this.DynamicSort.active = this.sortConfig.active || '';
          this.DynamicSort.direction = this.sortConfig.direction;
          // this.DynamicSort.sortChange.emit(this.sortConfig);
        }

        this.dataSource.sort = this.DynamicSort;
      } else {
        // this.dataSource.paginator = this.paging.toArray()[0];
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.dataSource.sort = this.sort;
      }

      if (this.tableConfig && this.tableConfig.searchString) {
        this.searchField = this.tableConfig.searchString;
      }
      // if (this.sortConfig) {
      //   this.dataSource.sort = this.sort;
      //   this.sort.active = this.sortConfig.active;
      //   this.sort.direction = this.sortConfig.direction;
      //   this.sort.sortChange.emit(this.sortConfig);
      // }
    }
    // if(this.productView === 'product_view'){
    //   let unq_objs = [...new Map(this.dataSource.data.map(o =>[o["app_id"], o])).values()];
    //   this.dataSource.data = unq_objs;
    //  }

    if (this.checked) {
      this.viewChange(this.checked);
    }
  }

  evaluateFunction(column, element) {
    let output = {};
    if (column.cellStyle) {
      output = column.cellStyle(element);
    }

    if (column.type === 'number') {
      output['text-align-right'] = true;
    }
    if (column.type === 'textnumber') {
      output['text-align-center'] = true;
    }
    if (column.type === 'html') {
      output['text-align-left'] = true;
    }
    return output;
  }

  evaluateSpanSpecificClass(column, element) {
    let output = {};
    if (column.spanCellStyle) {
      output = column.spanCellStyle(element);
    }
    return output;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.onChildClick.emit({ childCheck: numSelected === numRows, data: [] });
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.onCheckClick.emit({ check: false, data: [], tempOrgID: this.tempOrgID });
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row));
      this.onCheckClick.emit({ check: true, data: this.selection.selected, tempOrgID: this.tempOrgID });
    }
  }

  selecttoggle() {
    // console.log(this.selection.selected,row);
    // if (!event.checked && this.selection.selected.length > 0) {
    //   this.selection.selected[0]['apps'] = this.selection.selected[0]['apps'].filter(
    //     ar => !row['apps'].find(rm => rm.long_app_name == ar.long_app_name && ar.infraProjectedYtdCharges == rm.infraProjectedYtdCharges)
    //   );
    // }
    if (this.selection.selected.length > 0) {
      this.onCheckClick.emit({ check: true, data: this.selection.selected, tempOrgID: this.tempOrgID });
    } else {
      this.onCheckClick.emit({ check: false, data: [], tempOrgID: this.tempOrgID });
    }
  }

  decomData() {
    return this.util.getDecomString(this.decomColumn);
  }

  PLMData() {
    return this.util.plmVulnerabilityString(this.plmColumn);
  }

  onClickClearSearchField() {
    this.searchField = '';
    if (this.getTableClass()) {
      this.filterConfig.filterCallBack('');
    }
  }

  redirectToAppView(element) {
    const { app_id } = element;
    this.route.navigate(['application-insights'], { queryParams: { askId: app_id } });
  }

  getTooltipInfo(column, element) {
    if (column.description) {
      if (column.description.value) {
        switch (column.columnDef) {

          case 'product':
            if (element.productDescription) {
              return `${element.productDescription || 'No Description Available'}`;
            } else {
              return 'No Description Available';
            }

          case 'product_family':
            if (element.productFamilyDescription) {
              return `${element.productFamilyDescription || 'No Description Available'}`;
            } else {
              return 'No Description Available';
            }

          case 'current':
            return `Transition 1 : ${element.Transition1},
                    Transition 2 : ${element.Transition2},
                    Transition 3 : ${element.Transition3},
                    Transition 4 : ${element.Transition4},
                    Transition 5 : ${element.Transition5},
                    Transition 6 : ${element.Transition6}`;
          // if (element.productFamilyDescription) {
          //   return `${element.productFamilyDescription || 'No Description Available'}`;
          // } else {
          //   return 'No Description Available';
          // }

          default:
            return `${column.description.name}: ${column.cell(element)}`;
        }
      } else {
        if (typeof column.description == 'function') {
          let descResponse = column.description(element);
          if (descResponse) {
            return `${descResponse}`;
          }
        } else {
          return `${column.description.name}`;
        }
      }
    }
  }
  public viewChange(value) {
    if (value) {
      this.isCheckedShowAllColumn = false;
      this.product_app_view = false;
      const unique = (arr, props = []) => [
        ...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()
      ];
      const newArr = unique(this.dataSource.data, [
        'product_family',
        'product',
        'productManager',
        'productOwner',
        'deliveryOwner'
      ]);
      this.dataSource.data = newArr;
      this.defaultData = newArr;
      this.columns.forEach(element => {
        if (element.display === 'none') {
          this.togglecolumn.push(element.columnDef);
        }
        delete element.display;
        if (!this.stackHolderColumns.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
    } else {
      this.isCheckedShowAllColumn = true;
      this.columns.forEach(element => {
        delete element.display;
        if (this.togglecolumn.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
      if (this.productView === 'product_view') {
        this.dataSource.data = this.FinalTableData;
        this.defaultData = this.initialTableData;
      }
    }
  }
  stackHolderView(event) {
    if (event.checked) {
      const unique = (arr, props = []) => [
        ...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()
      ];
      const newArr = unique(this.dataSource.data, [
        'product_family',
        'product',
        'lob',
        'productManager',
        'productOwner',
        'deliveryOwner'
      ]);
      // let unq_objs = [...new Map(this.dataSource.data.map(o => [o["app_id"], o])).values()];
      this.dataSource.data = newArr;
      this.defaultData = newArr;
      this.columns.forEach(element => {
        delete element.display;
        if (!this.stackHolderColumns.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
    } else {
      this.dataSource.data = this.initialTableData;
      this.columns.forEach(element => {
        delete element.display;
        if (this.togglecolumn.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
      if (this.productView === 'product_view') {
        // const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
        // const newArr = unique(this.dataSource.data, ['app_id', 'application', 'lob', 'product_family', 'product']);
        // let unq_objs = [...new Map(this.dataSource.data.map(o => [o["app_id"], o])).values()];
        this.dataSource.data = this.initialTableData;
        this.defaultData = this.initialTableData;
      }
    }
  }
  showhidecolumn(event) {
    this.isCheckedShowAllColumn = event.checked;
    if (event.checked) {
      this.columns.forEach(element => {
        if (element.display === 'none') {
          this.togglecolumn.push(element.columnDef);
        }
        delete element.display;
      });
      if (this.productView === 'product_view') {
        this.dataSource.data = this.FinalTableData;
        this.defaultData = this.initialTableData;
      }
    } else {
      this.columns.forEach(element => {
        delete element.display;
        if (this.togglecolumn.includes(element.columnDef)) {
          element.display = 'none';
        }
      });
      if (this.productView === 'product_view') {
        const unique = (arr, props = []) => [
          ...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()
        ];
        const newArr = unique(this.dataSource.data, ['app_id', 'application', 'lob', 'product_family', 'product']);
        // let unq_objs = [...new Map(this.dataSource.data.map(o => [o["app_id"], o])).values()];
        this.dataSource.data = newArr;
        this.defaultData = newArr;
      }
    }
  }

  editAction(element, column) {
    if (column.columnDef === 'action-edit') {
      const obj = { element, column };
      this.cellClick.emit(obj);
    } else {
      this.route.navigate(['uhcproduct-home/askid-level-capability'], {
        queryParams: {
          askId: element.app_id,
          organization: element.organization ? element.organization : '',
          product: element.product,
          appName: element.application,
          product_family: element.product_family,
          product_id: this.productId,
          family_id: this.productFamilyId,
          prod_initial: this.prodInitial,
          prodFamily_initial: this.prodFamilyInitial
        }
      });
    }
  }

  checkEditFeasibility(column) {
    if (column.columnDef === 'action') {
      return false;

      // return true;
    } else if (column.columnDef === 'action-edit') {
      return true;
    } else {
      return false;
    }
  }

  checkDeleteRow(col) {
    if (col.columnDef === 'deleterow') return true;
  }

  filterDropdown(column) {
    switch (column.columnDef) {
      case 'app_id':{
        // const arrayUniqueByKey = [...new Map(this.defaultData.map(item =>
        //   [item[app_id], item])).values()];

        // let unique = [...new Set(this.defaultData.map(item => item.app_id))];
        // unique = unique.map((str, index) => ({ name: str, selected: false }));
        // console.log(unique);
        const result = this.localFilterConfig.filter.filter(d => d.filterName === 'app_id');
        this.filterList = result[0].filterValue.map(i => ({ name: i.app_id, selected: false }));
        // this.list1 =  this.typesOfShoes;
        // this.list1 = this.typesOfShoes;
        // this.checkedValue = true;

        break;
      }
      case 'application':{
        // let unique1 = [...new Set(this.defaultData.map(item => item.application))];
        // unique1 = unique1.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique1;
        // this.list2 = unique1;
        const result1 = this.localFilterConfig.filter.filter(d => d.filterName === 'application');
        this.filterList = result1[0].filterValue.map(i => ({ name: i.app_name, id: i.app_id, selected: false }));
        break;
      }
      case 'lob':{
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.lob))];
        // this.list3 =  this.typesOfShoes;
        // let unique2 = [...new Set(this.defaultData.map(item => item.lob))];
        // unique2 = unique2.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique2;
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.lob))];
        // this.list3 = this.typesOfShoes;
        // this.checkedValue = true;

        const result2 = this.localFilterConfig.filter.filter(d => d.filterName === 'lob');
        this.filterList = result2[0].filterValue.map(i => ({ name: i, selected: false }));
        break;
      }
      case 'product_family':{
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.product_family))];
        // this.list4 =  this.typesOfShoes;
        // let unique3 = [...new Set(this.defaultData.map(item => item.product_family))];
        // unique3 = unique3.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique3
        // this.list4 = this.typesOfShoes;
        // this.checkedValue = unique;
        const result3 = this.localFilterConfig.filter.filter(d => d.filterName === 'product_family');
        this.filterList = result3[0].filterValue.map(i => ({ name: i.product_family, selected: false }));
        break;
      }
      case 'product':{
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.product))];
        // this.list5 =  this.typesOfShoes;
        // let unique4 = [...new Set(this.defaultData.map(item => item.product))];
        // unique4 = unique4.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique4;
        // this.list5 = this.typesOfShoes;
        // this.checkedValue = unique;
        const result4 = this.localFilterConfig.filter.filter(d => d.filterName === 'product');
        this.filterList = result4[0].filterValue.map(i => ({ name: i.product, selected: false }));
        break;
      }
      case 'organization':{
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.organization))];
        // this.list6 =  this.typesOfShoes;
        // let unique5 = [...new Set(this.defaultData.map(item => item.organization))];
        // unique5 = unique5.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique5;
        // this.list6 = this.typesOfShoes;
        // this.checkedValue = unique;
        const result5 = this.localFilterConfig.filter.filter(d => d.filterName === 'organization');
        this.filterList = result5[0].filterValue.map(i => ({ name: i, selected: false }));
        break;
      }
      case 'Service_Description':{
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.product))];
        // this.list5 =  this.typesOfShoes;
        // let unique4 = [...new Set(this.defaultData.map(item => item.product))];
        // unique4 = unique4.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique4;
        // this.list5 = this.typesOfShoes;
        // this.checkedValue = unique;
        const result6 = this.localFilterConfig.filter.filter(d => d.filterName === 'Service_Description');
        this.filterList = result6[0].filterValue.map(i => ({ name: i, selected: false }));

        break;
      }
      case 'server':{
        // this.typesOfShoes = [...new Set(this.defaultData.map(item => item.product))];
        // this.list5 =  this.typesOfShoes;
        // let unique4 = [...new Set(this.defaultData.map(item => item.product))];
        // unique4 = unique4.map((str, index) => ({ name: str, selected: false }));
        // this.filterList = unique4;
        // this.list5 = this.typesOfShoes;
        // this.checkedValue = unique;
        const result7 = this.localFilterConfig.filter.filter(d => d.filterName === 'server');
        this.filterList = result7[0].filterValue.map(i => ({ name: i, selected: false }));
        this.filterList = this.filterList.reduce((a, b) => {
          if (!a.find(data => data.name === b.name)) {
            a.push(b);
          }
          return a;
        }, []);
        break;
      }

    }
    // console.log(this.typesOfShoes);
    return true;
  }

  applyLocalFilter(list, column) {
    this.filterApplied = true;
    const obj = {
      list: list,
      column: column,
      type: 'apply'
    };
    this.localFilterServerSidePageEvent.emit(obj);
    // let value= column.columnDef;
    // this.filValue = [...new Set(this.filValue.map(item => item.name))];

    // let result = this.initialTableData.filter(item => this.filValue.includes(item[column.columnDef]));

    // this.dataSource.data = result;
    // this.finalTableData = result;
  }

  clearLocalFilter(list, column) {
    // this.searchValue = '';
    // this.dataSource.data = this.initialTableData;
    // if (this.isCheckedShowAllColumn) {
    //   this.dataSource.data = this.FinalTableData;
    // } else {
    //   const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
    //   const newArr = unique(this.dataSource.data, ['app_id', 'application', 'lob', 'product_family', 'product']);
    //   // let unq_objs = [...new Map(this.dataSource.data.map(o => [o["app_id"], o])).values()];
    //   this.dataSource.data = newArr;
    // }
    this.filterApplied = false;
    this.filValue = [];
    const obj = {
      list: list,
      column: column,
      type: 'clear'
    };
    this.localFilterServerSidePageEvent.emit(obj);
  }

  checkLocalFilerValid(col) {
    if (this.localFilterConfig && this.localFilterConfig.localFilter) {
      switch (col) {
        case 'action':
        case 'productManager':
        case 'productOwner':
        case 'deliveryOwner':
          return false;

        case 'app_id':
        case 'application':
        case 'lob':
        case 'product_family':
        case 'product':
        case 'organization':
        case 'Service_Description':
        case 'server':
          return true;
      }
    } else {
      return false;
    }
  }

  valueSearchSave(val, column) {
    switch (column.columnDef) {
      case 'application':
        this.selectedFilteredValue.appName = val;
    }
  }

  getTableClass(type = '') {
    if (this.tableConfig && this.tableConfig.tableClass) {
      if (type) {
        return this.tableConfig.tableClass + '_' + type;
      } else {
        return this.tableConfig.tableClass;
      }
    }
    return '';
  }

  /**
   * Server side Pagination Starts
   */

  fetchMoreItems(event) {
    if (this.sortConfig) {
      event.sort = [[this.sortConfig.active, this.sortConfig.direction]];

      if (this.searchField) {
        event.search = this.searchField;
      }
    }
    this.paginationClickEvent.emit(event);
    return false;
  }

  sortData(event) {
    if (this.tableConfig.serverSidePagination) {
      let sort: any[] = [];
      if (event.direction) {
        sort = [[event.active, event.direction]];
      }
      this.sortChangeEvent.emit(sort);
    } 
  }

  checkToolTip(column) {
    let status = false;
    switch (column.columnDef) {
      case 'Employee_Count':
        status = true;
        break;
    }
    return status;
  }

  getToolTipContent(column) {
    let content: any;
    switch (column.columnDef) {
      case 'Employee_Count':
        content = 'Headcount calculated based on billable utilization';
        break;
    }
    return content;
  }
  checkForHover(column) {
    let hoverStatus = false;
    switch (column) {
      case 'appName3':
        hoverStatus = true;
        this.isExpand = true;
        break;
    }
    return hoverStatus;
  }

  getColumValue(column, ele) {
    if (column == 'appName3') {
      let temp = this.selectedRowIndex;
      if (temp == ele.appName3) {
        this.selectedRowIndex = '';
      } else {
        this.selectedRowIndex = ele.appName3;
      }
      this.isExpand = true;
    } else {
      this.isExpand = false;
    }
  }
  enabledDisabledSearchOnClick() {
    this.isSearchEnabled = !this.isSearchEnabled;
  }

  getClassHtmlClassName(column, element) {
    if (column['columnLink']) {
      return '';
    }
    if (
      this.tableConfig &&
      ['lbr_type_tble_class', 'dept_tble_class', 'emp_tble_class'].includes(this.tableConfig['tableClass'])
    ) {
      if (element[column['columnDef']]) {
        return 'link-click';
      }
    } else {
      return 'link-click';
    }
  }

  childSearchCallback(event) {
    const currentSearchedValue = event.target.value;
    if (!this.searchConfig.isServerSideSearch) {
      if (this.searchConfig.validateSearch !== undefined) {
        const validateResult = this.searchConfig.validateSearch(currentSearchedValue);
        if (validateResult) {
          this.dataSource.filter = currentSearchedValue;
        } else {
          this.dataSource.filter = '';
        }
      } else {
        this.dataSource.filter = currentSearchedValue;
      }
    } else {
      this.searchConfig.onServerSearch(currentSearchedValue);
    }
  }
  closeSearch() {
    this.dataSource.filter = '';
  }

  getSelectedRowC() {
    return this.selection.selected;
  }

  getServiceInfo(details) {
    return details.SV_DESC ? `Service Description:${details.SV_DESC}` : '';
  }

}
