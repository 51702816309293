import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginStubComponent} from './auth/login-stub/login-stub.component';
import { environment } from 'src/environments/environment';
import { LandingPageV4Component } from '../landing-page-v4/landing-page-v4.component';

const routes: Routes = [
 
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LoginRoutingModule {
  isSSOCalled: boolean;
  constructor(){
    this.isSSOCalled = environment.enableSSO;
    if(this.isSSOCalled){
      routes.push({path: 'landing-page', component: LandingPageV4Component})
    }
    else {
      routes.push({ path: '', component: LoginStubComponent })
    }
  }
 }
