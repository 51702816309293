import { environment } from '../environments/environment';

export let adminV4APIs = {
  envApiUrl: environment.apiUrl,
  getAllUserRoles: '/v4/admin-view/getAccessDetails',
  updateUserRole: '/v4/admin-view/UpdateTechAccess',
  getTemplatesList: '/v4/admin-view/templates-list',
  generateBulkTemplate: '/v4/admin-view/generate/',
  runPostSubmit: '/v4/admin-view/postsubmit/',
}

export let apphubV4APIs = {



  environment: environment.environmentName,



envApiUrl: environment.apiUrl,

routerPath: {

  paduRoadmap : '/v4/apphub/padu-roadMap',
  associatedCapabilities: '/v4/apphub/capability-Details'

}



}
